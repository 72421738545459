import { Button, makeStyles, tokens, mergeClasses, Body1Strong, ButtonProps } from '@fluentui/react-components'
import { ColumnRegular } from '@fluentui/react-icons'
import { Fragment } from 'react'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'

interface IProps {
  placeholder?: string
  appearance?: ButtonProps['appearance']
  icon?: ButtonProps['icon']
  className?: string
  isColumnSettingPanelOpen?: boolean
  onClickButton?: () => void
}

export const ColumnSettingButton: React.FC<IProps> = ({ ...props }) => {
  const styles = useStyles()

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Fragment>
          <Body1Strong> Column Settings </Body1Strong>
        </Fragment>
      }
      toolTipParentContent={
        <Button
          className={mergeClasses(
            styles.columnSetting,
            props.isColumnSettingPanelOpen && styles.activeColumnSettingBtn,
          )}
          icon={<ColumnRegular className={styles.icon} />}
          appearance="subtle"
          onClick={props.onClickButton}
          aria-label="Close Panel"
        >
          {props.placeholder}
        </Button>
      }
    />
  )
}

const useStyles = makeStyles({
  columnSetting: {
    paddingInline: '4px',
    '&:hover': {
      backgroundColor: tokens.colorSubtleBackgroundSelected,
    },
  },
  activeColumnSettingBtn: {
    backgroundColor: tokens.colorSubtleBackgroundSelected,
  },
  icon: {
    color: tokens.colorNeutralForeground1,
  },
})
