import { Row, Spacer } from '@copilot-dash/components'
import { IQuery } from '@copilot-dash/domain'
import { makeStyles, Text } from '@fluentui/react-components'
import { memo } from 'react'
import { QueryCopyButton } from '../../../../../components/TicketsFilterPanel/children/queries/QueryCopyButton'
import { QueryMoreActionButton } from '../../../../../components/TicketsFilterPanel/children/queries/QueryMoreActionButton'

export const Title = memo((props: { rowData: IQuery }) => {
  const styles = useStyles()
  const query = props.rowData

  return (
    <Row fill>
      <Text size={400} weight="semibold" wrap className={styles.title} title={query.title}>
        {query.title}
      </Text>{' '}
      <Spacer /> <QueryCopyButton query={query} className={styles.queriesActionIcon} />{' '}
      <QueryMoreActionButton query={query} className={styles.queriesActionIcon} />
    </Row>
  )
})

const useStyles = makeStyles({
  title: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflow: 'hidden',
  },
  queriesActionIcon: {
    color: 'transparent',
  },
})

Title.displayName = 'Title'
