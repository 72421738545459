import { IClusterConfigData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getClusteringConfigList(
  context: IDashStoreContext,
  productId: number,
): Promise<Array<IClusterConfigData>> {
  const response = await context.api.automation.getClusteringConfigList({ productId: productId })
  return response.map((item) => {
    return { ...item }
  })
}
