import { Column, Row } from '@copilot-dash/components'

import { Text } from '@fluentui/react-components'
import { RocketFilled } from '@fluentui/react-icons'
import { useStyles } from './ClusterNoPermissionScreen.style'

export function ClusterNoPermissionScreen() {
  const styles = useStyles()
  // will display more information to the user when the permission request link is ready.
  return (
    <Row hAlign="center" vAlign="center">
      <Row className={styles.card}>
        <Column className={styles.container}>
          <Column hAlign="center" className={styles.header}>
            <RocketFilled aria-label="Rocket" className={styles.headerIcon} />
            <Text className={styles.headerTitle}>Sorry!</Text>
            <Text className={styles.headerTitle}>You don&apos;t have permission to configure the Cluster.</Text>
          </Column>
        </Column>
      </Row>
    </Row>
  )
}
