import { APIV2WorkItem } from '@copilot-dash/api'
import { IUpdateWorkItem } from '@copilot-dash/domain'
import { isNil, omitBy } from 'lodash'

export function convertWorkItemFromDomainToAPI(workItem: IUpdateWorkItem): APIV2WorkItem {
  return {
    ...omitBy(
      {
        id: workItem.workItemId,
        status: workItem.dsatStatus,
        priority: workItem.priority,
        teamId: workItem.teamId ? Number(workItem.teamId) : undefined,
        assignTo: workItem.assignTo,
        rootCauseList: workItem.rootCauseIdList?.map((id) => ({
          workItemId: id,
          relationType: 'Linked',
        })),
        closedComment: workItem.closedComment,
      },
      isNil,
    ),
    id: workItem.workItemId,
  }
}
