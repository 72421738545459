import { Column, Row, Spacer } from '@copilot-dash/components'
import { usePromise } from '@copilot-dash/core'
import {
  IRootCauseRecommendation,
  IUpdateWorkItem,
  IWorkItem,
  RootCauseActionTypeV2,
  WorkItemStatus,
} from '@copilot-dash/domain'
import { Button, makeStyles, shorthands, Spinner, tokens } from '@fluentui/react-components'
import { ChevronDownRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { motion } from 'framer-motion'
import { isEqual } from 'lodash'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { TicketScreenRootCauseSuggestionsV2 } from './TicketScreenRootCauseSuggestionsV2'
import { ITicketActionFormRef, TicketActionForm } from '../../../../components/TicketActionForm/TicketActionForm'
import {
  mapWorkItemToTicketActionFormValue,
  mapCopilotDashStateToWorkItemState,
} from '../../../../components/TicketActionForm/utils'
import { useToast } from '../../../../hooks/useToast'
import { TicketScreenStore } from '../../TicketScreenStore'

export const TicketScreenActionContentV2: FC = memo(() => {
  const styles = useStyles()
  const formRef = useRef<ITicketActionFormRef | null>(null)
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = app.store.use.getOrFetchTicketByTicketId(ticketId)
  const customTagsSnapshot = app.store.use.getTicketCustomTags(ticketId)
  const workItemSnapshot = app.store.use.getWorkItemByFeedbackId(ticketId)

  const [formLoaded, setFormLoaded] = useState(false)
  const [formModified, setFormModified] = useState(false)
  const [saving, setSaving] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const draftRef = useRef<Partial<IWorkItem & { customTags: string[] }>>({})
  const lastSavedWorkItemData = useRef<(IUpdateWorkItem & { customTags?: string[] }) | null>(null)
  const toast = useToast()
  const [hasSuggestionAdded, setHasSuggestionAdded] = useState(false)

  useEffect(() => {
    if (customTagsSnapshot.status === 'done' && workItemSnapshot.status === 'done') {
      lastSavedWorkItemData.current = {
        ...workItemSnapshot.data,
        customTags: customTagsSnapshot.data,
      }
    }
  }, [workItemSnapshot.data, workItemSnapshot.status, customTagsSnapshot.data, customTagsSnapshot.status])

  const rootCauseRecommendationSnapshot = usePromise(
    useMemo(() => {
      return app.store.actions.fetchRootCauseRecommendationByFeedbackId(ticketId)
    }, [ticketId]),
  )

  const [invisibleRCR, setInvisibleRCRIds] = useState<string[]>([])

  const rootCauseRecommendations: IRootCauseRecommendation[] = useMemo(
    () =>
      rootCauseRecommendationSnapshot.data
        ?.filter((item) => item.isRcrEnabled)
        .filter((item) => item.userAction === RootCauseActionTypeV2.UserNoAction)
        .filter((item) => !invisibleRCR.includes(item.workItemId)) ?? [],
    [rootCauseRecommendationSnapshot.data, invisibleRCR],
  )

  useEffect(() => {
    if (customTagsSnapshot.status === 'done' && workItemSnapshot.status === 'done') {
      const data = mapWorkItemToTicketActionFormValue(workItemSnapshot.data, customTagsSnapshot.data)
      formRef.current?.setValues(data)
      setInvisibleRCRIds(data.rootCauseIDs)
    }
  }, [customTagsSnapshot, workItemSnapshot])

  const handleSave = useCallback(() => {
    const parsed = UpdateWorkItemSchema.safeParse(draftRef.current)
    if (!parsed.success) return
    const postData = parsed.data
    setSaving(true)

    const customTagsRequest = {
      ticketId: ticketId,
      customTags: postData.customTags ?? [],
      workItemEntityId: postData.workItemId,
    }

    Promise.all([
      app.store.actions.batchUpdateWorkItems([{ ...postData, feedbackId: ticketId }]),
      app.store.actions.batchUpdateWorkItemCustomTags([customTagsRequest]),
    ])
      .then(() => {
        app.store.actions.batchUpdateTicketsByWorkItems([{ ...postData, feedbackId: ticketId }])
        lastSavedWorkItemData.current = postData
        toast.showSuccess('Saved successfully')
      })
      .catch((err) => {
        toast.showError('Failed to save', err.message)
      })
      .finally(() => {
        setSaving(false)
        setSubmittable(!isEqual(postData, lastSavedWorkItemData.current))
      })
  }, [ticketId, toast])

  const handleAcceptSuggestion = useCallback(
    (suggestion: IRootCauseRecommendation) => {
      if (!formRef.current) return
      app.store.actions.updateRootCauseRecommendationActionV2([
        {
          ticketId: ticketId,
          userActionList: [
            {
              workItemId: suggestion.workItemId,
              userActionType: RootCauseActionTypeV2.UserConfirmed,
            },
          ],
        },
      ])
      setInvisibleRCRIds((prev) => [...prev, suggestion.workItemId])
      const values = formRef.current.getValues()

      formRef.current.setValues({
        rootCauseIDs: (values?.rootCauseIDs || []).concat(suggestion.workItemId).filter((v) => v),
      })
      setHasSuggestionAdded(true)
    },
    [ticketId],
  )
  const handleRejectSuggestion = useCallback(
    (suggestion: IRootCauseRecommendation) => {
      if (!formRef.current) return
      app.store.actions.updateRootCauseRecommendationActionV2([
        {
          ticketId: ticketId,
          userActionList: [
            {
              workItemId: suggestion.workItemId,
              userActionType: RootCauseActionTypeV2.UserRejected,
            },
          ],
        },
      ])
      setInvisibleRCRIds((prev) => [...prev, suggestion.workItemId])
    },
    [ticketId],
  )

  useEffect(() => {
    if (hasSuggestionAdded) {
      setTimeout(() => {
        handleSave()
        setHasSuggestionAdded(false)
      }, 100)
    }
  }, [hasSuggestionAdded, handleSave])

  useEffect(() => {
    if (
      workItemSnapshot.status === 'done' &&
      customTagsSnapshot.status === 'done' &&
      ticketSnapshot.status === 'done' &&
      formRef.current &&
      formLoaded
    ) {
      formRef.current?.watch(async (values, fieldName) => {
        // fieldName is undefined when the form is changed by code, not by user
        if (fieldName !== undefined) {
          setIsShowMore(true)
        }

        const pass = await formRef.current?.validate()
        if (!pass) {
          setSubmittable(false)
          return
        }
        if (!values) return
        if (!values.state) return

        const postData: IUpdateWorkItem & { customTags: string[] } = {
          workItemId: workItemSnapshot.data.workItemId,
          dsatStatus: mapCopilotDashStateToWorkItemState(
            values.state,
            !!values.area,
            (values['rootCauseIDs'] ?? []).length > 0,
          ),
          priority: String(values.priority) ? `P${values.priority}` : undefined,
          teamId: values.area,
          assignTo: values.assignTo,
          closedComment: values.noActionableReason ?? '',
          rootCauseIdList: values.rootCauseIDs,
          customTags: values.customTags ?? [],
        }

        app.store.actions.updateAllCustomTags(postData.customTags ?? [])
        draftRef.current = postData
        setSubmittable(!isEqual(postData, lastSavedWorkItemData.current))
      })
    }
  }, [ticketSnapshot, workItemSnapshot, customTagsSnapshot, formLoaded])

  const showActionFrom = () => {
    setIsShowMore(!isShowMore)
  }

  return (
    <motion.div
      className={styles.root}
      initial={{ maxHeight: '66px', minHeight: '66px' }}
      animate={{ maxHeight: isShowMore ? '1000px' : '66px' }}
      transition={{ duration: 0.5 }}
    >
      <Row>
        {ticketSnapshot.status === 'done' &&
          customTagsSnapshot.status === 'done' &&
          workItemSnapshot.status === 'done' && (
            <>
              <Column fill>
                <TicketActionForm
                  className={styles.ticketActionForm}
                  ref={(ref) => {
                    formRef.current = ref
                    setFormLoaded(true)
                  }}
                  defaultValues={mapWorkItemToTicketActionFormValue(workItemSnapshot.data, customTagsSnapshot.data)}
                  onModified={(modified, data) => {
                    setFormModified(modified)
                    if (data && data.rootCauseIDs) {
                      setInvisibleRCRIds?.(data.rootCauseIDs)
                    }
                  }}
                  productId={ticketSnapshot.data.productId}
                >
                  {rootCauseRecommendations.length > 0 && (
                    <TicketScreenRootCauseSuggestionsV2
                      recommendations={rootCauseRecommendations}
                      onAccept={handleAcceptSuggestion}
                      onReject={handleRejectSuggestion}
                    />
                  )}
                </TicketActionForm>
              </Column>
              <Spacer style={{ width: '10%', minWidth: '10px', flexShrink: 1 }} />
              <Column className={styles.action}>
                {isShowMore && (
                  <Column>
                    <Button
                      disabled={!formModified || !submittable || saving}
                      appearance="primary"
                      onClick={handleSave}
                      icon={saving ? <Spinner size="extra-tiny" /> : null}
                    >
                      Save
                    </Button>
                  </Column>
                )}
                <Button
                  onClick={showActionFrom}
                  icon={isShowMore ? <ChevronUpRegular /> : <ChevronDownRegular />}
                  appearance="subtle"
                  iconPosition="after"
                  className={styles.button}
                >
                  {isShowMore ? 'Show less' : 'Show more'}
                </Button>
              </Column>
            </>
          )}
      </Row>
    </motion.div>
  )
})

TicketScreenActionContentV2.displayName = 'TicketScreenActionContentV2'

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '16px',
    borderRadius: tokens.borderRadiusLarge,
    overflowX: 'auto',
    overflowY: 'hidden',
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
  },
  ticketActionForm: {
    '@media (width>1196px)': {
      gridTemplateColumns: '1fr minmax(10px,80px) 1fr minmax(10px,80px) 1fr minmax(10px,80px) 1fr',
      gridTemplateRows: 'auto 8px auto 8px auto 8px auto',
      gridTemplateAreas: [
        ['state', '.', 'priority', '.', 'area', '.', 'assignTo'],
        ['.', '.', '.', '.', '.', '.', '.'],
        [
          'noActionableReason',
          'noActionableReason',
          'noActionableReason',
          'noActionableReason',
          'noActionableReason',
          'noActionableReason',
          'noActionableReason',
        ],
        ['.', '.', '.', '.', '.', '.', '.'],
        ['customTags', 'customTags', 'customTags', 'customTags', 'rootCauseIDs', 'rootCauseIDs', 'rootCauseIDs'],
      ]
        .map((row) => `"${row.join(' ')}"`)
        .join(' '),
    },
    '@media (840px < width <= 1196px)': {
      gridTemplateColumns: '1fr 40px 1fr 40px 1fr',
      gridTemplateRows: 'auto 8px auto 8px auto',
      gridTemplateAreas: [
        ['state', '.', 'priority', '.', 'area'],
        ['.', '.', '.', '.', '.'],
        ['assignTo', '.', 'customTags', 'customTags', 'customTags'],
        ['.', '.', '.', '.', '.'],
        ['rootCauseIDs', 'rootCauseIDs', 'rootCauseIDs', 'rootCauseIDs', 'rootCauseIDs'],
      ]
        .map((row) => `"${row.join(' ')}"`)
        .join(' '),
    },
    '@media (width <= 840px)': {
      gridTemplateColumns: '1fr 40px 1fr',
      gridTemplateRows: 'auto auto  auto auto  ',
      gridTemplateAreas: [
        ['state', '.', 'priority'],
        ['noActionableReason', 'noActionableReason', 'noActionableReason'],
        ['area', '.', 'assignTo'],
        ['.', '.', '.'],
        ['customTags', 'customTags', 'customTags'],
        ['rootCauseIDs', 'rootCauseIDs', 'rootCauseIDs'],
      ]
        .map((row) => `"${row.join(' ')}"`)
        .join(' '),
    },
  },
  button: {
    color: tokens.colorBrandForegroundLink,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: tokens.colorCompoundBrandForeground1Hover,
    },
  },
  action: {
    justifyContent: 'space-between',
  },
})

const UpdateWorkItemSchema = z.object({
  workItemId: z.string(),
  dsatStatus: z.nativeEnum(WorkItemStatus).optional(),
  priority: z.string().optional(),
  teamId: z.string().optional(),
  assignTo: z.string().optional(),
  closedComment: z.string().optional(),
  rootCauseIdList: z.array(z.string()).optional(),
  customTags: z.array(z.string()).optional(),
})
