import { AsyncSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { DashSettingFilterConfigVersion, IFilterItem, IFilterOption } from '@copilot-dash/domain'

import { useDashStore } from './DashStoreProvider'

export function useFilterOptions(filterName: string): AsyncSnapshot<IFilterOption[]> {
  const store = useDashStore()
  const snapshot = store.use.getFilterConfig(DashSettingFilterConfigVersion)

  return PromiseSnapshots.map(snapshot, (item) => {
    const optionList: IFilterOption[] = []
    if (!filterName) {
      item.forEach((filter) => {
        filter.options.forEach((option: IFilterItem) => {
          optionList.push({
            key: option.key,
            text: option.displayName,
            menu: option.groupName,
            disabled: !option.enable,
          })
        })
      })
    } else {
      const filterData = item.find((item) => item.name === filterName)
      filterData?.options.forEach((option: IFilterItem) =>
        optionList.push({
          key: option.key,
          text: option.displayName,
          menu: option.groupName,
          disabled: !option.enable,
        }),
      )
    }

    return optionList.sort((a, b) => {
      if (
        a.text.toLocaleLowerCase() === 'unknown' ||
        a.text.toLocaleLowerCase() === 'no' ||
        a.text.toLocaleLowerCase() === 'negative'
      )
        return 1
      if (
        b.text.toLocaleLowerCase() === 'unknown' ||
        b.text.toLocaleLowerCase() === 'no' ||
        b.text.toLocaleLowerCase() === 'negative'
      )
        return -1
      return a.text.localeCompare(b.text)
    })
  })
}
