import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const correlationIds = generateKustoStringArrayExp(params.correlationIds)

  return `
TuringBotMonitoredScope_NoEUPI
| where CorrelationId in (${correlationIds})
| project
    TIMESTAMP,
    Message,
    LogLevel,
    ScopeName,
    State,
    ServiceVersion,
    ConversationId,
    TraceId,
    CorrelationId,
    ScopeId,
    CausalityNameChain,
    CausalityIdChain,
    TrafficType
| sort by TIMESTAMP asc   
`
}

export const SydneyTuringBotMonitoredScope_WW: IKustoQueryDefinition = {
  database: 'CompliantSydneyLogs',
  connection: 'https://compliantsydney.eastus.kusto.windows.net',
  query,
}
