import { Field, Dropdown, Option } from '@fluentui/react-components'
import { FC, memo, useState } from 'react'
import { useCommonStyles } from './commonStyles.styles'
import { COPILOT_DASH_STATE_OPTIONS, ElementType } from '../utils'

interface IStateProps {
  value: string | undefined
  onChange: (value: string) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  disabledOptions?: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>[]
}

export const State: FC<IStateProps> = memo(
  ({
    value,
    onChange,
    orientation = 'horizontal',
    className,
    style,
    defaultValueText,
    disabled,
    fieldProps,
    disabledOptions = [],
  }) => {
    const styles = useCommonStyles()
    const [open, setOpen] = useState(false)
    return (
      <Field className={className} label="State:" orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <Dropdown
          open={open}
          onOpenChange={(evt, data) => {
            if (evt.type === 'blur') return
            setOpen(data.open)
          }}
          disabled={disabled}
          value={value || defaultValueText}
          selectedOptions={value ? [value] : []}
          onOptionSelect={(_, data) => {
            if (data.selectedOptions[0]) {
              onChange(data.selectedOptions[0])
            } else {
              onChange('')
            }
          }}
          listbox={{
            className: styles.maxHeight500,
          }}
          className={styles.minWidth150}
          appearance="underline"
        >
          {COPILOT_DASH_STATE_OPTIONS.filter((v) => v).map((item: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>) => (
            <Option key={item} value={item} disabled={disabledOptions?.includes(item)}>
              {item}
            </Option>
          ))}
        </Dropdown>
      </Field>
    )
  },
)

State.displayName = 'State'
