import { IRootCauseData } from '@copilot-dash/domain'
import { Text, tokens } from '@fluentui/react-components'
import { memo } from 'react'
import { TimeView } from '../../../../../components/Time/TimeView'

export const FixBy = memo((props: { rowData: IRootCauseData }) => {
  return (
    <>
      {props.rowData.eTA ? (
        <TimeView value={props.rowData.eTA} format="YYYY/MM/DD" />
      ) : (
        <Text style={{ color: tokens.colorNeutralForegroundDisabled }}>Not selected</Text>
      )}
    </>
  )
})

FixBy.displayName = 'FixBy'
