import { useEffect, useLayoutEffect, useState } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createSearchScreenActions } from './createSearchScreenActions'
import { createSearchScreenState } from './createSearchScreenState'
import { ISearchScreenState } from './ISearchScreenStoreState'
import { SearchScreenStoreContext } from './SearchScreenStoreContext'
import { ISearchScreenProps } from '../SearchScreen'

interface IProps {
  readonly props: ISearchScreenProps
  readonly children: React.ReactNode
}

export function SearchScreenStoreProvider({ props, children }: IProps) {
  const [value] = useState(() => {
    const initialState = createSearchScreenState(props)
    const useBoundStore = create<ISearchScreenState>()(immer(() => initialState))
    const store = {
      get state() {
        return useBoundStore.getState()
      },
      use: useBoundStore,
      setState: useBoundStore.setState,
    }

    const actions = createSearchScreenActions(store, props)
    return { store, actions }
  })

  useLayoutEffect(() => {
    return value.store.use.subscribe((state, prevState) => {
      value.actions.onStateChanged(state, prevState)
    })
  }, [value])

  useLayoutEffect(() => {
    value.actions.onInit()
  }, [value])

  useEffect(() => {
    value.actions.updateProps(props)
  }, [value, props])

  return <SearchScreenStoreContext.Provider value={value}>{children}</SearchScreenStoreContext.Provider>
}
