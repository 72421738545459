import { INewTicketData } from '@copilot-dash/domain'
import { useMemo } from 'react'

interface IResult {
  readonly verbatim: string | undefined
  readonly verbatimTranslated: boolean
  readonly highlights: Array<string> | undefined
  readonly highlightsTranslated: boolean
}

export function useTranslatedTicketVerbatim(ticket: INewTicketData): IResult {
  const translation = app.settings.translateContentToEnglish.use()

  return useMemo((): IResult => {
    const verbatimLanguage = ticket.verbatimLanguage

    const verbatim = ticket.verbatim
    const verbatimInEnglish = ticket.verbatimInEnglish
    const verbatimTranslated =
      translation &&
      !!verbatimInEnglish &&
      verbatimLanguage !== 'English' &&
      verbatim?.trim() !== verbatimInEnglish?.trim()

    const highlight = ticket.verbatimWithHighlight
    const highlightInEnglish = ticket.verbatimInEnglishWithHighlight
    const highlightTranslated = translation && !!highlightInEnglish && verbatimLanguage !== 'English'

    return {
      verbatim: verbatimTranslated ? verbatimInEnglish : verbatim,
      verbatimTranslated: verbatimTranslated,
      highlights: highlightTranslated ? highlightInEnglish : highlight,
      highlightsTranslated: highlightTranslated,
    }
  }, [translation, ticket])
}
