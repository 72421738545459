import { ISearchTicketArgs, ProductName, TeamViewSubMenuIds } from '@copilot-dash/domain'
import { z } from 'zod'

interface ITeamViewRouteState {
  readonly refreshTicker?: number
}

export interface ITeamViewRouteArgs extends ISearchTicketArgs, ITeamViewRouteState {
  readonly product: ProductName
  readonly subMenu?: TeamViewSubMenuIds
  readonly hash?: string
  readonly topIssueBatchId?: string | null
  readonly queryId?: string
}

export const teamViewRouteStateSchema = z.object({
  refreshTicker: z.number().optional(),
})
