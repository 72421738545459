import { Tab, TabItem, TabItemForMonaco } from '@copilot-dash/components'
import { TicketAsyncView } from '../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function UserpSearchGroup3({ ticketId, messageId }: IProps) {
  const snapshot = app.store.use.getRawUserpGroup3(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        return (
          <Tab>
            <TabItem name="JSON">
              <TabItemForMonaco data={data} />
            </TabItem>
          </Tab>
        )
      }}
    </TicketAsyncView>
  )
}
