import { AnyExplainerResult, AnyExplainerUser } from '@copilot-dash/components'
import { z } from 'zod'

export function explainEmail(keyword: string): AnyExplainerResult | undefined {
  const schema = z.string().email()
  if (!schema.safeParse(keyword).success || !keyword.toLowerCase().endsWith('@microsoft.com')) {
    return undefined
  }

  return {
    user: () => {
      return explainByEmail(keyword)
    },
  }
}

async function explainByEmail(email: string): Promise<AnyExplainerUser> {
  const profilePromise = app.store.actions.getUserProfile(email)
  const avatarPromise = app.store.actions.getUserPhoto(email)

  const profile = await profilePromise.catch(() => undefined)
  const avatar = await avatarPromise.catch(() => undefined)

  return {
    id: profile?.id ?? '',
    name: profile?.displayName || undefined,
    email: profile?.userPrincipalName || undefined,
    avatar: avatar || undefined,
  }
}
