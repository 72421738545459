import { ProductIds } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketEnvironment } from '../actions-ticket/getTicketEnvironment'
import { getTicketSystemTags } from '../actions-ticket/getTicketSystemTags'

/**
 * Doc:
 * https://o365exchange.visualstudio.com/O365%20Core/_workitems/edit/5533399
 */
export async function assertTicketApplicableForUserpConversation(
  context: IDashStoreContext,
  ticketId: string,
  scenario: 'utterance' | 'response' | 'conversation',
): Promise<void> {
  const environment = await getTicketEnvironment(context, ticketId).promise
  if (environment.productId !== ProductIds.USERP) {
    return
  }

  if (environment.endpoint?.scenario === 'USERP Copilot') {
    return
  }

  const tags = await getTicketSystemTags(context, ticketId).promise
  if (tags.hasUserpConversation) {
    return
  }

  switch (scenario) {
    case 'utterance':
      throw TicketError.create('NoUserpUtteranceForNotApplicable', { ticketId })
    case 'response':
      throw TicketError.create('NoUserpResponseForNotApplicable', { ticketId })
    case 'conversation':
      throw TicketError.create('NoUserpConversationForNotApplicable', { ticketId })
  }
}
