import { ApiV2TicketSourceType } from '@copilot-dash/api'

import { SubstrateSearchInfo_WW } from './definitions/01_SubstrateSearchInfo_WW'
import { SubstrateSearchException_WW } from './definitions/02_SubstrateSearchException_WW'
import { SydneyTuringBotTraceMDS_EU } from './definitions/03_SydneyTuringBotTraceMDS_EU'
import { SydneyTuringBotTraceMDS_WW } from './definitions/03_SydneyTuringBotTraceMDS_WW'
import { BizchatPerformanceEventV4_EU } from './definitions/04_BizchatPerformanceEventV4_EU'
import { BizchatPerformanceEventV4_WW } from './definitions/04_BizchatPerformanceEventV4_WW'
import { MetaOSClientEvent_EU } from './definitions/05_MetaOSClientEvent_EU'
import { MetaOSClientEvent_WW } from './definitions/05_MetaOSClientEvent_WW'
import { SydneyTuringBotChatMessageEventMDS_WW } from './definitions/06_SydneyTuringBotChatMessageEventMDS_WW'
import { SydneyTuringBotMonitoredScope_WW } from './definitions/07_SydneyTuringBotMonitoredScope_WW'
import { LLM_WW } from './definitions/08_LLM_WW'
import { BizChat3SLatency_EU } from './definitions/09_BizChat3SLatency_EU'
import { BizChat3SLatency_WW } from './definitions/09_BizChat3SLatency_WW'
import { AugLoop_WW } from './definitions/10_AugLoop_WW'
import { Slicer_WW } from './definitions/11_Slicer_WW'
import { SydneyExtensibility_WW } from './definitions/12_SydneyExtensibility_WW'
import { EntityServeExtensibility_WW } from './definitions/13_EntityServeExtensibility_WW'
import { IKustoQueryDefinition } from './types/IKustoQueryDefinition'

const definitionsForWW: Record<string, IKustoQueryDefinition> = {
  SubstrateSearchInfo: SubstrateSearchInfo_WW,
  SubstrateSearchException: SubstrateSearchException_WW,
  SydneyTuringBotTraceMDS: SydneyTuringBotTraceMDS_WW,
  BizchatPerformanceEventV4: BizchatPerformanceEventV4_WW,
  MetaOSClientEvent: MetaOSClientEvent_WW,
  SydneyTuringBotChatMessageEventMDS: SydneyTuringBotChatMessageEventMDS_WW,
  SydneyTuringBotMonitoredScope: SydneyTuringBotMonitoredScope_WW,
  LLM: LLM_WW,
  BizChat3SLatency: BizChat3SLatency_WW,
  AugLoop: AugLoop_WW,
  Slicer: Slicer_WW,
  SydneyExtensibility: SydneyExtensibility_WW,
  EntityServeExtensibility: EntityServeExtensibility_WW,
}

const definitionsForEU: Record<string, IKustoQueryDefinition> = {
  SydneyTuringBotTraceMDS: SydneyTuringBotTraceMDS_EU,
  BizchatPerformanceEventV4: BizchatPerformanceEventV4_EU,
  MetaOSClientEvent: MetaOSClientEvent_EU,
  BizChat3SLatency: BizChat3SLatency_EU,
}

/**
 * All queries are here:
 *
 * https://o365exchange.visualstudio.com/O365%20Core/_git/CopilotDash?path=/sources/FeedbackCollector/FeedbackCollector.Cosmic.Deploy/Container/Service/ServiceConfig/msit/config.json
 */
export function getKustoQueryDefinition(
  type: ApiV2TicketSourceType,
  region: 'WW' | 'EU',
): IKustoQueryDefinition | undefined {
  const primaryDefinitions = region === 'EU' ? definitionsForEU : definitionsForWW
  const backupDefinitions = region === 'EU' ? definitionsForWW : definitionsForEU

  return primaryDefinitions[type] || backupDefinitions[type]
}
