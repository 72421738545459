import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { TimeView } from '../Time/TimeView'
import { TimezoneView } from '../Timezone/TimezoneView'

export const ColumnConfigDateTime: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Date],
  field: String(ColumnKey.Date) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles>
        {props.data.ticketId && <TimeView value={props.data.dateTimeUtc} format="MM/DD HH:mm" />}
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>
        <TimezoneView>
          {(data) => {
            return (
              <span>
                {ColumnIdNameMapping[ColumnKey.Date]} ({data.valueLabel})
              </span>
            )
          }}
        </TimezoneView>
      </TableCellLayoutWithStyles>
    ),
  },
  minWidth: 90,
  width: 120,
  maxWidth: 200,
  sortable: true,
  resizable: true,
}
