import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'
import { ErrorView } from '../../Error'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function RowForWebSearch({ ticketId, messageId }: IProps) {
  const snapshot = application.store.use.getTicketSystemTags(ticketId)

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Web Search</Text>
      </TableCell>
      <TableCell>
        {(() => {
          switch (snapshot.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'error':
              return <ErrorView type="inline" error={snapshot.error} />
            case 'done': {
              const webSearchTriggered = snapshot.data?.webSearchTriggered
              if (!webSearchTriggered) {
                return 'WebSearch is not triggered'
              }

              return <span>WebSearch is triggered</span>
            }
          }
        })()}
      </TableCell>
    </TableRow>
  )
}
