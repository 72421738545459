import { ColDef } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { get } from 'lodash'
import { generateAnyColumnWidth } from './generateAnyColumnWidth'
import { generateAnyText } from './generateAnyText'
import { AnyTableColDef } from '../AnyTableTypes'

interface IParams<T> {
  readonly key: string
  readonly items: T[]
  readonly def: AnyTableColDef<T> | undefined
}

export function generateAnyColumn<T>({ key, items, def }: IParams<T>): ColDef<T> {
  return {
    headerTooltip: key,
    headerName: def?.name ?? key,
    cellDataType: true,
    initialWidth: def?.width ?? generateAnyColumnWidth(key, items),
    editable: true,
    filter: true,
    valueGetter: (params) => {
      return generateAnyText(get(params.data, key))
    },
    cellRenderer: def?.render ? customCellRenderer : undefined,
  }

  function customCellRenderer(params: CustomCellRendererProps<T>) {
    const render = def?.render
    if (render) {
      if (typeof render === 'function') {
        const item = params.data
        if (item) {
          return render(item)
        } else {
          return null
        }
      } else {
        return render
      }
    }

    return null
  }
}
