import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketApplicableForUserpSearch } from '../actions-ticket-assert/assertTicketApplicableForUserpSearch'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

interface IOptions {
  readonly existingSearchQuery?: string
}

export function getTicketSearchQuery(
  context: IDashStoreContext,
  ticketId: string,
  options?: IOptions,
): PromiseSnapshot<string> {
  return context.getOrFetch<string>({
    get: (state) => {
      return state.tickets[ticketId]?.searchQuery
    },
    set: (state, snapshot) => {
      ;(state.tickets[ticketId] ??= {}).searchQuery = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    if (options?.existingSearchQuery) {
      return options.existingSearchQuery
    }

    await assertTicketHasUserConsent(context, ticketId)
    await assertTicketApplicableForUserpSearch(context, ticketId, 'query')
    throw TicketError.create('NoUserpSearch', { ticketId })
  }
}
