import { Body, Row } from '@copilot-dash/components'

import { memo } from 'react'
import { useStyles } from './ClusterScreenLayout.styles'
import { ClusterRoute } from '../../../router'
import { ClusterJobList } from '../children/clusterConfiguration/clusterList/ClusterJobList'
import { ClusterResult } from '../children/clusterConfiguration/clusterResult/ClusterResult'

export const ClusterScreenLayout = memo(function ClusterScreenLayout() {
  const styles = useStyles()
  const clusteringRequestId = ClusterRoute.navigator.useArgsOptional()?.clusteringRequestId

  return (
    <Row className={styles.container}>
      <Body className={styles.body}>
        {clusteringRequestId ? <ClusterResult clusteringRequestId={clusteringRequestId} /> : <ClusterJobList />}
      </Body>
    </Row>
  )
})
