import { ApiV2CreateRootCauseRequest, ApiV2UpdateRootCauseRequest } from '@copilot-dash/api'
import { ICreateRootCause, IUpdateRootCause } from '@copilot-dash/domain'

export class RootCauseDataConverter {
  static postDataToApiV2(data: ICreateRootCause): ApiV2CreateRootCauseRequest {
    return {
      title: data.rootCauseTitle,
      description: data.description,
      priority: `P${data.rootCausePriority}`,
      rootCauseStatus: data.rootCauseStatus,
      teamId: Number(data.teamId),
      assignTo: data.owner,
      eta: data.eTA,
      rootCauseType: 'Issue',
    }
  }

  static updateDataToApiV2(data: IUpdateRootCause): ApiV2UpdateRootCauseRequest {
    return {
      title: data.rootCauseTitle,
      description: data.description,
      priority: `P${data.rootCausePriority}`,
      teamId: Number(data.teamId),
      assignTo: data.owner,
      eta: data.eTA,
      rootCauseStatus: data.rootCauseStatus,
      rootCauseType: 'Issue',
    }
  }
}
