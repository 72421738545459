import { Tab, TabItem } from '@copilot-dash/components'
import { UserpSearch3sDebug } from './UserpSearch3sDebug'
import { UserpSearchGroup1 } from './UserpSearchGroup1'
import { UserpSearchGroup2 } from './UserpSearchGroup2'
import { UserpSearchGroup3 } from './UserpSearchGroup3'
import { UserpSearchGroup4 } from './UserpSearchGroup4'
import { UserpSearchKusto } from './UserpSearchKusto'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function UserpSearch({ ticketId, selectedTurnId }: IProps) {
  return (
    <Tab>
      {/* Group1 */}
      <TabItem name="Request Data">
        <UserpSearchGroup1 ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>

      {/* Group2 */}
      <TabItem name="LU Data">
        <UserpSearchGroup2 ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>

      {/* Group3 */}
      <TabItem name="Response Metadata">
        <UserpSearchGroup3 ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>

      {/* Group4 */}
      <TabItem name="Search Entity Data">
        <UserpSearchGroup4 ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>

      {/* Kusto */}
      <TabItem name="Kusto">
        <UserpSearchKusto ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>

      {/* Debug */}
      <TabItem name="3S Debug Data">
        <UserpSearch3sDebug ticketId={ticketId} messageId={selectedTurnId} />
      </TabItem>
    </Tab>
  )
}
