import { Row } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { MainForegroundForMetadata } from './MainForegroundForMetadata'
import { MainForegroundForSourceBlob } from './MainForegroundForSourceBlob'
import { MainForegroundForSourceTable } from './MainForegroundForSourceTable'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function MainForeground() {
  const styles = useStyles()
  const activeTab = TicketDiagnosticStore.use((state) => state.activeTab)
  const activeSourceUrl = TicketDiagnosticStore.use((state) => state.activeSourceUrl)
  const ticketId = TicketDiagnosticStore.use((state) => state.ticketId)

  if (activeTab === TicketDiagnosticTab.Home) {
    return null
  }

  switch (activeTab) {
    case TicketDiagnosticTab.Metadata:
      return (
        <Row className={styles.root}>
          <MainForegroundForMetadata />
        </Row>
      )
    case TicketDiagnosticTab.Table:
      return (
        <Row className={styles.root} vAlign="stretch" hAlign="stretch">
          <MainForegroundForSourceTable ticketId={ticketId} />
        </Row>
      )
    case TicketDiagnosticTab.Blobs: {
      if (!activeSourceUrl) {
        return null
      } else {
        return (
          <Row className={styles.root}>
            <MainForegroundForSourceBlob url={activeSourceUrl} ticketId={ticketId} />
          </Row>
        )
      }
    }
  }
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
})
