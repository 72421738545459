import { initAppAuth } from './initAppAuth'
import { initAppInWindow } from './initAppInWindow'
import { initAppResourceErrors } from './initAppResourceErrors'
import { initClarity } from './initClarity'
import { initExplainer } from './initExplainer'
import { initImmer } from './initImmer'
import { initUrlAddress } from './initUrlAddress'
import { PerformanceUtils } from '../utils/PerformanceUtils'
import { SplashUtils } from '../utils/SplashUtils'

export async function init(): Promise<void> {
  try {
    PerformanceUtils.onStartInitialization()
    SplashUtils.showSplash()

    await doInitialize()

    PerformanceUtils.onEndInitialization()
    const duration = Math.round(performance.now())

    Logger.trace.info(`Application initialized in ${duration} ms.`)
  } catch (error) {
    Logger.trace.error('Failed to initialize application. Please check!', error)
    throw error
  } finally {
    SplashUtils.hideSplash()
  }
}

async function doInitialize(): Promise<void> {
  await initUrlAddress()
  await initImmer()
  await initExplainer()

  await initAppInWindow()
  await initAppAuth()
  await initAppResourceErrors()
  await initClarity() // Clarity need to be initialized after auth for getting userID
}
