import { Row, Tab, TabItem, TabItemForAnyTable, TabItemForMonaco } from '@copilot-dash/components'
import { AsyncSnapshots } from '@copilot-dash/core'
import { IKustoBizChat3SLatencyLogItem } from '@copilot-dash/domain'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'
import { TicketFlameGraphButton } from '../../../../common/TicketFlameGraphButton'
import { TicketSourceKustoOpener } from '../../../../common/TicketSourceKustoOpener'

export function BizChat3SLatencyView() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { readonly ticketId: string; readonly selectedTurnId: string }) {
  const snapshot = app.store.use.get3SLatencyLogs(props.ticketId, props.selectedTurnId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        const mappedItems = data.map((item) => item.raw)

        return (
          <Tab>
            <TabItem name="Table">
              <TabItemForAnyTable
                data={mappedItems}
                toolbar={<Toolbar data={data} ticketId={props.ticketId} messageId={props.selectedTurnId} />}
              />
            </TabItem>
            <TabItem name="JSON">
              <TabItemForMonaco
                data={mappedItems}
                toolbar={<Toolbar data={data} ticketId={props.ticketId} messageId={props.selectedTurnId} />}
              />
            </TabItem>
          </Tab>
        )
      }}
    </TicketAsyncView>
  )
}

interface IToolbarProps {
  readonly ticketId: string
  readonly messageId: string
  readonly data: IKustoBizChat3SLatencyLogItem[]
}

function Toolbar({ ticketId, messageId, data }: IToolbarProps) {
  return (
    <Row gap="gap.small">
      {data.map((item, index) => {
        return (
          <TicketFlameGraphButton key={index} index={index} tooltip={item.transactionId} value={item.perfTraceFile} />
        )
      })}
      <TicketSourceKustoOpener ticketId={ticketId} messageId={messageId} source="substrateSearchLatencyLog" />
    </Row>
  )
}
