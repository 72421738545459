import { z } from 'zod'

export interface ApiSearchItem {
  readonly id: string
  readonly verbatim?: string
  readonly verbatimLanguage?: string
  readonly verbatimInEnglish?: string
  readonly utterance?: string
  readonly utteranceLanguage?: string
  readonly utteranceInEnglish?: string
  readonly response?: string
  readonly responseLanguage?: string
  readonly responseInEnglish?: string
  readonly searchQuery?: string
  readonly userId?: string
  readonly createDateTime?: string
  readonly tenantId?: string
  readonly tags?: Array<string>
  readonly fullTags?: Array<string>
  readonly customTags?: Array<string>
  readonly emotionType?: string
  readonly hasUserConsent?: boolean
  readonly clientName?: string
  readonly scenarioName?: string
  readonly ring?: string
  readonly emailAddress?: string
  readonly invocationSlicers?: Array<string>
  readonly highlights?: Record<string, Array<string>>
  readonly screenshotCount?: string
  readonly chatMode?: string
  readonly scenarioSlicer?: string
  readonly utteranceGroup?: string
  readonly workItemAssignTo?: string
  readonly workItemId?: string
  readonly workItemPriority?: string
  readonly workItemStatus?: string
  readonly workItemTeamId?: string
  readonly relatedWorkItemIds?: Array<string>
  readonly messageId?: string
}

export interface ApiAISearchResponse {
  readonly searchItems: Array<ApiSearchItem>
  readonly count: number
  readonly startIndex: number
  readonly stepNumber: number
  readonly facetResults?: Record<string, Record<string, number | undefined>> //We made it optional for a quick test of different service side rings
}

export const apiSearchItemSchema = z.object({
  id: z.string(),
  verbatim: z.string().optional(),
  verbatimLanguage: z.string().optional(),
  verbatimInEnglish: z.string().optional(),
  utterance: z.string().optional(),
  utteranceLanguage: z.string().optional(),
  utteranceInEnglish: z.string().optional(),
  response: z.string().optional(),
  responseLanguage: z.string().optional(),
  responseInEnglish: z.string().optional(),
  searchQuery: z.string().optional(),
  userId: z.string().optional(),
  createDateTime: z.string().optional(),
  tenantId: z.string().optional(),
  tags: z.array(z.string()).optional(),
  fullTags: z.array(z.string()).optional(),
  customTags: z.array(z.string()).optional(),
  emotionType: z.string().optional(),
  hasUserConsent: z.boolean().optional(),
  clientName: z.string().optional(),
  scenarioName: z.string().optional(),
  ring: z.string().optional(),
  emailAddress: z.string().optional(),
  invocationSlicers: z.array(z.string()).optional(),
  highlights: z.record(z.array(z.string())).optional(),
  screenshotCount: z.string().optional(),
  chatMode: z.string().optional(),
  scenarioSlicer: z.string().optional(),
  utteranceGroup: z.string().optional(),
  workItemAssignTo: z.string().optional(),
  workItemId: z.string().optional(),
  workItemPriority: z.string().optional(),
  workItemStatus: z.string().optional(),
  workItemTeamId: z.string().optional(),
  relatedWorkItemIds: z.array(z.string()).optional(),
  messageId: z.string().optional(),
})

export const apiAISearchResponseSchema = z.object({
  searchItems: z.array(apiSearchItemSchema),
  count: z.number(),
  startIndex: z.number(),
  stepNumber: z.number(),
  facetResults: z.record(z.record(z.union([z.number(), z.undefined()]))).optional(),
})
