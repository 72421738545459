import { ApiTraceKustoLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoTraceLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getTraceLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoTraceLogItem[]> {
  return context.getOrFetch<IKustoTraceLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.traceLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.traceLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.traceLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asTraceLog()
        return results.map(convertToItemV2)
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.TraceLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.TraceLog, sourceItem })
    },
  })

  function convertToItemV2(raw: ApiTraceKustoLogItemV2): IKustoTraceLogItem {
    return {
      eventName: raw.EventName,
      message: raw.Message,
      raw,
    }
  }
}
