import { Column } from '@copilot-dash/components'
import { Spinner } from '@fluentui/react-components'
import * as React from 'react'

export const TableLoading: React.FC<{ className?: string; style?: React.CSSProperties }> = React.memo(
  ({ className, style }) => (
    <Column hAlign="center" vAlign="center" fill className={className} style={style}>
      <Spinner labelPosition="below" label="Loading..." />
    </Column>
  ),
)

TableLoading.displayName = 'TableLoading'
