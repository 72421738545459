import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text, Tooltip } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { useStyles } from './ColumnComponent.styles'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { ToolTipText } from '../Text/ToolTipText'

export const ColumnConfigTenantName: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.TenantName],
  field: String(ColumnKey.TenantName) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    if (props.data.tenantId && !props.data.tenantName) {
      return <ConfigTenantNameFromStore tenantId={props.data.tenantId} />
    } else {
      return <ConfigTenantName tenantName={props.data.tenantName} tenantId={props.data.tenantId} />
    }
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.TenantName]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 130,
  sortable: false,
  resizable: true,
}

const ConfigTenantName = ({
  tenantName,
  keyWords,
  tenantId,
}: {
  tenantName?: string
  keyWords?: string[]
  tenantId?: string
}) => {
  const styles = useStyles()
  return (
    <TableCellLayoutWithStyles truncate>
      {tenantName ? (
        <ToolTipText
          text={tenantName}
          keywords={tenantName === keyWords?.[0] ? tenantName : undefined}
          isTwoLinesTruncate={true}
        />
      ) : tenantId ? (
        <ToolTipText
          text={tenantId}
          keywords={tenantId === keyWords?.[0] ? tenantId : undefined}
          isTwoLinesTruncate={true}
        />
      ) : (
        <Text className={styles.grayColor}>None</Text>
      )}
    </TableCellLayoutWithStyles>
  )
}

const ConfigTenantNameFromStore = ({ tenantId, keyWords }: { tenantId: string; keyWords?: string[] }) => {
  const styles = useStyles()
  const snapshot = app.store.use.getTenant(tenantId)

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Skeleton appearance="translucent" className={styles.skeleton}>
          <SkeletonItem className={styles.skeletonItem1} />
          <SkeletonItem className={styles.skeletonItem2} />
        </Skeleton>
      )
    case 'error':
      return (
        <Tooltip content={tenantId} relationship="label">
          <Text className={styles.grayColor}>Unknown</Text>
        </Tooltip>
      )
    case 'done':
      return <ConfigTenantName tenantName={snapshot.data.tenantName} keyWords={keyWords} />
  }
}
