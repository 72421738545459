import { Column, Row, Spacer } from '@copilot-dash/components'
import { IRootCauseRecommendation } from '@copilot-dash/domain'
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Body1Stronger,
  Button,
  Caption1,
  Divider,
  mergeClasses,
  Text,
} from '@fluentui/react-components'
import { AddRegular, LightbulbFilamentRegular, ThumbDislikeRegular } from '@fluentui/react-icons'
import { FC, memo, useEffect } from 'react'
import { useStyles } from './RootCauseSuggestions.styles'

interface IRootCauseSuggestionsProps {
  suggestions: IRootCauseRecommendation[]
  onAcceptSuggestion: (suggestion: IRootCauseRecommendation) => void
  onRejectSuggestion: (suggestion: IRootCauseRecommendation) => void
  border?: boolean
  className?: string
}

export const RootCauseSuggestionsV2: FC<IRootCauseSuggestionsProps> = memo(
  ({ suggestions, onAcceptSuggestion, onRejectSuggestion, border = true, className }) => {
    const styles = useStyles()

    const teamsMap = app.store.use((state) => state.team.teamsMap)
    useEffect(() => {
      app.store.actions.getTeamsMap()
    }, [])

    return (
      <Column className={className}>
        <Row className={styles.header}>
          <Text size={400} weight="bold">
            Root Cause Recommendations ({suggestions.length})
          </Text>
        </Row>
        <Accordion
          multiple
          defaultOpenItems={suggestions.length > 0 ? [0] : []}
          className={mergeClasses(styles.accordion, border ? styles.border : undefined)}
          collapsible
        >
          {suggestions.map((item, index) => {
            return (
              <AccordionItem key={item.workItemId} value={index}>
                <AccordionHeader expandIconPosition="end" button={{ className: styles.accordionHeader }}>
                  <Row fill>
                    <Body1Stronger>{item.title}</Body1Stronger>
                    <Spacer />
                    <Text className={styles.area}>Area: {teamsMap?.data?.[item.teamId]?.name}</Text>
                  </Row>
                </AccordionHeader>
                <AccordionPanel>
                  <Row className={styles.action} vAlign="center">
                    <LightbulbFilamentRegular fontSize={'16px'} />
                    <Spacer width={2} />
                    <Caption1>Generated by AI. Check for accuracy.</Caption1>
                    <Spacer />
                    <Button onClick={() => onRejectSuggestion(item)} icon={<ThumbDislikeRegular fontSize={14} />}>
                      This is incorrect
                    </Button>
                    <Spacer width={8} />
                    <Button
                      onClick={() => onAcceptSuggestion(item)}
                      className={styles.addBtn}
                      icon={<AddRegular fontSize={14} />}
                    >
                      Add
                    </Button>
                  </Row>
                </AccordionPanel>
                {index !== suggestions.length - 1 && <Divider />}
              </AccordionItem>
            )
          })}
        </Accordion>
      </Column>
    )
  },
)

RootCauseSuggestionsV2.displayName = 'RootCauseSuggestionsV2'
