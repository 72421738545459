import { TicketEmotionType } from '@copilot-dash/domain'
import { makeStyles, Tag, tokens } from '@fluentui/react-components'

interface IProps {
  readonly emotion: TicketEmotionType
}

export function TicketScreenBarTicketEmotion({ emotion }: IProps) {
  const styles = useStyles()

  switch (emotion) {
    case TicketEmotionType.Negative:
      return (
        <Tag className={styles.negative} appearance="filled" size="small">
          Negative
        </Tag>
      )
    case TicketEmotionType.Positive:
      return (
        <Tag className={styles.positive} appearance="filled" size="small">
          Positive
        </Tag>
      )
    case TicketEmotionType.Empty:
      return null
  }
}

const useStyles = makeStyles({
  negative: {
    backgroundColor: tokens.colorStatusWarningBackground2,
    color: tokens.colorStatusWarningForeground2,
  },
  positive: {
    backgroundColor: tokens.colorStatusSuccessBackground2,
    color: tokens.colorStatusSuccessForeground2,
  },
})
