import { Row, Spacer } from '@copilot-dash/components'
import { Badge, Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { useCallback, useMemo } from 'react'
import { useStyles } from './SatAndDastCount.styles'
import { useSearchScreenState } from '../../store'

export function SatAndDastCount() {
  const styles = useStyles()
  const searchSnapshot = useSearchScreenState((state) => state.loadMoreSnapshot)

  const getPercentageText = useCallback((percentage: number) => {
    switch (true) {
      case percentage < 0.05:
        return '(< 0.1%)'
      case percentage < 100 && percentage >= 99.95:
        return '(> 99.9%)'
      default:
        return `(${percentage.toFixed(1) + '%'})`
    }
  }, [])

  const getCountText = useCallback(
    (ticketCount: number, count: number) => {
      if (ticketCount === 0 || count === 0) {
        return '0%'
      }
      const percentage = getPercentageText((count / ticketCount) * 100)
      return `${count.toLocaleString()} ${percentage}`
    },
    [getPercentageText],
  )

  const countRender = useMemo(() => {
    switch (searchSnapshot[0]?.status) {
      case 'waiting':
        return (
          <Skeleton className={styles.skeleton}>
            <Row>
              <SkeletonItem size={16} />
              <Spacer width={16} />
              <SkeletonItem size={16} />
            </Row>
          </Skeleton>
        )
      case 'done':
        if (searchSnapshot[0].data.satCount !== undefined && searchSnapshot[0].data.dsatCount !== undefined) {
          return (
            <Row vAlign="center">
              <Badge color="success" shape="rounded" size="extra-small" />
              <Text className={styles.countText}>
                SAT: {getCountText(searchSnapshot[0].data.ticketCount, searchSnapshot[0].data.satCount)}
              </Text>
              <Spacer width={16} />
              <Badge color="severe" shape="rounded" size="extra-small" />
              <Text className={styles.countText}>
                DSAT: {getCountText(searchSnapshot[0].data.ticketCount, searchSnapshot[0].data.dsatCount)}
              </Text>
            </Row>
          )
        }
        return
      case 'none':
      case 'error':
      default:
        return
    }
  }, [searchSnapshot, styles.countText, styles.skeleton, getCountText])

  return <Row vAlign="center">{countRender}</Row>
}
