import { Route } from 'react-router-dom'
import { McpExperimentRouteMapper } from './McpExperimentMapper'
import { McpExperimentRoutePage } from './McpExperimentRoutePage'
import { createNavigator } from '../../navigator/createNavigator'

export function McpExperimentRoute() {
  return <Route id={ID} path={PATH} element={<McpExperimentRoutePage />} />
}

const ID = 'product.mcpexperiment'
const PATH = '/:product/mcpexperiment'
McpExperimentRoute.navigator = createNavigator(ID, PATH, McpExperimentRouteMapper)
