import { ProductIds, ITeamData } from '@copilot-dash/domain'
import {
  Dialog,
  DialogSurface,
  DialogTrigger,
  Text,
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  Spinner,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { FC, memo, useRef, useState, useCallback } from 'react'
import { IFormData, FieldNames, SaveQueryForm } from './SaveQueryForm'
import { useToast } from '../../hooks/useToast'

interface IProps {
  open: boolean
  onClose: () => void
  onSaved: () => void

  okBtnText?: string
  fields?: FieldNames[]
  defaultValue?: IFormData
  title?: string
  productId: ProductIds
  teamsOptions: ITeamData[]
}

export const RenameQueryDialog: FC<IProps> = memo(
  ({
    open,
    onClose,
    okBtnText = 'Save',
    fields = [FieldNames.Title, FieldNames.TeamId],
    defaultValue,
    title = 'Rename the query',
    teamsOptions,
    onSaved,
  }) => {
    const toast = useToast()
    const [isSavable, setIsSavable] = useState(false)
    const [saving, setSaving] = useState(false)
    const formDataRef = useRef<IFormData | null>(null)

    const onOk = useCallback(
      async (data: IFormData) => {
        return application.store.actions
          .updateQuery({
            queryId: data.queryId,
            title: data.title,
            productId: data.productId,
            isTeamShared: data.isTeamShared,
            teamId: data.teamId,
            copilotDashQuery: data.copilotDashQuery,
            copilotDashPath: data.copilotDashPath,
            filterPayload: data.filterPayload,
          })
          .then((response) => {
            if (response?.queryId) {
              toast.showSuccess('Query saved successfully.', '', 'top')
              onClose()
            }
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      },
      [toast, onClose],
    )

    return (
      <Dialog
        open={open}
        onOpenChange={(_, data) => {
          if (!data.open) {
            onClose()
          }
        }}
        modalType="alert"
      >
        <DialogSurface onClick={(e) => e.stopPropagation()}>
          <DialogBody>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      onClose()
                    }}
                  />
                </DialogTrigger>
              }
            >
              {title}
              <br />
              {defaultValue?.teamId && <Text size={300}>Everyone in the team will access your changes.</Text>}
            </DialogTitle>

            <DialogContent>
              <SaveQueryForm
                fields={fields}
                afterValidate={(isValid, data) => {
                  setIsSavable(isValid)
                  if (isValid) {
                    formDataRef.current = data
                  }
                }}
                defaultValue={defaultValue}
                teamsOptions={teamsOptions}
                disabled={{
                  [FieldNames.IsTeamShared]: true,
                  [FieldNames.TeamId]: true,
                }}
                isUpdate={false}
              />
            </DialogContent>
            <DialogActions position="end">
              <Button
                disabled={!isSavable || saving}
                appearance="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (formDataRef.current) {
                    setSaving(true)
                    onOk(formDataRef.current).finally(() => setSaving(false))
                  }
                }}
                icon={saving ? <Spinner size="extra-tiny" /> : null}
              >
                {okBtnText}
              </Button>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onClose()
                  }}
                >
                  Cancel
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  },
)

RenameQueryDialog.displayName = 'RenameQueryDialog'
