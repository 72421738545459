import { Fragment, ReactNode } from 'react'
import { OverlayItem } from './OverlayItem'
import { Overlays } from './Overlays'

interface IProps {
  readonly children: ReactNode
}

export function OverlayProvider({ children }: IProps): ReactNode {
  return (
    <Fragment>
      {children}
      <Content />
    </Fragment>
  )
}

function Content(): ReactNode {
  const items = Overlays.use((state) => state.items)

  return (
    <>
      {items.map((item) => (
        <OverlayItem key={item.key} item={item} />
      ))}
    </>
  )
}
