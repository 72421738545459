import { Column } from '@copilot-dash/components'
import { DefaultProductChannelMapping, TeamViewSubMenuIds } from '@copilot-dash/domain'
import {
  Dropdown,
  mergeClasses,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  Spinner,
  Text,
} from '@fluentui/react-components'
import * as React from 'react'
import { useStyles } from './TeamFilter.styles'
import { TeamRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { useTeamViewStore } from '../store'
import { CategoryTypeIds } from '../store/const'

interface ITeamFilterProps {
  className?: string
}

export const TeamFilter: React.FC<ITeamFilterProps> = ({ className }) => {
  const styles = useStyles()

  const teamList = useGlobalStore((state) => state.team.teamList)
  const lastSelectedTeam = useTeamViewStore((state) => state.teams.lastSelectedTeamName)
  const lastSelectedTeamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)

  const onSelectChange = React.useCallback((_event: SelectionEvents, data: OptionOnSelectData) => {
    Logger.telemetry.trackEvent('TeamView/SwitchTeam', {
      product: useTeamViewStore.getState().productName,
      teamName: data.optionText,
    })

    if (!data.optionText || !data.optionValue) return

    // all fetch tickets actions will be triggered by the url args change, so we just need to navigate to the right url
    // the url monitor is in the src/screens/team/TeamScreen.tsx

    TeamRoute.navigator.navigate({
      product: useTeamViewStore.getState().productName,
      subMenu: TeamViewSubMenuIds.UnRootCaused,
      teamName: data.optionText,
      ticketStatus: CategoryTypeIds.UnRootCaused,
      channel: DefaultProductChannelMapping[useTeamViewStore.getState().productName],
    })
    useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.UnRootCaused)
    const productId = useTeamViewStore.getState().computed.productId
    useTeamViewStore.getState().teams.setLastSelectedTeam(data.optionText, data.optionValue, true, productId)

    useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
  }, [])

  return (
    <Dropdown
      className={mergeClasses(styles.container, className)}
      onOptionSelect={onSelectChange}
      button={<Text className={styles.selectedOption}>{`${lastSelectedTeam}`}</Text>}
      selectedOptions={lastSelectedTeamId ? [String(lastSelectedTeamId)] : lastSelectedTeamId === '0' ? ['0'] : []}
    >
      {teamList.status === 'done' ? (
        <Column style={{ maxHeight: '70vh' }}>
          {(teamList.data ?? []).map((team) => (
            <Option key={team.id} value={String(team.id)}>
              {`${team.name}`}
            </Option>
          ))}
        </Column>
      ) : (
        <Spinner />
      )}
    </Dropdown>
  )
}
