import { Row } from '@copilot-dash/components'
import { Button, Input, tokens } from '@fluentui/react-components'
import { FluentIcon, Prompt24Filled, Prompt24Regular, SendRegular, bundleIcon } from '@fluentui/react-icons'
import { memo, useEffect, useState } from 'react'

import { useStyles } from './QueryBox.styles'
import { useFeedbackCopilotScreenActions } from '../../store'

interface IProps {
  readonly diableInput: boolean
  readonly defaultInput?: string
}
export const QueryBox = memo(({ diableInput, defaultInput }: IProps) => {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState('')
  const actions = useFeedbackCopilotScreenActions()
  const hoverIcon = Prompt24Filled
  const regularIcon = Prompt24Regular
  const isPromptEnabled = app.features.feedbackCopilotPrompts.use()

  useEffect(() => {
    if (defaultInput) {
      setInputValue(defaultInput)
    }
  }, [defaultInput])

  const onSubmit = () => {
    if (inputValue) {
      actions.submit(inputValue)
      setInputValue('')
    }
  }
  const getBundledIcon = (iconRegular: FluentIcon, iconHover: FluentIcon) => {
    const Icon = bundleIcon(iconHover, iconRegular)
    return <Icon />
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      actions.submit(inputValue)
      setInputValue('')
    }
  }

  return (
    <div className={styles.container}>
      {isPromptEnabled && (
        <Row vAlign="end" className={styles.prompt}>
          <Button icon={getBundledIcon(regularIcon, hoverIcon)} appearance="transparent" aria-label="View promts">
            View promts
          </Button>
        </Row>
      )}
      <Row style={{ width: '100%' }}>
        <Input
          className={styles.promptinput}
          value={inputValue}
          onChange={(_, data) => setInputValue(data.value)}
          onKeyDown={handleKeyDown}
          placeholder="Ask a question about feedback or insights"
          contentAfter={
            diableInput ? (
              <SendRegular
                style={{
                  cursor: 'not-allowed',
                  color: tokens.colorNeutralForeground3,
                }}
              />
            ) : (
              <SendRegular
                style={{
                  cursor: 'pointer',
                  color: tokens.colorNeutralForeground1,
                }}
                onClick={onSubmit}
              />
            )
          }
        />
      </Row>
    </div>
  )
})

QueryBox.displayName = 'QueryBox'
