import { Column } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Button, mergeClasses } from '@fluentui/react-components'
import { useStyles } from './ActivityHistoryCard.styles'
import { UserActivity } from './UserActivity'
import { useActivityHistoryPanelStore } from '../store/store'

export function ActivityHistoryCard() {
  const styles = useStyles()
  const activityHistories = useActivityHistoryPanelStore((state) => state.activityHistory).data

  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const onClick = (activity: IActivityHistory) => {
    if (activity?.fieldDiffs && Object.keys(activity.fieldDiffs).length > 0) {
      useActivityHistoryPanelStore.getState().setSelectedActivity(activity)
    }
  }

  return (
    <Column>
      {(activityHistories ?? []).map((activity) => (
        <Button
          className={mergeClasses(
            styles.button,
            activity.activityId === selectedActivity?.activityId && styles.selected,
          )}
          onClick={() => onClick(activity)}
          key={activity.activityId}
        >
          <UserActivity activity={activity} />
        </Button>
      ))}
    </Column>
  )
}
