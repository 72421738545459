import { Body, Column, Right, Row, Spacer } from '@copilot-dash/components'
import {
  AllSupportedAIFColumns,
  AllSupportedNonAIFColumns,
  INewTicketData,
  NonRemovableColumns,
  SearchTextPrefixType,
  UserpEnabledColumns,
  overViewTabsSchema,
} from '@copilot-dash/domain'
import { SelectTabData, SelectTabEvent } from '@fluentui/react-components'

import { ColDef } from 'ag-grid-community'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import { memo, useEffect, useRef, useState } from 'react'
import { useStyles } from './SearchScreenLayout.styles'
import { BATCH_UPDATE_TICKETS_PANEL_WIDTH } from '../../../components/BatchTicketsOperationPanel/BatchUpdateTicketsPanel.styles'
import { COLUMN_SETTING_PANEL_WIDTH } from '../../../components/ColumnSettingPanel/children/ColumnSettingPanelBody.styles'
import { ColumnSettingPanel } from '../../../components/ColumnSettingPanel/ColumnSettingPanel'
import { ITicketFilterFormRef } from '../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { FILTER_PANEL_WIDTH } from '../../../components/TicketsFilterPanel/layout/TicketsFilterPanelLayout.styles'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { SearchRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { IssueDetail } from '../children/feedbackInsights/issueDetail'
import { BatchOperationPanel } from '../children/panel/BatchOperationPanel'
import { FilterPanel } from '../children/panel/FilterPanel'
import { SearchScreenBody } from '../children/SearchScreenBody'
import { useSearchScreenActions, useSearchScreenState } from '../store'

export const SearchScreenLayout = memo(function SearchScreenLayout() {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form
  const columnsNonAIF = app.settings.searchScreenNonAIFColumns.use()
  const setColumnsNonAIF = app.settings.searchScreenNonAIFColumns.set

  const columnsAIF = app.settings.searchScreenAIFColumns.use()
  const setColumnsAIF = app.settings.searchScreenAIFColumns.set

  const columnsVipDSATs = app.settings.feedbackInsightsVipDSATsColumns.use()
  const setColumnsVipDSATs = app.settings.feedbackInsightsVipDSATsColumns.set

  const columnsTopIssueDetails = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const setColumnsTopIssueDetails = app.settings.feedbackInsightsTopIssueDetailsColumns.set

  const columnsUserp = app.settings.userpColumns.use()
  const setColumnsUserp = app.settings.userpColumns.set

  const formRef = useRef<ITicketFilterFormRef | null>(null)
  const [selectedColumns, setSelectedColumns] = useState<ColDef<INewTicketData>[]>([])
  const focusedTab = useSearchScreenState((state) => state.focusedTab)
  const issueId = SearchRoute.navigator.useArgsOptional()?.issueId
  const issueBatchId = SearchRoute.navigator.useArgsOptional()?.issueBatchId

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? form.defaultRange,
      })
    }
  }, [form])

  useEffect(() => {
    // NOTE: @Ethan - Reset the area list and root cause list when product changes
    // This is to avoid the issue of showing the wrong area list and root cause list when switching between products
    // Legacy - will be removed in V2 stage3
    useGlobalStore.getState().clearAreaListAndRootCauseList()
  }, [form.product])

  // Update selected columns based on the form
  useEffect(() => {
    const defaultColumns = AllSearchTableColumns(form.searchTextPrefix || SearchTextPrefixType.All, form.searchText)
    if (form.product === 'TenantAdminFeedback') {
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnsAIF.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )
      setSelectedColumns(newSelectedColumns)
    } else if (form.product === 'USERP') {
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnsUserp.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )
      setSelectedColumns(newSelectedColumns)
    } else {
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnsNonAIF.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )
      setSelectedColumns(newSelectedColumns)
    }
  }, [form.searchTextPrefix, form.searchText, form.product, columnsAIF, columnsNonAIF, form.tenantIds, columnsUserp])

  const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
    const value = overViewTabsSchema.safeParse(data.value).data
    if (value) {
      actions.updateFiltersPanelVisibility(false)
      actions.updateColumnSettingPanelVisibility(false)
      actions.updateFocusedTab(value)
    }
  }

  return (
    <motion.div
      layout
      animate={
        focusedTab === 'allFeedback' && state.isTicketsFilterPanelOpen
          ? 'filterOpen'
          : state.isColumnSettingPanelOpen
            ? 'columnSettingOpen'
            : state.isBatchOperationPanelOpen
              ? 'batchOperationOpen'
              : 'close'
      }
      className={styles.framerContainer}
    >
      <Row fill>
        <Spacer width="32px" />
        <Body className={styles.body}>
          <Column>
            <Body>
              {issueId && issueBatchId ? (
                <IssueDetail issueId={issueId} issueBatchId={issueBatchId} />
              ) : (
                <SearchScreenBody
                  selectedColumns={selectedColumns}
                  selectedValue={focusedTab}
                  onTabSelect={onTabSelect}
                  setColumns={
                    focusedTab === 'feedbackInsights'
                      ? issueId
                        ? setColumnsTopIssueDetails
                        : setColumnsVipDSATs
                      : form.product === 'TenantAdminFeedback'
                        ? setColumnsAIF
                        : form.product === 'USERP'
                          ? setColumnsUserp
                          : setColumnsNonAIF
                  }
                />
              )}
            </Body>
          </Column>
        </Body>
        <Spacer width="12px" />
        <motion.div
          layout
          variants={{
            close: { width: 0 },
            filterOpen: { width: FILTER_PANEL_WIDTH },
            columnSettingOpen: { width: COLUMN_SETTING_PANEL_WIDTH },
            batchOperationOpen: { width: BATCH_UPDATE_TICKETS_PANEL_WIDTH },
          }}
          className={styles.framerPanel}
          initial={false}
        >
          <Right className={styles.right}>
            <Column fill>
              <FilterPanel />
              <ColumnSettingPanel
                isPanelOpen={state.isColumnSettingPanelOpen}
                onDismiss={() => actions.updateColumnSettingPanelVisibility(false)}
                NonRemovableColumns={NonRemovableColumns}
                AllSupportedColumns={
                  form.product === 'TenantAdminFeedback'
                    ? AllSupportedAIFColumns
                    : form.product === 'USERP'
                      ? UserpEnabledColumns
                      : AllSupportedNonAIFColumns
                }
                selectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? columnsTopIssueDetails
                      : columnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? columnsAIF
                      : form.product === 'USERP'
                        ? columnsUserp
                        : columnsNonAIF
                }
                setSelectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? setColumnsTopIssueDetails
                      : setColumnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? setColumnsAIF
                      : form.product === 'USERP'
                        ? setColumnsUserp
                        : setColumnsNonAIF
                }
              />
              <BatchOperationPanel />
            </Column>
          </Right>
        </motion.div>
      </Row>
    </motion.div>
  )
})
