import { makeStyles, Text, Toolbar } from '@fluentui/react-components'
import { Spacer } from '@copilot-dash/components'
import { AnyTableStore } from '../../AnyTableStore'

export function Footer() {
  const styles = useStyles()
  const count = AnyTableStore.use((state) => {
    return Array.isArray(state.data) ? state.data.length : 0
  })

  const searchResultCount = AnyTableStore.use((state) => {
    return state.searchResultCount
  })

  return (
    <Toolbar className={styles.root}>
      <Text>Total entries: {searchResultCount ?? count}</Text>
      <Spacer />
    </Toolbar>
  )
}

const useStyles = makeStyles({
  root: {
    minHeight: '44px',
    paddingLeft: '16px',
  },
})
