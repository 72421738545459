import { ApiGetQueryResponse, ApiUpdateQueryRequest, ApiCreateQueryRequest } from '@copilot-dash/api'
import { CopilotDashPath, IQuery, IQueryUpdatePrams, IQueryCollection, ProductIds, TeamId } from '@copilot-dash/domain'

export class QueryDataConverter {
  static fromUpdateOrGetQueryApi(api: ApiGetQueryResponse): IQuery {
    const query = api.queries[0]
    if (!query) {
      throw new Error(`Query not found`)
    }
    return {
      queryId: query.queryId,
      title: query.title,
      productId: String(query.productId) as ProductIds,
      isTeamShared: query.isTeamShared,
      teamId: query.teamId === -1 ? undefined : (String(query.teamId) as TeamId),
      userId: query.userId,
      createdBy: query.createdBy,
      createdTime: query.createdTime,
      lastModifiedBy: query.lastModifiedBy,
      lastModifiedTime: query.lastModifiedTime,
      copilotDashQuery: query.copilotDashQuery,
      copilotDashPath: query.copilotDashPath.includes('search') ? CopilotDashPath.search : CopilotDashPath.team,
      filterPayload: query.filterPayload,
      oDataQuery: query.oDataQuery,
    }
  }

  static fromGetQueriesApi(api: ApiGetQueryResponse): IQueryCollection {
    const queries = api.queries.map((query) => ({
      queryId: query.queryId,
      title: query.title,
      productId: String(query.productId) as ProductIds,
      isTeamShared: query.isTeamShared,
      teamId: query.teamId === -1 ? undefined : (String(query.teamId) as TeamId),
      userId: query.userId,
      createdBy: query.createdBy,
      createdTime: query.createdTime,
      lastModifiedBy: query.lastModifiedBy,
      lastModifiedTime: query.lastModifiedTime,
      copilotDashQuery: query.copilotDashQuery,
      copilotDashPath: query.copilotDashPath.includes('search') ? CopilotDashPath.search : CopilotDashPath.team,
      filterPayload: query.filterPayload,
      oDataQuery: query.oDataQuery,
    }))

    return {
      queries: queries.sort((a, b) => this.compareDates(a.lastModifiedTime, b.lastModifiedTime)),
    }
  }

  static updateDataToUpdateApi(options: IQueryUpdatePrams): ApiUpdateQueryRequest {
    return {
      Title: options.title,
      CopilotDashQuery: options.copilotDashQuery,
      FilterPayload: options.filterPayload,
      ODataQuery: options.oDataQuery,
    }
  }

  static updateDataToCreateApi(options: IQueryUpdatePrams): ApiCreateQueryRequest {
    return {
      Title: options.title,
      ProductId: Number(options.productId),
      IsTeamShared: options.isTeamShared,
      TeamId: Number(options.teamId),
      CopilotDashQuery: options.copilotDashQuery,
      CopilotDashPath: options.copilotDashPath,
      FilterPayload: options.filterPayload,
      ODataQuery: options.oDataQuery,
    }
  }

  private static compareDates(a: string, b: string): number {
    try {
      const dateA = new Date(a).getTime()
      const dateB = new Date(b).getTime()
      return isNaN(dateA) || isNaN(dateB) ? 0 : dateB - dateA
    } catch {
      return 0
    }
  }
}
