import { TicketCallFlow } from '@copilot-dash/domain'
import { Node } from '@xyflow/react'
import { compact } from 'lodash'
import { generateNode } from './generateNode'
import { CallFlowNode } from '../store/IState'

export function generateNodes(
  turnId: string,
  data: TicketCallFlow,
  selectedServiceIds: Array<string>,
): Node<CallFlowNode>[] {
  const eventIds = new Set<string>()

  const nodes = data.events.map((event) => {
    if (eventIds.has(event.id)) {
      return
    }

    eventIds.add(event.id)
    return generateNode(turnId, event)
  })

  return compact(nodes).filter((node) => {
    return selectedServiceIds.length === 0 || selectedServiceIds.includes(node.data.item.serviceId)
  })
}
