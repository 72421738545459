import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { $generateHtmlFromNodes } from '@lexical/html'
import { $getRoot, LexicalEditor } from 'lexical'
import { MutableRefObject } from 'react'
import { IActions } from './IActions'
import { IState } from './IState'
import { isEditorEmpty } from '../utils/lexical'

interface IParams {
  readonly get: IStoreGetter<IState>
  readonly set: IStoreSetter<IState>
  readonly onSubmitRef: MutableRefObject<(value: string) => Promise<void>>
  readonly onCancelRef: MutableRefObject<(() => void) | undefined>
}

export function createActions({ get, set, onSubmitRef, onCancelRef }: IParams): IActions {
  let instance: LexicalEditor | undefined = undefined

  return {
    prepare(editor) {
      instance = editor
    },

    async submit() {
      set((state) => {
        state.isSubmitting = true
        state.initialValue = undefined
      })

      try {
        const htmlString = readHtmlString()
        await onSubmitRef.current(htmlString ?? '')
        clearEditor()

        set((state) => {
          state.isSubmitting = false
        })
      } catch (error) {
        set((state) => {
          state.isSubmitting = false
        })
      }
    },

    cancel() {
      onCancelRef.current?.()
    },

    uploading() {
      instance?.setEditable(false)
      set((state) => {
        state.isUploading = true
      })
    },

    uploaded() {
      set((state) => {
        state.isUploading = false
      })
      instance?.setEditable(true)
    },
  }

  function readHtmlString(): string | undefined {
    if (!instance) {
      return undefined
    }

    return instance.read(() => {
      if (isEditorEmpty(instance!.getEditorState())) {
        return undefined
      }

      return $generateHtmlFromNodes(instance!)
    })
  }

  function clearEditor() {
    instance?.update(() => {
      $getRoot().clear()
    })
  }
}
