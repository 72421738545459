import { PromiseSnapshot } from '@copilot-dash/core'
import { IM365TenantItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTenant(context: IDashStoreContext, tenantId: string): PromiseSnapshot<IM365TenantItem> {
  return context.getOrFetch<IM365TenantItem>({
    get: (state) => {
      return state.basic.tenants[tenantId]
    },
    set: (state, snapshot) => {
      state.basic.tenants[tenantId] = snapshot
    },
    fetch: async () => {
      const getTenantList = async () => {
        if (!context.enableV2Endpoint) {
          const response = await context.api.logCollector.getM365TenantList({ Keyword: tenantId, Limit: 20 })
          return response.m365TenantList
        } else {
          return await context.api.copilotDash.getTenantList(tenantId, 20)
        }
      }

      const tenants = await getTenantList()

      if (tenants.length === 0 || tenants.length > 1) {
        throw Error('No tenant found')
      }

      return tenants[0]!
    },
  })
}
