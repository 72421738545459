export enum LanguageTierSlicers {
  'Tier 1' = 'Tier 1',
  'Tier 2' = 'Tier 2',
  'Tier 3' = 'Tier 3',
  Others = 'Others',
}

export interface LocaleSlicer {
  readonly languageTier: LanguageTierSlicers
  readonly locales: string[]
}

export const LocaleSlicers: LocaleSlicer[] = [
  {
    languageTier: LanguageTierSlicers['Tier 1'],
    locales: [
      'en-us',
      'ja-jp',
      'en-gb',
      'fr-fr',
      'es-es',
      'zh-cn',
      'de-de',
      'pt-br',
      'it-it',
      'fr-ca',
      'es-mx',
      'en-in',
      'en-ca',
      'en-au',
    ],
  },
  {
    languageTier: LanguageTierSlicers['Tier 2'],
    locales: [
      'zh-tw',
      'ko-kr',
      'sv-se',
      'da-dk',
      'nl-nl',
      'fi-fi',
      'nb-no',
      'cs-cz',
      'pl-pl',
      'pt-pt',
      'he-il',
      'tr-tr',
      'hu-hu',
      'ru-ru',
      'ar-sa',
      'th-th',
      'nl-be',
      'sv-fi',
    ],
  },
  {
    languageTier: LanguageTierSlicers['Tier 3'],
    locales: [
      'sk-sk',
      'sl-si',
      'uk-ua',
      'el-gr',
      'vi-vn',
      'sr-latn-rs',
      'lt-lt',
      'et-ee',
      'lv-lv',
      'hr-hr',
      'id-id',
      'ro-ro',
      'bg-bg',
    ],
  },
  {
    languageTier: LanguageTierSlicers.Others,
    locales: ['Others'],
  },
]
