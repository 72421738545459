import { Row } from '@copilot-dash/components'
import { IRootCauseData } from '@copilot-dash/domain'
import { translateRootCausePriority } from '@copilot-dash/store'
import { makeStyles, Text } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { memo } from 'react'

export const Priority = memo((props: { rowData: IRootCauseData }) => {
  const { rowData } = props
  const styles = useStyles()

  return (
    <Row vAlign="center">
      <Text wrap className={styles.title}>
        {isNil(rowData.rootCausePriority) ? '' : translateRootCausePriority(rowData.rootCausePriority)}
      </Text>
    </Row>
  )
})

Priority.displayName = 'Priority'

const useStyles = makeStyles({
  title: {
    wordBreak: 'break-word',
  },
})
