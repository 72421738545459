import { Body1Stronger } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { PromptDetailsCardFilters } from './config'
import { useTicketFilterStore } from '../../store/store'
import {
  ChatModeFilter,
  GroundedPromptsFilter,
  InvocationSlicerFilter,
  InvocationTypeFilter,
  LanguageFilter,
} from '../filters'
import { UtteranceGroupAndScenarioSlicerFilter } from '../filters/UtteranceGroupAndScenarioSlicerFilter'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function PromptDetailsCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

  const isInvocationSlicerEnable = useTicketFilterStore((state) => state.isInvocationSlicerEnable)

  const hasPromptDetailsCard = useMemo(() => {
    return filterPanelItems.some((item) => PromptDetailsCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasPromptDetailsCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Prompt details</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems?.includes('promptLanguages') && (
          <Controller
            name="promptLanguages"
            control={control}
            render={({ field }) => <LanguageFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('groundedPrompts') && (
          <Controller
            name="groundedPrompts"
            control={control}
            render={({ field }) => (
              <GroundedPromptsFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {!isInvocationSlicerEnable && filterPanelItems?.includes('invocationType') && (
          <Controller
            name="invocationType"
            control={control}
            render={({ field }) => <InvocationTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {isInvocationSlicerEnable && filterPanelItems?.includes('invocationSlicers') && (
          <Controller
            name="invocationSlicers"
            control={control}
            render={({ field }) => (
              <InvocationSlicerFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {filterPanelItems?.includes('chatMode') && (
          <Controller
            name="chatMode"
            control={control}
            render={({ field }) => <ChatModeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('scenarioSlicers') && <UtteranceGroupAndScenarioSlicerFilter control={control} />}
      </Card>
    </section>
  )
}
