import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect, useMemo } from 'react'
import { AnyTableColDef } from './AnyTableTypes'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { IState } from './store/IState'
import { generateAnyColumn } from './utils/generateAnyColumn'
import { generateAnyColumnKeys } from './utils/generateAnyColumnKeys'

interface IProps<T> {
  readonly data: T[]
  readonly columns: AnyTableColDef<T>[]
  readonly filter: string | undefined
  readonly children: ReactNode
}

export function AnyTableStore<T>({ data, columns, filter, children }: IProps<T>) {
  const generatedColumns = useMemo(() => {
    const keys = generateAnyColumnKeys<T>({
      data: data,
      columns,
    })

    return keys.map((key) => {
      return generateAnyColumn({ key, items: data, def: columns.find((column) => column.key === key) })
    })
  }, [data, columns])

  const store = useStore<IState, IActions>((set, get) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: createState({ data, columns: columns as any, filter }),
    actions: createActions({ set, get }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.data = data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.columns = generatedColumns as any
      state.filter = filter
    })
  }, [store, data, generatedColumns, filter])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()

AnyTableStore.use = Context.use
AnyTableStore.useActions = Context.useActions
