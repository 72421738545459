import { PromiseSnapshots } from '@copilot-dash/core'
import { FeedbackId, INewTicketData, ITicketData, IUpdateWorkItem } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function batchUpdateTickets(context: IDashStoreContext, tickets: INewTicketData[]): Promise<void> {
  context.use.setState((state) => {
    tickets.forEach((ticket) => {
      const item = (state.tickets[ticket.ticketId] ??= {})
      item.ticket = PromiseSnapshots.done(ticket)
    })
  })
}

export async function batchUpdateTicketsByWorkItems(
  context: IDashStoreContext,
  workItems: Array<IUpdateWorkItem & { feedbackId: FeedbackId }>,
): Promise<void> {
  context.use.setState((state) => {
    const awaitUpdateTickets: INewTicketData[] = []
    workItems.forEach((workItem) => {
      const { feedbackId, ...workItemData } = workItem
      if (state.tickets[feedbackId]?.ticket?.status === 'done') {
        const oldData: ITicketData | undefined = state.tickets[feedbackId]?.ticket?.data
        if (oldData) {
          const newData: INewTicketData = {
            ...oldData,
            ticketId: oldData?.ticketId ?? feedbackId,
            workItemId: workItemData.workItemId,
            teamId: workItemData.teamId,
            rootCauseIds: workItemData.rootCauseIdList,
            priority: workItemData.priority
              ? workItemData.priority.startsWith('P') && workItemData.priority[1]
                ? parseInt(workItemData.priority[1])
                : undefined
              : undefined,
            workItemStatus: workItemData.dsatStatus,
            assignTo: workItemData.assignTo,
          }
          awaitUpdateTickets.push(newData)
        }
      }
    })
    if (awaitUpdateTickets.length > 0) {
      awaitUpdateTickets.forEach((ticket) => {
        const item = (state.tickets[ticket.ticketId] ??= {})
        item.ticket = PromiseSnapshots.done(ticket)
      })
    }
  })
}
