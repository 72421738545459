import { ApiV2Ticket, ApiV2TicketSource, ApiV2TicketSourceType } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketDiagnosticData, ITicketDiagnosticSource } from '@copilot-dash/domain'
import { compact, sortBy, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

type Definition = {
  group: string
  template: 'group' | 'kusto'
  subtitle?: string
}

const definitions: Record<string, Definition> = {
  Conversation: {
    group: 'Conversation',
    template: 'group',
  },
  USERP: {
    group: 'USERP',
    template: 'group',
  },
  SubstrateSearchOnline: {
    group: '3S Online',
    template: 'group',
  },
  SubstrateSearchOffline: {
    group: '3S Offline',
    template: 'group',
  },

  // Kusto logs
  SubstrateSearchInfo: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  BizChat3SLatency: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  BizchatPerformanceEventV4: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  LLM: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  AugLoop: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  SydneyTuringBotMonitoredScope: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  SydneyTuringBotTraceMDS: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  SydneyTuringBotChatMessageEventMDS: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
  MetaOSClientEvent: {
    group: 'Kusto Logs',
    template: 'kusto',
  },
} satisfies Partial<Record<ApiV2TicketSourceType, Definition>>

export function getTicketDiagnosticData(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketDiagnosticData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.diagnostic
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.diagnostic = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise
      return convert(raw)
    },
  })
}

function convert(raw: ApiV2Ticket): ITicketDiagnosticData {
  const sources = raw.sources?.map(generate) ?? []

  const types = uniq([
    ...Object.values(definitions).map((item) => item.group), //
    ...compact(sources.map((source) => source.group)),
  ])

  const groups = types.map((type) => {
    const sourcesInThisGroup = sources.filter((source) => source.group === type)
    const sourcedSorted = sortBy(sourcesInThisGroup, (source) => {
      return source.raw.relatedId ?? '' + source.title
    })

    return {
      name: type,
      sources: sourcedSorted,
    }
  })

  return {
    groups: groups.filter((group) => group.sources.length > 0),
    raw,
  }
}

function generate(source: ApiV2TicketSource): ITicketDiagnosticSource & { group: string; raw: ApiV2TicketSource } {
  const definition = definitions[source.type]
  if (!definition) {
    return {
      group: 'Other',
      title: source.name ?? source.url,
      subtitle: source.relatedId || undefined,
      url: source.url,
      raw: source,
    }
  }

  switch (definition.template) {
    case 'group':
      return {
        group: definition.group,
        title: `${source.type} - Group${source.complianceLevel}`,
        subtitle: source.relatedId,
        url: source.url,
        raw: source,
      }
    case 'kusto':
      return {
        group: definition.group,
        title: `${source.type}`,
        subtitle: source.relatedId,
        url: source.url,
        raw: source,
      }
  }
}
