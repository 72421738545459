import { Overlays } from '@copilot-dash/components'
import { Route } from 'react-router-dom'
import { TicketRouteArgs } from './TicketRouteArgs'
import { TicketRouteMapper } from './TicketRouteMapper'
import { TicketRoutePage } from './TicketRoutePage'
import { TicketScreenDialog } from '../../../screens/ticket/TicketScreenDialog'
import { createNavigator } from '../../navigator/createNavigator'

export function TicketRoute() {
  return <Route id={ID} path={PATH} element={<TicketRoutePage />} />
}

const ID = 'ticket'
const PATH = '/ticket/:id'

TicketRoute.navigator = Object.assign(createNavigator(ID, PATH, TicketRouteMapper), {
  navigateToTicketModal: (args: TicketRouteArgs) => {
    Overlays.open({
      content: (open, onClose) => {
        return <TicketScreenDialog ticketId={args.id} open={open} onClose={onClose} />
      },
    })
  },
})
