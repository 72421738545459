import { MonacoView } from '@copilot-dash/components'
import { IMcpExperimentFeedbackResponse } from '@copilot-dash/domain'
import FeedbackBarChart from './McpExperimentFeedbackBarChart'
import { useStyles } from './McpExperimentFeedbackTrend.styles'

interface IProps {
  data: IMcpExperimentFeedbackResponse
  window: string
}

const FeedbackTrend: React.FC<IProps> = ({ data, window }) => {
  const styles = useStyles()

  const controlData = data.controlFeedbacks
  const treatmentData = data.treatmentFeedbacks
  const allData = data.allFeedbacks
  const satRatioData = data.satRatioFeedbacks
  const satRatioCanvasData = data.byCanvasFeedbacks
  const feedbackPromptData = data.byPromptFeedbacks

  return (
    <>
      <div className={styles.chartContainer}>
        <div className={styles.chartItem}>
          <h2 className={styles.labelItem}>Feedback Trend (Treatment)</h2>
          {!!treatmentData && <FeedbackBarChart data={treatmentData} window={window} />}
        </div>
        <div className={styles.chartItem}>
          <h2 className={styles.labelItem}>Feedback Trend (Control)</h2>
          {!!controlData && <FeedbackBarChart data={controlData} window={window} />}
        </div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.tableItem}>
          <h2 className={styles.labelItem}>All Feedback</h2>
          <MonacoView data={allData} />
        </div>
        <div className={styles.tableItem}>
          <h2 className={styles.labelItem}>SAT Ratio</h2>
          <MonacoView data={satRatioData} />
        </div>
        <div className={styles.tableItem}>
          <h2 className={styles.labelItem}>Feedback By Prompt</h2>
          <MonacoView data={feedbackPromptData} />
        </div>
        <div className={styles.tableItem}>
          <h2 className={styles.labelItem}>Feedback By Canvas</h2>
          <MonacoView data={satRatioCanvasData} />
        </div>
      </div>
    </>
  )
}
export default FeedbackTrend
