import { ICellRendererParams } from 'ag-grid-community'
import { ReactElement, useMemo } from 'react'
import { ValueViewForBoolean } from './ValueViewForBoolean'
import { ValueViewForCustom } from './ValueViewForCustom'
import { ValueViewForEmpty } from './ValueViewForEmpty'
import { ValueViewForEnum } from './ValueViewForEnum'
import { ValueViewForNumber } from './ValueViewForNumber'
import { ValueViewForString } from './ValueViewForString'
import { ValueViewForStringDatetime } from './ValueViewForStringDatetime'
import { ValueViewForStringEmail } from './ValueViewForStringEmail'
import { ValueViewForStringLink } from './ValueViewForStringLink'
import { ValueViewForUnknown } from './ValueViewForUnknown'
import { AnyPropertyItem } from '../../../AnyPropertyType'

export function ValueView({ data }: ICellRendererParams<AnyPropertyItem>) {
  const item = data!

  return useMemo((): ReactElement => {
    if (item.value === undefined || item.value === null || item.value === '') {
      return <ValueViewForEmpty value={item.value} />
    }

    if (typeof item.value === 'object') {
      if (Array.isArray(item.value)) {
        if (item.value.length === 0) {
          return <ValueViewForEmpty value={[]} />
        }
      } else {
        if (Object.keys(item.value).length === 0) {
          return <ValueViewForEmpty value={{}} />
        }
      }
    }

    switch (item.type) {
      case 'string':
        return <ValueViewForString value={item.value} />
      case 'datetime':
        return <ValueViewForStringDatetime value={item.value} />
      case 'number':
        return <ValueViewForNumber value={item.value} />
      case 'boolean':
        return <ValueViewForBoolean value={item.value} />
      case 'empty':
        return <ValueViewForEmpty value={item.value} />
      case 'enum':
        return <ValueViewForEnum value={item.value} />
      case 'json':
        return <ValueViewForUnknown value={item.value} />
      case 'custom':
        return <ValueViewForCustom value={item.value} />
      case 'email':
        return <ValueViewForStringEmail value={item.value} />
      case 'link':
        return <ValueViewForStringLink value={item.value} />
    }
  }, [item.type, item.value])
}
