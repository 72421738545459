import { PromiseSnapshot } from '@copilot-dash/core'
import { ITeamData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getAllTeams(context: IDashStoreContext): PromiseSnapshot<ITeamData[]> {
  return context.getOrFetch<ITeamData[]>({
    get: (state) => {
      return state.team.allTeams
    },
    set: (state, snapshot) => {
      state.team.allTeams = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getTeamListFlatten()
      return response
        .map((team) => ({
          id: String(team.teamId),
          name: team.teamName,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  })
}
