import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)`,
    borderRadius: '4px',
    overflow: 'auto',
    minWidth: '100%',
    backgroundColor: tokens.colorNeutralBackground1,
  },

  table: {
    width: '100%',
    minWidth: 'fit-content',
    padding: '12px',
    paddingTop: '0px',
  },

  title: {
    width: '100%',
    padding: '15px',
    paddingBottom: '10px',
    borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
  },

  tableRow: {
    borderBottomWidth: 0,
    padding: '6px 0',
  },
  finetueButton: {
    height: '40px',
    backgroundColor: tokens.colorCompoundBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    padding: '0 16px',
  },
  clusterButton: {
    height: '40px',
    padding: '0 16px',
  },
  pointer: {
    cursor: 'pointer',
  },
  loadMoreBtn: {
    color: tokens.colorBrandForeground1,
    marginLeft: '126px',
    marginBottom: '24px',
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '24px',
    height: '52px',
    borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
    backgroundColor: tokens.colorNeutralBackground2,
  },
  parkle: {
    width: '20px',
    height: '20px',
    marginRight: '3px',
    verticalAlign: 'middle',
  },
  text: {
    color: tokens.colorNeutralForeground3,
  },
})
