import { Row, Spacer } from '@copilot-dash/components'
import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { RocketFilled } from '@fluentui/react-icons'
import { ReactNode } from 'react'
import { HeaderRingText } from './HeaderRingText'
import { HomeRoute, RouteLink } from '../../../../router'

const BRAND_NAME = 'M365 Copilot Dash'

const useStyles = makeStyles({
  icon: {
    color: tokens.colorBrandForeground1,
    width: '32px',
    height: '32px',
  },
  brand: {
    maxWidth: '100%',
    minWidth: '50px',
  },
})

export function HeaderBrandView(): ReactNode {
  const styles = useStyles()
  const homeRoutePath = HomeRoute.navigator.generatePath()

  return (
    <RouteLink path={homeRoutePath} reloadDocument={true} className={styles.brand}>
      <Row hAlign="start" vAlign="center">
        <RocketFilled aria-label="Rocket" className={styles.icon} />
        <Spacer width="12px" />
        <Text wrap={false} size={500} weight="semibold" truncate>
          {BRAND_NAME}
        </Text>
        <HeaderRingText />
      </Row>
    </RouteLink>
  )
}
