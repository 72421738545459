import { Row } from '@copilot-dash/components'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewCardErrorIcon({ components }: IProps) {
  const styles = useStyles()

  let levelClass = ''
  switch (components.level) {
    case 'ERROR':
      levelClass = styles.error
      break
    case 'WARNING':
      levelClass = styles.warning
      break
    case 'OK':
      levelClass = styles.success
      break
  }

  return <Row className={mergeClasses(levelClass)}>{components.icon}</Row>
}

const useStyles = makeStyles({
  success: {
    fontSize: '32px',
    color: tokens.colorStatusSuccessForeground1,
  },
  warning: {
    fontSize: '32px',
    color: tokens.colorNeutralForeground3,
  },
  error: {
    fontSize: '48px',
    color: tokens.colorStatusWarningForeground1,
  },
})
