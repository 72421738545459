import { SearchTicketResult } from '@copilot-dash/domain'
import { batchUpdateTickets } from './batchUpdateTickets'
import { IDashStoreContext } from '../../IDashStoreContext'
import { SearchTicketAction } from '../actions-search/SearchTicketAction'

const MAX_LIMIT = 1000

export async function fetchTicketsByTicketIdAndUpdateStore(
  context: IDashStoreContext,
  ticketIds: string[],
): Promise<SearchTicketResult> {
  return context.requestAnd({
    request: () => {
      const options = { id: ticketIds, offset: 0, count: MAX_LIMIT }
      return new SearchTicketAction(context).search(options)
    },
    onSuccess: (_, response) => {
      batchUpdateTickets(context, response.tickets)
    },
  })
}
