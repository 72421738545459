import { z } from 'zod'

export interface ApiV2Filter {
  readonly name: string
  readonly parent?: string
  readonly displayName: string
  readonly type: 'multiSelectList' | 'singleSelectList' | 'singleSelectCheckbox' | 'radio'
  readonly options: Array<APIV2FilterOption>
}

export interface APIV2FilterOption {
  readonly key: string
  readonly displayName: string
  readonly parentKey?: string
  readonly groupName?: string
  readonly enable: boolean
}

export interface ApiV2GetFilterConfigResponse {
  readonly filterConfig: FilterConfig
}

export interface FilterConfig {
  readonly version: string
  readonly filters: Array<ApiV2Filter>
}

const apiV2FilterOptionSchema = z.object({
  key: z.string(),
  displayName: z.string(),
  parentKey: z.string().optional(),
  groupName: z.string().optional(),
  enable: z.boolean(),
})

const apiV2FilterSchema = z.object({
  name: z.string(),
  parent: z.string().optional(),
  displayName: z.string(),
  type: z.union([
    z.literal('multiSelectList'),
    z.literal('singleSelectList'),
    z.literal('singleSelectCheckbox'),
    z.literal('radio'),
  ]),
  options: z.array(apiV2FilterOptionSchema),
})

const apiV2FilterConfigSchema = z.object({
  version: z.string(),
  filters: z.array(apiV2FilterSchema),
})

export const apiV2GetFilterConfigResponseSchema = z.object({
  filterConfig: apiV2FilterConfigSchema,
})
