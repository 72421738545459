import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'
import { TimeView } from '../../../../../components/Time/TimeView'

export const ColumnWorkflowStartTimestamp: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.WorkflowStartTimestamp],
  field: String(JobColumnKey.WorkflowStartTimestamp) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles>
        <TimeView value={props.data.workflowStartTimestamp} format="YYYY/MM/DD HH:mm" />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>Create on</TableCellLayoutWithStyles>,
  },
  minWidth: 200,
  width: 200,
  maxWidth: 200,
  sortable: true,
  resizable: true,
}
