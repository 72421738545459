import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const messageId = params.messageId

  return `
TuringBotChatMessageEventMDS_NoEUPI
| where MessageId in ('${messageId}')
| distinct *
| sort by TIMESTAMP asc
`
}

export const SydneyTuringBotChatMessageEventMDS_WW: IKustoQueryDefinition = {
  database: 'CompliantSydneyLogs',
  connection: 'https://compliantsydney.eastus.kusto.windows.net',
  query,
}
