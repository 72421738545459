import { ReactNode, useState } from 'react'
import { MonacoView } from '../monaco/MonacoView'
import { MonacoLanguage } from '../monaco/MonacoViewTypes'
import { MonacoToolbar } from '../monaco-toolbar/MonacoToolbar'
import { TabItemToolbar } from '../tab-item/TabItemToolbar'

interface IProps {
  readonly language?: MonacoLanguage
  readonly data: unknown
  readonly toolbar?: ReactNode
}

export function TabItemForMonaco({ language = 'json', data, toolbar }: IProps): ReactNode {
  const [wrap, setWrap] = useState(true)
  const [minimap, setMinimap] = useState(false)
  const [editable, setEditable] = useState(false)

  return (
    <>
      <TabItemToolbar>
        <MonacoToolbar
          wrap={wrap}
          setWrap={setWrap}
          minimap={minimap}
          setMinimap={setMinimap}
          editable={editable}
          setEditable={setEditable}
        >
          {toolbar}
        </MonacoToolbar>
      </TabItemToolbar>
      <MonacoView language={language} data={data} wrap={wrap} minimap={minimap} editable={editable} />
    </>
  )
}
