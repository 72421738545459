import { Column } from '@copilot-dash/components'
import { useRouteError } from 'react-router-dom'
import { ErrorView } from '../../components/Error'

export function RootRouteError() {
  const error = useRouteError()

  return (
    <Column vAlign="center" hAlign="center" fill>
      <ErrorView error={error} />
    </Column>
  )
}
