import { Column, Row } from '@copilot-dash/components'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { Image, Text, makeStyles, tokens } from '@fluentui/react-components'

import { CopilotMessageContext } from './CopilotMessageContext'
import FeedbackCopilotBrand from '../../assets/FeedbackCopilotBrand.svg'

interface IProps {
  readonly data: IFCConversationMessageData
}

export function CopilotMessageTile({ data }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root}>
      <div className={styles.avatarDiv}>
        <Image src={FeedbackCopilotBrand} className={styles.avatar} />
      </div>
      <Text className={styles.aiContext}>AI-generated</Text>
      <Column className={styles.content} hAlign="stretch">
        <CopilotMessageContext
          defaultResponse={data.botDefaultResponse}
          queryStatus={data.queryStatus}
          queryResult={data.queryResult}
        />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
    backgroundColor: 'transparent',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoRows: 'max-content',
    gridTemplateColumns: 'max-content min-content 1fr',
    justifyItems: 'start',
    position: 'relative',
  },
  aiContext: {
    gridColumn: 2,
    alignContent: 'center',
    fontSize: '10px',
    color: tokens.colorNeutralForeground3,
    backgroundColor: tokens.colorNeutralBackground4Pressed,
    minWidth: '71px',
    fontWeight: tokens.fontWeightRegular,
    display: 'inline-block',
    borderRadius: '4px',
    textWrap: 'nowrap',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  avatarDiv: {
    gridColumn: 1,
  },
  avatar: {
    width: '24px',
    height: '24px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    width: '-webkit-fill-available',
    padding: '0px',
    gridColumn: '1 / 4',
    flexDirection: 'column',
    textAlign: 'start',
  },
})
