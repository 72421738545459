import { Row, Spacer } from '@copilot-dash/components'
import {
  ColumnKey,
  DefaultProductChannelMapping,
  INewTicketData,
  SearchTextPrefixType,
  TeamViewSubMenuIds,
  getProductIdByName,
} from '@copilot-dash/domain'
import { Dropdown, Option, OptionOnSelectData, SelectionEvents, Text } from '@fluentui/react-components'
import { CalendarRegular } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { compact } from 'lodash'
import * as React from 'react'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForTopIssues.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { TopIssueTickets } from './TopIssueTickets'
import { IAGGridTableRef } from '../../../components/AGGridTable/AGGridTable'
import { ErrorView } from '../../../components/Error'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { TimeView } from '../../../components/Time/TimeView'
import { TeamRoute } from '../../../router'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'
import { useTeamViewStore } from '../store'

interface ITopIssuesTicketsProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  readonly columnSetting: ColumnKey[]
  readonly setColumnSetting: (columns: ColumnKey[]) => void
}

export const TicketsTableForTopIssues: React.FC<ITopIssuesTicketsProps> = React.memo(
  ({ onRowClick, clickedTicketKey, columnSetting, setColumnSetting }) => {
    useClaritySectionId(ClarityScenarioIds.menuTopIssues)
    const tablesRef = React.useRef<{
      [key: string]: IAGGridTableRef | null
    }>({})

    const currentArgs = useTeamViewStore((state) => state.route.args)
    const selectedBatchId = currentArgs?.topIssueBatchId
    const productName = currentArgs?.product
    const productId = getProductIdByName(productName)

    const topIssueListResponse = useTeamViewStore((state) => state.topIssues.topIssueList)
    const issueClusteringBatchList = useTeamViewStore((state) => state.topIssues.issueClusteringBatchList)
    const topIssueBatchList = issueClusteringBatchList.data ?? []

    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const styles = useStyles()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

    const topIssueTicketIds = useTeamViewStore((state) => state.tickets.topIssueTicketIds)
    const isAllIssuesTicketIsEmpty = React.useMemo(() => {
      let isEmpty = false
      let accumulatedCount = 0
      if (!selectedBatchId) return false
      if (topIssueListResponse.status !== 'done') return false
      for (const batchIssueKey of Object.keys(topIssueTicketIds)) {
        if (!batchIssueKey.startsWith(selectedBatchId)) continue
        const snapshot = topIssueTicketIds[batchIssueKey]
        if (snapshot?.status === 'done') {
          accumulatedCount += snapshot.data.ticketCount
        } else {
          return false
        }
      }
      if (accumulatedCount === 0) {
        isEmpty = true
      }
      return isEmpty && topIssueListResponse.data.length > 0
    }, [selectedBatchId, topIssueListResponse, topIssueTicketIds])

    const tableColumns = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, searchText])

    const headerText = 'Top Issues'

    const currentSelectedGroupKeyRef = React.useRef('')
    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined, groupKey: string) => {
      if (groupKey !== currentSelectedGroupKeyRef.current) {
        tablesRef.current[currentSelectedGroupKeyRef.current]?.clearSelection()
      }
      currentSelectedGroupKeyRef.current = groupKey
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel((selectedItems ?? [])?.length > 0)
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const handleBatchChange = React.useCallback(
      (event: SelectionEvents, data: OptionOnSelectData) => {
        if (data.optionValue === selectedBatchId) return
        useTeamViewStore.getState().topIssues.clearTopIssueList()
        const currentArgs = useTeamViewStore.getState().route.args
        TeamRoute.navigator.navigate({
          product: currentArgs.product,
          subMenu: currentArgs.subMenu,
          teamName: currentArgs.teamName,
          topIssueBatchId: data.optionValue,
          channel: DefaultProductChannelMapping[currentArgs.product],
        })
      },
      [selectedBatchId],
    )

    const selectedTeamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
    React.useEffect(() => {
      if (selectedTeamId && selectedBatchId) {
        useTeamViewStore.getState().topIssues.fetchTopIssueList(selectedBatchId, selectedTeamId)
      }
    }, [selectedBatchId, selectedTeamId])

    React.useEffect(() => {
      if (productId) {
        useTeamViewStore
          .getState()
          .topIssues.fetchIssueClusteringBatchList(productId)
          .then((resp) => {
            if (resp?.[0] && !selectedBatchId) {
              const currentArgs = useTeamViewStore.getState().route.args
              if (currentArgs.subMenu === TeamViewSubMenuIds.TopIssues) {
                TeamRoute.navigator.navigate(
                  {
                    product: currentArgs.product,
                    subMenu: currentArgs.subMenu,
                    teamName: currentArgs.teamName,
                    topIssueBatchId: resp[0].batchId,
                    channel: DefaultProductChannelMapping[currentArgs.product],
                  },
                  {
                    replace: true,
                  },
                )
              }
            }
          })
      }
    }, [selectedBatchId, productId])

    const selectedBatchInfo = topIssueBatchList.find((item) => item.batchId === selectedBatchId)

    const content = React.useMemo(() => {
      if (topIssueListResponse.status === 'waiting') {
        return <TableLoading className={styles.loading} />
      }
      if (topIssueListResponse.status === 'error') {
        return <ErrorView error={topIssueListResponse.error} />
      }
      if (topIssueListResponse.status === 'done') {
        return topIssueListResponse.data.length > 0 ? (
          <div>
            <Row className={styles.gridTable}>
              <Row className={styles.gridHeader}>Issue</Row>
              <Row className={styles.gridHeader}>Coverarge</Row>
              <Row className={styles.gridHeader}>DSAT</Row>
              <Row className={styles.gridHeader}>Tenant</Row>
            </Row>
            {topIssueListResponse.data.map((issue) => (
              <TopIssueTickets
                key={issue.issueId}
                batchId={selectedBatchId}
                issue={issue}
                columns={tableColumns}
                clickedTicketKey={clickedTicketKey}
                onRowClick={onRowClick}
                onSelectionChange={(tickets) => handleSelectionChange(tickets, issue.issueId)}
                tableRef={(ref) => {
                  tablesRef.current[issue.issueId] = ref
                }}
                estimatedHeight={40 * issue.ticketCount}
                setColumns={setColumnSetting}
              />
            ))}
          </div>
        ) : (
          <ErrorView.Custom level="WARNING" message="No Top Issues generated" />
        )
      }
      return null
    }, [
      clickedTicketKey,
      handleSelectionChange,
      onRowClick,
      selectedBatchId,
      styles.loading,
      tableColumns,
      topIssueListResponse,
      styles.gridHeader,
      styles.gridTable,
      setColumnSetting,
    ])

    return (
      <>
        <Row className={styles.header} wrap>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Text size={600} weight="semibold">
            {headerText}
          </Text>
          <Spacer />
          <Row vAlign="center" wrap>
            <Text className={styles.rollingDate}>Weekly rolling date: </Text>
            <Spacer width={8} />
            <Dropdown
              selectedOptions={selectedBatchId ? [selectedBatchId] : []}
              onOptionSelect={handleBatchChange}
              button={
                selectedBatchInfo ? (
                  <Row vAlign="center">
                    <CalendarRegular fontSize={20} />
                    <Spacer width={4} />
                    <TimeView value={selectedBatchInfo.from} format="YYYY/M/D" />
                    {' - '}
                    <TimeView value={selectedBatchInfo.to} format="YYYY/M/D" />
                  </Row>
                ) : undefined
              }
            >
              {topIssueBatchList.map((item) => (
                <Option key={item.batchId} value={item.batchId} text={`${item.from}-${item.to}`}>
                  <Text>
                    <TimeView value={item.from} format="YYYY/M/D" />
                    {' - '}
                    <TimeView value={item.to} format="YYYY/M/D" />
                  </Text>
                </Option>
              ))}
            </Dropdown>
          </Row>
        </Row>
        <QuickSearch />

        {content}
        {isAllIssuesTicketIsEmpty ? (
          <ErrorView.Custom level="WARNING" message="No top issues found for this query" />
        ) : null}
      </>
    )
  },
)

TicketsTableForTopIssues.displayName = 'TicketsTableForTopIssues'
