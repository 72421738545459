import { Column } from '@copilot-dash/components'
import { IFeature } from '@copilot-dash/settings'
import { LabsPanelGroupItem } from './LabsPanelGroupItem'
import { LabsPanelGroupTitle } from './LabsPanelGroupTitle'

interface IProps {
  readonly group: string | undefined
  readonly features: IFeature[]
}

export function LabsPanelGroup({ group, features }: IProps) {
  return (
    <Column hAlign="start">
      <LabsPanelGroupTitle group={group} />
      {features.map((feature, index) => (
        <LabsPanelGroupItem key={index} feature={feature} />
      ))}
    </Column>
  )
}
