import { ITicketDiagnosticData } from '@copilot-dash/domain'
import { OutlineGroup } from './OutlineGroup'
import { OutlineItemForHome } from './OutlineItemForHome'
import { OutlineItemForTicketMetadata } from './OutlineItemForTicketMetadata'
import { OutlineItemForTicketSourceTable } from './OutlineItemForTicketSourceTable'

interface IProps {
  readonly data: ITicketDiagnosticData
}

export function OutlineGroupForDefault({ data }: IProps) {
  return (
    <OutlineGroup>
      <OutlineItemForHome />
      <OutlineItemForTicketMetadata />
      <OutlineItemForTicketSourceTable />
    </OutlineGroup>
  )
}
