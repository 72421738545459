import { Row } from '@copilot-dash/components'
import {
  Combobox,
  makeStyles,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  Text,
  tokens,
} from '@fluentui/react-components'
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'

interface ITimeSelectorProps {
  value: Date | null
  onChange: (value: Date | null) => void
}

const HOURS = Array.from({ length: 24 }, (_, i) => i).map((i) => String(i).padStart(2, '0'))
const MINUTES = Array.from({ length: 60 }, (_, i) => i).map((i) => String(i).padStart(2, '0'))

export const TimeSelector: FC<ITimeSelectorProps> = memo(({ value, onChange }) => {
  const styles = useStyles()
  const hours = String(value?.getHours() ?? 0).padStart(2, '0')
  const minutes = String(value?.getMinutes() ?? 0).padStart(2, '0')

  const handleHoursChange = useCallback<(hours: string) => void>(
    (hours) => {
      if (value) {
        const newValue = new Date(value)
        newValue.setHours(Number(hours))
        onChange(newValue)
      }
    },
    [onChange, value],
  )
  const handleMinutesChange = useCallback<(minutes: string) => void>(
    (minutes) => {
      if (value) {
        const newValue = new Date(value)
        newValue.setMinutes(Number(minutes))
        onChange(newValue)
      }
    },
    [onChange, value],
  )

  return (
    <Row className={styles.timeSelector}>
      <Selector value={hours} onChange={handleHoursChange} options={HOURS} />
      <Text>&nbsp;:&nbsp;</Text>
      <Selector value={minutes} onChange={handleMinutesChange} options={MINUTES} />
    </Row>
  )
})

TimeSelector.displayName = 'TimeSelector'

const Selector: FC<{ value: string; onChange: (value: string) => void; options: string[] }> = memo(
  ({ value, onChange, options }) => {
    const mountNodeRef = useRef<HTMLDivElement>(null)
    const styles = useStyles()
    const [focused, setFocused] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    const onOptionSelect = useCallback<(event: SelectionEvents, data: OptionOnSelectData) => void>(
      (event, data) => {
        if (data.selectedOptions?.[0]) {
          onChange(data.selectedOptions[0])
          setInputValue(data.selectedOptions[0])
        }
      },
      [onChange],
    )

    useEffect(() => {
      setInputValue(value)
    }, [value])

    return (
      <>
        <Combobox
          className={styles.selector}
          input={{
            className: styles.input,
          }}
          appearance="outline"
          expandIcon={null}
          value={focused ? inputValue : value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          selectedOptions={[value]}
          onInput={(event) => setInputValue(event.currentTarget.value)}
          onOptionSelect={onOptionSelect}
          mountNode={mountNodeRef.current}
          listbox={{
            className: styles.listbox,
          }}
        >
          {options.map((option) => (
            <Option key={option}>{option}</Option>
          ))}
        </Combobox>
        <div ref={mountNodeRef} />
      </>
    )
  },
)

Selector.displayName = 'Selector'

const useStyles = makeStyles({
  timeSelector: {
    alignItems: 'baseline',
  },
  input: {
    minWidth: 'unset',
    width: '22px',
    padding: 0,
    textAlign: 'center',
    color: tokens.colorBrandForeground1,
    fontWeight: tokens.fontWeightSemibold,
  },
  selector: {
    minWidth: 'unset',
    width: '22px',
    padding: 0,
    textAlign: 'center',
  },
  listbox: {
    minWidth: '100px',
    maxHeight: '300px',
  },
})
