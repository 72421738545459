import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Column } from '@copilot-dash/components'
import { Body } from './body/Body'
import { Footer } from './footer/Footer'

export function Root() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <Body />
      <Footer />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    maxHeight: '100%',
    height: '100%',

    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke3),
  },
})
