import { ISearchScreenForm } from './ISearchScreenStoreState'
import { ISearchScreenPropsQuery } from '../SearchScreen'

export function createSearchScreenStateForm(query: ISearchScreenPropsQuery): ISearchScreenForm {
  return {
    queryId: query.queryId,
    range: query.range,
    product: query.product || 'M365Chat',
    applications: query.applications,
    platforms: query.platforms,
    licenses: query.licenses,
    authTypes: query.authTypes,
    channel: query.channel,
    customerTypes: query.customerTypes,
    groundedPrompts: query.groundedPrompts,
    ring: query.ring,
    promptLanguages: query.promptLanguages,
    userId: query.userId,
    defaultRange: { type: 'absolute', from: '', to: '' },
    hasVerbatim: query.hasVerbatim,
    hasUserConsent: query.hasUserConsent,
    invocationSlicers: query.invocationSlicers,
    invocationType: query.invocationType,
    triggeredSkill: query.triggeredSkill,
    hasCitation: query.hasCitation,
    hasEntityCard: query.hasEntityCard,
    hitAvalon: query.hitAvalon,
    isCooked: query.isCooked,
    isApology: query.isApology,
    searchText: query.searchText,
    searchTextPrefix: query.searchTextPrefix,
    tenantIds: query.tenantIds,
    thumbs: query.thumbs,
    priority: query.priority,
    isSTCAChina: query.isSTCAChina,
    isTopi18N: query.isTopi18N,
    responseHeroType: query.responseHeroType,
    responseLinkType: query.responseLinkType,
    semanticSearchType: query.semanticSearchType,
    experienceType: query.experienceType,
    hasConnector: query.hasConnector,
    hasGPTExtension: query.hasGPTExtension,
    hasMessageExtension: query.hasMessageExtension,
    hasCopilotExtensionIds: query.hasCopilotExtensionIds,
    optionsSets: query.optionsSets,
    errorCode: query.errorCode,
    isGCIntent: query.isGCIntent,
    hasConnectorResult: query.hasConnectorResult,
    dSATStatus: query.dSATStatus,
    customTags: query.customTags,
    sliceIds: query.sliceIds,
    copilotExtensionIds: query.copilotExtensionIds,
    flights: query.flights,
    hasErrorMessages: query.hasErrorMessages,
    agentTypes: query.agentTypes,
    appKinds: query.appKinds,
    scenarios: query.scenarios,
    order: query.order,
    hasScreenshot: query.hasScreenshot,
    chatMode: query.chatMode,
    utteranceGroups: query.utteranceGroups,
    scenarioSlicers: query.scenarioSlicers,
    feedbackTargets: query.feedbackTargets,
    builderNameSlicers: query.builderNameSlicers,
    capabilitiesSlicers: query.capabilitiesSlicers,
    usageIntensityCohortSlicers: query.usageIntensityCohortSlicers,
    engagementCohortSlicers: query.engagementCohortSlicers,
    languageTierSlicers: query.languageTierSlicers,
    localeSlicers: query.localeSlicers,
    hasAssignee: query.hasAssignee,
    teamIds: query.teamIds,
    clientFlights: query.clientFlights,
    deployRing: query.deployRing,
  }
}
