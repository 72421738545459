import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { IState } from './store/IState'

interface IProps {
  readonly ticketId: string
  readonly ticket: ReactNode
  readonly children: ReactNode
}

export function TicketDiagnosticStore({ ticketId, ticket, children }: IProps) {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({ ticketId, children: ticket }),
    actions: createActions(set, get),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.ticketId = ticketId
      state.children = ticket
    })
  }, [store, ticketId, ticket])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()
TicketDiagnosticStore.use = Context.use
TicketDiagnosticStore.useActions = Context.useActions
