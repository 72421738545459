import { useState } from 'react'
import { Row, Spacer } from '@copilot-dash/components'
import { AnyTableToolbarSearchButton } from './AnyTableToolbarSearchButton'
import { AnyTableToolbarSearchInput } from './AnyTableToolbarSearchInput'

interface IProps {
  readonly keyword: string | undefined
  readonly onSearch?: (keyword: string) => void
}

export function AnyTableToolbarSearch({ keyword, onSearch }: IProps) {
  const [visible, setVisible] = useState(false)

  const handleOnToggle = () => {
    setVisible((prev) => !prev)
    onSearch?.('')
  }

  return (
    <Row>
      {visible && <AnyTableToolbarSearchInput keyword={keyword} onSearch={onSearch} />}
      {visible && <Spacer size={4} />}
      <AnyTableToolbarSearchButton visible={visible} onToggle={handleOnToggle} />
    </Row>
  )
}
