import { PromiseSnapshot } from '@copilot-dash/core'
import { IQuery, ProductIds } from '@copilot-dash/domain'
import { QueryDataConverter } from './converters/QueryDataConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getMyQueries(context: IDashStoreContext, productId: ProductIds): PromiseSnapshot<IQuery[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.query.myQueriesMap[productId]
    },
    set: (state, snapshot) => {
      state.query.myQueriesMap[productId] = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getMyQueries()
      return QueryDataConverter.fromGetQueriesApi(response).queries
    },
  })
}
