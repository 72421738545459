import { z } from 'zod'

export interface ApiV2ClientListItem {
  readonly clientName: string
  readonly displayClientName: string
  readonly platform: string
  readonly licenseType: string
  readonly application: string
  readonly productByClient: string
}

export interface ApiV2ScenarioListItem {
  readonly scenarioName: string
  readonly displayScenarioName: string
}

export interface ApiV2ProductsResponse {
  readonly productId: string
  readonly productName: string
  readonly clientList: Array<ApiV2ClientListItem>
  readonly scenarioList: Array<ApiV2ScenarioListItem>
  readonly teamIdList: Array<number>
}

export const apiV2ClientListItemSchema = z.object({
  clientName: z.string(),
  displayClientName: z.string(),
  platform: z.string(),
  licenseType: z.string(),
  application: z.string(),
  productByClient: z.string(),
})

export const apiV2ScenarioListItemSchema = z.object({
  scenarioName: z.string(),
  displayScenarioName: z.string(),
})

export const apiV2ProductsResponseSchema = z.object({
  productId: z.string(),
  productName: z.string(),
  clientList: z.array(apiV2ClientListItemSchema),
  scenarioList: z.array(apiV2ScenarioListItemSchema),
  teamIdList: z.array(z.number()),
})
