import { PromiseSnapshots } from '@copilot-dash/core'
import { FeedbackId, IUpdateWorkItem } from '@copilot-dash/domain'
import { convertWorkItemFromDomainToAPI } from './converters/convertWorkItemFromDomainToAPI'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function batchUpdateWorkItems(
  context: IDashStoreContext,
  workItems: Array<IUpdateWorkItem & { feedbackId: FeedbackId }>,
  signal?: AbortSignal,
) {
  return context.requestAnd({
    request: async () => {
      return await context.api.copilotDash.patchWorkItems(
        {
          workItemList: workItems.map(convertWorkItemFromDomainToAPI),
        },
        signal,
      )
    },
    onSuccess(state, data) {
      for (const successfulWorkItemId of data.updateSuccessedWorkItemIdList) {
        const workItem = workItems.find((workItem) => workItem.workItemId === successfulWorkItemId)
        if (!workItem) continue

        const feedbackId = workItem.feedbackId
        const oldWorkItem = state.workItems.workItemMap[feedbackId]?.data
        if (oldWorkItem) {
          const newWorkItem = { ...oldWorkItem, ...workItem }
          state.workItems.workItemMap[feedbackId] = PromiseSnapshots.done(newWorkItem)
        }
      }
    },
  })
}
