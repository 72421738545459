import { z } from 'zod'

export interface ApiV2RootCauseItem {
  readonly id: string
  readonly rootCauseTitle: string
  readonly rootCauseStatus: string
  readonly isDelete: boolean
  readonly eta?: string
  readonly assignTo?: string
  readonly createdTimeUtc: string
}
export type ApiV2RootCausesResponse = Array<ApiV2RootCauseItem>

export const apiV2RootCauseItemSchema = z.object({
  id: z.string(),
  rootCauseTitle: z.string(),
  rootCauseStatus: z.string(),
  isDelete: z.boolean(),
  eta: z.string().optional(),
  assignTo: z.string().optional(),
  createdTimeUtc: z.string(),
})
export const apiV2RootCausesResponseSchema = z.array(apiV2RootCauseItemSchema)
