import { ApiCreateRootCauseResponse } from '@copilot-dash/api'
import { ICreateRootCause } from '@copilot-dash/domain'
import { RootCauseDataConverter } from './converters/RootCauseDataConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function createRootCause(
  context: IDashStoreContext,
  params: ICreateRootCause,
): Promise<ApiCreateRootCauseResponse> {
  const data = RootCauseDataConverter.postDataToApiV2(params)
  const response = await context.api.copilotDash.createRootCause(data)
  return { workItemId: response.id, errorMessage: '' }
}
