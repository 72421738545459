import { Column } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { TicketScreenKustoLogsBar } from './TicketScreenKustoLogsBar'
import { TicketScreenKustoLogsBody } from './TicketScreenKustoLogsBody'

export function TicketScreenKustoLogs() {
  const styles = useStyles()

  return (
    <Column className={styles.root} fill>
      <TicketScreenKustoLogsBar />
      <TicketScreenKustoLogsBody />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px',

    '& > *:first-child': {
      flex: '0 0 auto',
      border: `1px solid ${tokens.colorNeutralStroke3}`,
      borderBottom: 'none',
    },

    '& > *:last-child': {
      flex: '1 1 0',
      border: `1px solid ${tokens.colorNeutralStroke3}`,
    },
  },
})
