import { ApiV2TicketSource } from '@copilot-dash/api'
import { AnyTableColDef, Overlays, Tab, TabItem, TabItemForAnyTable, TabItemForMonaco } from '@copilot-dash/components'
import { usePromise } from '@copilot-dash/core'
import { Button } from '@fluentui/react-components'
import { SearchRegular } from '@fluentui/react-icons'
import { useMemo } from 'react'
import { MainForegroundForSourceBlob } from './MainForegroundForSourceBlob'
import { TicketAsyncView } from '../../../ticket/children/common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
}

export function MainForegroundForSourceTable({ ticketId }: IProps) {
  const promise = useMemo(() => {
    return app.api.copilotDash.getTicket(ticketId)
  }, [ticketId])

  const snapshot = usePromise(promise)

  const columns = useMemo((): AnyTableColDef<ApiV2TicketSource>[] => {
    return [
      {
        key: 'preview',
        render(item) {
          return (
            <Button
              icon={<SearchRegular />}
              onClick={() => {
                Overlays.openAsFullDialog({
                  content: <MainForegroundForSourceBlob url={item.url} ticketId={ticketId} />,
                })
              }}
              appearance="transparent"
            />
          )
        },
      },
    ]
  }, [ticketId])

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => (
        <Tab>
          <TabItem name="Table">
            <TabItemForAnyTable data={data.sources ?? []} columns={columns} />
          </TabItem>
          <TabItem name="JSON">
            <TabItemForMonaco data={data.sources ?? []} />
          </TabItem>
        </Tab>
      )}
    </TicketAsyncView>
  )
}
