import { PromiseSnapshot } from '@copilot-dash/core'
import {
  ITicketMessageForBot,
  ITicketMessageForUser,
  ITicketTurnConversation,
  ITicketTurnConversationSourceType,
} from '@copilot-dash/domain'
import { convertTicketChatTurnFromConversationGroup1 } from './converters/convertTicketChatConversationFromConversationGroup1'
import { convertTicketChatTurnFromConversationLegacy } from './converters/convertTicketChatConversationFromConversationLegacy'
import { getTicketLastTurnMessageId } from './getTicketLastTurnMessageId'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getRawConversation } from '../actions-raw-ticket-chat/getRawConversation'
import { getRawConversationGroup1 } from '../actions-raw-ticket-chat/getRawConversationGroup1'

const OCV_RESPONSE_WHITE_LIST = [
  'Something went wrong',
  'Something went wrong. Please try again later.',
  'Sorry something went wrong',
  'Sorry something went wrong.',
  'Sorry something went wrong. Please try again or share your feedback.',
  'Sorry, something went wrong. Please try again.',
]

export function getTicketTurnConversation(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITicketTurnConversation> {
  return context.getOrFetch<ITicketTurnConversation>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.conversation
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.conversation = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnConversation> {
    const backend = fetchSydney()
    const backendData = await backend.catch(() => undefined)
    const backendError = await backend.then(() => undefined).catch((error) => error)

    const frontend = fetchFrontend()
    const frontendData = await frontend.catch(() => undefined)
    const frontendError = await frontend.then(() => undefined).catch((error) => error)

    if (backendData && frontendData) {
      return merge(backendData, frontendData)
    }

    const data = backendData ?? frontendData
    if (data) {
      return data
    }

    throw backendError ?? frontendError
  }

  async function fetchSydney(): Promise<ITicketTurnConversation> {
    try {
      return await fetchBackendFromConversationGroup1()
    } catch (error) {
      try {
        return await fetchBackendFromConversationLegacy()
      } catch {
        // do nothing
      }

      throw error
    }
  }

  async function fetchBackendFromConversationGroup1(): Promise<ITicketTurnConversation> {
    const response = await getRawConversationGroup1(context, ticketId, messageId).promise
    return convertTicketChatTurnFromConversationGroup1(messageId, response)
  }

  async function fetchBackendFromConversationLegacy(): Promise<ITicketTurnConversation> {
    const response = await getRawConversation(context, ticketId, messageId).promise
    return convertTicketChatTurnFromConversationLegacy(messageId, response)
  }

  async function fetchFrontend(): Promise<ITicketTurnConversation | undefined> {
    const lastMessageId = await getTicketLastTurnMessageId(context, ticketId).promise
    if (lastMessageId !== messageId) {
      return undefined
    }

    const raw = await getRawV2Ticket(context, ticketId).promise
    const chat = raw.diagnosticContext?.chat?.find((chat) => chat.messageId === messageId)
    const utterance = chat?.utterance?.text || chat?.utterance?.sydneyText
    const response = cleanResponse(chat?.response?.text || chat?.response?.sydneyText)

    return generate(utterance, response, 'Metadata')
  }

  function merge(backend: ITicketTurnConversation, frontend: ITicketTurnConversation): ITicketTurnConversation {
    return {
      ...backend,
      utterance: mergeUtterance(backend.utterance, frontend.utterance),
      response: mergeResponse(backend.response, frontend.response),
    }
  }

  function mergeUtterance(
    backend: ITicketMessageForUser | undefined,
    frontend: ITicketMessageForUser | undefined,
  ): ITicketMessageForUser | undefined {
    if (backend?.content.text) {
      return backend
    }

    return frontend
  }

  function mergeResponse(
    backend: ITicketMessageForBot | undefined,
    frontend: ITicketMessageForBot | undefined,
  ): ITicketMessageForBot | undefined {
    const frontendText = frontend?.content.text
    if (frontendText && OCV_RESPONSE_WHITE_LIST.includes(frontendText)) {
      return frontend
    }

    if (backend?.content.text) {
      return backend
    }

    return backend ?? frontend
  }

  async function generate(
    utterance: string | undefined,
    response: string | undefined,
    source: ITicketTurnConversationSourceType,
  ): Promise<ITicketTurnConversation | undefined> {
    if (utterance === undefined || response === undefined) {
      return undefined
    }

    return {
      id: messageId,
      timestamp: undefined,
      utterance: {
        timestamp: undefined,
        content: {
          language: undefined,
          text: utterance,
          textInEnglish: undefined,
          markdown: undefined,
          markdownInEnglish: undefined,
          adaptiveCard: undefined,
        },
      },
      response: {
        timestamp: undefined,
        content: {
          language: undefined,
          text: response,
          textInEnglish: undefined,
          markdown: response,
          markdownInEnglish: undefined,
          adaptiveCard: undefined,
        },
      },
      invocations: [],
      suggestions: [],
      source,
      raw: undefined,
    }
  }

  /**
   * TODO: There is a OCV bug. Some responses from OCV are JSON strings
   */
  function cleanResponse(response: string | undefined): string | undefined {
    if (!response) {
      return response
    }

    try {
      return JSON.parse(response)
    } catch {
      return response
    }
  }
}
