import { Row } from '@copilot-dash/components'
import { SymptomReportItem } from '@copilot-dash/domain'
import { TableCell, Tag } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'StringArray' }>
}

export function ItemCellValueForStringArray({ item }: IProps) {
  const displayText = useMemo(() => item.value.join('; '), [item])

  return (
    <TableCell title={displayText}>
      <Row gap="gap.small" wrap>
        {item.value.map((value, index) => (
          <Tag key={index}>{value}</Tag>
        ))}
      </Row>
    </TableCell>
  )
}
