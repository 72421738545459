import { createDashAuth } from '@copilot-dash/auth'
import { createFeatures, createSettings } from '@copilot-dash/settings'
import { createDashStore } from '@copilot-dash/store'
import { createAppApi } from './createAppApi'
import { createAppEnv } from './createAppEnv'
import { createAppLogger } from './createAppLogger'
import { IApp } from './IApp'
import { createAppRouter } from '../router'

export function createApp(): IApp {
  const env = createAppEnv()
  const settings = createSettings(env)
  const features = createFeatures(env)
  const auth = createDashAuth({ isTorus: features.torusApp.value })
  const logger = createAppLogger(env, auth)
  const router = createAppRouter(auth)
  const api = createAppApi(env, auth, settings, features)
  const store = createDashStore({
    api,
    options: {
      isV2Enabled: () => features.v2Ticket.value,
      isV2DiscussionEnabled: () => features.v2Discussion.value,
      isV2TeamViewEnabled: () => features.v2TeamView.value,
      hasTier2: () => !!auth.permission?.advanced,
    },
  })

  return {
    env,
    auth,
    logger,
    api,
    settings,
    features,
    store,
    router,
  }
}
