import { Body1Stronger } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { ClientEnvironmentCardFilters } from './config'
import { useTicketFilterStore } from '../../store/store'
import { ClientFlightFilter, DeployRingFilter } from '../filters'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function ClientEnvironmentCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

  const hasFeedbackDetailsCard = useMemo(() => {
    return filterPanelItems.some((item) => ClientEnvironmentCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasFeedbackDetailsCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Client environment</Body1Stronger>
      <Card className={styles.cardFeedbackTag}>
        {filterPanelItems?.includes('deployRing') && (
          <Controller
            name="deployRing"
            control={control}
            render={({ field }) => <DeployRingFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('clientFlights') && (
          <Controller
            name="clientFlights"
            control={control}
            render={({ field }) => <ClientFlightFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
