import { Column, Row } from '@copilot-dash/components'
import {
  Button,
  Dialog,
  DialogBody,
  DialogSurface,
  DialogTitle,
  Label,
  makeStyles,
  tokens,
  Tooltip,
} from '@fluentui/react-components'
import { DismissRegular, ZoomFitRegular, ZoomInRegular, ZoomOutRegular } from '@fluentui/react-icons'
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch'

interface IImagePreviewModal {
  imageUrl: string
  onClose: () => void
}

const INITIAL_SCALE = 1
const MAX_SCALE = 3
const MIN_SCALE = 0.5

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls()

  return (
    <Row gap="gap.small" style={{ margin: '10px' }}>
      <Tooltip content={'Zoom In'} relationship={'label'}>
        <Button size="small" icon={<ZoomInRegular />} onClick={() => zoomIn()} />
      </Tooltip>
      <Tooltip content={'Zoom Out'} relationship={'label'}>
        <Button size="small" icon={<ZoomOutRegular />} onClick={() => zoomOut()} />
      </Tooltip>
      <Tooltip content={'Reset Zoom'} relationship={'label'}>
        <Button size="small" icon={<ZoomFitRegular />} onClick={() => resetTransform()} />
      </Tooltip>
    </Row>
  )
}

export const ImagePreviewModal: React.FC<IImagePreviewModal> = ({ imageUrl, onClose }) => {
  const styles = useStyles()

  return (
    <Dialog open={!!imageUrl} onOpenChange={onClose}>
      <DialogSurface className={styles.dialogSurface}>
        <DialogTitle
          action={
            <Button
              icon={<DismissRegular />}
              appearance="subtle"
              onClick={onClose}
              aria-label="Close"
              className={styles.closeButton}
            />
          }
        >
          <Label className={styles.title}>Image Preview</Label>
        </DialogTitle>
        <DialogBody className={styles.body}>
          <TransformWrapper initialScale={INITIAL_SCALE} maxScale={MAX_SCALE} minScale={MIN_SCALE}>
            <Column style={{ width: '100%', height: '100%' }}>
              <Controls />
              <TransformComponent wrapperClass={styles.wrapperClass} contentClass={styles.contentClass}>
                <img src={imageUrl} alt="Image Preview" className={styles.image} />
              </TransformComponent>
            </Column>
          </TransformWrapper>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialogSurface: {
    padding: '10px',
    width: '90vw',
    height: '100vw',
    maxWidth: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    height: '100%',
  },
  closeButton: {
    color: 'white',
    ':hover': {
      backgroundColor: 'unset',
    },
    ':active': {
      backgroundColor: 'unset !important',
    },
  },
  title: {
    fontSize: '16px',
    color: tokens.colorNeutralForegroundOnBrand,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  wrapperClass: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentClass: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})
