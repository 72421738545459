import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey, JobStatus } from '@copilot-dash/domain'

import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.style'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'
import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnClusteringRequestStatus: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.ClusteringRequestStatus],
  field: String(JobColumnKey.ClusteringRequestStatus) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderStatus item={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>Status</TableCellLayoutWithStyles>,
  },
  minWidth: 200,
  width: 200,
  sortable: true,
  resizable: true,
}

const RenderStatus = memo(({ item }: { item: IClusterJobData }) => {
  const styles = useStyles()
  if (item.isPublished) {
    return <Text className={styles.blue}>Published</Text>
  }

  switch (item.clusteringRequestStatus) {
    case JobStatus.Submitted:
    case JobStatus.Clustering:
    case JobStatus.DataGathering:
    case JobStatus.DatasetReady:
      return <Text className={styles.darkOrange}>In progress</Text>
    case JobStatus.ClusteringError:
    case JobStatus.DatasetGenerationError:
      return <Text className={styles.yellow}>Failed</Text>
    case JobStatus.ClusteringCompleted:
      return <Text className={styles.green}>Completed</Text>
    case JobStatus.Canceled:
      return <Text className={styles.grayColor}>Canceled</Text>
    default:
      return <Text className={styles.grayColor}>Unknown</Text>
  }
})

RenderStatus.displayName = 'RenderStatus'
