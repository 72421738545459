import { z } from 'zod'

const api3sOnlineDataGroup2QuerySchema = z.record(z.unknown()).and(
  z.object({
    QueryString: z.string().optional(),
    DisplayQueryString: z.string().optional(),
  }),
)

const api3sOnlineDataGroup2ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
    TrimmedResponse: z
      .record(z.unknown())
      .and(
        z.object({
          ExtendedData: z
            .record(z.unknown())
            .and(
              z.object({
                Info: z
                  .record(z.unknown())
                  .and(
                    z.object({
                      LuAlteredQueries: z.unknown().optional(),
                      LlmLuOutput: z.unknown().optional(),
                      LuClassification: z.unknown().optional(),
                    }),
                  )
                  .optional(),
              }),
            )
            .optional(),
        }),
      )
      .optional(),
  }),
)

const api3sOnlineDataGroup2AnswerEntityRequestSchema = z.record(z.unknown()).and(
  z.object({
    Query: api3sOnlineDataGroup2QuerySchema.optional(),
  }),
)

const api3sOnlineDataGroup2EntityRequestSchema = z.record(z.unknown()).and(
  z.object({
    Query: api3sOnlineDataGroup2QuerySchema.optional(),
  }),
)

export const api3sOnlineDataGroup2ReplayRequestSchema = z.record(z.unknown()).and(
  z.object({
    AnswerEntityRequests: z.array(api3sOnlineDataGroup2AnswerEntityRequestSchema).optional(),
    EntityRequests: z.array(api3sOnlineDataGroup2EntityRequestSchema).optional(),
  }),
)

export const api3sOnlineDataGroup2ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayRequest: api3sOnlineDataGroup2ReplayRequestSchema.optional(),
    ReplayResponse: api3sOnlineDataGroup2ReplayResponseSchema.optional(),
  }),
)

export const api3sOnlineDataGroup2Schema = z.array(api3sOnlineDataGroup2ItemSchema)
