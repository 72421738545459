import { ProductIds } from '../basic'
import { TeamId } from '../team'

export enum CopilotDashPath {
  search = 'search',
  team = 'team',
}

export interface IQuery {
  queryId: string
  title: string
  productId: ProductIds
  isTeamShared: boolean
  teamId?: TeamId
  userId?: string
  createdBy: string
  createdTime: string
  lastModifiedBy: string
  lastModifiedTime: string
  copilotDashQuery: string
  copilotDashPath: CopilotDashPath
  filterPayload: string
  oDataQuery?: string
}

export interface IQueryCollection {
  queries: IQuery[]
}
