import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'

import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
  disabled?: boolean
}

export const PriorityFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, disabled }) => {
  const filterOptionsSnapshot = useFilterOptions('priority')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('priority')

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-priorityFilter'}
      filterType={displayName}
      isGroup={false}
      disabled={disabled}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => onChangeValue(item)}
      infoLabelContent={
        <>
          Priority is only assigned to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
