import { Row } from '@copilot-dash/components'
import { PromiseSnapshot } from '@copilot-dash/core'
import { Spinner, Text } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { ErrorView } from '../../../../components/Error'

interface IProps<T> {
  readonly snapshot: PromiseSnapshot<T>
  readonly loadingMessage?: string
  readonly children: (data: T) => ReactNode
}

export function TicketAsyncView<T>({ snapshot, loadingMessage = 'Loading', children }: IProps<T>) {
  switch (snapshot.status) {
    case 'error':
      return (
        <Row vAlign="center" hAlign="center" fill>
          <ErrorView error={snapshot.error} />
        </Row>
      )
    case 'waiting':
      return (
        <Row vAlign="center" hAlign="center" fill>
          <Spinner label={loadingMessage && <Text>{loadingMessage}</Text>} labelPosition="below" />
        </Row>
      )
    case 'done':
      return children(snapshot.data)
  }
}
