import { z } from 'zod'
import { ApiClusterJobSummaryData } from './ApiClusterJobSummaryData'

export interface ApiClusterJobData {
  readonly id: string
  readonly product?: string
  readonly productId?: number
  readonly description?: string
  readonly startTimeUTC?: string
  readonly endTimeUTC?: string
  readonly clusteringConfigId?: string
  readonly amlDataSetJobId?: string
  readonly amlDataSetJobStatus?: string
  readonly amlDataSet?: string
  readonly dataSetClusteringRequestId?: string
  readonly dataSetClusteringRequestDescription?: string
  readonly dataSetRetryCount?: number
  readonly amlClusteringJobId?: string
  readonly amlClusteringJobStatus?: string
  readonly amlClusteringResultLink?: string
  readonly amlClusteringReviewedResultLink?: string
  readonly clusteringRetryCount?: number
  readonly workflowStartTimestamp?: string
  readonly workflowEndTimestamp?: string
  readonly clusteringRequestStatus?: string
  readonly isPublished?: boolean
  readonly createdBy?: string
  readonly isBase?: boolean
  readonly clusterName?: string
  readonly clusteringSummary?: Array<ApiClusterJobSummaryData>
}

export interface ApiClusterJobDataWithResult {
  readonly result: boolean
  readonly data?: ApiClusterJobData
}

export interface ApiClusterJobResponse {
  readonly jobs: Array<ApiClusterJobData>
  readonly hasMore: boolean
  readonly ticketCount: number
  readonly errorMessage: string
  readonly sATCount: number
  readonly dSATCount: number
}

export const apiClusterJobDataSchema = z.object({
  id: z.string(),
  product: z.string().optional(),
  productId: z.number().optional(),
  description: z.string().optional(),
  startTimeUTC: z.string().optional(),
  endTimeUTC: z.string().optional(),
  clusteringConfigId: z.string().optional(),
  amlDataSetJobId: z.string().optional(),
  amlDataSetJobStatus: z.string().optional(),
  amlDataSet: z.string().optional(),
  dataSetClusteringRequestId: z.string().optional(),
  dataSetClusteringRequestDescription: z.string().optional(),
  dataSetRetryCount: z.number().optional(),
  amlClusteringJobId: z.string().optional(),
  amlClusteringJobStatus: z.string().optional(),
  amlClusteringResultLink: z.string().optional(),
  amlClusteringReviewedResultLink: z.string().optional(),
  clusteringRetryCount: z.number().optional(),
  workflowStartTimestamp: z.string().optional(),
  workflowEndTimestamp: z.string().optional(),
  clusteringRequestStatus: z.string().optional(),
  isPublished: z.boolean().optional(),
  createdBy: z.string().optional(),
  isBase: z.boolean().optional(),
  clusterName: z.string().optional(),
})

export const apiClusterJobDataWithResultSchema = z.object({
  result: z.boolean(),
  data: apiClusterJobDataSchema.optional(),
})

export const apiClusterJobResponseSchema = z.array(apiClusterJobDataSchema)
