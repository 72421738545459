import { IDashStoreContext } from '../../IDashStoreContext'

export async function getAttachment(context: IDashStoreContext, rawUrl: string): Promise<string> {
  if (!context.enableV2Discussion) {
    const response = await context.api.logCollector.getAttachmentUrl(rawUrl)
    const blob = await context.api.logCollector.getAttachment(response.url)
    return convertBinaryToBase64(blob.data)
  } else {
    const response = await context.api.copilotDash.getAttachmentV2(rawUrl)
    return convertBinaryToBase64(response)
  }
}

export async function convertBinaryToBase64(arrayBuffer: ArrayBuffer, mimeType: string = 'image/*'): Promise<string> {
  return new Promise((resolve, reject) => {
    const blob = new Blob([arrayBuffer], { type: mimeType })
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
