import { MonacoView } from '../monaco/MonacoView'
import { MonacoLanguage } from '../monaco/MonacoViewTypes'
import { FullDialog } from '../overlay/children/FullDialog'
import { Overlays } from '../overlay/Overlays'

interface IOptions {
  readonly language?: MonacoLanguage
  readonly wrap?: boolean
  readonly title?: string
}

interface IProps extends IOptions {
  readonly data: unknown
  readonly open: boolean
  readonly onClose: () => void
}

export function MonacoDialog({ data, language, wrap, title, open, onClose }: IProps) {
  return (
    <FullDialog open={open} onClose={onClose} title={title ?? language ?? 'JSON'}>
      <MonacoView data={data} language={language} wrap={wrap} minimap={true} />
    </FullDialog>
  )
}

MonacoDialog.open = (data: unknown, options?: IOptions) => {
  Overlays.open({
    content: (open, onClose) => {
      return (
        <MonacoDialog data={data} language={options?.language} wrap={options?.wrap} open={open} onClose={onClose} />
      )
    },
  })
}
