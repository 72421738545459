import { ClusterResultColumnKey, IClusterJobResultSummary } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'
import { Priority } from '../../../../search/children/feedbackInsights/children/topIssue/tableColumns/Priority'

export const ColumnClusterResultPriority: ColDef<IClusterJobResultSummary> = {
  headerName: ClusterResultColumnKey.Priority,
  field: String(ClusterResultColumnKey.Priority) as NestedFieldPaths<IClusterJobResultSummary>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <Priority data={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>Priority</TableCellLayoutWithStyles>,
  },
  minWidth: 150,
  width: 150,
  maxWidth: 200,
  sortable: true,
  resizable: true,
}
