import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getRootCauseCustomTags(
  context: IDashStoreContext,
  rootCauseGuid: string,
  forceRequest?: boolean,
): PromiseSnapshot<string[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.team.rootCauseCustomTags?.[rootCauseGuid]
    },
    set: (state, snapshot) => {
      if (!state.team.rootCauseCustomTags) {
        state.team.rootCauseCustomTags = {}
      }
      state.team.rootCauseCustomTags[rootCauseGuid] = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getRootCauseCustomTags(rootCauseGuid)
      return response
    },
  })
}
