import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { Column } from '../../../layout'

interface IProps {
  readonly children: ReactNode
}

export function Body({ children }: IProps) {
  const styles = useStyles()

  return (
    <Column className={mergeClasses(styles.root)} vAlign="stretch" hAlign="stretch" fill>
      <Column
        className={mergeClasses('AnimatedTabBody-wrapper', styles.wrapper)}
        vAlign="stretch"
        hAlign="stretch"
        fill
      >
        {children}
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    zIndex: 0,
  },
})
