import { Text, tokens } from '@fluentui/react-components'
import { FC, memo } from 'react'
import { ICellBaseProps } from './types'

function getColorByPriority(priority: number) {
  if (priority === 0) return tokens.colorStatusDangerForeground1
  if (priority === 1) return tokens.colorPaletteCornflowerForeground2
  if (priority === 2) return tokens.colorNeutralStrokeAccessible
  return tokens.colorNeutralStrokeAccessible
}

export function renderedPriority(priority: number) {
  return (
    <Text size={300} weight="semibold" style={{ color: getColorByPriority(priority) }}>
      P{priority}
    </Text>
  )
}

export const Priority: FC<ICellBaseProps> = memo(({ data }) => {
  return renderedPriority(data.priority)
})
Priority.displayName = 'Priority'
