import { IFilterOption, ProductIds } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'

import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (InvocationTypes: string[]) => void
  selectedProductId: ProductIds
}

export const ErrorCodeFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, selectedProductId }) => {
  const filterOptionsSnapshot = useFilterOptions('errorCode')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  filterOptions.forEach((item) => {
    if (item.menu === '3SErrorCode') {
      item.hidden = selectedProductId === ProductIds.M365ChatWebChat
    }
  })
  const displayName = getFilterDisplayName('errorCode')

  return (
    <CopilotDashMultiSelectDropdown
      comboId="ErrorCodeFilter"
      filterType={displayName}
      infoLabelContent={
        <>
          Refer to{' '}
          <a
            href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/466764/Search-Filters#:~:text=to%20certain%20requests.-,Error%20Code,-%3A%20Error%20defined%20by"
            target="_blank"
            rel="noopener noreferrer"
          >
            wiki
          </a>{' '}
          to understand the definitions of the error code filters.
        </>
      }
      optionsList={filterOptions.filter((item) => !item.hidden)}
      isGroup={true}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
}
