import { MonacoView } from '@copilot-dash/components'
import { usePromise } from '@copilot-dash/core'
import { useMemo } from 'react'
import { TicketAsyncView } from '../../../ticket/children/common/TicketAsyncView'

interface IProps {
  readonly url: string
  readonly ticketId: string
}

export function MainForegroundForSourceBlob({ url, ticketId }: IProps) {
  const promise = useMemo(() => {
    return app.api.copilotDash.getTicketBlobRaw(ticketId, url)
  }, [ticketId, url])

  const snapshot = usePromise(promise)

  return (
    <TicketAsyncView snapshot={snapshot} loadingMessage="Loading blob...">
      {(data) => <MonacoView data={data} />}
    </TicketAsyncView>
  )
}
