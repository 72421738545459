import { TelemetryScope } from '@copilot-dash/logger'
import { ISearchClusterOptions } from '@copilot-dash/store'
import { ClusterScreenLayout } from './layout/ClusterScreenLayout'
import { ClusterScreenStoreProvider } from './store/ClusterScreenStoreProvider'

export interface IClusterScreenProps {
  readonly onNavigate: (query: ISearchClusterOptions) => void
}

export function ClusterScreen() {
  return (
    <TelemetryScope scope="ClusterScreen">
      <ClusterScreenStoreProvider>
        <ClusterScreenLayout />
      </ClusterScreenStoreProvider>
    </TelemetryScope>
  )
}
