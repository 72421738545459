import { makeStyles, tokens } from '@fluentui/react-components'
import { Row } from '@copilot-dash/components'
import { HeaderTabBar } from './HeaderTabBar'
import { HeaderToolbar } from './HeaderToolbar'

export function Header() {
  const styles = useStyles()

  return (
    <Row className={styles.root} space="between" hAlign="stretch" vAlign="center">
      <HeaderTabBar />
      <HeaderToolbar />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 12px 0 4px',
    width: '100%',
    minHeight: '32px',
    borderBottom: `1px solid ${tokens.colorNeutralStroke3}`,
  },
})
