import { isPlainObject } from 'lodash'
import { generateAnyPropertyItem } from './generateAnyPropertyItem'
import { AnyPropertyItem } from '../AnyPropertyType'

export function generateAnyPropertyItems(value: unknown): AnyPropertyItem[] {
  if (value && isPlainObject(value)) {
    return Object.entries(value).map(([key, value]) => generateAnyPropertyItem(key, value))
  }

  return [generateAnyPropertyItem('root', value)]
}
