import { Row } from '@copilot-dash/components'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function MainBackground() {
  const styles = useStyles()
  const children = TicketDiagnosticStore.use((state) => state.children)
  const visible = TicketDiagnosticStore.use((state) => state.activeTab === TicketDiagnosticTab.Home)

  return <Row className={mergeClasses(styles.root, !visible && styles.invisible)}>{children}</Row>
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
  },
  invisible: {
    opacity: 0,
    pointerEvents: 'none',
  },
})
