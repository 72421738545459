import { Row } from '@copilot-dash/components'
import { makeStyles, Text } from '@fluentui/react-components'
import { SettingsScreenTab, useSettingsScreenStore } from '../../store'

export function SettingsScreenHeader() {
  const styles = useStyles()
  const selectedTab = useSettingsScreenStore((state) => state.selectedTab)

  let title: string
  switch (selectedTab) {
    case SettingsScreenTab.General:
      title = 'General'
      break
    case SettingsScreenTab.Labs:
      title = 'Labs'
      break
  }

  return (
    <Row className={styles.root} vAlign="center">
      <Text size={500} weight="semibold">
        {title}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    height: '64px',
    padding: '4px 16px',
  },
})
