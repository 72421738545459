import { PromiseSnapshot } from '@copilot-dash/core'
import { IFilterGroup } from '@copilot-dash/domain'
import { convertFilterFromAPItoDomain } from './converters/convertFilterFromAPItoDomain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getFilterConfig(context: IDashStoreContext, version: string): PromiseSnapshot<IFilterGroup[]> {
  return context.getOrFetch<IFilterGroup[]>({
    get: (state) => {
      return state.basic.filters
    },
    set: (state, snapshot) => {
      state.basic.filters = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getFilterConfig(version)
      return response.filterConfig.filters.map(convertFilterFromAPItoDomain)
    },
  })
}
