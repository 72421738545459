import { useMemo } from 'react'
import { Root } from './children/Root'
import { generateAnyPropertyItems } from './utils/generateAnyPropertyItems'
import { Column } from '../layout'

interface IProps<T> {
  readonly data: T
}

export function AnyProperty<T extends object = object>({ data }: IProps<T>) {
  const items = useMemo(() => {
    return generateAnyPropertyItems(data)
  }, [data])

  return (
    <Column style={{ flex: 1 }} hAlign="stretch" fill>
      <Root items={items} />
    </Column>
  )
}
