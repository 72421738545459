import { Column } from '@copilot-dash/components'
import { AsyncSnapshots } from '@copilot-dash/core'
import { makeStyles, Spinner } from '@fluentui/react-components'
import { SssLogViewTree } from './SssLogViewTree'
import { ErrorView } from '../../../../../../../components/Error'
import { Scrollbar } from '../../../../../../../components/Scrollbar/Scrollbar'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'

export function SssLogView() {
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketTurnId={selectedTurnId} />
}

function Content({ ticketTurnId }: { readonly ticketTurnId: string }) {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const snapshot = application.store.use.get3sLogs(ticketId, ticketTurnId)

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Column vAlign="center" hAlign="center" fill>
          <Spinner labelPosition="below" label="Loading..." />
        </Column>
      )
    case 'error':
      return (
        <Column vAlign="center" fill>
          <ErrorView error={snapshot.error} />
        </Column>
      )
    case 'done':
      return (
        <Scrollbar className={styles.root}>
          <Column gap="gap.large">
            {snapshot.data.items.map((item, index) => {
              return <SssLogViewTree key={index} data={snapshot.data} item={item} index={index} />
            })}
          </Column>
        </Scrollbar>
      )
  }
}

const useStyles = makeStyles({
  root: {
    padding: '12px',
  },
})
