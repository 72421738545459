import { Row, Spacer } from '@copilot-dash/components'
import { PromiseSnapshots } from '@copilot-dash/core'
import {
  ColumnKey,
  INewTicketData,
  IRootCauseList,
  RootCauseActionTypeV2,
  RootCauseId,
  SearchTextPrefixType,
} from '@copilot-dash/domain'
import { getListByIds } from '@copilot-dash/store'
import { Button, Text } from '@fluentui/react-components'
import { ArrowDownRegular, ArrowSortRegular, ArrowUpRegular } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { compact } from 'lodash'
import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { ClusteringTickets } from './ClusteringTickets'
import { QuickSearch } from './QuickSearch'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { useStyles } from './UnRootCauseTickets.styles'
import { IAGGridTableRef } from '../../../components/AGGridTable/AGGridTable'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'
import { useTeamViewStore } from '../store'

interface IUnRootCauseTicketsProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  readonly columnSetting?: ColumnKey[]
  readonly setColumnSetting: (columns: ColumnKey[]) => void
}

interface RCRListProps extends IUnRootCauseTicketsProps {
  tableColumns: ColDef<INewTicketData>[]
  handleSelectionChange: (selectedItems: INewTicketData[] | undefined, groupKey: string) => void
  registerTableRef: (ref: IAGGridTableRef | null, issueId: RootCauseId) => void
}

const RCRListV1: FC<RCRListProps> = memo(
  ({ tableColumns, handleSelectionChange, registerTableRef, setColumnSetting, onRowClick, clickedTicketKey }) => {
    const recommendedRootCausesList = useTeamViewStore(
      (state) => state.rootCauses.teamRootCauses.data?.recommendedRootCausesList ?? [],
    )
    const unRootCausedTicketIds = useTeamViewStore((state) => state.tickets.unRootCausedTicketIds)

    const orderBy = useTeamViewStore((state) => state.orderBy)
    const rcrList: IRootCauseList = useMemo(() => {
      const defaultClusteringList = [...recommendedRootCausesList]
      let sortClusteringList = defaultClusteringList.map((item) => {
        const recommendationCount = unRootCausedTicketIds[item.issueId]?.data?.ticketCount
        return { ...item, recommendationCount: recommendationCount ?? 0 }
      })

      if (orderBy) {
        sortClusteringList = sortClusteringList
          .filter((i) => i.issueId !== '-1')
          .sort((a, b) =>
            orderBy === 'Positive'
              ? a.recommendationCount - b.recommendationCount
              : b.recommendationCount - a.recommendationCount,
          )
        sortClusteringList.push({
          issueId: '-1',
          rootCauseTitle: 'Others',
          recommendationCount: unRootCausedTicketIds['-1']?.data?.ticketCount ?? 0,
          createdTimeUtc: '',
          teamId: useTeamViewStore.getState().teams.lastSelectedTeamId!,
          rootCauseType: 'Issue',
        })
      }
      return sortClusteringList
    }, [recommendedRootCausesList, orderBy, unRootCausedTicketIds])

    const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeamName)
    useEffect(() => {
      teamName && useTeamViewStore.getState().rootCauses.fetchTeamRootCauses(teamName)
    }, [teamName])

    return (
      <>
        {rcrList.map((rootCause) => (
          <ClusteringTickets
            key={rootCause.issueId}
            rootCause={rootCause}
            columns={tableColumns}
            clickedTicketKey={clickedTicketKey}
            onRowClick={onRowClick}
            onSelectionChange={(tickets) => handleSelectionChange(tickets, rootCause.issueId)}
            tableRef={(ref) => {
              registerTableRef(ref, rootCause.issueId)
            }}
            clusteringListLength={rcrList.length}
            setColumns={setColumnSetting}
          />
        ))}
      </>
    )
  },
)
RCRListV1.displayName = 'RCRListV1'

const RCRListV2: FC<RCRListProps> = memo(
  ({ tableColumns, handleSelectionChange, registerTableRef, setColumnSetting, onRowClick, clickedTicketKey }) => {
    useClaritySectionId(ClarityScenarioIds.menuUnRootCausedDSATs)
    const filter = useTeamViewStore((state) => state.tickets.filterForm)
    const rcrIdListSnapshot = useTeamViewStore((state) => state.tickets.rcrIdList)
    const unRootCausedTicketIds = useTeamViewStore((state) => state.tickets.unRootCausedTicketIds)
    const teamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
    const productId = useTeamViewStore((state) => state.computed.productId)
    const orderBy = useTeamViewStore((state) => state.orderBy)

    // get recommended root causes using AISearch Facets
    useEffect(() => {
      useTeamViewStore.getState().tickets.fetchRCRList()
      productId && teamId && app.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
    }, [filter, productId, teamId])

    const rootCauseMap = app.store.use((state) => state.team.v2RootCauseMap)
    const rootCauseIdsByTeamSnapshot = app.store.use((state) =>
      teamId ? state.team.v2RootCauseIdsByTeam?.[teamId] : undefined,
    )
    const rcrListSnapShot = useMemo(() => {
      if (rcrIdListSnapshot.status === 'done' && rootCauseIdsByTeamSnapshot?.status === 'done')
        return getListByIds(
          rcrIdListSnapshot.data.filter((rcId) => rootCauseIdsByTeamSnapshot.data.includes(rcId)),
          rootCauseMap,
        )
      return PromiseSnapshots.waiting(Promise.resolve(() => []))
    }, [rcrIdListSnapshot.data, rcrIdListSnapshot.status, rootCauseIdsByTeamSnapshot, rootCauseMap])

    const rcrList = useMemo(() => {
      if (rcrListSnapShot.status !== 'done') return []
      // return rcrListSnapShot.data
      let sortClusteringList = rcrListSnapShot.data.map((item) => {
        const recommendationCount = unRootCausedTicketIds[item.issueId]?.data?.ticketCount
        return { ...item, recommendationCount: recommendationCount ?? 0 }
      })

      sortClusteringList = sortClusteringList
        .filter((i) => i.enable)
        .filter((i) => i.visible)
        .filter((i) => i.issueId !== '-1')
        .sort((a, b) =>
          orderBy === 'Positive'
            ? a.recommendationCount - b.recommendationCount
            : b.recommendationCount - a.recommendationCount,
        )
      sortClusteringList.push({
        issueId: '-1',
        rootCauseTitle: 'Others',
        recommendationCount: unRootCausedTicketIds['-1']?.data?.ticketCount ?? 0,
        createdTimeUtc: '',
        teamId: useTeamViewStore.getState().teams.lastSelectedTeamId!,
        rootCauseType: 'Issue',
      })

      return sortClusteringList
    }, [orderBy, rcrListSnapShot.data, rcrListSnapShot.status, unRootCausedTicketIds])

    return (
      <>
        {rcrList.map((rootCause) => (
          <ClusteringTickets
            key={rootCause.issueId}
            rootCause={rootCause}
            columns={tableColumns}
            clickedTicketKey={clickedTicketKey}
            onRowClick={onRowClick}
            onSelectionChange={(tickets) => handleSelectionChange(tickets, rootCause.issueId)}
            tableRef={(ref) => {
              registerTableRef(ref, rootCause.issueId)
            }}
            clusteringListLength={rcrList.length}
            setColumns={setColumnSetting}
          />
        ))}
      </>
    )
  },
)
RCRListV2.displayName = 'RCRListV2'

export const UnRootCauseTickets: FC<IUnRootCauseTicketsProps> = memo(
  ({ onRowClick, clickedTicketKey, columnSetting, setColumnSetting }) => {
    useClaritySectionId(ClarityScenarioIds.menuUnRootCausedDSATs)
    const tablesRef = useRef<{
      [key: string]: IAGGridTableRef | null
    }>({})

    const orderBy = useTeamViewStore((state) => state.orderBy)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const styles = useStyles()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

    const unRootCausedTicketIds = useTeamViewStore((state) => state.tickets.unRootCausedTicketIds)

    const rcrIdListSnapshot = useTeamViewStore((state) => state.tickets.rcrIdList)
    const totalRCRCountV2 = (rcrIdListSnapshot.data ?? [])
      .map((issueId) => unRootCausedTicketIds[issueId]?.data?.ticketCount)
      .filter((count) => count && count > 0).length

    const totalRCRCount = totalRCRCountV2

    const tableColumns = useMemo(() => {
      const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnSetting?.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, searchText])

    const headerText = 'Un-Root Caused DSATs'
    const unRootCauseTicketsCount = useTeamViewStore((state) => state.tickets.computed.unRootCauseTicketsCount)

    const currentSelectedGroupKeyRef = useRef('')
    const handleSelectionChange = useCallback((selectedItems: INewTicketData[] | undefined, groupKey: string) => {
      if (groupKey !== currentSelectedGroupKeyRef.current) {
        tablesRef.current[currentSelectedGroupKeyRef.current]?.clearSelection()
      }
      currentSelectedGroupKeyRef.current = groupKey
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      if ((selectedItems ?? [])?.length > 0) {
        useTeamViewStore.getState().toggleBatchUpdatePanel(true)
        selectedItems &&
          useTeamViewStore
            .getState()
            .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
        const firstTicket = selectedItems?.[0]
        if (firstTicket?.ticketId) {
          application.store.actions.fetchRootCauseRecommendationByFeedbackId(firstTicket.ticketId).then((resp) => {
            const suggestion = resp.filter((item) => item.userAction === RootCauseActionTypeV2.UserNoAction)[0]
            if (suggestion) {
              useTeamViewStore.getState().batchTicketsSubStore.setSuggestionV2(suggestion)
            }
          })
        }
      } else {
        useTeamViewStore.getState().toggleBatchUpdatePanel(false)
      }
    }, [])

    const handleClick = () => {
      switch (orderBy) {
        case 'Reverse':
          useTeamViewStore.getState().setOrderBy('Positive')
          break
        case 'Positive':
          useTeamViewStore.getState().setOrderBy('Reverse')
          break
        default:
          useTeamViewStore.getState().setOrderBy('Reverse')
          break
      }
    }

    return (
      <>
        <Row className={styles.header}>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Text size={500} weight="semibold">
            {headerText}
            {typeof unRootCauseTicketsCount === 'number' && `(${unRootCauseTicketsCount})`}
          </Text>
          <Spacer width={8} />
          {typeof unRootCauseTicketsCount === 'number' && unRootCauseTicketsCount > 0 && totalRCRCount > 1 && (
            <Button
              icon={orderBy ? orderBy === 'Positive' ? <ArrowUpRegular /> : <ArrowDownRegular /> : <ArrowSortRegular />}
              aria-label="Sort Root Cause Recommendation by counts"
              onClick={handleClick}
            >
              Sort
            </Button>
          )}
        </Row>
        <QuickSearch />
        <RCRListV2
          tableColumns={tableColumns}
          handleSelectionChange={handleSelectionChange}
          registerTableRef={function (ref: IAGGridTableRef | null, issueId: RootCauseId): void {
            tablesRef.current[issueId] = ref
          }}
          setColumnSetting={setColumnSetting}
          onRowClick={onRowClick}
          clickedTicketKey={clickedTicketKey}
        />
      </>
    )
  },
)

UnRootCauseTickets.displayName = 'UnRootCauseTickets'
