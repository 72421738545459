import { Column } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { TicketOutlineBar } from './TicketOutlineBar'
import { TicketOutlineBody } from './TicketOutlineBody'

export function TicketOutline() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <TicketOutlineBar />
      <TicketOutlineBody />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground4,

    '& > *:first-child': {
      flex: '0 0 auto',

      borderBottom: `1px solid ${tokens.colorNeutralStroke2}`,
    },

    '& > *:last-child': {
      flex: '1 1 0',
    },
  },
})
