import { MenuItem, useIsOverflowItemVisible } from '@fluentui/react-components'
import { ITabItem } from '../../store/IState'
import { TabStore } from '../../TabStore'

interface IProps {
  readonly item: ITabItem
}

export function HeaderTabBarMenuItem({ item }: IProps) {
  const actions = TabStore.useActions()
  const isVisible = useIsOverflowItemVisible(item.name)

  if (isVisible) {
    return null
  }

  return (
    <MenuItem
      onClick={() => {
        actions.activate(item.name)
      }}
    >
      <div>{item.title ?? item.name}</div>
    </MenuItem>
  )
}
