import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { ReactNode, ComponentProps, useState, useMemo } from 'react'
import Markdown, { Components } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { ErrorViewBoundary } from '../Error'
import { ImagePreviewModal } from '../Image/ImagePreviewModal'

const TranslationIndicatorMarkdown = '![](/svg/translation.svg)'

interface IProps {
  readonly children?: string | null | undefined
  readonly className?: string
  readonly isTranslated?: boolean
  readonly enableImagePreview?: boolean
}

export function MarkdownView({ isTranslated, children, className, enableImagePreview }: IProps): ReactNode {
  const styles = useStyles()
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const DEFAULT_COMPONENTS: Components = {
    a: ({ node, ...props }) => {
      return <a {...props} target="_blank" rel="noopener noreferrer" />
    },
    table: ({ node, ...props }) => {
      return (
        <div className={styles.table}>
          <table {...props} />
        </div>
      )
    },
    img: (props: ComponentProps<'img'>) => {
      if (enableImagePreview) {
        return <img {...props} style={{ cursor: 'pointer', maxWidth: '100%' }} onClick={handleImageClick} />
      } else {
        return <img {...props} style={{ maxWidth: '100%' }} />
      }
    },
  }

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    const imageUrl = (event.target as HTMLImageElement).src
    setSelectedImage(imageUrl)
  }

  const childrenWithTranslationIndicator = useMemo(() => {
    if (isTranslated) {
      return `${TranslationIndicatorMarkdown} ${children}`
    }

    return children
  }, [children, isTranslated])

  return (
    <ErrorViewBoundary level="component" label="MarkdownView">
      <Markdown
        className={mergeClasses('Markdown', styles.markdown, className)}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={DEFAULT_COMPONENTS}
      >
        {childrenWithTranslationIndicator}
      </Markdown>
      {selectedImage && <ImagePreviewModal imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />}
    </ErrorViewBoundary>
  )
}

const useStyles = makeStyles({
  markdown: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',

    '& h1, h2, h3, h4, h5, h6, h7, h8, h9': {
      lineHeight: 'normal',
      margin: '0 4px',
    },

    '& img': {
      verticalAlign: 'middle',
    },
  },
  table: {
    overflow: 'auto',

    '& table': {
      width: 'max-content',
      borderCollapse: 'collapse',

      '& th, td': {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        padding: '4px 12px',
        maxWidth: '240px',
      },
    },
  },
})
