import { Row } from '@copilot-dash/components'
import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function MainBadge() {
  const styles = useStyles()
  const actions = TicketDiagnosticStore.useActions()
  const isOpen = TicketDiagnosticStore.use((state) => state.isOpen)

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (isOpen) {
      actions.setOpen(false)
      actions.setActiveTab(TicketDiagnosticTab.Home)
    } else {
      actions.setOpen(true)
    }
  }

  return (
    <Row
      className={styles.root}
      onClick={handleOnClick}
      title={isOpen ? 'Close' : 'Diagnostic'}
      vAlign="end"
      hAlign="end"
    >
      <Text size={200}>🩺</Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    cursor: 'pointer',
    bottom: 0,
    right: 0,
    width: '32px',
    height: '32px',
    clipPath: 'polygon(100% 100%, 0 100%, 100% 0)',
    backgroundColor: tokens.colorBrandBackground2,
    zIndex: 2,
    padding: '2px',

    ':hover': {
      backgroundColor: tokens.colorBrandBackground2Hover,
    },
  },
})
