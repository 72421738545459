import { Row } from '@copilot-dash/components'
import { Spinner } from '@fluentui/react-components'

export const CardLoading: React.FC = () => {
  return (
    <Row vAlign="center" hAlign="center" style={{ height: '200px' }}>
      <Spinner label="Loading..." />
    </Row>
  )
}
