import { Column } from '@copilot-dash/components'
import { IUserp3sDebugData } from '@copilot-dash/domain'
import { Button, Field, makeStyles, ProgressBar, Spinner } from '@fluentui/react-components'
import { ArrowDownloadRegular, OpenRegular } from '@fluentui/react-icons'
import { AxiosProgressEvent } from 'axios'
import { useState } from 'react'
import { RouteLink } from '../../../../../router'

const URL_DEBUG_IN_3S_EXPLAIN =
  'https://devui.sdf-devui.substrate-turing-enterprisedevui.eastus-sdf.cosmic-ppe.office.net/3sexplain'

interface IProps {
  readonly ticketId: string
  readonly data: IUserp3sDebugData
}

export function UserpSearch3sDebugContent({ ticketId, data }: IProps) {
  const styles = useStyles()

  const [waiting, setWaiting] = useState(false)
  const [event, setEvent] = useState<AxiosProgressEvent | null>(null)

  const handleClick = async () => {
    if (waiting) {
      return
    }

    try {
      setWaiting(true)

      const blob = await app.api.copilotDash.getTicketBlobFile(ticketId, data.url, {
        onDownloadProgress: (event) => {
          setEvent(event)
        },
      })

      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      const baseName = data.name ?? 'file'
      const fileName = baseName.endsWith('.json') ? baseName : `${baseName}.json`

      a.href = url
      a.download = fileName
      a.click()
      a.remove()

      URL.revokeObjectURL(url)
    } catch (error) {
      Logger.trace.error('Failed to download 3S debug data file', error)
    } finally {
      setWaiting(false)
    }
  }

  return (
    <Column className={styles.root} hAlign="start">
      <div>
        <Button
          onClick={handleClick}
          disabled={waiting}
          icon={waiting ? <Spinner size="tiny" /> : <ArrowDownloadRegular />}
        >
          {waiting ? 'Downloading...' : 'Download'}
        </Button>
      </div>

      <RouteLink path={URL_DEBUG_IN_3S_EXPLAIN} openInNewTab>
        <Button icon={<OpenRegular />}>Debug in 3sExplain</Button>
      </RouteLink>

      <Field
        className={styles.downloadProgress}
        validationMessage={event ? format(event) : ''}
        validationState="none"
        style={{
          opacity: waiting ? 1 : 0,
        }}
      >
        <ProgressBar max={1} value={event?.progress ?? 0} />
      </Field>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '32px 16px',
    gap: '16px',
  },
  downloadProgress: {
    padding: '16px 0',
    width: '100%',
  },
})

function format(event: AxiosProgressEvent) {
  if (!event.total) {
    return ''
  }

  return `${formatBytes(event.loaded)} / ${formatBytes(event.total)}`
}

function formatBytes(bytes: number) {
  if (bytes >= 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`
  }

  return `${(bytes / 1024).toFixed(2)} KB`
}
