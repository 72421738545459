import { IClusterJobData } from '@copilot-dash/domain'
import { ColDef } from 'ag-grid-community'
import { ColumnAmlClusterName } from './ColumnAmlClusterName'
import { ColumnClusteringRequestCreator } from './ColumnClusteringRequestCreator'
import { ColumnClusteringRequestStatus } from './ColumnClusteringRequestStatus'
import { ColumnClusterJobConfiguration } from './ColumnClusterJobConfiguration'
import { ColumnStartTimeUTC } from './ColumnStartTimeUTC'
import { ColumnWorkflowStartTimestamp } from './ColumnWorkflowStartTimestamp'

export const AllClusterJobTableColumns = (): ColDef<IClusterJobData>[] => [
  ColumnAmlClusterName,
  ColumnClusterJobConfiguration,
  ColumnStartTimeUTC,
  ColumnClusteringRequestCreator,
  ColumnWorkflowStartTimestamp,
  ColumnClusteringRequestStatus,
]
