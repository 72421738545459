import { INewTicketData, ITicketData } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStyles } from './ColumnComponent.styles'
import { ErrorView } from '../Error'
import { KeywordsHighLightText } from '../Text/KeywordsHighLightText'

export function ResponsiveEmailColumnComponent({
  item,
  keyWords,
}: {
  item: ITicketData | INewTicketData
  keyWords?: string
}) {
  const styles = useStyles()

  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const tableCellContent = useMemo(() => {
    switch (inView) {
      case true:
        if (item.ticketId) return <EmailColumnComponent ticketId={item.ticketId} keyWords={keyWords} />
        else return <Text className={styles.grayColor}>N/A</Text>
      default:
        return null
    }
  }, [inView, item, keyWords, styles.grayColor])

  return <div ref={ref}>{tableCellContent}</div>
}

function EmailColumnComponent({
  ticketId,
  keyWords,
}: {
  ticketId: string
  keyWords?: string
  supportToolTip?: boolean
}) {
  const styles = useStyles()
  const email = application.store.use.getTicketEmail(ticketId)

  const renderContent = () => {
    switch (email.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent" className={styles.skeleton}>
            <SkeletonItem className={styles.skeletonItem1} />
            <SkeletonItem className={styles.skeletonItem2} />
          </Skeleton>
        )
      case 'done':
        return <KeywordsHighLightText text={email.data} keywords={keyWords} isTwoLinesTruncate={true} />
      case 'error':
        return <ErrorView type="inline" error={email.error} />
    }
  }

  return <div>{renderContent()}</div>
}
