import { Column } from '@copilot-dash/components'
import {
  Button,
  makeStyles,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { tokens } from '@fluentui/tokens'
import { TicketScreen } from './TicketScreen'
import { ClarityScenarioIds, useClaritySectionId } from '../../utils/useClarityPageId'

interface IProps {
  readonly ticketId: string
  readonly open: boolean
  readonly onClose: () => void
}

export function TicketScreenDialog({ ticketId, open, onClose }: IProps) {
  useClaritySectionId(ClarityScenarioIds.ticketDetailPanel)
  const styles = useStyles()

  return (
    <OverlayDrawer
      position="bottom"
      open={open}
      onOpenChange={onClose}
      className={styles.root}
      style={{ flex: '1 1 0' }}
    >
      {/* TODO: There is a bug that Drawer MUST have a header to avoid focus issue. */}
      {/* TODO: We need to replace this drawer with other components */}
      <DrawerHeader className={styles.header}>
        <DrawerHeaderTitle action={<Button appearance="subtle" aria-label="Close" icon={<Dismiss24Regular />} />}>
          <div />
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody className={styles.body}>
        <Column fill>
          <TicketScreen ticketId={ticketId} onClose={onClose} />
        </Column>
      </DrawerBody>
    </OverlayDrawer>
  )
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
    height: 'calc(100vh - 80px)',

    backgroundColor: tokens.colorNeutralBackground3,
    borderTopLeftRadius: tokens.borderRadiusLarge,
    borderTopRightRadius: tokens.borderRadiusLarge,
  },
  header: {
    height: '0',
    width: '0',
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
  },
  body: {
    padding: 0,
    margin: 0,
    height: '100%',
    cursor: 'default',
    overflow: 'hidden',
  },
})
