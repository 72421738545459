import { useMergeRefs, FloatingPortal, FloatingArrow } from '@floating-ui/react'
import { tokens } from '@fluentui/react-components'
import * as React from 'react'
import { TooltipContext, TooltipOptions, useTooltip, useTooltipContext } from './hooks'
import { ThemeProvider } from '../../theme/ThemeProvider'

export function FloatingTooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

export const FloatingTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = true, ...props }, propRef) {
  const context = useTooltipContext()
  const childrenRef = (children as unknown as { ref: React.Ref<unknown> | undefined }).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export const FloatingTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & { arrowcolor?: string }
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open) return null

  return (
    <FloatingPortal>
      <ThemeProvider>
        <TooltipContentInner
          ref={ref}
          style={{
            boxShadow: tokens.shadow28,
            borderRadius: tokens.borderRadiusMedium,
            backgroundColor: tokens.colorNeutralBackground3,
            zIndex: 1000000, // keep it the same as the Fluent UI default Drawer/Popover/Dialog
            overflow: 'visible',
            padding: '16px',
            ...context.floatingStyles,
            ...style,
          }}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </TooltipContentInner>
      </ThemeProvider>
    </FloatingPortal>
  )
})

const TooltipContentInner = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement> & { arrowcolor?: string }>(
  function TooltipContentInner({ className, ...props }, propRef) {
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    return (
      <div ref={ref} {...props}>
        <div className={className}>{props.children}</div>
        <FloatingArrow
          ref={context.arrowRef}
          context={context.context}
          fill={props.arrowcolor ?? tokens.colorNeutralBackground3}
        />
      </div>
    )
  },
)
