import { Logger } from '@copilot-dash/logger'
import { z } from 'zod'
import { AutomationApiClient } from './AutomationApiClient'
import { AutomationNotificationService } from './AutomationNotificationService'
import {
  ApiClusterBaseResponseData,
  ApiClusterClusterConfigData,
  ApiClusterJobData,
  ApiClusterJobDataWithResult,
  ApiCreateClusterRequest,
  ApiCreateRagQueryRequest,
  ApiFCConversationMessageData,
  ApiGetClusterJobListRequest,
  ApiGetClusterRequestResult,
  ApiGetHistoryRagQuery,
  ApiHistortyRagQueryResponse,
  ApiPublishClusterRequestResult,
  ApiUpdateHistoryReadQuery,
  apiClusterBaseResponseSchema,
  apiClusterConfigDataResponseSchema,
  apiClusterJobDataSchema,
  apiClusterJobDataWithResultSchema,
  apiClusterJobResponseSchema,
  apiFCConversationMessageDataSchema,
  apiHistortyRagQueryResponseSchema,
} from './types'

import { ApiClient } from '../../client/ApiClient'
import { DashApiOptions } from '../../DashApiOptions'

const RAG_HUB = 'processingHubRag'
const CLUSTER_HUB = 'processingHubClustering'
export class AutomationApi {
  private readonly client: ApiClient
  private readonly ragSignalRService: AutomationNotificationService
  private readonly clusterSignalRService: AutomationNotificationService
  constructor(options: DashApiOptions) {
    this.client = new AutomationApiClient(options)
    this.ragSignalRService = new AutomationNotificationService(options, RAG_HUB)
    this.clusterSignalRService = new AutomationNotificationService(options, CLUSTER_HUB)
  }

  getClusterJobListByProduct(params: ApiGetClusterJobListRequest): Promise<Array<ApiClusterJobData>> {
    return this.client.get(`/listClusteringRequest`, {
      params: params,
      schema: apiClusterJobResponseSchema,
    })
  }

  getDataSetListByProduct(params: ApiGetClusterJobListRequest): Promise<Array<ApiClusterJobData>> {
    return this.client.get(`/listDataset`, {
      params: params,
      schema: apiClusterJobResponseSchema,
    })
  }

  createClusteringRequest(data: ApiCreateClusterRequest): Promise<ApiClusterJobData> {
    return this.client.post(`/createClusteringRequest`, {
      data: data,
      schema: apiClusterJobDataSchema,
    })
  }

  getClusteringResult(params: ApiGetClusterRequestResult): Promise<ApiClusterJobDataWithResult> {
    return this.client.get(`/getClusteringResultDetail`, {
      params: params,
      schema: apiClusterJobDataWithResultSchema,
    })
  }

  cancelClustering(data: ApiGetClusterRequestResult): Promise<ApiClusterBaseResponseData> {
    return this.client.post(`/cancelClustering`, {
      params: data,
      schema: apiClusterBaseResponseSchema,
    })
  }

  getClusteringConfigList(params: ApiGetClusterJobListRequest): Promise<Array<ApiClusterClusterConfigData>> {
    return this.client.get(`/getClusteringConfig`, {
      params: params,
      schema: apiClusterConfigDataResponseSchema,
    })
  }

  publishClusteringResult(params: ApiPublishClusterRequestResult): Promise<ApiClusterBaseResponseData> {
    return this.client.post(`/publishClusteringResult`, {
      data: params,
      schema: apiClusterBaseResponseSchema,
    })
  }

  getHistoryRagQuery(params: ApiGetHistoryRagQuery): Promise<ApiHistortyRagQueryResponse> {
    return this.client.get(`/getHistoryRagQueries`, {
      params: params,
      schema: apiHistortyRagQueryResponseSchema,
    })
  }

  submitRagQuery(data: ApiCreateRagQueryRequest): Promise<ApiFCConversationMessageData> {
    return this.client.post(`/submitRagQuery`, {
      data: data,
      timeout: 120000,
      schema: apiFCConversationMessageDataSchema,
    })
  }

  updateUserHasReadRagQuery(data: ApiUpdateHistoryReadQuery): Promise<boolean> {
    return this.client.post(`/updateUserHasReadRagQuery`, {
      data: data,
      schema: z.boolean(),
    })
  }

  // Start the Feedback Copilot SignalR connection
  async startConnection(connectionName: 'FeedbackCopilot' | 'Cluster'): Promise<void> {
    Logger.trace.info(`${connectionName} start Connection`)
    switch (connectionName) {
      case 'FeedbackCopilot':
        await this.ragSignalRService.startConnection()
        break
      case 'Cluster':
        await this.clusterSignalRService.startConnection()
        break
      default:
        Logger.trace.error('Invalid connection name')
        break
    }
  }

  // Stop the SignalR connection
  async stopConnection(connectionName: 'FeedbackCopilot' | 'Cluster'): Promise<void> {
    Logger.trace.info(`${connectionName} stop Connection`)
    switch (connectionName) {
      case 'FeedbackCopilot':
        await this.ragSignalRService.stopConnection()
        break
      case 'Cluster':
        await this.clusterSignalRService.stopConnection()
        break
      default:
        Logger.trace.error('Invalid connection name')
        break
    }
  }

  async subscribeClusterResultToProduct(productId: number): Promise<void> {
    try {
      await this.clusterSignalRService.connection.invoke('JoinProductGroup', productId.toString())
      Logger.trace.info(`Subscribed to product: ${productId}`)
    } catch (error) {
      setTimeout(() => this.subscribeClusterResultToProduct(productId), 5000) // restart connection after 5 seconds
      Logger.trace.error('Error subscribing to ID:', error)
    }
  }

  async leaveClusterResultToProduct(productId: number): Promise<void> {
    try {
      await this.clusterSignalRService.connection.invoke('JoinProductGroup', productId.toString())
      Logger.trace.info(`Subscribed to product: ${productId}`)
    } catch (error) {
      setTimeout(() => this.subscribeClusterResultToProduct(productId), 5000) // restart connection after 5 seconds
      Logger.trace.error('Error subscribing to ID:', error)
    }
  }

  // Listen for messages from the server
  onReceiveMessage(callback: (result: ApiFCConversationMessageData) => void): void {
    Logger.trace.info('Receive messages from the server')
    this.ragSignalRService.connection.on('ReceiveSummary', callback)
  }

  onReceiveClusterResult(callback: (result: Array<ApiClusterJobData>) => void): void {
    Logger.trace.info('Receive cluster result from the server')
    this.clusterSignalRService.connection.on('ReceiveSummary', callback)
  }
}
