import { ColDef } from 'ag-grid-community'
import { IState } from './IState'

interface IParams {
  readonly data: unknown[]
  readonly columns: ColDef<unknown>[]
  readonly filter: string | undefined
}

export function createState({ data, columns, filter }: IParams): IState {
  return {
    data: data,
    columns: columns,
    filter: filter,
    searchResultCount: undefined,
  }
}
