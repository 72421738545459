import { z } from 'zod'

const apiUserpGroup1ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
  }),
)

const apiUserpGroup1ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiUserpGroup1ReplayResponseSchema.optional(),
  }),
)

export const apiUserpGroup1Schema = z.array(apiUserpGroup1ItemSchema)
