import { makeStyles, shorthands } from '@fluentui/react-components'

export const useStyles = makeStyles({
  sendButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '8px',
    bottom: '10px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  baseLoadingStyle: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
  },
  sendLoading: {
    right: '18px',
    bottom: '18px',
  },
  sendEditLoading: {
    right: '-10px',
    bottom: '0px',
  },
  cancelEditButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '-30px',
    bottom: '24px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  submitEditButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '-30px',
    bottom: '0px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  submitDescriptionBtn: {
    position: 'absolute',
    cursor: 'pointer',
    right: '6px',
    bottom: '6px',
    fontSize: '300',
    fontWeight: '600',
    width: '69px',
    height: '34px',
  },
  cancelDescriptionBtn: {
    position: 'absolute',
    cursor: 'pointer',
    right: '70px',
    bottom: '6px',
    fontSize: '300',
    fontWeight: '600',
    width: '69px',
    height: '34px',
  },
})
