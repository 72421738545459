import { PromiseSnapshot } from '@copilot-dash/core'
import { ITeamData, ProductIds, TeamId } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTeams(context: IDashStoreContext, productId: ProductIds): PromiseSnapshot<ITeamData[]> {
  return context.getOrFetch<ITeamData[]>({
    get: (state) => {
      return state.team.teams?.[productId]
    },
    set: (state, snapshot) => {
      const teams = (state.team.teams ??= {})
      teams[productId] = snapshot
    },
    fetch: async () => {
      if (productId === ProductIds.All) {
        const response = await context.api.copilotDash.getTeamListFlatten()
        return response
          .map((team) => ({
            id: String(team.teamId),
            name: team.teamName,
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      }
      const response = await context.api.copilotDash.getTeamListFlatten(productId)
      return response
        .map((team) => ({
          id: String(team.teamId),
          name: team.teamName,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  })
}

export function getTeamsMap(context: IDashStoreContext): PromiseSnapshot<Record<TeamId, ITeamData>> {
  return context.getOrFetch<Record<TeamId, ITeamData>>({
    get: (state) => state.team.teamsMap,
    set: (state, snapshot) => {
      state.team.teamsMap = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getTeamListFlatten()
      return response
        .map((team) => ({
          id: String(team.teamId),
          name: team.teamName,
        }))
        .reduce(
          (acc, team) => {
            acc[team.id] = team
            return acc
          },
          {} as Record<TeamId, ITeamData>,
        )
    },
  })
}
