import { Column, Row } from '@copilot-dash/components'
import { Text, Divider } from '@fluentui/react-components'
import { GlobeRegular, RocketFilled } from '@fluentui/react-icons'
import { useStyles } from './PermissionScreen.style'
import { RouteLink } from '../../router'

export function PermissionScreen() {
  const styles = useStyles()
  return (
    <Row hAlign="center" vAlign="center">
      <Row className={styles.card}>
        <Column className={styles.container}>
          <Column hAlign="center" className={styles.header}>
            <RocketFilled aria-label="Rocket" className={styles.headerIcon} />
            <Text className={styles.headerTitle}>You don&apos;t have permission to view General Diagnostics</Text>
          </Column>
          <Divider style={{ maxHeight: '20px' }} />
          <Text className={styles.boldText}>How to request permission:</Text>
          <Column className={styles.bodyStep}>
            <Row className={styles.step1}>
              <Text className={styles.step1Text}>Step 1: Go to </Text>
              <RouteLink
                openInNewTab
                path="https://coreidentity.microsoft.com/manage/Entitlement/entitlement/copilotgener-kqlp"
                className={styles.link}
              >
                <Row>
                  <GlobeRegular className={styles.bodyIcon} fontSize={18} />
                  <Text>Copilot Feedback General Diagnostics</Text>
                </Row>
              </RouteLink>
            </Row>
            <Text className={styles.step}>Step 2: Fill in Justification and submit the request</Text>
            <Text className={styles.step}>
              Step 3: Get your manager&apos;s approval if you are in Rajesh&apos;s Organization; otherwise, the request
              will be reviewed and approved by the entitlement owner if you are from other organizations..
            </Text>
            <Text className={styles.step}>
              Step 4: Please expect the permission to be granted within 24-48 hours of submission.
            </Text>
          </Column>
        </Column>
      </Row>
    </Row>
  )
}
