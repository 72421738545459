import { IRootCauseData, IRootCauseList, ITeamList, ProductIds, TeamId } from '@copilot-dash/domain'
import { Field, mergeClasses } from '@fluentui/react-components'
import { isEqual } from 'lodash'
import {
  CSSProperties,
  useImperativeHandle,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { useForm, Controller, useWatch, useFormState } from 'react-hook-form'
import { z } from 'zod'
import { Area } from './fields/Area'
import { AssignTo, EmailAddress } from './fields/AssignTo'
import { CustomTags } from './fields/CustomTags'
import { NoActionableReason } from './fields/NoActionableReason'
import { Priority } from './fields/Priority'
import { RootCauses } from './fields/RootCauses'
import { State } from './fields/State'
import { useStyles } from './TicketActionForm.styles'
import { COPILOT_DASH_STATE_OPTIONS, ElementType, EMPTY_ROOT_CAUSE } from './utils'
import { useGlobalStore } from '../../store'
import { allowRootCause } from '../../utils/productFilters'
import { sleep } from '../../utils/time'

export enum FieldNames {
  state = 'state',
  noActionableReason = 'noActionableReason',
  priority = 'priority',
  area = 'area',
  assignTo = 'assignTo',
  customTags = 'customTags',
  rootCauseIDs = 'rootCauseIDs',
}
type Filed = `${FieldNames}` | '.'

interface IGridLayout {
  templateColumns: string
  templateRows: string
  templateAreas: Filed[][]
  columnGap?: string
  rowGap?: string
}

interface DefaultValues {
  [FieldNames.state]?: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>
  [FieldNames.noActionableReason]?: string
  [FieldNames.priority]?: number
  [FieldNames.area]?: TeamId
  [FieldNames.assignTo]?: EmailAddress
  [FieldNames.customTags]?: string[]
  [FieldNames.rootCauseIDs]?: string[]
}

type ValuesWithExtraInfo = DefaultValues & {
  rootCauseList: IRootCauseList
  teamList: ITeamList
}

export interface ITicketActionFormProps {
  productId?: ProductIds
  gridLayout?: IGridLayout
  labelWidth?: CSSProperties['width']
  orientation?: 'horizontal' | 'vertical'
  defaultValues?: DefaultValues
  defaultValueTexts?: {
    [FieldNames.state]?: string
    [FieldNames.priority]?: string
    [FieldNames.area]?: string
    [FieldNames.assignTo]?: string
    [FieldNames.customTags]?: string
    [FieldNames.rootCauseIDs]?: string
  }
  disabled?: {
    [FieldNames.state]?: boolean
    [FieldNames.noActionableReason]?: boolean
    [FieldNames.priority]?: boolean
    [FieldNames.area]?: boolean
    [FieldNames.assignTo]?: boolean
    [FieldNames.customTags]?: boolean
    [FieldNames.rootCauseIDs]?: boolean
  }
  className?: string
  rootCauseMaxWidth?: string
  fieldsProps?: {
    state?: Partial<React.ComponentProps<typeof Field>>
    noActionableReason?: Partial<React.ComponentProps<typeof Field>>
    priority?: Partial<React.ComponentProps<typeof Field>>
    area?: Partial<React.ComponentProps<typeof Field>>
    assignTo?: Partial<React.ComponentProps<typeof Field>>
    customTags?: Partial<React.ComponentProps<typeof Field>>
    rootCauses?: Partial<React.ComponentProps<typeof Field>>
  }
  enableRules?: {
    [FieldNames.state]?: boolean
    [FieldNames.noActionableReason]?: boolean
    [FieldNames.priority]?: boolean
    [FieldNames.area]?: boolean
    [FieldNames.assignTo]?: boolean
    [FieldNames.customTags]?: boolean
    [FieldNames.rootCauseIDs]?: boolean
  }
  ticketInfo?: {
    teamName?: string
    customTags?: string[]
  }
  onModified?: (isModified: boolean, data: ValuesWithExtraInfo) => void
  children?: React.ReactNode
}

type WatchFn = <T extends keyof ValuesWithExtraInfo>(
  values: ValuesWithExtraInfo,
  key: T,
  value: ValuesWithExtraInfo[T],
) => void
export interface ITicketActionFormRef {
  setValues: (values: Partial<DefaultValues>) => void
  getValues: () => ValuesWithExtraInfo
  watch: (fn: WatchFn) => void
  validate: (filedName?: FieldNames) => Promise<boolean>
}

const formValuesTypeGuard = (value: unknown): value is DefaultValues => {
  const result = z
    .object({
      state: z.string().optional(),
      noActionableReason: z.string().optional(),
      priority: z.number().optional(),
      area: z.string().optional(),
      assignTo: z.string().optional(),
      rootCauseIDs: z.array(z.string()).optional(),
    })
    .safeParse(value)
  return result.success
}

export const TicketActionForm = memo(
  forwardRef<ITicketActionFormRef, ITicketActionFormProps>(
    (
      {
        gridLayout,
        labelWidth = '88px',
        defaultValues = {},
        orientation = 'horizontal',
        defaultValueTexts = {
          state: '',
          priority: '',
          area: '',
          assignTo: '',
          customTags: '',
          rootCauseIDs: '',
        },
        disabled,
        className,
        rootCauseMaxWidth,
        fieldsProps,
        enableRules = {
          state: true,
          noActionableReason: true,
          priority: true,
          area: true,
          assignTo: false,
          customTags: false,
          rootCauseIDs: true,
        },
        ticketInfo,
        onModified,
        children,
        productId,
      },
      ref,
    ) => {
      const styles = useStyles()
      const watchFnRef = useRef<WatchFn | null>(null)
      const [showNoActionableReason, setShowNoActionableReason] = useState(false)
      const [formValues, setFormValues] = useState<DefaultValues>(defaultValues)
      const teamId = formValues?.area

      const { control, watch, getValues, trigger, reset } = useForm({
        defaultValues,
        mode: 'onChange',
      })

      const ticketStatus = useWatch({
        control,
        name: FieldNames.state,
        defaultValue: defaultValues.state,
        exact: true,
      })
      const { errors } = useFormState({ control })

      const teamListSnapshot = application.store.use.getTeams(productId ?? ProductIds.M365Chat)
      const areaListAndRootCauseList = useGlobalStore((state) => state.areaListAndRootCauseList)
      const teamLoading = teamListSnapshot.status === 'waiting'
      const rootCauseLoading = areaListAndRootCauseList?.status === 'waiting'
      const teamList = useMemo(() => teamListSnapshot.data ?? [], [teamListSnapshot])
      const teamIdList = useMemo(() => teamList.map((item) => item.id), [teamList])

      const rootCausesGroupedByTeam = useMemo(() => {
        if (areaListAndRootCauseList.status !== 'done') return {}
        const obj = areaListAndRootCauseList.data?.reduce(
          (acc, item) => {
            const targetTeam = teamList.find((team) => team.id === item.teamId)
            const areaPath = targetTeam?.name
            const teamId = targetTeam?.id
            if (areaPath && teamId && teamIdList.includes(teamId)) {
              acc[areaPath] = item.rootCauseList
            }
            return acc
          },
          {} as Record<string, IRootCauseList>,
        )
        return obj
      }, [areaListAndRootCauseList, teamList, teamIdList])

      const getFlattenRootCauseList = useCallback(() => {
        if (useGlobalStore.getState().areaListAndRootCauseList.status !== 'done') {
          return []
        }
        const nestedList = useGlobalStore.getState().areaListAndRootCauseList.data ?? []
        const obj = nestedList.reduce(
          (acc, item) => {
            const targetTeam = teamList.find((team) => team.id === item.teamId)
            const areaPath = targetTeam?.name
            const teamId = targetTeam?.id
            if (areaPath && teamId && teamIdList.includes(teamId)) {
              acc[areaPath] = item.rootCauseList
            }
            return acc
          },
          {} as Record<string, IRootCauseList>,
        )
        return obj ? Object.values(obj).flat() : []
      }, [teamIdList, teamList])

      useEffect(() => {
        if (areaListAndRootCauseList.status !== 'none') return

        if (teamList.length > 0) {
          useGlobalStore.getState().fetchAreaListAndRootCauseList(teamList)
        }
      }, [areaListAndRootCauseList.status, teamList])

      useEffect(() => {
        if (productId && teamId) {
          application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
        }
      }, [productId, teamId])

      const handleValuesChange = useCallback(
        (data: unknown, name?: string, values?: unknown) => {
          if (!formValuesTypeGuard(data)) return
          setFormValues(data)
          const flattenRootCauseList = getFlattenRootCauseList()
          const dataWithRootCauseDetails: ValuesWithExtraInfo = {
            ...data,
            rootCauseList: (data['rootCauseIDs'] || [])
              .map((id) => flattenRootCauseList.find((item) => item.issueId === id))
              .filter((v): v is IRootCauseData => !!v),
            teamList,
          }
          if (!isEqual(data, defaultValues)) {
            onModified?.(true, dataWithRootCauseDetails)
          } else {
            onModified?.(false, dataWithRootCauseDetails)
          }

          watchFnRef.current?.(
            dataWithRootCauseDetails,
            name as keyof ValuesWithExtraInfo,
            dataWithRootCauseDetails[name as keyof ValuesWithExtraInfo],
          )
          if (name === FieldNames.area) {
            const area = (values as DefaultValues)?.area
            const currentArea = teamList.find((item) => item.id === area)
            if (productId && currentArea?.id) {
              application.store.actions.getOrFetchRootCauseListByTeam(productId, currentArea.id)
            }
          }
        },
        [defaultValues, getFlattenRootCauseList, onModified, productId, teamList],
      )
      const handleValuesChangeRef = useRef(handleValuesChange)
      handleValuesChangeRef.current = handleValuesChange

      useEffect(() => {
        const subscription = watch((data, { name, values }) => {
          handleValuesChangeRef.current(data, name, values)
        })
        return () => subscription.unsubscribe()
      }, [watch])

      useEffect(() => {
        const values = getValues()
        handleValuesChangeRef.current(values)
      }, [getValues])

      useEffect(() => {
        setShowNoActionableReason(ticketStatus === 'Closed - Not Actionable')

        // NOTE: @Ethan - Root Cause validation is dynamic when user selecting different ticket status. So we need to validate it when ticket status changed.
        setTimeout(() => {
          trigger('rootCauseIDs')
          trigger('noActionableReason')
        }, 100)
      }, [ticketStatus, trigger])

      useImperativeHandle(
        ref,
        () => ({
          setValues: (values) => {
            if (values) {
              const old = getValues()
              reset({ ...old, ...values })
            }
          },
          getValues: () => {
            const values = getValues()
            const flattenRootCauseList = getFlattenRootCauseList()
            return {
              ...values,
              rootCauseList: (values['rootCauseIDs'] || [])
                .map((id) => flattenRootCauseList.find((item) => item.issueId === id))
                .filter((v): v is IRootCauseData => !!v),
              teamList,
            }
          },
          watch: (fn) => {
            watchFnRef.current = fn
          },
          validate: async (filedName) => {
            await sleep(100)
            if (filedName) {
              return trigger(filedName)
            } else {
              return trigger()
            }
          },
        }),
        [getValues, reset, getFlattenRootCauseList, teamList, trigger],
      )

      const subGridTemplateColumns = orientation === 'horizontal' ? `${labelWidth} 1fr` : `1fr`
      return (
        <div
          className={mergeClasses(styles.grid, className)}
          style={{
            gridTemplateColumns: gridLayout?.templateColumns,
            gridTemplateRows: gridLayout?.templateRows,
            gridTemplateAreas: gridLayout?.templateAreas.map((row) => `"${row.join(' ')}"`).join(' '),
            columnGap: gridLayout?.columnGap,
            rowGap: gridLayout?.rowGap,
          }}
        >
          <Controller
            name={FieldNames.state}
            control={control}
            rules={enableRules.state ? { required: 'Required' } : undefined}
            render={({ field }) => {
              return (
                <State
                  disabled={disabled?.state}
                  defaultValueText={defaultValueTexts?.state}
                  value={field.value}
                  onChange={field.onChange}
                  className={styles.state}
                  orientation={orientation}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  fieldProps={{
                    validationState: errors['state']?.message ? 'error' : 'none',
                    validationMessage: errors['state']?.message,
                    ...(fieldsProps?.state ?? {}),
                  }}
                />
              )
            }}
          />
          {showNoActionableReason ? (
            <Controller
              shouldUnregister={true}
              name={FieldNames.noActionableReason}
              control={control}
              rules={enableRules.noActionableReason ? { required: 'Required' } : undefined}
              render={({ field }) => {
                if (!showNoActionableReason) return <></>
                return (
                  <NoActionableReason
                    disabled={disabled?.noActionableReason}
                    value={field.value}
                    onChange={field.onChange}
                    className={styles.noActionableReason}
                    orientation={orientation}
                    style={{ gridTemplateColumns: subGridTemplateColumns }}
                    fieldProps={{
                      validationState: errors['noActionableReason']?.message ? 'error' : 'none',
                      validationMessage: errors['noActionableReason']?.message,
                      ...(fieldsProps?.noActionableReason ?? {}),
                    }}
                  />
                )
              }}
            />
          ) : null}
          <Controller
            name={FieldNames.priority}
            control={control}
            rules={enableRules.priority ? { required: 'Required' } : undefined}
            render={({ field }) => {
              return (
                <Priority
                  disabled={disabled?.priority}
                  defaultValueText={defaultValueTexts?.priority}
                  value={field.value}
                  onChange={field.onChange}
                  className={styles.priority}
                  orientation={orientation}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  fieldProps={{
                    validationState: errors['priority']?.message ? 'error' : 'none',
                    validationMessage: errors['priority']?.message,
                    ...(fieldsProps?.priority ?? {}),
                  }}
                />
              )
            }}
          />
          <Controller
            name={FieldNames.area}
            control={control}
            rules={enableRules.area ? { required: 'Required' } : undefined}
            render={({ field }) => {
              return (
                <Area
                  disabled={disabled?.area}
                  defaultValueText={defaultValueTexts?.area}
                  value={field.value}
                  onChange={field.onChange}
                  loading={teamLoading}
                  teamList={teamList}
                  className={styles.area}
                  orientation={orientation}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  fieldProps={{
                    validationState: errors['area']?.message ? 'error' : 'none',
                    validationMessage: errors['area']?.message,
                    ...(fieldsProps?.area ?? {}),
                  }}
                />
              )
            }}
          />
          <Controller
            name={FieldNames.assignTo}
            control={control}
            render={({ field }) => {
              return (
                <AssignTo
                  value={field.value}
                  onChange={field.onChange}
                  orientation={orientation}
                  className={styles.assignTo}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  defaultValueTexts={defaultValueTexts?.assignTo}
                  fieldProps={{
                    ...(fieldsProps?.assignTo ?? {}),
                  }}
                />
              )
            }}
          />
          <Controller
            name={FieldNames.rootCauseIDs}
            control={control}
            rules={
              enableRules.rootCauseIDs
                ? {
                    validate: (value) => {
                      if (ticketStatus === 'Closed - Fixed') {
                        return (value ?? []).filter((v) => v !== EMPTY_ROOT_CAUSE).length > 0 || 'Required'
                      }
                      return true
                    },
                  }
                : undefined
            }
            render={({ field }) => {
              return (
                <RootCauses
                  productId={productId}
                  allowCreateNewRootCause={!!(allowRootCause(productId) && formValues?.area)}
                  disabled={disabled?.rootCauseIDs}
                  defaultValueText={defaultValueTexts?.rootCauseIDs}
                  value={field.value}
                  onChange={field.onChange}
                  loading={rootCauseLoading}
                  className={styles.rootCauses}
                  rootCauseMaxWidth={rootCauseMaxWidth}
                  orientation={orientation}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  fieldProps={{
                    validationState: errors['rootCauseIDs']?.message ? 'error' : 'none',
                    validationMessage: errors['rootCauseIDs']?.message,
                    ...(fieldsProps?.rootCauses ?? {}),
                  }}
                  teamName={teamList.find((item) => item.id === formValues?.area)?.name}
                  teamId={teamList.find((item) => item.id === formValues?.area)?.id}
                  rootCauseGrouped={rootCausesGroupedByTeam}
                >
                  {children}
                </RootCauses>
              )
            }}
          />
          <Controller
            name={FieldNames.customTags}
            control={control}
            render={({ field }) => {
              return (
                <CustomTags
                  orientation={orientation}
                  className={styles.customTags}
                  value={field.value ?? []}
                  defaultValueText={defaultValueTexts?.customTags}
                  style={{ gridTemplateColumns: subGridTemplateColumns }}
                  onChange={field.onChange}
                ></CustomTags>
              )
            }}
          />
        </div>
      )
    },
  ),
)

TicketActionForm.displayName = 'TicketActionForm'
