import { IClusterJobData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getClusteringResult(
  context: IDashStoreContext,
  clusteringRequestId: string,
): Promise<IClusterJobData | undefined> {
  const response = await context.api.automation.getClusteringResult({ clusteringRequestId: clusteringRequestId })
  return response.data
}
