import { z } from 'zod'

export interface ApiTicketContext {
  readonly dateTimeUtc?: string
  readonly tenantId?: string
  readonly verbatimFeedback?: string
  readonly categories?: Array<string>
  readonly clientName?: string
  readonly additionalContext?: string
  readonly emailAddress?: string
  readonly scenarioName?: string
  readonly externalTicketId: string
  readonly ring?: string
  readonly thumbFeedback?: string
  readonly status?: string
  readonly resolvedReason?: string
  readonly ticketSource?: string
  readonly vsoTags?: string
  readonly vsoDescription?: string
  readonly vsoAccount?: string
  readonly vsoLink?: string
  readonly logicalId?: string
  readonly reasonForTags?: string
  readonly hasVerbatim?: string
  readonly externalTicketIds?: string
  readonly sourceId?: string
  readonly oCVLink?: string
  readonly oCVLanguage?: string
  readonly productName?: string
  readonly e2eLatency?: number
  readonly uiHost?: string
  readonly tenantName?: string
  readonly endpoint?: string
  readonly assignTo?: string
  readonly teamId?: number
}

export const apiTicketContextSchema = z.object({
  dateTimeUtc: z.string().optional(),
  tenantId: z.string().optional(),
  verbatimFeedback: z.string().optional(),
  categories: z.array(z.string()).optional(),
  clientName: z.string().optional(),
  additionalContext: z.string().optional(),
  emailAddress: z.string().optional(),
  scenarioName: z.string().optional(),
  externalTicketId: z.string(),
  ring: z.string().optional(),
  thumbFeedback: z.string().optional(),
  status: z.string().optional(),
  resolvedReason: z.string().optional(),
  ticketSource: z.string().optional(),
  vsoTags: z.string().optional(),
  vsoDescription: z.string().optional(),
  vsoAccount: z.string().optional(),
  vsoLink: z.string().optional(),
  logicalId: z.string().optional(),
  reasonForTags: z.string().optional(),
  hasVerbatim: z.string().optional(),
  externalTicketIds: z.string().optional(),
  sourceId: z.string().optional(),
  oCVLink: z.string().optional(),
  oCVLanguage: z.string().optional(),
  productName: z.string().optional(),
  e2eLatency: z.number().optional(),
  uiHost: z.string().optional(),
  tenantName: z.string().optional(),
  assignTo: z.string().optional(),
  teamId: z.number().optional(),
})
