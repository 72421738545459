import { Column } from '@copilot-dash/components'
import { ITicketDiagnosticData } from '@copilot-dash/domain'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { OutlineBar } from './OutlineBar'
import { OutlineBody } from './OutlineBody'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

interface IProps {
  readonly data: ITicketDiagnosticData
}

export function Outline({ data }: IProps) {
  const styles = useStyles()
  const isOpen = TicketDiagnosticStore.use((state) => state.isOpen)

  return (
    <Column className={mergeClasses(styles.root, isOpen ? styles.visible : styles.hidden)}>
      <OutlineBar data={data} />
      <OutlineBody data={data} />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground3,
    overflow: 'hidden',
    transition: 'width 0.2s ease-in-out',
    zIndex: 0,
  },
  visible: {
    width: '300px',
  },
  hidden: {
    width: '0px',
  },
})
