import { z } from 'zod'

export interface ApiAISearchRootCauseItem {
  id: string
  createdTimeUtc: string
  title: string
  priority: string
  assignTo?: string
  status: string
  teamId: string
  customTags?: string[]
  eta?: string
  rootCauseType?: string
  description?: string
  score?: number
  isRcrEnabled?: boolean
}

export interface ApiAISearchRootcauseResponse {
  searchItems: Array<ApiAISearchRootCauseItem>
  count: number
  startIndex: number
  stepNumber: number
  facetResults?: Record<string, Record<string, number | undefined>>
}

const apiAISearchRootCauseItemSchema = z.object({
  id: z.string(),
  createdTimeUtc: z.string(),
  title: z.string(),
  priority: z.string(),
  assignTo: z.string().optional(),
  status: z.string(),
  teamId: z.string(),
  customTags: z.array(z.string()).optional(),
  eta: z.string().optional(),
  rootCauseType: z.string().optional(),
  description: z.string().optional(),
  score: z.number().optional(),
  isRcrEnabled: z.boolean().optional(),
})

export const apiAISearchRootCauseResponseSchema = z.object({
  searchItems: z.array(apiAISearchRootCauseItemSchema),
  count: z.number(),
  startIndex: z.number(),
  stepNumber: z.number(),
  facetResults: z.record(z.record(z.union([z.number(), z.undefined()]))).optional(),
})
