import { ProductIds, ProductName, ProductNames } from '../basic'

export const UN_TRIAGED_TEAM_NAME_M365CHAT = 'Untriaged feedback'
export const UN_TRIAGED_TEAM_NAME_CWC = 'CWC Untriaged feedback'
export const UN_TRIAGED_TEAM_NAME_MCP = 'MCP Untriaged Feedback'
export const UN_TRIAGED_TEAM_NAME_USERP = 'USERP Untriaged Feedback'
export const UN_TRIAGED_TEAM_NAMES = [
  UN_TRIAGED_TEAM_NAME_M365CHAT,
  UN_TRIAGED_TEAM_NAME_CWC,
  UN_TRIAGED_TEAM_NAME_MCP,
  UN_TRIAGED_TEAM_NAME_USERP,
]

export const UN_TRIAGED_TEAM_ID_M365CHAT = 39
export const UN_TRIAGED_TEAM_ID_CWC = 47
export const UN_TRIAGED_TEAM_ID_MCP = 59
export const UN_TRIAGED_TEAM_ID_USERP = 64
export const UN_TRIAGED_TEAM_IDS = [
  UN_TRIAGED_TEAM_ID_M365CHAT,
  UN_TRIAGED_TEAM_ID_CWC,
  UN_TRIAGED_TEAM_ID_MCP,
  UN_TRIAGED_TEAM_ID_USERP,
]

export const TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST: Array<ProductName> = [
  ProductNames.M365Chat,
  ProductNames.M365ChatWebChat,
  ProductNames.TeamsMeetingCopilot,
  ProductNames.USERP,
]

export function isProductSupportTeamView(product: ProductName): boolean {
  return TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product)
}

export const TOP_ISSUES_SUPPORT_PRODUCT_NAME_LIST: Array<ProductName> = [ProductNames.M365Chat]

export const P0_P1_AS_DEFAULT_SEARCH_PRODUCT_NAME_LIST: Array<ProductName> = [ProductNames.M365Chat]

export const ENABLE_CREATE_ROOT_CAUSE_PRODUCT_ID_LIST: Array<string> = [
  ProductIds.M365Chat,
  ProductIds.M365ChatWebChat,
  ProductIds.TeamsMeetingCopilot,
  ProductIds.USERP,
]
