import { IDashStoreContext } from '../../IDashStoreContext'

export async function deleteRootCause(context: IDashStoreContext, rootCauseId: string) {
  let succeeded = false

  const response = await context.api.copilotDash.deleteRootCauseById(rootCauseId)
  succeeded = response.isSucceeded
  if (succeeded) {
    context.use.setState((state) => {
      delete state.team.v2RootCauseMap[rootCauseId]
      const v2RootCausesByTeam = state.team.v2RootCauseIdsByTeam
      for (const teamId in v2RootCausesByTeam) {
        const rootCauseIds = v2RootCausesByTeam[teamId]
        if (!rootCauseIds?.data) continue
        const index = rootCauseIds.data.indexOf(rootCauseId)
        if (index !== -1) {
          rootCauseIds.data = rootCauseIds.data.filter((id) => id !== rootCauseId)
        }
      }
    })
  }

  return succeeded
}
