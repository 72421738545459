import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoAugLoopLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getAugLoopLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoAugLoopLogItem[]> {
  return context.getOrFetch<IKustoAugLoopLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.augLoopLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.augLoopLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.augLoopLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asAugLoopLog()
        return results
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.AugLoop)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.AugLoop, sourceItem })
    },
  })
}
