import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnClusterJobConfiguration: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.Configuration],
  field: String(JobColumnKey.Configuration) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        {props.data.isBase ? 'Base model' : 'Customized configuration'}
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>
        {JobColumnIdNaColumnIdNameMapping[JobColumnKey.Configuration]}
      </TableCellLayoutWithStyles>
    ),
  },
  minWidth: 250,
  width: 250,
  maxWidth: 500,
  sortable: true,
  resizable: true,
}
