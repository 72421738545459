import { editor } from 'monaco-editor'
import { MonacoFold } from '../MonacoViewTypes'

export function invokeMonacoFoldAction(editor: editor.IStandaloneCodeEditor, fold: MonacoFold) {
  const action = getMonacoFoldAction(fold)
  editor.getAction(action)?.run()
}

function getMonacoFoldAction(defaultFold: MonacoFold): string {
  switch (defaultFold) {
    case 'all':
      return 'editor.foldAll'
    case 'level1':
      return 'editor.foldLevel1'
    case 'level2':
      return 'editor.foldLevel2'
  }
}
