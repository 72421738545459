import { MonacoDialog } from '@copilot-dash/components'
import { ITelemetryMetric } from '@copilot-dash/domain'
import { FullScreenMaximizeRegular } from '@fluentui/react-icons'
import { MouseEventHandler } from 'react'

interface IProps {
  readonly metric: ITelemetryMetric
}

export function MetricCardHeaderOpenJson({ metric }: IProps) {
  const handleOnClick: MouseEventHandler = (event) => {
    event.stopPropagation()

    MonacoDialog.open(metric.raw)
  }

  return <FullScreenMaximizeRegular title="Show raw JSON" onClick={handleOnClick} />
}
