import { z } from 'zod'
import { createSetting } from './createSetting'
import { IFeature } from './IFeature'

interface IParams {
  readonly key: string
  readonly defaultValue: boolean
  readonly description: string
  readonly group: string | undefined
  readonly keyPrefix?: string
}

export function createFeature({
  key,
  defaultValue,
  description,
  group,
  keyPrefix = 'dash_flight_',
}: IParams): IFeature {
  const schema = z.boolean()
  const setting = createSetting<boolean>({ key, defaultValue, schema, keyPrefix })

  return Object.assign(setting, {
    description,
    group,
  })
}
