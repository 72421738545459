import { Editor } from './editor/Editor'
import { LexicalRichTextEditorStore } from './LexicalRichTextEditorStore'

interface IProps {
  initialValue?: string | undefined
  mode?: 'create' | 'edit'
  placeholder?: string | undefined
  role?: 'discussion' | 'description'
  onSubmit: (value: string | undefined) => Promise<void>
  onCancel?: () => void
}

export function LexicalRichTextEditor({
  initialValue,
  mode = 'create',
  placeholder = 'Enter some rich text...',
  role = 'discussion',
  onSubmit,
  onCancel,
}: IProps) {
  return (
    <LexicalRichTextEditorStore
      initialValue={initialValue}
      mode={mode}
      placeholder={placeholder}
      role={role}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <Editor />
    </LexicalRichTextEditorStore>
  )
}
