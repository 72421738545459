import { Spacer } from '@copilot-dash/components'
import { ISevalQueryData } from '@copilot-dash/domain'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  makeStyles,
  Spinner,
  Text,
  tokens,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { FC, memo, useCallback, useState } from 'react'
import { z } from 'zod'
import { useToast } from '../../hooks/useToast'

const querySetDataSchema = z.object({
  templateId: z.string(),
  templateName: z.string(),
  targetQueryCount: z.number(),
  querySeed: z.array(
    z.object({
      ticketId: z.string(),
      messageId: z.string().optional(),
      utterance: z.string().optional(),
      datetimeInUtc: z.string().optional(),
    }),
  ),
})

interface IGenerateQuerySetTemplateProps {
  defaultQuerySet?: Partial<ISevalQueryData>
  open: boolean
  onClose: () => void
  title?: string
}

export const GenerateQuerySetTemplate: FC<IGenerateQuerySetTemplateProps> = memo(
  ({ open, onClose, title = 'Generate Query Set Template', defaultQuerySet }) => {
    const styles = useStyles()
    const [saving, setSaving] = useState(false)
    const [formValues, setFormValues] = useState<
      Partial<Pick<ISevalQueryData, 'templateName' | 'targetQueryCount'>> | undefined
    >(defaultQuerySet?.templateName ? { templateName: defaultQuerySet.templateName } : {})
    const toast = useToast()

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setFormValues((prev) => ({ ...(prev ?? {}), templateName: value }))
    }, [])

    const handleTargetQueryCountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const num = Number(value)
      if (isNaN(num)) return
      setFormValues((prev) => ({ ...(prev ?? {}), targetQueryCount: num }))
    }, [])

    const handleSave = useCallback(() => {
      const parsed = querySetDataSchema.safeParse({ ...defaultQuerySet, ...formValues })
      if (!parsed.success) {
        const issue = parsed.error.issues[0]
        toast.showError('Invalid data', JSON.stringify(issue))
        return
      }
      setSaving(true)
      const data = parsed.data
      app.store.actions
        .putSevalQuerySet(data)
        .then(() => {
          toast.showSuccess('Success', 'Query set template generated successfully')
          onClose()
        })
        .catch((err) => {
          toast.showError('Error', 'Failed to generate query set template' + (err?.message ? `: ${err.message}` : ''))
        })
        .finally(() => {
          setSaving(false)
        })
    }, [defaultQuerySet, formValues, onClose, toast])

    return (
      <Dialog
        open={open}
        onOpenChange={(_, data) => {
          if (!data.open) {
            onClose()
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                </DialogTrigger>
              }
            >
              {title}
            </DialogTitle>
            <DialogContent>
              <div className={styles.container}>
                <Field label="Template Name" style={{ fontWeight: 600 }} required>
                  <Input
                    required
                    className={styles.input}
                    placeholder="Enter name"
                    aria-label="inline"
                    value={formValues?.templateName}
                    onChange={handleNameChange}
                  />
                </Field>
                <Spacer height={16} />
                <Field label="Target Query Count" style={{ fontWeight: 600 }} required>
                  <Input
                    value={typeof formValues?.targetQueryCount === 'number' ? String(formValues.targetQueryCount) : ''}
                    className={styles.input}
                    onChange={handleTargetQueryCountChange}
                    required
                  />
                </Field>
                <Spacer height={16} />
                <Field label="Query Seed" style={{ fontWeight: 600 }}>
                  <Text>Selected DSATs ({defaultQuerySet?.querySeed?.length})</Text>
                </Field>
              </div>
            </DialogContent>
            <DialogActions position="end">
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
              <Button
                disabled={saving || !formValues?.templateName || !formValues?.targetQueryCount}
                appearance="primary"
                onClick={handleSave}
                icon={saving ? <Spinner size="extra-tiny" /> : null}
              >
                Generate
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    )
  },
)

GenerateQuerySetTemplate.displayName = 'GenerateQuerySetTemplate'

const useStyles = makeStyles({
  container: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  input: {
    border: 'none',
    backgroundColor: tokens.colorNeutralBackground3,
  },
})
