import { ProductIds, ProductName } from '@copilot-dash/domain'

import { ISearchClusterOptions } from './IClusterScreenStoreState'

// Function to create a stable string representation of the object

export const stableStringify = (obj: unknown): string => {
  const allKeys: string[] = []
  const seen = new Set()

  JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return // Circular reference found, discard key
      }
      seen.add(value)
    }
    allKeys.push(key)
    return value
  })

  allKeys.sort()

  const orderedObj: { [key: string]: unknown } = {}
  allKeys.forEach((key) => {
    orderedObj[key] = (obj as { [key: string]: unknown })[key]
  })

  return JSON.stringify(orderedObj)
}

export const defaultFilterForm: ISearchClusterOptions = {
  count: 50,
  offset: 0,
  productId: ProductIds.M365Chat,
}

export const productConfigurablePemission: Map<ProductName, string> = new Map([
  ['OutlookCopilot', 'configurable.cluster.outlook.write'],
  ['ExcelCopilot', 'configurable.cluster.excel.write'],
  ['TeamsMeetingCopilot', 'configurable.cluster.mcp.write'],
  ['WordCopilot', 'configurable.cluster.word.write'],
  ['PPTCopilot', 'configurable.cluster.ppt.write'],
])

export const defaultClusterArgs: ISearchClusterOptions = {
  ...defaultFilterForm,
}

export enum JobStatus {
  Submitted = 'Submitted',
  Canceled = 'Canceled',
  DataGathering = 'DataGathering',
  DatasetGenerationError = 'DatasetGenerationError',
  Clustering = 'Clustering',
  ClusteringCompleted = 'ClusteringCompleted',
  ClusteringError = 'ClusteringError',
  DatasetReady = 'DatasetReady',
}
