import { getProductNameById, isProductSupportTeamView } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { TicketScreenActionContentV2 } from './TicketScrenActionContentV2'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenAction() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = app.store.use.getTicket(ticketId)
  const workItemSnapshot = app.store.use.getWorkItemByFeedbackId(ticketId)
  const productId = app.store.use.getTicketEnvironment(ticketId)?.data?.productId
  const productName = getProductNameById(productId)

  const enableEdit = workItemSnapshot.data?.workItemId

  if (ticketSnapshot.status === 'waiting' || workItemSnapshot.status === 'waiting') {
    return (
      <Skeleton>
        <SkeletonItem style={{ height: '66px' }} />
      </Skeleton>
    )
  }

  if (!enableEdit) {
    return null
  }

  if (productName && !isProductSupportTeamView(productName)) {
    return null
  }

  return (
    <motion.div
      initial={{ height: 66, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      style={{ overflow: 'hidden' }}
    >
      <TicketScreenActionContentV2 />
    </motion.div>
  )
}
