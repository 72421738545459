import { Column, Row, Spacer } from '@copilot-dash/components'
import { uuid } from '@copilot-dash/core'
import { INewTicketData, ISevalQueryData, ITopIssueExtendInfo, TeamId } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import {
  Button,
  Divider,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Text,
} from '@fluentui/react-components'
import { ArrowLeftRegular, BookCoinsRegular, MoreCircleRegular, PenSparkleRegular } from '@fluentui/react-icons'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AssignedTeam } from './children/assignedTeam'
import { getRandomColor } from './children/components/Legend'
import { IssueDetails } from './children/issueDetails'
import { IssueTickets } from './children/issueTickets'
import { groupTicketsByStateV2 } from './children/issueTickets/utils'
import { Progress } from './children/progress'
import { useStyles } from './IssueDetail.styles'
import { ErrorViewBoundary } from '../../../../../components/Error'
import { GenerateQuerySetTemplate } from '../../../../../components/GenerateQuerySetTemplate/GenerateQuerySetTemplate'
import { LoadingMask } from '../../../../../components/LoadingMask'
import { useToast } from '../../../../../hooks/useToast'
import { SearchRoute } from '../../../../../router'
import { ClarityScenarioIds, useClaritySectionId } from '../../../../../utils/useClarityPageId'
import { useSearchScreenActions } from '../../../store'

export interface IssueDetailProps {
  issueId: string
  issueBatchId: string
}

export const IssueDetail = memo(function IssueDetail(props: IssueDetailProps) {
  useClaritySectionId(ClarityScenarioIds.overviewInsightsIssueDetails)
  const { issueId, issueBatchId } = props
  const styles = useStyles()
  const toast = useToast()

  const teamsSnapshot = app.store.use.getTeamsMap()

  const actions = useSearchScreenActions()
  const location = useLocation()
  const isQuerySetVisible = app.features.v2QuerySet.use()
  const [querySetDialogOpen, setQuerySetDialogOpen] = useState(false)
  const [extendInfoLoading, setExtendInfoLoading] = useState(true)
  const [ticketsLoading, setTicketLoading] = useState(true)
  const [issueInfo, setIssueInfo] = useState<ITopIssueExtendInfo | undefined>()
  const [loadedTickets, setLoadedTickets] = useState<INewTicketData[]>([])
  const [querySetData, setQuerySetData] = useState<Partial<ISevalQueryData>>()
  const [generatingQuerySet, setGeneratingQuerySet] = useState(false)

  useEffect(() => {
    if (!issueInfo && location.state?.issueExtendInfo) {
      setIssueInfo(location.state.issueExtendInfo)
      setExtendInfoLoading(false)
    } else if (!issueInfo && issueBatchId && issueId) {
      application.store.actions.getTopIssueExtendInfo(issueBatchId, [issueId]).then((extendInfoMap) => {
        setIssueInfo(extendInfoMap[`${issueBatchId}-${issueId}`])
        setExtendInfoLoading(false)
      })
    }
  }, [issueInfo, location.state, issueBatchId, issueId])

  const issueTitle = issueInfo?.issueTitle
  const handleGoBack = useCallback(() => {
    if (history.length > 1) {
      history.back()
      return
    }
    const args = SearchRoute.navigator.getArgs()
    SearchRoute.navigator.navigate({
      product: args.product,
      tab: 'feedbackInsights',
      issueBatchId: issueBatchId,
      showIssueCount: args.showIssueCount,
    })
  }, [issueBatchId])

  const issueDetailData = useMemo(() => {
    return {
      priority: issueInfo?.priority ?? 0,
      DSATsCount: issueInfo?.ticketCount ?? 0,
      DSATsCoverage: issueInfo?.coverage ?? 0,
      vipUserIds: issueInfo?.VIPPowerUserIdList ?? [],
      tenants: issueInfo?.cAPTenantList ?? [],
    }
  }, [issueInfo])

  const progressData = useMemo(() => {
    return Object.entries(groupTicketsByStateV2(loadedTickets))
      .map(([state, tickets]) => {
        const ticketCount = tickets.length
        return { state, count: ticketCount }
      })
      .filter((item) => item.count > 0)
  }, [loadedTickets])

  const handleTicketsLoaded = useCallback((tickets: INewTicketData[]) => {
    setTicketLoading(false)
    setLoadedTickets(tickets)
  }, [])

  const handleGenerateQuerySet = useCallback(() => {
    if (issueId && issueBatchId) {
      setGeneratingQuerySet(true)
      const query = SearchRoute.navigator.getArgsOptional()
      app.store.actions
        .searchTickets({
          product: query?.product || 'M365Chat',
          defaultRange: undefined,
          clusteringIssueId: issueId,
          batchId: issueBatchId,
          count: 1000,
          offset: 0,
          isAIF: false,
          customerTypes: undefined,
        })
        .then((result) => {
          const tickets = result.tickets
          if (tickets.length === 0) {
            toast.showError('No tickets found for this query')
            return
          }
          const templateId = uuid()
          const data: Partial<ISevalQueryData> = {
            templateId,
            templateName: `QuerySet-${new Date().toLocaleString()}`,
            querySeed: tickets.map((ticket) => ({
              ticketId: ticket.ticketId,
              messageId: ticket.messageId,
              utterance: ticket.utterance,
              datetimeInUtc: ticket.dateTimeUtc,
            })),
          }
          setQuerySetData(data)
          setQuerySetDialogOpen(true)
        })
        .catch((error) => {
          toast.showError('Error', error.message)
        })
        .finally(() => {
          setGeneratingQuerySet(false)
        })
    }
  }, [toast, issueId, issueBatchId])

  const assignedTeamData: Array<{
    name: string
    count: number
    teamId: TeamId
  }> = useMemo(() => {
    const tempData: { name: string; count: number; teamId: TeamId }[] = []
    if (teamsSnapshot.status === 'done') {
      for (const ticket of loadedTickets) {
        const teamId = ticket.teamId
        if (teamId) {
          const teamName = teamsSnapshot.data[ticket.teamId]?.name
          const team = tempData.find((team) => team.name === teamName)
          if (team) {
            team.count++
          } else if (teamName) {
            tempData.push({ name: teamName, count: 1, teamId: teamId })
          }
        }
      }
      return [...tempData].sort((a, b) => b.count - a.count)
    }
    return tempData
  }, [loadedTickets, teamsSnapshot.data, teamsSnapshot.status])

  const teamsWithColors = useMemo(() => {
    const assignedTeamPredefinedColors = ['#3340C2', '#7281FF', '#515EF5', '#36a7e4', '#2c94cb']
    const assignedTeamBackgroundColors = assignedTeamData.map(
      (_, index) => assignedTeamPredefinedColors[index] || getRandomColor(),
    )

    const items = assignedTeamData.map((team, index) => ({
      color: assignedTeamBackgroundColors[index] || getRandomColor(),
      label: team.name,
    }))
    return assignedTeamData.map((team, index) => ({
      count: team.count,
      legendItem: items[index],
      teamId: team.teamId,
    }))
  }, [assignedTeamData])

  useEffect(() => {
    // clear the drill down scenario and query when unmount
    return () => {
      actions.updateFeedbackInsightsIssueDetailDrillDownInfo(undefined, undefined)
    }
  }, [actions])

  return (
    <ErrorViewBoundary label="TopIssueDetail">
      <TelemetryScope
        scope="TopIssueDetail"
        properties={{
          issueId,
          issueBatchId,
        }}
      >
        <Column className={styles.container}>
          <Row vAlign="center">
            <ArrowLeftRegular onClick={handleGoBack} fontSize={20} style={{ flexShrink: 0, cursor: 'pointer' }} />
            <Spacer width={8} />
            <Text size={600} weight="semibold">
              {issueTitle}
            </Text>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <MenuButton icon={<MoreCircleRegular />} appearance="transparent" style={{ marginLeft: '12px' }} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem>
                    <Link
                      style={{ width: '220px', flexShrink: 0, paddingRight: '40px' }}
                      rel="noopener"
                      target="_blank"
                      href="https://forms.office.com/r/GZTQXzvU5N"
                      appearance="subtle"
                      onClick={() => {
                        Logger.telemetry.trackEvent('TopIssues/ClickFeedback', {
                          issueId,
                          issueBatchId,
                          product: SearchRoute.navigator.useArgsOptional()?.product,
                          location: 'Overview/FeedbackInsights',
                        })
                      }}
                    >
                      <Button style={{ padding: 0 }} appearance="transparent" icon={<PenSparkleRegular />}>
                        Provide issue feedback
                      </Button>
                    </Link>
                  </MenuItem>
                  {isQuerySetVisible && (
                    <MenuItem onClick={handleGenerateQuerySet}>
                      <Button style={{ padding: 0 }} appearance="transparent" icon={<BookCoinsRegular />}>
                        Generate Query Set Template
                      </Button>
                    </MenuItem>
                  )}
                </MenuList>
              </MenuPopover>
            </Menu>
          </Row>
          <Spacer height={16} />
          <Row className={styles.cardsContainer}>
            <Column className={styles.card}>
              <IssueDetails
                loading={extendInfoLoading}
                priority={issueDetailData.priority}
                DSATsCount={issueDetailData.DSATsCount}
                DSATsCoverage={issueDetailData.DSATsCoverage}
                vipUserIds={issueDetailData.vipUserIds}
                tenants={issueDetailData.tenants}
              />
            </Column>
            <Divider vertical className={styles.divider} />
            <Column className={styles.card}>
              <Progress loading={ticketsLoading} stateList={progressData} />
            </Column>
            <Divider vertical className={styles.divider} />
            <Column className={styles.card}>
              <AssignedTeam loading={ticketsLoading} teamsWithColors={teamsWithColors} />
            </Column>
          </Row>
          <Spacer height={30} />
          <IssueTickets onTicketsLoaded={handleTicketsLoaded} expectedTicketCount={issueDetailData.DSATsCount} />
          {querySetDialogOpen && querySetData && (
            <GenerateQuerySetTemplate
              defaultQuerySet={querySetData}
              open={querySetDialogOpen}
              onClose={() => setQuerySetDialogOpen(false)}
            />
          )}
          <LoadingMask visible={generatingQuerySet} />
        </Column>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
})
