import { z } from 'zod'

export enum ConfigColumnKey {
  Id = 'id',
  Scenario = 'scenario',
  Description = 'StartTimeUTC',
  UtteranceExamples = 'EndTimeUTC',
  UpdateDateTime = 'AmlDataSetJobStatus',
  UpdateBy = 'AmlClusteringJobStatus',
  Area = 'area',
  Categories = 'categories',
}

export const configColumnKeySchema = z.nativeEnum(ConfigColumnKey)
