import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const correlationIds = generateKustoStringArrayExp(params.correlationIds)
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
TuringBotTraceMDS_NoEUPI 
| where TIMESTAMP ${timeRange}
| where CorrelationId in (${correlationIds})
| project TIMESTAMP, Message, LogLevel, ServiceVersion, TraceId, CorrelationId,ScopeId, EventName, SourceBranch
| sort by TIMESTAMP asc
`
}

export const SydneyTuringBotTraceMDS_WW: IKustoQueryDefinition = {
  database: 'CompliantSydneyLogs',
  connection: 'https://compliantsydney.eastus.kusto.windows.net',
  query,
}
