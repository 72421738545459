import { usePromise } from '@copilot-dash/core'
import { Avatar, makeStyles, Persona, Slot } from '@fluentui/react-components'
import { useMemo } from 'react'
import { AnyExplainerUser } from '../../../../any-explainer/AnyExplainer'
import { AnyExplainers } from '../../../../any-explainer/AnyExplainers'
import { Row } from '../../../../layout'

interface IProps {
  readonly value: string
}

export function ValueViewForStringEmail({ value }: IProps) {
  const styles = useStyles()
  const isMSIT = useMemo(() => {
    return value.endsWith('@microsoft.com')
  }, [value])

  return (
    <div className={styles.root} title={value}>
      {isMSIT ? <ContentForMSIT value={value} /> : <Content email={value} />}
    </div>
  )
}

function ContentForMSIT({ value }: IProps) {
  const promise = useMemo(async (): Promise<AnyExplainerUser | undefined> => {
    const result = AnyExplainers.explain(value)

    if (!result?.user) {
      return
    }

    const user = result.user
    if (typeof user === 'function') {
      return user()
    } else {
      return user
    }
  }, [value])

  const snapshot = usePromise(promise)
  const avatarUrl: string | undefined = useMemo(() => {
    if (snapshot.status === 'done' && snapshot.data?.avatar) {
      return URL.createObjectURL(snapshot.data.avatar)
    } else {
      return undefined
    }
  }, [snapshot])

  return <Content email={value} displayName={snapshot.data?.name ?? undefined} photoUrl={avatarUrl} />
}

function Content({ email, displayName, photoUrl }: { email: string; displayName?: string; photoUrl?: string }) {
  let avatar: Slot<typeof Avatar>
  if (photoUrl) {
    avatar = {
      image: {
        src: photoUrl,
      },
    }
  } else {
    avatar = {
      color: 'colorful',
      'aria-hidden': true,
    }
  }

  const name = displayName ?? email
  const secondaryText = displayName ? email : undefined

  return (
    <Row vAlign="center">
      <Persona avatar={avatar} name={name} secondaryText={secondaryText} size="small" textAlignment="center" />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
})
