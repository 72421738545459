import { Row } from '@copilot-dash/components'
import { ColumnKey, columnKeySchema, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ColDef } from 'ag-grid-community'
import { compact } from 'lodash'
import * as React from 'react'
import { z } from 'zod'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForAll.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { AGGridTable } from '../../../components/AGGridTable/AGGridTable'
import { ErrorView } from '../../../components/Error'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'
import { useTeamViewStore } from '../store'

interface ITicketsTableForCategoryProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  readonly columnSetting: ColumnKey[]
  readonly setColumnSetting: (columns: ColumnKey[]) => void
}

export const TicketsTableForAll: React.FC<ITicketsTableForCategoryProps> = React.memo(
  ({ onRowClick, clickedTicketKey, columnSetting, setColumnSetting }) => {
    useClaritySectionId(ClarityScenarioIds.menuAllTickets)
    const ticketIdsSnapshot = useTeamViewStore((state) => state.tickets.ticketIdListByAll)
    const ticketsSnapshot = app.store.use.getTickets(ticketIdsSnapshot.data?.ticketIds ?? [])
    const hasMore = useTeamViewStore((state) => state.tickets.hasMoreTicketsForAll)
    const loadMore = useTeamViewStore((state) => state.tickets.loadMoreTicketListForAll)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const styles = useStyles()

    const handleColumnsChange = React.useCallback(
      (columns: Array<string>) => {
        const movedColumns = columns
          .map((column) => columnKeySchema.safeParse(column))
          .filter((result) => result.success)
          .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
        setColumnSetting(movedColumns)
      },
      [setColumnSetting],
    )

    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel(!!(selectedItems && selectedItems.length > 0))
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

    const colDefs = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, searchText])
    const table = React.useMemo(() => {
      if (ticketIdsSnapshot.status === 'waiting') {
        return <TableLoading />
      }
      if (ticketIdsSnapshot.status === 'error') {
        return <ErrorView error={ticketsSnapshot.error} />
      }
      if (ticketsSnapshot.status === 'done' && ticketIdsSnapshot.status === 'done') {
        return ticketIdsSnapshot.data.ticketCount > 0 ? (
          <AGGridTable
            rowData={ticketsSnapshot.data ?? []}
            getRowId={(data) => data.data.ticketId}
            columnDefs={colDefs}
            className={styles.container}
            onRowClicked={onRowClick}
            focusedRowId={clickedTicketKey}
            hasMore={hasMore}
            loadMore={loadMore}
            rowSelectionMode="multiRow"
            onSelectionChange={handleSelectionChange}
            handleColumnsChange={handleColumnsChange}
          />
        ) : (
          <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No tickets found for this query" />
        )
      }
      return null
    }, [
      ticketsSnapshot.status,
      ticketsSnapshot.error,
      ticketsSnapshot.data,
      ticketIdsSnapshot.status,
      ticketIdsSnapshot.data?.ticketCount,
      colDefs,
      styles.container,
      styles.flexGrow,
      onRowClick,
      clickedTicketKey,
      hasMore,
      loadMore,
      handleSelectionChange,
      handleColumnsChange,
    ])

    const headerText = 'All DSATs'

    return (
      <>
        <Row className={styles.header}>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Text size={500} weight="semibold">
            {headerText}
            {ticketIdsSnapshot.status === 'done' && ` (${ticketIdsSnapshot.data?.ticketCount})`}
          </Text>
        </Row>
        <QuickSearch />
        {table}
      </>
    )
  },
)

TicketsTableForAll.displayName = 'TicketsTableForAll'
