import { IProductData } from '@copilot-dash/domain'
import { Dropdown, DropdownProps, makeStyles, mergeClasses, Spinner } from '@fluentui/react-components'
import { TicketProductDropdownButton } from './children/TicketProductDropdownButton'
import { TicketProductDropdownOption } from './children/TicketProductDropdownOption'
import { ErrorView } from '../Error'

interface IProps {
  readonly value: string | undefined
  readonly onChange: (value: IProductData) => void
  readonly disabled?: boolean
  readonly isItemDisabled?: (product: IProductData) => boolean
  readonly className?: string
}

export function TicketProductDropdown({ value, onChange, disabled, isItemDisabled, className }: IProps) {
  const styles = useStyles()
  const products = application.store.use.getProducts()

  const onOptionSelect: DropdownProps['onOptionSelect'] = (_, option) => {
    const product = products.data?.find((product) => product.name === option.optionValue)
    if (product && product.name !== value) {
      onChange(product)
    }
  }

  return (
    <Dropdown
      className={mergeClasses('TicketProductDropdown', styles.root, className)}
      value={value ?? ''}
      selectedOptions={value ? [value] : []}
      onOptionSelect={onOptionSelect}
      button={value ? <TicketProductDropdownButton value={value} products={products} /> : undefined}
      placeholder="Select a product"
      disabled={disabled}
    >
      {(() => {
        switch (products.status) {
          case 'waiting':
            return <Spinner className={styles.waiting} />
          case 'error':
            return <ErrorView error={products.error} />
          case 'done':
            return products.data.map((item, index) => {
              const disabled = isItemDisabled ? isItemDisabled(item) : false
              return <TicketProductDropdownOption key={index} data={item} disabled={disabled} />
            })
        }
      })()}
    </Dropdown>
  )
}

const useStyles = makeStyles({
  root: {
    minWidth: 'unset',

    '&::after': {
      borderBottom: 'unset',
    },
  },
  waiting: {
    padding: '16px',
  },
})
