import { Row, Spacer } from '@copilot-dash/components'
import { Button, DrawerHeader, makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { useTeamViewStore } from '../../store'
import { RootCauseScreenDrawerType } from '../../store/types'

interface IProps {
  readonly type: RootCauseScreenDrawerType
}

export function RootCauseScreenDrawerHeader({ type }: IProps) {
  const styles = useStyles()

  const handleClose = () => {
    useTeamViewStore.getState().rootCauses.closeDrawer()
  }

  let title: string
  switch (type) {
    case RootCauseScreenDrawerType.RootCauseDetail:
      title = 'Details'
      break
    case RootCauseScreenDrawerType.DiscussionPanel:
      title = 'Discussion'
      break
    case RootCauseScreenDrawerType.ActivityHistory:
      title = 'History'
      break
  }

  return (
    <DrawerHeader className={styles.root}>
      <Row vAlign="center" gap="gap.small">
        <Text size={500} weight="semibold">
          {title}
        </Text>
        <Spacer />
        <Button appearance="subtle" aria-label="Close" icon={<DismissRegular />} onClick={handleClose} title="Close" />
      </Row>
    </DrawerHeader>
  )
}

const useStyles = makeStyles({
  root: {
    paddingTop: '20px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke3),
  },
})
