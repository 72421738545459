import { Column } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { TicketScreenMainBar } from './TicketScreenMainBar'
import { TicketScreenMainBody } from './TicketScreenMainBody'

export function TicketScreenMain() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <TicketScreenMainBar />
      <TicketScreenMainBody />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,

    '& > *:first-child': {
      flex: '0 0 auto',
      borderBottom: `1px solid ${tokens.colorNeutralStroke3}`,
    },

    '& > *:last-child': {
      flex: '1 1 0',
      overflow: 'auto',
    },
  },
})
