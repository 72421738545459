import { ColumnIdNameMapping, ColumnKey, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CopilotDashCustomHeaderProps, CustomHeader } from './CustomHeaderComponent'
import { RenderLongText } from './RenderLongText'
import { ResponsiveResponseColumnComponent } from './ResponsiveResponseColumnComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { useTranslatedTicketResponse } from '../Translation/useTranslatedTicketResponse'

export const createColumnConfigResponse = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
): ColDef<INewTicketData> => {
  return {
    headerName: ColumnIdNameMapping[ColumnKey.Response],
    field: String(ColumnKey.Response) as NestedFieldPaths<INewTicketData>,
    cellRenderer: (props: CustomCellRendererProps) => {
      return <Cell data={props.data} searchTextPrefix={searchTextPrefix} keyWords={keyWords} />
    },
    headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
    headerComponentParams: {
      customHeaderContent: (
        <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.Response]}</TableCellLayoutWithStyles>
      ),
    },
    flex: 2,
    minWidth: 190,
    sortable: false,
    resizable: true,
  }
}

interface ICellProps {
  readonly data: INewTicketData
  readonly searchTextPrefix: SearchTextPrefixType
  readonly keyWords?: string
}

function Cell({ data, searchTextPrefix, keyWords }: ICellProps) {
  const response = useTranslatedTicketResponse(data)

  if (response.text) {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderLongText
          originText={response.text}
          highlightText={response.highlights}
          isTranslated={response.textTranslated || response.highlightsTranslated}
        />
      </TableCellLayoutWithStyles>
    )
  }

  return (
    <TableCellLayoutWithStyles truncate>
      {data.ticketId && (
        <ResponsiveResponseColumnComponent item={data} searchTextPrefix={searchTextPrefix} keyWords={keyWords} />
      )}
    </TableCellLayoutWithStyles>
  )
}
