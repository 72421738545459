import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'
import { memo, useMemo } from 'react'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const ResponseLinkTypeFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('responseLinkType')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  filterOptions.forEach((item) => {
    item.hidden = item.disabled
  })
  const displayName = getFilterDisplayName('responseLinkType')

  return (
    <CopilotDashMultiSelectDropdown
      comboId="ResponseLinkType"
      filterType={displayName}
      optionsList={filterOptions.filter((item) => !item.hidden)}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
})

ResponseLinkTypeFilter.displayName = 'ResponseLinkTypeFilter'
