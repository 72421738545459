import { Toolbar, ToolbarDivider } from '@fluentui/react-components'
import { Fragment, ReactNode } from 'react'
import { AnyTableToolbarSearch } from './AnyTableToolbarSearch'

interface IProps {
  readonly keyword: string | undefined
  readonly onSearch: (keyword: string) => void
  readonly children?: ReactNode
}

export function AnyTableToolbar({ keyword, onSearch, children }: IProps) {
  return (
    <Toolbar>
      <Fragment>
        {children}
        {children && <ToolbarDivider />}
      </Fragment>

      <AnyTableToolbarSearch keyword={keyword} onSearch={onSearch} />
    </Toolbar>
  )
}
