import { Column, Row } from '@copilot-dash/components'
import { Spinner } from '@fluentui/react-components'
import { useStyles } from './ActivityHistoryPanelLayout.styles'
import { ActivityHistoryBody } from './children/ActivityHistoryBody'
import { ActivityHistoryEmpty } from './children/ActivityHistoryEmpty'
import { useActivityHistoryPanelStore } from './store/store'
import { ErrorView } from '../Error'
import { Scrollbar } from '../Scrollbar/Scrollbar'

export function ActivityHistoryPanelLayout() {
  const styles = useStyles()
  const activityHistorySnapshot = useActivityHistoryPanelStore((state) => state.activityHistory)
  const allTeamsSnapshot = application.store.use.getAllTeams()

  let content
  if (allTeamsSnapshot.status === 'waiting' || activityHistorySnapshot.status === 'waiting') {
    content = (
      <Row className={styles.waiting} fill vAlign="center" hAlign="center">
        <Spinner label="Loading..." />
      </Row>
    )
  } else if (allTeamsSnapshot.status === 'error' || activityHistorySnapshot.status === 'error') {
    content = <ErrorView error={activityHistorySnapshot.error || allTeamsSnapshot.error} />
  } else if (allTeamsSnapshot.status === 'done' && activityHistorySnapshot.status === 'done') {
    if (activityHistorySnapshot.data.length === 0) {
      content = <ActivityHistoryEmpty />
    } else {
      content = <ActivityHistoryBody />
    }
  }

  return (
    <Column className={styles.body} fill>
      <Scrollbar className={styles.scrollbar}>{content}</Scrollbar>
    </Column>
  )
}
