import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.padding('30px', '160px'),
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  search: {
    marginBottom: '16px',
  },
  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },
  flexGrow: {
    flexGrow: 1,
  },
  finetueButton: {
    height: '40px',
    backgroundColor: tokens.colorCompoundBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    padding: '0 16px',
  },
  resultContainer: {
    ...shorthands.flex(1),
  },
  cardsContainer: {
    display: 'flex',
    ...shorthands.borderRadius('8px'),
    height: '100%',
  },
})
