import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMessageContent } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketVerbatim(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketMessageContent> {
  return context.getOrFetch<ITicketMessageContent>({
    get: (state) => {
      return state.tickets[ticketId]?.verbatim
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.verbatim = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise
      const verbatim = raw.userFeedback.verbatim
      if (verbatim && verbatim.text) {
        return {
          language: verbatim.language,
          text: verbatim.text,
          textInEnglish: verbatim.textInEnglish,
          markdown: undefined,
          markdownInEnglish: undefined,
          adaptiveCard: undefined,
        }
      }

      throw TicketError.create('NoVerbatim', { ticketId })
    },
  })
}
