import { Column } from '@copilot-dash/components'
import { makeStyles, shorthands, Text } from '@fluentui/react-components'
import { useEffect } from 'react'

const useStyles = makeStyles({
  container: {
    ...shorthands.padding('16px'),
  },
})

export function LogoutScreen() {
  const styles = useStyles()

  useEffect(() => {
    application.auth.logout()
  }, [])

  return (
    <Column className={styles.container} vAlign="center" hAlign="center">
      <Text>Logging out...</Text>
    </Column>
  )
}
