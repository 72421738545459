import { AugLoopLogView } from './tabs/aug-loop-log/AugLoopLogView'
import { BizChat3SLatencyView } from './tabs/biz-chat-3s-latency/BizChat3SLatencyView'
import { BizChatPerfView } from './tabs/biz-chat-perf/BizChatPerfView'
import { ExtensibilityLogView } from './tabs/extensibility-log/ExtensibilityLogView'
import { LlmLogView } from './tabs/llm-log/LlmLogView'
import { SssLogView } from './tabs/sss-result/SssLogView'
import { StateAndDurationView } from './tabs/state-and-duration/StateAndDurationView'
import { TraceLogView } from './tabs/trace-log/TraceLogView'
import { Scrollbar } from '../../../../../components/Scrollbar/Scrollbar'
import { TicketScreenKustoTab } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'

export function TicketScreenKustoLogsBody() {
  const selectedKustoTab = TicketScreenStore.use((state) => state.selectedKustoTab)

  return (
    <Scrollbar>
      {(() => {
        switch (selectedKustoTab) {
          case undefined:
            return null
          case TicketScreenKustoTab.SssLogs:
            return <SssLogView />
          case TicketScreenKustoTab.StateAndDuration:
            return <StateAndDurationView />
          case TicketScreenKustoTab.TraceLog:
            return <TraceLogView />
          case TicketScreenKustoTab.LlmLog:
            return <LlmLogView />
          case TicketScreenKustoTab.AugLoopLog:
            return <AugLoopLogView />
          case TicketScreenKustoTab.BizChat3SLatency:
            return <BizChat3SLatencyView />
          case TicketScreenKustoTab.BizChatPerf:
            return <BizChatPerfView />
          case TicketScreenKustoTab.ExtensibilityLog:
            return <ExtensibilityLogView />
        }
      })()}
    </Scrollbar>
  )
}
