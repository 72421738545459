import { ISearchTicketArgs, SearchFilterFieldNames } from '@copilot-dash/domain'
import { Dialog, DialogSurface, DialogTrigger, makeStyles, tokens } from '@fluentui/react-components'
import { ComponentProps, useEffect } from 'react'
import { CustomizedFilterScreen } from './CustomizedFilterScreen'
import { useTicketFilterStore } from './store/store'
import { ThemeProvider } from '../../theme/ThemeProvider'

interface IProps {
  readonly children: ComponentProps<typeof DialogTrigger>['children']
  readonly ticketFilterForm: ISearchTicketArgs
  readonly supportedTicketFilterItems: SearchFilterFieldNames[]
}
export function CustomizedFilterDialog(props: IProps) {
  const styles = useStyles()
  const setTicketFilterForm = useTicketFilterStore((state) => state.setTicketFilterForm)
  const setSupportedTicketFilterItems = useTicketFilterStore((state) => state.setSupportedTicketFilterItems)

  useEffect(() => {
    setTicketFilterForm(props.ticketFilterForm)
    setSupportedTicketFilterItems(props.supportedTicketFilterItems)
  }, [props.supportedTicketFilterItems, props.ticketFilterForm, setSupportedTicketFilterItems, setTicketFilterForm])

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>{props.children}</DialogTrigger>
      <DialogSurface className={styles.surface}>
        <ThemeProvider className={styles.container}>
          <CustomizedFilterScreen />
        </ThemeProvider>
      </DialogSurface>
    </Dialog>
  )
}

const useStyles = makeStyles({
  surface: {
    backgroundColor: 'unset',
    maxWidth: '600px',
    maxHeight: '600px',
    padding: 'unset',
    margin: 'auto',
    borderRadius: tokens.borderRadiusLarge,
    overflow: 'hidden',

    '@media (min-height: 600px)': {
      height: '600px',
    },
    '@media (max-height: 600px)': {
      height: 'calc(100vh - 96px - 96px)',
    },
  },

  container: {
    height: '100%',

    '& > *': {
      height: '100%',
      width: '100%',
    },
  },
})
