import { Route } from 'react-router-dom'
import { SearchRouteMapper } from './SearchRouteMapper'
import { SearchRoutePage } from './SearchRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { TicketRoute } from '../ticket/TicketRoute'

export function SearchRoute() {
  return (
    <Route
      id={ID}
      path={PATH}
      element={<SearchRoutePage />}
      loader={({ request }) => {
        const url = new URL(request.url)
        const ticketId = url.searchParams.get('modalTicketId')
        if (ticketId) {
          return TicketRoute.navigator.generateRedirectResponse({
            id: ticketId,
          })
        }

        return null
      }}
    />
  )
}

const ID = 'product.search'
const PATH = '/:product/search'

SearchRoute.id = ID
SearchRoute.navigator = createNavigator(ID, PATH, SearchRouteMapper)
