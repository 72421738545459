import { Body, Column, Right, Row } from '@copilot-dash/components'
import {
  AllSupportedNonAIFColumns,
  INewTicketData,
  NonRemovableColumns,
  TeamViewSubMenuIds,
  UserpEnabledColumns,
} from '@copilot-dash/domain'
import { mergeClasses } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import * as React from 'react'
import { useStyles } from './TicketsLayout.styles'
import { COLUMN_SETTING_PANEL_WIDTH } from '../../../components/ColumnSettingPanel/children/ColumnSettingPanelBody.styles'
import { ColumnSettingPanel } from '../../../components/ColumnSettingPanel/ColumnSettingPanel'
import { FILTER_PANEL_WIDTH } from '../../../components/TicketsFilterPanel/layout/TicketsFilterPanelLayout.styles'
import { TicketRoute } from '../../../router'
import { BATCH_UPDATE_TICKETS_PANEL_WIDTH } from '../children/BatchUpdateTicketsPanel.styles'
import { BatchUpdateTicketsPanelV2 } from '../children/BatchUpdateTicketsPanelV2'
import { FilterPanel } from '../children/FilterPanel'
import { RootCauseDrawerGroup } from '../children/RootCauseDrawerGroup'
import { RootCauseTable } from '../children/RootCauseTable/RootCauseTable'
import { SharedQueriesView } from '../children/SharedQueries/SharedQueriesView'
import { TicketsTableForAll } from '../children/TicketsTableForAll'
import { TicketsTableForRootCause } from '../children/TicketsTableForRootCause'
import { TicketsTableForTopIssues } from '../children/TicketsTableForTopIssues'
import { UnRootCauseTickets } from '../children/UnRootCauseTickets'
import { useTeamViewStore } from '../store'

interface ITicketsLayoutProps {
  className?: string
}

export const TicketsLayout: React.FC<ITicketsLayoutProps> = React.memo(({ className }) => {
  const styles = useStyles()
  const currentSelectedSubMenu = useTeamViewStore((state) => state.teams.selectedSubMenu)
  const selectedTicket = useTeamViewStore((state) => state.selectedTicket)
  const setSelectedTicket = useTeamViewStore((state) => state.setSelectedTicket)
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
  const form = useTeamViewStore((state) => state.tickets.filterForm)

  const columnsUserp = app.settings.userpColumns.use()
  const setColumnsUserp = app.settings.userpColumns.set

  const defaultColumns = app.settings.teamViewColumns.use()
  const setDefaultColumns = app.settings.teamViewColumns.set

  const columns = form.product === 'USERP' ? columnsUserp : defaultColumns
  const setColumns = form.product === 'USERP' ? setColumnsUserp : setDefaultColumns

  const onRowClick = React.useCallback(
    (row?: INewTicketData) => {
      setSelectedTicket(row)
      if (row && row.ticketId) {
        app.store.actions.updateTicket(row.ticketId, row)
        TicketRoute.navigator.navigateToTicketModal({ id: row.ticketId })
        return
      }
    },
    [setSelectedTicket],
  )

  const tableRender = React.useMemo(() => {
    switch (currentSelectedSubMenu) {
      case TeamViewSubMenuIds.All:
        return (
          <Column>
            <TicketsTableForAll
              onRowClick={onRowClick}
              clickedTicketKey={selectedTicket?.ticketId}
              columnSetting={columns}
              setColumnSetting={setColumns}
            />
          </Column>
        )
      case TeamViewSubMenuIds.UnRootCaused:
        return (
          <Column>
            <UnRootCauseTickets
              onRowClick={onRowClick}
              clickedTicketKey={selectedTicket?.ticketId}
              columnSetting={columns}
              setColumnSetting={setColumns}
            />
          </Column>
        )
      case TeamViewSubMenuIds.TopIssues:
        return (
          <Column>
            <TicketsTableForTopIssues
              onRowClick={onRowClick}
              clickedTicketKey={selectedTicket?.ticketId}
              columnSetting={columns}
              setColumnSetting={setColumns}
            />
          </Column>
        )
      case TeamViewSubMenuIds.RootCauses:
        if (selectedRootCauseId)
          return (
            <Column>
              <TicketsTableForRootCause
                onRowClick={onRowClick}
                clickedTicketKey={selectedTicket?.ticketId}
                columnSetting={columns}
                setColumnSetting={setColumns}
              />
            </Column>
          )
        return (
          <Column>
            <RootCauseTable />
          </Column>
        )
      case TeamViewSubMenuIds.SharedQueries:
        return (
          <Column>
            <SharedQueriesView />
          </Column>
        )
    }
    return null
  }, [currentSelectedSubMenu, onRowClick, selectedTicket?.ticketId, columns, setColumns, selectedRootCauseId])

  const isTicketsFilterPanelOpen = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const isTicketDetailOpen = useTeamViewStore((state) => state.computed.ticketDetailOpen)
  const isColumnSettingOpen = useTeamViewStore((state) => state.computed.columnSettingOpen)
  const isBatchUpdateOpen = useTeamViewStore((state) => state.computed.batchUpdatePanelOpen)

  const selectedTicketsKey = useTeamViewStore((state) =>
    state.batchTicketsSubStore.tickets.map((ticket) => ticket.ticketId).join(','),
  )

  return (
    <Row className={mergeClasses(styles.container, className)}>
      <Body className={styles.body}>{tableRender}</Body>
      <motion.div
        layout
        animate={
          isTicketsFilterPanelOpen
            ? 'filterOpen'
            : isTicketDetailOpen
              ? 'ticketSummaryOpen'
              : isColumnSettingOpen
                ? 'columnSettingOpen'
                : isBatchUpdateOpen
                  ? 'batchUpdateOpen'
                  : 'close'
        }
        variants={{
          close: { width: 0 },
          filterOpen: { width: FILTER_PANEL_WIDTH },
          columnSettingOpen: { width: COLUMN_SETTING_PANEL_WIDTH },
          batchUpdateOpen: { width: BATCH_UPDATE_TICKETS_PANEL_WIDTH },
        }}
        initial={false}
      >
        <Right className={styles.right}>
          <Column>
            <FilterPanel />
            <ColumnSettingPanel
              isPanelOpen={isColumnSettingOpen}
              onDismiss={() => useTeamViewStore.getState().toggleColumnSetting(false)}
              NonRemovableColumns={NonRemovableColumns}
              AllSupportedColumns={form.product === 'USERP' ? UserpEnabledColumns : AllSupportedNonAIFColumns}
              selectedColumns={columns}
              setSelectedColumns={setColumns}
            />
            <BatchUpdateTicketsPanelV2 open={isBatchUpdateOpen} key={selectedTicketsKey} />
            <RootCauseDrawerGroup />
          </Column>
        </Right>
      </motion.div>
    </Row>
  )
})

TicketsLayout.displayName = 'TicketsLayout'
