import { z } from 'zod'

export enum JobColumnKey {
  Id = 'Id',
  Configuration = 'Configuration',
  ClusterName = 'ClusterName',
  StartTimeUTC = 'StartTimeUTC',
  WorkflowStartTimestamp = 'WorkflowStartTimestamp',
  ClusteringRequestStatus = 'ClusteringRequestStatus',
  CreatedBy = 'CreatedBy',
}

export const jobcolumnKeySchema = z.nativeEnum(JobColumnKey)

export type JobColumnIdNameMap = Record<JobColumnKey, string>

export const JobColumnIdNaColumnIdNameMapping: JobColumnIdNameMap = {
  Id: 'Id',
  ClusterName: 'Cluster Name',
  Configuration: 'Configuration',
  StartTimeUTC: 'DataSet',
  CreatedBy: 'Creator',
  WorkflowStartTimestamp: 'Create on',
  ClusteringRequestStatus: 'Status',
}
