import { Column, Spacer } from '@copilot-dash/components'
import { Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import DOMPurify from 'dompurify'
import { useEffect, useMemo, useState } from 'react'
import { updateImageSrc } from '../components/DiscussionPanel/children/CommentUtil'
import { ImagePreviewModal } from '../components/Image/ImagePreviewModal'

interface RenderContentProps {
  rawDescription: string
  isHovered?: boolean
  className?: string
}

export const RenderDescription: React.FC<RenderContentProps> = ({ rawDescription, isHovered, className }) => {
  const [updatedHtml, setUpdatedHtml] = useState<string | null>(null)
  const [modalSrc, setModalSrc] = useState<string | null>(null)

  const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement
    if (target.tagName === 'IMG') {
      const imageUrl = (event.target as HTMLImageElement).src
      setModalSrc(imageUrl)
    }
  }
  const sanitizedDescription = useMemo(() => {
    return DOMPurify.sanitize(rawDescription, { ADD_ATTR: ['target'] })
  }, [rawDescription])

  useEffect(() => {
    updateImageSrc(sanitizedDescription, 'description').then(setUpdatedHtml)
  }, [sanitizedDescription])

  if (updatedHtml === null) {
    return (
      <Skeleton>
        <Column>
          <SkeletonItem size={16} />
          <Spacer height={4} />
          <SkeletonItem size={16} />
          <Spacer height={4} />
          <SkeletonItem size={16} />
        </Column>
      </Skeleton>
    )
  }

  return (
    <Column className="lexical">
      {modalSrc && <ImagePreviewModal imageUrl={modalSrc} onClose={() => setModalSrc(null)} />}
      {updatedHtml && (
        <div
          style={{ backgroundColor: isHovered ? tokens.colorNeutralBackground3 : '', padding: '4px' }}
          className={className}
          dangerouslySetInnerHTML={{ __html: updatedHtml }}
          onClick={handleImageClick}
        />
      )}
    </Column>
  )
}
