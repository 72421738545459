import { Tab, TabItem, TabItemForAnyTable, TabItemForMonaco } from '@copilot-dash/components'
import { AsyncSnapshots, PromiseSnapshots } from '@copilot-dash/core'
import { IExtensibilityLogItem } from '@copilot-dash/domain'
import { compact, uniq } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'
import { TicketSourceKustoOpener } from '../../../../common/TicketSourceKustoOpener'

interface IRow {
  readonly Property: string
  readonly Value: string
}

function mapItemToRows(log: IExtensibilityLogItem): IRow[] {
  const { importantScopes, importantEvents, ...rest } = log

  const rows: IRow[] = []
  const appendObject = (data: Record<string, unknown>) => {
    Object.entries(data).forEach(([key, value]) => {
      rows.push({
        Property: key,
        Value: String(value),
      })
    })
  }

  const appendList = (key: string, list: string[]) => {
    if (list.length === 0) {
      rows.push({ Property: key, Value: '' })
    } else {
      list.forEach((item) => {
        rows.push({ Property: key, Value: item })
      })
    }
  }

  const append = (key: string, value?: string) => {
    rows.push({ Property: key, Value: value ?? '' })
  }

  // 1. Basic
  appendObject(rest)

  // 2. Scopes
  append('--- ScopeNames ---')
  Object.entries(importantScopes).forEach(([key, value]) => {
    const messages = compact(uniq(value.map((item) => item.message)))
    appendList(key, messages)
  })

  // 3. Events
  append('--- EventNames ---')
  Object.entries(importantEvents).forEach(([key, value]) => {
    const events = compact(uniq(value.map((item) => item.message)))
    appendList(key, events)
  })

  return rows
}

export function ExtensibilityLogView(): ReactNode {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} messageId={selectedTurnId} />
}

function Content({ ticketId, messageId }: { readonly ticketId: string; readonly messageId: string }) {
  const snapshot = app.store.use.getExtensibilityLog(ticketId, messageId)

  const transposedSnapshot = useMemo(() => {
    return PromiseSnapshots.map(snapshot, (logs) => {
      return logs.flatMap(mapItemToRows)
    })
  }, [snapshot])

  const toolbar = <TicketSourceKustoOpener ticketId={ticketId} messageId={messageId} source="extensibility" />

  return (
    <TicketAsyncView snapshot={transposedSnapshot}>
      {(items) => {
        return (
          <Tab>
            <TabItem name="Table">
              <TabItemForAnyTable data={items} toolbar={toolbar} />
            </TabItem>
            <TabItem name="JSON">
              <TabItemForMonaco data={items} toolbar={toolbar} />
            </TabItem>
          </Tab>
        )
      }}
    </TicketAsyncView>
  )
}
