import { Column } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewPopoverBodyContext({ components }: IProps) {
  const styles = useStyles()
  const context = components.context
  if (!context) {
    return null
  }

  return <Column className={styles.root}>{context}</Column>
}

const useStyles = makeStyles({
  root: {
    padding: '20px 0 12px 0',
    wordBreak: 'break-word',

    '& table': {
      borderCollapse: 'collapse',
      fontSize: '0.9em',
    },

    '& table td': {
      border: '1px solid #ddd',
      padding: '4px 8px',
    },

    '& table tbody tr td:first-child': {
      whiteSpace: 'nowrap',
    },
  },
})
