import { ProductIds } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { isEqual } from 'lodash'
import { Ref, forwardRef, useEffect } from 'react'
import { ITicketFilterFormRef, getCleanedFilters } from './children/TicketFilterForm/TicketFilterForm.types'
import { TicketsFilterPanelLayout } from './layout/TicketsFilterPanelLayout'
import { HeaderTab, useTicketFilterStore } from './store/store'
import { ITicketFilterPanel } from './TicketFilterPanel.types'

const TicketsFilterPanelInner = <T,>(props: ITicketFilterPanel<T>, ref: Ref<ITicketFilterFormRef>) => {
  useEffect(() => {
    useTicketFilterStore.getState().setSelectedProductId(props.selectedProductId ?? ProductIds.M365Chat)
    useTicketFilterStore.getState().setSupportedTicketFilterItems(props.supportedTicketFilterItems)
    useTicketFilterStore.getState().setCustomizedFilters(props.customizedFilters)
    useTicketFilterStore.getState().setCopilotDashPath(props.copilotDashPath)
    useTicketFilterStore.getState().setIsInvocationSlicerEnable(props.isInvocationSlicerEnable ?? false)
    useTicketFilterStore.getState().setSelectedApplications(props.selectedApplications)
    useTicketFilterStore.getState().setSelectedPlatforms(props.selectedPlatforms)
    useTicketFilterStore.getState().setSelectedLicenses(props.selectedLicenses)
  }, [
    props.selectedProductId,
    props.supportedTicketFilterItems,
    props.customizedFilters,
    props.copilotDashPath,
    props.isInvocationSlicerEnable,
    props.selectedApplications,
    props.selectedPlatforms,
    props.selectedLicenses,
  ])
  useEffect(() => {
    useTicketFilterStore.getState().setSelectedHeaderTab(HeaderTab.Filters)
  }, [props.selectedProductId])

  useEffect(() => {
    const parsedPayload = getCleanedFilters(JSON.parse(props.currentQuery?.filterPayload || '{}'))
    const currentFilter = getCleanedFilters({ ...props.ticketFilterForm, ...props.externalArgs })
    const equal = isEqual(parsedPayload, currentFilter)

    useTicketFilterStore.getState().setQueryChanged(!equal)
    useTicketFilterStore.getState().setCurrentQuery(props.currentQuery ?? undefined)
  }, [props.currentQuery, props.ticketFilterForm, props.externalArgs])

  return (
    <TelemetryScope scope="SearchFiltersView">
      <TicketsFilterPanelLayout<T> {...props} ref={ref} />
    </TelemetryScope>
  )
}

TicketsFilterPanelInner.displayName = 'TicketsFilterPanelInner'

export const TicketsFilterPanel = forwardRef(TicketsFilterPanelInner) as <T>(
  props: ITicketFilterPanel<T> & { ref?: Ref<ITicketFilterFormRef> },
) => JSX.Element
