export enum UtteranceGroups {
  Analyze = 'Analyze',
  Ask = 'Ask',
  Chat = 'Chat',
  Command = 'Command',
  Create = 'Create',
  Summarize = 'Summarize',
  Other = 'Other',
}

export interface ScenarioSlicer {
  readonly utteranceGroup: UtteranceGroups
  readonly scenarios: string[]
}

export const ScenarioSlicers: ScenarioSlicer[] = [
  {
    utteranceGroup: UtteranceGroups.Analyze,
    scenarios: [
      'Analyze: Excel Data',
      'Compare: File v File',
      'Compare: Work v Web',
      'Recommend: Biz Strategy',
      'Recommend: Next Steps',
      'Suggest: Workflow Optimization',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Ask,
    scenarios: [
      'Describe: Image',
      'Discover: Icons / Images',
      'Discover: People',
      'Extensibility: Fanout to multiple plugins',
      'Extensibility: Sales Copilot',
      'Help: BizChat',
      'Help: Microsoft',
      'Help: Plugins',
      'Help: Web Content',
      'Learn: BizChat',
      'Q&A: Data Handling',
      'Q&A: Dynamics365 Sales',
      'Q&A: Dynamics365 Supply Chain',
      'Q&A: Edge Browsing History',
      'Q&A: Email Content',
      'Q&A: File Content',
      'Q&A: Focus Areas',
      'Q&A: GPT only',
      'Q&A: Graph Connectors - Learning',
      'Q&A: Graph Connectors - Other Corp Sites',
      'Q&A: Graph Connectors - PowerBI',
      'Q&A: Graph Connectors - ServiceNow',
      'Q&A: Graph Connectors - Wiki',
      'Q&A: IDEAs Copilot',
      'Q&A: LinkedIn',
      'Q&A: Message Extensions - Confluence',
      'Q&A: Message Extensions - Jira',
      'Q&A: Message Extensions - Mural',
      'Q&A: Message Extensions - Priority Matrix',
      'Q&A: Message Extensions - Trello',
      'Q&A: Message Extensions - Viva Goals',
      'Q&A: People Details',
      'Q&A: Person',
      'Q&A: Power Platform Plugins - FreshDesk',
      'Q&A: Power Platform Plugins - Salesforce',
      'Q&A: Power Platform Plugins - ServiceNow',
      'Q&A: Power Platform Plugins - Twilio',
      'Q&A: Power Platform Plugins - ZenDesk',
      'Q&A: Sticky Notes (OneNote)',
      'Q&A: Stream Video',
      'Q&A: Teams Channels',
      'Q&A: Teams Chat',
      'Q&A: Teams Meeting',
      'Q&A: Tenant Info',
      'Q&A: Topics & Acronyms',
      'Q&A: User Data',
      'Q&A: User Profile/Me',
      'Q&A: Viva Engage',
      'Q&A: Web Only',
      'Rediscover: Person',
      'Search across: User Data',
      'Search for: Calendar Event',
      'Search for: Emails',
      'Search for: Files',
      'Search for: Next Best Contact',
      'Search for: Stream Video (video files in ODSP)',
      'Search for: Teams Chats',
      'Search for: Tenant Info',
      'Web and/or Work',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Chat,
    scenarios: [
      'Generic Pleasantries',
      'Modify: Add Grounding',
      'Modify: Correct Grounding',
      'Refine: Response',
      'Remember: Copilot Profile',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Command,
    scenarios: [
      'Add to: Tasks',
      'Call in: Teams',
      'Capture: Note',
      'Capture: Video',
      'Command: Windows',
      'Draft to: Word',
      'Edit in Outlook, Plain',
      'Modify: File',
      'Open: File',
      'Post to: Teams Channel',
      'Post to: Viva Engage / Answers',
      'Remind Me: Action-based',
      'Remind Me: People-based',
      'Remind Me: Time-based',
      'Schedule: Meeting',
      'Share: File',
      'Write to: Loop',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Create,
    scenarios: [
      'Brainstorm: GPT + Work',
      'Brainstorm: GPT only',
      'Compose: Email Reply',
      'Compose: Introduction Email',
      'Compose: Status Report',
      'Create: Ambiguous Search',
      'Create: GPT + Work',
      'Create: GPT only',
      'Create: Image',
      'Create: Onboarding Plan',
      'Create: PPT from File',
      'Create: PPT from prompt',
      'Create: Web Only',
      'Programming: GPT only',
      'Rewrite: GPT only',
      'Translate: Language',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Summarize,
    scenarios: [
      'Catch up: All Comms',
      'Catch up: Emails',
      'Catch up: Focus Area',
      'Catch up: From Person',
      'Catch up: My Tasks',
      'Catch up: Teams Chats',
      'Catch up: Trending Information',
      'Catch up: With Person',
      'Meeting Prep: Multiple Meetings',
      'Meeting Prep: Single Meeting',
      'Prep for: Day',
      'Q&A: Calendar RSVP',
      'Recap: Multiple Past Meetings',
      'Recap: Past Meeting',
      'Recommend: Files',
      'Summarize: Calendar Time Range',
      'Summarize: Changes to File',
      'Summarize: File',
      'Summarize: GPT only',
      'Summarize: Work AND Web',
    ],
  },
  {
    utteranceGroup: UtteranceGroups.Other,
    scenarios: [],
  },
]
