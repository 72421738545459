import { Api3sOnlineDataGroup2Item, Api3sOnlineDataGroup3Item } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, ITicketTurnHealthData, ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRaw3sOnlineDataGroup2 } from '../actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from '../actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup3'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { get3sTransactionIds } from '../actions-ticket-chat-3s/get3sTransactionIds'
import { get3sGwsLogs } from '../actions-ticket-kusto/get3sGwsLogs'

export function getTicketHealthData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketTurnHealthData> {
  return context.getOrFetch<ITicketTurnHealthData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.health
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.health = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnHealthData> {
    await assertTicket3sTriggered(context, ticketId, turnId)

    const logsPromise = get3sGwsLogs(context, ticketId, turnId).promise
    const group2Promise = getRaw3sOnlineDataGroup2(context, ticketId, turnId).promise
    const group3Promise = getRaw3sOnlineDataGroup3(context, ticketId, turnId).promise

    const logs = await logsPromise.catch(() => undefined)
    const group2 = await group2Promise
    const group3 = await group3Promise.catch(() => undefined)

    // From GWS Logs
    const results: Record<string, ITicketTurnTransactionHealthData> = {}
    for (const item of logs ?? []) {
      if (item.httpStatusCode === '500' && item.transactionId) {
        results[item.transactionId] = getHealthItemData(item, item.transactionId, undefined, undefined, 'GWS log')
      }
    }

    // From 3s online data
    const transactionIds = await get3sTransactionIds(context, ticketId, turnId).promise
    transactionIds.forEach((transactionId, index) => {
      const online = group2[index]
      if (transactionId) {
        results[transactionId] = getHealthItemData(
          logs?.find((log) => log.transactionId === transactionId),
          transactionId,
          online,
          group3?.find((transaction) => {
            return transaction.ReplayResponse.TraceId === transactionId
          }),
          '3S online data',
        )
      }
    })

    return results
  }

  function getHealthItemData(
    fullGWSLog: IKustoGwsLogItem | undefined,
    transactionId: string,
    group2Item: Api3sOnlineDataGroup2Item | undefined,
    group3Item: Api3sOnlineDataGroup3Item | undefined,
    source: string,
  ): ITicketTurnTransactionHealthData {
    const newDiagnosticData: Record<string, unknown> = {}
    if (fullGWSLog) {
      const diagnosticData = fullGWSLog.diagnosticDataCustomized
      Object.keys(diagnosticData ?? {}).forEach((key) => {
        if (
          key.startsWith('ShouldExecuteDomain') &&
          diagnosticData?.[key]?.toString().includes('ShouldExecute: True')
        ) {
          newDiagnosticData[key] = diagnosticData[key]
        }
      })
      if (diagnosticData?.PeopleNaturalSearchAnswerWorkflow) {
        newDiagnosticData['PeopleNaturalSearchAnswerWorkflow'] = diagnosticData?.PeopleNaturalSearchAnswerWorkflow
      }
    }

    const query =
      group2Item?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.DisplayQueryString ||
      group2Item?.ReplayRequest?.AnswerEntityRequests?.[0]?.Query?.QueryString ||
      group2Item?.ReplayRequest?.EntityRequests?.[0]?.Query?.DisplayQueryString ||
      group2Item?.ReplayRequest?.EntityRequests?.[0]?.Query?.QueryString

    const lu = group2Item?.ReplayResponse?.TrimmedResponse?.ExtendedData?.Info

    return {
      transactionId,
      source,
      httpStatusCode: fullGWSLog?.httpStatusCode,
      query,
      lu,
      response: {
        entitySets: getEntitySets(group3Item),
        answerEntitySets: getAnswerEntitySets(group3Item),
      },
      gwsLog: {
        diagnosticData: newDiagnosticData,
        responseMetaJson: fullGWSLog?.responseMetaJsonDecoded,
      },
    }
  }

  function getEntitySets(group3Item: Api3sOnlineDataGroup3Item | undefined): string {
    const results: string[] = []
    for (const entitySet of group3Item?.ReplayResponse.TrimmedResponse.EntitySets ?? []) {
      for (const resultSet of entitySet.ResultSets ?? []) {
        const entityType = resultSet.Results?.[0]?.Type
        const provenance = resultSet.Provenance
        const first = entityType === 'Message' ? provenance || entityType : entityType
        const second = resultSet.Results?.length || 0

        results.push(`[${first}, ${second}]`)
      }
    }

    return results.join(', ')
  }

  function getAnswerEntitySets(group3Item: Api3sOnlineDataGroup3Item | undefined): string {
    const results: string[] = []
    for (const entitySet of group3Item?.ReplayResponse.TrimmedResponse.AnswerEntitySets ?? []) {
      for (const resultSet of entitySet.ResultSets ?? []) {
        const entityType = resultSet.Results?.[0]?.Type
        const provenance = resultSet.Provenance
        const first = entityType === 'Message' ? provenance || entityType : entityType
        const second = resultSet.Results?.length || 0

        results.push(`[${first}, ${second}]`)
      }
    }

    return results.join(', ')
  }
}
