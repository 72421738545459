import { Column, Row } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { FC } from 'react'
import { useStyles } from './StatusField.styles'
interface IProps {
  readonly activity?: IActivityHistory
}

export const OperateCommentFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (isNil(activity?.fieldDiffs?.OperatorComment)) return null

  return (
    <Row vAlign="start">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Reason:</Text>
      </Column>
      <Column>
        <Row>
          {activity?.fieldDiffs?.OperatorComment && (
            <Text className={styles.tag} title={activity?.fieldDiffs?.OperatorComment}>
              {activity?.fieldDiffs?.OperatorComment}
            </Text>
          )}
        </Row>
      </Column>
    </Row>
  )
}
