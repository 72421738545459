import { createContext, useContext } from 'react'

import { IFeedbackCopilotScreenStore } from '../../feedbackcopilot/store/IFeedbackCopilotScreenStore'
import { IFeedbackCopilotScreenActions } from '../../feedbackcopilot/store/IFeedbackCopilotScreenStoreActions'
import { IFeedbackCopilotScreenState } from '../../feedbackcopilot/store/IFeedbackCopilotScreenStoreState'

export const FeedbackCopilotScreenStoreContext = createContext<{
  readonly store: IFeedbackCopilotScreenStore
  readonly actions: IFeedbackCopilotScreenActions
} | null>(null)

function useFeedbackCopilotScreenContext() {
  const value = useContext(FeedbackCopilotScreenStoreContext)
  if (value === null) {
    throw new Error('useFeedbackCopilotScreenContext must be used within a FeedbackCopilotScreenStoreProvider')
  }

  return value
}

export function useFeedbackCopilotScreenState<T>(selector: (state: IFeedbackCopilotScreenState) => T): T {
  return useFeedbackCopilotScreenContext().store.use(selector)
}

export function useFeedbackCopilotScreenActions(): IFeedbackCopilotScreenActions {
  return useFeedbackCopilotScreenContext().actions
}
