import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { convertTo3sGwsLogsByV2Items } from './converter/convertTo3sGwsLogs'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOnlineExists } from '../actions-ticket-assert/assertTicket3sOnlineExists'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function get3sGwsLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoGwsLogItem[]> {
  return context.getOrFetch<IKustoGwsLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.threeSGwsLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.threeSGwsLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.substrateSearchLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asSubstrateSearchLog()
        return convertTo3sGwsLogsByV2Items(results)
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicket3sTriggered(context, ticketId, messageId)
      await assertTicket3sOnlineExists(context, ticketId, messageId, undefined)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.GwsLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.GwsLog, sourceItem })
    },
  })
}
