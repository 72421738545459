import { Row, Spacer } from '@copilot-dash/components'
import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey, JobStatus } from '@copilot-dash/domain'
import { Text, mergeClasses } from '@fluentui/react-components'
import {
  CheckmarkCircleRegular,
  DismissCircleRegular,
  DocumentErrorRegular,
  ShiftsActivityRegular,
  TextBulletListSquareSparkleRegular,
} from '@fluentui/react-icons'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo, useMemo } from 'react'
import { useStyles } from './ColumnComponent.style'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnAmlClusterName: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.ClusterName],
  field: String(JobColumnKey.ClusterName) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderCluster item={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>
        {JobColumnIdNaColumnIdNameMapping[JobColumnKey.ClusterName]}
      </TableCellLayoutWithStyles>
    ),
  },
  minWidth: 400,
  width: 500,
  maxWidth: 600,
  sortable: false,
  resizable: true,
}

const RenderCluster = memo(({ item }: { item: IClusterJobData }) => {
  const styles = useStyles()

  const statusIcon = useMemo(() => {
    if (item.isPublished) {
      return <TextBulletListSquareSparkleRegular className={mergeClasses(styles.blue, styles.iconSize)} />
    }
    switch (item.clusteringRequestStatus) {
      case JobStatus.Submitted:
      case JobStatus.Clustering:
      case JobStatus.DataGathering:
      case JobStatus.DatasetReady:
        return <ShiftsActivityRegular className={mergeClasses(styles.darkOrange, styles.iconSize)} />
      case JobStatus.ClusteringError:
      case JobStatus.DatasetGenerationError:
        return <DocumentErrorRegular className={mergeClasses(styles.yellow, styles.iconSize)} />
      case JobStatus.ClusteringCompleted:
        return <CheckmarkCircleRegular className={mergeClasses(styles.green, styles.iconSize)} />
      case JobStatus.Canceled:
        return <DismissCircleRegular className={mergeClasses(styles.grayColor, styles.iconSize)} />
      default:
        return <DismissCircleRegular className={mergeClasses(styles.grayColor, styles.iconSize)} />
    }
  }, [
    item.clusteringRequestStatus,
    item.isPublished,
    styles.blue,
    styles.darkOrange,
    styles.grayColor,
    styles.green,
    styles.iconSize,
    styles.yellow,
  ])
  return (
    <Row fill={true} vAlign="center" style={{ display: 'flex' }}>
      {statusIcon}
      <Spacer width={'8px'} />
      <Text className={styles.grayColor}>{item.clusterName}</Text>
    </Row>
  )
})

RenderCluster.displayName = 'RenderCluster'
