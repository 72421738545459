import { Draft, produce } from 'immer'
import { getArgs } from './getArgs'
import { isCurrent } from './isCurrent'
import { navigate } from './navigate'
import { IRouteContext } from '../../types/IRouteContext'

export function updateArgsIfActive<TArgs>(
  updater: (draft: Draft<TArgs>) => void,
  context: IRouteContext<TArgs>,
): boolean {
  if (!isCurrent(context)) {
    return false
  }

  const oldArgs = getArgs(context)
  const newArgs = produce(oldArgs, (draft) => {
    updater(draft)
  })

  navigate(newArgs, context)
  return true
}
