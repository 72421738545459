import { ReactNode, useState } from 'react'
import { AnyTable } from '../any-table/AnyTable'
import { AnyTableColDef } from '../any-table/AnyTableTypes'
import { AnyTableToolbar } from '../any-table-toolbar/AnyTableToolbar'
import { TabItemToolbar } from '../tab-item/TabItemToolbar'

interface IProps<T> {
  readonly data: T[]
  readonly toolbar?: ReactNode
  readonly columns?: AnyTableColDef<T>[]
}

export function TabItemForAnyTable<T = unknown>({ data, toolbar, columns }: IProps<T>): ReactNode {
  const [keyword, setKeyword] = useState<string>('')

  return (
    <>
      <TabItemToolbar>
        <AnyTableToolbar keyword={keyword} onSearch={setKeyword}>
          {toolbar}
        </AnyTableToolbar>
      </TabItemToolbar>
      <AnyTable data={data} filter={keyword} columns={columns} />
    </>
  )
}
