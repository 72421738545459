import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Editor, Monaco } from '@monaco-editor/react'
import { editor } from 'monaco-editor'
import { CSSProperties } from 'react'
import { initMonaco } from './instance/initMonaco'
import { MonacoFold, MonacoLanguage } from './MonacoViewTypes'
import { invokeMonacoFoldAction } from './utils/invokeMonacoFoldAction'
import { useMonacoHeight } from './utils/useMonacoHeight'
import { useMonacoValue } from './utils/useMonacoValue'
import { useThemeMode } from '../theme/ThemeModeProvider'

interface IProps {
  readonly data: unknown
  readonly language?: MonacoLanguage
  readonly dark?: boolean
  readonly wrap?: boolean
  readonly minHeight?: CSSProperties['minHeight']
  readonly maxHeight?: CSSProperties['maxHeight']
  readonly defaultFold?: MonacoFold
  readonly showLineNumber?: boolean
  readonly showShadow?: boolean
  readonly className?: string
}

export function MonacoViewInline({
  data,
  language,
  wrap = true,
  minHeight,
  maxHeight,
  defaultFold,
  showLineNumber = false,
  showShadow = false,
  className,
}: IProps) {
  const styles = useStyles()
  const theme = useThemeMode()
  const result = useMonacoValue(data, language)

  const initialHeight = useMonacoHeight(result.value)
  const style: CSSProperties = {
    height: initialHeight,
    minHeight,
    maxHeight,
  }

  const handleOnMount = (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
    if (defaultFold) {
      invokeMonacoFoldAction(editor, defaultFold)
    }
  }

  return (
    <div className={mergeClasses(styles.root, className)} style={style}>
      <Editor
        className={styles.editor}
        value={result.value}
        language={result.language}
        theme={theme === 'dark' ? 'vs-dark' : 'light'}
        beforeMount={initMonaco}
        onMount={handleOnMount}
        options={{
          readOnly: true,
          minimap: { enabled: false },
          wordWrap: wrap ? 'on' : 'off',
          wordWrapColumn: 80,
          lineNumbers: showLineNumber ? 'on' : 'off',
          renderLineHighlight: 'none',
          scrollBeyondLastLine: false,
          scrollbar: {
            useShadows: showShadow,
            vertical: 'visible',
            horizontal: 'auto',
            alwaysConsumeMouseWheel: false,
          },
        }}
      />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflow: 'hidden',
    minHeight: '5rem',

    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
  },

  editor: {
    overflow: 'auto',
    width: '100%',
    minWidth: '100%',

    '& .monaco-editor, .monaco-editor-background, .margin': {
      backgroundColor: 'transparent',
    },

    '& .monaco-editor *:first-child > *:first-child': {
      backgroundColor: 'transparent',
    },
  },
})
