import { User } from '@copilot-dash/api'
import { Row } from '@copilot-dash/components'
import { AsyncSnapshot, useAsyncLoader } from '@copilot-dash/core'
import {
  Combobox,
  mergeClasses,
  Option,
  OptionOnSelectData,
  Persona,
  SelectionEvents,
  Spinner,
} from '@fluentui/react-components'
import { MailRegular } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import * as React from 'react'
import { useStyles } from './PeoplePicker.styles'

interface BaseProps {
  readonly userId?: string
  readonly userEmail?: string
  readonly disable?: boolean
  readonly onSelect?: (userId: string, userEmail: string | undefined) => void
  readonly onDeselect?: () => void
  readonly className?: string
  readonly comboBoxProps?: Partial<React.ComponentProps<typeof Combobox>>
  readonly icon?: React.ReactNode
  readonly placeholder?: string
}
interface PropsA extends BaseProps {
  inputValue?: never
  onInputChange?: never
}

interface PropsB extends BaseProps {
  inputValue: string
  onInputChange: (value: string) => void
}

type IProps = PropsA | PropsB
function isPropsB(props: IProps): props is PropsB {
  return (props as PropsB).inputValue !== undefined
}

export function PeoplePicker(props: IProps) {
  const {
    userId,
    userEmail,
    disable,
    onSelect,
    onDeselect: onDeSelect,
    className,
    comboBoxProps = {},
    icon,
    placeholder,
  } = props

  const styles = useStyles()

  const [_input, _setInput] = React.useState<string>('')
  const [isOpen, setIsOpen] = React.useState(false)

  const input = isPropsB(props) ? props.inputValue : _input
  const setInput = isPropsB(props) ? props.onInputChange : _setInput

  const [submit, snapshot] = useAsyncLoader(
    React.useCallback((input: string): Promise<User[]> | null => {
      if (input.trim().length === 0) {
        return null
      }

      return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
    }, []),
  )

  const debouncedSubmit = React.useMemo(() => debounce(submit, 300), [submit])

  const tryOpen = React.useCallback(() => {
    if (input.trim().length > 0 || snapshot.status !== 'none') {
      if (!isOpen) {
        setIsOpen(true)
      }
    }
  }, [input, isOpen, snapshot.status])

  const onClick = React.useCallback(() => {
    if (!isOpen && input.trim().length > 0) {
      debouncedSubmit(input.trim())
      if (!isOpen) {
        setIsOpen(true)
      }
    }
  }, [debouncedSubmit, input, isOpen])

  const onFocus = React.useCallback(() => {
    if (!isOpen && input.trim().length > 0) {
      debouncedSubmit(input.trim())
      if (!isOpen) {
        setIsOpen(true)
      }
    }
  }, [debouncedSubmit, input, isOpen])

  const onBlur = React.useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
      debouncedSubmit('')
    }
  }, [debouncedSubmit, isOpen])

  const onInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const oldInput = input
      const newInput = e.target.value

      if (oldInput !== newInput) {
        setInput(newInput)
      }

      if (oldInput.trim() !== newInput.trim()) {
        debouncedSubmit(newInput.trim())
        tryOpen()

        if (userId || userEmail) {
          onDeSelect?.()
        }
      }
    },
    [input, setInput, debouncedSubmit, tryOpen, userId, userEmail, onDeSelect],
  )

  const onOptionSelect = React.useCallback(
    (_event: SelectionEvents, data: OptionOnSelectData) => {
      const userId = data.optionValue
      const userEmail = data.optionText

      if (userId) {
        setIsOpen(false)
        onSelect?.(userId, userEmail)
      } else {
        onDeSelect?.()
      }
    },
    [onDeSelect, onSelect],
  )

  return (
    <Row className={mergeClasses(className, styles.emailFilterContainer)}>
      {icon ?? <MailRegular className={styles.mailIcon} />}
      <Combobox
        autoComplete="none"
        aria-autocomplete="none"
        placeholder={placeholder ?? 'From Email'}
        value={input}
        freeform
        clearable
        open={isOpen && input.trim().length !== 0 && snapshot.status !== 'none'}
        listbox={{ className: styles.listbox }}
        className={styles.container}
        disabled={disable}
        onClick={onClick}
        onInput={onInput}
        onOptionSelect={onOptionSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        selectedOptions={userId ? [userId] : []}
        {...comboBoxProps}
      >
        <PeoplePickerContent snapshot={snapshot} />
      </Combobox>
    </Row>
  )
}

function PeoplePickerContent({ snapshot }: { snapshot: AsyncSnapshot<User[]> }) {
  const styles = useStyles()
  switch (snapshot.status) {
    case 'none':
      return (
        <Option key="freeform" disabled>
          None
        </Option>
      )
    case 'waiting':
      return <Spinner className={styles.loading} />
    case 'done':
      if (snapshot.data.length === 0) {
        return (
          <Option key="freeform" disabled>
            No results found
          </Option>
        )
      }

      return snapshot.data.map((user) => {
        if (!user.userPrincipalName || !user.id) {
          return null
        }
        return (
          <Option key={user.id} text={user.userPrincipalName} value={user.id}>
            <Persona
              avatar={{ color: 'colorful', 'aria-hidden': true }}
              name={user.displayName ?? 'No display name'}
              secondaryText={user.userPrincipalName}
              size="small"
            />
          </Option>
        )
      })
    case 'error':
      return (
        <Option key="freeform" disabled>
          {`${snapshot.error}`}
        </Option>
      )
  }
}
