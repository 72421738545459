import { TelemetryScope } from '@copilot-dash/logger'
import { UnknownScreenLayout } from './layout/UnknownScreenLayout'
import { ClarityScenarioIds, useClaritySectionId } from '../../utils/useClarityPageId'

interface IProps {
  readonly path: string
}

export function UnknownScreen(props: IProps) {
  useClaritySectionId(ClarityScenarioIds.notFound)
  return (
    <TelemetryScope scope="UnknownScreen">
      <UnknownScreenLayout path={props.path} />
    </TelemetryScope>
  )
}
