import { ApiClusterJobPublishState } from '@copilot-dash/api'
import { Row } from '@copilot-dash/components'
import { IClusterJobData, IClusterJobResultSummary } from '@copilot-dash/domain'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from '@fluentui/react-components'
import { useCallback, useState } from 'react'
import { useToast } from '../../../../../../hooks/useToast'
import { ClusterRoute, SearchRoute } from '../../../../../../router'

import { useStyles } from '../ClusterResult.styles'

interface IProps {
  loading: boolean
  clusteringRequestId?: string
  selectClusterJob: IClusterJobData | undefined
  tableResultItems: IClusterJobResultSummary[]
}

export function ClusterPublishButton({ loading, clusteringRequestId, selectClusterJob, tableResultItems }: IProps) {
  const styles = useStyles()
  const [uploading, setUploading] = useState(false)
  const args = ClusterRoute.navigator.getArgs()
  const toast = useToast()
  const submitResult = useCallback(() => {
    if (clusteringRequestId) {
      setUploading(true)
      app.store.actions
        .publishClusterResult({
          clusteringRequestId: clusteringRequestId,
          updatedIssueList: tableResultItems,
          state: ApiClusterJobPublishState.SaveAndPublish,
        })
        .then((resp) => {
          setUploading(false)
          if (resp.result) {
            toast.showSuccess(resp.message ?? 'Publish successfully', '', 'top')
            SearchRoute.navigator.navigate({
              product: args.product,
              tab: 'feedbackInsights',
            })
          } else {
            toast.showError(resp.message ?? 'Failed to publish', '', 'top')
          }
        })
        .catch((error) => {
          toast.showError(error.message ?? 'Failed to publish', '', 'top')
        })
        .finally(() => {
          setUploading(false)
        })
    }
  }, [args.product, clusteringRequestId, tableResultItems, toast])

  return (
    <Row className={styles.operationRow}>
      <Dialog>
        <DialogTrigger disableButtonEnhancement>
          <Button className={styles.publishButton} appearance="primary" disabled={loading}>
            Publish
          </Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Publish the clustering?</DialogTitle>
            <DialogContent>
              All Top Issues will be updated with your new cluster results based on{' '}
              {selectClusterJob?.isBase ? 'base model' : 'your configurations'}. Future top issues will also be
              clustered according to {selectClusterJob?.isBase ? 'base model' : 'your latest configurations'}.
            </DialogContent>
            <DialogActions>
              <Button
                appearance="primary"
                disabled={uploading}
                icon={uploading ? <Spinner size="extra-tiny" /> : null}
                onClick={submitResult}
              >
                Publish
              </Button>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>

      <Button
        className={styles.cancelButton}
        disabled={loading}
        onClick={() => {
          ClusterRoute.navigator.navigate({
            product: args.product,
          })
        }}
      >
        Cancel
      </Button>
    </Row>
  )
}
