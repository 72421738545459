import { Body1Stronger } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { CustomFilterCardFilters } from './config'
import { useTicketFilterStore } from '../../store/store'
import {
  EngagementCohortSlicerFilter,
  ErrorCodeFilter,
  ExperienceTypeFilter,
  HasConnectorFilter,
  HasConnectorResultFilter,
  HasGPTExtensionFilter,
  HasMessageExtensionFilter,
  IsGCIntentFilter,
  IsSTCAChinaFilter,
  IsTopi18NTenantFilter,
  SemanticSearchTypeFilter,
  SettingLanguageSlicerFilter,
  UsageIntensityCohortSlicerFilter,
} from '../filters'
import { FeedbackTargetFilter } from '../filters/FeedbackTargetFilter'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function CustomFiltersCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const customizedFilters = useTicketFilterStore((state) => state.customizedFilters)
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const enableCohortFilter = app.features.cohortFilter.use()

  const hasCustomizedFilters = useMemo(() => {
    return (
      customizedFilters.length > 0 &&
      filterPanelItems.some((item) => customizedFilters.includes(item)) &&
      CustomFilterCardFilters.some((item) => customizedFilters.includes(item))
    )
  }, [customizedFilters, filterPanelItems])

  if (!hasCustomizedFilters) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Custom filters</Body1Stronger>
      <Card className={styles.card}>
        {/* Is Top i18N Tenants */}
        {customizedFilters?.includes('isTopi18N') && filterPanelItems.includes('isTopi18N') && (
          <Controller
            name="isTopi18N"
            control={control}
            render={({ field }) => (
              <IsTopi18NTenantFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {/* Is STCA China  */}
        {customizedFilters?.includes('isSTCAChina') && filterPanelItems.includes('isSTCAChina') && (
          <Controller
            name="isSTCAChina"
            control={control}
            render={({ field }) => <IsSTCAChinaFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {/* Has GPT-Extension keywords */}
        {customizedFilters?.includes('hasGPTExtension') && filterPanelItems.includes('hasGPTExtension') && (
          <Controller
            name="hasGPTExtension"
            control={control}
            render={({ field }) => (
              <HasGPTExtensionFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {/* Has MessageExtension keywords  */}
        {customizedFilters?.includes('hasMessageExtension') && filterPanelItems.includes('hasMessageExtension') && (
          <Controller
            name="hasMessageExtension"
            control={control}
            render={({ field }) => (
              <HasMessageExtensionFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}
        {/* Has Connector keywords */}
        {customizedFilters?.includes('hasConnector') && filterPanelItems.includes('hasConnector') && (
          <Controller
            name="hasConnector"
            control={control}
            render={({ field }) => <HasConnectorFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {/* Is GC intent  */}
        {customizedFilters?.includes('isGCIntent') && filterPanelItems.includes('isGCIntent') && (
          <Controller
            name="isGCIntent"
            control={control}
            render={({ field }) => <IsGCIntentFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {/* Has Connector result  */}
        {customizedFilters?.includes('hasConnectorResult') && filterPanelItems.includes('hasConnectorResult') && (
          <Controller
            name="hasConnectorResult"
            control={control}
            render={({ field }) => (
              <HasConnectorResultFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {/* Semantic search type */}
        {customizedFilters?.includes('semanticSearchType') && filterPanelItems.includes('semanticSearchType') && (
          <Controller
            name="semanticSearchType"
            control={control}
            render={({ field }) => (
              <SemanticSearchTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {/* Experience type  */}
        {customizedFilters?.includes('experienceType') && filterPanelItems.includes('experienceType') && (
          <Controller
            name="experienceType"
            control={control}
            render={({ field }) => <ExperienceTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {/* Feedback target  */}
        {customizedFilters?.includes('feedbackTargets') && filterPanelItems.includes('feedbackTargets') && (
          <Controller
            name="feedbackTargets"
            control={control}
            render={({ field }) => <FeedbackTargetFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {/* Error Code  */}
        {customizedFilters?.includes('errorCode') && filterPanelItems.includes('errorCode') && (
          <Controller
            name="errorCode"
            control={control}
            render={({ field }) => (
              <ErrorCodeFilter
                selectedValues={field.value}
                onChangeValue={field.onChange}
                selectedProductId={selectedProductId}
              />
            )}
          />
        )}

        {/* Usage Intensity Cohort */}
        {customizedFilters?.includes('usageIntensityCohortSlicers') &&
          filterPanelItems.includes('usageIntensityCohortSlicers') &&
          enableCohortFilter && (
            <Controller
              name="usageIntensityCohortSlicers"
              control={control}
              render={({ field }) => (
                <UsageIntensityCohortSlicerFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Engagement Cohort  */}
        {customizedFilters?.includes('engagementCohortSlicers') &&
          filterPanelItems.includes('engagementCohortSlicers') &&
          enableCohortFilter && (
            <Controller
              name="engagementCohortSlicers"
              control={control}
              render={({ field }) => (
                <EngagementCohortSlicerFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}
        {/* Setting Language  */}
        {customizedFilters?.includes('languageTierSlicers') && filterPanelItems.includes('languageTierSlicers') && (
          <SettingLanguageSlicerFilter control={control} />
        )}
      </Card>
    </section>
  )
}
