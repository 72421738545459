import { SearchBox, SearchBoxProps } from '@fluentui/react-components'

interface IProps {
  readonly keyword?: string
  readonly onSearch?: (keyword: string) => void
}

export function AnyTableToolbarSearchInput({ keyword, onSearch }: IProps) {
  const handleOnChanged: SearchBoxProps['onChange'] = (_, data) => {
    const value = data.value.trim()
    onSearch?.(value)
  }

  return (
    <SearchBox
      title="Type to search results"
      value={keyword ?? ''}
      placeholder="Search"
      onChange={handleOnChanged}
      type="search"
      autoFocus
      dismiss={null}
    />
  )
}
