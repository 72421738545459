import { Column } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { OutlineGroupHeader } from './OutlineGroupHeader'

interface IProps {
  readonly name?: string
  readonly children: React.ReactNode
}

export function OutlineGroup({ name, children }: IProps) {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      {name && <OutlineGroupHeader name={name} />}
      {children}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    paddingBottom: '16px',
  },
})
