import { OverlayProvider, Overlays } from '@copilot-dash/components'
import { Outlet, useBlocker } from 'react-router-dom'
import { PermissionScreen } from '../../screens/permission/PermissionScreen'
import { RootShell } from '../../screens/root/RootShell'

export function RootRoutePage() {
  // Block navigation if there are overlays open
  useBlocker(() => {
    return Overlays.closeTop()
  })

  return (
    <OverlayProvider>
      <RootShell>
        {(() => {
          if (app.auth.permission?.general === false) {
            return <PermissionScreen />
          }

          return <Outlet />
        })()}
      </RootShell>
    </OverlayProvider>
  )
}
