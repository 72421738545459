import Clarity from '@microsoft/clarity'
import { useEffect } from 'react'

export enum ClarityScenarioIds {
  notFound = '404',
  login = 'login',
  logout = 'logout',
  overviewSearch = 'overview-search',
  overviewInsights = 'overview-insights',
  overviewInsightsIssueDetails = 'overview-insights-issue-details',
  menuAllTickets = 'menu-all-tickets',
  menuUnRootCausedDSATs = 'menu-un-root-caused-dsats',
  menuTopIssues = 'menu-top-issues',
  menuRootCauses = 'menu-root-causes',
  menuRootCauseDetails = 'menu-root-cause-details',
  menuSharedQueries = 'menu-shared-queries',
  ticketDetailPanel = 'ticket-detail-panel',
  ticketDetailPage = 'ticket-detail-page',
}

export function useClaritySectionId(scenarioId: ClarityScenarioIds) {
  useEffect(() => {
    Clarity.setTag('scenarioId', scenarioId)
    return () => {
      Clarity.setTag('scenarioId', '')
    }
  }, [scenarioId])
}
