import { AsyncLoader } from '@copilot-dash/core'
import { IRootCausesByTeam, IUpdateRootCause } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { ITeamViewRootCauses, ITeamViewStore, RootCauseScreenDrawerType } from './types'

export const createRootCauses: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewRootCauses> = (
  set,
  get,
) => {
  const asyncLoader = new AsyncLoader<IRootCausesByTeam>()
  let preFetchTeamName = ''
  return {
    teamRootCauses: { status: 'none' },
    selectedRootCauseId: null,
    selectedVsoAccount: null,
    refreshRootCauseCounter: 0,
    isOpenDiscussionPanel: false,
    isOpenActivityDiscussionPanel: false,
    activeDrawer: undefined,
    computed: {
      get selectedRootCause() {
        return get().rootCauses.teamRootCauses.data?.rootCauseList.find(
          (rootCause) => rootCause.issueId === get().rootCauses.selectedRootCauseId,
        )
      },
    },
    updateRootCauseCounter: () => {
      set((state) => {
        state.rootCauses.refreshRootCauseCounter += 1
      })
    },
    setSelectedRootCause: (rootCauseId = null) => {
      set((state) => {
        state.rootCauses.selectedRootCauseId = rootCauseId
      })
    },
    fetchTeamRootCauses: async (teamName: string, forceRefresh = false) => {
      if (preFetchTeamName === teamName && !forceRefresh && get().rootCauses.teamRootCauses.status === 'done') return
      preFetchTeamName = teamName
      asyncLoader.submit({
        promise: application.store.actions.getTeamRootCauses(teamName),
        onChanged: (snapshot) => {
          set((state) => {
            state.rootCauses.teamRootCauses = snapshot
          })
        },
      })
    },

    updateTeamRootCause: async (data: IUpdateRootCause) => {
      const response = await application.store.actions.updateRootCause(data)
      if (response.errorMessage === '') {
        set((state) => {
          const rootCause = state.rootCauses.teamRootCauses.data?.rootCauseList.find(
            (rootCause) => rootCause.issueId === data.issueId,
          )
          if (rootCause) {
            rootCause.rootCauseTitle = data.rootCauseTitle
            rootCause.rootCauseStatus = data.rootCauseStatus
            rootCause.rootCausePriority = data.rootCausePriority
            rootCause.eTA = data.eTA
            rootCause.owner = data.owner
          }
        })
      }
      return response
    },
    handleDiscussionPanelButtonClick() {
      set((state) => {
        state.rootCauses.isOpenActivityDiscussionPanel = false
        state.rootCauses.isOpenDiscussionPanel = !state.rootCauses.isOpenDiscussionPanel
      })
    },

    handleActivityHistoryPanelButtonClick() {
      set((state) => {
        state.rootCauses.isOpenDiscussionPanel = false
        state.rootCauses.isOpenActivityDiscussionPanel = !state.rootCauses.isOpenActivityDiscussionPanel
      })
    },

    openDrawer: (drawer: RootCauseScreenDrawerType) => {
      set((state) => {
        state.rootCauses.isOpenDiscussionPanel = false
        state.rootCauses.isOpenActivityDiscussionPanel = false
        state.rootCauses.activeDrawer = drawer
      })
    },

    closeDrawer() {
      set((state) => {
        state.rootCauses.isOpenDiscussionPanel = false
        state.rootCauses.isOpenActivityDiscussionPanel = false
        state.rootCauses.activeDrawer = undefined
      })
    },
  }
}
