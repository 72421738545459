import { AsyncLoader, AsyncSnapshot } from '@copilot-dash/core'

import { ProductIds } from '@copilot-dash/domain'
import { stableStringify } from './const'
import { ClusterJobResult, ISearchClusterOptions } from './IClusterScreenStoreState'

export function createClusterSearcher() {
  const asyncLoader = new AsyncLoader<ClusterJobResult>()
  const fetchingPool = new Map<string, Promise<ClusterJobResult>>()
  const _lastFetchingKeyRef: { current: string } = { current: '' }
  const searchTicket = async (
    request: ISearchClusterOptions,
    onChange: (snapshot: AsyncSnapshot<ClusterJobResult>, request?: { productId?: ProductIds }) => void,
  ) => {
    const key = stableStringify(request)
    _lastFetchingKeyRef.current = key
    const { productId, ...requestData } = request
    const promise =
      fetchingPool.get(key) ||
      app.store.actions.getClusterJobListByProduct({ productId: Number(productId), ...requestData })
    fetchingPool.set(key, promise)

    asyncLoader.submit({
      promise,
      onChanged: (snapshot) => {
        if (key !== _lastFetchingKeyRef.current) {
          return
        }
        onChange(snapshot, request)
      },
      onFinished: () => {
        fetchingPool.delete(key)
      },
      onFailed: () => {
        fetchingPool.delete(key)
      },
    })

    return promise
  }

  return searchTicket
}
