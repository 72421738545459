import { z } from 'zod'

export const apiClusterJobResultSummarySchema = z.object({
  issue: z.string(),
  count: z.number().optional(),
  percent: z.number().optional(),
  tenantCount: z.number().optional(),
  priority: z.number().optional(),
  id: z.string(),
})
