import { User } from '@copilot-dash/api'
import { PromiseSnapshot, PromiseSnapshotDone } from '@copilot-dash/core'
import { IRootCauseData } from '@copilot-dash/domain'
import { Text, tokens } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { AssignTo } from './AssignTo'
import { Count } from './Count'
import { CreateDate } from './CreateDate'
import { FixBy } from './FixBy'
import { Name } from './Name'
import { Priority } from './Priority'
import { Remove } from './Remove'
import { State } from './State'

type CompareType = 'ascending' | 'descending'
function compare<T, U = T>(
  a: T | undefined,
  b: T | undefined,
  type: CompareType,
  comparator: (a: U, b: U) => number,
  isComparable = (v: T | U | undefined): v is U => v !== undefined && v !== '',
): number {
  if (!isComparable(a)) return 1
  if (!isComparable(b)) return -1
  return type === 'ascending' ? comparator(a, b) : comparator(b, a)
}

export function stringCompare(a: string | undefined, b: string | undefined, type: CompareType): number {
  return compare(a, b, type, (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
}

function stateCompare(a: string | undefined, b: string | undefined, type: CompareType): number {
  const order = ['New', 'Active', 'Closed']
  return compare(a, b, type, (a, b) => order.indexOf(a) - order.indexOf(b))
}

function countCompare(a: number, b: number, type: CompareType): number {
  return compare(a, b, type, (a, b) => a - b)
}

export function dateCompare(a: string | undefined, b: string | undefined, type: CompareType): number {
  return compare(a, b, type, (a, b) => new Date(a).getTime() - new Date(b).getTime())
}

export function userInfoCompare(
  a: PromiseSnapshot<User> | PromiseSnapshotDone<User> | undefined,
  b: PromiseSnapshot<User> | PromiseSnapshotDone<User> | undefined,
  type: CompareType,
): number {
  return compare<PromiseSnapshot<User>, PromiseSnapshotDone<User>>(
    a,
    b,
    type,
    (a, b) => {
      if (isNil(a.data.displayName)) return 1
      if (isNil(b.data.displayName)) return -1
      return a.data.displayName?.toLocaleLowerCase().localeCompare(b.data.displayName?.toLocaleLowerCase())
    },
    (v: PromiseSnapshot<User> | undefined): v is PromiseSnapshotDone<User> => !isNil(v) && v.status === 'done',
  )
}

export const columnsConfig: Array<{
  id: keyof IRootCauseData | 'Action'
  renderHeaderCell: () => JSX.Element
  renderCell: (data: IRootCauseData) => JSX.Element
  cellStyle?: React.CSSProperties
  onSort?: (a: IRootCauseData, b: IRootCauseData, type: 'ascending' | 'descending') => number
}> = [
  {
    id: 'rootCauseTitle',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Title
      </Text>
    ),
    renderCell: (data) => <Name rowData={data} />,
    cellStyle: {
      minWidth: 300,
      flex: '1 1 800px',
    },
    onSort: (a, b, type) => {
      let num = stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return countCompare(a.rootCauseCount ?? 0, b.rootCauseCount ?? 0, type)
    },
  },
  {
    id: 'owner',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Assign To
      </Text>
    ),
    renderCell: (data) => <AssignTo rowData={data} />,
    cellStyle: {
      minWidth: 260,
      maxWidth: 260,
      flex: '0 0 180px',
    },
    onSort: (a, b, type) => {
      const aUser = a.owner ? app.store.state.users[a.owner]?.profile : undefined
      const bUser = b.owner ? app.store.state.users[b.owner]?.profile : undefined
      let num = userInfoCompare(aUser, bUser, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'rootCausePriority',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Priority
      </Text>
    ),
    renderCell: (data) => <Priority rowData={data} />,
    cellStyle: {
      minWidth: 120,
      maxWidth: 120,
      flex: '0 0 120px',
    },
    onSort: (a, b, type) => {
      if (isNil(a.rootCausePriority)) return 1
      if (isNil(b.rootCausePriority)) return -1
      let num = countCompare(a.rootCausePriority, b.rootCausePriority, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'eTA',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Fix By
      </Text>
    ),
    renderCell: (data) => <FixBy rowData={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      flex: '0 0 180px',
    },
    onSort: (a, b, type) => {
      let num = dateCompare(a.eTA, b.eTA, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'rootCauseCount',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        DSAT Count
      </Text>
    ),
    renderCell: (data) => <Count rowData={data} />,
    cellStyle: {
      minWidth: 160,
      maxWidth: 160,
      flex: '0 0 160px',
    },
    onSort: (a, b, type) => {
      let num = countCompare(a.rootCauseCount ?? 0, b.rootCauseCount ?? 0, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'rootCauseStatus',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        State
      </Text>
    ),
    renderCell: (data) => <State rowData={data} />,
    cellStyle: {
      minWidth: 120,
      maxWidth: 120,
      flex: '0 0 120px',
    },
    onSort: (a, b, type) => {
      let num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      num = countCompare(a.rootCauseCount ?? 0, b.rootCauseCount ?? 0, type)
      if (num !== 0) return -num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'createdTimeUtc',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Create Date
      </Text>
    ),
    renderCell: (data) => <CreateDate rowData={data} />,
    cellStyle: {
      minWidth: 120,
      maxWidth: 120,
      flex: '0 0 120px',
    },
    onSort: (a, b, type) => {
      let num = dateCompare(a.createdTimeUtc, b.createdTimeUtc, type)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus, type)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle, type)
    },
  },
  {
    id: 'Action',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Action
      </Text>
    ),
    renderCell: (data) => <Remove rowData={data} />,
    cellStyle: {
      minWidth: 120,
      maxWidth: 120,
      flex: '0 0 120px',
    },
  },
]
