import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMessageContent } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketApplicableForUserpConversation } from '../actions-ticket-assert/assertTicketApplicableForUserpConversation'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketResponse(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketMessageContent> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.response
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.response = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketMessageContent> {
    await assertTicketHasUserConsent(context, ticketId)
    await assertTicketApplicableForUserpConversation(context, ticketId, 'response')

    // 1. Get value
    const raw = await getRawV2Ticket(context, ticketId).promise
    const response = raw.diagnosticContext?.chat?.[0]?.response
    if (response) {
      return {
        language: response.language,
        text: response.text,
        textInEnglish: response.textInEnglish,
        markdown: response.text,
        markdownInEnglish: response.textInEnglish,
        adaptiveCard: undefined,
      }
    }

    throw TicketError.create('NoResponse', { ticketId })
  }
}
