import { z } from 'zod'

export interface ApiClusterScenario {
  scenario?: string
  description?: string
  utteranceExamples?: string[]
  updateDateTime?: string
  updateBy?: string
}

export interface ApiClusterAreaIssue {
  area?: string
  description?: string
  categories?: object
  updateDateTime?: string
  updateBy?: string
}

export interface ApiClusterClusterConfigData {
  id: string
  product?: string
  productId?: number
  scenarios?: ApiClusterScenario[]
  areaIssues?: ApiClusterAreaIssue[]
}

const apiScenarioSchema = z.object({
  scenario: z.string().optional(),
  description: z.string().optional(),
  utteranceExamples: z.array(z.string()).optional(),
  updateDateTime: z.string().optional(),
  updateBy: z.string().optional(),
})

const apiAreaIssueSchema = z.object({
  area: z.string().optional(),
  description: z.string().optional(),
  categories: z.object({}).optional(),
  updateDateTime: z.string().optional(),
  updateBy: z.string().optional(),
})

const apiClusterConfigDataSchema = z.object({
  id: z.string(),
  product: z.string().optional(),
  productId: z.number().optional(),
  scenarios: z.array(apiScenarioSchema).optional(),
  areaIssues: z.array(apiAreaIssueSchema).optional(),
})

export const apiClusterConfigDataResponseSchema = z.array(apiClusterConfigDataSchema)
