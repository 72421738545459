import { makeStyles, tokens } from '@fluentui/react-components'
import { RouteLink } from '../../../../router'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenVerbatimScreenshots() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const metadataSnapshot = app.store.use.getTicketMetadata(ticketId)
  const metadata = metadataSnapshot.data
  if (!metadata) {
    return null
  }

  if (!metadata.screenshotCount || !metadata.ocvLink) {
    return null
  }

  return (
    <RouteLink className={styles.link} type="link" path={metadata.ocvLink} openInNewTab>
      View screenshots
    </RouteLink>
  )
}

const useStyles = makeStyles({
  link: {
    fontWeight: tokens.fontWeightSemibold,
  },
})
