import { getCurrentRouteParamsHash } from './getCurrentRouteParamsHash'
import { getCurrentRouteParamsPath } from './getCurrentRouteParamsPath'
import { getCurrentRouteParamsQuery } from './getCurrentRouteParamsQuery'
import { getCurrentRouteParamsState } from './getCurrentRouteParamsState'
import { IRouteParams } from '../../../types/IRouteParams'

export function getCurrentRouteParams(): IRouteParams {
  const pathParams = getCurrentRouteParamsPath()
  const queryParams = getCurrentRouteParamsQuery()
  const hash = getCurrentRouteParamsHash()
  const state = getCurrentRouteParamsState()

  return {
    pathParams,
    queryParams,
    hash,
    state,
  }
}
