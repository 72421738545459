import { Row } from '@copilot-dash/components'
import { TicketCallFlow } from '@copilot-dash/domain'
import { makeStyles, tokens } from '@fluentui/react-components'
import { TurnViewBarLegendItem } from './TurnViewBarLegendItem'

interface IProps {
  readonly data: TicketCallFlow
  readonly selectedServiceIds: string[]
  readonly onServiceToggle: (serviceId: string) => void
}

export function TurnViewBar({ data, selectedServiceIds, onServiceToggle }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root}>
      <div className={styles.background} />
      <Row className={styles.surface} wrap>
        {data.services.map((item) => {
          return (
            <TurnViewBarLegendItem
              key={item.id}
              service={item}
              selected={selectedServiceIds.includes(item.id)}
              hasSelection={selectedServiceIds.length > 0}
              onToggle={() => {
                onServiceToggle(item.id)
              }}
            />
          )
        })}
      </Row>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'relative',
    width: '100%',
    backdropFilter: 'blur(4px)',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: tokens.colorNeutralBackground4,
    opacity: 0.78,
    zIndex: -1,
  },
  surface: {
    padding: '2px 16px',
  },
})
