import { ApiRootCausesByTeamResponse } from '@copilot-dash/api'
import { IRootCausesByTeam } from '@copilot-dash/domain'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'

export function convertTeamRootCauseList(apiData: ApiRootCausesByTeamResponse): IRootCausesByTeam {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rootCauseList, recommendedRootCausesList, ...otherData } = apiData
  const convertRCRData = recommendedRootCausesList.map((item) => {
    const { owner, ...list } = item
    return {
      ...list,
      owner: isAssignedTo3SDashTorus(owner) ? undefined : owner,
      createdTimeUtc: item.eTA ?? '',
      rootCauseType: 'Issue' as const,
      teamId: '',
    }
  })
  return { rootCauseList: [], recommendedRootCausesList: convertRCRData, ...otherData }
}
