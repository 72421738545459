import { RouterNavigateOptions } from '@remix-run/router'
import { generatePathByPathPatternAndParams } from './utils/generatePathByPathPatternAndParams'
import { IRouteContext } from '../../types/IRouteContext'

export function navigate<TArgs>(
  args: TArgs,
  context: IRouteContext<TArgs>,
  options?: RouterNavigateOptions,
): Promise<void> {
  const params = context.mapper.mapToParams(args)
  const path = generatePathByPathPatternAndParams(context.path, params)
  const state = params.state
  const mergedOptions = { state, ...options }
  return app.router.navigate(path, mergedOptions)
}
