import { ProductIds } from './defaults/ProductIds'
import { ProductName } from './ProductName'

export interface IProductData {
  readonly name: ProductName
  readonly title: string
  readonly productId: ProductIds
  readonly endpoints: IProductEndpointData[]
  readonly channels: IProductChannelData[]
}

export interface IProductEndpointData {
  readonly productId: ProductIds
  readonly name: string
  readonly title: string
  readonly application?: ApplicationType
  readonly platform?: PlatformType
  readonly license?: LicenseType
  readonly scenario?: ScenarioType
}

export interface IProductChannelData {
  readonly productId: ProductIds
  readonly name: string
  readonly title: string
}

export const ProductApplication = [
  'M365',
  'Office.com',
  'Copilot.cloud.microsoft',
  'Teams',
  'Teams MetaOS',
  'Outlook',
  'Outlook Monarch',
  'Outlook OWA',
  'Union',
  'Union Legacy',
  'Edge',
  'Edge MetaOS',
  'Custom Copilot Webpart',
  'OneDrive for Business',
  'ODSP',
  'OneUp',
  'SharePoint Doc Libs',
  'SharePoint Pages',
  'SharePoint Lists',
  'Unknown ODSP',
  'BCB Legacy',
  'DevUI',
  'Side By Side',
] as const
export type ApplicationType = (typeof ProductApplication)[number]

export const ProductPlatform = ['Web', 'Desktop', 'Windows', 'Mac', 'iOS', 'Android', 'Mobile - Unknown'] as const
export type PlatformType = (typeof ProductPlatform)[number]

export const ProductLicense = ['Premium', 'Starter / Paid', 'Free'] as const
export type LicenseType = (typeof ProductLicense)[number]

export type ScenarioType = 'During Meeting' | 'Post Meeting' | 'USERP' | 'USERP Copilot'
