import { z } from 'zod'

export interface ApiDeleteRootCauseResponse {
  readonly isSucceeded: boolean
  readonly errorMessage: string
}

export const apiDeleteRootCauseResponseSchema = z.object({
  isSucceeded: z.boolean(),
  errorMessage: z.string(),
})
