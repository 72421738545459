import { MonacoView } from '@copilot-dash/components'
import { usePromise } from '@copilot-dash/core'
import { useMemo } from 'react'
import { TicketAsyncView } from '../../../ticket/children/common/TicketAsyncView'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function MainForegroundForMetadata() {
  const ticketId = TicketDiagnosticStore.use((state) => state.ticketId)
  const promise = useMemo(() => {
    return app.api.copilotDash.getTicket(ticketId)
  }, [ticketId])

  const snapshot = usePromise(promise)

  return (
    <TicketAsyncView snapshot={snapshot} loadingMessage="Loading Ticket Metadata...">
      {(data) => <MonacoView data={data} />}
    </TicketAsyncView>
  )
}
