import {
  ApiActivityHistory,
  ApiField,
  ApiRootCauseActiveContext,
  ApiRCRUserAction,
  ApiIssueInfo,
  ApiWorkItemRelation,
} from '@copilot-dash/api'
import {
  IActivityHistory,
  ActivityHistoryRootCauseContext,
  TicketIssueData,
  ActivityHistoryType,
  activityHistoryTypeSchema,
  SystemType,
  systemTypeSchema,
  ActivityFieldDiff,
  actorTypeSchema,
  ActorType,
  ActivityHistoryRCRUserAction,
  WorkItemRelationData,
} from '@copilot-dash/domain'
import { isNil, isEqual } from 'lodash'

export class ActivityHistoryV2Converter {
  static readonly getActivityHistoriesDiff = (data: ApiActivityHistory[]): IActivityHistory[] => {
    data.sort((a, b) => a.timestamp.localeCompare(b.timestamp))
    const lastField: ApiField = {}
    const collectedDiff: IActivityHistory[] = []
    let version = 0
    for (const activity of data) {
      const parsedActivityType = this.safeParseActivityHistoryType(activity.activityType)
      const parsedSystemType = this.safeParseSystemType(activity.system)
      const parsedActorType = this.safeParseActorType(activity.actor)
      const calcDiff = this.generateFieldDiff(activity, collectedDiff, lastField)

      collectedDiff.push({
        revision: version,
        activityId: activity.activityId,
        ticketId: activity.entityId,
        timestamp: activity.timestamp,
        activity: parsedActivityType,
        actor: parsedActorType,
        system: parsedSystemType,
        userId: activity.userId,
        fieldDiffs: calcDiff,
      })
      version++
    }
    return collectedDiff
  }

  private static generateFieldDiff(
    activityItem: ApiActivityHistory,
    currentDiff: IActivityHistory[],
    lastField: ApiField,
  ): ActivityFieldDiff | undefined {
    const parsedActivityType = this.safeParseActivityHistoryType(activityItem.activityType)
    let lastCommentContent: string | undefined
    let lastCustomTags: string[] | undefined
    let currentCustomTags: string[] | undefined
    const newFieldDiff: ActivityFieldDiff = {}
    switch (parsedActivityType) {
      case 'AddComment':
        return {
          CommentContent: { newValue: activityItem.field?.commentContent },
          CommentId: { newValue: activityItem.field?.commentId },
        }
      case 'DeleteComment':
        lastCommentContent = this.findCommentContentById(currentDiff, activityItem.field?.commentId)
        return {
          CommentContent: { oldValue: lastCommentContent },
          CommentId: { oldValue: activityItem.field?.commentId },
        }
      case 'UpdateComment':
        lastCommentContent = this.findCommentContentById(currentDiff, activityItem.field?.commentId)
        return {
          CommentContent: { oldValue: lastCommentContent, newValue: activityItem.field?.commentContent },
          CommentId: { oldValue: activityItem.field?.commentId, newValue: activityItem.field?.commentId },
        }
      case 'SetTicketRootCausingActions':
        return {
          RootCauseActiveContext: {
            newValue: activityItem.field?.rootCauseActiveContextList?.map(this.mapRootCauseActiveContext),
          },
        }
      case 'UpdateRootCauseRecommendation':
        return {
          RCRUserActions: {
            newValue: activityItem.field?.rcrUserActions?.map(this.mapRCRUserActions),
          },
        }

      case 'UpdateCustomTags':
        lastCustomTags = this.findLatestCustomTag(currentDiff)
        currentCustomTags = activityItem.field?.customTags
        if (this.isSameStringList(lastCustomTags, currentCustomTags)) {
          return undefined
        }
        return {
          CustomTags: { oldValue: lastCustomTags, newValue: currentCustomTags },
        }
      case 'WorkItemCreated':
      case 'TicketStatusUpdate':
      case 'WorkItemAutoTriage':
      case 'WorkItemDetailsUpdated':
      case 'RootCauseCreated':
      case 'RootCauseDeleted':
      case 'RootCauseDetailsUpdated':
        if (!activityItem.field || Object.keys(activityItem.field).length === 0) {
          return undefined
        }
        if (!this.isSameString(lastField.state, activityItem.field?.state)) {
          newFieldDiff.State = {
            oldValue: lastField.state ?? undefined,
            newValue: activityItem.field?.state ?? undefined,
          }
          lastField.state = activityItem.field?.state
        }
        if (!this.isSameString(lastField?.assignTo, activityItem.field?.assignTo)) {
          newFieldDiff.AssignTo = {
            oldValue: lastField.assignTo ?? undefined,
            newValue: activityItem.field?.assignTo ?? undefined,
          }
          lastField.assignTo = activityItem.field?.assignTo
        }
        if (!this.isSameString(lastField?.teamArea, activityItem.field?.teamArea)) {
          newFieldDiff.TeamArea = {
            oldValue: lastField.teamArea,
            newValue: activityItem.field?.teamArea ?? undefined,
          }
          lastField.teamArea = activityItem.field?.teamArea
        }
        if (!this.isSameString(lastField?.teamId?.toString(), activityItem.field?.teamId?.toString())) {
          newFieldDiff.TeamId = {
            oldValue: lastField?.teamId?.toString() ?? undefined,
            newValue: activityItem.field?.teamId?.toString() ?? undefined,
          }
          lastField.teamId = activityItem.field?.teamId
        }
        if (!this.isSameString(lastField?.priority?.toString(), activityItem.field?.priority?.toString())) {
          newFieldDiff.Priority = {
            oldValue: lastField?.priority?.toString() ?? undefined,
            newValue: activityItem.field?.priority?.toString() ?? undefined,
          }
          lastField.priority = activityItem.field?.priority
        }
        if (!this.isSameString(lastField.eta, activityItem.field?.eta)) {
          newFieldDiff.ETA = {
            oldValue: lastField.eta ?? undefined,
            newValue: activityItem.field?.eta ?? undefined,
          }
          lastField.eta = activityItem.field?.eta
        }
        if (!this.isSameString(lastField.title, activityItem.field?.title)) {
          newFieldDiff.Title = {
            oldValue: lastField.title ?? undefined,
            newValue: activityItem.field?.title ?? undefined,
          }
          lastField.title = activityItem.field?.title
        }
        if (!this.isSameString(lastField.description, activityItem.field?.description)) {
          newFieldDiff.Description = {
            oldValue: lastField.description ?? undefined,
            newValue: activityItem.field?.description ?? undefined,
          }
          lastField.description = activityItem.field?.description
        }
        if (!this.isSameTicketIssueDataList(lastField?.issueList, activityItem.field?.issueList)) {
          newFieldDiff.IssueList = {
            oldValue: lastField?.issueList ? lastField?.issueList.map(this.mapTicketIssueData) : undefined,
            newValue: activityItem.field?.issueList
              ? activityItem.field?.issueList.map(this.mapTicketIssueData)
              : undefined,
          }
          lastField.issueList = activityItem.field?.issueList
        }
        if (
          !this.isSameWorkItemRelationDataList(
            lastField?.workItemRelationList,
            activityItem.field?.workItemRelationList,
          )
        ) {
          newFieldDiff.WorkItemRelationList = {
            oldValue: lastField?.workItemRelationList
              ? lastField?.workItemRelationList.map(this.mapWorkItemRelationData)
              : undefined,
            newValue: activityItem.field?.workItemRelationList
              ? activityItem.field?.workItemRelationList.map(this.mapWorkItemRelationData)
              : undefined,
          }
          lastField.workItemRelationList = activityItem.field?.workItemRelationList
        }
        if (activityItem.field?.operateComment) {
          newFieldDiff.OperatorComment = activityItem.field.operateComment
        }
        return newFieldDiff

      case 'Unset':
      case 'FeedbackCreated':
      case 'FeedbackCooked':
      default:
        return undefined
    }
  }

  private static mapRootCauseActiveContext(context: ApiRootCauseActiveContext): ActivityHistoryRootCauseContext {
    return {
      AdoIssueId: context.adoIssueId,
      Title: context.title,
      UserAction: context.userAction,
      Project: context.project,
      Areapath: context.areapath,
      AdoLink: context.adoLink,
      TeamName: context.teamName,
    }
  }

  private static mapTicketIssueData(context: ApiIssueInfo): TicketIssueData {
    return {
      IssueId: context.issueId,
      VsoAccount: context.vsoAccount,
      RootCauseTitle: context.rootCauseTitle,
    }
  }

  private static mapWorkItemRelationData(context: ApiWorkItemRelation): WorkItemRelationData {
    return {
      WorkItemId: context.workItemId,
      RelationType: context.relationType,
    }
  }
  private static isSameList<T>(getSortKey: (item: T) => string, oldValue?: T[], newValue?: T[]): boolean {
    const isEmpty = (arr?: T[]) => isNil(arr) || arr.length === 0

    if (isEmpty(oldValue) && isEmpty(newValue)) return true
    if (isEmpty(oldValue) || isEmpty(newValue)) return false

    const sort = (arr: T[]) => [...arr].sort((a, b) => getSortKey(a).localeCompare(getSortKey(b)))

    return isEqual(sort(oldValue as T[]), sort(newValue as T[]))
  }

  private static isSameWorkItemRelationDataList(
    oldValue?: ApiWorkItemRelation[],
    newValue?: ApiWorkItemRelation[],
  ): boolean {
    return this.isSameList((item) => item.workItemId, oldValue, newValue)
  }

  private static isSameTicketIssueDataList(oldValue?: ApiIssueInfo[], newValue?: ApiIssueInfo[]): boolean {
    return this.isSameList((item) => item.issueId, oldValue, newValue)
  }

  private static isSameStringList(oldValue?: string[], newValue?: string[]): boolean {
    return this.isSameList((item) => item, oldValue, newValue)
  }

  private static isSameString(oldValue?: string, newValue?: string): boolean {
    if (isNil(oldValue) && isNil(newValue)) return true
    if ((isNil(oldValue) && newValue === '') || (oldValue === '' && isNil(newValue))) return true
    return oldValue === newValue
  }

  private static mapRCRUserActions(context: ApiRCRUserAction): ActivityHistoryRCRUserAction {
    return {
      WorkItemId: context.workItemId,
      UserActionType: context.userActionType,
    }
  }

  private static findCommentContentById(diffs: IActivityHistory[], commentId: string | undefined): string | undefined {
    if (!commentId) return undefined

    const recentDiff = diffs
      .filter((d) => d.fieldDiffs?.CommentId?.newValue === commentId)
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      .shift()

    if (!recentDiff) return undefined

    return recentDiff.fieldDiffs?.CommentContent?.newValue
  }

  private static findLatestCustomTag(diffs: IActivityHistory[]): string[] | undefined {
    const recentDiff = diffs
      .filter((d) => d.activity === 'UpdateCustomTags')
      .filter((d) => d.fieldDiffs !== undefined)
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      .shift()

    if (!recentDiff) return undefined

    return recentDiff.fieldDiffs?.CustomTags?.newValue
  }

  public static safeParseActorType(value: unknown): ActorType {
    const result = actorTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'unset'
    }
  }

  public static safeParseSystemType(value: unknown): SystemType {
    const result = systemTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }

  public static safeParseActivityHistoryType(value: unknown): ActivityHistoryType {
    const result = activityHistoryTypeSchema.safeParse(value)
    if (result.success) {
      return result.data
    } else {
      return 'Unset'
    }
  }
}
