import { ReactNode } from 'react'
import { Root } from './children/Root'
import { TabStore } from './TabStore'

interface IProps {
  readonly tab?: string
  readonly onTabChange?: (tab: string) => void
  readonly className?: string
  readonly children?: ReactNode
}

export function Tab({ tab, onTabChange, className, children }: IProps): ReactNode {
  return (
    <TabStore tab={tab} onTabChange={onTabChange}>
      <Root className={className}>{children}</Root>
    </TabStore>
  )
}
