import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { ChevronDownFilled } from '@fluentui/react-icons'
import { IExpansionState } from './useExpansion'

interface IProps {
  readonly state: IExpansionState
}

export function InvocationMessageItemForExpansion({ state }: IProps) {
  const styles = useStyles()

  if (!state.expandable) {
    return null
  }

  return (
    <Button
      className={styles.root}
      size="small"
      appearance="transparent"
      icon={<ChevronDownFilled className={styles.icon} />}
      onClick={state.toggle}
    >
      {`Show remaining ${state.collapsedCount} invocations`}
    </Button>
  )
}

const useStyles = makeStyles({
  root: {
    marginLeft: '-3px',
    color: tokens.colorBrandForeground1,
  },
  icon: {
    fontSize: '10px',
    marginLeft: '1px',
  },
})
