import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.flex(1),
    ...shorthands.overflow('hidden'),
  },
  body: {
    ...shorthands.overflow('hidden', 'auto'),
    columnGap: 0,
    width: '100%',
    flexDirection: 'column',
    ...shorthands.padding('0px', '0px', '0px', '0px'),
  },
  framerContainer: {
    height: '100%',
  },
  framerPanel: {
    width: '100%',
    height: '100%',
  },
  right: {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, .14)',
    height: '100%',
    '& .fui-Drawer': {
      transitionDuration: '0s',
    },
  },
})
