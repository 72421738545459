import { ApiGetClusterJobListRequest } from '@copilot-dash/api'
import { ClusterJobResult } from './ClusterJobResult'
import { convertClusterJobList } from './converters/convertClusterJobList'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getClusterJobListByProduct(
  context: IDashStoreContext,
  jobSearchRequest: ApiGetClusterJobListRequest,
): Promise<ClusterJobResult> {
  const response = await context.api.automation.getClusterJobListByProduct(jobSearchRequest)
  return convertClusterJobList(response)
}

// export async function getdataset list by product
export async function getDataSetListByProduct(
  context: IDashStoreContext,
  productId: number,
): Promise<ClusterJobResult> {
  const response = await context.api.automation.getDataSetListByProduct({ productId: productId })
  return convertClusterJobList(response)
}
