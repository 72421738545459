import { IDashAuth } from '@copilot-dash/auth'
import { redirect, Route } from 'react-router-dom'
import { LoginRouteMapper } from './LoginRouteMapper'
import { LoginRoutePage } from './LoginRoutePage'
import { createNavigator } from '../../navigator/createNavigator'

export function LoginRoute(auth: IDashAuth) {
  return (
    <Route
      id={ID}
      path={PATH}
      element={<LoginRoutePage />}
      loader={() => {
        const isAuthenticated = auth.isAuthenticated
        if (isAuthenticated) {
          return redirect('/')
        }

        return null
      }}
    />
  )
}

const ID = 'login'
const PATH = '/login'
LoginRoute.navigator = createNavigator(ID, PATH, LoginRouteMapper)
