import { Column } from '@copilot-dash/components'
import { ITicketDiagnosticData } from '@copilot-dash/domain'
import { makeStyles, Text } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketDiagnosticData
}

export function OutlineBar({ data }: IProps) {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <Text size={500} weight="semibold" truncate wrap={false}>
        Ticket Diagnostic 🩺
      </Text>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '12px 16px',
  },
})
