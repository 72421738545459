import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getSymptomReportForCWC(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<object> {
  return context.getOrFetch<object>({
    get: (state) => {
      return state.tickets[ticketId]?.symptomReportForCWC
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.symptomReportForCWC = snapshot
    },
    fetch: async () => {
      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.cwcSymptomReport
      if (sourceItem?.url) {
        const response = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asCWCSymptomReport()
        return response[0]?.FeedbackAnalysis
      }
      throw TicketError.create('NoCWCAnalysis', { ticketId })
    },
  })
}
