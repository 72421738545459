import { MonacoViewInline, Row } from '@copilot-dash/components'
import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { TableCell, TableRow, Text } from '@fluentui/react-components'
interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrievalLU({ data }: IProps) {
  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - LU</Text>
        <br />
        Source: {data.source}
      </TableCell>
      <TableCell>
        <p>
          <span>
            {data.lu?.LuAlteredQueries ? (
              'Has LUAlteredQueries'
            ) : (
              <span>
                No LUAlteredQueries{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
            ,{' '}
          </span>
          <span>
            {data?.lu?.LlmLuOutput ? (
              'Has LlmLuOutput'
            ) : (
              <span>
                No LlmLuOutput{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
            ,{' '}
          </span>
          <span>
            {data?.lu?.LuClassification ? (
              'Has LUClassification'
            ) : (
              <span>
                No LUClassification{' '}
                <Text weight="semibold" style={{ color: 'orange' }}>
                  Warning
                </Text>
              </span>
            )}
          </span>
        </p>
        {data?.lu && Object.keys(data?.lu).length > 0 && (
          <Row hAlign="stretch">
            <MonacoViewInline data={data?.lu} language="json" />
          </Row>
        )}
      </TableCell>
    </TableRow>
  )
}
