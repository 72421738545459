import { IState } from './IState'

interface IParams {
  readonly activeTab: string | undefined
  readonly onActiveTabChange: ((tab: string) => void) | undefined
}

export function createState({ activeTab, onActiveTabChange }: IParams): IState {
  return {
    items: [],
    activeTab: activeTab,
    onActiveTabChange: onActiveTabChange,
  }
}
