import { z } from 'zod'
import { ApiV2RootCausesResponse, apiV2RootCausesResponseSchema } from './ApiV2RootCausesResponse'

export interface ApiV2WorkItemResponse {
  readonly id: string
  readonly adoWorkItemId?: string
  readonly dsatStatus:
    | 'Untriaged'
    | 'TeamAssigned'
    | 'RootCaused'
    | 'ClosedFixed'
    | 'ClosedByDesign'
    | 'ClosedNotActionable'
  readonly priority: string
  readonly teamId: number
  readonly assignTo?: string
  readonly adoWorkItemLink?: string
  readonly rootCauseList: ApiV2RootCausesResponse
  readonly closedComment?: string
}

export const apiV2WorkItemResponseSchema = z.object({
  id: z.string(),
  adoWorkItemId: z.string().optional(),
  dsatStatus: z.enum([
    'Untriaged',
    'TeamAssigned',
    'RootCaused',
    'ClosedFixed',
    'ClosedByDesign',
    'ClosedNotActionable',
  ]),
  priority: z.string(),
  teamId: z.number(),
  assignTo: z.string().optional(),
  adoWorkItemLink: z.string().optional(),
  rootCauseList: apiV2RootCausesResponseSchema,
  closedComment: z.string().optional(),
})
