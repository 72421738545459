export enum ProductIds {
  All = '0',
  M365Chat = '1',
  M365ChatWebChat = '2',
  ExcelCopilot = '3',
  WordCopilot = '4',
  PPTCopilot = '5',
  TeamsMeetingCopilot = '6',
  ODSPCopilot = '7',
  TenantAdminFeedback = '8',
  LoopCopilot = '9',
  OneNoteCopilot = '10',
  OutlookCopilot = '11',
  M365Agent = '12',
  USERP = '13',
  SearchChat = '14',
  AgentBuilder = '15',
  CopilotActions = '16',
  VivaEngageCopilot = '17',
}
