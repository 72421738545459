import { Column } from '@copilot-dash/components'
import { ITicketTurnConversation } from '@copilot-dash/domain'
import { Accordion, makeStyles, tokens } from '@fluentui/react-components'
import { useState } from 'react'
import { InvocationMessageItemForData } from './InvocationMessageItemForData'
import { InvocationMessageItemForExpansion } from './InvocationMessageItemForExpansion'
import { useExpansion } from './useExpansion'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function InvocationMessageView({ data }: IProps) {
  const styles = useStyles()
  const [openItems, setOpenItems] = useState<unknown[]>([])
  const expansion = useExpansion(data.invocations?.length ?? 0)

  const invocations = data.invocations
  if (!invocations || invocations.length === 0) {
    return null
  }

  return (
    <Column className={styles.root} hAlign="stretch">
      <Accordion
        className={styles.accordion}
        openItems={openItems}
        multiple
        collapsible
        onToggle={(_, data) => {
          setOpenItems(data.openItems)
        }}
      >
        <div className="timeline-line"></div>
        {invocations.map((item, index) => {
          if (index < expansion.expandedCount) {
            return (
              <InvocationMessageItemForData
                index={index}
                data={item}
                key={index}
                expanded={openItems.includes(index)}
              />
            )
          } else {
            return null
          }
        })}
      </Accordion>
      <div>
        <InvocationMessageItemForExpansion state={expansion} />
      </div>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    marginLeft: '52px',
    marginRight: '16px',
  },
  accordion: {
    position: 'relative',

    '& .timeline-line': {
      position: 'absolute',
      top: '16px',
      bottom: '16px',
      left: '16px',
      width: '1px',
      marginLeft: '-0.5px',
      backgroundColor: tokens.colorNeutralStroke1,
    },
  },
})
