import { Body, Column, Header, Row, Spacer } from '@copilot-dash/components'
import { ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { SelectTabData, SelectTabEvent, Tab, TabList } from '@fluentui/react-components'
import { ColDef } from 'ag-grid-community'
import { memo, useMemo } from 'react'
import { AllFeedback } from './allFeedback/AllFeedback'
import { FeedbackInsightsLayout } from './feedbackInsights'
import { useStyles } from './SearchScreenBody.styles'
import { useSearchScreenState } from '../store'

interface IProps {
  readonly selectedColumns: ColDef<INewTicketData>[]
  readonly selectedValue: unknown
  readonly onTabSelect: (event: SelectTabEvent, data: SelectTabData) => void
  readonly setColumns: (value: ColumnKey[]) => void
}

export const SearchScreenBody = memo(function SearchScreenBody({
  selectedColumns,
  selectedValue,
  onTabSelect,
  setColumns,
}: IProps) {
  const styles = useStyles()
  const searchSnapshot = useSearchScreenState((state) => state.loadMoreSnapshot)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)

  const ticketCount = useMemo(() => {
    switch (searchSnapshot[0]?.status) {
      case 'none':
        return '⚪'
      case 'waiting':
        return '⏳'
      case 'done':
        return searchFeedbackResult.total.toLocaleString() ?? 'No data'
      case 'error':
        return 0
      default:
        return null
    }
  }, [searchFeedbackResult.total, searchSnapshot])

  return (
    <Column className={styles.container}>
      {/* Header */}
      <Spacer height="20px" />
      <Header className={styles.header}>
        <Row>
          {/* Left */}
          <Spacer width="10px" />
          <TabList selectedValue={selectedValue} size="medium" onTabSelect={onTabSelect}>
            <Tab value="allFeedback">All Feedback ({ticketCount})</Tab>
            <Tab value="feedbackInsights">Feedback Insights</Tab>
          </TabList>
          <Spacer />
        </Row>
      </Header>

      {/* Body */}
      <Body className={styles.body}>
        {selectedValue === 'allFeedback' && <AllFeedback selectedColumns={selectedColumns} setColumns={setColumns} />}
        {selectedValue === 'feedbackInsights' && <FeedbackInsightsLayout />}
      </Body>
    </Column>
  )
})
