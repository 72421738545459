import { INewTicketData, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'

export const ColumnConfigRing: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Ring],
  field: String(ColumnKey.Ring) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <TableCellLayoutWithStyles truncate>{props.data.ring}</TableCellLayoutWithStyles>
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.Ring]}</TableCellLayoutWithStyles>,
  },
  width: 100,
  minWidth: 80,
  sortable: true,
  resizable: true,
}
