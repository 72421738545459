import { useMemo } from 'react'

interface IResult {
  readonly value: string
  readonly language?: string
}

export function useMonacoValue(data: unknown, language: string | undefined): IResult {
  return useMemo(() => {
    if (data === undefined) {
      return {
        value: 'undefined',
        language: 'plaintext',
      }
    }

    if (data === null) {
      return {
        value: 'null',
        language: 'plaintext',
      }
    }

    if (typeof data === 'string') {
      return {
        value: data,
        language: language ?? (isJsonString(data) ? 'json' : 'plaintext'),
      }
    }

    try {
      return {
        value: JSON.stringify(data, null, 2),
        language: 'json',
      }
    } catch (e) {
      return {
        value: `Failed to stringify: ${e}`,
        language: 'plaintext',
      }
    }
  }, [data, language])
}

function isJsonString(str: string) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}
