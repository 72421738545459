import { ITicketMessageContent } from '@copilot-dash/domain'
import { usePrivacyModeClass } from '../../../../components/Privacy/usePrivacyModeClass'
import { TranslationIndicator } from '../../../../components/Translation/TranslationIndicator'
import { useTranslatedMessage } from '../../../../components/Translation/useTranslatedMessage'

interface IProps {
  readonly content: ITicketMessageContent
}

export function TicketScreenVerbatimContent({ content }: IProps) {
  const translation = useTranslatedMessage(content)
  const privacyModeClass = usePrivacyModeClass()

  return (
    <span className={privacyModeClass}>
      <TranslationIndicator isTranslated={translation.textTranslated} />
      <span>{translation.text}</span>
    </span>
  )
}
