import { ReactNode, useEffect } from 'react'

import { useTabItemId } from './TabItemContext'
import { TabStore } from '../tab/TabStore'

interface IProps {
  readonly children?: ReactNode
}

export function TabItemToolbar({ children }: IProps): ReactNode {
  const id = useTabItemId()
  const actions = TabStore.useActions()

  useEffect(() => {
    actions.update({
      id,
      toolbar: children,
    })
  }, [actions, children, id])

  return null
}
