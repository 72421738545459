import { UserpSearch3sDebugContent } from './UserpSearch3sDebugContent'
import { TicketAsyncView } from '../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function UserpSearch3sDebug({ ticketId, messageId }: IProps) {
  const snapshot = app.store.use.getTicketUserpDebugData(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => <UserpSearch3sDebugContent ticketId={ticketId} data={data} />}
    </TicketAsyncView>
  )
}
