import { createContext, ReactNode, useContext } from 'react'
import { IOverlayItem } from './store/OverlayStoreState'

const OverlayItemContext = createContext<IOverlayItem | undefined>(undefined)

interface IProps {
  readonly item: IOverlayItem
}

export function OverlayItem({ item }: IProps): ReactNode {
  return (
    <OverlayItemContext.Provider value={item}>
      <div key={item.key}>{item.content(item.open, item.close)}</div>
    </OverlayItemContext.Provider>
  )
}

export function useOverlayItem(): IOverlayItem | undefined {
  return useContext(OverlayItemContext)
}
