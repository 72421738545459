import { z } from 'zod'

export const apiStateAndDurationKustoLogItemSchema = z.object({
  message: z.string(),
  scopeId: z.string(),
  scopeName: z.string(),
})

export const apiTraceKustoLogItemSchema = z.object({
  message: z.string(),
  eventName: z.string().optional(),
})

export const apiEventKustoLogItemSchema = z.object({
  tIMESTAMP: z.string(),
  message: z.string(),
  logLevel: z.string(),
  traceId: z.string(),
  correlationId: z.string(),
  scopeId: z.string(),
  scopeName: z.string(),
  eventName: z.string(),
  exception: z.string(),
  exceptionMessage: z.string(),
  exceptionType: z.string(),
  status: z.string(),
})

export const apiLlmKustoLogItemSchema = z.object({})

export const apiBizPerfLogItemSchema = z.object({
  chatFCFR: z.string().optional(),
  perfTraceFile: z.string().optional(),
})

export const apiBizChat3SLatencyLogItemSchema = z.object({
  perfTraceFile: z.string().optional(),
  env_time: z.string().optional(),
  transactionId: z.string().optional(),
})

export const apiGwsLogItemSchema = z.object({
  diagnosticData: z.string().optional(),
  responseMetaJson: z.string().optional(),
  transactionId: z.string().optional(),
  env_time: z.string().optional(),
  routeAction: z.string().optional(),
  requestedActions: z.string().optional(),
  httpStatusCode: z.string().optional(),
})

const apiGwsLogDiagnosticPluginSchema = z.object({
  PN: z.string().optional(),
  DC: z.union([z.string(), z.any()]).optional(),
})

export const apiAugLoopLogItemSchema = z.object({})

export const apiGwsLogItemDiagnosticDataSchema = z.object({
  PN: z.string().optional(),
  InnerD: z.array(apiGwsLogDiagnosticPluginSchema).optional(),
})
