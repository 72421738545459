import { ReactNode } from 'react'

export interface IState {
  readonly children: ReactNode
  readonly ticketId: string
  readonly isOpen: boolean
  readonly activeTab: TicketDiagnosticTab
  readonly activeSourceUrl?: string
}

export enum TicketDiagnosticTab {
  Home = 'Home',
  Metadata = 'Metadata',
  Table = 'Table',
  Blobs = 'Blobs',
}
