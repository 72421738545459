import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { useStyles } from './ColumnComponent.styles'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { ToolTipText } from '../Text/ToolTipText'

export const ColumnConfigTenantId: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.TenantId],
  field: String(ColumnKey.TenantId) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <ConfigTenantId tenantId={props.data.tenantId} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.TenantId]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 130,
  sortable: false,
  resizable: true,
}

const ConfigTenantId = ({ tenantId, keyWords }: { tenantId?: string; keyWords?: string[] }) => {
  const styles = useStyles()
  return (
    <TableCellLayoutWithStyles truncate>
      {tenantId ? (
        <ToolTipText
          text={tenantId}
          keywords={tenantId === keyWords?.[0] ? tenantId : undefined}
          isTwoLinesTruncate={true}
        />
      ) : (
        <Text className={styles.grayColor}>N/A</Text>
      )}
    </TableCellLayoutWithStyles>
  )
}
