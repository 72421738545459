import { ApiAISearchResponse, ApiSearchItem } from '@copilot-dash/api'
import {
  ColumnKey,
  INewTicketData,
  SearchTicketResult,
  TicketChatMode,
  TicketEmotionType,
  WorkItemStatus,
  getProductIdByEndpoint,
} from '@copilot-dash/domain'

export class AiTicketConverter {
  static readonly fromApi = (api: ApiAISearchResponse): SearchTicketResult => {
    const tickets: INewTicketData[] = api.searchItems.map((ticket: ApiSearchItem) => {
      return this.fromApiContext(ticket)
    })

    const hasMore = api.startIndex + api.stepNumber < api.count
    const { positive: satCount = 0, negative: dsatCount = 0 } = api.facetResults?.emotionType || {}

    return {
      tickets,
      hasMore,
      satCount,
      dsatCount,
      ticketCount: api.count,
      facetResults: api.facetResults,
    }
  }

  static readonly fromApiContext = (item: ApiSearchItem): INewTicketData => {
    const highlights = item.highlights || {}
    const verbatimWithHighlight = highlights['verbatim'] ? highlights['verbatim'] : undefined
    const utteranceWithHighlight = highlights['utterance'] ? highlights['utterance'] : undefined
    const responseWithHighlight = highlights['response'] ? highlights['response'] : undefined
    const searchQueryWithHighlight = highlights['searchQuery'] ? highlights['searchQuery'] : undefined
    const productId = getProductIdByEndpoint(item.clientName)

    return {
      ...item,
      ticketId: item.id,
      dateTimeUtc: item.createDateTime,
      thumbFeedback: item.emotionType,
      endpoint: item.clientName,
      emotionType: item.emotionType === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      productId: productId,
      verbatimWithHighlight: verbatimWithHighlight,
      utteranceWithHighlight: utteranceWithHighlight,
      responseWithHighlight: responseWithHighlight,
      screenshotCount: item.screenshotCount,
      searchQueryWithHighlight: searchQueryWithHighlight,
      chatMode: item.chatMode ? this.getChatMode(item.chatMode) : undefined,
      workItemStatus: item.workItemStatus as WorkItemStatus,
      ...this.fromApiWorkItem(item),
    }
  }

  static readonly fromApiWorkItem = (
    item: ApiSearchItem,
  ): Pick<INewTicketData, 'workItemId' | 'teamId' | 'rootCauseIds' | 'priority' | 'workItemStatus' | 'assignTo'> => {
    return {
      workItemId: item.workItemId,
      teamId: item.workItemTeamId,
      rootCauseIds: item.relatedWorkItemIds,
      priority: item.workItemPriority
        ? item.workItemPriority.startsWith('P') && item.workItemPriority[1]
          ? parseInt(item.workItemPriority[1])
          : undefined
        : undefined,
      workItemStatus: item.workItemStatus as WorkItemStatus,
      assignTo: item.workItemAssignTo,
    }
  }

  static readonly postApiOrder = (order: Array<string>): Array<string> => {
    const fieldMapping: Partial<{ [K in ColumnKey]: string }> = {
      Date: 'createDateTime',
      EmotionType: 'emotionType',
      Endpoint: 'clientName',
      Ring: 'ring',
      Email: 'emailAddress',
    }

    return order
      .map((order) => {
        const [field, direction] = order.split(' ')
        if (field) {
          const mappedField = fieldMapping[field as ColumnKey] || field
          return `${mappedField} ${direction}`
        }
        return undefined
      })
      .filter((order): order is string => order !== undefined)
  }

  private static getChatMode = (chatMode: string): INewTicketData['chatMode'] => {
    switch (chatMode) {
      case 'TextChat':
        return TicketChatMode.Text
      case 'VoiceChat':
        return TicketChatMode.Voice
      default:
        return undefined
    }
  }
}
