import { dayjs } from '@copilot-dash/core'

export function generateKustoTimeRangeExp(time: string, minutes: number): string | undefined {
  const timeObject = dayjs.utc(time)

  const start = timeObject.subtract(minutes, 'minute').toISOString()
  const end = timeObject.add(minutes, 'minute').toISOString()

  return `between (datetime("${start}") .. datetime("${end}"))`
}
