import { TelemetryScope } from '@copilot-dash/logger'
import { SymptomReportForCWCLayout } from './layout/SymptomReportForCWCLayout'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function SymptomReportForCWC({ ticketId, messageId }: IProps) {
  return (
    <TelemetryScope scope="SymptomReportViewCWC" properties={{ ticketId: ticketId }}>
      <SymptomReportForCWCLayout ticketId={ticketId} messageId={messageId} />
    </TelemetryScope>
  )
}
