import { INewTicketData } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useStyles } from './ColumnComponent.styles'
import { ErrorView } from '../Error'
import { usePrivacyModeClass } from '../Privacy/usePrivacyModeClass'
import { ToolTipText } from '../Text/ToolTipText'

export function ResponsiveCustomTagColumnComponent({ item }: { item: INewTicketData; selectedTags?: string[] }) {
  const privacyModeClass = usePrivacyModeClass()

  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const styles = useStyles()

  const tableCellContent = useMemo(() => {
    switch (inView) {
      case true:
        if (item.customTags) return <ToolTipText text={item.customTags.join('; ')} isTwoLinesTruncate={true} />
        if (item.ticketId) return <ResponseColumnComponent ticketId={item.ticketId} />
        else return <Text className={styles.grayColor}>N/A</Text>
      default:
        return null
    }
  }, [inView, item, styles.grayColor])

  return (
    <div ref={ref} className={privacyModeClass}>
      {tableCellContent}
    </div>
  )
}

//TODO: Refine Tag Column Render UX following design: https://www.figma.com/design/S0zghQcav4ZvKtQCVhWZrw/Copilot-Dash-UX-POR?node-id=10450-98832&t=j1l4TID3QwIc5vf1-4

function ResponseColumnComponent({ ticketId }: { ticketId: string }) {
  const styles = useStyles()
  const response = application.store.use.getTicketCustomTags(ticketId)
  const renderContent = () => {
    switch (response.status) {
      case 'waiting':
        return (
          <Skeleton appearance="translucent" className={styles.skeleton}>
            <SkeletonItem className={styles.skeletonItem1} />
            <SkeletonItem className={styles.skeletonItem2} />
          </Skeleton>
        )
      case 'done':
        return <ToolTipText text={response.data.join('; ')} isTwoLinesTruncate={true} />
      case 'error':
        return <ErrorView type="inline" error={response.error} />
    }
  }

  return <div>{renderContent()}</div>
}
