import { Body1Stronger, InfoLabel } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useStyles } from './Card.styles'
import { DateCardFilters } from './config'
import { TimeFilter } from '../../../SearchOptions/TimeFilter'
import { useTicketFilterStore } from '../../store/store'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}
export function DateCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const hasDateCard = useMemo(() => {
    return filterPanelItems.some((item) => DateCardFilters.includes(item))
  }, [filterPanelItems])
  if (!hasDateCard) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Date</Body1Stronger>
      <div className={styles.card}>
        <InfoLabel weight="semibold">Time range:</InfoLabel>
        {filterPanelItems?.includes('range') && (
          <Controller
            name="range"
            control={control}
            render={({ field }) => <TimeFilter onChanged={field.onChange} range={field.value} />}
          />
        )}
      </div>
    </section>
  )
}
