import { Column, Row } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text, mergeClasses } from '@fluentui/react-components'
import { xorBy } from 'lodash'
import { FC, useMemo } from 'react'
import { useStyles } from './StatusField.styles'
import { getWorkItemTitle } from '../utils'

interface IProps {
  readonly activity?: IActivityHistory
}

export const RootCauseFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  const AddedIssue = useMemo(() => {
    if (activity?.fieldDiffs?.IssueList) {
      const { oldValue, newValue } = activity.fieldDiffs.IssueList
      if (oldValue && newValue) {
        const addedIssues = xorBy(newValue, oldValue, 'IssueId').filter(
          (issue) => !oldValue.some((oldIssue) => oldIssue.IssueId === issue.IssueId),
        )
        return addedIssues
      } else if (newValue && newValue.length > 0) {
        return newValue
      }
    }
    return []
  }, [activity])

  const AddedRootCauseV2 = useMemo(() => {
    if (activity?.fieldDiffs?.WorkItemRelationList) {
      const { oldValue, newValue } = activity.fieldDiffs.WorkItemRelationList
      if (oldValue && newValue) {
        const addedIssues = xorBy(newValue, oldValue, 'WorkItemId').filter(
          (issue) => !oldValue.some((oldIssue) => oldIssue.WorkItemId === issue.WorkItemId),
        )
        return addedIssues
      } else if (newValue && newValue.length > 0) {
        return newValue
      }
    }
    return []
  }, [activity])

  const DeletedIssue = useMemo(() => {
    if (activity?.fieldDiffs?.IssueList) {
      const { oldValue, newValue } = activity.fieldDiffs.IssueList
      if (oldValue && newValue) {
        const deletedIssues = xorBy(newValue, oldValue, 'IssueId').filter(
          (issue) => !newValue.some((newIssue) => newIssue.IssueId === issue.IssueId),
        )
        return deletedIssues
      } else if (oldValue && oldValue.length > 0) {
        return oldValue
      }
    }
    return []
  }, [activity])

  const DeletedRootCauseV2 = useMemo(() => {
    if (activity?.fieldDiffs?.WorkItemRelationList) {
      const { oldValue, newValue } = activity.fieldDiffs.WorkItemRelationList
      if (oldValue && newValue) {
        const deletedIssues = xorBy(newValue, oldValue, 'WorkItemId').filter(
          (issue) => !newValue.some((newIssue) => newIssue.WorkItemId === issue.WorkItemId),
        )
        return deletedIssues
      } else if (oldValue && oldValue.length > 0) {
        return oldValue
      }
    }
    return []
  }, [activity])

  if (
    AddedIssue.length === 0 &&
    DeletedIssue.length === 0 &&
    AddedRootCauseV2.length === 0 &&
    DeletedRootCauseV2.length === 0
  )
    return null

  return (
    <Row vAlign="start">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Root Cause:</Text>
      </Column>
      <Column className={styles.rootCauseValue}>
        {AddedIssue.length > 0 && AddedRootCauseV2.length === 0 && (
          <>
            {AddedIssue.map((issue, idx) => (
              <Text key={idx} title={issue.RootCauseTitle} className={mergeClasses(styles.tag, styles.customTag)}>
                {issue.RootCauseTitle}
              </Text>
            ))}
          </>
        )}

        {AddedRootCauseV2.length > 0 && (
          <>
            {AddedRootCauseV2.map((issue, idx) => (
              <Text
                key={idx}
                title={getWorkItemTitle(issue.WorkItemId)}
                className={mergeClasses(styles.tag, styles.customTag)}
              >
                {getWorkItemTitle(issue.WorkItemId)}
              </Text>
            ))}
          </>
        )}

        {DeletedIssue.length > 0 && DeletedRootCauseV2.length === 0 && (
          <>
            {DeletedIssue.map((issue, idx) => (
              <Text
                key={idx}
                title={issue.RootCauseTitle}
                className={mergeClasses(styles.tag, styles.customTag, styles.deleted)}
              >
                {issue.RootCauseTitle}
              </Text>
            ))}
          </>
        )}

        {DeletedRootCauseV2.length > 0 && (
          <>
            {DeletedRootCauseV2.map((issue, idx) => (
              <Text
                key={idx}
                title={getWorkItemTitle(issue.WorkItemId)}
                className={mergeClasses(styles.tag, styles.customTag, styles.deleted)}
              >
                {getWorkItemTitle(issue.WorkItemId)}
              </Text>
            ))}
          </>
        )}
      </Column>
    </Row>
  )
}
