import { Column, Row, Spacer } from '@copilot-dash/components'
import { Text } from '@fluentui/react-components'
import { BoxSearchRegular } from '@fluentui/react-icons'
import { FC, memo } from 'react'

interface EmptyProps {
  title: string
  subTitle?: string
}

export const Empty: FC<EmptyProps> = memo((props) => {
  return (
    <Column fill hAlign="center">
      <Spacer height={20} />
      <Row>
        <BoxSearchRegular fontSize="40px" />
      </Row>
      <Text size={500} weight="semibold">
        {props.title}
      </Text>
      {props.subTitle && <Text>{props.subTitle}</Text>}
      <Spacer height={20} />
    </Column>
  )
})

Empty.displayName = 'Empty'
