import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { Body } from './body/Body'
import { Header } from './header/Header'
import { Column } from '../../layout'

interface IProps {
  readonly className?: string
  readonly children: ReactNode
}

export function Root({ className, children }: IProps) {
  const styles = useStyles()

  return (
    <Column className={mergeClasses(styles.root, className)} vAlign="stretch" hAlign="stretch">
      <Header />
      <Body>{children}</Body>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
})
