import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  createCommand,
  COMMAND_PRIORITY_EDITOR,
  $insertNodes,
  $isRootOrShadowRoot,
  $createParagraphNode,
} from 'lexical'
import * as React from 'react'
import { $createImageNode, ImageNode } from '../../editor-nodes/ImageNode'

export const INSERT_IMAGE_COMMAND = createCommand<string>()

export const ImagesPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on lexical editor')
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (src: string) => {
          const imageNode = $createImageNode(src, 'image')
          $insertNodes([imageNode])
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
          }

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor])

  return null
}
