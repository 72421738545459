import { ColumnKey, INewTicketData, SearchTextPrefixType, columnKeySchema } from '@copilot-dash/domain'

import { TelemetryScope } from '@copilot-dash/logger'
import { ColDef } from 'ag-grid-community'
import { compact } from 'lodash'
import { memo, useMemo } from 'react'
import { z } from 'zod'
import { AGGridTable } from '../../../../components/AGGridTable/AGGridTable'
import { ErrorView } from '../../../../components/Error'
import { AllSearchTableColumns } from '../../../../components/TicketTableColumnConfigs'
import { useFeedbackCopilotScreenActions, useFeedbackCopilotScreenState } from '../../store'

interface IProps {
  readonly tableItem: INewTicketData[]
  readonly selectedColumns: ColumnKey[]
  readonly keyPicker: (item: INewTicketData, index: number) => string
  readonly setColumns: (value: ColumnKey[]) => void
}

export const RagResultTableView = memo(({ tableItem, selectedColumns, keyPicker, setColumns }: IProps) => {
  const actions = useFeedbackCopilotScreenActions()
  const searchFeedbackResult = useFeedbackCopilotScreenState((state) => state.searchFeedbackResult)
  const searchSessionId = useFeedbackCopilotScreenState((state) => state.searchSessionId)

  const handleColumnsChange = (columns: Array<string>) => {
    const movedColumns = columns
      .map((column) => columnKeySchema.safeParse(column))
      .filter((result) => result.success)
      .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
    setColumns(movedColumns)
  }

  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    const defaultColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      selectedColumns.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )
    return newSelectedColumns
  }, [selectedColumns])

  const loadMoreFunction = (offset: number) => {
    actions.loadMore(offset)
  }

  const supportLoadMore = useMemo(() => {
    return searchFeedbackResult.hasMore
  }, [searchFeedbackResult])

  if (tableItem.length === 0) {
    return <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
  }

  return (
    <TelemetryScope
      scope="FeedbackCopilotScreenSessionView"
      properties={{ searchSessionId: searchSessionId ? searchSessionId : '' }}
    >
      <AGGridTable
        rowData={tableItem}
        getRowId={(data) => data.data.ticketId ?? ''}
        columnDefs={colDefs}
        loadMore={loadMoreFunction}
        onRowClicked={actions.rowClick}
        hasMore={supportLoadMore}
        handleColumnsChange={handleColumnsChange}
      />
    </TelemetryScope>
  )
})

RagResultTableView.displayName = 'RagResultTableView'
