import { PromiseSnapshot } from '@copilot-dash/core'
import { IMcpExperimentFeedbackResponse } from '@copilot-dash/domain'
import { Button } from '@fluentui/react-components'
import { FilterDropdown } from '../children/components/filterDropdown'
import FeedbackFilters from '../children/McpExperimentFeedbackFilters'
import { useStyles } from '../McpExperimentExperimentScreen.styles'

const TIME_WINDOW_DAY = '1d'
const TIME_WINDOW_HOUR = '1h'
const TIME_WINDOWS = [TIME_WINDOW_DAY, TIME_WINDOW_HOUR]

interface IProps {
  datePickerRange: Date[]
  flightName: string
  deployRings: string[]
  clientRings: string[]
  clientPlatforms: string[]
  setDatePickerRange: (dateRange: [Date, Date]) => void
  setFlightName: (flightName: string) => void
  setDeployRings: (deployRings: string[]) => void
  setClientRings: (clientRings: string[]) => void
  setClientPlatforms: (clientPlatforms: string[]) => void
  setPromise: (promise: Promise<IMcpExperimentFeedbackResponse>) => void
  snapshot: PromiseSnapshot<IMcpExperimentFeedbackResponse>
  getData: () => Promise<IMcpExperimentFeedbackResponse>
  chartTimeWindow: string
  setChartTimeWindow: (chartTimeWindow: string) => void
}

export const FilterLayout: React.FC<IProps> = ({
  datePickerRange,
  flightName,
  deployRings,
  clientRings,
  clientPlatforms,
  setDatePickerRange,
  setFlightName,
  setDeployRings,
  setClientRings,
  setClientPlatforms,
  setPromise,
  snapshot,
  getData,
  chartTimeWindow,
  setChartTimeWindow,
}) => {
  const styles = useStyles()

  return (
    <div className={styles.containerStyle}>
      {!!datePickerRange && (
        <FeedbackFilters
          datePickerRange={datePickerRange}
          flightName={flightName}
          deployRings={deployRings}
          clientRings={clientRings}
          clientPlatforms={clientPlatforms}
          setDatePickerRange={(dateRange) => {
            if (dateRange.length === 2) setDatePickerRange(dateRange as [Date, Date])
          }}
          setFlightName={setFlightName}
          setDeployRings={setDeployRings}
          setClientRings={setClientRings}
          setClientPlatforms={setClientPlatforms}
        />
      )}
      <Button
        onClick={() => {
          setPromise(getData())
        }}
        className={`${styles.buttonBase} ${snapshot.status === 'done' ? styles.buttonEnabled : styles.buttonDisabled}`}
        disabled={snapshot.status === 'waiting'}
      >
        Submit Filters
      </Button>
      <FilterDropdown
        label="Select Time Window"
        options={TIME_WINDOWS}
        currentFilter={chartTimeWindow}
        setFilter={setChartTimeWindow}
      />
    </div>
  )
}
