import { ApiV2Ticket } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { produce } from 'immer'
import { IDashStoreContext } from '../../IDashStoreContext'

const MESSAGE_ID_EMPTY = '(empty)'

export function getRawV2Ticket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ApiV2Ticket> {
  return context.getOrFetch<ApiV2Ticket>({
    get: (state) => {
      return state.tickets[ticketId]?.rawV2Ticket
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.rawV2Ticket = snapshot
    },
    fetch: async () => {
      const ticket = await context.api.copilotDash.getTicket(ticketId)

      // TODO: This is a temporary fix for the issue with empty message IDs
      return produce(ticket, (draft) => {
        draft.diagnosticContext?.chat?.forEach((item) => {
          if (item.messageId === '') {
            item.messageId = MESSAGE_ID_EMPTY
          }
        })

        draft.sources?.forEach((source) => {
          if (source.relatedId === '') {
            source.relatedId = MESSAGE_ID_EMPTY
          }
        })
      })
    },
  })
}
