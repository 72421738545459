import { Column, Row, Spacer } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text, mergeClasses } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { FC, useMemo } from 'react'
import { useStyles } from './StatusField.styles'

interface IProps {
  readonly activity?: IActivityHistory
}

export const TeamFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  const allTeamsSnapshot = application.store.use.getAllTeams()

  const newTeam = useMemo(() => {
    if (activity?.fieldDiffs?.TeamArea?.newValue) {
      return activity?.fieldDiffs?.TeamArea?.newValue
    }
    if (!isNil(activity?.fieldDiffs?.TeamId?.newValue) && allTeamsSnapshot.data) {
      const team = allTeamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.TeamId?.newValue)
      return team?.name
    }
    return undefined
  }, [activity, allTeamsSnapshot])

  const oldTeam = useMemo(() => {
    if (!isNil(activity?.fieldDiffs?.TeamArea?.oldValue)) {
      return activity?.fieldDiffs?.TeamArea?.oldValue
    }
    if (!isNil(activity?.fieldDiffs?.TeamId?.oldValue) && allTeamsSnapshot.data) {
      const team = allTeamsSnapshot.data.find((team) => team.id === activity?.fieldDiffs?.TeamId?.oldValue)
      return team?.name
    }
    return undefined
  }, [activity, allTeamsSnapshot])

  if (newTeam === oldTeam) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Team:</Text>
      </Column>
      {newTeam && (
        <Text className={styles.tag} title={newTeam}>
          {newTeam}
        </Text>
      )}
      <Spacer width="4px" />
      {oldTeam && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={oldTeam}>
          {oldTeam}
        </Text>
      )}
    </Row>
  )
}
