import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketChatCitation } from '@copilot-dash/domain'
import { compact } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversationGroup1 } from '../actions-raw-ticket-chat/getRawConversationGroup1'

export function getTicketTurnConversationCitations(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketChatCitation[]> {
  return context.getOrFetch<ITicketChatCitation[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.citations
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.citations = snapshot
    },
    fetch: async () => {
      return fetchFromGroup3()
    },
  })

  async function fetchFromGroup3(): Promise<ITicketChatCitation[]> {
    const group1 = await getRawConversationGroup1(context, ticketId, turnId).promise
    const message = group1.conversation?.messages?.find(
      (message) => message.sourceAttributions && message.sourceAttributions.length > 0,
    )

    const citations = message?.sourceAttributions?.map((item): ITicketChatCitation | undefined => {
      if (item.referenceMetadata && item.referenceMetadata.type && item.isCitedInResponse !== 'False') {
        return {
          type: item.referenceMetadata.type,
        }
      } else {
        return undefined
      }
    })

    return compact(citations)
  }
}
