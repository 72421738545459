import { Column } from '@copilot-dash/components'
import { IComment } from '@copilot-dash/domain'
import { DiscussionPanelEmpty } from '../children/DiscussionPanelEmpty'
import { UserMessage } from '../children/UserMessage'
import { DiscussionPanel } from '../DiscussionPanel'

export function DiscussionPanelBody() {
  const commentsSnapshot = DiscussionPanel.use((state) => state.commentsSnapshot)

  return (
    <Column>
      {commentsSnapshot.data?.length === 0 ? (
        <DiscussionPanelEmpty />
      ) : (
        commentsSnapshot.data?.map((comment: IComment) => (
          <UserMessage
            key={comment.id}
            id={comment.id}
            discussionId={comment.discussionId}
            userId={comment.userId}
            content={comment.content}
            createDateTime={comment.createDateTime}
            updateDateTime={comment.updateDateTime}
          />
        ))
      )}
    </Column>
  )
}
