import { Column } from '@copilot-dash/components'
import { ITicketDiagnosticData } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { OutlineGroupForDefault } from './OutlineGroupForDefault'
import { OutlineGroupForSource } from './OutlineGroupForSource'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'

interface IProps {
  readonly data: ITicketDiagnosticData
}

export function OutlineBody({ data }: IProps) {
  const styles = useStyles()

  return (
    <Scrollbar>
      <Column className={styles.root}>
        <OutlineGroupForDefault data={data} />
        {data.groups.map((group, index) => {
          return <OutlineGroupForSource key={index} data={group} />
        })}
      </Column>
    </Scrollbar>
  )
}

const useStyles = makeStyles({
  scrollbar: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  root: {
    overflowX: 'hidden',
  },
})
