import { z } from 'zod'

export interface ApiWorkItemRelation {
  workItemId: string
  relationType?: string
}

export interface ApiIssueInfo {
  issueId: string
  vsoAccount: string
  rootCauseTitle: string
}

export interface ApiRCRUserAction {
  workItemId: string
  userActionType: string
}

export interface ApiRootCauseActiveContext {
  adoIssueId?: string
  title?: string
  userAction?: string
  project?: string
  areapath?: string
  adoLink?: string
  teamName?: string
  displayTeamName?: string
}

export interface ApiField {
  state?: string
  priority?: number
  teamArea?: string
  teamId?: number
  assignTo?: string
  workItemRelationList?: ApiWorkItemRelation[]
  issueList?: ApiIssueInfo[]
  closedComment?: string
  customTags?: string[]
  rcrUserActions?: ApiRCRUserAction[]
  rootCauseActiveContextList?: ApiRootCauseActiveContext[]
  isUpdateAreaAndIssues?: boolean
  commentId?: string
  commentContent?: string
  eta?: string
  title?: string
  description?: string
  operateComment?: string
}

export interface ApiActivityHistory {
  activityId: string
  entityId: string
  timestamp: string
  actor: string
  activityType: string
  userId?: string
  system?: string
  field?: ApiField
}

export interface ApiActivityHistoryResponseV2 {
  activityHistories: ApiActivityHistory[]
  count: number
}

const apiWorkItemRelationSchema = z.object({
  workItemId: z.string(),
  relationType: z.string().optional(),
})

const apiIssueInfoSchema = z.object({
  issueId: z.string(),
  vsoAccount: z.string(),
  rootCauseTitle: z.string(),
})

export const apiRCRUserActionSchema = z.object({
  workItemId: z.string(),
  userActionType: z.string(),
})

export const apiRootCauseActiveContextSchema = z.object({
  adoIssueId: z.string().optional(),
  title: z.string().optional(),
  userAction: z.string().optional(),
  project: z.string().optional(),
  areapath: z.string().optional(),
  adoLink: z.string().optional(),
  teamName: z.string().optional(),
  displayTeamName: z.string().optional(),
})

export const apiFieldSchema = z.object({
  state: z.string().optional(),
  priority: z.number().optional(),
  teamArea: z.string().optional(),
  teamId: z.number().optional(),
  assignTo: z.string().optional(),
  workItemRelationList: z.array(apiWorkItemRelationSchema).optional(),
  issueList: z.array(apiIssueInfoSchema).optional(),
  closedComment: z.string().optional(),
  customTags: z.array(z.string()).optional(),
  rcrUserActions: z.array(apiRCRUserActionSchema).optional(),
  rootCauseActiveContextList: z.array(apiRootCauseActiveContextSchema).optional(),
  isUpdateAreaAndIssues: z.boolean().optional(),
  commentId: z.string().optional(),
  commentContent: z.string().optional(),
  eta: z.string().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  operateComment: z.string().optional(),
})

export const apiActivityHistorySchema = z.object({
  activityId: z.string(),
  entityId: z.string(),
  timestamp: z.string(),
  actor: z.string(),
  activityType: z.string(),
  userId: z.string().optional(),
  system: z.string().optional(),
  field: apiFieldSchema.optional(),
})

export const apiActivityHistoryResponseV2Schema = z.object({
  activityHistories: z.array(apiActivityHistorySchema),
  count: z.number(),
})
