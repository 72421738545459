import { useLayoutEffect, useState } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createFeedbackCopilotScreenActions } from '../../feedbackcopilot/store/createFeedbackCopilotScreenActions'
import { createFeedbackCopilotScreenState } from '../../feedbackcopilot/store/createFeedbackCopilotScreenState'
import { FeedbackCopilotScreenStoreContext } from '../../feedbackcopilot/store/FeedbackCopilotScreenStoreContext'
import { IFeedbackCopilotScreenState } from '../../feedbackcopilot/store/IFeedbackCopilotScreenStoreState'

interface IProps {
  readonly children: React.ReactNode
}

export function FeedbackCopilotScreenStoreProvider({ children }: IProps) {
  const [value] = useState(() => {
    const initialState = createFeedbackCopilotScreenState()
    const useBoundStore = create<IFeedbackCopilotScreenState>()(immer(() => initialState))
    const store = {
      get state() {
        return useBoundStore.getState()
      },
      use: useBoundStore,
      setState: useBoundStore.setState,
    }

    const actions = createFeedbackCopilotScreenActions(store)
    return { store, actions }
  })
  const hasHistory = app.store.use((state) => state.feedbackCopilot.allFCConversations?.data?.length ?? 0) > 0
  useLayoutEffect(() => {
    return value.store.use.subscribe((state, prevState) => {
      value.actions.onStateChanged(state, prevState, true)
    })
  }, [value])

  useLayoutEffect(() => {
    value.actions.onInit(hasHistory)
  }, [value, hasHistory])

  return (
    <FeedbackCopilotScreenStoreContext.Provider value={value}>{children}</FeedbackCopilotScreenStoreContext.Provider>
  )
}
