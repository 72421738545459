import { ApiV2TicketUserFeedback } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMetadata, TicketEmotionType } from '@copilot-dash/domain'

import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketMetadata(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketMetadata> {
  return context.getOrFetch<ITicketMetadata>({
    get: (state) => {
      return state.tickets[ticketId]?.metadata
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.metadata = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise

      return {
        id: raw.id,
        createdDateTime: raw.userFeedback.createdDateTime,
        emotionType: convertEmotionType(raw.userFeedback.emotionType ?? raw.userFeedback.sentiment),
        ocvLink: raw.userFeedback.ocvLink,
        tenantId: raw.tenant?.tenantId ?? raw.user?.tenantId,
        tenantName: undefined,
        screenshotCount: raw.userFeedback.screenshotCount,
      }
    },
  })

  function convertEmotionType(emotionType: ApiV2TicketUserFeedback['emotionType'] = 'Empty'): TicketEmotionType {
    switch (emotionType) {
      case 'Positive':
        return TicketEmotionType.Positive
      case 'Negative':
        return TicketEmotionType.Negative
      case 'Empty':
        return TicketEmotionType.Empty
    }
  }
}
