import { TeamId, Email } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getTeamDRIListByTeamId(context: IDashStoreContext, teamId: TeamId): Promise<Email[]> {
  const response = await context.api.copilotDash.getFeatureTeams(teamId)
  return response.driList
}

export async function setTeamDRIList(context: IDashStoreContext, teamId: TeamId, params: Email[]): Promise<void> {
  await context.api.copilotDash.patchFeatureTeams(teamId, {
    propertyList: [
      {
        propertyName: 'driList',
        value: params.join(','),
      },
    ],
  })
  return
}
