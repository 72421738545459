import { ApiAISearchResponse } from '@copilot-dash/api'
import { PromiseSnapshots } from '@copilot-dash/core'
import {
  ISearchTicketOptions,
  SearchTicketFacet,
  SearchTicketFacetPrefix,
  SearchTicketResult,
} from '@copilot-dash/domain'
import { EnableExceptionTracking } from '@copilot-dash/logger'
import { z } from 'zod'
import { AiSearchQueryBuilder } from './AiSearchQueryBuilder'
import { AiTicketConverter } from './converters/AiTicketConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export class SearchTicketIdByAISearchAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(
    options: ISearchTicketOptions,
    facets: Array<SearchTicketFacet> = [SearchTicketFacetPrefix.emotionType],
  ): Promise<SearchTicketResult> {
    const aiSearchQueryBuilder = new AiSearchQueryBuilder()
    const { searchQuery, filterQuery, searchAndHighlightField } = aiSearchQueryBuilder.buildQuery(options)

    const searchPrams = {
      query: searchQuery ? searchQuery : undefined,
    }
    const searchBody = {
      filter: filterQuery,
      skip: options.offset,
      size: options.count,
      orderBy:
        options.order && options.order.length > 0
          ? AiTicketConverter.postApiOrder(options.order)
          : ['createDateTime desc'],
      facets: facets,
      searchFields: searchAndHighlightField,
      highlightFields: searchAndHighlightField,
    }

    const response = await this.context.requestAnd<ApiAISearchResponse>({
      request: async () => {
        return await this.context.api.copilotDash.getAISearchTicket(searchPrams, searchBody)
      },
      onSuccess: (state, data) => {
        data.searchItems.forEach((item) => {
          const ticket = (state.tickets[item.id] ??= {})

          if (item.utterance) {
            ticket.utterance = PromiseSnapshots.done({
              language: item.utteranceLanguage,
              text: item.utterance,
              textInEnglish: item.utteranceInEnglish,
              markdown: undefined,
              markdownInEnglish: undefined,
              adaptiveCard: undefined,
            })
          }

          if (item.response) {
            ticket.response = PromiseSnapshots.done({
              language: item.responseLanguage,
              text: item.response,
              textInEnglish: item.responseInEnglish,
              markdown: undefined,
              markdownInEnglish: undefined,
              adaptiveCard: undefined,
            })
          }

          if (item.verbatim) {
            ticket.verbatim = PromiseSnapshots.done({
              language: item.verbatimLanguage,
              text: item.verbatim,
              textInEnglish: item.verbatimInEnglish,
              markdown: undefined,
              markdownInEnglish: undefined,
              adaptiveCard: undefined,
            })
          }

          if (item.emailAddress && z.string().email().safeParse(item.emailAddress).success) {
            ticket.email = PromiseSnapshots.done(item.emailAddress)
          }

          if (item.customTags) {
            ticket.customTags = PromiseSnapshots.done(item.customTags)
          }

          if (item.hasUserConsent !== undefined) {
            ticket.hasUserConsent = PromiseSnapshots.done(item.hasUserConsent)
          }
        })
      },
    })

    return AiTicketConverter.fromApi(response)
  }
}
