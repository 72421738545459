import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const ids = generateKustoStringArrayExp([...params.botRequestIds, ...params.correlationIds])
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
3SPerf_Sydney
| where AppScenario == 'sydney'
| where env_time ${timeRange}
| where ClientRequestId has_any (${ids})
| sort by env_time asc
`
}

export const BizChat3SLatency_EU: IKustoQueryDefinition = {
  database: 'Performance',
  connection: 'https://searchperfeu.westeurope.kusto.windows.net',
  query,
}
