import { Row } from '@copilot-dash/components'
import { I3sLogDataItem } from '@copilot-dash/domain'
import { Badge, makeStyles, Text, tokens } from '@fluentui/react-components'

interface IProps {
  readonly item: I3sLogDataItem
  readonly index: number
}

export function SssLogViewTreeTitle({ item, index }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root} gap="gap.small" vAlign="center">
      <Text weight="semibold">3S Transaction {index + 1}: </Text>

      {item.transactionLabels.map((label, index) => {
        return (
          <Badge key={index} appearance="filled" color="informative">
            {label}
          </Badge>
        )
      })}

      <Text wrap={false}>{item.transactionId}</Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    flexWrap: 'wrap',
    fontSize: '14px',
  },

  route: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    paddingInlineStart: '5px',
    paddingInlineEnd: '5px',
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase200,
    borderRadius: tokens.borderRadiusMedium,
  },

  routeDisabled: {
    cursor: 'default',
  },
})
