import { Row, Spacer } from '@copilot-dash/components'
import { IRootCauseRecommendation } from '@copilot-dash/domain'
import { Popover, PopoverSurface, PopoverTrigger, Text, tokens } from '@fluentui/react-components'
import { SparkleFilled } from '@fluentui/react-icons'
import { FC, memo } from 'react'
import { useStyles } from './TicketScreenRootCauseSuggestions.styles'
import { RootCauseSuggestionsV2 } from '../../../../components/TicketActionForm/components/RootCauseSuggestionsV2'

interface ITicketScreenRootCauseSuggestionsProps {
  recommendations: IRootCauseRecommendation[]
  onAccept: (rootCause: IRootCauseRecommendation) => void
  onReject: (rootCause: IRootCauseRecommendation) => void
}

export const TicketScreenRootCauseSuggestionsV2: FC<ITicketScreenRootCauseSuggestionsProps> = memo(
  ({ onAccept, onReject, recommendations }) => {
    const styles = useStyles()
    // const ticketId = TicketScreenStore.use((state) => state.ticketId)
    if (recommendations.length === 0) {
      return null
    }
    const firstRecommendation = recommendations[0]
    return (
      <Row>
        <Row>
          <SparkleFilled fontSize={20} color={tokens.colorCompoundBrandForeground1} />
          <Spacer width={12} />
          <Text size={400}>Recommendations:</Text>
          <Spacer width={6} />
          <Popover openOnHover withArrow positioning="below">
            <PopoverTrigger>
              <Text size={400} className={styles.blue}>
                {firstRecommendation?.title}
                {recommendations.length > 1 ? ` (+${recommendations.length - 1})` : ''}
              </Text>
            </PopoverTrigger>

            <PopoverSurface className={styles.popoverSurface} tabIndex={-1}>
              <RootCauseSuggestionsV2
                suggestions={recommendations}
                onAcceptSuggestion={onAccept}
                onRejectSuggestion={onReject}
                border={false}
              />
            </PopoverSurface>
          </Popover>
        </Row>
      </Row>
    )
  },
)

TicketScreenRootCauseSuggestionsV2.displayName = 'TicketScreenRootCauseSuggestionsV2'
