import { ApiClusterJobSummaryData } from './ApiClusterJobSummaryData'

export enum ApiClusterJobPublishState {
  SaveOnly = 'SaveOnly',
  PublishOnly = 'PublishOnly',
  SaveAndPublish = 'SaveAndPublish',
}

export interface ApiPublishClusterRequestResult {
  readonly clusteringRequestId: string
  readonly updatedIssueList: Array<ApiClusterJobSummaryData>
  readonly state?: ApiClusterJobPublishState
}
