import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const messageId = params.messageId
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
PerfCopilot
| where MessageId in ("${messageId}")
| where TIMESTAMP ${timeRange}
| sort by TIMESTAMP asc
`
}

export const BizchatPerformanceEventV4_WW: IKustoQueryDefinition = {
  database: 'Performance',
  connection: 'https://searchperfww.westus.kusto.windows.net',
  query,
}
