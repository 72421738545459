import { NullableOption, User } from '@copilot-dash/api'
import { Column, Row, Spacer } from '@copilot-dash/components'
import { AsyncSnapshot, useAsyncLoader } from '@copilot-dash/core'
import {
  Button,
  Divider,
  Field,
  Option,
  Persona,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Spinner,
  Tag,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import { AddRegular, ChatRegular, CheckmarkRegular, Dismiss16Filled, MailRegular } from '@fluentui/react-icons'
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce'
import { CopilotDashDropdownInput } from '../../Filter/CopilotDashDropdownInput'
import { Scrollbar } from '../../Scrollbar/Scrollbar'
import { UserDisplayName } from '../../User/UserDisplayName'
import { UserPhoto } from '../../User/UserPhoto'

export type EmailAddress = string | undefined

interface IAssignToProps {
  value: EmailAddress
  onChange: (value: EmailAddress) => void
  orientation?: 'horizontal' | 'vertical'
  defaultValueTexts?: string
  className?: string
  style?: React.CSSProperties
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const AssignTo: FC<IAssignToProps> = memo(
  ({ value, onChange, orientation = 'horizontal', className, style, fieldProps }) => {
    const styles = useStyles()
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
    const [input, setInput] = useState<string>('')
    const debouncedValue = useDebounce(input.trim(), 300)
    const debouncedInput = useDebounce(input.trim(), 300)
    const [submit, snapshot] = useAsyncLoader(
      useCallback((input: string): Promise<User[]> | null => {
        if (input.trim().length === 0) {
          return null
        }

        return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
      }, []),
    )
    const submitRef = useRef(submit)
    submitRef.current = submit

    useEffect(() => {
      submitRef.current(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
      submitRef.current(debouncedInput)
    }, [debouncedInput])

    const onOptionSelect = useCallback(
      (userId: string | undefined, userPrincipalName: NullableOption<string> | undefined) => {
        if (userId && userPrincipalName) {
          onChange?.(userPrincipalName)
        } else {
          onChange?.('')
        }
        setInput('')
        setIsPopoverOpen(false)
      },
      [onChange],
    )

    const renderContent = (snapshot: AsyncSnapshot<User[]>) => {
      switch (snapshot.status) {
        case 'none':
          return (
            <Option key="freeform" disabled>
              None
            </Option>
          )
        case 'waiting':
          return <Spinner />
        case 'done':
          if (snapshot.data.length === 0) {
            return (
              <Option key="freeform" disabled>
                No results found
              </Option>
            )
          }

          return snapshot.data.map((user) => {
            if (!user.userPrincipalName || !user.id) {
              return null
            }

            return (
              <Column key={user.id}>
                <Button
                  icon={value === user.userPrincipalName ? <CheckmarkRegular /> : <Spacer width="8px" />}
                  appearance="subtle"
                  onClick={() => onOptionSelect(user.id, user.userPrincipalName)}
                  className={styles.selectButton}
                  title={user.userPrincipalName}
                >
                  <Persona
                    avatar={{ color: 'colorful', 'aria-hidden': true }}
                    name={user.displayName ?? 'No display name'}
                    secondaryText={user.userPrincipalName}
                    size="small"
                  />
                </Button>
              </Column>
            )
          })
        case 'error':
          return (
            <Option key="freeform" disabled>
              {`${snapshot.error}`}
            </Option>
          )
      }
    }
    const targetFinalOptions = renderContent(snapshot)

    return (
      <Field label="Assign To:" className={className} orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '150px', overflow: 'hidden' }}>
          <Popover
            positioning={{
              align: 'start',
              pinned: false,
              position: 'below',
            }}
            onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
            open={isPopoverOpen}
          >
            <PopoverTrigger disableButtonEnhancement>
              {value ? (
                <Tag
                  dismissible
                  key={value}
                  media={<UserPhoto userId={value} className={styles.avatar} />}
                  value={value}
                  dismissIcon={{
                    as: 'span',
                    children: <Dismiss16Filled className={styles.dismissButton} onClick={(_) => onChange?.('')} />,
                  }}
                  className={styles.tag}
                >
                  <UserDisplayName
                    userId={value}
                    className={styles.displayName}
                    fallback={value}
                    isEnableToolTip={false}
                  />
                </Tag>
              ) : (
                <Button
                  appearance="subtle"
                  style={{ color: tokens.colorBrandForeground1, whiteSpace: 'nowrap' }}
                  icon={<AddRegular />}
                >
                  Add People
                </Button>
              )}
            </PopoverTrigger>
            <PopoverSurface style={{ maxWidth: '300px', padding: '0' }}>
              {value ? (
                <Column style={{ padding: '12px' }}>
                  <Row style={{ alignItems: 'center' }}>
                    <UserPhoto userId={value} className={styles.avatarDetail} />
                    <Spacer width={8} />
                    <UserDisplayName
                      userId={value}
                      className={styles.displayName}
                      fallback={value}
                      isEnableToolTip={false}
                    />
                  </Row>
                  <Divider style={{ margin: '8px 0' }} />
                  <Text className={styles.contact}>Contact</Text>
                  <Row className={styles.contactItem}>
                    <MailRegular className={styles.icon} />
                    <Spacer width={8} />
                    <a href={'mailto:' + value} className={styles.text}>
                      {value}
                    </a>
                  </Row>
                  <Row className={styles.contactItem}>
                    <ChatRegular className={styles.icon} />
                    <Spacer width={8} />
                    <a href={'sip:' + value} className={styles.text}>
                      {value}
                    </a>
                  </Row>
                </Column>
              ) : (
                <Column>
                  <CopilotDashDropdownInput inputValue={input} setInputValue={setInput} />
                  <Scrollbar className={styles.scrollbar}>{targetFinalOptions}</Scrollbar>
                </Column>
              )}
            </PopoverSurface>
          </Popover>
        </div>
      </Field>
    )
  },
)

AssignTo.displayName = 'AssignTo'

const useStyles = makeStyles({
  scrollbar: {
    maxHeight: '300px',
    padding: '6px',
  },
  selectButton: {
    textAlign: 'left',
    justifyContent: 'left',
    fontWeight: tokens.fontWeightRegular,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    },
  },
  selectCloseButton: {
    padding: 0,
    width: '20px',
    height: '20px',
  },
  tag: {
    paddingLeft: '8px',
    '& .fui-Tag__media': {
      padding: '0 6px 0 0',
    },
    '& .fui-Tag__primaryText': {
      overflow: 'hidden',
    },
  },
  displayName: {
    fontSize: tokens.fontSizeBase300,
    maxWidth: '120px',
  },
  avatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
  avatarDetail: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
  },
  interactionTag: {
    '& .fui-InteractionTagSecondary': {
      ...shorthands.border(0),
    },
  },
  dismissButton: {
    color: tokens.colorNeutralForeground3,
    height: tokens.lineHeightBase100,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
  contact: {
    margin: '8px 6px',
  },
  contactItem: {
    height: '32px',
    alignItems: 'center',
    padding: '0 6px',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  text: {
    color: tokens.colorBrandForeground1,
    textDecoration: 'none',
  },
})
