import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getTicketSystemTags } from '../actions-ticket/getTicketSystemTags'

export async function assertTicket3sTriggered(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): Promise<void> {
  const lastMessageId = await getLastMessageId()
  if (messageId === lastMessageId) {
    const tag = await getTicketSystemTags(context, ticketId).promise
    if (!tag.sssTriggered) {
      throw TicketError.create('No3SDueToNotTriggered', { ticketId, messageId })
    }
  }

  async function getLastMessageId(): Promise<string | undefined> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    return raw.diagnosticContext?.chat?.[0]?.messageId
  }
}
