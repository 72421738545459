import { Row } from '@copilot-dash/components'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { MainBackground } from './MainBackground'
import { MainBadge } from './MainBadge'
import { MainForeground } from './MainForeground'

export function Main() {
  const styles = useStyles()

  return (
    <Row className={mergeClasses(styles.root)}>
      <MainBackground />
      <MainForeground />
      <MainBadge />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow16,
    zIndex: 1,
  },
})
