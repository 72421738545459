import { FeedbackId, IRootCauseRecommendation } from '@copilot-dash/domain'
import { convertRCRFromAPItoDomain } from './converters/convertRCRFromAPItoDomain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getRootCauseRecommendationByFeedbackId(
  context: IDashStoreContext,
  feedbackId: FeedbackId,
): Promise<IRootCauseRecommendation[]> {
  const response = await context.api.copilotDash.getRootCauseRecommendations(feedbackId)
  return response.map(convertRCRFromAPItoDomain)
}
