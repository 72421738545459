import { AnyTableStore } from './AnyTableStore'
import { AnyTableColDef } from './AnyTableTypes'
import { Root } from './children/Root'

interface IProps<T> {
  readonly data: T[]
  readonly filter?: string
  readonly columns?: AnyTableColDef<T>[]
}

export function AnyTable<T = unknown>({ data, filter, columns = [] }: IProps<T>) {
  return (
    <AnyTableStore data={data} filter={filter} columns={columns}>
      <Root />
    </AnyTableStore>
  )
}
