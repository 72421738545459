import { Column } from '@copilot-dash/components'
import { FC } from 'react'
import { AssignToFieldChangeRow } from './AssignToFieldChangeRow'
import { EtaFieldChangeRow } from './EtaFieldChangeRow'
import { OperateCommentFieldChangeRow } from './OperateCommentFieldChangeRow'
import { PriorityFieldChangeRow } from './PriorityFieldChangeRow'
import { RootCauseDescriptionRow } from './RootCauseDescriptionRow'
import { RootCauseFieldChangeRow } from './RootCauseFieldChangeRow'
import { StateFieldChangeRow } from './StateFieldChangeRow'
import { TeamFieldChangeRow } from './TeamFieldChangeRow'
import { TitleFieldChangeRow } from './TitleFieldChangeRow'
import { useActivityHistoryPanelStore } from '../../store/store'

export const ActivityUpdateStatus: FC = () => {
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Column fill>
      <TitleFieldChangeRow activity={selectedActivity} />
      <StateFieldChangeRow activity={selectedActivity} />
      <PriorityFieldChangeRow activity={selectedActivity} />
      <TeamFieldChangeRow activity={selectedActivity} />
      <AssignToFieldChangeRow activity={selectedActivity} />
      <RootCauseFieldChangeRow activity={selectedActivity} />
      <EtaFieldChangeRow activity={selectedActivity} />
      <RootCauseDescriptionRow activity={selectedActivity} />
      <OperateCommentFieldChangeRow activity={selectedActivity} />
    </Column>
  )
}
