import { z } from 'zod'

export interface ApiBatchRootCausesCustomTagsResponse {
  readonly id: string
  readonly customTags: Array<string>
  readonly workItemType: string
  readonly workItemEntityId: string
}

export const apiBatchRootCausesCustomTagsResponseSchema = z.object({
  id: z.string(),
  customTags: z.array(z.string()),
  workItemType: z.string(),
  workItemEntityId: z.string(),
})
