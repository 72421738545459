import { Column } from '@copilot-dash/components'
import { mergeClasses } from '@fluentui/react-components'
import { SettingsScreenOutlineBody } from './SettingsScreenOutlineBody'
import { SettingsScreenOutlineHeader } from './SettingsScreenOutlineHeader'

interface IProps {
  readonly className?: string
}

export function SettingsScreenOutline({ className }: IProps) {
  return (
    <Column className={mergeClasses('SettingsScreenOutline', className)}>
      <SettingsScreenOutlineHeader />
      <SettingsScreenOutlineBody />
    </Column>
  )
}
