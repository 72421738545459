import { z } from 'zod'

export const apiV2TicketUserSchema = z.object({
  objectId: z.string().optional(),
  puid: z.string().optional(),
  email: z.string().optional(),
  emailAddress: z.string().optional(),
  tenantId: z.string().optional(),
  locale: z.string().optional(),
  isEU: z.boolean().optional(),
  authenticationType: z.string().optional(),
})

export const apiV2TicketTenantSchema = z.object({
  tenantId: z.string(),
  tenantName: z.string().optional(),
})

export const apiV2TicketUserFeedbackVerbatimSchema = z.object({
  text: z.string().optional(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
})

export const apiV2TicketEnvironmentSchema = z.object({
  clientName: z.string(),
  scenarioName: z.union([
    z.literal('CopilotChatFeedback'),
    z.literal('CopilotGlobalFeedback'),
    z.literal('CopilotCatchUpFeedback'),
    z.literal('CopilotAssistantFeedback'),
    z.literal('tenantsfeedbackservice'),
    z.string(),
  ]),
  ring: z.string(),
  channel: z.string().optional(),
  clientVersion: z.string().optional(),
  adminPortal: z
    .object({
      selectedProduct: z.string(),
    })
    .optional(),
})

export const apiV2TicketChatUtteranceSchema = z.object({
  text: z.string(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
  sydneyText: z.string().optional(),
})

export const apiV2TicketChatResponseSchema = z.object({
  text: z.string(),
  locale: z.string().optional(),
  language: z.string().optional(),
  textInEnglish: z.string().optional(),
  sydneyText: z.string().optional(),
})

export const apiV2TicketChatSlicerSchema = z.object({
  invocations: z.array(z.string()).optional(),
  utteranceGroup: z.string().optional(),
  scenario: z.string().optional(),
  builderName: z.string().optional(),
  capabilities: z.array(z.string()).optional(),
  usageIntensityCohort: z.string().optional(),
  engagementCohort: z.string().optional(),
  languageTier: z.string().optional(),
  locale: z.string().optional(),
})

export const apiV2TicketChatSubstrateSearchSchema = z.object({
  transactionIds: z.array(z.string()).optional(),
})

export const apiV2TicketSearchSchema = z.object({
  query: z.string().optional(),
  transactionIds: z.array(z.string()).optional(),
})

export const apiV2TicketSourceSchema = z.object({
  relatedId: z.string().optional(),
  relatedType: z.string().optional(),
  type: z.string(),
  complianceLevel: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3), z.literal(4)]),
  name: z.string().optional(),
  url: z.string(),
})

export const apiV2TicketUserFeedbackSchema = z.object({
  createdDateTime: z.string(),
  emotionType: z.union([z.literal('Positive'), z.literal('Negative'), z.literal('Empty')]).optional(),
  sentiment: z.union([z.literal('Positive'), z.literal('Negative'), z.literal('Empty')]).optional(),
  hasUserConsent: z.boolean(),
  verbatim: apiV2TicketUserFeedbackVerbatimSchema,
  screenshotCount: z.number(),
  ocvLink: z.string().optional(),
})

export const apiV2TicketChatSchema = z.object({
  index: z.number(),
  messageId: z.string(),
  createdDateTime: z.string().optional(),
  chatMode: z.union([z.literal('TextChat'), z.literal('VoiceChat')]),
  invocationSlicers: z.string().optional(),
  utterance: apiV2TicketChatUtteranceSchema.optional(),
  response: apiV2TicketChatResponseSchema.optional(),
  slicer: apiV2TicketChatSlicerSchema.optional(),
  substrateSearch: apiV2TicketChatSubstrateSearchSchema.optional(),
})

export const apiV2TicketDiagnosticContextSchema = z.object({
  sydneyEndpoint: z.string(),
  conversationId: z.string().optional(),
  correlationId: z.string().optional(),
  clientSessionId: z.string().optional(),
  tags: z.array(z.string()).optional(),
  chat: z.array(apiV2TicketChatSchema).optional(),
  search: apiV2TicketSearchSchema.optional(),
})

export const apiV2TicketSchema = z.object({
  id: z.string(),
  user: apiV2TicketUserSchema,
  tenant: apiV2TicketTenantSchema.optional(),
  userFeedback: apiV2TicketUserFeedbackSchema,
  environment: apiV2TicketEnvironmentSchema.optional(),
  diagnosticContext: apiV2TicketDiagnosticContextSchema.optional(),
  sources: z.array(apiV2TicketSourceSchema).optional(),
  tags: z.array(z.string()).optional(),
  version: z.number().optional(),
})
