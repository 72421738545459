import { ISearchTicketOptions, SearchTicketFacet, SearchTicketResult } from '@copilot-dash/domain'
import { SearchTicketAction } from './SearchTicketAction'
import { IDashStoreContext } from '../../IDashStoreContext'

export function searchTickets(
  context: IDashStoreContext,
  options: ISearchTicketOptions,
  facets?: Array<SearchTicketFacet>,
): Promise<SearchTicketResult> {
  return new SearchTicketAction(context).search(options, facets)
}
