import { getProductNameById } from '@copilot-dash/domain'
import { TicketRoute } from './TicketRoute'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { TicketScreen } from '../../../screens/ticket/TicketScreen'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'

export function TicketRoutePage() {
  useClaritySectionId(ClarityScenarioIds.ticketDetailPage)
  const args = TicketRoute.navigator.useArgs()
  const title = `Ticket - ${args.id}`

  const productId = app.store.use.getTicketEnvironment(args.id)?.data?.productId
  const productName = getProductNameById(productId)

  return (
    <GlobalProductSelector value={productName} onChange={() => {}} disabled>
      <PageTitle title={title}>
        <TicketScreen ticketId={args.id} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
