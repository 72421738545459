import { z } from 'zod'

export interface ApiV2RootCauseRecommendation {
  readonly workItemId: string
  readonly title: string
  readonly userAction: string
  readonly teamId: number
  readonly rank: number
  readonly isRcrEnabled: boolean
}
export type ApiV2RootCauseRecommendationsResponse = Array<ApiV2RootCauseRecommendation>

export const apiV2RootCauseRecommendationSchema = z.object({
  workItemId: z.string(),
  title: z.string(),
  userAction: z.string(),
  teamId: z.number(),
  rank: z.number(),
  isRcrEnabled: z.boolean(),
})

export const apiV2RootCauseRecommendationsSchema = z.array(apiV2RootCauseRecommendationSchema)
