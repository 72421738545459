import { Column, Row } from '@copilot-dash/components'
import { getProductIdByName, ProductIds, ProductName, ProductNames } from '@copilot-dash/domain'
import { Tab, TabList } from '@fluentui/react-components'
import { memo, useEffect, useState } from 'react'
import { FeedbackInsights } from './FeedbackInsights'
import { SearchRoute } from '../../../../router'
import { ClarityScenarioIds, useClaritySectionId } from '../../../../utils/useClarityPageId'

const TABS = {
  mainline: { value: 0, displayText: 'Mainline Copilot' },
  agents: { value: 1, displayText: 'Agents' },
}

export const FeedbackInsightsLayout = memo(() => {
  useClaritySectionId(ClarityScenarioIds.overviewInsights)
  const productName = SearchRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const hasAgentData = useHasAgentData(productName)
  const [selectedTab, setSelectedTab] = useState(TABS.mainline.value)

  if (!productId) {
    return null
  }

  return (
    <Column>
      <Row style={{ padding: '0px 14px' }}>
        <TabList size="small" selectedValue={selectedTab} onTabSelect={(_, data) => setSelectedTab(Number(data.value))}>
          <Tab value={TABS.mainline.value}>{TABS.mainline.displayText}</Tab>
          {hasAgentData && <Tab value={TABS.agents.value}>{TABS.agents.displayText}</Tab>}
        </TabList>
      </Row>
      {selectedTab === TABS.mainline.value && <FeedbackInsights productId={productId} key="mainline" />}
      {selectedTab === TABS.agents.value && hasAgentData && (
        <FeedbackInsights productId={ProductIds.M365Agent} key="agents" />
      )}
    </Column>
  )
})

FeedbackInsightsLayout.displayName = 'FeedbackInsightsLayout'

function useHasAgentData(productName: ProductName | undefined) {
  const agentBatchList = application.store.use((state) => state.topIssue.batchList?.[ProductIds.M365Agent])
  useEffect(() => {
    application.store.actions.getOrFetchTeamIssueClusteringBatchInfoList(ProductIds.M365Agent)
  }, [])
  if (productName && productName !== ProductNames.M365Chat) return false
  return agentBatchList && agentBatchList.status === 'done' && agentBatchList.data.length > 0
}
