import { IPublicClientApplication } from '@azure/msal-browser'
import { parseJwt } from '@copilot-dash/api'
import { Logger } from '@copilot-dash/logger'
import { z } from 'zod'
import { acquireToken } from './acquireToken'

export async function acquireTokenRoles(
  client: IPublicClientApplication,
  scopes: Array<string>,
): Promise<Array<string>> {
  try {
    const token = await acquireToken(client, scopes)
    const tokenObject = parseJwt(token)

    const roles = tokenObject.payload['roles']
    return z.array(z.string()).safeParse(roles).data ?? []
  } catch (e) {
    Logger.trace.warn('Failed to acquire token roles. Cause: ' + e)
    return []
  }
}
