import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoGwsLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getRawUserpGroup1 } from '../actions-raw-ticket-userp/getRawUserpGroup1'
import { assertTicketApplicableForUserpSearch } from '../actions-ticket-assert/assertTicketApplicableForUserpSearch'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'
import { convertTo3sGwsLogsByV2Items } from '../actions-ticket-kusto/converter/convertTo3sGwsLogs'

export function getTicketSearchKusto(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoGwsLogItem[]> {
  return context.getOrFetch<IKustoGwsLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.userpGwsLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.userpGwsLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)
      let items: IKustoGwsLogItem[] = []

      // 1. Fetch
      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.substrateSearchLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asSubstrateSearchLog()
        items = convertTo3sGwsLogsByV2Items(results)
      }

      // 2. Filter
      const transactionIds = await fetchTransactionId().catch((): string[] => [])
      items = items.filter((item) => transactionIds.includes(item.transactionId ?? ''))
      if (items.length) {
        return items
      }

      await assertTicketApplicableForUserpSearch(context, ticketId, 'source')
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.GwsLog, sourceItem })
    },
  })

  async function fetchTransactionId(): Promise<string[]> {
    // 1. From ticket metadata
    const ticket = await getRawV2Ticket(context, ticketId).promise
    let transactionIds = ticket.diagnosticContext?.search?.transactionIds ?? []
    if (transactionIds.length > 0) {
      return transactionIds
    }

    // 2. From USERP group1 file
    const group1 = await getRawUserpGroup1(context, ticketId, messageId).promise
    transactionIds = compact(group1.map((item) => item.ReplayResponse?.TraceId))
    if (transactionIds.length > 0) {
      return transactionIds
    }

    return []
  }
}
