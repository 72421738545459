import { Column } from '@copilot-dash/components'
import { useStyles } from './ActivityHistoryDetails.styles'
import { ActivityComment } from './StatusChange/ActivityComment'
import { ActivityConfirmRootCause } from './StatusChange/ActivityConfirmRootCause'
import { ActivityUpdateCustomTags } from './StatusChange/ActivityUpdateCustomTags'
import { ActivityUpdateStatus } from './StatusChange/ActivityUpdateStatus'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { useActivityHistoryPanelStore } from '../store/store'

export function ActivityHistoryDetails() {
  const styles = useStyles()
  const scrollbarRef = useScrollbar()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const renderActivityContent = () => {
    if (!selectedActivity?.activity) return null

    switch (selectedActivity.activity) {
      case 'AddComment':
      case 'UpdateComment':
      case 'DeleteComment':
        return <ActivityComment />

      case 'TicketStatusUpdate':
      case 'WorkItemCreated':
      case 'WorkItemDetailsUpdated':
      case 'WorkItemAutoTriage':
      case 'RootCauseCreated':
      case 'RootCauseDetailsUpdated':
        return <ActivityUpdateStatus />

      case 'SetTicketRootCausingActions':
      case 'UpdateRootCauseRecommendation':
        return <ActivityConfirmRootCause />

      case 'UpdateCustomTags':
        return <ActivityUpdateCustomTags />

      default:
        return null
    }
  }

  return (
    <Column ref={scrollbarRef} className={styles.container}>
      {renderActivityContent()}
    </Column>
  )
}
