import { ISearchTicketOptions, SearchTicketResult } from '@copilot-dash/domain'

import { EnableExceptionTracking } from '@copilot-dash/logger'
import { BaseTicketSearchAction } from './BaseTicketSearchAction'
import { NewTicketConverter } from './converters/NewTicketConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

interface IParams {
  readonly searchId: string
  readonly options: ISearchTicketOptions
}

export class SearchTicketWithIdListAction extends BaseTicketSearchAction {
  constructor(context: IDashStoreContext) {
    super(context)
  }

  @EnableExceptionTracking()
  async search(params: IParams): Promise<SearchTicketResult> {
    const request = this.createRequest({
      options: params.options,
      searchId: params.searchId,
    })

    const response = await this.context.api.logCollector.newSearchCopilotTickets(request)

    return {
      searchId: params.searchId,
      ticketCount: response.ticketCount ?? response.tickets.length,
      tickets: response.tickets.map(NewTicketConverter.fromApiContext),
      hasMore: response.hasMore ?? false,
      errorMessage: response.errorMessage,
      satCount: response.sATCount,
      dsatCount: response.dSATCount,
    }
  }
}
