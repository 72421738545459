import { z } from 'zod'

export interface ApiV2batchUpdateRootCauseRecommendationResponse {
  readonly updateSuccessedTicketIdList: string[]
  readonly updateFailedTicketIdList: string[]
}

export const apiV2batchUpdateRootCauseRecommendationResponseSchema = z.object({
  updateSuccessedTicketIdList: z.array(z.string()),
  updateFailedTicketIdList: z.array(z.string()),
})
