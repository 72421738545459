import { ITicketMessageContent } from '@copilot-dash/domain'
import { useMemo } from 'react'

interface IResult {
  readonly text: string
  readonly textTranslated: boolean

  readonly markdown: string | undefined
  readonly markdownTranslated: boolean
}

export function useTranslatedMessage(content: ITicketMessageContent): IResult {
  const translation = app.settings.translateContentToEnglish.use()

  return useMemo((): IResult => {
    const contentLanguage = content.language

    const text = content.text
    const textInEnglish = content.textInEnglish
    const textTranslated =
      translation && !!textInEnglish && contentLanguage !== 'English' && text?.trim() !== textInEnglish?.trim()

    const markdown = content.markdown
    const markdownInEnglish = content.markdownInEnglish
    const markdownTranslated = translation && !!markdownInEnglish && contentLanguage !== 'English'

    return {
      text: textTranslated ? textInEnglish : text,
      textTranslated,
      markdown: markdownTranslated ? markdownInEnglish : markdown,
      markdownTranslated,
    }
  }, [translation, content])
}
