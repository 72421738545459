import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'

// use WeakMap to store the memorized results, this is to avoid re-render in the UI and also to avoid fetching the same data multiple times
const memorizedResults: WeakMap<
  Array<string | number | symbol>,
  { status: 'waiting' | 'done' | 'error'; data: PromiseSnapshot<unknown[]> }
> = new WeakMap()

export function getListByIds<T extends string | number | symbol, U>(
  ids: T[],
  map: Record<T, PromiseSnapshot<U>>,
): PromiseSnapshot<U[]> {
  const results = ids.map((id) => map[id])
  const newStatus = results.some((result) => result?.status === 'error')
    ? 'error'
    : results.some((result) => result?.status === 'waiting')
      ? 'waiting'
      : results.every((result) => result?.status === 'done')
        ? 'done'
        : null

  if (newStatus === null) {
    throw new Error('[DashPortal]Unexpected status, some of the results are not done, waiting or error')
  }

  // check if can return the memorized data
  if (memorizedResults.has(ids) && memorizedResults.get(ids)!.status === newStatus) {
    if (newStatus === 'done') {
      // double check if the data is the same
      const newData = results.map((result) => result.data!)
      const oldData = memorizedResults.get(ids)!.data.data!
      if (newData.length === oldData.length && newData.every((data, i) => data === oldData[i])) {
        return memorizedResults.get(ids)!.data as PromiseSnapshot<U[]>
      }
    } else {
      return memorizedResults.get(ids)!.data as PromiseSnapshot<U[]>
    }
  }

  // return the new data
  let ret: PromiseSnapshot<U[]> = { status: 'done', promise: Promise.resolve([]), data: [] }
  switch (newStatus) {
    case 'done':
      ret = PromiseSnapshots.done(results.map((result) => result.data!))
      break
    case 'error':
      ret = PromiseSnapshots.error(results.find((result) => result.status === 'error')!.error)
      break
    case 'waiting':
      ret = PromiseSnapshots.waiting(Promise.all(results.map((result) => result.promise)))
      break
    default:
      break
  }

  // update the memorized data
  memorizedResults.set(ids, { status: newStatus, data: ret })

  return ret
}
