function translateRootCausePriority(priority: string): number
function translateRootCausePriority(priority: number): string
function translateRootCausePriority(priority: string | number): string | number {
  if (typeof priority === 'string') {
    if (priority.startsWith('P') && priority[1]) {
      return parseInt(priority[1])
    } else {
      throw new Error('Invalid priority string')
    }
  } else {
    return `P${priority}`
  }
}

export { translateRootCausePriority }
