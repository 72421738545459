import { AnyExplainers } from '@copilot-dash/components'
import { explainEmail } from './explainers/explainEmail'
import { explainTicketClient } from './explainers/explainTicketClient'
import { explainTicketScenario } from './explainers/explainTicketScenario'

export async function initExplainer(): Promise<void> {
  AnyExplainers.register(explainTicketClient)
  AnyExplainers.register(explainTicketScenario)
  AnyExplainers.register(explainEmail)

  AnyExplainers.registerTimezoneHook(() => app.settings.timezone.use())
}
