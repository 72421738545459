import { ApiStateAndDurationKustoLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoStateDurationLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getStateDurationLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoStateDurationLogItem[]> {
  return context.getOrFetch<IKustoStateDurationLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.stateAndDurationLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.stateAndDurationLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.stateDurationLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asStateDurationLog()
        return results.map(convertItem)
      }

      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChat3SLatencyLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.StateDurationLog, sourceItem })
    },
  })

  function convertItem(raw: ApiStateAndDurationKustoLogItemV2): IKustoStateDurationLogItem {
    return {
      scopeName: raw.ScopeName,
      message: raw.Message,
      raw,
    }
  }
}
