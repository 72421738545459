import { ApiExtensibilityLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IExtensibilityLogItem, IKustoStateDurationLogItem, IKustoTraceLogItem } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { getStateDurationLog } from './getStateDurationLog'
import { getTraceLogs } from './getTraceLogs'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

const ImportantScopeNamePrefixes = [
  'SubstrateSearchServiceUserConfig',
  'MetaOsGptProvider.GetGptAsync',
  'TuringBot.GptConfigRetriever',
  'EntityServeService',
  'SkillDiscoveryServicePluginSource',
  'ExtensionRunner:ext:aiplugin-openapi-loader-',
  'ConfirmationHandler.SendConfirmationDialog',
  'PluginRequestAuthHandler',
  'OpenApiContainer.RunFunction/',
  'ExtensionRunner:ext:OpenAPI-spec.OpenAPI-spec//',
  'CustomEngineCopilotMcsClient',
  'Plugin_ResultsReturned',
]

const ImportantEventNames = [
  'GptEnabledToolsChecker',
  'SkillDiscoveryPluginSource',
  'PluginExecution',
  'DeveloperInfoEvent',
  'ReferencesAdded',
]

export function getExtensibilityLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IExtensibilityLogItem[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.extensibilityLog
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.extensibilityLog = snapshot
    },
    fetch: async () => {
      return await doFetch()
    },
  })

  async function doFetch(): Promise<IExtensibilityLogItem[]> {
    const source = await getTicketSource(context, ticketId).promise
    const sourceItem = source.turns[messageId]?.extensibility
    if (!sourceItem?.url) {
      throw TicketError.create('NoExtensibilityLog', { ticketId })
    }

    const logs = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asExtensibility()
    const validLogs = logs.filter((log) => log.CopilotExtensionIds)
    if (validLogs.length === 0) {
      throw TicketError.create('NoExtensibilityLog', { ticketId, sourceItem })
    }

    return await Promise.all(validLogs.map(convertItemV2))
  }

  async function convertItemV2(item: ApiExtensibilityLogItemV2): Promise<IExtensibilityLogItem> {
    const stateDurationLogs = await getStateDurationLog(context, ticketId, messageId).promise.catch(() => [])
    const traceLogs = await getTraceLogs(context, ticketId, messageId).promise.catch(() => [])

    return {
      correlationId: item.CorrelationId,
      conversationId: item.ConversationId,
      botConversationId: item.BotConversationId,
      botRequestId: item.BotRequestId,
      copilotExtensionIds: item.CopilotExtensionIds,
      messageId: item.MessageId,
      importantScopes: getImportantScopes(stateDurationLogs),
      importantEvents: getImportantEvents(traceLogs),
    }
  }

  function getImportantScopes(logs: IKustoStateDurationLogItem[]): Record<string, IKustoStateDurationLogItem[]> {
    const result: Record<string, IKustoStateDurationLogItem[]> = {}

    // Store logs with important prefixes for extensibility
    for (const log of logs) {
      for (const prefix of ImportantScopeNamePrefixes) {
        if (log.scopeName.startsWith(prefix)) {
          if (!result[log.scopeName]) {
            result[log.scopeName] = []
          }
          result[log.scopeName]?.push(log)
          break
        }
      }
    }

    // Add empty array for missing prefixes
    for (const prefix of ImportantScopeNamePrefixes) {
      if (!Object.keys(result).some((scopeName) => scopeName.startsWith(prefix))) {
        result[prefix] = []
      }
    }

    return result
  }

  function getImportantEvents(logs: IKustoTraceLogItem[]): Record<string, IKustoTraceLogItem[]> {
    const result: Record<string, IKustoTraceLogItem[]> = {}
    for (const name of ImportantEventNames) {
      result[name] = logs.filter((item) => item.eventName === name)
    }
    return result
  }
}
