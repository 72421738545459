import { Column } from '@copilot-dash/components'
import { mergeClasses } from '@fluentui/react-components'
import { useStyles } from './Card.styles'

interface IProps {
  readonly className?: string
  readonly children?: React.ReactNode
}

export function Card(props: IProps) {
  const styles = useStyles()

  return <Column className={mergeClasses(styles.card, props.className)}>{props.children}</Column>
}
