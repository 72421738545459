import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { FeedbackId, IWorkItem } from '@copilot-dash/domain'
import { convertWorkItemFromAPItoDomain } from './converters/convertWorkItemFromAPItoDomain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { convertRootCauseFromAPIv2toDomain } from '../actions-team-root-cause/converters/convertRootCauseList'

export function getWorkItemByFeedbackId(
  context: IDashStoreContext,
  feedbackId: FeedbackId,
): PromiseSnapshot<IWorkItem> {
  return context.getOrFetch<IWorkItem>({
    get: (state) => {
      return state.workItems.workItemMap[feedbackId]
    },
    set: (state, snapshot) => {
      state.workItems.workItemMap[feedbackId] = snapshot
    },
    fetch: async () => {
      const ret = await context.requestAnd({
        request: async () => context.api.copilotDash.getWorkItemByFeedbackId(feedbackId),
        onSuccess(state, data) {
          data.rootCauseList.forEach((rootCauseFromApi) => {
            const rootCause = convertRootCauseFromAPIv2toDomain(rootCauseFromApi, context, String(data.teamId))
            state.team.v2RootCauseMap[rootCause.issueId] = PromiseSnapshots.done(rootCause)
          })
        },
      })
      return convertWorkItemFromAPItoDomain(ret)
    },
  })
}
