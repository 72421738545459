import { z } from 'zod'

const api3sOfflineDataGroup3ImpressionSchema = z.record(z.unknown()).and(
  z.object({
    ImpressionId: z.string().optional(),
  }),
)

export const api3sOfflineDataGroup3Schema = z.record(z.unknown()).and(
  z.object({
    ConversationId: z.string().optional(),
    EnterpriseSearchImpressions: z.array(api3sOfflineDataGroup3ImpressionSchema).optional(),
  }),
)
