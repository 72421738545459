import { IClusterScreenState } from './IClusterScreenStoreState'

export function createClusterScreenState(): IClusterScreenState {
  return {
    clickedClusterJobInfo: undefined,
    jobListByAll: { status: 'none' },
    hasMoreJobsForAll: false,
    jobfilterForm: { offset: 0, count: 50 },
  }
}
