import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CopilotDashCustomHeaderProps, CustomHeader } from './CustomHeaderComponent'
import { RenderLongText } from './RenderLongText'
import { TableCellForSnapshot } from './TableCellForSnapshot'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'

export const ColumnConfigUserpSearchQuery: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.UserpSearchQuery],
  field: String(ColumnKey.UserpSearchQuery) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <Content ticket={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.UserpSearchQuery]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 150,
  sortable: false,
  resizable: true,
}

function Content({ ticket }: { ticket: INewTicketData }) {
  const snapshot = app.store.use.getTicketSearchQuery(ticket.ticketId, { existingSearchQuery: ticket.searchQuery })

  return (
    <TableCellForSnapshot snapshot={snapshot}>
      {(data) => {
        return <RenderLongText originText={data} highlightText={ticket.searchQueryWithHighlight} isTranslated={false} />
      }}
    </TableCellForSnapshot>
  )
}
