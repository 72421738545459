import {
  makeStyles,
  Menu,
  MenuItemCheckbox,
  MenuList,
  MenuPopover,
  MenuTrigger,
  tokens,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Tooltip,
} from '@fluentui/react-components'
import { MoreHorizontal24Filled, TextWrapRegular } from '@fluentui/react-icons'
import { Fragment, ReactNode } from 'react'

interface IProps {
  readonly wrap: boolean
  readonly setWrap: (wrap: boolean) => void
  readonly minimap: boolean
  readonly setMinimap: (minimap: boolean) => void
  readonly editable: boolean
  readonly setEditable: (editable: boolean) => void
  readonly children?: ReactNode
}

export function MonacoToolbar({
  wrap,
  setWrap,
  minimap,
  setMinimap,
  editable,
  setEditable,
  children,
}: IProps): ReactNode {
  const styles = useStyles()

  return (
    <Toolbar className={styles.toolbar}>
      <Fragment>
        {children}
        {children && <ToolbarDivider />}
      </Fragment>

      {/* Wrap */}
      <Tooltip content={wrap ? 'Click to unwrap text' : 'Click to wrap text'} relationship={'label'}>
        <ToolbarButton
          className={wrap ? 'enabled' : 'disabled'}
          icon={<TextWrapRegular />}
          onClick={() => {
            setWrap(!wrap)
          }}
        />
      </Tooltip>

      {/* More */}
      <ToolbarDivider />
      <Menu
        checkedValues={{
          feature: [
            editable ? 'editable' : '', //
            minimap ? 'minimap' : '',
          ],
        }}
      >
        <MenuTrigger>
          <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList className={styles.menu}>
            <MenuItemCheckbox
              name="feature"
              className={minimap ? 'enabled' : 'disabled'}
              persistOnClick
              onClick={() => {
                return setMinimap(!minimap)
              }}
              value="minimap"
            >
              {'Show Minimap'}
            </MenuItemCheckbox>

            <MenuItemCheckbox
              name="feature"
              className={editable ? 'enabled' : 'disabled'}
              persistOnClick
              onClick={() => {
                return setEditable(!editable)
              }}
              value="editable"
            >
              {'Allow Editing'}
            </MenuItemCheckbox>
          </MenuList>
        </MenuPopover>
      </Menu>
    </Toolbar>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
  },

  toolbar: {
    flex: 1,
    gap: '2px',

    '& button': {},

    '& button.disabled': {
      color: tokens.colorNeutralForeground4,
    },

    '& button.enabled': {
      color: tokens.colorBrandForeground1,
      // backgroundColor: tokens.colorBrandBackground2,
      backgroundColor: tokens.colorNeutralBackground3,
    },
  },

  menu: {
    '& .fui-MenuItem.disabled': {},

    '& .fui-MenuItem.enabled': {
      color: tokens.colorBrandForeground1,
      backgroundColor: tokens.colorNeutralBackground3,
    },
  },
})
