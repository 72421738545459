import { IAISearchRootCausesOptions, ISearchRootCausesParams } from './AISearchRootCauses.types'

export class AiSearchRootCausesQueryBuilder {
  static buildQuery(options: IAISearchRootCausesOptions): {
    searchQuery?: string
    filterQuery: string
    searchAndHighlightField?: string[]
  } {
    const args = this.__prepareParam(options)
    const encodeArg = this.__encodeParam(args)

    const searchQuery = this.__createSearchQuery(encodeArg)
    const filterQuery = this.__createFilter(encodeArg)
    const searchAndHighlightField = this.__createSearchFieldsAndHighlightFields(encodeArg)
    return { searchQuery, filterQuery, searchAndHighlightField }
  }

  static __prepareParam = (options: IAISearchRootCausesOptions): ISearchRootCausesParams => {
    return {
      teamIds: options.teamIds ?? [],
      searchRootCauseTitle: options.searchRootCauseTitle,
    }
  }

  static __encodeParam = (args: ISearchRootCausesParams): ISearchRootCausesParams => {
    const escapeFilter: (text: string | undefined) => string | undefined = (
      text: string | undefined,
    ): string | undefined => {
      return text ? this.__escapeSpecialCharactersInFilter(text) : undefined
    }

    const escapeArray = (arr: string[] | undefined): string[] | undefined => {
      return arr
        ? arr.map((item) => escapeFilter(item)).filter((item): item is string => item !== undefined)
        : undefined
    }

    const encodedRequest: ISearchRootCausesParams = {
      teamIds: escapeArray(args.teamIds),
      searchRootCauseTitle: args.searchRootCauseTitle
        ? this.__escapeSpecialCharactersInSearchQuery(args.searchRootCauseTitle)
        : undefined,
    }
    return encodedRequest
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
   */
  static __createSearchQuery = (args: ISearchRootCausesParams): string | undefined => {
    const searchTerms: string[] = []
    // Add searchable fields to searchQuery
    if (args.searchRootCauseTitle) searchTerms.push(`title:"${args.searchRootCauseTitle}"`)
    return searchTerms.length > 0 ? searchTerms.join(' OR ') : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-pagination-page-layout#hit-highlighting
   */
  static __createSearchFieldsAndHighlightFields = (args: ISearchRootCausesParams): string[] | undefined => {
    const fields = []
    if (args.searchRootCauseTitle) fields.push('title')
    return fields.length > 0 ? fields : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
   */
  static __createFilter = (args: ISearchRootCausesParams): string => {
    const filters: string[] = []
    const addFilter = (condition: boolean, filter: string) => {
      if (condition) filters.push(filter)
    }

    addFilter(!!args.teamIds?.length, `search.in(teamId, '${args.teamIds?.join(',')}', ',')`)

    return filters.join(' and ')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#escaping-special-characters
   */
  static __escapeSpecialCharactersInSearchQuery(text: string): string {
    // Escape special characters in search query
    const specialCharacters = /[+\-&|!(){}[\]^"~*?:\\/]/g
    return text.replace(specialCharacters, '\\$&')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-odata-filter-orderby-syntax#escaping-special-characters-in-string-constants
   */
  static __escapeSpecialCharactersInFilter(text: string): string {
    // Escape single quotes by doubling them
    return text.replace(/'/g, "''")
  }
}
