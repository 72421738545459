import { z } from 'zod'

export interface ApiV2PatchWorkItemsResponse {
  readonly updateSuccessedWorkItemIdList: Array<string>
  readonly updateFailedWorkItemIdList: Array<string>
}
export const apiV2PatchWorkItemsResponseSchema = z.object({
  updateSuccessedWorkItemIdList: z.array(z.string()),
  updateFailedWorkItemIdList: z.array(z.string()),
})
