import { Column, Row } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text, mergeClasses } from '@fluentui/react-components'
import { FC } from 'react'
import { useStyles } from './StatusField.styles'

interface IProps {
  readonly activity?: IActivityHistory
}

export const AssignToFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (activity?.fieldDiffs?.AssignTo?.oldValue === activity?.fieldDiffs?.AssignTo?.newValue) return null

  return (
    <Row vAlign="start">
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Assign To:</Text>
      </Column>
      <Column>
        <Row>
          {activity?.fieldDiffs?.AssignTo?.newValue && (
            <Text className={styles.tag} title={activity?.fieldDiffs?.AssignTo?.newValue}>
              {activity?.fieldDiffs?.AssignTo?.newValue}
            </Text>
          )}
        </Row>
        <Row>
          {activity?.fieldDiffs?.AssignTo?.oldValue && (
            <Text className={mergeClasses(styles.tag, styles.deleted)} title={activity?.fieldDiffs?.AssignTo?.oldValue}>
              {activity?.fieldDiffs?.AssignTo?.oldValue}
            </Text>
          )}
        </Row>
      </Column>
    </Row>
  )
}
