import { Row } from '@copilot-dash/components'
import { Spinner, Text } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { TicketScreenStore } from '../TicketScreenStore'

export function TicketScreenLayoutForWaiting(): ReactNode {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <Row vAlign="center" hAlign="center">
      <Spinner label={<Text>Loading... ({ticketId})</Text>} labelPosition="below" />
    </Row>
  )
}
