import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const botRequestIds = generateKustoStringArrayExp(params.botRequestIds)
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
EntityServeEvent_Global
| where env_time ${timeRange}
| extend ClientRequestIdJoin = tostring(split(ClientRequestId, ':')[0])
| extend ClientRequestIdJoin = tostring(split(ClientRequestIdJoin, '.')[0])
| where ClientRequestIdJoin in~ (${botRequestIds})
| project env_time, httpHost, httpPath, httpStatusCode, severityText, ClientRequestId, ClientRequestIdJoin, Cv, PartnerName, Region, ScenarioName, ScenarioTag, UserMailboxGuid, UserPuid, RequestSummary, ResponseSummary, RetryAfterValue, RuntimeModel
`
}

export const EntityServeExtensibility_WW: IKustoQueryDefinition = {
  database: 'SubstrateSearchExceptionEvent',
  connection: 'https://substratesearch.kusto.windows.net',
  query,
}
