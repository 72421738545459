import { Button, Tooltip } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarLeadingBack() {
  const close = TicketScreenStore.use((state) => state.close)
  if (!close) {
    return
  }

  return (
    <Tooltip content="Close" relationship="label">
      <Button appearance="subtle" onClick={close} icon={<DismissRegular fontSize={24} />} />
    </Tooltip>
  )
}
