import { ApiRootCauseListByTeamResponse, ApiV2RootCauseItem } from '@copilot-dash/api'
import { IRootCauseData, IRootCauseListByTeam, TeamId } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../../IDashStoreContext'
import { isAssignedTo3SDashTorus } from '../../../utils/is3SDashTorus'
import { getUserProfile } from '../../actions-basic-user/getUserProfile'
import { IAISearchRootCausesResult } from '../AISearchRootCauses.types'

export function convertRootCauseList(
  apiData: ApiRootCauseListByTeamResponse,
  context: IDashStoreContext,
  teamId: TeamId,
): IRootCauseListByTeam {
  const returnData: IRootCauseListByTeam = {
    ...apiData,
    rootCauseList: apiData.rootCauseList.map((item) => {
      const { owner, ...list } = item
      if (owner && !isAssignedTo3SDashTorus(owner)) {
        getUserProfile(context, owner)
      }
      return {
        ...list,
        owner: isAssignedTo3SDashTorus(owner) ? undefined : owner,
        teamId: teamId,
        rootCauseType: 'Issue',
      }
    }),
  }

  return returnData
}

export function convertRootCauseFromAPIv2toDomain(
  apiResponse: ApiV2RootCauseItem,
  context: IDashStoreContext,
  teamId: TeamId,
): IRootCauseData {
  const { id, isDelete, assignTo, eta, ...list } = apiResponse
  if (assignTo && !isAssignedTo3SDashTorus(assignTo)) {
    getUserProfile(context, assignTo)
  }
  return {
    ...list,
    owner: isAssignedTo3SDashTorus(assignTo) ? undefined : assignTo,
    eTA: eta,
    issueId: id,
    rootCauseCount: -1,
    visible: !isDelete,
    teamId: teamId,
    rootCauseType: 'Issue',
  }
}

export function convertRootCauseListV2(
  apiData: IAISearchRootCausesResult,
  context: IDashStoreContext,
): IRootCauseListByTeam {
  const returnData: IRootCauseListByTeam = {
    rootCauseList: apiData.rootCauseList.map((item) => {
      if (item.owner && !isAssignedTo3SDashTorus(item.owner)) {
        getUserProfile(context, item.owner)
      }
      return item
    }),
  }

  return returnData
}
