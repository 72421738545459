import { Row } from '@copilot-dash/components'
import { Drawer, makeStyles, tokens } from '@fluentui/react-components'
import { RootCauseScreenDrawerBody } from './RootCauseScreenDrawerBody'
import { RootCauseScreenDrawerHeader } from './RootCauseScreenDrawerHeader'
import { useTeamViewStore } from '../../store'
import { RootCauseScreenDrawerType } from '../../store/types'

interface IProps {
  readonly type: RootCauseScreenDrawerType
}

export function RootCauseScreenDrawer({ type }: IProps) {
  const styles = useStyles()
  const open = useTeamViewStore((state) => state.rootCauses.activeDrawer === type)

  return (
    <Row>
      <Drawer
        open={open}
        position="end"
        size="medium"
        type="overlay"
        style={{ width: `400px` }}
        className={styles.root}
      >
        <RootCauseScreenDrawerHeader type={type} />
        <RootCauseScreenDrawerBody type={type} />
      </Drawer>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    top: 0,
    bottom: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow64,
    borderTopLeftRadius: tokens.borderRadiusMedium,
    borderBottomLeftRadius: tokens.borderRadiusMedium,
  },
})
