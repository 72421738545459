import { Column, Row } from '@copilot-dash/components'
import { ITicketTurnConversation } from '@copilot-dash/domain'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { CopilotMessageViewAvatar } from './CopilotMessageViewAvatar'
import { usePrivacyModeClass } from '../../../../Privacy/usePrivacyModeClass'
import { MessageContent } from '../common/MessageContent'
import { MessageLabel } from '../common/MessageLabel'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function CopilotMessageView({ data }: IProps) {
  const privacyModeClass = usePrivacyModeClass()
  const styles = useStyles()
  if (!data.response) {
    return null
  }

  return (
    <Row className={styles.root}>
      <CopilotMessageViewAvatar />
      <Column className={mergeClasses(styles.content, privacyModeClass)} hAlign="stretch">
        <MessageContent content={data.response.content} />
        <MessageLabel data={data} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
  },
})
