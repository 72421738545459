import { Column, Row } from '@copilot-dash/components'
import { Text, shorthands, makeStyles, tokens } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { useActivityHistoryPanelStore } from '../../store/store'
import { getWorkItemTitle, mapRootCauseActionType, mapRootCauseActionTypeV2 } from '../utils'

export const ActivityConfirmRootCause: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  const Actions = useMemo(() => {
    if (selectedActivity?.fieldDiffs?.RootCauseActiveContext) {
      const { newValue } = selectedActivity.fieldDiffs.RootCauseActiveContext
      if (Array.isArray(newValue)) {
        return newValue
      }
    }
    return []
  }, [selectedActivity])

  const ActionsV2 = useMemo(() => {
    if (selectedActivity?.fieldDiffs?.RCRUserActions) {
      const { newValue } = selectedActivity.fieldDiffs.RCRUserActions
      if (Array.isArray(newValue)) {
        return newValue
      }
    }
    return []
  }, [selectedActivity])

  return (
    <Column>
      {(Actions.length > 0 || ActionsV2.length > 0) && (
        <>
          {Actions.map((action, idx) => (
            <Row key={idx}>
              <Text key={idx} className={styles.userAction}>
                {mapRootCauseActionType(action.UserAction)}
              </Text>

              <Text key={idx} title={action.Title} className={styles.tag}>
                {action.Title}
              </Text>
            </Row>
          ))}

          {ActionsV2.map((action, idx) => (
            <Row key={idx}>
              <Text key={idx} className={styles.userAction}>
                {mapRootCauseActionTypeV2(action.UserActionType)}
              </Text>
              <Text key={idx} title={getWorkItemTitle(action.WorkItemId)} className={styles.tag}>
                {getWorkItemTitle(action.WorkItemId)}
              </Text>
            </Row>
          ))}
        </>
      )}
    </Column>
  )
}

const useStyles = makeStyles({
  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '4px',
  },
  userAction: {
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '4px',
  },
})
