import { ProductName } from '@copilot-dash/domain'
import { IClusterRouteArgs } from './types'
import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_PRODUCT = 'product'

enum ClusterRouteParams {
  clusteringRequestId = 'clusteringRequestId',
}

export const ClusterRouteMapper: IRouteMapper<IClusterRouteArgs> = {
  mapToParams(args: IClusterRouteArgs): IRouteParams {
    return {
      pathParams: {
        product: args.product,
      },
      queryParams: {
        [ClusterRouteParams.clusteringRequestId]: args.clusteringRequestId,
      },
    }
  },

  mapToArgs(params: IRouteParams): IClusterRouteArgs {
    const path = new RouteDataReader(params.pathParams)
    const query = new RouteDataReader(params.queryParams)
    return {
      product: path.getString(PATH_PARAM_PRODUCT) as ProductName,
      clusteringRequestId: query.getString(ClusterRouteParams.clusteringRequestId),
    }
  },
}
