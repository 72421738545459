import { Column, Spacer } from '@copilot-dash/components'
import { ITeamData, TeamId } from '@copilot-dash/domain'
import { Button, Text } from '@fluentui/react-components'
import { CheckmarkRegular } from '@fluentui/react-icons'
import { useState } from 'react'
import { useStyles } from './CopilotDashDropdown.styles'
import { CopilotDashPopover } from './CopilotDashPopover'

interface IProps {
  placeholder?: string
  teamList: ITeamData[]
  teamId: TeamId | undefined
  teamName: string
  onTeamIdChange: (value: string | undefined) => void
  showInput?: boolean
  disabled?: boolean
}

export const CopilotDashTeamDropdown: React.FC<IProps> = ({
  placeholder = 'Select',
  teamList,
  teamId,
  teamName,
  onTeamIdChange,
  showInput,
  disabled,
}) => {
  const styles = useStyles()
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  const onOptionSelect = (item: ITeamData) => {
    onTeamIdChange(item.id)
    setIsPopoverOpen(false)
  }

  function filterOptions(teamList: ITeamData[]) {
    const filteredOptions = teamList.filter((item) => {
      return item.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      filteredOptions.map((item) => (
        <Column key={item.id}>
          <Button
            icon={teamId === item.id ? <CheckmarkRegular /> : <Spacer width="26px" />}
            appearance="subtle"
            className={styles.selectButton}
            onClick={() => onOptionSelect(item)}
            title={item.name}
          >
            {item.name}
          </Button>
        </Column>
      ))
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = filterOptions(teamList)

  const handleSelectCleanClick = () => {
    onTeamIdChange(undefined)
  }

  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={teamName}
      placeholder={placeholder}
      selectedOptions={teamId ? [teamId] : []}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={inputValue}
      setInputValue={setInputValue}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      showInput={showInput}
      disabled={disabled}
    />
  )
}
