import { TicketTriggeredSkillList } from '@copilot-dash/domain'

/*
 * ['3S'] -> [['3S:Triggered']]
 * ['3S', 'WebSearch'] -> [['3S:Triggered'], ['WebSearch:Triggered']]
 * ['3S', 'WebSearch', 'LLMOnly'] -> [['3S:Triggered'], ['WebSearch:Triggered'], ['!3S:Triggered', '!WebSearch:Triggered', '!Moments:Triggered']]
 */
export function convertTriggeredSkillsFromKeyArray(triggeredSkills: string[]): string[][] {
  return triggeredSkills.map((val) => {
    const skill = TicketTriggeredSkillList.find((skill) => skill.key === val)
    return skill ? skill.options : []
  })
}
