import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  mergeClasses,
} from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { tokens } from '@fluentui/tokens'

interface IProps {
  readonly title?: string
  readonly open: boolean
  readonly header?: boolean
  readonly transition?: boolean
  readonly onClose: () => void
  readonly children?: React.ReactNode
}

export function FullDialog({ title, open, header = true, transition = false, onClose, children }: IProps) {
  const styles = useStyles()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogSurface className={mergeClasses(styles.surface, transition && styles.surfaceWithTransition)}>
        <DialogBody className={styles.body}>
          {header && (
            <DialogTitle
              className={styles.title}
              action={
                <DialogTrigger action="close">
                  <div className={styles.closeButton} title="Close">
                    <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
                  </div>
                </DialogTrigger>
              }
            >
              {title ?? ''}
            </DialogTitle>
          )}
          <DialogContent className={styles.content}>{children}</DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}

const useStyles = makeStyles({
  surface: {
    padding: '0px !important',
    paddingBottom: '0px !important',
    maxWidth: 'unset',
    maxHeight: 'unset',
    overflow: 'hidden',
    border: 'none',

    backgroundColor: tokens.colorNeutralBackground1,
    borderTopLeftRadius: tokens.borderRadiusLarge,
    borderTopRightRadius: tokens.borderRadiusLarge,

    width: 'calc(100vw - 256px)',
    height: 'calc(100vh - 256px)',

    '@media (600px < width <= 1080px)': {
      width: 'calc(100vw - 128px)',
    },

    '@media (600px < height <= 1080px)': {
      height: 'calc(100vh - 128px)',
    },

    '@media (width <= 600px)': {
      width: '100vw',
      height: '100vh',
      borderRadius: 0,
    },

    '@media (height <= 600px)': {
      height: '100vh',
    },
  },
  surfaceWithTransition: {
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
  },
  body: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    maxHeight: 'unset',
    overflow: 'hidden',
    gap: 0,
  },
  title: {
    padding: '8px 16px',
  },
  closeButton: {
    padding: '8px 8px',

    '& > button': {
      '&:hover': {
        backgroundColor: tokens.colorNeutralBackground3Hover,
      },
    },
  },
  content: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    borderTop: `1px solid ${tokens.colorNeutralStroke2}`,

    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',

    '& > *:first-child': {
      flex: '1',
      height: '100%',
    },
  },
})
