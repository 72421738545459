import { KustoLogTable } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function assertTicketKustoNotExpired(
  context: IDashStoreContext,
  ticketId: string,
  table: KustoLogTable,
): Promise<void> {
  // TODO: Implement this function for V2
  return
}
