import { z } from 'zod'

const apiUserpGroup3ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
  }),
)

const apiUserpGroup3ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiUserpGroup3ReplayResponseSchema.optional(),
  }),
)

export const apiUserpGroup3Schema = z.array(apiUserpGroup3ItemSchema)
