import { Column, Row } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { RootCauseScreenDrawer } from './Drawer/RootCauseScreenDrawer'
import { RootCauseScreenDrawerType } from '../store/types'

export function RootCauseDrawerGroup() {
  const styles = useStyles()

  return (
    <Column className={styles.root} vAlign="stretch" hAlign="stretch">
      <Row className={styles.drawer}>
        <RootCauseScreenDrawer type={RootCauseScreenDrawerType.RootCauseDetail} />
        <RootCauseScreenDrawer type={RootCauseScreenDrawerType.DiscussionPanel} />
        <RootCauseScreenDrawer type={RootCauseScreenDrawerType.ActivityHistory} />
      </Row>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    backgroundColor: tokens.colorNeutralBackground3,
    position: 'relative',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  drawer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
})
