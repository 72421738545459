import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { LogoutScreen } from '../../../screens/logout/LogoutScreen'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'

export function LogoutRoutePage() {
  useClaritySectionId(ClarityScenarioIds.logout)
  return (
    <PageTitle title="Logout">
      <LogoutScreen />
    </PageTitle>
  )
}
