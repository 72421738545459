import { Column, Row, Spacer } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text, mergeClasses } from '@fluentui/react-components'
import { FC } from 'react'
import { useStyles } from './StatusField.styles'

interface IProps {
  readonly activity?: IActivityHistory
}

export const PriorityFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (activity?.fieldDiffs?.Priority?.newValue === activity?.fieldDiffs?.Priority?.oldValue) return null
  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Priority:</Text>
      </Column>
      {activity?.fieldDiffs?.Priority?.newValue && (
        <Text className={styles.tag} title={'P' + `${activity?.fieldDiffs?.Priority?.newValue}`}>
          P{activity?.fieldDiffs?.Priority?.newValue}
        </Text>
      )}
      <Spacer width="4px" />
      {activity?.fieldDiffs?.Priority?.oldValue && (
        <Text
          className={mergeClasses(styles.tag, styles.deleted)}
          title={'P' + `${activity?.fieldDiffs?.Priority?.oldValue}`}
        >
          P{activity?.fieldDiffs?.Priority?.oldValue}
        </Text>
      )}
    </Row>
  )
}
