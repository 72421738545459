import { Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components'
import { ReactElement } from 'react'
import { TimezoneSelector } from './TimezoneSelector'
import { ErrorViewBoundary } from '../Error'

interface IProps {
  readonly children: ReactElement
}

export function TimezoneViewPopover({ children }: IProps) {
  return (
    <Popover openOnHover positioning="below-end">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverSurface onClick={(e) => e.stopPropagation()}>
        <ErrorViewBoundary label={'TimezoneViewPopover'}>
          <TimezoneSelector />
        </ErrorViewBoundary>
      </PopoverSurface>
    </Popover>
  )
}
