import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey } from '@copilot-dash/domain'
import { makeStyles, tokens } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo, useMemo } from 'react'

import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'
import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'
import { UserProfile } from '../../../../../components/User/UserProfile'

export const ColumnClusteringRequestCreator: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.CreatedBy],
  field: String(JobColumnKey.CreatedBy) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderUserProfile item={props.data as IClusterJobData} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{JobColumnIdNaColumnIdNameMapping[JobColumnKey.CreatedBy]}</TableCellLayoutWithStyles>
    ),
  },
  minWidth: 200,
  width: 200,
  maxWidth: 300,
  sortable: true,
  resizable: true,
}

const RenderUserProfile = memo(({ item }: { item: IClusterJobData }) => {
  const styles = useStyles()

  const content = useMemo(() => {
    return (
      <UserProfile
        userId={item.createdBy ?? 'System'}
        fallback={item.createdBy}
        defaultDisplayName={'System'}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={styles.customUserName}
      />
    )
  }, [item.createdBy, styles.customUserName, styles.customUserPhoto])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})
RenderUserProfile.displayName = 'RenderUserProfile'

const useStyles = makeStyles({
  customUserPhoto: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
  },
  customUserName: {
    fontSize: tokens.fontSizeBase300,
  },
})
