import { Column, Row, Spacer } from '@copilot-dash/components'
import { getProductIdByName } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { Text } from '@fluentui/react-components'
import { ArrowLeftRegular } from '@fluentui/react-icons'
import * as React from 'react'

import { useCallback, useEffect } from 'react'
import { useStyles } from './ClusterJobTableList.styles'
import { Actions } from './component/Actions'
import { AGGridTable } from '../../../../../components/AGGridTable/AGGridTable'
import { ErrorView, ErrorViewBoundary } from '../../../../../components/Error'
import { ClusterRoute, SearchRoute } from '../../../../../router'
import { LoaderComponent } from '../../../../search/children/loader/LoaderComponent'
import {
  defaultFilterForm,
  productConfigurablePemission,
  useClusterScreenActions,
  useClusterScreenState,
} from '../../../store'

import { ClusterNoPermissionScreen } from '../permission/ClusterNoPermissionScreen'
import { AllClusterJobTableColumns } from '../tableColumns/generateAllClusterTableColumns'

export const ClusterJobList = React.memo(() => {
  const productName = ClusterRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const actions = useClusterScreenActions()
  const ticketsSnapshot = useClusterScreenState((state) => state.jobListByAll)
  const hasMore = useClusterScreenState((state) => state.hasMoreJobsForAll)
  const loadMore = actions.loadMoreClusterListForAll

  const styles = useStyles()

  useEffect(() => {
    if (productId) {
      const filters = defaultFilterForm
      filters.productId = productId
      actions.setFilterForm(filters)
      actions.fetchClusterJobListForAll(filters)
    }
  }, [actions, productId])

  const tableColumns = React.useMemo(() => {
    const defaultColumns = AllClusterJobTableColumns()
    defaultColumns.push(Actions)
    return defaultColumns
  }, [])

  const handleGoBack = useCallback(() => {
    const args = ClusterRoute.navigator.getArgs()
    SearchRoute.navigator.navigate({
      product: args.product,
      tab: 'feedbackInsights',
    })
  }, [])
  const table = React.useMemo(() => {
    if (ticketsSnapshot.status === 'waiting') {
      return (
        <Column vAlign="center" fill>
          <Spacer height={50} />
          <LoaderComponent />
        </Column>
      )
    }
    if (ticketsSnapshot.status === 'error') {
      return <ErrorView error={ticketsSnapshot.error} />
    }
    if (ticketsSnapshot.status === 'done') {
      return ticketsSnapshot.data?.jobCount > 0 ? (
        <AGGridTable
          className={styles.resultContainer}
          rowData={ticketsSnapshot.data?.jobs}
          getRowId={(data) => data.data.id ?? ''}
          columnDefs={tableColumns}
          loadMore={(offset) => loadMore(offset)}
          hasMore={hasMore}
          handleColumnsChange={() => {}}
        />
      ) : (
        <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No Cluster Jobs found for this query" />
      )
    }
    return null
  }, [
    ticketsSnapshot.status,
    ticketsSnapshot.error,
    ticketsSnapshot.data?.jobCount,
    ticketsSnapshot.data?.jobs,
    styles.resultContainer,
    styles.flexGrow,
    tableColumns,
    hasMore,
    loadMore,
  ])
  const hasClusterPermission = React.useMemo(() => {
    const args = ClusterRoute.navigator.getArgs()
    return (
      args.product &&
      application.auth.permission?.roles?.includes(productConfigurablePemission.get(args.product ?? '') ?? '')
    )
  }, [])

  if (!hasClusterPermission) {
    return <ClusterNoPermissionScreen />
  }

  return (
    <>
      <ErrorViewBoundary label="ClusterJobList">
        <TelemetryScope scope="ClusterJobList">
          <Column className={styles.container}>
            <Row vAlign="center">
              <ArrowLeftRegular fontSize={20} style={{ flexShrink: 0, cursor: 'pointer' }} onClick={handleGoBack} />
              <Spacer width={8} />
              <Text size={600} weight="semibold">
                Clusterings
              </Text>
              <Spacer />
            </Row>
            <Spacer height={16} />
            <Row className={styles.cardsContainer}>
              <Column vAlign="center" fill>
                {table}
              </Column>
            </Row>
          </Column>
        </TelemetryScope>
      </ErrorViewBoundary>
    </>
  )
})

ClusterJobList.displayName = 'ClusterJobList'
