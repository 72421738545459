import { Monaco } from '@monaco-editor/react'
import { initMonacoTooltip } from './initMonacoTooltip'

let initialized = false

export function initMonaco(monaco: Monaco) {
  if (!initialized) {
    initMonacoTooltip(monaco)
    initialized = true
  }
}
