import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { clamp } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { TabStore } from '../tab/TabStore'

const DEFAULT_DURATION = 0.2

/**
 * If the child component is not activated for more than {keepAliveDuration} milliseconds, it will be unmounted.
 */
const DEFAULT_KEEP_ALIVE_DURATION = 2 * 60 * 1000

interface IProps {
  readonly id: string
  readonly children?: React.ReactNode
}

export function TabItemBody({ id, children }: IProps) {
  const keepAliveTimerRef = useRef<NodeJS.Timeout>()
  const tabIndex = TabStore.use((state) => state.items.findIndex((item) => item.id === id))
  const activeTabIndex = TabStore.use((state) => state.items.findIndex((item) => item.name === state.activeTab))

  const styles = useStyles()
  const [initialized, setInitialized] = useState(tabIndex === activeTabIndex)
  const isSelected = tabIndex === activeTabIndex

  const onSelected = () => {
    if (keepAliveTimerRef.current) {
      clearTimeout(keepAliveTimerRef.current)
      keepAliveTimerRef.current = undefined
    }

    setInitialized(true)
  }

  const onDeselected = () => {
    if (!keepAliveTimerRef.current) {
      keepAliveTimerRef.current = setTimeout(() => {
        setInitialized(false)
      }, DEFAULT_KEEP_ALIVE_DURATION)
    }
  }

  const onSelectedRef = useRef(onSelected)
  onSelectedRef.current = onSelected
  const onDeselectedRef = useRef(onDeselected)
  onDeselectedRef.current = onDeselected

  useEffect(() => {
    if (isSelected) {
      onSelectedRef.current()
    } else {
      onDeselectedRef.current()
    }
  }, [isSelected])

  const translateDirection = clamp(tabIndex - activeTabIndex, -1, 1)
  const translateX = `${translateDirection * 10}%`

  if (activeTabIndex === -1) {
    return null
  }

  return (
    <motion.div
      className={mergeClasses(`AnimatedTabItem-${tabIndex}`, styles.root)}
      style={{
        pointerEvents: isSelected ? 'auto' : 'none',
      }}
      initial={{
        zIndex: isSelected ? 1 : 0,
        opacity: isSelected ? 1 : 0,
        transform: `translateX(${translateX})`,
      }}
      animate={{
        zIndex: isSelected ? 1 : 0,
        opacity: isSelected ? 1 : 0,
        transform: `translateX(${translateX})`,
      }}
      transition={{
        ease: 'easeInOut',
        duration: DEFAULT_DURATION,
      }}
    >
      {(initialized || isSelected) && children}
    </motion.div>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    '& > *': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
})
