import { IFilterOption, TicketRingType } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'

import { useMemo } from 'react'
import { z } from 'zod'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: TicketRingType[] | undefined
  onChangeValue: (value: TicketRingType[]) => void
}

export const RingFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('ring')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('ring')

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-ringFilter'}
      filterType={displayName}
      isGroup={false}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const ring = z.array(z.nativeEnum(TicketRingType)).safeParse(item).data
        if (ring) {
          onChangeValue(ring)
        }
      }}
    />
  )
}
