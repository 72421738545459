import { useMemo } from 'react'
import { ErrorViewType } from './ErrorViewType'
import { ErrorViewLayout } from './layout/ErrorViewLayout'
import { CustomError, CustomErrorData } from '../ErrorConfigs/types/CustomError'
import { getErrorComponents } from '../ErrorConfigs/utils/getErrorComponents'

interface IProps {
  readonly type?: ErrorViewType
  readonly error: unknown
  readonly className?: string
}

export function ErrorView({ error, className, type = 'block' }: IProps) {
  const components = useMemo(() => {
    return getErrorComponents(error)
  }, [error])

  return <ErrorViewLayout className={className} components={components} type={type} />
}

ErrorView.Custom = function ErrorViewCard(props: Omit<IProps, 'error'> & CustomErrorData) {
  const { className, type, ...error } = props

  const customErrorObject = useMemo(() => {
    return new CustomError(error)
  }, [error])

  return <ErrorView error={customErrorObject} className={className} type={type} />
}
