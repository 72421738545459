import { ProductIds, TeamId } from '@copilot-dash/domain'

import { getFilterDisplayName } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: TeamId[] | undefined
  onChangeValue: (value: TeamId[]) => void
  productId?: ProductIds
}

export const TeamIdsFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, productId }) => {
  const teamListSnapshot = app.store.use.getTeams(productId ?? ProductIds.All)

  const filterOptions = useMemo(() => {
    if (teamListSnapshot.status !== 'done') {
      return []
    }

    return teamListSnapshot.data
      .filter((team) => team.id && team.name)
      .map((team) => ({
        key: team.id.toString(),
        text: team.name.toString(),
      }))
  }, [teamListSnapshot])

  const displayName = getFilterDisplayName('teamIds')
  return teamListSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-teamIdFilter'}
      filterType={displayName}
      isGroup={false}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      showInput={true}
      infoLabelContent={
        <>
          Team applies only to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
