import { produce, PromiseSnapshots } from '@copilot-dash/core'
import {
  BasicSearchTicketArgsNames,
  IQuery,
  TicketDSATStatusTypeV1,
  WorkItemStatus,
  workItemStatusSchema,
} from '@copilot-dash/domain'
import { omitBy } from 'lodash'
import { useMemo } from 'react'
import { ScreenLoadingView } from './ScreenLoadingView'
import { ErrorView } from '../../components/Error'
import { ISearchScreenPropsQuery } from '../../screens/search/SearchScreen'
import { ITeamViewRouteArgs } from '../routes/product.team/types'

type RouteArgs = ITeamViewRouteArgs | ISearchScreenPropsQuery

export function RouteWithQueryData<TArgs extends RouteArgs>({
  queryId,
  args,
  children,
}: {
  queryId: string
  args: TArgs
  children: (parsedArgs: TArgs, currentQuery?: IQuery) => React.ReactNode
}) {
  const queriesSnapshot = application.store.use.getQuery(queryId)
  const mergedQueriesSnapshot = useMemo(() => {
    const parsedPayload = JSON.parse(queriesSnapshot?.data?.filterPayload || '{}') as TArgs

    return PromiseSnapshots.map(queriesSnapshot, (data) => {
      return mergeQueryParam(parsedPayload, args)
    })
  }, [queriesSnapshot, args])

  switch (mergedQueriesSnapshot.status) {
    case 'waiting':
      return <ScreenLoadingView />
    case 'done':
      return <>{children(mergedQueriesSnapshot.data, queriesSnapshot.data)}</>
    case 'error':
      return <ErrorView type="block" error={mergedQueriesSnapshot.error} />
  }
}

const mergeQueryParam = <T extends RouteArgs>(parsedPayload: T, query: T): T => {
  const omitUndefined = (value: unknown): boolean => value === undefined
  const filteredQuery = omitBy(query, omitUndefined)
  const compatiblePayload = makeQueryCompatible(parsedPayload)
  return { ...compatiblePayload, ...filteredQuery }
}

function makeQueryCompatible<T extends RouteArgs>(parsedPayload: T): T {
  const compatiblePayload = produce(parsedPayload, (draft) => {
    if (draft[BasicSearchTicketArgsNames.dSATStatus]) {
      draft[BasicSearchTicketArgsNames.dSATStatus] = draft[BasicSearchTicketArgsNames.dSATStatus]?.map(
        (status: unknown): WorkItemStatus => {
          switch (status) {
            case TicketDSATStatusTypeV1.DSATStatusClosedByDesign:
              return WorkItemStatus.ClosedByDesign
            case TicketDSATStatusTypeV1.DSATStatusClosedFixed:
              return WorkItemStatus.ClosedFixed
            case TicketDSATStatusTypeV1.DSATStatusClosedNotActionable:
              return WorkItemStatus.ClosedNotActionable
            case TicketDSATStatusTypeV1.DSATStatusRootCaused:
              return WorkItemStatus.RootCaused
            case TicketDSATStatusTypeV1.DSATStatusTeamAssigned:
              return WorkItemStatus.TeamAssigned
            case TicketDSATStatusTypeV1.DSATStatusUntriaged:
              return WorkItemStatus.Untriaged
            default:
              try {
                return workItemStatusSchema.parse(status)
              } catch (error) {
                throw new Error(`Unknown DSAT status: ${status}`)
              }
          }
        },
      )
    }
  })
  return compatiblePayload
}
