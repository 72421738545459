import { Route } from 'react-router-dom'
import { SettingsRoutePage } from './SettingsRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { VoidMapper } from '../../utils/VoidMapper'

export function SettingsRoute() {
  return <Route id={ID} path={PATH} element={<SettingsRoutePage />} />
}

const ID = 'settings'
const PATH = '/settings'
SettingsRoute.navigator = createNavigator(ID, PATH, VoidMapper)
