import { Body1Stronger } from '@fluentui/react-components'
import { useMemo } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { QueryProcessingCardFilters } from './config'
import { useTicketFilterStore } from '../../store/store'
import { TriggeredSkillFilter, OptionsSetsFilter, HitAvalonFilter, SliceIdsFilter, FlightFilter } from '../filters'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function QueryProcessingCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const hasQueryProcessingCard = useMemo(() => {
    return filterPanelItems.some((item) => QueryProcessingCardFilters.includes(item))
  }, [filterPanelItems])

  if (!hasQueryProcessingCard) return null
  return (
    <section className={styles.section}>
      <Body1Stronger>Query processing</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems.includes('hitAvalon') && (
          <Controller
            name="hitAvalon"
            control={control}
            render={({ field }) => <HitAvalonFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('triggeredSkill') && (
          <Controller
            name="triggeredSkill"
            control={control}
            render={({ field }) => <TriggeredSkillFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('optionsSets') && (
          <Controller
            name="optionsSets"
            control={control}
            render={({ field }) => <OptionsSetsFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes('sliceIds') && (
          <Controller
            name="sliceIds"
            control={control}
            render={({ field }) => <SliceIdsFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {filterPanelItems?.includes('flights') && (
          <Controller
            name="flights"
            control={control}
            render={({ field }) => <FlightFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
