import { ApiBizChat3SLatencyLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoBizChat3SLatencyLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function get3SLatencyLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoBizChat3SLatencyLogItem[]> {
  return context.getOrFetch<IKustoBizChat3SLatencyLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.threeSLatencyLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.threeSLatencyLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.substrateSearchLatencyLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash
          .getTicketBlob(ticketId, sourceItem.url)
          .asSubstrateSearchLatencyLog()
        return results.map(convertItem)
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicket3sTriggered(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChat3SLatencyLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.BizChat3SLatencyLog, sourceItem })
    },
  })

  function convertItem(raw: ApiBizChat3SLatencyLogItemV2): IKustoBizChat3SLatencyLogItem {
    return {
      transactionId: raw.TransactionId,
      perfTraceFile: raw.PerfTraceFile,
      raw,
    }
  }
}
