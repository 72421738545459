import { makeStyles, Text } from '@fluentui/react-components'
import { Row } from '@copilot-dash/components'
import { ValueViewForStringCopyButton } from './ValueViewForStringCopyButton'
import { ValueViewForStringExplainer } from './ValueViewForStringExplainer'

const LONG_TEXT_LENGTH = 1000

interface IProps {
  readonly value: string
}

export function ValueViewForString({ value }: IProps) {
  const styles = useStyles()

  const child = (
    <Row className={styles.root} title={value} wrap>
      <Text className={styles.text} wrap truncate block={false}>
        {value}
        <ValueViewForStringExplainer value={value} />
        <ValueViewForStringCopyButton value={value} />
      </Text>
    </Row>
  )

  if (value.length > LONG_TEXT_LENGTH) {
    return <div className={styles.scrollbar}>{child}</div>
  } else {
    return child
  }
}

const useStyles = makeStyles({
  scrollbar: {
    maxHeight: '220px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  root: {
    padding: '8px',
  },
  text: {
    wordBreak: 'break-word',
  },
})
