import { ITicketDiagnosticGroup } from '@copilot-dash/domain'
import { OutlineGroup } from './OutlineGroup'
import { OutlineItemForTicketBlob } from './OutlineItemForTicketBlob'

interface IProps {
  readonly data: ITicketDiagnosticGroup
}

export function OutlineGroupForSource({ data }: IProps) {
  return (
    <OutlineGroup name={data.name}>
      {data.sources.map((source, index) => (
        <OutlineItemForTicketBlob key={index} data={source} />
      ))}
    </OutlineGroup>
  )
}
