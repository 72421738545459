import { LanguageTierSlicers, LocaleSlicers } from '@copilot-dash/domain'
import { getFilterDisplayName } from '@copilot-dash/store'
import { Control, Controller } from 'react-hook-form'

import { CopilotDashDependencyDropdown } from '../../../Filter/CopilotDashDependencyDropdown'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export const SettingLanguageSlicerFilter: React.FC<IProps> = ({ control }: IProps) => {
  function calcLocaleSlicersFromLanguageTierSlicers(selectedLanguageTierSlicers: string[]): string[] {
    const items = (
      selectedLanguageTierSlicers.length === 0
        ? LocaleSlicers
        : LocaleSlicers.filter((locale) => selectedLanguageTierSlicers.some((group) => group === locale.languageTier))
    )
      .map((slicer) => slicer.locales)
      .flat()

    return items.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
  }

  const optionsList = Object.values(LanguageTierSlicers).map((item) => ({
    key: item,
    text: item,
  }))

  const parentDisplayName = getFilterDisplayName('languageTierSlicers')
  const childDisplayName = getFilterDisplayName('localeSlicers')

  return (
    <>
      <Controller
        name="languageTierSlicers"
        control={control}
        render={({ field }) => (
          <Controller
            name="localeSlicers"
            control={control}
            render={({ field: localeField }) => (
              <CopilotDashDependencyDropdown
                parentFilter={field.name}
                parentDisplayName={parentDisplayName}
                parentOptions={optionsList}
                selectedParents={field.value}
                onParentChanged={field.onChange}
                childFilter={localeField.name}
                childDisplayName={childDisplayName}
                selectedChlidren={localeField.value}
                onChildChanged={localeField.onChange}
                parentInfoLabel={<>Setting Language is computed offline with a potential delay. SLA: 33 hours.</>}
                childInfoLabel={<>Please select a languageTier slicer first.</>}
                childCalcFunc={calcLocaleSlicersFromLanguageTierSlicers}
              />
            )}
          />
        )}
      />
    </>
  )
}
