import { DefaultProducts } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { TicketScreenLayout } from './layout/TicketScreenLayout'
import { TicketScreenStore } from './TicketScreenStore'
import { TicketDiagnosticPlugin } from '../ticket-diagnostic/TicketDiagnosticPlugin'

interface IProps {
  readonly ticketId: string
  readonly onClose?: () => void
}

export function TicketScreen({ ticketId, onClose }: IProps) {
  const environment = app.store.use.getTicketEnvironment(ticketId)
  const productId = environment.data?.productId
  const clientName = environment.data?.clientName
  const product = DefaultProducts.find((product) => product.productId === environment.data?.productId)
  const productName = product?.name

  return (
    <TicketDiagnosticPlugin ticketId={ticketId}>
      <TelemetryScope scope="TicketScreen" properties={{ ticketId, productId, productName, clientName }}>
        <TicketScreenStore key={ticketId} ticketId={ticketId} onClose={onClose}>
          <TicketScreenLayout />
        </TicketScreenStore>
      </TelemetryScope>
    </TicketDiagnosticPlugin>
  )
}
