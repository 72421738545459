import { Times } from '@copilot-dash/core'
import { ISearchTicketOptions, SearchTextPrefixType, getProductEndpoints } from '@copilot-dash/domain'

import { IQueryBuilderArgs } from './AiSearchQueryBuilder.types'
import { convertHasErrorMessages } from './utils/convertHasErrorMessages'
import { convertTriggeredSkillsFromKeyArray } from './utils/convertTriggeredSkillsFromKeyArray'

export class AiSearchQueryBuilder {
  buildQuery(options: ISearchTicketOptions): {
    searchQuery?: string
    filterQuery: string
    searchAndHighlightField?: string[]
  } {
    const args = this.prepareParam(options)
    const encodeArg = this.encodeParam(args)

    const searchQuery = this.createSearchQuery(encodeArg)
    const filterQuery = this.createFilter(encodeArg)
    const searchAndHighlightField = this.createSearchFieldsAndHighlightFields(encodeArg)
    return { searchQuery, filterQuery, searchAndHighlightField }
  }

  private prepareParam = (options: ISearchTicketOptions): IQueryBuilderArgs => {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const triggeredSkillGroups = convertTriggeredSkillsFromKeyArray(options.triggeredSkill ?? [])

    const hasErrorMessagesConditions = convertHasErrorMessages(options.hasErrorMessages)

    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.scenarios,
    )

    const request: IQueryBuilderArgs = {
      userId: options.userId,
      tenantId: options.tenantIds,
      from: from,
      to: to,
      emotionType: options.thumbs,
      hasUserConsent:
        options.hasUserConsent?.length === 1 ? (options.hasUserConsent[0]?.startsWith('!') ? false : true) : undefined,
      hasVerbatim:
        options.hasVerbatim?.length === 1 ? (options.hasVerbatim[0]?.startsWith('!') ? false : true) : undefined,
      clientName: clientNames,
      scenarioName: options.channel,
      authTypes: options.authTypes,
      ring: options.ring,
      chatMode: options.chatMode,

      verbatim:
        options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      utterance:
        options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      response:
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      searchQuery:
        options.searchTextPrefix === SearchTextPrefixType.Query || options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      customTags: options.customTags,
      copilotExtensionIds: options.copilotExtensionIds,
      flights: options.flights,
      sliceIds: options.sliceIds,
      optionsSets: options.optionsSets,
      hasScreenshot: options.hasScreenshot,
      tags: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appKinds?.length ? [options.appKinds.join('|')] : []),
        ...(options.feedbackTargets?.length ? [options.feedbackTargets.join('|')] : []),
      ],
      triggeredSkillGroups: triggeredSkillGroups,
      invocationSlicers: options.invocationSlicers,
      utteranceGroups: options.utteranceGroups,
      scenarioSlicers: options.scenarioSlicers,
      builderNameSlicers: options.builderNameSlicers,
      capabilitiesSlicers: options.capabilitiesSlicers,
      usageIntensityCohortSlicers: options.usageIntensityCohortSlicers,
      engagementCohortSlicers: options.engagementCohortSlicers,
      languageTierSlicers: options.languageTierSlicers,
      localeSlicers: options.localeSlicers,
      id: options.id ? [...options.id] : [],
      workItemStatuses: options.dSATStatus ? [...options.dSATStatus] : [],
      workItemPriorities: options.priority
        ? [...options.priority].map((str) => (str.startsWith('P') ? str : `P${str}`))
        : [],
      workItemTeamIds: options.teamId ? [options.teamId] : options.teamIds ? [...options.teamIds] : [],
      workItemAssignees: options.dSATAssignedTo ? [...options.dSATAssignedTo] : [],
      workItemHasAssignee: options.hasAssignee,
      workItemRootCauseIds: options.issueId ? [options.issueId] : [],
      hasRootCauseRecommendation: options.hasRootCauseRecommendation,
      recommendedRootCauseIds: options.recommendedRootCauseId ? [options.recommendedRootCauseId] : [],
      topIssueIds: options.clusteringIssueId ? [options.clusteringIssueId] : [],
      topIssueBatchIds: options.batchId ? [options.batchId] : [],
      clientFlights: options.clientFlights,
      deployRing: options.deployRing,
    }

    return request
  }

  private encodeParam = (args: IQueryBuilderArgs): IQueryBuilderArgs => {
    const escapeFilter: (text: string | undefined) => string | undefined = (
      text: string | undefined,
    ): string | undefined => {
      return text ? this.escapeSpecialCharactersInFilter(text) : undefined
    }

    const escapeArray = (arr: string[] | undefined): string[] | undefined => {
      return arr
        ? arr.map((item) => escapeFilter(item)).filter((item): item is string => item !== undefined)
        : undefined
    }

    const encodedRequest: IQueryBuilderArgs = {
      ...args,

      // Escape special characters in search query for verbatim, utterance, response and userp
      verbatim: args.verbatim ? this.escapeSpecialCharactersInSearchQuery(args.verbatim) : undefined,
      utterance: args.utterance ? this.escapeSpecialCharactersInSearchQuery(args.utterance) : undefined,
      response: args.response ? this.escapeSpecialCharactersInSearchQuery(args.response) : undefined,
      searchQuery: args.searchQuery ? this.escapeSpecialCharactersInSearchQuery(args.searchQuery) : undefined,

      // Escape special characters in filter for copilotExtensionIds, clientFlights, flights, sliceIds, and optionsSets
      copilotExtensionIds: escapeFilter(args.copilotExtensionIds),
      flights: escapeFilter(args.flights),
      sliceIds: escapeFilter(args.sliceIds),
      optionsSets: escapeFilter(args.optionsSets),
      clientFlights: escapeFilter(args.clientFlights),

      // Escape special characters in array elements for customTags, tags, invocationSlicers, id, userId, workItemStatuses, workItemPriorities, workItemAssignees, workItemRootCauseIds, workItemTeamIds
      customTags: escapeArray(args.customTags),
      tags: escapeArray(args.tags),
      invocationSlicers: escapeArray(args.invocationSlicers),
      utteranceGroups: escapeArray(args.utteranceGroups),
      scenarioSlicers: escapeArray(args.scenarioSlicers),
      builderNameSlicers: escapeArray(args.builderNameSlicers),
      capabilitiesSlicers: escapeArray(args.capabilitiesSlicers),
      usageIntensityCohortSlicers: escapeArray(args.usageIntensityCohortSlicers),
      engagementCohortSlicers: escapeArray(args.engagementCohortSlicers),
      languageTierSlicers: escapeArray(args.languageTierSlicers),
      localeSlicers: escapeArray(args.localeSlicers),
      id: escapeArray(args.id),
      userId: escapeArray(args.userId),
      workItemStatuses: escapeArray(args.workItemStatuses),
      workItemPriorities: escapeArray(args.workItemPriorities),
      workItemAssignees: escapeArray(args.workItemAssignees),
      workItemRootCauseIds: escapeArray(args.workItemRootCauseIds),
      workItemTeamIds: escapeArray(args.workItemTeamIds),
      recommendedRootCauseIds: escapeArray(args.recommendedRootCauseIds),
      topIssueIds: escapeArray(args.topIssueIds),
      topIssueBatchIds: escapeArray(args.topIssueBatchIds),
    }

    return encodedRequest
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax
   */
  private createSearchQuery = (args: IQueryBuilderArgs): string | undefined => {
    const searchTerms: string[] = []
    // Add searchable fields to searchQuery
    if (args.utterance) searchTerms.push(`utterance:"${args.utterance}"`)
    if (args.verbatim) searchTerms.push(`verbatim:"${args.verbatim}"`)
    if (args.response) searchTerms.push(`response:"${args.response}"`)
    if (args.searchQuery) searchTerms.push(`searchQuery:"${args.searchQuery}"`)

    return searchTerms.length > 0 ? searchTerms.join(' OR ') : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-pagination-page-layout#hit-highlighting
   */
  private createSearchFieldsAndHighlightFields = (args: IQueryBuilderArgs): string[] | undefined => {
    // add 'utterance', 'verbatim', 'response', and 'searchQuery' to the array if they have values in args.
    const fields = ['utterance', 'verbatim', 'response', 'searchQuery']
    const searchTerms = fields.filter((field) => args[field as keyof IQueryBuilderArgs])

    return searchTerms.length > 0 ? searchTerms : undefined
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
   */
  private createFilter = (args: IQueryBuilderArgs): string => {
    const filters: string[] = []
    const addFilter = (condition: boolean, filter: string) => {
      if (condition) filters.push(filter)
    }

    // Add other fields to filterQuery
    addFilter(!!args.userId?.length, `search.in(userId, '${args.userId?.join(',')}', ',')`)
    addFilter(!!args.tenantId?.length, `search.in(tenantId, '${args.tenantId?.join(',')}', ',')`)
    addFilter(!!args.from, `createDateTime ge ${args.from}`)
    addFilter(!!args.to, `createDateTime le ${args.to}`)
    addFilter(!!args.emotionType?.length, `search.in(emotionType, '${args.emotionType?.join(',')}', ',')`)
    addFilter(args.hasUserConsent !== undefined, `hasUserConsent eq ${args.hasUserConsent}`)

    /**
     * The AI search using Cosmos DB is faster than the collection process based on SQL Server.
     * This discrepancy may result in search indexing being completed before the ticket collection is finished.
     */
    // short-term solution 1: add a time offset to filter tickets from 60 minutes ago.
    const now = new Date()
    const fifteenMinutesAgo = new Date(now.getTime() - 60 * 60 * 1000)
    addFilter(true, `createDateTime le ${fifteenMinutesAgo.toISOString()}`)

    //Short-term solution 2: Show tickets even when it's "cooked" - Search filter: fullTags not eq null or empty
    // Note: tickets before 2025-01-01 don't have fullTags, so we need to show them as well.
    // Error: only CopilotChatFeedback scenario tickets have fullTags.
    // const noFullTagsDate = new Date('2025-01-01T00:00:00Z')
    // addFilter(true, `(createDateTime le ${noFullTagsDate.toISOString()} or fullTags/any())`)

    // Add filter for hasVerbatim
    if (args.hasVerbatim === true) {
      addFilter(true, `(verbatim ne null and verbatim ne '')`)
    } else if (args.hasVerbatim === false) {
      addFilter(true, `(verbatim eq null or verbatim eq '')`)
    }

    addFilter(!!args.clientName?.length, `search.in(clientName, '${args.clientName?.join(',')}', ',')`)
    addFilter(!!args.scenarioName?.length, `search.in(scenarioName, '${args.scenarioName?.join(',')}', ',')`)
    addFilter(!!args.ring?.length, `search.in(ring, '${args.ring?.join(',')}', ',')`)
    addFilter(!!args.authTypes?.length, `search.in(authenticationType, '${args.authTypes?.join(',')}', ',')`)
    addFilter(!!args.customTags?.length, `customTags/any(t: search.in(t, '${args.customTags?.join(',')}', ','))`)
    addFilter(!!args.chatMode?.length, `search.in(chatMode, '${args.chatMode?.join(',')}', ',')`)
    addFilter(!!args.deployRing?.length, `search.in(deployRing, '${args.deployRing?.join(',')}', ',')`)

    /**
     * Tag-Based Filter: This filter handles both 'tags' and 'fullTags' fields in the AI search.
     * It ensures that the search considers both fields for the presence or absence of tags.
     * - If a tag starts with '!', it excludes the tag from both 'tags' and 'fullTags'.
     * - If a tag includes '|', it checks for multiple values in both 'tags' and 'fullTags' using 'search.in'.
     * - For single values, it checks if the tag is present in either 'tags' or 'fullTags'.
     * Short-term solution: Check both 'tags' and 'fullTags' fields for the presence or absence of tags.
     * Long-term solution: We plan to merge 'tags' and 'fullTags' into a single property.
     */
    addFilter(
      !!args.tags?.length,
      `(${args.tags
        ?.map((tag) => {
          if (tag.startsWith('!')) {
            return `(not tags/any(t: t eq '${tag.substring(1)}') and not fullTags/any(t: t eq '${tag.substring(1)}'))`
          } else if (tag.includes('|')) {
            return `(tags/any(t: search.in(t, '${tag}', '|')) or fullTags/any(t: search.in(t, '${tag}', '|')))`
          } else {
            return `(tags/any(t: t eq '${tag}') or fullTags/any(t: t eq '${tag}'))`
          }
        })
        .join(' and ')})`,
    )

    /*
     * Use an AND condition for tags configurated within a single skill option, e.g., ['!3S:Triggered', '!WebSearch:Triggered', '!Moments:Triggered']
     * Use an OR condition across different skill options, e.g., ['3S:Triggered'], ['WebSearch:Triggered']
     */
    addFilter(
      !!args.triggeredSkillGroups?.length,
      `(${args.triggeredSkillGroups
        ?.map((skillGroup) => {
          const skillGroupCondition = skillGroup
            .map((tag) => {
              if (tag.startsWith('!')) {
                return `(not tags/any(t: t eq '${tag.substring(1)}') and not fullTags/any(t: t eq '${tag.substring(1)}'))`
              } else {
                return `(tags/any(t: t eq '${tag}') or fullTags/any(t: t eq '${tag}'))`
              }
            })
            .join(' and ')
          return `(${skillGroupCondition})`
        })
        .join(' or ')})`,
    )

    // Filter for optionsSets, sliceIds, flights and clientFlights.Note: These fields are filterable in AI search.
    addFilter(!!args.optionsSets, `optionsSets/any(t: t eq '${args.optionsSets}')`)
    addFilter(!!args.sliceIds, `sliceIds/any(t: t eq '${args.sliceIds}')`)
    addFilter(!!args.flights, `flights/any(t: t eq '${args.flights}')`)
    addFilter(!!args.clientFlights, `clientFlights/any(t: t eq '${args.clientFlights}')`)

    // Support partial pattern search for copilotExtensionIds. Note: copilotExtensionIds is searchable in AI search
    addFilter(!!args.copilotExtensionIds, `search.ismatch('${args.copilotExtensionIds}', 'copilotExtensionIds')`)

    // Remote: string list (slicers)
    // Client: string list (invocationSlicers)
    // Checks for any elements in the remote slicers array that are also present in the client-provided invocationSlicers list.
    addFilter(!!args.invocationSlicers?.length, `slicers/any(s: search.in(s, '${args.invocationSlicers}', ','))`)

    // Remote: string (utteranceGroup)
    // Client: string list (utteranceGroups)
    // Checks for any elements in the remote utteranceGroup string matches any of the strings provided in the client utteranceGroups list.
    addFilter(!!args.utteranceGroups?.length, `search.in(utteranceGroup, '${args.utteranceGroups?.join(',')}', ',')`)

    // Remote: string (scenarioSlicer)
    // Client: string list (scenarioSlicers)
    // Checks if the remote scenarioSlicer string matches any of the strings provided in the client scenarioSlicers list.
    addFilter(!!args.scenarioSlicers?.length, `search.in(scenarioSlicer, '${args.scenarioSlicers?.join(',')}', ',')`)

    // Remote: string (builderNameSlicer)
    // Client: string list (builderNameSlicers)
    // Checks if the remote builderNameSlicer string matches any of the strings provided in the client builderNameSlicers list.
    addFilter(
      !!args.builderNameSlicers?.length,
      `search.in(builderNameSlicer, '${args.builderNameSlicers?.join(',')}', ',')`,
    )

    // Remote: string list (capabilitiesSlicers)
    // Client: string list (capabilitiesSlicers)
    // Checks for any elements in the remote capabilitiesSlicers array that are also present in the client-provided capabilitiesSlicers list.
    addFilter(
      !!args.capabilitiesSlicers?.length,
      `capabilitiesSlicer/any(s: search.in(s, '${args.capabilitiesSlicers}', ','))`,
    )

    // Remote: string (usageIntensityCohortSlicer)
    // Client: string list (usageIntensityCohortSlicers)
    // Checks if the remote usageIntensityCohortSlicer string matches any of the strings provided in the client usageIntensityCohortSlicers list.
    addFilter(
      !!args.usageIntensityCohortSlicers?.length,
      `search.in(usageIntensityCohortSlicer, '${args.usageIntensityCohortSlicers?.join(',')}', ',')`,
    )

    // Remote: string (engagementCohortSlicer)
    // Client: string list (engagementCohortSlicers)
    // Checks if the remote engagementCohortSlicer string matches any of the strings provided in the client engagementCohortSlicer list.
    addFilter(
      !!args.engagementCohortSlicers?.length,
      `search.in(engagementCohortSlicer, '${args.engagementCohortSlicers?.join(',')}', ',')`,
    )

    // Remote: string (languageTierSlicer)
    // Client: string list (languageTierSlicers)
    // Checks if the remote languageTierSlicer string matches any of the strings provided in the client languageTierSlicers list.
    addFilter(
      !!args.languageTierSlicers?.length,
      `search.in(languageTierSlicer, '${args.languageTierSlicers?.join(',')}', ',')`,
    )

    // Remote: string (localeSlicer)
    // Client: string list (localeSlicers)
    // Checks if the remote localeSlicer string matches any of the strings provided in the client localeSlicers list.
    addFilter(!!args.localeSlicers?.length, `search.in(localeSlicer, '${args.localeSlicers?.join(',')}', ',')`)

    // Add filter for feedback ticket ID
    addFilter(!!args.id?.length, `search.in(id, '${args.id?.join(',')}', ',')`)

    // Add filter for screenshotCount
    if (args.hasScreenshot === true) {
      addFilter(true, `screenshotCount ne null and screenshotCount ne '' and screenshotCount ne '0'`)
    } else {
      addFilter(
        args.hasScreenshot === false,
        `(screenshotCount eq null or screenshotCount eq '' or screenshotCount eq '0')`,
      )
    }

    /**
     * TeamView V2
     */
    // Add filter for hasRootCauseRecommendation, workItemStatuses, workItemPriorities, workItemTeamIds, workItemAssignees, and workItemRootCauseIds
    if (args.hasRootCauseRecommendation === false) {
      addFilter(true, `(rootCauseRecommendationWorkItemId eq null or rootCauseRecommendationWorkItemId eq '')`)
    }
    addFilter(!!args.workItemStatuses?.length, `search.in(workItemStatus, '${args.workItemStatuses?.join(',')}', ',')`)
    addFilter(
      !!args.workItemPriorities?.length,
      `search.in(workItemPriority, '${args.workItemPriorities?.join(',')}', ',')`,
    )
    addFilter(!!args.workItemTeamIds?.length, `search.in(workItemTeamId, '${args.workItemTeamIds?.join(',')}', ',')`)

    if (typeof args.workItemHasAssignee === 'boolean') {
      if (args.workItemHasAssignee) {
        addFilter(true, `(workItemAssignTo ne null and workItemAssignTo ne '')`)
      } else {
        addFilter(true, `(workItemAssignTo eq null or workItemAssignTo eq '')`)
      }
    }

    addFilter(
      !!args.workItemAssignees?.length,
      `search.in(workItemAssignTo, '${args.workItemAssignees?.join(',')}', ',')`,
    )

    addFilter(
      !!args.workItemRootCauseIds?.length,
      `relatedWorkItemIds/any(t: search.in(t, '${args.workItemRootCauseIds?.join(',')}', ','))`,
    )
    // Add filter for recommendedRootCauseIds
    addFilter(
      !!args.recommendedRootCauseIds?.length,
      `search.in(rootCauseRecommendationWorkItemId, '${args.recommendedRootCauseIds?.join(',')}', ',')`,
    )

    /**
     * Top Issues
     */
    addFilter(!!args.topIssueIds?.length, `search.in(topIssueId, '${args.topIssueIds?.join(',')}', ',')`)
    addFilter(!!args.topIssueBatchIds?.length, `search.in(topIssueBatchId, '${args.topIssueBatchIds?.join(',')}', ',')`)

    return filters.join(' and ')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#escaping-special-characters
   */
  private escapeSpecialCharactersInSearchQuery(text: string): string {
    // Escape special characters in search query
    const specialCharacters = /[+\-&|!(){}[\]^"~*?:\\/]/g
    return text.replace(specialCharacters, '\\$&')
  }

  /**
   * https://learn.microsoft.com/en-us/azure/search/query-odata-filter-orderby-syntax#escaping-special-characters-in-string-constants
   */
  private escapeSpecialCharactersInFilter(text: string): string {
    // Escape single quotes by doubling them
    return text.replace(/'/g, "''")
  }
}
