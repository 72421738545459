import { ClusterResultColumnKey, IClusterJobResultSummary } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnClusterResultIssue: ColDef<IClusterJobResultSummary> = {
  headerName: ClusterResultColumnKey.Issue,
  field: String(ClusterResultColumnKey.Issue) as NestedFieldPaths<IClusterJobResultSummary>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <Text weight={'semibold'}>{props.data.issue}</Text>
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>Issue</TableCellLayoutWithStyles>,
  },
  minWidth: 800,
  width: 800,
  maxWidth: 1200,
  sortable: true,
  resizable: true,
}
