import { shorthands, makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  columnContainer: {
    width: '30%',
  },
  statueKey: {
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
    fontWeight: tokens.fontWeightSemibold,
    whiteSpace: 'nowrap',
  },
  tag: {
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.padding('4px', '8px'),
    ...shorthands.margin('4px', '4px'),
    borderRadius: '4px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
  deleted: {
    textDecoration: 'line-through',
    marginRight: '8px',
    color: tokens.colorScrollbarOverlay,
    '& img': {
      filter: 'grayscale(50%) opacity(50%)',
    },
  },

  noUpdate: {
    ...shorthands.padding('4px', '4px'),
    ...shorthands.margin('4px', '4px'),
  },
  newValue: {
    maxWidth: '50%',
    wordBreak: 'break-word',
  },
  customTag: {
    width: 'fit-content',
    maxWidth: '500px',
  },
  rootCauseValue: {
    maxWidth: '70%',
  },
  descriptionRow: { marginLeft: '4px', paddingRight: '4px', overflowWrap: 'break-word' },
})
