export enum JobStatus {
  Submitted = 'Submitted',
  Canceled = 'Canceled',
  DataGathering = 'DataGathering',
  DatasetGenerationError = 'DatasetGenerationError',
  Clustering = 'Clustering',
  ClusteringCompleted = 'ClusteringCompleted',
  ClusteringError = 'ClusteringError',
  DatasetReady = 'DatasetReady',
  Published = 'Published',
}
