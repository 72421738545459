import { IFilterOption, WorkItemStatus } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'

import { useMemo } from 'react'
import { z } from 'zod'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: WorkItemStatus[] | undefined
  onChangeValue: (value: WorkItemStatus[]) => void
  disabled?: boolean
}

export const DSATStatusFilter: React.FC<IProps> = ({ selectedValues, onChangeValue, disabled }) => {
  const filterOptionsSnapshot = useFilterOptions('dSATStatus')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('dSATStatus')

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-dSATStatusFilter'}
      filterType={displayName}
      isGroup={false}
      disabled={disabled}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={(item: string[]) => {
        const state = z.array(z.nativeEnum(WorkItemStatus)).safeParse(item)
        if (state.success) {
          onChangeValue(state.data)
        }
      }}
      infoLabelContent={
        <>
          State applies only to <b>user-consented DSATs</b> with a work item.
        </>
      }
    />
  )
}
