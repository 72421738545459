import { Field, Dropdown, Option } from '@fluentui/react-components'
import { FC, memo, useState } from 'react'
import { useCommonStyles } from './commonStyles.styles'
import { PRIORITY_OPTIONS } from '../utils'

interface IPriorityProps {
  value: number | undefined
  onChange: (value: number) => void
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const Priority: FC<IPriorityProps> = memo(
  ({ value, onChange, orientation = 'horizontal', className, style, defaultValueText, disabled, fieldProps }) => {
    const styles = useCommonStyles()
    const [open, setOpen] = useState(false)
    return (
      <Field className={className} label="Priority:" orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <Dropdown
          open={open}
          onOpenChange={(evt, data) => {
            if (evt.type === 'blur') return
            setOpen(data.open)
          }}
          disabled={disabled}
          value={typeof value === 'number' ? `P${value}` : defaultValueText}
          selectedOptions={String(value) ? [String(value)] : []}
          onOptionSelect={(_, data) => data.selectedOptions[0] && onChange(Number(data.selectedOptions[0]))}
          appearance="underline"
          listbox={{
            className: styles.maxHeight500,
          }}
          className={styles.minWidth150}
        >
          {PRIORITY_OPTIONS.map((item: number) => (
            <Option key={item} value={String(item)} text={`P${item}`}>
              <span>P{item}</span>
            </Option>
          ))}
        </Dropdown>
      </Field>
    )
  },
)

Priority.displayName = 'Priority'
