import { Column, Row, Spacer } from '@copilot-dash/components'
import {
  ColumnKey,
  INewTicketData,
  ISearchTicketOptions,
  SearchTextPrefixType,
  columnKeySchema,
} from '@copilot-dash/domain'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  InteractionTag,
  InteractionTagPrimary,
  InteractionTagSecondary,
  Spinner,
  TagGroup,
  Text,
  tokens,
} from '@fluentui/react-components'
import { FilterRegular, LinkDismissRegular } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { compact } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { AGGridTable } from '../../../../../../../components/AGGridTable/AGGridTable'
import { ColumnSettingButton } from '../../../../../../../components/Button/ColumnSettingButton'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { SearchRoute } from '../../../../../../../router'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'

import { LoaderComponent } from '../../../../loader/LoaderComponent'

interface IProps {
  readonly scenario: string
  readonly searchParams: Partial<ISearchTicketOptions>
  readonly expectedTicketCount: number
}

export const CustomSearchTickets = memo(function CustomSearchTickets(props: IProps) {
  const { scenario, searchParams, expectedTicketCount } = props

  const actions = useSearchScreenActions()
  const selectedRowId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const columns = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const setColumns = app.settings.feedbackInsightsTopIssueDetailsColumns.set

  const handleColumnsChange = (columns: string[]) => {
    const movedColumns = columns
      .map((column) => columnKeySchema.safeParse(column))
      .filter((result) => result.success)
      .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
    setColumns(movedColumns)
  }

  const colDefs = useMemo(() => {
    const defaultColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      columns.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )
    return newSelectedColumns
  }, [columns])

  const args = SearchRoute.navigator.useArgsOptional()
  const issueId = args?.issueId
  const batchId = args?.issueBatchId

  const [tickets, setTickets] = useState<INewTicketData[]>([])
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const doubleConfirmRemoveTicketRef = useRef<INewTicketData | undefined>()
  const [removing, setRemoving] = useState(false)
  const handleRemoveTicket = useCallback(
    (feedbackId: string) => {
      if (batchId && issueId && feedbackId) {
        setRemoving(true)
        app.store.actions
          .removeTicketFromTopIssue(batchId, issueId, feedbackId)
          .then(() => {
            setTickets((pre) => pre.filter((item) => item.ticketId !== feedbackId))
          })
          .finally(() => {
            setRemoving(false)
            setDialogOpen(false)
          })
      }
    },
    [batchId, issueId],
  )

  const rowHoverStickySlot = useCallback(
    (rowData: INewTicketData) => (
      <DialogTrigger disableButtonEnhancement>
        <Button
          onClick={() => {
            doubleConfirmRemoveTicketRef.current = rowData
          }}
          icon={<LinkDismissRegular />}
          style={{ width: 'max-content' }}
          appearance="transparent"
        >
          Remove from Issue
        </Button>
      </DialogTrigger>
    ),
    [],
  )

  const onClose = useCallback(() => {
    actions.updateFeedbackInsightsIssueDetailDrillDownInfo(undefined, undefined)
  }, [actions])

  const searchParamsRef = useRef(searchParams)
  searchParamsRef.current = searchParams
  useEffect(() => {
    const curArgs = SearchRoute.navigator.getArgsOptional()
    if (!curArgs) return
    setLoading(true)
    app.store.actions
      .searchTickets({
        product: curArgs.product,
        clusteringIssueId: curArgs.issueId,
        batchId: curArgs.issueBatchId,
        count: expectedTicketCount,
        offset: 0,
        isAIF: false,
        teamId: undefined,
        ticketStatus: 'TopIssue',
        ...searchParamsRef.current,
      })
      .then((result) => {
        setTickets(result.tickets)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [expectedTicketCount, scenario])

  useEffect(() => {
    return () => {
      actions.updateBatchOperationPanelVisibility(false)
      actions.setSelectedTickets([])
    }
  }, [actions])

  return (
    <Dialog
      modalType="alert"
      open={dialogOpen}
      onOpenChange={(_, data) => {
        setDialogOpen(data.open)
        if (!data.open) {
          doubleConfirmRemoveTicketRef.current = undefined
        }
      }}
    >
      <Column style={{ width: '100%' }} fill>
        <Row vAlign="center" style={{ marginBottom: '24px' }}>
          <FilterRegular fontSize={20} />
          <Spacer width="12px" />
          <TagGroup onDismiss={onClose}>
            <InteractionTag shape="circular" style={{ border: `1px solid ${tokens.colorNeutralStroke1}` }}>
              <InteractionTagPrimary hasSecondaryAction>{scenario}</InteractionTagPrimary>
              <InteractionTagSecondary aria-label="remove" style={{ borderLeft: 'none' }} />
            </InteractionTag>
          </TagGroup>
          <Spacer />
          <ColumnSettingButton placeholder="Columns" onClickButton={actions.handleColumnSettingButtonClick} />
        </Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          <Row fill style={{ width: '100%', minHeight: '400px' }}>
            <AGGridTable
              rowData={tickets}
              getRowId={(data) => data.data.ticketId}
              columnDefs={colDefs}
              onRowClicked={actions.rowClick}
              focusedRowId={selectedRowId}
              stickySuffixRenderer={rowHoverStickySlot}
              handleColumnsChange={handleColumnsChange}
              rowSelectionMode="multiRow"
              onSelectionChange={(selectedItems: INewTicketData[]) => {
                actions.setSelectedTickets(selectedItems)
                actions.updateBatchOperationPanelVisibility(selectedItems.length > 0)
              }}
            />
          </Row>
        )}
      </Column>
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Text size={400} weight="semibold">
              Are you sure to remove the DSAT from the current issue clustering?
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              disabled={removing}
              icon={removing ? <Spinner size="extra-tiny" /> : null}
              onClick={() => {
                doubleConfirmRemoveTicketRef.current &&
                  handleRemoveTicket(doubleConfirmRemoveTicketRef.current.ticketId)
              }}
            >
              Remove
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
})
