import { Column } from '@copilot-dash/components'
import { useCallback } from 'react'
import { TicketCallFlow } from '../../../../../components/TicketCallFlow/TicketCallFlow'
import { TicketScreenMainTab, TicketScreenTelemetryTab } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'

export function TicketOutlineCallFlow() {
  const actions = TicketScreenStore.useActions()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)
  const selectedScopeId = TicketScreenStore.use((state) => state.selectedScopeId)

  const handleSelectTurn = useCallback(
    (turnId: string, scopeId?: string) => {
      if (scopeId) {
        actions.selectTurnScope(turnId, scopeId)
        actions.selectMainTab(TicketScreenMainTab.Telemetry)
        actions.selectTelemetryTab(TicketScreenTelemetryTab.Metrics)
      } else {
        actions.selectTurn(turnId)
      }
    },
    [actions],
  )

  return (
    <Column>
      <TicketCallFlow
        ticketId={ticketId}
        selectedMessageId={selectedTurnId}
        selectedEventId={selectedScopeId}
        onSelect={handleSelectTurn}
      />
    </Column>
  )
}
