import { Times } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export async function assertTicket3sOfflineCooked(context: IDashStoreContext, ticketId: string): Promise<void> {
  const raw = await getRawV2Ticket(context, ticketId).promise
  const createTime = raw.userFeedback.createdDateTime

  if (!createTime) {
    return
  }

  if (Times.isWithinLastXHours(createTime, 6)) {
    throw TicketError.create('No3sOfflineDueTo6Hours', { ticketId, ticketCreationTime: createTime })
  }

  if (Times.isWithinLastXHours(createTime, 48)) {
    throw TicketError.create('No3sOfflineDueTo48Hours', { ticketId, ticketCreationTime: createTime })
  }
}
