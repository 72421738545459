import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping, WorkItemStatus } from '@copilot-dash/domain'
import { mergeClasses, Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo, useMemo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'
import { getTicketStepStageByFields, mapADOStateToCopilotDashState } from '../TicketActionForm/utils'

export const ColumnConfigStatus: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Status],
  field: String(ColumnKey.Status) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderStatusV2 item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.Status]}</TableCellLayoutWithStyles>,
  },
  flex: 1,
  minWidth: 110,
  sortable: false,
  resizable: true,
}

const RenderStatus = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else {
    let text: string

    const copilotDashState = mapADOStateToCopilotDashState(item.status, item.reasoning)
    if (item.status === 'Closed') {
      text = copilotDashState
    } else {
      text = getTicketStepStageByFields(copilotDashState, item.rootCauseList ?? [], item.teamId)
    }
    return (
      <FloatingTooltip>
        <FloatingTooltipTrigger>
          <Text
            className={mergeClasses(
              styles.text,
              text.startsWith('Closed') && styles.green,
              text === 'Team Assigned' && styles.yellow,
              text === 'Root Caused' && styles.blue,
            )}
          >
            {text}
          </Text>
        </FloatingTooltipTrigger>
        <FloatingTooltipContent>
          <Text className={styles.tooltipContent}>{text}</Text>
        </FloatingTooltipContent>
      </FloatingTooltip>
    )
  }
})

RenderStatus.displayName = 'RenderStatus'

const RenderStatusV2 = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  const text = useMemo(() => {
    switch (item.workItemStatus) {
      case WorkItemStatus.Untriaged:
        return 'Untriaged'
      case WorkItemStatus.TeamAssigned:
        return 'Team Assigned'
      case WorkItemStatus.RootCaused:
        return 'Root Caused'
      case WorkItemStatus.ClosedByDesign:
        return 'Closed - By Design'
      case WorkItemStatus.ClosedFixed:
        return 'Closed - Fixed'
      case WorkItemStatus.ClosedNotActionable:
        return 'Closed - Not Actionable'
      default:
        return ''
    }
  }, [item.workItemStatus])

  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  }
  return (
    <FloatingTooltip>
      <FloatingTooltipTrigger>
        <Text
          className={mergeClasses(
            styles.text,
            text.startsWith('Closed') && styles.green,
            text === 'Team Assigned' && styles.yellow,
            text === 'Root Caused' && styles.blue,
          )}
        >
          {text}
        </Text>
      </FloatingTooltipTrigger>
      <FloatingTooltipContent>
        <Text className={styles.tooltipContent}>{text}</Text>
      </FloatingTooltipContent>
    </FloatingTooltip>
  )
})

RenderStatusV2.displayName = 'RenderStatusV2'
