import { ApiBizPerfLogItemV2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoBizPerfLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getPerformanceLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoBizPerfLogItem[]> {
  return context.getOrFetch<IKustoBizPerfLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.perfLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.perfLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.performanceLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asPerformanceLog()
        return results.map(convertToItem)
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.BizChatPerfLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.BizChatPerfLog, sourceItem })
    },
  })

  function convertToItem(raw: ApiBizPerfLogItemV2): IKustoBizPerfLogItem {
    return {
      chatFCFR: raw.chatFCFR,
      perfTraceFile: raw.PerfTraceFile,
      raw,
    }
  }
}
