import { z } from 'zod'

export interface ApiClusterBaseResponseData {
  result: boolean
  message?: string
}

export const apiClusterBaseResponseSchema = z.object({
  result: z.boolean(),
  message: z.string().optional(),
})
