import { Row } from '@copilot-dash/components'
import { makeStyles, Text } from '@fluentui/react-components'
import { TicketLinkCopyButton } from '../../../../components/TicketTableColumnConfigs/TicketLinkCopyButton'
import { RouteLink, TicketRoute } from '../../../../router'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicketID() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <Row vAlign="center">
      <Text weight="semibold" className={styles.margin}>
        ID:
      </Text>
      <RouteLink path={TicketRoute.navigator.generatePath({ id: ticketId })} openInNewTab type="link">
        <Text wrap={false}>{ticketId}</Text>
      </RouteLink>
      <TicketLinkCopyButton ticketId={ticketId} />
    </Row>
  )
}
const useStyles = makeStyles({
  margin: {
    marginRight: '8px',
  },
})
