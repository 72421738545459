import { ApiPublishClusterRequestResult } from '@copilot-dash/api'
import { IClusterBaseResultData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function publishClusterResult(
  context: IDashStoreContext,
  data: ApiPublishClusterRequestResult,
): Promise<IClusterBaseResultData> {
  const response = await context.api.automation.publishClusteringResult(data)
  return { ...response }
}
