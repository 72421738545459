import { Column, Row } from '@copilot-dash/components'
import { Link, makeStyles, Text, tokens } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { InsightsTag } from './InsightsTag'
import { useSearchScreenActions } from '../../../store'

interface IProps {
  tenantName: string
  tenantId: string
  enableTooltip?: boolean
  isShowViewDSATs?: boolean
}

export const CAPTenantTag: FC<IProps> = ({ tenantName, tenantId, enableTooltip = false, isShowViewDSATs = false }) => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const truncatedTag = useMemo(() => {
    return (
      <Row>
        <Text wrap={false} truncate className={styles.tenantName}>
          {tenantName}
        </Text>
      </Row>
    )
  }, [styles.tenantName, tenantName])

  const tooltipContent = useMemo(() => {
    return (
      <Column style={{ padding: '8px 6px' }}>
        <Text truncate size={300} weight="semibold">
          {tenantName}
        </Text>
        {isShowViewDSATs && (
          <Row>
            <Link
              onClick={() => {
                actions.updateFeedbackInsightsIssueDetailDrillDownInfo(`Tenant: ${tenantName}`, {
                  tenantIds: [tenantId],
                })
              }}
            >
              View DSATs
            </Link>
          </Row>
        )}
      </Column>
    )
  }, [tenantName, actions, tenantId, isShowViewDSATs])

  return (
    <InsightsTag
      isTagClearable={false}
      tagKey={'CAPTenant'}
      toolTipContentValue={enableTooltip ? tooltipContent : undefined}
      truncatedTagValue={truncatedTag}
      className={styles.tagStyle}
    />
  )
}

const useStyles = makeStyles({
  tenantName: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },
  tagStyle: {
    height: '26px',
    ':hover': {
      backgroundColor: '#CACCFF',
    },
  },
})
