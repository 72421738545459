import { Column, Row, Spacer } from '@copilot-dash/components'
import { debounce } from 'lodash'
import { FC, memo, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Priority } from './Priority'
import { TitleInput } from './TitleInput'
import { ROOT_CAUSE_PRIORITY_OPTIONS } from '../../../../../../components/RootCauseCreateDialog/fields/Priority'

export enum FieldNames {
  ID = 'id',
  ISSUETITLE = 'issuetitle',
  PRIORITY = 'priority',
}

export interface IFormData {
  [FieldNames.ID]: string
  [FieldNames.ISSUETITLE]: string
  [FieldNames.PRIORITY]: number
}

interface IIssueEditFormProps {
  afterValidate: (isValid: boolean, data: IFormData) => void
  defaultValue?: Partial<IFormData>
  fields?: FieldNames[]
}

const DEFAULT_VALUE: IFormData = {
  [FieldNames.ID]: '',
  [FieldNames.ISSUETITLE]: '',
  [FieldNames.PRIORITY]: ROOT_CAUSE_PRIORITY_OPTIONS[0],
}

export const IssueEditForm: FC<IIssueEditFormProps> = memo(
  ({ afterValidate, defaultValue = DEFAULT_VALUE, fields = [FieldNames.PRIORITY, FieldNames.ISSUETITLE] }) => {
    const { control, trigger, watch } = useForm({
      mode: 'onChange',
      defaultValues: defaultValue,
    })

    const debouncedValidate = useMemo(() => {
      return debounce(async (value: IFormData) => {
        const isValid = await trigger()
        afterValidate(isValid, value)
      }, 200)
    }, [afterValidate, trigger])

    useEffect(() => {
      const subscription = watch((value, { type }) => {
        if (type === 'change') {
          debouncedValidate(value as IFormData)
        }
      })
      return () => subscription.unsubscribe()
    }, [debouncedValidate, watch])

    return (
      <Column>
        {fields.includes(FieldNames.ISSUETITLE) && (
          <Controller
            name={FieldNames.ISSUETITLE}
            control={control}
            rules={{ required: 'Required' }}
            render={({ field, fieldState }) => {
              return (
                <TitleInput
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter issue title"
                  fieldProps={{
                    validationMessage: fieldState.error?.message,
                  }}
                />
              )
            }}
          />
        )}

        <Spacer height={26} />

        <Row vAlign="start">
          {fields.includes(FieldNames.PRIORITY) && (
            <>
              <Controller
                name={FieldNames.PRIORITY}
                control={control}
                rules={{ required: 'Required' }}
                render={({ field, fieldState }) => {
                  return (
                    <Priority
                      orientation="vertical"
                      value={field.value}
                      onChange={field.onChange}
                      fieldProps={{
                        validationMessage: fieldState.error?.message,
                      }}
                    />
                  )
                }}
              />
            </>
          )}
        </Row>
        <Spacer height={60} />
      </Column>
    )
  },
)

IssueEditForm.displayName = 'IssueEditForm'
