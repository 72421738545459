import { createContext, useContext } from 'react'

import { IClusterScreenStore } from './IClusterScreenStore'
import { IClusterScreenActions } from './IClusterScreenStoreActions'
import { IClusterScreenState } from './IClusterScreenStoreState'

export const ClusterScreenStoreContext = createContext<{
  readonly store: IClusterScreenStore
  readonly actions: IClusterScreenActions
} | null>(null)

function useClusterScreenContext() {
  const value = useContext(ClusterScreenStoreContext)
  if (value === null) {
    throw new Error('useClusterScreenContext must be used within a ClusterScreenStoreProvider')
  }

  return value
}

export function useClusterScreenState<T>(selector: (state: IClusterScreenState) => T): T {
  return useClusterScreenContext().store.use(selector)
}

export function useClusterScreenActions(): IClusterScreenActions {
  return useClusterScreenContext().actions
}
