import { Logger } from '@copilot-dash/logger'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { z } from 'zod'
import { DashApiOptions } from '../../DashApiOptions'

const BASE_URL_TEST = 'https://feedbackautomationv2-test.microsoft.com'
const BASE_URL_SDF = 'https://feedbackautomationv2-sdf.microsoft.com'
const BASE_URL_MSIT = 'https://feedbackautomationv2.microsoft.com'

export class AutomationNotificationService {
  readonly connection: HubConnection
  private readonly baseUrl: string

  constructor(options: DashApiOptions, hubName: string) {
    this.baseUrl = this.getBaseUrl(options)
    this.connection = this.buildConnection(`${hubName}?userId=` + options.getAccountId())
  }

  buildConnection(hubSubUrl: string): HubConnection {
    const hubUrl = this.baseUrl + `/${hubSubUrl}`
    return new HubConnectionBuilder()
      .withUrl(hubUrl, { withCredentials: true })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build()
  }

  async startConnection(): Promise<void> {
    try {
      Logger.trace.info(
        'start to connect SignalR,current connection state:',
        this.connection?.state,
        this.connection?.connectionId,
      )
      if (this.connection.state !== 'Connected') {
        await this.connection.start()
        Logger.trace.info('SignalR Connected', this.connection.connectionId)
      }
    } catch (error) {
      Logger.trace.error('Error starting SignalR connection:', error)
      setTimeout(() => this.startConnection(), 5000) // restart connection after 5 seconds
    }
  }

  async stopConnection(): Promise<void> {
    try {
      await this.connection.stop()
      Logger.trace.info('SignalR Disconnected')
    } catch (error) {
      Logger.trace.error('Error stopping SignalR connection:', error)
    }
  }

  private getBaseUrl(options: DashApiOptions): string {
    const endpointUrl = options.getATServerEndpointUrl()
    if (endpointUrl) {
      const url = z.string().url().safeParse(endpointUrl)
      if (url.success) {
        return endpointUrl
      }
    }

    const ring = options.getRing()
    switch (ring) {
      case 'dev':
      case 'test':
        return BASE_URL_TEST
      case 'sdf':
        return BASE_URL_SDF
      case 'msit':
      case 'unknown':
        return BASE_URL_MSIT
    }
  }
}
