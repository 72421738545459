import { Column, Row } from '@copilot-dash/components'
import { makeStyles, mergeClasses, Text, tokens } from '@fluentui/react-components'
import { ReactNode } from 'react'

interface IProps {
  readonly icon?: ReactNode
  readonly title: string
  readonly subtitle?: string
  readonly active?: boolean
  readonly onActive?: () => void
}

export function OutlineItem({ icon = '📄', title, subtitle, active, onActive }: IProps) {
  const styles = useStyles()

  return (
    <Row
      className={mergeClasses(
        styles.root,
        onActive && styles.rootActivatable,
        active ? styles.rootActive : styles.rootInactive,
      )}
      gap="gap.smaller"
    >
      <Row className={styles.left} vAlign="center">
        {icon}
      </Row>
      <Column className={styles.right} onClick={onActive} vAlign="center" title={title}>
        <Text className={styles.title} block truncate wrap={false}>
          {title}
        </Text>
        {subtitle && (
          <Text className={styles.subtitle} block truncate wrap={false}>
            {subtitle}
          </Text>
        )}
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '4px 16px',
    transition: 'background-color 0.2s ease-in-out',
    minHeight: '40px',
  },
  rootActivatable: {
    cursor: 'pointer',
  },
  rootActive: {
    backgroundColor: tokens.colorSubtleBackgroundSelected,
  },
  rootInactive: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground3Hover,
    },
  },
  left: {
    flex: '0 0 auto',
    fontSize: '16px',
  },
  right: {
    flex: 1,
    overflow: 'hidden',
  },
  title: {
    color: tokens.colorNeutralForeground2,
  },
  subtitle: {
    color: tokens.colorNeutralForeground4,
    fontSize: '0.8em',
  },
})
