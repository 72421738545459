import { Column, Row, Spacer } from '@copilot-dash/components'
import { IQuery, CopilotDashPath, getProductNameById } from '@copilot-dash/domain'
import { Text, makeStyles, tokens, Divider, shorthands } from '@fluentui/react-components'
import { QueryCopyButton } from './QueryCopyButton'
import { QueryMoreActionButton } from './QueryMoreActionButton'
import { RouteLink, SearchRoute, TeamRoute } from '../../../../router'
import { TimeView } from '../../../Time/TimeView'
import { UserProfile } from '../../../User/UserProfile'

interface IProps {
  readonly query: IQuery
}

export function QueryRowInPanel({ query }: IProps) {
  const styles = useStyles()

  const link =
    query.copilotDashPath === CopilotDashPath.search
      ? SearchRoute.navigator.generatePathString({
          queryId: query.queryId,
          product: getProductNameById(query.productId) || 'M365Chat',
        })
      : TeamRoute.navigator.generatePathString({
          queryId: query.queryId,
          product: getProductNameById(query.productId) || 'M365Chat',
        })

  const onClickLink = () => {
    Logger.telemetry.trackEvent('SavedQueries/Navigate', {
      isTeamShared: query.isTeamShared,
      queryId: query.queryId,
      productId: Number(query.productId),
      teamId: Number(query.teamId),
    })
  }

  return (
    <RouteLink path={link} onClick={onClickLink} type="button">
      <Column fill className={styles.root}>
        <Spacer height="8px" />
        <Row fill>
          <Text className={styles.title} title={query.title}>
            {query.title}
          </Text>
          <Spacer />
          <QueryCopyButton query={query} className={styles.queriesActionIcon} />
          <QueryMoreActionButton query={query} className={styles.queriesActionIcon} />
        </Row>
        <Spacer height="8px" />
        <Row className={styles.row}>
          <UserProfile
            userId={query.lastModifiedBy}
            fallback="Unknown User"
            customUserPhotoClassName={styles.customUserPhoto}
            customUserDisplayNameClassName={styles.customUserName}
            fill={false}
          />
          <Divider vertical />
          <Text className={styles.text} align="center">
            <TimeView value={query.lastModifiedTime} format="MM/DD/YYYY" showTooltip={false} />
          </Text>
        </Row>
        <Spacer height="8px" />
        <Divider />
      </Column>
    </RouteLink>
  )
}

const useStyles = makeStyles({
  root: {
    '&:hover .queriesActionIcon': {
      color: tokens.colorNeutralForeground2,
    },
  },
  queriesActionIcon: {
    color: 'transparent',
  },
  customUserPhoto: {
    width: '18px',
    height: '18px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase200,
    maxWidth: '180px',
    width: '180px',
    color: tokens.colorNeutralStrokeAccessible,
  },
  text: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralStrokeAccessible,
    alignItems: 'center',
    display: 'flex',
  },
  teamName: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralStrokeAccessible,
    alignItems: 'center',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    ...shorthands.overflow('hidden'),
  },
  title: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflowWrap: 'break-word',
    ...shorthands.overflow('hidden'),
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
  },
  row: {
    flexWrap: 'wrap',
  },
})
