import { createContext, useContext } from 'react'

export const TabItemContext = createContext<string | undefined>(undefined)

export const useTabItemId = (): string => {
  const id = useContext(TabItemContext)
  if (id === undefined) {
    throw new Error('TabItemContext is not defined')
  }

  return id
}
