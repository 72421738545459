import { uuid } from '@copilot-dash/core'
import { Logger } from '@copilot-dash/logger'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { buildMemoryStorage, CacheProperties, Header, setupCache } from 'axios-cache-interceptor'
import { ZodType } from 'zod'
import { ApiClientContext } from './ApiClientContext'
import { ApiError } from './ApiError'
import { ApiErrorUtils } from './ApiErrorUtils'
import { parseToStandardDate } from '../utils'
import { serializeParamsToString } from '../utils/serializeParamsToString'

export type ApiCacheOptions = false | Partial<CacheProperties>
export type ApiRequestConfig<T = unknown> = AxiosRequestConfig & {
  readonly schema: ZodType<T>
  readonly cache?: ApiCacheOptions
}

export abstract class ApiClient {
  protected readonly context: ApiClientContext
  protected readonly delegate: AxiosInstance

  protected constructor(context: ApiClientContext) {
    this.context = context
    this.delegate = axios.create(context.config)

    // 1. Init params serializer
    this.delegate.defaults.paramsSerializer = (params) => {
      return serializeParamsToString(params)
    }

    // 2. Init request interceptor
    this.delegate.interceptors.request.use(async (config) => {
      await this.onRequest?.(config)
      return config
    })

    // 3. Init response cache
    this.delegate.interceptors.response.use(async (response) => {
      response.headers[Header.CacheControl] = ''
      response.headers[Header.Expires] = ''

      return response
    })

    // 4. Init response interceptor
    this.delegate.interceptors.response.use(async (response) => {
      await this.onResponse?.(response)
      return response
    })

    // 5. Init cache
    // https://axios-cache-interceptor.js.org/guide/getting-started
    setupCache(this.delegate, {
      storage: buildMemoryStorage(),
      ttl: 0,
    })
  }

  async get<T>(url: string, config: ApiRequestConfig<T> & { data?: never }): Promise<T> {
    return this.requestData({
      ...config,
      url,
      method: 'GET',
    })
  }

  async post<T>(url: string, config: ApiRequestConfig<T>): Promise<T> {
    return this.requestData({
      ...config,
      url,
      method: 'POST',
    })
  }

  async put<T>(url: string, config: ApiRequestConfig<T> & { cache?: never }): Promise<T> {
    return this.requestData({
      ...config,
      url,
      method: 'PUT',
    })
  }

  async patch<T>(url: string, config: ApiRequestConfig<T> & { cache?: never }): Promise<T> {
    return this.requestData({
      ...config,
      url,
      method: 'PATCH',
    })
  }

  async delete<T>(url: string, config: ApiRequestConfig<T> & { cache?: never }): Promise<T> {
    return this.requestData({
      ...config,
      url,
      method: 'DELETE',
    })
  }

  protected onRequest?(config: InternalAxiosRequestConfig<unknown>): Promise<void>
  protected onResponse?(response: AxiosResponse<unknown>): Promise<void>

  private async requestData<T>(config: ApiRequestConfig<T>): Promise<T> {
    config.headers ??= {}
    config.headers['X-Session-Id'] ??= this.context.getSessionId()
    config.headers['X-Correlation-Id'] ??= uuid()
    config.headers['X-Trace-Id'] ??= uuid()

    try {
      // Request
      const response = await this.request(config)
      let data: unknown = response.data
      try {
        if (!config.responseType || config.responseType === 'json') {
          data = parseToStandardDate(response.data)
        }
      } catch (error) {
        throw ApiErrorUtils.onResponseError(this.context, config, response, error)
      }

      // Response validate
      try {
        // Note: Zod only parses the defined properties, but we need to return the whole object
        config.schema.parse(data)
        return data as T
      } catch (error) {
        throw ApiErrorUtils.onResponseError(this.context, config, response, error)
      }
    } catch (error) {
      this.printError(error)

      throw error
    }
  }

  private async request(config: ApiRequestConfig): Promise<AxiosResponse> {
    config.headers ??= {}
    config.headers['X-Session-Id'] ??= this.context.getSessionId()
    config.headers['X-Correlation-Id'] ??= uuid()
    config.headers['X-Trace-Id'] ??= uuid()

    let response: AxiosResponse
    try {
      response = await this.delegate(config)
    } catch (error) {
      throw ApiErrorUtils.onRequestError(this.context, config, error)
    }

    if (response.status < 200 || response.status >= 400) {
      throw ApiErrorUtils.onResponseError(this.context, config, response, undefined)
    }

    return response
  }

  private printError(error: unknown): void {
    if (error instanceof ApiError) {
      Logger.trace.error('Failed to call API.', {
        message: error.message,
        endpoint: error.data.endpoint,
        baseUrl: error.data.baseUrl,
        statusCode: error.data.statusCode,
        statusMessage: error.data.statusMessage,
        errorCode: error.data.errorCode,
        errorMessages: error.data.errorMessages,
      })
    } else {
      Logger.trace.error('Failed to call API. An unknown error occurred.', String(error))
    }
  }
}
