import { Row } from '@copilot-dash/components'
import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { TicketScreenBarTicketADOLink } from './TicketScreenBarTicketADOLink'
import { TicketScreenBarTicketDate } from './TicketScreenBarTicketDate'
import { TicketScreenBarTicketDrawerTrigger } from './TicketScreenBarTicketDrawerTrigger'
import { TicketScreenBarTicketEmotion } from './TicketScreenBarTicketEmotion'
import { TicketScreenBarTicketID } from './TicketScreenBarTicketID'
import { TicketScreenBarTicketTenant } from './TicketScreenBarTicketTenant'
import { TicketScreenDrawerType } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicket() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const snapshot = application.store.use.getTicketMetadata(ticketId)

  switch (snapshot.status) {
    case 'waiting':
      return (
        <Row vAlign="center">
          <Skeleton className={styles.skeleton}>
            <SkeletonItem />
          </Skeleton>
        </Row>
      )
    case 'error':
      return (
        <Row vAlign="center">
          <TicketScreenBarTicketID />
        </Row>
      )
    case 'done':
      return (
        <Row vAlign="center" gap="gap.smaller" hAlign="end" className={styles.row}>
          <Row className={styles.informationRow} gap="gap.smaller" vAlign="center" hAlign="end">
            <TicketScreenBarTicketEmotion emotion={snapshot.data.emotionType} />
            <TicketScreenBarTicketID />
            <TicketScreenBarTicketDate datetime={snapshot.data.createdDateTime} />
            <TicketScreenBarTicketTenant tenantId={snapshot.data.tenantId} tenantName={snapshot.data.tenantName} />
            <TicketScreenBarTicketADOLink />
          </Row>
          <Row gap="gap.smaller" vAlign="center">
            <TicketScreenBarTicketDrawerTrigger type={TicketScreenDrawerType.TicketContext} />
            <TicketScreenBarTicketDrawerTrigger type={TicketScreenDrawerType.DiscussionPanel} />
            <TicketScreenBarTicketDrawerTrigger type={TicketScreenDrawerType.ActivityHistory} />
          </Row>
        </Row>
      )
  }
}

const useStyles = makeStyles({
  skeleton: {
    minWidth: '240px',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'row',
    gap: tokens.spacingHorizontalM,
  },
  row: {
    width: '90%',
  },
  informationRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})
