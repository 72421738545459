import { ProductIds } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketEnvironment } from '../actions-ticket/getTicketEnvironment'

/**
 * Doc:
 * https://o365exchange.visualstudio.com/O365%20Core/_workitems/edit/5533399
 */
export async function assertTicketApplicableForUserpSearch(
  context: IDashStoreContext,
  ticketId: string,
  scenario: 'query' | 'source',
): Promise<void> {
  const environment = await getTicketEnvironment(context, ticketId).promise
  if (environment.productId !== ProductIds.USERP) {
    return
  }

  if (environment.endpoint?.scenario === 'USERP Copilot') {
    switch (scenario) {
      case 'query':
        throw TicketError.create('NoUserpSearchQueryForNotApplicable', { ticketId })
      case 'source':
        throw TicketError.create('NoUserpSearchDataForNotApplicable', { ticketId })
    }
  }
}
