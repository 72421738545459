import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { TicketOutlineCallFlow } from './call-flow/TicketOutlineCallFlow'
import { TicketOutlineConversation } from './conversation/TicketOutlineConversation'
import { AnimatedTabBody } from '../../../../components/AnimatedTabBody/AnimatedTabBody'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { TicketScreenOutlineTab } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketOutlineBody() {
  const styles = useStyles()

  const tabs = useMemo(() => {
    return Object.values(TicketScreenOutlineTab)
  }, [])

  const selectedTab = TicketScreenStore.use((state) => state.selectedOutlineTab)
  const selectedIndex = tabs.indexOf(selectedTab)

  return (
    <AnimatedTabBody tabs={tabs} activeTabIndex={selectedIndex}>
      {(tab) => {
        switch (tab) {
          case TicketScreenOutlineTab.Conversation:
            return (
              <Scrollbar className={styles.scrollbar}>
                <TicketOutlineConversation />
              </Scrollbar>
            )
          case TicketScreenOutlineTab.CallFlow:
            return (
              <Scrollbar className={styles.scrollbar}>
                <TicketOutlineCallFlow />
              </Scrollbar>
            )
        }
      }}
    </AnimatedTabBody>
  )
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  scrollbar: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
})
