import { AutomationApi } from './api/Automation/AutomationApi'
import { CopilotDashApi } from './api/copilot-dash/CopilotDashApi'
import { LogCollectorApi } from './api/LogCollector/LogCollectorApi'
import { MicrosoftGraphApi } from './api/MicrosoftGraph/MicrosoftGraphApi'
import { DashApiOptions } from './DashApiOptions'

export class DashApi {
  readonly copilotDash: CopilotDashApi
  readonly microsoftGraph: MicrosoftGraphApi
  readonly logCollector: LogCollectorApi
  readonly automation: AutomationApi

  constructor(options: DashApiOptions) {
    this.copilotDash = new CopilotDashApi(options)
    this.microsoftGraph = new MicrosoftGraphApi(options)
    this.logCollector = new LogCollectorApi(options)
    this.automation = new AutomationApi(options)
  }
}
