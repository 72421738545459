import { last } from 'lodash'
import { getCurrentRouteParams } from './utils/getCurrentRouteParams'
import { IRouteContext } from '../../types/IRouteContext'

export function getArgs<TArgs>(context: IRouteContext<TArgs>): TArgs {
  const current = last(app.router.state.matches)
  if (!current) {
    throw new Error('Unable to call "getArgs()" outside of a router context. [routeId: ${routeId}]')
  }

  if (current.route.id !== context.id) {
    throw new Error(
      `Unable to call "getArgs()" from a different route. [expected: ${context.id}], [actual: ${current.route.id}]`,
    )
  }

  const currentRouteParams = getCurrentRouteParams()
  return context.mapper.mapToArgs(currentRouteParams)
}
