import { Column, Row } from '@copilot-dash/components'
import { ITicketTurnConversation } from '@copilot-dash/domain'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { UserMessageViewAvatar } from './UserMessageViewAvatar'
import { usePrivacyModeClass } from '../../../../Privacy/usePrivacyModeClass'
import { MessageContent } from '../common/MessageContent'
import { MessageLabel } from '../common/MessageLabel'

interface IProps {
  readonly data: ITicketTurnConversation
}

export function UserMessageView({ data }: IProps) {
  const privacyModeClass = usePrivacyModeClass()
  const styles = useStyles()
  if (!data.utterance) {
    return null
  }

  return (
    <Row className={styles.root}>
      <UserMessageViewAvatar />
      <Column className={mergeClasses(styles.content, privacyModeClass)} hAlign="stretch">
        <MessageContent content={data.utterance.content} />
        <MessageLabel data={data} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
  },
})
