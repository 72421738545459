import { useMemo } from 'react'

const LINE_HEIGHT = 19
const LINE_HEIGHT_FIXED = 8

export function useMonacoHeight(value: string): number {
  return useMemo(() => {
    const lines = value.split('\n').length
    return LINE_HEIGHT * lines + LINE_HEIGHT_FIXED
  }, [value])
}
