import { Column, Row } from '@copilot-dash/components'
import { Link, makeStyles, shorthands, Text, tokens, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { InsightsTag } from './InsightsTag'
import { UserProfile } from '../../../../../components/User/UserProfile'
import { useSearchScreenActions } from '../../../store'

interface IProps {
  userId: string
  enableTooltip?: boolean
  className?: string
  isShowViewDSATs?: boolean
}

export const VIPUserTag: FC<IProps> = ({ userId, enableTooltip = false, className, isShowViewDSATs = false }) => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const truncatedTag = useMemo(() => {
    return (
      <UserProfile
        userId={userId}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={mergeClasses(styles.customUserName, className)}
        isEnableDisplayNameToolTip={enableTooltip}
        tooltipContent={(user) => (
          <Column style={{ paddingRight: '16px' }}>
            <Row>
              <Text weight="semibold">{user.displayName}</Text>
            </Row>
            {isShowViewDSATs && (
              <Row>
                <Link
                  style={{ fontSize: '14px', fontWeight: 400 }}
                  onClick={() => {
                    actions.updateFeedbackInsightsIssueDetailDrillDownInfo(`User: ${user.displayName}`, {
                      userId: [userId],
                    })
                  }}
                >
                  View DSATs
                </Link>
              </Row>
            )}
          </Column>
        )}
      />
    )
  }, [actions, enableTooltip, styles.customUserName, styles.customUserPhoto, userId, className, isShowViewDSATs])

  return (
    <Row>
      <InsightsTag
        isTagClearable={false}
        tagKey={'VIPUser'}
        truncatedTagValue={truncatedTag}
        className={styles.tagStyle}
      />
    </Row>
  )
}

const useStyles = makeStyles({
  customUserPhoto: {
    width: '18px',
    height: '18px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    maxWidth: '70px',
  },
  tagStyle: {
    //No corresponding color found in fluentui
    backgroundColor: '#F1FAFF',
    ':hover': {
      backgroundColor: '#CDEDFF',
    },
    ':active': {
      backgroundColor: '#CDEDFF',
    },
    height: '26px',
  },
})
