const KEY_RESOURCE_ERRORS = 'copilot_dash_resource_errors'

export function initAppResourceErrors(): void {
  const errors = localStorage.getItem(KEY_RESOURCE_ERRORS)
  if (errors) {
    Logger.trace.error('Failed to load javascript or css resources:', errors)
  }

  localStorage.removeItem(KEY_RESOURCE_ERRORS)
}
