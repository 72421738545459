import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const messageId = params.messageId

  return `
let MessageId = "${params.messageId}";
let traceId = TuringBotChatMessageEventMDS_NoEUPI
| where MessageId in ("${messageId}")
| project TraceId;
let correlationVectors = TuringBotMonitoredScope_NoEUPI
| where TraceId in (traceId)
| where ScopeName contains 'PolymerLLM' or ScopeName contains 'DeepLeo'
| distinct ServiceCallRequestId;
cluster('modeldqasprodeus2.eastus.kusto.windows.net').database('modeldqaseus2db').LLMApiRequestTracingEvent_Global
| where CorrelationVector in (correlationVectors)
| project PreciseTimeStamp, AppName, CorrelationVector, RequestId, AOAIRequestId, ExceptionType, ExceptionStack, AOAIHttpResponseStatusCode, ResolvedModelName, RequestedModelName, EventMessage, LlmApiProcessingTimeMs, OpenaiProcessingTimeMs, MessageId, Tag, PromptTokenCountBucket, CompletionTokenCountBucket
| sort by PreciseTimeStamp asc  
`
}

export const LLM_WW: IKustoQueryDefinition = {
  database: 'CompliantSydneyLogs',
  connection: 'https://compliantsydney.eastus.kusto.windows.net',
  query,
}
