import { Row, Spacer } from '@copilot-dash/components'
import { Text, tokens, Tooltip } from '@fluentui/react-components'
import { Legend, LegendItem } from './Legend'
import { useStyles } from './RatioProgressBar.styles'

export interface RatioProgressBarProps {
  readonly combinedTotal: number
  readonly minWidthPercentage: number
  readonly items: ProgressItem[]
}

interface ProgressItem extends LegendItem {
  readonly value: number
}

export const RatioProgressBar: React.FC<RatioProgressBarProps> = ({ combinedTotal, minWidthPercentage, items }) => {
  const styles = useStyles()
  const totalValue = items.reduce((sum, item) => sum + item.value, 0)
  const progressBarWidth = Math.max((totalValue / combinedTotal) * 100, minWidthPercentage)
  const firstNonZeroIndex = items.findIndex((item) => item.value > 0)
  const lastNonZeroIndex = items.length - 1 - [...items].reverse().findIndex((item) => item.value > 0)

  return (
    <Row>
      <Row
        style={{
          width: `${progressBarWidth}%`,
        }}
        className={styles.container}
      >
        {items.map((item, index) => {
          const percent = totalValue ? (item.value / totalValue) * 100 : 0

          return (
            <Tooltip
              key={index}
              relationship="label"
              withArrow
              content={
                <Row>
                  <Legend items={[{ color: item.color, label: item.label }]} /> <Spacer width="20px" />
                  <Text>
                    <Text style={{ fontWeight: tokens.fontWeightBold }}>{`${item.value}`}</Text>
                    {` (${percent.toFixed(2)}%)`}
                  </Text>
                </Row>
              }
            >
              <Row
                className="item"
                style={{
                  width: `${percent}%`,
                  backgroundColor: item.color,
                  marginLeft: index === 0 ? '0' : '1px',
                  borderTopLeftRadius: index === firstNonZeroIndex ? '4px' : '0',
                  borderBottomLeftRadius: index === firstNonZeroIndex ? '4px' : '0',
                  borderTopRightRadius: index === lastNonZeroIndex ? '4px' : '0',
                  borderBottomRightRadius: index === lastNonZeroIndex ? '4px' : '0',
                  transition: 'opacity 0.2s',
                }}
              />
            </Tooltip>
          )
        })}
      </Row>
    </Row>
  )
}
