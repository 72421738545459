import { Column } from '@copilot-dash/components'
import { TicketCallFlow } from '@copilot-dash/domain'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useState } from 'react'
import { TurnViewBar } from './bar/TurnViewBar'
import { TurnViewFlow } from './call-flow/TurnViewFlow'
import { TurnViewSkeleton } from './skeleton/TurnViewSkeleton'
import { ErrorView } from '../../Error'
import { TicketCallFlowStore } from '../TicketCallFlowStore'

interface IProps {
  readonly messageId: string
  readonly selectedAppearance?: 'outline' | 'none'
}

export function TurnView({ messageId, selectedAppearance = 'outline' }: IProps) {
  const styles = useStyles()
  const actions = TicketCallFlowStore.useActions()
  const ticketId = TicketCallFlowStore.use((state) => state.ticketId)
  // const selected = TicketCallFlowStore.use((state) => state.selectedMessageId === messageId)
  const snapshot = app.store.use.getCallFlow(ticketId, messageId)

  const handleOnSelect = () => {
    actions.selectTurn(messageId)
  }

  return (
    <Column className={styles.root} onClick={handleOnSelect}>
      {/* <div
        className={mergeClasses(
          styles.border,
          selected ? 'selected' : 'unselected',
          selectedAppearance === 'outline' ? 'outline' : 'none',
        )}
      /> */}
      {(() => {
        switch (snapshot.status) {
          case 'waiting':
            return <TurnViewSkeleton />
          case 'error':
            return <ErrorView error={snapshot.error} />
          case 'done': {
            if (snapshot.data.services.length === 0 || snapshot.data.events.length === 0) {
              return <ErrorView.Custom message="No Call Flow data" level="OK" />
            }

            return <Content messageId={messageId} data={snapshot.data} />
          }
        }
      })()}
    </Column>
  )
}

function Content({ messageId, data }: IProps & { data: TicketCallFlow }) {
  const styles = useStyles()
  const [selectedServiceIds, setSelectedServiceIds] = useState<string[]>([])

  const handleOnServiceToggle = (serviceId: string) => {
    setSelectedServiceIds((prev) => {
      if (prev.includes(serviceId)) {
        return prev.filter((id) => id !== serviceId)
      } else {
        return [...prev, serviceId]
      }
    })
  }

  return (
    <Column className={styles.root} hAlign="stretch">
      <Column className={styles.bar}>
        <TurnViewBar data={data} selectedServiceIds={selectedServiceIds} onServiceToggle={handleOnServiceToggle} />
      </Column>
      <TurnViewFlow messageId={messageId} data={data} selectedServiceIds={selectedServiceIds} />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    // borderRadius: tokens.borderRadiusXLarge,
    // backgroundColor: tokens.colorNeutralBackground1,
    // position: 'relative',
    // overflow: 'hidden',
  },
  border: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: tokens.borderRadiusXLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    overflow: 'hidden',
    // pointerEvents: 'none',

    '&.selected': {
      // pointerEvents: 'none',
    },

    '&.selected.outline': {
      ...shorthands.border('3px', 'solid', tokens.colorBrandStroke1),
    },

    '&.unselected': {
      cursor: 'pointer',
    },

    '&.unselected:hover': {
      ...shorthands.borderColor(tokens.colorBrandStroke2),
    },
  },
  bar: {
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
})
