import { OPGProductEndpoints, ProductIds, ScenarioNames } from '@copilot-dash/domain'
import { TicketScreenMainTabsConfig, TicketScreenMainTab } from '../store/IState'

export async function getTicketScreenMainTabsConfig(ticketId: string): Promise<TicketScreenMainTabsConfig> {
  const ticketEnvironment = await app.store.actions.getTicketEnvironment(ticketId)
  const messageIds = await app.store.actions.getTicketMessageIds(ticketId)

  if (messageIds.length === 0) {
    throw new Error('No message IDs found for the ticket.')
  }

  const supportedMainTabs = Object.values(TicketScreenMainTab).filter((tab) => {
    switch (tab) {
      case TicketScreenMainTab.SymptomReport:
        return supportSymptomReport()
      case TicketScreenMainTab.UserpSearchData:
        return ticketEnvironment.productId === ProductIds.USERP
      case TicketScreenMainTab.ConversationData:
      case TicketScreenMainTab.Telemetry:
      case TicketScreenMainTab.KustoLog:
        return true
    }
  })

  function supportSymptomReport(): boolean {
    const isM365Chat = ticketEnvironment.productId === ProductIds.M365Chat
    const isM365WebChat = ticketEnvironment.productId === ProductIds.M365ChatWebChat
    const isAIF = ticketEnvironment.scenarioName?.toLowerCase() === ScenarioNames.AIF.toLowerCase()
    const isOPG = OPGProductEndpoints.some(
      (client) => client?.toLowerCase() === ticketEnvironment.clientName?.toLowerCase(),
    )

    return (isM365Chat || isM365WebChat) && !isAIF && !isOPG
  }

  const defaultTab =
    supportedMainTabs.includes(TicketScreenMainTab.UserpSearchData) && ticketEnvironment.endpoint?.scenario === 'USERP'
      ? TicketScreenMainTab.UserpSearchData
      : supportedMainTabs[0]

  return {
    tabs: supportedMainTabs,
    defaultTab,
  }
}
