import { INewTicketData, ColumnKey, ColumnIdNameMapping, DefaultProductEndpoints } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'

export const ColumnConfigEndpoint: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Endpoint],
  field: String(ColumnKey.Endpoint) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    const endpoint =
      DefaultProductEndpoints.find((endpoint) => endpoint.name.toLowerCase() === props.data.endpoint?.toLowerCase())
        ?.title ?? props.data.endpoint
    return (
      <TableCellLayoutWithStyles truncate title={endpoint}>
        {endpoint}
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.Endpoint]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 120,
  sortable: true,
  resizable: true,
}
