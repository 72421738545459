import { Column, Row, Spacer } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text, Tooltip } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { ActivityHistoryDetails } from './ActivityHistoryDetails'
import { ActivityTitle } from './ActivityTitle'
import { useStyles } from './UserActivity.styles'
import { summarizeActivity } from './utils'
import { useActivityHistoryPanelStore } from '../store/store'

interface IProps {
  readonly activity: IActivityHistory
}

export const UserActivity: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)
  const allTeamsSnapshot = application.store.use.getAllTeams()
  const summary = useMemo(() => {
    return summarizeActivity(activity, allTeamsSnapshot.data)
  }, [activity, allTeamsSnapshot.data])

  return (
    <Column fill className={styles.container}>
      <Row>
        <ActivityTitle activity={activity} />
      </Row>
      <Spacer height={4} />
      <Row>
        <Tooltip content={summary} relationship={'label'}>
          <Text className={styles.summarizeActivity}>{summary}</Text>
        </Tooltip>
      </Row>
      <Spacer height={4} />
      {selectedActivity?.activityId === activity.activityId && (
        <Row>
          <ActivityHistoryDetails />
        </Row>
      )}
      <Spacer height={12} />
    </Column>
  )
}
