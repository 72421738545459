import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketEmail(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.email
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.email = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise
      const emailAddress = raw.user?.emailAddress ?? raw.user?.email
      if (emailAddress) {
        return emailAddress
      }

      await assertTicketHasUserConsent(context, ticketId)
      throw TicketError.create('NoEmail', { ticketId })
    },
  })
}
