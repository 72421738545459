import { ApiConversationGroup3, ApiConversationGroup3Metric } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { get } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketApplicableForUserpConversation } from '../actions-ticket-assert/assertTicketApplicableForUserpConversation'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

const DECODE_METRIC_KEY = ['input', 'output', 'extendedData']

export function getRawConversationGroup3(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ApiConversationGroup3> {
  return context.getOrFetch<ApiConversationGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.rawConversationGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.rawConversationGroup3 = snapshot
    },
    fetch: async () => {
      const group3 = await doFetch()
      return decodeMetricsDataIfNeeded(group3)
    },
  })

  async function doFetch(): Promise<ApiConversationGroup3> {
    await assertTicketHasUserConsent(context, ticketId)

    const source = await getTicketSource(context, ticketId).promise
    const sourceItem = source.turns[messageId]?.conversationGroup3
    if (sourceItem?.url) {
      return await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asConversationGroup3()
    }

    await assertTicketApplicableForUserpConversation(context, ticketId, 'conversation')
    await assertTicketTurnExists(context, ticketId, messageId)
    throw TicketError.create('NoConversations', { ticketId, messageId, sourceItem })
  }
}

function extractDecodedData(metric: ApiConversationGroup3Metric): Record<string, unknown> | undefined {
  const decodedData: Record<string, unknown> = {}

  for (const key of DECODE_METRIC_KEY) {
    const metricValue = get(metric, key)

    if (typeof metricValue === 'string') {
      const segments = metricValue.split('\r\n').map((segment) => tryParseJson(segment) || segment)
      const isParsed = segments.some((segment) => typeof segment === 'object') || segments.length > 1

      if (isParsed) {
        decodedData[key] = segments.length === 1 ? segments[0] : segments
      }
    }
  }

  return Object.keys(decodedData).length > 0 ? decodedData : undefined
}

function decodeMetricsDataIfNeeded(conversationGroup3: ApiConversationGroup3): ApiConversationGroup3 {
  const conversation = conversationGroup3.conversation
  const request = conversation?.requests?.[0]
  const telemetry = request?.telemetry
  const metrics = telemetry?.metrics

  if (metrics && conversationGroup3.conversation?.requests) {
    const updatedMetrics = metrics.map((item) => {
      const decodedData = extractDecodedData(item)

      if (!decodedData) {
        return item
      }

      return {
        ...item,
        decodedData,
      }
    })

    conversationGroup3.conversation.requests[0] = {
      ...request,
      telemetry: {
        ...telemetry,
        metrics: updatedMetrics,
      },
    }
  }

  return conversationGroup3
}

function tryParseJson(str: string): object | undefined {
  try {
    return JSON.parse(str)
  } catch (_) {
    return undefined
  }
}
