import { Text } from '@fluentui/react-components'
import { ICellRendererParams } from 'ag-grid-community'
import { AnyPropertyItem } from '../../../AnyPropertyType'

export function NameView({ data }: ICellRendererParams<AnyPropertyItem>) {
  return (
    <Text weight="semibold" wrap={false} truncate title={data?.key}>
      {data?.name}
    </Text>
  )
}
