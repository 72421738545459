import { IBatchTicketCustomTagsType } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicket } from '../actions-ticket/getTicket'

export async function getBatchTicketsCustomTags(
  context: IDashStoreContext,
  ticketIds: string[],
): Promise<IBatchTicketCustomTagsType> {
  return ticketIds.reduce((acc, ticketId) => {
    acc[ticketId] = getTicket(context, ticketId).data?.customTags ?? []
    return acc
  }, {} as IBatchTicketCustomTagsType)
}
