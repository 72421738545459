import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawTicketInteractions } from '../actions-raw-ticket-interactions/getRawTicketInteractions'

export async function assertTicketTurnExists(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): Promise<void> {
  await assertFromInteractions(context, ticketId, messageId)
}

async function assertFromInteractions(context: IDashStoreContext, ticketId: string, messageId: string) {
  const interactions = await getRawTicketInteractions(context, ticketId).promise
  const interaction = interactions.find((i) => i.MessageId === messageId)
  const statusCode = interaction?.Diagnostic?.Conversation?.StatusCode
  if (statusCode) {
    const error = TicketError.diagnostic(statusCode, ticketId)
    if (error) {
      throw error
    }
  }

  // If there is only one interaction, check the status code of the conversation
  // In this case, the message ID might be invalid
  if (interactions.length === 1) {
    const statusCode = interactions[0]?.Diagnostic?.Conversation?.StatusCode
    if (statusCode) {
      const error = TicketError.diagnostic(statusCode, ticketId)
      if (error) {
        throw error
      }
    }
  }
}
