import { uuid } from '@copilot-dash/core'
import { INewTicketData, IProductData, OverViewTabs } from '@copilot-dash/domain'
import { SearchLoadMoreTicketsAction } from './actions/SearchLoadMoreTicketsAction'
import { SearchScreenTelemetryAction } from './actions/SearchScreenTelemetryAction'
import { createSearchScreenStateForm } from './createSearchScreenStateForm'
import { ISearchScreenStore } from './ISearchScreenStore'
import { ISearchScreenActions } from './ISearchScreenStoreActions'
import { ISearchScreenForm, ISearchScreenState } from './ISearchScreenStoreState'
import { TicketRoute } from '../../../router'
import { ISearchScreenProps, ISearchScreenPropsQuery } from '../SearchScreen'

export function createSearchScreenActions(store: ISearchScreenStore, props: ISearchScreenProps): ISearchScreenActions {
  return new SearchScreenActions(store, props)
}

class SearchScreenActions implements ISearchScreenActions {
  private readonly store: ISearchScreenStore
  private props: ISearchScreenProps

  private readonly searchLoadMoreTicketsAction: SearchLoadMoreTicketsAction
  private readonly telemetryAction: SearchScreenTelemetryAction

  constructor(store: ISearchScreenStore, props: ISearchScreenProps) {
    this.store = store
    this.props = props

    this.searchLoadMoreTicketsAction = new SearchLoadMoreTicketsAction(store)
    this.telemetryAction = new SearchScreenTelemetryAction(store)
  }

  onInit() {
    const searchSessionId = uuid()
    this.store.setState({
      searchSessionId: searchSessionId,
    })
    this.loadMore(0)
  }

  onStateChanged(state: ISearchScreenState, old: ISearchScreenState): void {
    this.telemetryAction.onStateChanged(state, old)
    if (state.form !== old.form && state.focusedTab === 'allFeedback') {
      this.store.setState({
        searchFeedbackResult: {
          ticketIds: [],
          total: 0,
          hasMore: true,
        },
      })
      this.searchLoadMoreTicketsAction.clearLoadMorePromise()
      this.loadMore(0)
      this.rowClick()
    }
  }

  updateProps(newProps: ISearchScreenProps): void {
    const oldProps = this.props
    this.props = newProps
    if (newProps.query !== oldProps.query) {
      this.store.setState({
        form: createSearchScreenStateForm(newProps.query),
        focusedTab: newProps.query.tab || 'allFeedback',
        currentQuery: newProps.currentQuery,
      })
    }
  }

  selectProduct(product: IProductData): void {
    this.submit({
      product: product.name,
      defaultRange: this.store.state.form.defaultRange,
    })
  }

  submit(form: ISearchScreenForm): void {
    const searchSessionId = uuid()
    this.store.setState({
      searchSessionId: searchSessionId,
    })
    const query: ISearchScreenPropsQuery = {
      ...form,
      product: form.product || 'M365Chat',
      tab: 'allFeedback',
    }
    Logger.telemetry.trackEvent('Search/SubmitSearch', { filters: query, searchSessionId: searchSessionId })
    this.props.onNavigate(query)
  }

  loadMore(offset: number): void {
    this.searchLoadMoreTicketsAction.loadMoreTickets(offset)
  }

  handleTicketsFilterPanelButtonClick = (): void => {
    this.store.setState({
      isColumnSettingPanelOpen: false,
      isTicketSummaryPanelOpen: false,
    })
    if (this.store.state.isTicketsFilterPanelOpen) {
      this.store.setState({
        isTicketsFilterPanelOpen: false,
      })
    } else {
      this.store.setState({
        isTicketsFilterPanelOpen: true,
      })
    }
  }

  handleColumnSettingButtonClick = (): void => {
    this.store.setState({
      isTicketsFilterPanelOpen: false,
      isTicketSummaryPanelOpen: false,
    })
    if (this.store.state.isColumnSettingPanelOpen) {
      this.store.setState({
        isColumnSettingPanelOpen: false,
      })
    } else {
      this.store.setState({
        isColumnSettingPanelOpen: true,
      })
    }
  }

  rowClick = (item?: INewTicketData): void => {
    if (!item) {
      this.store.setState({
        clickedTicketInfo: undefined,
        isTicketSummaryPanelOpen: false,
      })
      return
    }

    Logger.telemetry.trackEvent('Search/ClickTicket', {
      filters: this.store.state.form,
      searchSessionId: this.store.state.searchSessionId ? this.store.state.searchSessionId : '',
      ticketId: item.ticketId,
    })
    if (item.ticketId) {
      this.store.setState({
        clickedTicketInfo: item,
      })
      TicketRoute.navigator.navigateToTicketModal({ id: item.ticketId })
      return
    }
  }

  onDismissTicketSummaryPanel = (): void => {
    this.store.setState({
      isTicketSummaryPanelOpen: false,
    })
  }

  updateColumnSettingPanelVisibility = (visible: boolean) => {
    if (visible === this.store.state.isColumnSettingPanelOpen) {
      return
    }
    this.store.setState({
      isColumnSettingPanelOpen: visible,
    })
    Logger.telemetry.trackEvent('SwitchColumnPanel', { status: visible ? 'Open' : 'Close' })
  }

  updateFiltersPanelVisibility = (visible: boolean): void => {
    if (visible === this.store.state.isTicketsFilterPanelOpen) {
      return
    }
    this.store.setState({
      isTicketsFilterPanelOpen: visible,
    })
    Logger.telemetry.trackEvent('SwitchFiltersPanel', { status: visible ? 'Open' : 'Close' })
  }

  resetFilters(): void {
    this.submit({
      product: this.store.state.form.product,
      searchText: this.store.state.form.searchText,
      searchTextPrefix: this.store.state.form.searchTextPrefix,
      userId: this.store.state.form.userId,
      tenantIds: this.store.state.form.tenantIds,
      defaultRange: this.store.state.form.defaultRange,
    })
  }

  updateFocusedTab(tab: OverViewTabs): void {
    const form = this.store.state.form
    const query: ISearchScreenPropsQuery = {
      product: form.product || 'M365Chat',
      tab: tab,
    }
    this.props.onNavigate(query)
  }

  updateFeedbackInsightsIssueDetailDrillDownInfo(scenario?: string, params?: Partial<ISearchScreenForm>): void {
    this.store.setState({
      feedbackInsights: {
        issueDetail: {
          drillDownScenario: scenario,
          drillDownParams: params,
        },
      },
    })
  }

  setSelectedTickets(tickets: INewTicketData[]): void {
    this.store.setState({
      selectedTickets: tickets,
    })
  }
  updateBatchOperationPanelVisibility(visible: boolean): void {
    this.store.setState({
      isBatchOperationPanelOpen: visible,
    })
  }
}
