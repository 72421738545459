import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { z } from 'zod'
import { TicketScreenMainTab, TicketScreenMainTabNames } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenOverflowTab } from '../common/TicketScreenOverflowTab'
import { TicketScreenOverflowTabs } from '../common/TicketScreenOverflowTabs'

export function TicketScreenMainBar() {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()
  const supportedTabs = TicketScreenStore.use((state) => state.supportedMainTabs)
  const selectTab = TicketScreenStore.use((state) => state.selectedMainTab)

  const targetTabs = useMemo(() => {
    return supportedTabs.map((tab): TicketScreenOverflowTab => {
      return {
        id: tab,
        name: TicketScreenMainTabNames[tab].name,
      }
    })
  }, [supportedTabs])

  return (
    <TicketScreenOverflowTabs
      className={styles.root}
      tabs={targetTabs}
      selectedId={selectTab}
      onSelect={function (tabId: string): void {
        const tab = z.nativeEnum(TicketScreenMainTab).safeParse(tabId).data
        if (tab) {
          actions.selectMainTab(tab)
        }
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 4px',
  },
})
