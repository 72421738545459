import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  popoverBasic: {
    padding: '5px 10px',
    borderRadius: '4px',
    width: '100%',
  },
  popoverDefault: {
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    backgroundColor: tokens.colorNeutralForegroundInvertedHover,
  },
  popoverSelected: {
    ...shorthands.border('1px', 'solid', tokens.colorBrandBackground2),
    backgroundColor: tokens.colorBrandBackground2,
  },
  popoverExpanded: {
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    backgroundColor: tokens.colorNeutralCardBackgroundPressed,
  },
  popoverExpandedSelected: {
    ...shorthands.border('1px', 'solid', tokens.colorBrandBackgroundInvertedSelected),
    backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
  },
  selectCloseButton: {
    padding: 0,
    width: '20px',
    height: '20px',
  },
  value: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  placeholder: {
    color: tokens.colorNeutralForeground3,
  },
  input: {
    backgroundColor: tokens.colorNeutralCardBackgroundPressed,
    borderRadius: '4px',
  },

  scrollbar: {
    padding: '6px',
    maxHeight: 'min(300px, calc(100vh - 250px))',
    minHeight: '44px',
  },

  row: {
    maxWidth: '90%',
  },
  trigger: {
    width: '100%',
    padding: '0',
    maxWidth: '330px',
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
  },

  popoverDisabled: {
    opacity: 0.67,
    backgroundColor: tokens.colorNeutralBackgroundDisabled,
  },
})
