import { getFilterDisplayName } from '@copilot-dash/store'
import { memo } from 'react'
import { CopilotDashSearchBox } from '../../../Filter/CopilotDashSearchBox'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const OptionsSetsFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashSearchBox
      value={value}
      onChangeValue={onChangeValue}
      title={getFilterDisplayName('optionsSets') + ':'}
      placeholder="Search optionsSets"
    />
  )
})

OptionsSetsFilter.displayName = 'OptionsSetsFilter'
