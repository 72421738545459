import {
  Button,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  tokens,
  useOverflowMenu,
} from '@fluentui/react-components'
import { MoreHorizontalFilled, MoreHorizontalRegular, bundleIcon } from '@fluentui/react-icons'
import { HeaderTabBarMenuItem } from './HeaderTabBarMenuItem'
import { TabStore } from '../../TabStore'

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular)

export function HeaderTabBarMenu() {
  const styles = useStyles()
  const { ref, isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>()
  const items = TabStore.use((state) => state.items)

  if (!isOverflowing) {
    return null
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          className={styles.menuButton}
          ref={ref}
          icon={<MoreHorizontal />}
          title={`${overflowCount} more tabs`}
          role="tab"
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList className={styles.menu}>
          {items.map((item) => (
            <HeaderTabBarMenuItem key={item.name} item={item} />
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}

const useStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: 'center',
  },
})
