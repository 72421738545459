import { ApiError, DashApi } from '@copilot-dash/api'
import { DashError, DashErrorLevel } from '@copilot-dash/error'
import { Logger } from '@copilot-dash/logger'
import { IDashStoreUse } from './createDashStoreUse'
import { IDashStoreContext } from '../IDashStoreContext'
import { IDashStoreOptions } from '../IDashStoreOptions'
import { getOrFetch } from '../utils/getOrFetch'
import { requestAnd } from '../utils/requestAnd'

interface IParams {
  readonly use: IDashStoreUse
  readonly api: DashApi
  readonly options: IDashStoreOptions
}

export function createDashStoreContext({ use, api, options }: IParams): IDashStoreContext {
  return {
    get enableV2Endpoint() {
      return options.isV2Enabled()
    },
    get enableV2Discussion() {
      return options.isV2DiscussionEnabled()
    },
    get hasTier2() {
      return options.hasTier2()
    },
    get enableV2TeamView() {
      return options.isV2TeamViewEnabled()
    },
    use,
    api,
    get state() {
      return use.getState()
    },

    getOrFetch: ({ fetch, get, set, shouldPromiseCompare }) => {
      return getOrFetch({
        use,
        get,
        set: (state, snapshot) => {
          set(state, snapshot)

          if (snapshot.status === 'error') {
            handleOnError(snapshot.error)
          }
        },
        fetch,
        shouldPromiseCompare,
      })
    },

    requestAnd: ({ request, onSuccess, onError }) => {
      return requestAnd({
        use,
        request,
        onSuccess: (state, data) => {
          onSuccess?.(state, data)
        },
        onError: (state, error) => {
          onError?.(state, error)
          handleOnError(error)
        },
      })
    },
  }

  function handleOnError(error: unknown) {
    printError(error)

    if (error instanceof ApiError) {
      handleOnApiError(error)
    }
  }

  function handleOnApiError(error: ApiError) {
    switch (error.data.statusCode) {
      case 401:
      case 403:
        handleOnApiAccessError(error)
        break
    }
  }

  function handleOnApiAccessError(error: ApiError) {}

  function printError(error: unknown) {
    if (error instanceof DashError) {
      switch (error.data.level) {
        case DashErrorLevel.Fine:
          break
        case DashErrorLevel.Warning:
          Logger.trace.warn('A warning occurred while executing an action.', error.data)
          break
        case DashErrorLevel.Error:
          Logger.trace.error('An error occurred while executing an action.', error.data)
          break
      }
      return
    }

    Logger.trace.error('An error occurred while executing an action.', error)
  }
}
