import { mergeClasses } from '@fluentui/react-components'
import { useMemo } from 'react'
import { useStyles } from './KeywordsHighLightText.styles'
import { MarkdownView } from '../Markdown/MarkdownView'

interface IProps {
  text: string
  keywords?: string
  isTwoLinesTruncate?: boolean
  className?: string
  isTranslated?: boolean
}

export function KeywordsHighLightText({
  text,
  keywords,
  isTwoLinesTruncate = false,
  className,
  isTranslated = false,
}: IProps) {
  const styles = useStyles()
  const escapedKeywords = useMemo(() => {
    if (!keywords) {
      return ''
    }
    return escapeForJSON(keywords)
  }, [keywords])

  if (
    !keywords ||
    (!text.toLowerCase().includes(keywords.toLowerCase()) &&
      !text.toLowerCase().includes(escapedKeywords.toLowerCase()))
  )
    return (
      <MarkdownView
        className={
          isTwoLinesTruncate
            ? mergeClasses(styles.markdown, styles.breakAll2Lines, className)
            : mergeClasses(styles.markdown, className)
        }
        isTranslated={isTranslated}
      >
        {text}
      </MarkdownView>
    )

  let highlightedText = ''
  if (text.toLowerCase().includes(keywords.toLowerCase())) {
    highlightedText = escapeForJSON(text.replace(new RegExp(`(${escapedKeywords})`, 'gi'), '<span>$1</span>'))
  } else if (text.toLowerCase().includes(escapedKeywords.toLowerCase())) {
    //To match a literal backslash in a regular expression, escape it by using two backslashes (\\)
    highlightedText = text.replace(new RegExp(`(${escapeForJSON(escapedKeywords)})`, 'gi'), '<span>$1</span>')
  }
  return (
    <MarkdownView
      className={
        isTwoLinesTruncate
          ? mergeClasses(styles.markdown, styles.breakAll2Lines, className)
          : mergeClasses(styles.markdown, className)
      }
      isTranslated={isTranslated}
    >
      {highlightedText}
    </MarkdownView>
  )
}

const escapeForJSON = (str: string) => {
  return JSON.stringify(str).slice(1, -1)
}
