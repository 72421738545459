import { usePromise } from '@copilot-dash/core'
import { ErrorView } from './components/Error'
import { DashboardApp } from './DashboardApp'
import { init } from './initialize/init'

const initialization = init()

export function Dashboard() {
  const snapshot = usePromise(initialization)

  switch (snapshot.status) {
    case 'waiting':
      return null
    case 'error':
      return <ErrorView error={snapshot.error} />
    case 'done':
      return <DashboardApp />
  }
}
