import { TimeRange, relativeTimeUnitSchema } from '@copilot-dash/core'
import {
  InvocationSlicer,
  OverViewTabs,
  ProductName,
  SearchTextPrefixType,
  TicketAuthType,
  TicketChatMode,
  TicketRingType,
  UtteranceGroups,
  WorkItemStatus,
} from '@copilot-dash/domain'

import { SearchRouteArgs } from './SearchRouteArgs'
import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_PRODUCT = 'product'

const QUERY_PARAM_PRODUCT = 'product'
const QUERY_PARAM_AGENT_TYPES = 'agentTypes'
const QUERY_PARAM_APP_KINDS = 'appKinds'
const QUERY_PARAM_APPLICATIONS = 'applications'
const QUERY_PARAM_PLATFORMS = 'platforms'
const QUERY_PARAM_LICENSES = 'licenses'
const QUERY_PARAM_AUTH_TYPES = 'authTypes'
const QUERY_PARAM_CHANNEL = 'channel'
const QUERY_PARAM_CUSTOMER = 'customer'
const QUERY_PARAM_RING = 'ring'
const QUERY_PARAM_LANGUAGE = 'language'
const QUERY_PARAM_USERID = 'userId'
const QUERY_PARAM_FROM = 'from'
const QUERY_PARAM_TO = 'to'
const QUERY_PARAM_LAST_TIME = 'lastTime'
const QUERY_PARAM_LAST_TIME_UNIT = 'lastTimeUnit'
const QUERY_PARAM_HAS_VERBATIM = 'hasVerbatim'
const QUERY_PARAM_HAS_USER_CONSENT = 'hasUserConsent'
const QUERY_PARAM_IS_COOKED = 'isCooked'
const QUERY_IS_APOLOGY = 'isApology'
const QUERY_PARAM_SEARCH_TEXT = 'searchText'
const QUERY_PARAM_TENANT_IDS = 'tenantId'
const QUERY_PARAM_COMPONENT_OUTPUT = 'groundedPrompts'
const QUERY_PARAM_THUMBS = 'thumbs'
const QUERY_PARAM_INVOCATION_SLICERS = 'invocationSlicers'
const QUERY_PARAM_INVOCATION_TYPE = 'invocationType'
const QUERY_PARAM_TRIGGERED_SKILL = 'triggeredSkill'
const QUERY_PARAM_HAS_CITATION = 'hasCitation'
const QUERY_PARAM_HAS_ENTITY_CARD = 'hasEntity'
const QUERY_PARAM_HITAVALON = 'hitAvalon'
const QUERY_PARAM_PRIORITY = 'priority'
const QUERY_PARAM_IS_STCA_CHINA = 'isSTCAChina'
const QUERY_PARAM_IS_TOP_I18N = 'isTopi18N'
const QUERY_PARAM_RESPONSE_HERO_TYPE = 'responseHeroType'
const QUERY_PARAM_RESPONSE_LINK_TYPE = 'responseLinkType'
const QUERY_PARAM_SEMANTIC_SEARCH_TYPE = 'semanticSearchType'
const QUERY_PARAM_EXPERIENCE_TYPE = 'experienceType'
const QUERY_PARAM_HAS_CONNECTOR = 'hasConnector'
const QUERY_PARAM_HAS_GPT_EXTENSION = 'hasGPTExtension'
const QUERY_PARAM_HAS_MESSAGE_EXTENSION = 'hasMessageExtension'
const QUERY_PARAM_HAS_COPILOT_EXTENSION_IDS = 'hasCopExtIds'
const QUERY_PARAM_OPTION_SETS = 'optionsSets'
const QUERY_PARAM_TAB = 'tab'
const QUERY_PARAM_ISSUE_ID = 'issueId'
const QUERY_PARAM_ISSUE_BATCH_ID = 'issueBatchId'
const QUERY_PARAM_SEARCH_TEXT_PREFIX = 'prefix'
const QUERY_PARAM_ERROR_CODE = 'errorCode'
const QUERY_PARAM_IS_GC_INTENT = 'isGCIntent'
const QUERY_PARAM_HAS_CONNECTOR_RESULT = 'hasConnectorResult'
const QUERY_PARAM_DSAT_STATUS = 'dSATStatus'
const QUERY_PARAM_CUSTOM_TAGS = 'customTags'
const QUERY_PARAM_DSAT_ASSIGNED_TO = 'dSATAssignedTo'
const QUERY_PARAM_COPILOT_EXTENSION_IDS = 'copExtIds'
const QUERY_PARAM_SLICE_IDS = 'sliceIds'
const QUERY_PARAM_QUERY_ID = 'queryId'
const QUERY_PARAM_FLIGHTS = 'flights'
const QUERY_PARAM_HAS_ERROR_MESSAGES = 'hasErrorMessages'
const QUERY_PARAM_SHOW_ISSUE_COUNT = 'showIssueCount'
const QUERY_PARAM_SCENARIOS = 'scenarios'
const QUERY_PARAM_ORDER = 'order'
const QUERY_PARAM_HAS_SCREENSHOT = 'hasScreenshot'
const QUERY_PARAM_CHAT_MODE = 'chatMode'
const QUERY_PARAM_UTTERANCE_GROUPS = 'utteranceGroups'
const QUERY_PARAM_SCENARIO_SLICERS = 'scenarioSlicers'
const QUERY_PARAM_FEEDBACK_TARGETS = 'feedbackTargets'
const QUERY_PARAM_BUILDER_NAME_SLICERS = 'builderNameSlicers'
const QUERY_PARAM_CAPABILITIES_SLICERS = 'capabilitiesSlicers'
const QUERY_PARAM_USAGE_INTENSITY_COHORT_SLICERS = 'usageIntensityCohortSlicers'
const QUERY_PARAM_ENGAGEMENT_COHORT_SLICERS = 'engagementCohortSlicers'
const QUERY_PARAM_LANGUAGE_TIER_SLICERS = 'languageTierSlicers'
const QUERY_PARAM_LOCALE_SLICERS = 'localeSlicers'
const QUERY_PARAM_HAS_ASSIGNEE = 'hasAssignee'
const QUERY_PARAM_TEAM_Ids = 'teamIds'
const QUERY_PARAM_CLIENT_FLIGHTS = 'clientFlights'
const QUERY_PARAM_DEPLOY_RING = 'deployRing'

export const SearchRouteMapper: IRouteMapper<SearchRouteArgs> = {
  mapToParams(args: SearchRouteArgs): IRouteParams {
    return {
      pathParams: {
        [PATH_PARAM_PRODUCT]: args.product,
      },
      queryParams: {
        [QUERY_PARAM_QUERY_ID]: args.queryId,
        [QUERY_PARAM_APPLICATIONS]: args.applications,
        [QUERY_PARAM_PLATFORMS]: args.platforms,
        [QUERY_PARAM_LICENSES]: args.licenses,
        [QUERY_PARAM_AUTH_TYPES]: args.authTypes,
        [QUERY_PARAM_CHANNEL]: args.channel,
        [QUERY_PARAM_CUSTOMER]: args.customerTypes,
        [QUERY_PARAM_RING]: args.ring,
        [QUERY_PARAM_LANGUAGE]: args.promptLanguages,
        [QUERY_PARAM_USERID]: args.userId,
        [QUERY_PARAM_FROM]: args.range?.type === 'absolute' && args.range.from.length > 0 ? args.range.from : undefined,
        [QUERY_PARAM_TO]: args.range?.type === 'absolute' && args.range.to.length > 0 ? args.range.to : undefined,
        [QUERY_PARAM_LAST_TIME]: args.range?.type === 'relative' ? args.range.value.toString() : undefined,
        [QUERY_PARAM_LAST_TIME_UNIT]: args.range?.type === 'relative' ? args.range.unit.toString() : undefined,
        [QUERY_PARAM_HAS_VERBATIM]: args.hasVerbatim?.toString(),
        [QUERY_PARAM_HAS_USER_CONSENT]: args.hasUserConsent?.toString(),
        [QUERY_PARAM_IS_COOKED]: args.isCooked,
        [QUERY_IS_APOLOGY]: args.isApology?.toString(),
        [QUERY_PARAM_SEARCH_TEXT]: args.searchText,
        [QUERY_PARAM_SEARCH_TEXT_PREFIX]: args.searchTextPrefix,
        [QUERY_PARAM_TENANT_IDS]: args.tenantIds,
        [QUERY_PARAM_COMPONENT_OUTPUT]: args.groundedPrompts,
        [QUERY_PARAM_THUMBS]: args.thumbs,
        [QUERY_PARAM_INVOCATION_SLICERS]: args.invocationSlicers,
        [QUERY_PARAM_INVOCATION_TYPE]: args.invocationType,
        [QUERY_PARAM_TRIGGERED_SKILL]: args.triggeredSkill,
        [QUERY_PARAM_HAS_CITATION]: args.hasCitation,
        [QUERY_PARAM_HAS_ENTITY_CARD]: args.hasEntityCard,
        [QUERY_PARAM_HITAVALON]: args.hitAvalon,
        [QUERY_PARAM_PRIORITY]: args.priority,
        [QUERY_PARAM_IS_STCA_CHINA]: args.isSTCAChina?.toString(),
        [QUERY_PARAM_IS_TOP_I18N]: args.isTopi18N?.toString(),
        [QUERY_PARAM_RESPONSE_HERO_TYPE]: args.responseHeroType,
        [QUERY_PARAM_RESPONSE_LINK_TYPE]: args.responseLinkType,
        [QUERY_PARAM_SEMANTIC_SEARCH_TYPE]: args.semanticSearchType,
        [QUERY_PARAM_EXPERIENCE_TYPE]: args.experienceType,
        [QUERY_PARAM_HAS_CONNECTOR]: args.hasConnector,
        [QUERY_PARAM_HAS_GPT_EXTENSION]: args.hasGPTExtension,
        [QUERY_PARAM_HAS_MESSAGE_EXTENSION]: args.hasMessageExtension,
        [QUERY_PARAM_HAS_COPILOT_EXTENSION_IDS]: args.hasCopilotExtensionIds,
        [QUERY_PARAM_OPTION_SETS]: args.optionsSets,
        [QUERY_PARAM_TAB]: args.tab,
        [QUERY_PARAM_ISSUE_ID]: args.issueId,
        [QUERY_PARAM_ISSUE_BATCH_ID]: args.issueBatchId,
        [QUERY_PARAM_ERROR_CODE]: args.errorCode,
        [QUERY_PARAM_IS_GC_INTENT]: args.isGCIntent,
        [QUERY_PARAM_HAS_CONNECTOR_RESULT]: args.hasConnectorResult,
        [QUERY_PARAM_DSAT_STATUS]: args.dSATStatus,
        [QUERY_PARAM_CUSTOM_TAGS]: args.customTags,
        [QUERY_PARAM_DSAT_ASSIGNED_TO]: args.dSATAssignedTo,
        [QUERY_PARAM_COPILOT_EXTENSION_IDS]: args.copilotExtensionIds,
        [QUERY_PARAM_SLICE_IDS]: args.sliceIds,
        [QUERY_PARAM_FLIGHTS]: args.flights,
        [QUERY_PARAM_HAS_ERROR_MESSAGES]: args.hasErrorMessages,
        [QUERY_PARAM_AGENT_TYPES]: args.agentTypes,
        [QUERY_PARAM_APP_KINDS]: args.appKinds,
        [QUERY_PARAM_SHOW_ISSUE_COUNT]: args.showIssueCount,
        [QUERY_PARAM_SCENARIOS]: args.scenarios,
        [QUERY_PARAM_ORDER]: args.order,
        [QUERY_PARAM_HAS_SCREENSHOT]: args.hasScreenshot,
        [QUERY_PARAM_CHAT_MODE]: args.chatMode,
        [QUERY_PARAM_UTTERANCE_GROUPS]: args.utteranceGroups,
        [QUERY_PARAM_SCENARIO_SLICERS]: args.scenarioSlicers,
        [QUERY_PARAM_FEEDBACK_TARGETS]: args.feedbackTargets,
        [QUERY_PARAM_BUILDER_NAME_SLICERS]: args.builderNameSlicers,
        [QUERY_PARAM_CAPABILITIES_SLICERS]: args.capabilitiesSlicers,
        [QUERY_PARAM_USAGE_INTENSITY_COHORT_SLICERS]: args.usageIntensityCohortSlicers,
        [QUERY_PARAM_ENGAGEMENT_COHORT_SLICERS]: args.engagementCohortSlicers,
        [QUERY_PARAM_LANGUAGE_TIER_SLICERS]: args.languageTierSlicers,
        [QUERY_PARAM_LOCALE_SLICERS]: args.localeSlicers,
        [QUERY_PARAM_HAS_ASSIGNEE]: args.hasAssignee,
        [QUERY_PARAM_TEAM_Ids]: args.teamIds,
        [QUERY_PARAM_CLIENT_FLIGHTS]: args.clientFlights,
        [QUERY_PARAM_DEPLOY_RING]: args.deployRing,
      },
    }
  },

  mapToArgs(data: IRouteParams): SearchRouteArgs {
    const query = new RouteDataReader(data.queryParams)
    const path = new RouteDataReader(data.pathParams)

    const getRange = (): TimeRange | undefined => {
      const lastTime = query.getNumber(QUERY_PARAM_LAST_TIME)
      const lastTimeUnit = query.getString(QUERY_PARAM_LAST_TIME_UNIT)
      if (lastTime && lastTime > 0) {
        return {
          type: 'relative',
          value: lastTime,
          unit: relativeTimeUnitSchema.safeParse(lastTimeUnit).data ?? 'days',
        }
      }

      const from = query.getString(QUERY_PARAM_FROM)
      const to = query.getString(QUERY_PARAM_TO)
      if (from && to) {
        return {
          type: 'absolute',
          from,
          to,
        }
      }

      return undefined
    }

    return {
      queryId: query.getString(QUERY_PARAM_QUERY_ID),
      product: path.getString(QUERY_PARAM_PRODUCT) as ProductName,
      applications: query.getStringArray(QUERY_PARAM_APPLICATIONS),
      platforms: query.getStringArray(QUERY_PARAM_PLATFORMS),
      licenses: query.getStringArray(QUERY_PARAM_LICENSES),
      authTypes: query.getEnumArray(QUERY_PARAM_AUTH_TYPES, TicketAuthType),
      channel: query.getStringArray(QUERY_PARAM_CHANNEL),
      customerTypes: query.getStringArray(QUERY_PARAM_CUSTOMER),
      ring: query.getEnumArray(QUERY_PARAM_RING, TicketRingType),
      promptLanguages: query.getStringArray(QUERY_PARAM_LANGUAGE),
      userId: query.getStringArray(QUERY_PARAM_USERID),
      range: getRange(),
      hasVerbatim: query.getStringArray(QUERY_PARAM_HAS_VERBATIM),
      hasUserConsent: query.getStringArray(QUERY_PARAM_HAS_USER_CONSENT),
      isCooked: query.getBoolean(QUERY_PARAM_IS_COOKED),
      isApology: query.getStringArray(QUERY_IS_APOLOGY),
      searchText: query.getString(QUERY_PARAM_SEARCH_TEXT),
      searchTextPrefix: query.getEnum(QUERY_PARAM_SEARCH_TEXT_PREFIX, SearchTextPrefixType),
      tenantIds: query.getStringArray(QUERY_PARAM_TENANT_IDS),
      groundedPrompts: query.getStringArray(QUERY_PARAM_COMPONENT_OUTPUT),
      thumbs: query.getStringArray(QUERY_PARAM_THUMBS),
      invocationSlicers: query.getEnumArray(QUERY_PARAM_INVOCATION_SLICERS, InvocationSlicer),
      invocationType: query.getStringArray(QUERY_PARAM_INVOCATION_TYPE),
      triggeredSkill: query.getStringArray(QUERY_PARAM_TRIGGERED_SKILL),
      hasCitation: query.getStringArray(QUERY_PARAM_HAS_CITATION),
      hasEntityCard: query.getStringArray(QUERY_PARAM_HAS_ENTITY_CARD),
      hitAvalon: query.getStringArray(QUERY_PARAM_HITAVALON),
      priority: query.getStringArray(QUERY_PARAM_PRIORITY),
      isSTCAChina: query.getStringArray(QUERY_PARAM_IS_STCA_CHINA),
      isTopi18N: query.getStringArray(QUERY_PARAM_IS_TOP_I18N),
      responseHeroType: query.getStringArray(QUERY_PARAM_RESPONSE_HERO_TYPE),
      responseLinkType: query.getStringArray(QUERY_PARAM_RESPONSE_LINK_TYPE),
      semanticSearchType: query.getStringArray(QUERY_PARAM_SEMANTIC_SEARCH_TYPE),
      experienceType: query.getStringArray(QUERY_PARAM_EXPERIENCE_TYPE),
      hasConnector: query.getStringArray(QUERY_PARAM_HAS_CONNECTOR),
      hasGPTExtension: query.getStringArray(QUERY_PARAM_HAS_GPT_EXTENSION),
      hasMessageExtension: query.getStringArray(QUERY_PARAM_HAS_MESSAGE_EXTENSION),
      hasCopilotExtensionIds: query.getStringArray(QUERY_PARAM_HAS_COPILOT_EXTENSION_IDS),
      optionsSets: query.getString(QUERY_PARAM_OPTION_SETS),
      tab: query.getString(QUERY_PARAM_TAB) as OverViewTabs,
      issueId: query.getString(QUERY_PARAM_ISSUE_ID),
      issueBatchId: query.getString(QUERY_PARAM_ISSUE_BATCH_ID),
      errorCode: query.getStringArray(QUERY_PARAM_ERROR_CODE),
      isGCIntent: query.getStringArray(QUERY_PARAM_IS_GC_INTENT),
      hasConnectorResult: query.getStringArray(QUERY_PARAM_HAS_CONNECTOR_RESULT),
      dSATStatus: query.getEnumArray(QUERY_PARAM_DSAT_STATUS, WorkItemStatus),
      customTags: query.getStringArray(QUERY_PARAM_CUSTOM_TAGS),
      dSATAssignedTo: query.getString(QUERY_PARAM_DSAT_ASSIGNED_TO),
      copilotExtensionIds: query.getString(QUERY_PARAM_COPILOT_EXTENSION_IDS),
      sliceIds: query.getString(QUERY_PARAM_SLICE_IDS),
      flights: query.getString(QUERY_PARAM_FLIGHTS),
      hasErrorMessages: query.getStringArray(QUERY_PARAM_HAS_ERROR_MESSAGES),
      agentTypes: query.getStringArray(QUERY_PARAM_AGENT_TYPES),
      appKinds: query.getStringArray(QUERY_PARAM_APP_KINDS),
      showIssueCount: query.getString(QUERY_PARAM_SHOW_ISSUE_COUNT),
      scenarios: query.getStringArray(QUERY_PARAM_SCENARIOS),
      order: query.getStringArray(QUERY_PARAM_ORDER),
      hasScreenshot: query.getBoolean(QUERY_PARAM_HAS_SCREENSHOT),
      chatMode: query.getEnumArray(QUERY_PARAM_CHAT_MODE, TicketChatMode),
      utteranceGroups: query.getEnumArray(QUERY_PARAM_UTTERANCE_GROUPS, UtteranceGroups),
      scenarioSlicers: query.getStringArray(QUERY_PARAM_SCENARIO_SLICERS),
      feedbackTargets: query.getStringArray(QUERY_PARAM_FEEDBACK_TARGETS),
      builderNameSlicers: query.getStringArray(QUERY_PARAM_BUILDER_NAME_SLICERS),
      capabilitiesSlicers: query.getStringArray(QUERY_PARAM_CAPABILITIES_SLICERS),
      usageIntensityCohortSlicers: query.getStringArray(QUERY_PARAM_USAGE_INTENSITY_COHORT_SLICERS),
      engagementCohortSlicers: query.getStringArray(QUERY_PARAM_ENGAGEMENT_COHORT_SLICERS),
      languageTierSlicers: query.getStringArray(QUERY_PARAM_LANGUAGE_TIER_SLICERS),
      localeSlicers: query.getStringArray(QUERY_PARAM_LOCALE_SLICERS),
      hasAssignee: query.getBoolean(QUERY_PARAM_HAS_ASSIGNEE),
      teamIds: query.getStringArray(QUERY_PARAM_TEAM_Ids),
      clientFlights: query.getString(QUERY_PARAM_CLIENT_FLIGHTS),
      deployRing: query.getEnumArray(QUERY_PARAM_DEPLOY_RING, TicketRingType),
    }
  },
}
