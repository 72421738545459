import { PromiseSnapshot } from '@copilot-dash/core'
import { FeedbackId, INewTicketData } from '@copilot-dash/domain'

import { SearchTicketAction } from './SearchTicketAction'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getOrFetchTicketByTicketId(
  context: IDashStoreContext,
  ticketId: FeedbackId,
): PromiseSnapshot<INewTicketData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.ticket
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.ticket = snapshot
    },
    fetch: () =>
      new SearchTicketAction(context).search({ id: [ticketId], offset: 0, count: 1 }, []).then((result) => {
        const ticket = result.tickets[0]
        if (!ticket) {
          return Promise.reject(new Error('Ticket not found'))
        }
        return ticket
      }),
  })
}
