import { User } from '@copilot-dash/api'
import { Column, Row, Spacer } from '@copilot-dash/components'
import { Avatar, Body1, Button, Divider, Image, Subtitle1, Text, tokens } from '@fluentui/react-components'
import { useStyles } from './HeaderAvatar.style'
import addFriend from '../../../../../assets/AddFriend.svg'
import { Clickable } from '../../../../components/Basic/Clickable'
import { RouteLink } from '../../../../router'

const URL_VIEW_ACCOUNT = 'https://myaccount.microsoft.com/'
const URL_VIEW_ACCOUNT_WITH_EMAIL = 'https://myaccount.microsoft.com/?login_hint='

interface IProps {
  readonly user: User
  readonly logout: () => void
  readonly avatarUrl: string | null
}

export function HeaderAvatarPopover(props: IProps) {
  const { logout, avatarUrl, user } = props
  const styles = useStyles()

  let myAccountUrl = URL_VIEW_ACCOUNT
  if (props.user.mail) {
    myAccountUrl = `${URL_VIEW_ACCOUNT_WITH_EMAIL}${encodeURIComponent(props.user.mail)}`
  }

  const onSwitchAccount = () => {
    application.auth.switch()
  }

  return (
    <Column>
      <Row vAlign="center">
        <Text className={styles.msBrandText} weight="semibold" size={300}>
          Microsoft
        </Text>
        <Spacer />
        <Button appearance="subtle" onClick={logout} className={styles.logout}>
          Sign out
        </Button>
      </Row>
      {user && (
        <Row vAlign="center" className={styles.panelUserContainer}>
          <Avatar
            image={avatarUrl ? { src: avatarUrl } : undefined}
            name={!avatarUrl && user.displayName ? user.displayName : undefined}
            className={avatarUrl ? styles.panelAvatar : styles.avatarIcon}
          />
          <Spacer width={tokens.spacingHorizontalM} />
          <Column>
            <Subtitle1>{user.displayName}</Subtitle1>
            <Body1>{user.mail}</Body1>
            <RouteLink path={myAccountUrl} type="link">
              View account
            </RouteLink>
          </Column>
        </Row>
      )}
      <Divider />
      <Clickable className={styles.addUserButton} onClick={onSwitchAccount}>
        <Row fill vAlign="center" className={styles.panelAddUser}>
          <div className={styles.addUserContainer}>
            <Image src={addFriend} className={styles.panelAddUserImage} />
          </div>
          <Text>Sign in with a different account</Text>
        </Row>
      </Clickable>
    </Column>
  )
}
