import { makeStyles } from '@fluentui/react-components'

interface IProps {
  readonly isTranslated?: boolean
  readonly className?: string
}

export function TranslationIndicator({ isTranslated = true, className }: IProps) {
  const styles = useStyles()
  if (isTranslated === false) {
    return null
  }

  return (
    <span className={styles.root}>
      <img src="/svg/translation.svg" alt="Translation" />
      <span>{` `}</span>
    </span>
  )
}

const useStyles = makeStyles({
  root: {
    verticalAlign: 'middle',
  },
})
