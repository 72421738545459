import { ApiV2WorkItemResponse } from '@copilot-dash/api'
import { IWorkItem, WorkItemStatus } from '@copilot-dash/domain'

export function convertWorkItemFromAPItoDomain(apiResponse: ApiV2WorkItemResponse): IWorkItem {
  return {
    ...apiResponse,
    workItemId: apiResponse.id,
    rootCauseIdList: apiResponse.rootCauseList.map((rootCause) => rootCause.id),
    teamId: apiResponse.teamId.toString(),
    dsatStatus: apiResponse.dsatStatus as WorkItemStatus,
  }
}
