import { TicketErrorCodes } from './TicketErrorCodes'
import { TicketErrorData, TicketErrorExtraData } from './TicketErrorData'
import { TicketErrorDefinition } from './TicketErrorDefinition'
import { TicketErrorDefinitions } from './TicketErrorDefinitions'
import { DashError } from '../core/DashError'
import { DashErrorType } from '../core/DashErrorType'

export class TicketError extends DashError {
  override readonly data: TicketErrorData

  constructor(data: TicketErrorData) {
    super(DashErrorType.Ticket, data)
    this.data = data
  }

  override toString(): string {
    return this.data.message
  }

  static create(key: keyof typeof TicketErrorDefinitions, extra: TicketErrorExtraData): TicketError {
    const definition: TicketErrorDefinition = TicketErrorDefinitions[key]
    const data: TicketErrorData = {
      level: definition.level,
      source: definition.source,
      message: typeof definition.message === 'function' ? definition.message(extra) : definition.message,
      description:
        typeof definition.description === 'function' ? definition.description(extra) : definition.description,
      extra,
    }

    return new TicketError(data)
  }

  static diagnostic(code: number, ticketId: string): TicketError | null {
    // Ignore success codes
    if (code >= 2200 && code < 2299) {
      return null
    }

    // Ignore success codes
    if (code >= 6200 && code < 6299) {
      return null
    }

    const data = TicketErrorCodes.find((error) => {
      if (error.code.length === 1) {
        return error.code[0] === code
      }

      return error.code[0] <= code && code <= error.code[1]
    })

    const final = data ?? TicketErrorCodes[TicketErrorCodes.length - 1]!
    return new TicketError({
      level: final.level,
      source: final.source,
      message: final.message,
      description: final.description,
      extra: {
        ticketId,
        errorCode: code,
      },
    })
  }
}
