import { Row } from '@copilot-dash/components'
import { Spinner, Text, makeStyles, tokens } from '@fluentui/react-components'
import { ErrorView } from '../Error'

interface IProps {
  readonly queryId: string
}
export const SharedQueryName = ({ queryId }: IProps) => {
  const styles = useStyles()
  const queriesSnapshot = application.store.use.getQuery(queryId)

  switch (queriesSnapshot.status) {
    case 'waiting':
      return <Spinner />
    case 'error':
      return <ErrorView error={queriesSnapshot.error} />
    case 'done':
      return (
        <Row className={styles.container}>
          <Text className={styles.text}>Shared Query: {queriesSnapshot.data.title}</Text>
        </Row>
      )
  }
}

const useStyles = makeStyles({
  container: {
    paddingBlock: '4px',
  },
  text: {
    fontWeight: tokens.fontWeightSemibold,
  },
})
