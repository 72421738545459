import { Column, Row } from '@copilot-dash/components'
import { ProductIds } from '@copilot-dash/domain'
import { useFilterOptions } from '@copilot-dash/store'
import { Button, Spinner, makeStyles, tokens } from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { Ref, forwardRef, useMemo } from 'react'

import { CustomizedFilterDialog } from '../../../CustomizedFilterDialog/CustomizedFilterDialog'
import { CustomFilterCardFilters } from '../../children/cards/config'
import { useTicketFilterStore } from '../../store/store'
import { ISearchPageFilterProps } from '../../TicketFilterPanel.types'
import { TicketFilterForm } from '../TicketFilterForm/TicketFilterForm'
import { ITicketFilterFormRef } from '../TicketFilterForm/TicketFilterForm.types'

const FilterTabBodyInner = <T,>(props: ISearchPageFilterProps<T>, ref: Ref<ITicketFilterFormRef>) => {
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const styles = useStyles()
  const filterOptionsSnapshot = useFilterOptions('')
  const { productId, teamId, stateDisabled } = props
  const hasCustomizedFilters = useMemo(() => {
    return CustomFilterCardFilters.some((item) => filterPanelItems.includes(item))
  }, [filterPanelItems])
  const teamListSnapshot = app.store.use.getTeams(productId ?? ProductIds.All)

  return (
    <Column fill>
      {filterOptionsSnapshot.status === 'waiting' || teamListSnapshot.status === 'waiting' ? (
        <Row fill vAlign="center" hAlign="center">
          <div className={styles.loading}>
            <Spinner labelPosition="below" label="Loading..." />
          </div>
        </Row>
      ) : (
        <Column fill key="content">
          <TicketFilterForm
            ref={ref}
            onSubmit={props.onSubmitFilterPanel}
            defaultValues={{
              ...props.ticketFilterForm,
              range: props.ticketFilterForm.range ?? props.defaultRange,
            }}
            productId={productId}
            teamId={teamId}
            stateDisabled={stateDisabled}
          />

          <Row>
            {hasCustomizedFilters && (
              <CustomizedFilterDialog
                ticketFilterForm={props.ticketFilterForm}
                supportedTicketFilterItems={filterPanelItems}
              >
                <Button appearance="transparent" className={styles.button} icon={<AddRegular fontSize={18} />}>
                  Add filters
                </Button>
              </CustomizedFilterDialog>
            )}
          </Row>
        </Column>
      )}
    </Column>
  )
}

FilterTabBodyInner.displayName = 'FilterTabBodyInner'

export const FilterTabBody = forwardRef(FilterTabBodyInner) as <T>(
  props: ISearchPageFilterProps<T> & { ref?: Ref<ITicketFilterFormRef> },
) => JSX.Element

const useStyles = makeStyles({
  button: {
    textAlign: 'center',
    padding: '0',
    color: tokens.colorBrandForegroundLink,
    margin: '16px 0',
    cursor: 'pointer',
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
})
