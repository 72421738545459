import { User } from '@copilot-dash/api'
import { useAsyncLoader } from '@copilot-dash/core'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  LexicalTypeaheadMenuPlugin,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { TextNode } from 'lexical'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MentionsMenu, MentionTypeaheadOption } from './MentionsMenu'
import { $createMentionNode } from '../../editor-nodes/MentionNode'

type MentionLoadingOption = {
  status: 'waiting'
}

type MentionErrorOption = {
  status: 'error'
  error: unknown
}

type MentionDataOption = {
  status: 'done'
  data: MentionTypeaheadOption[]
}

export type MentionOptions = MentionLoadingOption | MentionErrorOption | MentionDataOption

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;'
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']'

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
}

const PUNC = DocumentMentionsRegex.PUNCTUATION

const TRIGGERS = ['@'].join('')

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]'

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNC +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')'

const LENGTH_LIMIT = 75

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' + '[' + TRIGGERS + ']' + '((?:' + VALID_CHARS + VALID_JOINS + '){0,' + LENGTH_LIMIT + '})' + ')$',
)

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()(' + '[' + TRIGGERS + ']' + '((?:' + VALID_CHARS + '){0,' + ALIAS_LENGTH_LIMIT + '})' + ')$',
)

const SUGGESTION_LIST_LENGTH_LIMIT = 20

function checkForAtSignMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text)

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text)
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1] ?? ''

    const matchingString = match[3] ?? ''
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2] ?? '',
      }
    }
  }
  return null
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 1)
}

export function MentionsPlugin() {
  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState<string | null>(null)
  const [submit, snapshot] = useAsyncLoader(
    useCallback((mentionQuery: string): Promise<User[]> | null => {
      if (mentionQuery.trim().length === 0) {
        return null
      }
      return application.api.microsoftGraph.searchUsers(mentionQuery).then((res) => res.value)
    }, []),
  )

  const debouncedSubmit = useMemo(() => debounce(submit, 300), [submit])

  useEffect(() => {
    debouncedSubmit(queryString ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  })

  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.mentionUser.displayName,
          selectedOption.mentionUser.uid,
          selectedOption.mentionUser.emailAddress,
        )
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode)
        }
        mentionNode.select()
        closeMenu()
      })
    },
    [editor],
  )

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor)
      if (slashMatch !== null) {
        return null
      }
      return getPossibleQueryMatch(text)
    },
    [checkForSlashTriggerMatch, editor],
  )

  const options = useMemo<MentionOptions>(() => {
    if (snapshot.status === 'waiting') {
      return { status: 'waiting' }
    }

    if (snapshot.status === 'error') {
      return { status: 'error', error: snapshot.error }
    }

    if (snapshot.status === 'done') {
      const mentionTypeaheadOptions: MentionTypeaheadOption[] = snapshot.data
        .filter((user) => user.displayName && user.id)
        .map(
          (user) =>
            new MentionTypeaheadOption({
              displayName: user.displayName!,
              uid: user.id!,
              emailAddress: user.mail ?? undefined,
            }),
        )
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT)

      return { status: 'done', data: mentionTypeaheadOptions }
    }

    return { status: 'done', data: [] }
  }, [snapshot])

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options.status === 'done' ? options.data : []}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => (
        <MentionsMenu
          anchorElementRef={anchorElementRef}
          selectedIndex={selectedIndex}
          selectOptionAndCleanUp={selectOptionAndCleanUp}
          setHighlightedIndex={setHighlightedIndex}
          options={options}
        />
      )}
    />
  )
}
