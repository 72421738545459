import { PromiseSnapshots } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function updateAllRootCauseCustomTags(context: IDashStoreContext, customTags?: string[]): Promise<void> {
  context.use.setState((state) => {
    const currentTags = state.basic.allRootCauseCustomTags?.data ?? []
    const tagsToMerge = customTags ?? []
    const mergedTags = Array.from(new Set([...currentTags, ...tagsToMerge]))

    state.basic.allRootCauseCustomTags = PromiseSnapshots.done(mergedTags)
  })
}
