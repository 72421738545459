import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { ITicketData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getOrFetchTicketByTicketId } from '../actions-search/searchTicketByTicketId'

// use WeakMap to store the memorized tickets, this is to avoid re-render in the UI and also to avoid fetching the same ticket multiple times
const memorizedTickets: WeakMap<
  string[],
  { status: 'waiting' | 'done' | 'error'; data: PromiseSnapshot<ITicketData[]> }
> = new WeakMap()

export function getTickets(context: IDashStoreContext, ticketIDs: string[]): PromiseSnapshot<ITicketData[]> {
  const tickets = ticketIDs.map((ticketID) => {
    return getOrFetchTicketByTicketId(context, ticketID)
  })

  const newStatus = tickets.some((ticket) => ticket.status === 'error')
    ? 'error'
    : tickets.some((ticket) => ticket.status === 'waiting')
      ? 'waiting'
      : tickets.every((ticket) => ticket.status === 'done')
        ? 'done'
        : null

  if (newStatus === null) {
    throw new Error('unexpected status')
  }

  // check if can return the memorized data
  if (memorizedTickets.has(ticketIDs) && memorizedTickets.get(ticketIDs)!.status === newStatus) {
    if (newStatus === 'done') {
      // double check if the data is the same
      const newData = tickets.map((ticket) => ticket.data!)
      const oldData = memorizedTickets.get(ticketIDs)!.data.data!
      if (newData.length === oldData.length && newData.every((data, i) => data === oldData[i])) {
        return memorizedTickets.get(ticketIDs)!.data
      }
    } else {
      return memorizedTickets.get(ticketIDs)!.data
    }
  }

  // return the new data
  let ret: PromiseSnapshot<ITicketData[]> = PromiseSnapshots.done([])
  switch (newStatus) {
    case 'done':
      ret = PromiseSnapshots.done(tickets.map((ticket) => ticket.data!))
      break
    case 'error':
      ret = PromiseSnapshots.error(tickets.find((ticket) => ticket.status === 'error')!.error)
      break
    case 'waiting':
      ret = PromiseSnapshots.waiting(Promise.all(tickets.map((ticket) => ticket.promise)))
      break
    default:
      break
  }

  // update the memorized data
  memorizedTickets.set(ticketIDs, { status: newStatus, data: ret })

  return ret
}
