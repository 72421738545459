import { Column, Right, Row, Spacer } from '@copilot-dash/components'
import { IClusterJobData, IClusterJobResultSummary } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Skeleton,
  SkeletonItem,
  Text,
  tokens,
} from '@fluentui/react-components'
import { ArrowLeftRegular, DeleteRegular, PenRegular } from '@fluentui/react-icons'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useStyles } from './ClusterResult.styles'
import { ClusterPublishButton } from './component/ClusterPublishButton'
import { IssueEditDialog } from './component/IssueEditDialog'
import { FieldNames, IFormData } from './component/IssueEditForm'

import { PRIORITY_OPTIONS } from './component/Priority'
import { AGGridTable } from '../../../../../components/AGGridTable/AGGridTable'
import { ErrorViewBoundary } from '../../../../../components/Error'
import { TimeView } from '../../../../../components/Time/TimeView'
import { ClusterRoute } from '../../../../../router'
import { productConfigurablePemission } from '../../../store'
import { ClusterNoPermissionScreen } from '../permission/ClusterNoPermissionScreen'
import { AllClusterResultTableColumns } from '../tableColumns/generateAllClusterResultColumns'

export interface ClusterResultProps {
  clusteringRequestId?: string
}

export const ClusterResult = memo(function ClusterResult({ clusteringRequestId }: ClusterResultProps) {
  const styles = useStyles()
  const [loading, setLoading] = useState(true)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [tableResultItems, setTableResultItems] = useState<IClusterJobResultSummary[]>([])
  const [selectClusterJob, setSelectClusterJob] = useState<IClusterJobData>()
  const doubleConfirmIssueRef = useRef<IClusterJobResultSummary | undefined>()
  const doubleConfirmRemoveIssueRef = useRef<IClusterJobResultSummary | undefined>()
  const tableColumns = AllClusterResultTableColumns()
  const args = ClusterRoute.navigator.getArgs()
  const loadTickets = useCallback(() => {
    setLoading(true)
    if (clusteringRequestId) {
      app.store.actions
        .getClusteringResult(clusteringRequestId)
        .then((resp) => {
          if (resp) {
            setSelectClusterJob(resp)
            setTableResultItems(resp.clusteringSummary ?? [])
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [clusteringRequestId])

  const handleGoBack = useCallback(() => {
    ClusterRoute.navigator.navigate({
      product: args.product,
    })
  }, [args.product])

  const rowHoverStickySlot = useCallback(
    (rowData: IClusterJobResultSummary) => (
      <div style={{ display: 'flex', gap: 8, marginRight: 16 }}>
        <Button
          onClick={() => {
            doubleConfirmIssueRef.current = rowData
            if (rowData) {
              setIsEditOpen(true)
            }
          }}
          icon={<PenRegular />}
          style={{ width: 'max-content' }}
          appearance="transparent"
        />
        <Button
          onClick={() => {
            doubleConfirmRemoveIssueRef.current = rowData
            if (rowData) {
              setIsDeleteOpen(true)
            }
          }}
          icon={<DeleteRegular />}
          style={{ width: 'max-content' }}
          appearance="transparent"
        />
      </div>
    ),
    [],
  )

  const handleEditIssue = useCallback(
    (rowData: IFormData) => {
      // to update the issueitem with the latest rowdata
      const updatedData = tableResultItems.map((item) => {
        if (item.id === rowData.id) {
          return { ...item, issue: rowData[FieldNames.ISSUETITLE], priority: rowData[FieldNames.PRIORITY] }
        }
        return item
      })
      setTableResultItems(updatedData)
      setIsEditOpen(false)
      doubleConfirmIssueRef.current = undefined
    },
    [tableResultItems],
  )

  const handleRemoveIssue = useCallback(
    (issueId: string) => {
      //remove the item from the tableResultItems
      const updatedData = tableResultItems.filter((item) => item.id !== issueId)
      setTableResultItems(updatedData)
      setIsDeleteOpen(false)
      doubleConfirmRemoveIssueRef.current = undefined
    },
    [tableResultItems],
  )

  useEffect(() => {
    loadTickets()
  }, [loadTickets])

  const table = useMemo(() => {
    return (
      <AGGridTable
        rowData={tableResultItems}
        getRowId={(data) => data.data.id ?? ''}
        columnDefs={tableColumns}
        hasMore={false}
        loading={loading}
        className={styles.resultContainer}
        stickySuffixRenderer={rowHoverStickySlot}
        handleColumnsChange={() => {}}
      />
    )
  }, [loading, rowHoverStickySlot, styles.resultContainer, tableColumns, tableResultItems])

  const hasClusterPermission = useMemo(() => {
    const args = ClusterRoute.navigator.getArgs()
    return (
      args.product &&
      application.auth.permission?.roles?.includes(productConfigurablePemission.get(args.product ?? '') ?? '')
    )
  }, [])

  if (!hasClusterPermission) {
    return <ClusterNoPermissionScreen />
  }

  return (
    <>
      <ErrorViewBoundary label="ClusterJobResult">
        <TelemetryScope scope="ClusterJobResult" properties={{ clusteringRequestId: clusteringRequestId ?? '' }}>
          <Row className={styles.content}>
            <Column className={styles.container}>
              <Row vAlign="center">
                <ArrowLeftRegular fontSize={20} style={{ flexShrink: 0, cursor: 'pointer' }} onClick={handleGoBack} />
                <Spacer width={8} />
                <Text size={600} weight="semibold">
                  Clustering results
                </Text>
                <Spacer />
              </Row>
              <Spacer height={24} />
              {loading ? (
                <Skeleton>
                  <Row>
                    <SkeletonItem size={16} />
                  </Row>
                </Skeleton>
              ) : (
                <>
                  <Row space="between">
                    <Text weight="semibold" size={600}>
                      {selectClusterJob?.clusterName ?? 'Clustering Job'}
                    </Text>
                    <Right>
                      <Text size={300} weight="regular" style={{ color: tokens.colorNeutralForeground1 }}>
                        Dataset: <TimeView value={selectClusterJob?.startTimeUTC} format="YYYY/MM/DD" />-
                        <TimeView value={selectClusterJob?.endTimeUTC} format="YYYY/MM/DD" />
                      </Text>
                    </Right>
                  </Row>
                  <Spacer height={12} />
                  <Row vAlign="center">
                    <Text size={300} weight="regular" style={{ color: tokens.colorNeutralForeground3 }}>
                      Here&apos;s the new clustering results based on{' '}
                      {selectClusterJob?.isBase ? 'base model' : 'your configurations'}.
                    </Text>
                  </Row>
                </>
              )}
              <Spacer height={12} />
              <Row className={styles.cardsContainer}>
                <Column vAlign="center" fill>
                  {table}
                </Column>
              </Row>
            </Column>
          </Row>
          <ClusterPublishButton
            clusteringRequestId={clusteringRequestId}
            loading={loading}
            tableResultItems={tableResultItems}
            selectClusterJob={selectClusterJob}
          />
          <IssueEditDialog
            open={isEditOpen}
            onClose={() => {
              setIsEditOpen(false)
            }}
            defaultValue={{
              id: doubleConfirmIssueRef.current?.id,
              issuetitle: doubleConfirmIssueRef.current?.issue,
              priority: doubleConfirmIssueRef.current?.priority ?? PRIORITY_OPTIONS[2],
            }}
            onOk={handleEditIssue}
          />
          <Dialog open={isDeleteOpen} modalType="alert" onOpenChange={(_, data) => setIsDeleteOpen(data.open)}>
            <DialogSurface>
              <DialogBody>
                <DialogContent>
                  <Text size={400} weight="semibold">
                    Are you sure to remove the issue from the current clustering result?
                  </Text>
                </DialogContent>
                <DialogActions>
                  <Button
                    appearance="primary"
                    onClick={() => {
                      doubleConfirmRemoveIssueRef.current && handleRemoveIssue(doubleConfirmRemoveIssueRef.current.id)
                    }}
                  >
                    Remove
                  </Button>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Cancel</Button>
                  </DialogTrigger>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        </TelemetryScope>
      </ErrorViewBoundary>
    </>
  )
})
