import { uuid } from '@copilot-dash/core'
import { ReactNode } from 'react'
import { useOverlayStore } from '../store/OverlayStore'
import { IOverlayItem } from '../store/OverlayStoreState'

interface IParams {
  readonly content: (open: boolean, onClose: () => void) => ReactNode
}

export function open({ content }: IParams): void {
  const key = uuid()
  const item: IOverlayItem = {
    key,
    content: content,
    open: true,
    close: () => {
      useOverlayStore.setState((state) => {
        const item = state.items.find((item) => item.key === key)
        if (item) {
          item.open = false
        }
      })

      setTimeout(() => {
        useOverlayStore.setState((state) => {
          state.items = state.items.filter((item) => item.key !== key)
        })
      }, 500)
    },
  }

  useOverlayStore.setState((state) => {
    state.items.push(item)
  })
}
