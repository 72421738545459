import { Column } from '@copilot-dash/components'
import { Button, Popover, PopoverSurface, PopoverTrigger, makeStyles } from '@fluentui/react-components'
import { ChatHelpRegular } from '@fluentui/react-icons'
import { ReactNode, useCallback, useState } from 'react'
import { getFeedbackUrl } from '../../../../constants/getFeedbackUrl'
import { RouteLink } from '../../../../router'

const FEEDBACK_VIEW_UNTRIAGED_URL = 'https://aka.ms/copilotdash-issues'
const FEEDBACK_VIEW_ACTIVE_URL =
  'https://o365exchange.visualstudio.com/O365%20Core/_queries/query/?tempQueryId=8f33a34c-a5e5-4614-9955-52eae2b31d2c'
const FEEDBACK_VIEW_MY_URL =
  'https://o365exchange.visualstudio.com/O365%20Core/_queries/query/?tempQueryId=585062da-b220-4d29-bb9e-ec7086a522bf'

export function HeaderFeedbackButton(): ReactNode {
  let timeout: NodeJS.Timeout

  const styles = useStyles()

  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const enableFeedbackEntry = app.features.feedbackEntry.use()

  const handleClick = useCallback(() => {
    Logger.telemetry.trackEvent('SendFeedback', { url: window.location.href })
  }, [])

  const handleMouseDown = () => {
    timeout = setTimeout(() => {
      application.router.openInNewTab(FEEDBACK_VIEW_UNTRIAGED_URL)
      clearTimeout(timeout)
    }, 500)
  }

  const handleMouseUp = () => {
    clearTimeout(timeout)
  }

  const feedbackOptions = [
    { url: getFeedbackUrl(), label: 'Report a problem' },
    { url: FEEDBACK_VIEW_UNTRIAGED_URL, label: 'View untriaged feedbacks' },
    { url: FEEDBACK_VIEW_ACTIVE_URL, label: 'View active feedbacks' },
    { url: FEEDBACK_VIEW_MY_URL, label: 'View my feedbacks' },
  ]

  const handleMouseEnter = () => {
    setPopoverOpen(true)
  }

  const handleMouseLeave = () => {
    setPopoverOpen(false)
  }

  if (enableFeedbackEntry) {
    return (
      <Popover open={isPopoverOpen} unstable_disableAutoFocus>
        <PopoverTrigger disableButtonEnhancement>
          <RouteLink path={getFeedbackUrl()} title="Report a problem" openInNewTab>
            <Button
              appearance="transparent"
              shape="square"
              size="medium"
              onClick={handleClick}
              icon={<ChatHelpRegular />}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </RouteLink>
        </PopoverTrigger>
        <PopoverSurface onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {feedbackOptions.map((item) => {
            return (
              <Column key={item.label} fill>
                <RouteLink key={item.label} path={item.url} title={item.label} openInNewTab>
                  <Button appearance="subtle" className={styles.selectButton}>
                    {item.label}
                  </Button>
                </RouteLink>
              </Column>
            )
          })}
        </PopoverSurface>
      </Popover>
    )
  }

  return (
    <RouteLink path={getFeedbackUrl()} title="Report a problem" openInNewTab>
      <Button
        appearance="transparent"
        shape="square"
        size="medium"
        onClick={handleClick}
        icon={<ChatHelpRegular />}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
    </RouteLink>
  )
}

const useStyles = makeStyles({
  selectButton: {
    width: '100%',
    justifyContent: 'left',
  },
})
