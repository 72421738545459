import { Overflow, OverflowItem, TabList, TabListProps, makeStyles } from '@fluentui/react-components'
import { HeaderTabBarItem } from './HeaderTabBarItem'
import { HeaderTabBarMenu } from './HeaderTabBarMenu'
import { TabStore } from '../../TabStore'

export function HeaderTabBar() {
  const styles = useStyles()
  const actions = TabStore.useActions()

  const items = TabStore.use((state) => state.items)
  const activeTab = TabStore.use((state) => state.activeTab)

  const handleOnSelected: TabListProps['onTabSelect'] = (_, data) => {
    if (typeof data.value === 'string') {
      actions.activate(data.value)
    }
  }

  return (
    <div className={styles.root}>
      <Overflow minimumVisible={2}>
        {/* Note: Property "selectedValue" has to be empty string here to make it as a controlled component */}
        <TabList selectedValue={activeTab ?? ''} onTabSelect={handleOnSelected}>
          {items.map((tab) => {
            return (
              <OverflowItem key={tab.id} id={tab.name} priority={tab.name === activeTab ? 2 : 1}>
                <HeaderTabBarItem item={tab} />
              </OverflowItem>
            )
          })}
          <HeaderTabBarMenu key="menu" />
        </TabList>
      </Overflow>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    flex: '1 1 0',
    overflow: 'hidden',
    height: 'fit-content',
  },
})
