import { useMemo } from 'react'
import { useCurrentRouteParamsHash } from './useCurrentRouteParamsHash'
import { useCurrentRouteParamsPath } from './useCurrentRouteParamsPath'
import { useCurrentRouteParamsQuery } from './useCurrentRouteParamsQuery'
import { useCurrentRouteParamsState } from './useCurrentRouteParamsState'
import { IRouteParams } from '../../../types/IRouteParams'

export function useCurrentRouteParams(): IRouteParams {
  const pathParams = useCurrentRouteParamsPath()
  const queryParams = useCurrentRouteParamsQuery()
  const hash = useCurrentRouteParamsHash()
  const state = useCurrentRouteParamsState()

  return useMemo(() => {
    return {
      pathParams,
      queryParams,
      hash,
      state,
    }
  }, [pathParams, queryParams, hash, state])
}
