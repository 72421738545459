import { Column, Row, Spacer } from '@copilot-dash/components'
import {
  Button,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@fluentui/react-components'
import { ChevronDownRegular, ChevronUpRegular, DismissRegular } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { CopilotDashDropdownInput } from './CopilotDashDropdownInput'
import { useStyles } from './CopilotDashPopover.styles'
import { Scrollbar } from '../Scrollbar/Scrollbar'

interface IProps {
  optionContent: ReactNode
  placeholder?: string
  selectedOptions: string[]
  value: string | JSX.Element
  handleSelectCleanClick: () => void
  inputValue: string
  setInputValue: (value: string) => void
  isPopoverOpen: boolean
  setIsPopoverOpen: (value: boolean) => void
  disabled?: boolean
  showInput?: boolean
  prefixIcon?: ReactNode
  className?: string
}

export const CopilotDashPopover: React.FC<IProps> = ({
  optionContent,
  placeholder = 'Select',
  selectedOptions,
  value,
  handleSelectCleanClick,
  inputValue,
  setInputValue,
  isPopoverOpen,
  setIsPopoverOpen,
  disabled,
  showInput,
  prefixIcon,
  className,
}) => {
  const styles = useStyles()
  const [surfaceWidth, setSurfaceWidth] = useState(0)
  const [visible, setVisible] = useState(false)
  const popoverRef = useRef<HTMLDivElement>(null)

  const debouncedSurfaceWidth = debounce((width: number) => setSurfaceWidth(width), 300)

  useEffect(() => {
    if (!isPopoverOpen) {
      setInputValue('')
    }
  }, [isPopoverOpen, setInputValue])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (popoverRef.current) {
        debouncedSurfaceWidth(popoverRef.current.offsetWidth)
      }
    })
    const currentPopoverRef = popoverRef.current
    if (currentPopoverRef) {
      resizeObserver.observe(currentPopoverRef)
    }

    return () => {
      if (currentPopoverRef) {
        resizeObserver.unobserve(currentPopoverRef)
      }
    }
  }, [popoverRef, debouncedSurfaceWidth])

  const popoverClassName = useMemo(() => {
    if (isPopoverOpen) {
      if (selectedOptions.length > 0) {
        return mergeClasses(styles.popoverBasic, styles.popoverExpandedSelected)
      }
      return mergeClasses(styles.popoverBasic, styles.popoverExpanded)
    } else {
      if (selectedOptions.length > 0) {
        return mergeClasses(styles.popoverBasic, styles.popoverSelected)
      }
      return mergeClasses(styles.popoverBasic, styles.popoverDefault)
    }
  }, [
    isPopoverOpen,
    selectedOptions,
    styles.popoverBasic,
    styles.popoverExpandedSelected,
    styles.popoverExpanded,
    styles.popoverSelected,
    styles.popoverDefault,
  ])
  return (
    <Popover
      positioning={{
        align: 'end',
        pinned: false,
        position: 'below',
      }}
      onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
      open={isPopoverOpen}
    >
      <PopoverTrigger disableButtonEnhancement>
        <div className={mergeClasses(styles.trigger, disabled && styles.disabled, className)}>
          <Tooltip
            content={value}
            relationship="label"
            positioning={'above'}
            visible={visible && typeof value === 'string' && value.length > 0}
            onVisibleChange={(_ev, data) => setVisible(data.visible)}
          >
            <Row
              className={mergeClasses(popoverClassName, disabled && styles.popoverDisabled)}
              space="between"
              ref={popoverRef}
              vAlign="center"
            >
              <Row vAlign="center" className={styles.row}>
                {prefixIcon && (
                  <Row>
                    {prefixIcon}
                    <Spacer size={4} />
                  </Row>
                )}
                <Text
                  className={
                    typeof value === 'string' && value.length > 0
                      ? styles.value
                      : mergeClasses(styles.value, styles.placeholder)
                  }
                >
                  {typeof value === 'string'
                    ? value.length > 0
                      ? value
                      : placeholder
                    : value.props?.children.length
                      ? value
                      : placeholder}
                </Text>
              </Row>
              {isPopoverOpen ? (
                <ChevronUpRegular fontSize={20} />
              ) : selectedOptions.length > 0 ? (
                <Button
                  icon={<DismissRegular />}
                  appearance="subtle"
                  size="small"
                  onClick={(e) => {
                    handleSelectCleanClick()
                    e.stopPropagation()
                  }}
                  className={styles.selectCloseButton}
                />
              ) : (
                <ChevronDownRegular fontSize={20} />
              )}
            </Row>
          </Tooltip>
        </div>
      </PopoverTrigger>
      <PopoverSurface style={{ width: surfaceWidth > 280 ? surfaceWidth : 280, padding: 0 }}>
        <Column>
          {showInput && <CopilotDashDropdownInput inputValue={inputValue} setInputValue={setInputValue} />}
          <Scrollbar className={styles.scrollbar}>{optionContent}</Scrollbar>
        </Column>
      </PopoverSurface>
    </Popover>
  )
}
