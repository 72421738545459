import { ApiTicketInteraction } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

/**
 * TODO: This method should be removed once the V2 endpoint is enabled completely.
 */
export function getRawTicketInteractions(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ApiTicketInteraction[]> {
  return context.getOrFetch<ApiTicketInteraction[]>({
    get: (state) => {
      return state.tickets[ticketId]?.rawInteractions
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.rawInteractions = snapshot
    },
    fetch: async () => {
      const interactions = await doFetch()

      // There is a special case where the API returns a single interaction without a message id
      if (interactions.length === 1 && !interactions[0]?.MessageId) {
        const statusCode = interactions[0]?.Diagnostic?.Conversation?.StatusCode
        if (statusCode) {
          const error = TicketError.diagnostic(statusCode, ticketId)
          if (error) {
            throw error
          }
        }

        throw TicketError.create('NoConversations', { ticketId })
      }

      return interactions
    },
  })

  async function doFetch(): Promise<ApiTicketInteraction[]> {
    const ticket = await getRawV2Ticket(context, ticketId).promise
    const source = ticket.sources?.find((source) => {
      return source.name === 'copilot_feedback_internal_log_Diagnostic'
    })
    if (!source) {
      throw TicketError.create('NoConversations', { ticketId })
    }

    return await context.api.copilotDash.getTicketBlob(ticketId, source.url).asInteractions()
  }
}
