import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IActions } from './IActions'
import { IState, ITabItem } from './IState'

interface IParams {
  readonly set: IStoreSetter<IState>
  readonly get: IStoreGetter<IState>
}

export function createActions({ set, get }: IParams): IActions {
  return {
    init(item: ITabItem): () => void {
      set((state) => {
        state.items.push(item)

        if (!state.activeTab) {
          state.activeTab = item.name
        }
      })

      return () => {
        set((state) => {
          const index = state.items.findIndex((i) => i.id === item.id)
          if (index !== -1) {
            state.items.splice(index, 1)
          }
        })
      }
    },

    update(item: Partial<ITabItem> & Pick<ITabItem, 'id'>): void {
      set((state) => {
        const index = state.items.findIndex((i) => i.id === item.id)
        if (index !== -1) {
          state.items[index] = {
            ...state.items[index]!,
            ...item,
          }
        }
      })
    },

    activate(tab: string): void {
      set((state) => {
        state.activeTab = tab
      })
    },
  }
}
