import { Row } from '@copilot-dash/components'
import { Text } from '@fluentui/react-components'
import { FC, memo } from 'react'
import { ICellBaseProps } from './types'

export const DSATs: FC<ICellBaseProps> = memo(({ data }) => {
  return (
    <Row>
      <Text weight="semibold" size={300}>
        {data.ticketCount}
      </Text>
      &nbsp;
      <Text size={300}>({data.coverage}%)</Text>
    </Row>
  )
})
DSATs.displayName = 'DSATs'
