import { DocumentOnePageRegular } from '@fluentui/react-icons'
import { OutlineItem } from './OutlineItem'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function OutlineItemForTicketMetadata() {
  const actions = TicketDiagnosticStore.useActions()
  const active = TicketDiagnosticStore.use((state) => state.activeTab === TicketDiagnosticTab.Metadata)

  const handleOnActive = () => {
    actions.setActiveTab(TicketDiagnosticTab.Metadata)
  }

  return (
    <OutlineItem icon={<DocumentOnePageRegular />} title="Ticket Metadata" onActive={handleOnActive} active={active} />
  )
}
