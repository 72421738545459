import { ReactNode } from 'react'
import { Root } from './children/Root'
import { TicketDiagnosticStore } from './TicketDiagnosticStore'

interface IProps {
  readonly ticketId: string
  readonly children: ReactNode
}

export function TicketDiagnosticPlugin({ ticketId, children }: IProps) {
  const enabled = app.features.ticketDiagnostic.use()
  const isAdmin = app.auth.permission?.admin
  if (!enabled || !isAdmin) {
    return <>{children}</>
  }

  return (
    <TicketDiagnosticStore ticketId={ticketId} ticket={children}>
      <Root />
    </TicketDiagnosticStore>
  )
}
