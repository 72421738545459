import { ColumnKey } from '@copilot-dash/domain'
import { Drawer, makeStyles } from '@fluentui/react-components'
import { ColumnSettingPanelBody } from '../children/ColumnSettingPanelBody'
import { ColumnSettingPanelHeader } from '../children/ColumnSettingPanelHeader'

const COLUMN_SETTING_PANEL_WIDTH = 360

interface IProps {
  readonly isPanelOpen: boolean
  readonly onDismiss: () => void
  readonly NonRemovableColumns: ColumnKey[]
  readonly AllSupportedColumns: ColumnKey[]
  readonly selectedColumns: ColumnKey[]
  readonly setSelectedColumns: (columns: ColumnKey[]) => void
}

export function ColumnSettingPanelLayout({
  isPanelOpen,
  onDismiss,
  NonRemovableColumns,
  AllSupportedColumns,
  selectedColumns,
  setSelectedColumns,
}: IProps) {
  const styles = useStyles()

  return (
    <Drawer
      className={styles.drawer}
      type="inline"
      separator
      open={isPanelOpen}
      onOpenChange={onDismiss}
      position="end"
    >
      <ColumnSettingPanelHeader onDismiss={onDismiss} />
      <ColumnSettingPanelBody
        NonRemovableColumns={NonRemovableColumns}
        AllSupportedColumns={AllSupportedColumns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    </Drawer>
  )
}

const useStyles = makeStyles({
  drawer: {
    width: `${COLUMN_SETTING_PANEL_WIDTH}px`,
  },
})
