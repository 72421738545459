import { Column } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { TicketOutlineConversationHistory } from './TicketOutlineConversationHistory'

export function TicketOutlineConversation() {
  const styles = useStyles()

  return (
    <Column className={styles.root}>
      <TicketOutlineConversationHistory />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px 16px 64px 16px',
    gap: '16px',
  },
})
