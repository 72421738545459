import { ReactNode } from 'react'
import { IState, TicketDiagnosticTab } from './IState'

interface IParams {
  readonly ticketId: string
  readonly children: ReactNode
}

export function createState({ ticketId, children }: IParams): IState {
  return {
    ticketId,
    activeTab: TicketDiagnosticTab.Home,
    isOpen: false,
    children,
  }
}
