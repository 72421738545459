import { Column } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { RootCauseCustomTags } from './RootCauseCustomTag/RootCauseCustomTags'
import { RootCauseDescription } from './RootCauseDescription'

export function RootCauseDetailView() {
  const styles = useStyles()

  return (
    <Column gap="gap.medium" className={styles.root} vAlign="stretch" hAlign="stretch">
      <RootCauseCustomTags />
      <RootCauseDescription />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '20px 24px',
    overflow: 'hidden',
  },
})
