import { z } from 'zod'

const api3sOnlineDataGroup3ResultSchema = z.record(z.unknown()).and(
  z.object({
    Id: z.string().optional(),
    Type: z.string().optional(),
  }),
)

const api3sOnlineDataWholePageRankingSchema = z.record(z.unknown()).and(
  z.object({
    Cards: z
      .array(
        z.record(z.unknown()).and(
          z.object({
            EntityCards: z
              .array(
                z.record(z.unknown()).and(
                  z.object({
                    EntityIds: z.array(z.string()).optional(),
                  }),
                ),
              )
              .optional(),
          }),
        ),
      )
      .optional(),
  }),
)

const api3sOnlineDataGroup3ResultSetSchema = z.record(z.unknown()).and(
  z.object({
    Provenance: z.string().optional(),
    Results: z.array(api3sOnlineDataGroup3ResultSchema).optional(),
  }),
)

const api3sOnlineDataGroup3EntitySetSchema = z.record(z.unknown()).and(
  z.object({
    ResultSets: z.array(api3sOnlineDataGroup3ResultSetSchema).optional(),
  }),
)

const api3sOnlineDataGroup3TrimmedResponseSchema = z.record(z.unknown()).and(
  z.object({
    AnswerEntitySets: z.array(api3sOnlineDataGroup3EntitySetSchema).optional(),
    EntitySets: z.array(api3sOnlineDataGroup3EntitySetSchema).optional(),
    WholePageRankings: z.array(api3sOnlineDataWholePageRankingSchema).optional(),
  }),
)

export const api3sOnlineDataGroup3ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string(),
    TrimmedResponse: api3sOnlineDataGroup3TrimmedResponseSchema,
  }),
)

export const api3sOnlineDataGroup3ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: api3sOnlineDataGroup3ReplayResponseSchema,
  }),
)

export const api3sOnlineDataGroup3Schema = z.array(api3sOnlineDataGroup3ItemSchema)
