import { Row } from '@copilot-dash/components'
import { mergeClasses, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'
import { HIGHLIGHT_TAG, useStyles } from './RenderLongText.styles'
import { FloatingTooltip, FloatingTooltipContent, FloatingTooltipTrigger } from '../FloatingTooltip'
import { MarkdownView } from '../Markdown/MarkdownView'
import { usePrivacyModeClass } from '../Privacy/usePrivacyModeClass'
import { ReplaceMarkdownSyntax } from '../Text/HandleMarkdownSyntax'

interface IProps {
  readonly originText: string
  readonly highlightText?: Array<string>
  readonly isTranslated: boolean
}

export function RenderLongText({ originText, highlightText, isTranslated }: IProps) {
  const styles = useStyles()
  const privacyModeClass = usePrivacyModeClass()

  // Combines originText with highlightText, preserving the highlighted parts.
  const combinedText = useMemo(() => {
    if (!highlightText || highlightText.length === 0) return originText

    let combinedText = originText

    highlightText.forEach((highlight) => {
      const plainHighlightText = highlight.replace(new RegExp(`</?${HIGHLIGHT_TAG}>`, 'g'), '')

      const startIndex = combinedText.indexOf(plainHighlightText)
      if (startIndex !== -1) {
        const beforeHighlight = combinedText.slice(0, startIndex)
        const afterHighlight = combinedText.slice(startIndex + plainHighlightText.length)
        combinedText = beforeHighlight + highlight + afterHighlight
      }
    })

    return combinedText
  }, [originText, highlightText])

  const ellipsisText = useMemo(() => {
    if (!highlightText) return ReplaceMarkdownSyntax(originText)
    let truncatedHighlightText = combinedText

    const highlightTagIndex = combinedText.indexOf(`<${HIGHLIGHT_TAG}>`)
    let lastWordBefore = ''
    let truncatedLastWordBefore = ''
    let extractStartIndex = 0

    if (highlightTagIndex > 0) {
      const textBeforeKeyword = combinedText.slice(0, highlightTagIndex).trim()
      const wordsBefore = textBeforeKeyword.split(/\s+/)
      lastWordBefore = wordsBefore[wordsBefore.length - 1] || ''

      truncatedLastWordBefore = lastWordBefore
      if (lastWordBefore.length > 15) {
        truncatedLastWordBefore = lastWordBefore.slice(0, 7) + '...'
      }

      extractStartIndex = textBeforeKeyword.lastIndexOf(lastWordBefore)
    }
    const prependEllipsis = extractStartIndex > 0
    truncatedHighlightText =
      (prependEllipsis ? '... ' : '') +
      combinedText.substring(extractStartIndex).replace(lastWordBefore, truncatedLastWordBefore)

    return ReplaceMarkdownSyntax(truncatedHighlightText)
  }, [originText, highlightText, combinedText])

  return (
    <div className={privacyModeClass}>
      <FloatingTooltip>
        <FloatingTooltipTrigger>
          <Row>
            <MarkdownView className={mergeClasses(styles.markdown, styles.breakAll2Lines)} isTranslated={isTranslated}>
              {ellipsisText}
            </MarkdownView>
          </Row>
        </FloatingTooltipTrigger>
        <FloatingTooltipContent className={styles.tooltipContent} arrowcolor={tokens.colorNeutralBackground3}>
          <MarkdownView className={mergeClasses(styles.markdown)} isTranslated={isTranslated}>
            {combinedText}
          </MarkdownView>
        </FloatingTooltipContent>
      </FloatingTooltip>
    </div>
  )
}
