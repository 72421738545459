import {
  DefaultProductChannelMapping,
  ProductName,
  ProductNames,
  TeamViewSubMenuIds,
  WorkItemStatus,
} from '@copilot-dash/domain'
import { CategoryTypeIds } from './store/const'
import { ITeamViewRouteArgs } from '../../router'

export function generateDefaultArg(subMenu: TeamViewSubMenuIds, productName: ProductName): ITeamViewRouteArgs {
  switch (subMenu) {
    case TeamViewSubMenuIds.All:
      return {
        subMenu: TeamViewSubMenuIds.All,
        channel: DefaultProductChannelMapping[productName],
        product: productName,
      }
    case TeamViewSubMenuIds.UnRootCaused:
      return {
        subMenu: TeamViewSubMenuIds.UnRootCaused,
        ticketStatus: CategoryTypeIds.UnRootCaused, // Legacy, will be removed in V2 stage3
        dSATStatus: [WorkItemStatus.Untriaged, WorkItemStatus.TeamAssigned],
        priority: productName === ProductNames.M365Chat ? ['0', '1'] : undefined,
        channel: DefaultProductChannelMapping[productName],
        product: productName,
      }
    case TeamViewSubMenuIds.TopIssues:
      return {
        subMenu: TeamViewSubMenuIds.TopIssues,
        channel: DefaultProductChannelMapping[productName],
        product: productName,
      }
    case TeamViewSubMenuIds.RootCauses:
      return {
        subMenu: TeamViewSubMenuIds.RootCauses,
        product: productName,
      }
    case TeamViewSubMenuIds.SharedQueries:
      return {
        subMenu: TeamViewSubMenuIds.SharedQueries,
        channel: DefaultProductChannelMapping[productName],
        product: productName,
      }
    default:
      return {
        subMenu: TeamViewSubMenuIds.All,
        channel: DefaultProductChannelMapping[productName],
        product: productName,
      }
  }
}
