import { ColumnKey, INewTicketData, columnKeySchema } from '@copilot-dash/domain'

import { TelemetryScope } from '@copilot-dash/logger'
import { ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { z } from 'zod'
import { useStyles } from './SearchScreenTableView.styles'
import { AGGridTable } from '../../../../components/AGGridTable/AGGridTable'
import { addSortModelToAGGridColumn } from '../../../../components/AGGridTable/utils'
import { useSearchScreenActions, useSearchScreenState } from '../../store'

interface IProps {
  readonly tableItem: INewTicketData[]
  readonly selectedColumns: ColDef<INewTicketData>[]
  readonly loadingTable: boolean
  readonly setColumns: (value: ColumnKey[]) => void
}

export function SearchScreenTableView({ tableItem, selectedColumns, loadingTable, setColumns }: IProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const searchSessionId = useSearchScreenState((state) => state.searchSessionId)
  const form = useSearchScreenState((state) => state.form)

  const loadMoreFunction = (offset: number) => {
    actions.loadMore(offset)
  }

  const handleOrderChange = (order?: Array<string>) => {
    if (order !== form.order) {
      actions.submit({ ...form, order })
    }
  }

  const columnDefs = useMemo(() => {
    return addSortModelToAGGridColumn(selectedColumns, form.order ?? ['Date desc'])
  }, [selectedColumns, form.order])

  const supportLoadMore = useMemo(() => {
    return searchFeedbackResult.hasMore
  }, [searchFeedbackResult])

  const handleColumnsChange = (columns: Array<string>) => {
    const movedColumns = columns
      .map((column) => columnKeySchema.safeParse(column))
      .filter((result) => result.success)
      .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
    setColumns(movedColumns)
  }

  return (
    <TelemetryScope
      scope="SearchScreenSessionView"
      properties={{ searchSessionId: searchSessionId ? searchSessionId : '' }}
    >
      <AGGridTable
        rowData={tableItem}
        getRowId={(data) => data.data.ticketId}
        columnDefs={columnDefs}
        className={styles.container}
        onRowClicked={actions.rowClick}
        focusedRowId={ticketId}
        hasMore={supportLoadMore}
        loadMore={loadMoreFunction}
        handleOrderChange={handleOrderChange}
        loading={loadingTable}
        handleColumnsChange={handleColumnsChange}
      />
    </TelemetryScope>
  )
}
