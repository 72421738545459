import { Column, Row, Spacer } from '@copilot-dash/components'
import { getProductNameById, IRootCauseList, ProductIds, TeamId, TeamViewSubMenuIds } from '@copilot-dash/domain'
import {
  Button,
  Field,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Skeleton,
  SkeletonItem,
  Tag,
  Text,
  tokens,
} from '@fluentui/react-components'
import { AddRegular } from '@fluentui/react-icons'
import { isNil } from 'lodash'
import { FC, memo, useCallback, useLayoutEffect, useState } from 'react'
import { RootCauseSelectorList } from './components/RootCauseSelectorList'
import { RootCauseTag } from './components/RootCauseTag'
import { useStyles } from './RootCauses.styles'
import { useToast } from '../../../hooks/useToast'
import { TeamRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { CopilotDashDropdownInput } from '../../Filter/CopilotDashDropdownInput'
import { RootCauseCreateDialog } from '../../RootCauseCreateDialog/RootCauseCreateDialog'
import { IFormData as ICreateRootCauseFormData } from '../../RootCauseCreateDialog/RootCauseCreateForm'
import { Scrollbar } from '../../Scrollbar/Scrollbar'

interface IRootCausesProps {
  value: string[] | undefined | null
  onChange: (value: string[]) => void
  productId?: ProductIds
  orientation?: 'horizontal' | 'vertical'
  loading?: boolean
  className?: string
  rootCauseMaxWidth?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
  teamName?: string
  teamId?: TeamId
  children: React.ReactNode
  allowCreateNewRootCause?: boolean
  rootCauseGrouped?: Record<string, IRootCauseList>
}
export const RootCauses: FC<IRootCausesProps> = memo(
  ({
    value,
    onChange,
    productId,
    orientation = 'horizontal',
    loading = false,
    className,
    rootCauseMaxWidth,
    style,
    defaultValueText,
    disabled,
    fieldProps,
    teamName,
    teamId,
    children,
    allowCreateNewRootCause = false,
    rootCauseGrouped = {},
  }) => {
    const styles = useStyles()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [values, setValues] = useState(() => (value && value.length > 0 ? value : []))
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
    const [input, setInput] = useState<string>('')
    const teamsSnapshot = app.store.use.getTeamsMap()

    useLayoutEffect(() => {
      setValues(value && value.length > 0 ? value : [])
    }, [value])

    const onRootCauseChange = useCallback(
      (issueId: string) => {
        const newValues = [...values]
        newValues.push(issueId)
        const uniqueValues = Array.from(new Set(newValues))
        onChange(uniqueValues)
        setIsPopoverOpen(false)
      },
      [onChange, values],
    )

    const onRemoveRootCause = (e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      const newValues = [...values].filter((item) => item !== value)
      onChange(newValues)
    }

    const generatePath = useCallback(
      (issueId: string) => {
        const product = getProductNameById(productId)
        if (!product) return ''

        // NOTE: @Ethan - legacy code, need to be removed after v2TeamView is enabled.
        const teamAndRootCauseList = Object.entries(rootCauseGrouped).find(([_, rootCauseList]) =>
          rootCauseList.some((rootCause) => rootCause.issueId === issueId),
        )
        // NOTE: @Ethan - v2TeamView code
        const rootCauseSnapshot = app.store.state.team.v2RootCauseMap[issueId]
        let teamName = teamAndRootCauseList?.[0]
        if (!teamName) {
          const teamId = rootCauseSnapshot?.data?.teamId
          teamName = teamId ? teamsSnapshot.data?.[teamId]?.name : ''
        }

        if (!teamName) return ''

        return TeamRoute.navigator.generatePath({
          product,
          subMenu: TeamViewSubMenuIds.RootCauses,
          teamName,
          issueId,
        })
      },
      [productId, rootCauseGrouped, teamsSnapshot],
    )

    const toast = useToast()

    const handleSaveAndAdd = useCallback(
      async (data: ICreateRootCauseFormData) => {
        if (isNil(teamId)) return
        return application.store.actions
          .createRootCause({
            rootCauseTitle: data.title,
            description: '',
            rootCausePriority: data.priority,
            teamId: teamId,
            rootCauseStatus: 'Active',
          })
          .then((response) => {
            if (response.workItemId) {
              // NOTE: @Ethan - Update the root cause list in the store.
              if (teamName) {
                useGlobalStore.getState().addRootCauseListByAreaPath(teamName, {
                  issueId: response.workItemId,
                  rootCauseTitle: data.title,
                  visible: true,
                  rootCauseStatus: 'Active',
                  createdTimeUtc: '',
                  teamId: teamId,
                  rootCauseType: 'Issue',
                })
              }
              if (productId && teamId) {
                application.store.actions.appendRootCauseByTeam(productId, teamId, {
                  issueId: response.workItemId,
                  rootCauseTitle: data.title,
                  eTA: '',
                  owner: '',
                  createdTimeUtc: '',
                  rootCauseCount: 0,
                  enable: false,
                  teamId: teamId,
                  rootCauseType: 'Issue',
                  visible: true,
                })
              }
              onChange([...values, response.workItemId])
              setDialogOpen(false)
            } else {
              // handle error
              toast.showError('Failed to save', response.errorMessage)
            }
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      },
      [teamId, teamName, values, productId, onChange, toast],
    )

    return (
      <Field orientation={orientation} className={className} label="Root Cause:" style={style} {...(fieldProps ?? {})}>
        {loading ? (
          <Skeleton>
            <Column>
              <SkeletonItem size={16} />
              <Spacer height={4} />
              <SkeletonItem size={16} />
            </Column>
          </Skeleton>
        ) : (
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', minWidth: '150px', overflow: 'hidden' }}
          >
            {defaultValueText === 'Mixed' && (
              <Tag value={'Mixed'} key={'Mixed'} tabIndex={-1} className={styles.tagStyle} dismissIcon={null}>
                <Text className={styles.textStyle}>{'Mixed root causes'}</Text>
              </Tag>
            )}
            {values
              .filter((rootCause) => rootCause)
              .map((rootCauseId, index) => {
                return (
                  <RootCauseTag
                    key={rootCauseId}
                    rootCauseId={rootCauseId}
                    tabIndex={index}
                    onRemoveRootCause={onRemoveRootCause}
                    generatePath={generatePath}
                    rootCauseMaxWidth={rootCauseMaxWidth}
                  />
                )
              })}
            <Popover
              positioning={{
                align: 'start',
                pinned: false,
                position: 'below',
              }}
              onOpenChange={(_, data) => setIsPopoverOpen(data.open)}
              open={isPopoverOpen}
            >
              <PopoverTrigger disableButtonEnhancement>
                <Button
                  appearance="subtle"
                  style={{ color: tokens.colorBrandForeground1, whiteSpace: 'nowrap' }}
                  icon={<AddRegular fontSize={18} />}
                >
                  Add root cause
                </Button>
              </PopoverTrigger>
              <PopoverSurface style={{ padding: '0px' }}>
                <Column style={{ width: '400px' }}>
                  <CopilotDashDropdownInput inputValue={input} setInputValue={setInput} />
                  <Scrollbar className={styles.scrollbar}>
                    <Column>
                      <RootCauseSelectorList
                        input={input}
                        setInput={setInput}
                        disabledValues={values}
                        onChange={(value) => {
                          onRootCauseChange(value)
                        }}
                        onCreateNewRootCauseClicked={() => setDialogOpen(true)}
                        defaultValueText={defaultValueText}
                        disabled={disabled}
                        teamName={teamName}
                        allowCreateNewRootCause={allowCreateNewRootCause}
                        rootCauseGrouped={rootCauseGrouped}
                      />
                    </Column>
                  </Scrollbar>
                </Column>
              </PopoverSurface>
            </Popover>
            <Row className={mergeClasses(styles.alignItemsCenter, styles.addNew)}>{children}</Row>
            <RootCauseCreateDialog
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false)
              }}
              onOk={handleSaveAndAdd}
              okBtnText="Save and Add"
            />
          </div>
        )}
      </Field>
    )
  },
)

RootCauses.displayName = 'RootCauses'
