import { IUpdateTicketStatusInfoData, IUpdateTicketStatusResponse } from '@copilot-dash/domain'
import { TicketStatusInfoConverter } from './converters/TicketStatusInfoConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function updateTicketStatusInfo(
  context: IDashStoreContext,
  data: IUpdateTicketStatusInfoData,
): Promise<IUpdateTicketStatusResponse> {
  const requestData = TicketStatusInfoConverter.toAPIData(data)
  return context.api.logCollector.updateTicketStatusInfo(requestData)
}
