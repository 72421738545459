import { useCallback, useState } from 'react'

export interface IExpansionState {
  readonly expandable: boolean
  readonly expandedCount: number
  readonly collapsedCount: number
  readonly expanded: boolean
  readonly toggle: () => void
}

export function useExpansion(count: number): IExpansionState {
  const [expanded, setExpanded] = useState(false)
  const [expandable, setExpandable] = useState(count > 4)

  const expandedCount = expandable ? (expanded ? count : 2) : count
  const collapsedCount = count - expandedCount

  const toggle = useCallback(() => {
    setExpanded((prev) => {
      return !prev
    })

    setExpandable((prev) => {
      return false
    })
  }, [])

  return {
    expandable,
    expanded,
    expandedCount,
    collapsedCount,
    toggle,
  }
}
