import { Column, Row, Spacer } from '@copilot-dash/components'
import { uuid } from '@copilot-dash/core'
import {
  ColumnKey,
  INewTicketData,
  ISevalQueryData,
  IUpdateRootCause,
  SearchTextPrefixType,
  TeamViewSubMenuIds,
  columnKeySchema,
} from '@copilot-dash/domain'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Skeleton,
  SkeletonItem,
  Text,
} from '@fluentui/react-components'
import { BookCoinsRegular, EditRegular, MoreCircleRegular } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { compact, isNil } from 'lodash'
import * as React from 'react'

import { z } from 'zod'
import { RootCauseScreenBarDrawerTrigger } from './Drawer/RootCauseScreenBarDrawerTrigger'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForRootCause.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { AGGridTable } from '../../../components/AGGridTable/AGGridTable'

import { ErrorView } from '../../../components/Error'
import { GenerateQuerySetTemplate } from '../../../components/GenerateQuerySetTemplate/GenerateQuerySetTemplate'
import { LoadingMask } from '../../../components/LoadingMask'
import { RootCauseCreateDialog } from '../../../components/RootCauseCreateDialog/RootCauseCreateDialog'
import { FieldNames, IFormData } from '../../../components/RootCauseCreateDialog/RootCauseCreateForm'
import { OverflowTabList } from '../../../components/TabList/OverflowTabList'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { TimeView } from '../../../components/Time/TimeView'
import { UserProfile } from '../../../components/User/UserProfile'
import { useToast } from '../../../hooks/useToast'
import { TeamRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'
import { useTeamViewStore } from '../store'
import { DefaultRootCauseTab, EMPTY_TEAM_ID, LinkedDSATsTab, RootCauseTab } from '../store/const'
import { RootCauseScreenDrawerType } from '../store/types'

interface ITicketsTableForRootCauseProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  readonly columnSetting: ColumnKey[]
  readonly setColumnSetting: (columns: ColumnKey[]) => void
}

export const TicketsTableForRootCause: React.FC<ITicketsTableForRootCauseProps> = React.memo(
  ({ onRowClick, clickedTicketKey, columnSetting, setColumnSetting }) => {
    useClaritySectionId(ClarityScenarioIds.menuRootCauseDetails)
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [querySetData, setQuerySetData] = React.useState<Partial<ISevalQueryData>>()
    const [generatingQuerySet, setGeneratingQuerySet] = React.useState(false)
    const [querySetDialogOpen, setQuerySetDialogOpen] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState<RootCauseTab>(RootCauseTab.LinkedDSATs)
    const [showPendingConfirmedDSATs, setShowPendingConfirmedDSATs] = React.useState(false)
    const linkedTicketIdsSnapshot = useTeamViewStore((state) => state.tickets.ticketIdListByRootCause)
    const linkedTicketsSnapshot = app.store.use.getTickets(linkedTicketIdsSnapshot.data?.ticketIds ?? [])

    const recommendTicketIdsSnapshot = useTeamViewStore((state) => state.tickets.recommendTicketIdListByRootCause)
    const recommendTicketsSnapshot = app.store.use.getTickets(recommendTicketIdsSnapshot.data?.ticketIds ?? [])
    const linkedTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreLinkedTicketsForRootCause)
    const recommendTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreRecommendTicketsForRootCause)
    const loadMoreLinkedTickets = useTeamViewStore((state) => state.tickets.loadMoreTicketListByRootCause)
    const loadMoreRecommendTickets = useTeamViewStore((state) => state.tickets.loadMoreRecommendTicketListByRootCause)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
    const teamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
    const filter = useTeamViewStore((state) => state.tickets.filterForm)
    const product = useTeamViewStore.getState().productName
    const isQuerySetVisible = app.features.v2QuerySet.use()

    const styles = useStyles()
    const toast = useToast()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)
    const productId = useTeamViewStore((state) => state.computed.productId)
    const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])
    const rootCauseInfoSnapshotV2 = application.store.use(
      (state) => state.team.v2RootCauseMap?.[`${selectedRootCauseId}`],
    )

    const selectedRootCause = React.useMemo(() => {
      return rootCauseInfoSnapshotV2?.data
    }, [rootCauseInfoSnapshotV2])

    const colDefs = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, searchText])

    const linkedDSATsCount =
      linkedTicketIdsSnapshot.status === 'done' ? linkedTicketIdsSnapshot.data?.ticketCount ?? 0 : ''
    const recommendedDSATsCount =
      recommendTicketIdsSnapshot.status === 'done' ? recommendTicketIdsSnapshot.data?.ticketCount ?? 0 : ''

    const handleColumnsChange = React.useCallback(
      (columns: Array<string>) => {
        const movedColumns = columns
          .map((column) => columnKeySchema.safeParse(column))
          .filter((result) => result.success)
          .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
        setColumnSetting(movedColumns)
      },
      [setColumnSetting],
    )

    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel(!!(selectedItems && selectedItems.length > 0))
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const handleUpdateRootCause = React.useCallback(
      (data: IFormData) => {
        if (isNil(teamId) || teamId === EMPTY_TEAM_ID || !selectedRootCause) return Promise.resolve(undefined)
        let formattedDate = ''
        if (data[FieldNames.DUEDATE]) {
          const timeStr = String(data[FieldNames.DUEDATE])
          const date = new Date(timeStr)

          const year = date.getFullYear()
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const day = String(date.getDate()).padStart(2, '0')

          formattedDate = `${year}-${month}-${day}`
        }

        const postData: IUpdateRootCause = {
          rootCauseTitle: data[FieldNames.TITLE],
          description: selectedRootCause.description ?? '',
          rootCausePriority: data[FieldNames.PRIORITY],
          rootCauseStatus: data[FieldNames.STATE],
          issueId: selectedRootCause.issueId,
          teamId: teamId,
          owner: data[FieldNames.ASSIGNEDTO],
          eTA: formattedDate,
        }
        return useTeamViewStore
          .getState()
          .rootCauses.updateTeamRootCause(postData)
          .then((resp) => {
            if (resp.errorMessage === '') {
              setDialogOpen(false)
              useGlobalStore.getState().updateRootCause(postData)
              productId && application.store.actions.updateRootCauseByTeam(productId, teamId, postData)
            } else {
              toast.showError('Failed to save', resp.errorMessage)
            }
          })
      },
      [teamId, selectedRootCause, productId, toast],
    )

    const handleGenerateQuerySet = React.useCallback(() => {
      if (selectedRootCauseId) {
        setGeneratingQuerySet(true)
        app.store.actions
          .searchTickets({ issueId: selectedRootCauseId, offset: 0, count: 1000 })
          .then((result) => {
            const tickets = result.tickets
            if (tickets.length === 0) {
              toast.showError('No tickets found for this query')
              return
            }
            const templateId = uuid()
            const data: Partial<ISevalQueryData> = {
              templateId,
              templateName: `QuerySet-${new Date().toLocaleString()}`,
              querySeed: tickets.map((ticket) => ({
                ticketId: ticket.ticketId,
                messageId: ticket.messageId,
                utterance: ticket.utterance,
                datetimeInUtc: ticket.dateTimeUtc,
              })),
            }
            setQuerySetData(data)
            setQuerySetDialogOpen(true)
          })
          .catch((error) => {
            toast.showError('Error', error.message)
          })
          .finally(() => {
            setGeneratingQuerySet(false)
          })
      }
    }, [selectedRootCauseId, toast])

    React.useEffect(() => {
      if (selectedRootCause?.issueId) {
        useTeamViewStore.getState().tickets.fetchTicketListByRootCause(selectedRootCause.issueId, filter)
        useTeamViewStore.getState().tickets.fetchRecommendTicketListByRootCause(selectedRootCause.issueId, filter)
      }
    }, [filter, selectedRootCause?.issueId])

    React.useEffect(() => {
      if (selectedRootCauseId) {
        application.store.actions.fetchRootCauseInfoByIdV2(selectedRootCauseId)
      }
    }, [selectedRootCauseId, product])

    React.useEffect(() => {
      if (rootCauseInfoSnapshotV2?.status === 'done') {
        setShowPendingConfirmedDSATs(!!rootCauseInfoSnapshotV2.data.enable)
      }
    }, [rootCauseInfoSnapshotV2, rootCauseSnapshot, selectedRootCause])

    const table = React.useMemo(() => {
      const curTicketIdsSnapshot =
        currentTab === RootCauseTab.LinkedDSATs ? linkedTicketIdsSnapshot : recommendTicketIdsSnapshot
      const curTicketsSnapshot =
        currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsSnapshot : recommendTicketsSnapshot
      const loadMoreFunction =
        currentTab === RootCauseTab.LinkedDSATs ? loadMoreLinkedTickets : loadMoreRecommendTickets
      const hasMore = currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsHasMore : recommendTicketsHasMore

      if (curTicketIdsSnapshot.status === 'waiting') {
        return <TableLoading />
      }
      if (curTicketIdsSnapshot.status === 'error') {
        return <ErrorView error={curTicketIdsSnapshot.error} />
      }
      if (curTicketIdsSnapshot.status === 'done' && curTicketsSnapshot.status === 'done') {
        return curTicketIdsSnapshot.data.ticketCount > 0 ? (
          <AGGridTable
            key={currentTab}
            className={styles.container}
            rowData={curTicketsSnapshot.data}
            columnDefs={colDefs}
            getRowId={(data) => data.data.ticketId}
            onRowClicked={onRowClick}
            hasMore={hasMore}
            loadMore={loadMoreFunction}
            focusedRowId={clickedTicketKey}
            domLayout={curTicketIdsSnapshot.data.ticketCount > 30 ? 'normal' : 'autoHeight'}
            rowSelectionMode="multiRow"
            onSelectionChange={handleSelectionChange}
            handleColumnsChange={handleColumnsChange}
          />
        ) : (
          <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No tickets found for this query" />
        )
      }
      return null
    }, [
      currentTab,
      linkedTicketIdsSnapshot,
      recommendTicketIdsSnapshot,
      linkedTicketsSnapshot,
      recommendTicketsSnapshot,
      loadMoreLinkedTickets,
      loadMoreRecommendTickets,
      linkedTicketsHasMore,
      recommendTicketsHasMore,
      styles.container,
      styles.flexGrow,
      colDefs,
      onRowClick,
      clickedTicketKey,
      handleSelectionChange,
      handleColumnsChange,
    ])

    const updateSelectedTab = React.useCallback((tab: React.SetStateAction<RootCauseTab>) => {
      setCurrentTab(tab)
    }, [])

    const renderItemCount = React.useCallback(
      (item: RootCauseTab) => {
        if (item === RootCauseTab.LinkedDSATs) {
          return typeof linkedDSATsCount === 'number' && ` (${linkedDSATsCount})`
        } else {
          return typeof recommendedDSATsCount === 'number' && ` (${recommendedDSATsCount})`
        }
      },
      [linkedDSATsCount, recommendedDSATsCount],
    )

    const handleGoBack = React.useCallback(() => {
      const product = useTeamViewStore.getState().productName
      const lastSelectedTeam = useTeamViewStore.getState().teams.lastSelectedTeamName
      lastSelectedTeam &&
        product &&
        TeamRoute.navigator.navigate({
          product,
          subMenu: TeamViewSubMenuIds.RootCauses,
          teamName: lastSelectedTeam,
        })
    }, [])

    React.useEffect(() => {
      return () => {
        useTeamViewStore.getState().toggleFilterPanel(false)
      }
    }, [])

    React.useEffect(() => {
      if (selectedRootCause?.eTA) {
        const eTADate = new Date(selectedRootCause.eTA)
        const targetDate = new Date(selectedRootCause.eTA)
        targetDate.setDate(eTADate.getDate() + 10)

        const timer = setInterval(() => {
          const now = new Date()
          const timeDifference = targetDate.getTime() - now.getTime()
          const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          if (daysLeft < 0) {
            clearInterval(timer)
          }
        }, 60000)
        return () => clearInterval(timer)
      } else {
        return
      }
    }, [selectedRootCause])

    const snapshotLoading = React.useMemo(() => {
      return rootCauseInfoSnapshotV2 === undefined || rootCauseInfoSnapshotV2.status === 'waiting'
    }, [rootCauseInfoSnapshotV2])

    return (
      <>
        <Row className={styles.header} wrap>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbButton
                size="large"
                onClick={handleGoBack}
                style={{ height: 'unset', minWidth: 80, padding: 0 }}
              >
                <Text wrap size={300} className={styles.breakWord}>
                  Root Causes
                </Text>
              </BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbDivider />
          </Breadcrumb>
        </Row>
        {snapshotLoading ? (
          <Column>
            <Spacer height={8} />
            <Skeleton aria-label="Loading Content">
              <SkeletonItem />
            </Skeleton>
            <Spacer height={8} />
            <Skeleton aria-label="Loading Content">
              <SkeletonItem />
            </Skeleton>
            <Spacer height={8} />
          </Column>
        ) : (
          <Column>
            <Spacer height={8} />
            <Row vAlign="end">
              <Text wrap size={600} weight="semibold" className={styles.rootCauseTitle}>
                {selectedRootCause?.rootCauseTitle}
              </Text>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton icon={<MoreCircleRegular />} appearance="transparent" style={{ marginLeft: '16px' }} />
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={() => setDialogOpen(true)}>
                      <Button style={{ padding: 0 }} appearance="transparent" icon={<EditRegular />}>
                        Edit Root Cause
                      </Button>
                    </MenuItem>
                    {isQuerySetVisible && (
                      <MenuItem onClick={handleGenerateQuerySet}>
                        <Button style={{ padding: 0 }} appearance="transparent" icon={<BookCoinsRegular />}>
                          Generate Query Set Template
                        </Button>
                      </MenuItem>
                    )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </Row>
            <Spacer height={8} />
            <Row className={styles.subInfo}>
              <Row>
                <Text size={300}>Assigned to:&nbsp;</Text>
                {selectedRootCause?.owner ? (
                  <Text>
                    <UserProfile
                      userId={selectedRootCause.owner}
                      fallback={selectedRootCause.owner}
                      customUserPhotoClassName={styles.customUserPhoto}
                      customUserDisplayNameClassName={styles.customUserName}
                    />
                  </Text>
                ) : (
                  <Text className={styles.empty}>Not Assigned</Text>
                )}
              </Row>
              <Spacer style={{ flex: '0 1 40px' }} />
              <Row>
                <Text size={300}>Fix by:&nbsp;</Text>

                {selectedRootCause?.eTA ? (
                  <Text size={300} weight="semibold">
                    <TimeView value={selectedRootCause?.eTA} format="YYYY/MM/DD" />
                  </Text>
                ) : (
                  <Text className={styles.empty}>Not Selected</Text>
                )}
              </Row>
              <Spacer style={{ flex: '0 1 40px' }} />
              <Row>
                <Text size={300}>Priority:&nbsp;</Text>
                <Text size={300} weight="semibold">
                  {typeof selectedRootCause?.rootCausePriority === 'number'
                    ? `P${selectedRootCause.rootCausePriority}`
                    : undefined}
                </Text>
              </Row>
              <Spacer width={40} />
              <Row>
                <Text size={300}>State:&nbsp;</Text>
                <Text size={300} weight="semibold">
                  {selectedRootCause?.rootCauseStatus}
                </Text>
              </Row>
              <Spacer />
              <Row gap="gap.smaller">
                <RootCauseScreenBarDrawerTrigger type={RootCauseScreenDrawerType.RootCauseDetail} />
                <RootCauseScreenBarDrawerTrigger type={RootCauseScreenDrawerType.DiscussionPanel} />
                <RootCauseScreenBarDrawerTrigger type={RootCauseScreenDrawerType.ActivityHistory} />
              </Row>
            </Row>
          </Column>
        )}
        <QuickSearch />
        <OverflowTabList
          items={showPendingConfirmedDSATs ? DefaultRootCauseTab : LinkedDSATsTab}
          renderItem={(item) => (
            <>
              {item}
              {renderItemCount(item)}
            </>
          )}
          onSelect={updateSelectedTab}
        />
        <Spacer height={16} />
        {table}
        <RootCauseCreateDialog
          fields={[FieldNames.PRIORITY, FieldNames.TITLE, FieldNames.STATE, FieldNames.DUEDATE, FieldNames.ASSIGNEDTO]}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onOk={handleUpdateRootCause}
          okBtnText="Save"
          defaultValue={{
            [FieldNames.PRIORITY]: selectedRootCause?.rootCausePriority as 1 | 2 | 3 | 4 | undefined,
            [FieldNames.TITLE]: selectedRootCause?.rootCauseTitle,
            [FieldNames.STATE]: selectedRootCause?.rootCauseStatus,
            [FieldNames.DUEDATE]: selectedRootCause?.eTA,
            [FieldNames.ASSIGNEDTO]: selectedRootCause?.owner,
          }}
          title="Edit root cause"
        />
        {querySetDialogOpen && querySetData && (
          <GenerateQuerySetTemplate
            defaultQuerySet={querySetData}
            open={querySetDialogOpen}
            onClose={() => setQuerySetDialogOpen(false)}
          />
        )}
        <LoadingMask visible={generatingQuerySet} />
      </>
    )
  },
)

TicketsTableForRootCause.displayName = 'TicketsTableForRootCause'
