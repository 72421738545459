import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  root: {
    [`&.emptyData .ag-center-cols-viewport`]: {
      minHeight: '100px !important',
    },
    ['& .ag-center-cols-viewport']: {
      minHeight: '56px !important',
    },
    width: '100%',
    height: '100%',
    ['& .ag-row']: {
      borderBottom: 'var(--ag-row-border) !important', // make sure the border is always there, no matter weather the row is the last one or not
    },
  },

  highlight: {
    background: tokens.colorBrandBackground2,
  },
  noHighlight: {
    background: 'unset',
  },

  row: {
    ['& .copilotDashRowShadow']: {
      visibility: 'hidden',
      paddingLeft: '22px',
      background: 'linear-gradient(to right, #ECECEC00, #EBEBEB 22px, #EBEBEB)',
      display: 'flex',
      alignItems: 'center',
    },

    ':hover': {
      cursor: 'pointer',

      ['& .copilotDashRowShadow']: {
        visibility: 'visible',
      },
    },
    ['& .ag-checked']: {
      backgroundColor: tokens.colorCompoundBrandBackground,
      border: 0,
    },
  },
})
