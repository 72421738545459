import { Column } from '@copilot-dash/components'
import { sortBy, uniq } from 'lodash'
import { useMemo } from 'react'
import { LabsPanelGroup } from './LabsPanelGroup'
import { SettingsScreenSection } from '../../../common/SettingsScreenSection'
import { SettingsScreenSectionTitle } from '../../../common/SettingsScreenSectionTitle'

export function SettingsLabs() {
  const features = useMemo(() => {
    return Object.values(app.features)
  }, [])

  const groups = useMemo(() => {
    const groups = features.map((feature) => feature.group)
    const uniqGroups = uniq(groups)

    return sortBy(uniqGroups, [
      (group) => {
        return !!group
      },
    ])
  }, [features])

  return (
    <Column hAlign="start">
      <SettingsScreenSection>
        <SettingsScreenSectionTitle title="Experimental Features" />
        <Column gap="gap.small">
          {groups.map((group, index) => {
            return (
              <LabsPanelGroup
                key={index}
                group={group}
                features={features.filter((feature) => feature.group === group)}
              />
            )
          })}
        </Column>
      </SettingsScreenSection>
    </Column>
  )
}
