import { ProductIds, ProductName, TeamId } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { ITeamViewStore, ITeamViewTeams } from './types'

export const createTeamsStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewTeams> = (
  set,
  get,
) => ({
  lastSelectedTeamName: undefined,
  lastSelectedTeamId: undefined,
  selectedSubMenu: undefined,

  setProduct: (product: ProductName) => {
    set((state) => {
      state.productName = product
    })
  },
  setLastSelectedTeam: (teamName: string, teamId: TeamId, sync2Storage = false, productId?: ProductIds) => {
    set((state) => {
      state.teams.lastSelectedTeamName = teamName
      state.teams.lastSelectedTeamId = teamId
    })
    if (sync2Storage && productId) {
      get().teams.syncLastSelectedTeam2Storage(productId)
    }
  },
  setSelectedSubMenu: (category: string | undefined) => {
    set((state) => {
      state.teams.selectedSubMenu = category
    })
  },
  syncLastSelectedTeam2Storage: (productId?: ProductIds) => {
    if (!productId || !get().teams.lastSelectedTeamName || !get().teams.lastSelectedTeamId) return
    const teamName = get().teams.lastSelectedTeamName
    const teamId = get().teams.lastSelectedTeamId
    if (!teamName || !teamId) return
    const lastSelectedTeam = { teamName, teamId }
    const old = application.settings.lastSelectedTeamByProductId.value
    application.settings.lastSelectedTeamByProductId.set({ ...old, [productId]: lastSelectedTeam })
  },
  syncLastSelectedTeamFromStorage: (productId?: ProductIds) => {
    if (!productId) return
    const lastSelectedTeam = application.settings.lastSelectedTeamByProductId.value[productId]

    set((state) => {
      state.teams.lastSelectedTeamName = lastSelectedTeam?.teamName
      state.teams.lastSelectedTeamId = lastSelectedTeam?.teamId
    })
  },
})
