import { Column, Spacer } from '@copilot-dash/components'
import { ITeamData, ProductIds } from '@copilot-dash/domain'
import { SkeletonItem, Text, makeStyles, tokens } from '@fluentui/react-components'
import { isNaN, isNil } from 'lodash'
import { memo, useEffect, useMemo, useState } from 'react'
import { TeamQueries } from './TeamQueries'
import { CopilotDashTeamDropdown } from '../../../Filter/CopilotDashTeamDropdown'
import { useTicketFilterStore } from '../../store/store'

export const SharedQueryTab = memo(() => {
  const styles = useStyles()
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)
  const [teamName, setTeamName] = useState<string>('')
  const teamId = app.settings.sharedQueriesTeamId.use()

  const onTeamIdChange = (value: string | undefined) => {
    Logger.telemetry.trackEvent('SavedQueries/ChangeTeam', {
      teamId: Number(value),
      productId: Number(selectedProductId),
    })

    const newTeam = {
      ...teamId,
      [selectedProductId.toString()]: Number(value),
    }
    app.settings.sharedQueriesTeamId.set(newTeam)
  }
  const teamsSnapshot = app.store.use.getTeams(selectedProductId ?? ProductIds.M365Chat)

  const teamsOptions: ITeamData[] = useMemo(() => {
    switch (teamsSnapshot.status) {
      case 'done':
        return teamsSnapshot.data
      default:
        return []
    }
  }, [teamsSnapshot.data, teamsSnapshot.status])

  const currentTeamId = useMemo(() => {
    return isNaN(teamId[selectedProductId]) ? undefined : String(teamId[selectedProductId])
  }, [teamId, selectedProductId])

  useEffect(() => {
    if (isNil(currentTeamId)) {
      setTeamName('')
    } else {
      setTeamName(teamsOptions.find((team) => team.id === currentTeamId)?.name ?? '')
    }
  }, [teamsOptions, currentTeamId])

  const newTeamsOptions = useMemo(() => {
    return [...teamsOptions].sort((a, b) =>
      a.name.toLocaleUpperCase().trim().localeCompare(b.name.toLocaleUpperCase().trim()),
    )
  }, [teamsOptions])

  return (
    <Column fill>
      <Spacer height="20px" />
      <Text className={styles.title}>Team</Text>
      <Spacer height="12px" />
      {teamsSnapshot.status === 'waiting' ? (
        <SkeletonItem />
      ) : (
        <CopilotDashTeamDropdown
          teamList={newTeamsOptions}
          teamId={currentTeamId}
          teamName={teamName}
          onTeamIdChange={onTeamIdChange}
          placeholder="Select a Team"
          showInput={true}
        />
      )}
      <Spacer height="16px" />
      <Text className={styles.title}>Shared Queries</Text>
      {!isNil(currentTeamId) && <TeamQueries teamId={String(currentTeamId)} />}{' '}
    </Column>
  )
})

SharedQueryTab.displayName = 'SharedQueryTab'

const useStyles = makeStyles({
  title: {
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightBold,
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
})
