import { sortBy, uniq } from 'lodash'
import { AnyTableColDef } from '../AnyTableTypes'

/**
 * This number represents that the first "3" rows will be used as the data source,
 * and then all keys of these three rows will be taken out and these keys wil be the final column keys.
 */
const SAMPLE_ROW_COUNT = 3

interface IParams<T> {
  readonly data: T[]
  readonly columns: AnyTableColDef<T>[]
}

export function generateAnyColumnKeys<T>({ data: data, columns }: IParams<T>): string[] {
  const keys: string[] = columns.map((column) => column.key)
  for (let i = 0; i < SAMPLE_ROW_COUNT; i++) {
    keys.push(...Object.keys(data[i] ?? {}))
  }

  const map: Record<string, number> = {}
  columns.forEach((column, index) => {
    if (column.position) {
      switch (column.position) {
        case 'start':
          map[column.key] = Number.MIN_VALUE
          break
        case 'end':
          map[column.key] = Number.MAX_VALUE
          break
      }
    } else {
      map[column.key] = index
    }
  })

  return sortBy(uniq(keys), (key) => {
    const order = map[key]
    if (order) {
      return order
    }

    const lowerCaseKey = key.toLowerCase()
    if (lowerCaseKey.includes('timestamp')) return -3
    if (lowerCaseKey.includes('time')) return -2
    if (lowerCaseKey.includes('date')) return -1
    return 0
  })
}
