import { Column, Row, Spacer } from '@copilot-dash/components'
import { memo } from 'react'
import { TopIssueTable } from './TopIssueTable'

export const TopIssue = memo(() => {
  return (
    <Column>
      <Spacer height={24} />
      <Row>
        <TopIssueTable />
      </Row>
    </Column>
  )
})

TopIssue.displayName = 'TopIssue'
