import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { countBy } from 'lodash'
import { useMemo } from 'react'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'
import { ErrorView } from '../../Error'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function RowForSynthesisCitation({ ticketId, messageId }: IProps) {
  const tags = application.store.use.getTicketSystemTags(ticketId)
  const conversation = application.store.use.getTicketTurnConversation(ticketId, messageId)
  const citations = application.store.use.getTicketTurnConversationCitations(ticketId, messageId)

  const referenceText = useMemo(() => {
    const references = citations.data?.map((item) => item.type)
    const dict = countBy(references)

    const text = Object.keys(dict)
      .map((key) => `${key}: ${dict[key]}`)
      .join(', ')
      .trim()

    return text ? text : 'Empty'
  }, [citations.data])

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Synthesis</Text>
      </TableCell>
      <TableCell>
        {(() => {
          switch (conversation.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'error':
              return <ErrorView type="inline" error={conversation.error} />
            case 'done': {
              return (
                <>
                  {tags.data?.isApology && (
                    <span>
                      <span>Response: isApology</span>
                      <SymptomReportHealthLabel status="error" text="true" />
                      <br />
                    </span>
                  )}
                  <span>[Citation type, Citation Count]: {referenceText}</span>
                </>
              )
            }
          }
        })()}
      </TableCell>
    </TableRow>
  )
}
