import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

const DEFAULT_TOP_VALUE = 20

export function searchTopTenantIdsByAISearch(context: IDashStoreContext): PromiseSnapshot<string[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.basic.topTenantIds
    },
    set: (state, snapshot) => {
      state.basic.topTenantIds = snapshot
    },
    fetch: async () => {
      const searchBody = {
        filter: "tenantId ne null and tenantId ne ''",
        facets: [`tenantId,count:${DEFAULT_TOP_VALUE}`],
      }
      const response = await context.api.copilotDash.getAISearchTicket({ query: undefined }, searchBody)
      try {
        if (response?.facetResults?.['tenantId']) {
          const sortedTenants = Object.entries(response.facetResults['tenantId'])
            .sort(([, valueA], [, valueB]) => valueB! - valueA!)
            .map(([key]) => key)
          return sortedTenants
        }
        return []
      } catch (error) {
        return []
      }
    },
  })
}
