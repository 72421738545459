import { ClusterResultColumnKey, IClusterJobResultSummary } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnClusterResultCount: ColDef<IClusterJobResultSummary> = {
  headerName: ClusterResultColumnKey.Count,
  field: String(ClusterResultColumnKey.Count) as NestedFieldPaths<IClusterJobResultSummary>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <TableCellLayoutWithStyles truncate>{props.data.tenantCount}</TableCellLayoutWithStyles>
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>Tenants</TableCellLayoutWithStyles>,
  },
  minWidth: 150,
  flex: 1,
  sortable: true,
}
