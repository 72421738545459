import { Column } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { MetricCard } from './MetricCard'
import { Scrollbar } from '../../../../../../../components/Scrollbar/Scrollbar'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function MetricView({ ticketId, messageId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getTelemetryMetrics(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        return (
          <Scrollbar className={styles.root}>
            <Column className={styles.root} gap="gap.small">
              {data.map((metric, index) => {
                return <MetricCard key={index} messageId={messageId} metric={metric} />
              })}
            </Column>
          </Scrollbar>
        )
      }}
    </TicketAsyncView>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px 8px',
  },
})
