import { TicketRouteArgs } from './TicketRouteArgs'
import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_ID = 'id'

export const TicketRouteMapper: IRouteMapper<TicketRouteArgs> = {
  mapToParams(args: TicketRouteArgs): IRouteParams {
    return {
      pathParams: {
        [PATH_PARAM_ID]: args.id,
      },
    }
  },

  mapToArgs(data: IRouteParams): TicketRouteArgs {
    const path = new RouteDataReader(data.pathParams)
    const id = path.getString(PATH_PARAM_ID)
    if (id === undefined) {
      throw new Error(
        `Unable to map route params to 'TicketRouteArgs'. Missing required path param: '${PATH_PARAM_ID}'`,
      )
    }

    return {
      id,
    }
  },
}
