import { AnyProperty, Column, Tab, TabItem, TabItemForMonaco } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketAsyncView } from '../../common/TicketAsyncView'

export function TicketContextView() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketContextSnapshot = app.store.use.getTicketContext(ticketId)

  return (
    <Column className={styles.root} vAlign="stretch" hAlign="stretch">
      <TicketAsyncView snapshot={ticketContextSnapshot}>
        {(data) => {
          return (
            <Tab>
              <TabItem name="Property">
                <AnyProperty data={data.properties} />
              </TabItem>
              <TabItem name="JSON">
                <TabItemForMonaco data={data.raw} />
              </TabItem>
            </Tab>
          )
        }}
      </TicketAsyncView>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: '0',
    overflow: 'hidden',
  },
})
