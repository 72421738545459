import {
  TeamViewSubMenuIds,
  SearchFilterFieldNames,
  FilterPanelConfig,
  ProductIds,
  SearchRouteConfig,
} from '@copilot-dash/domain'

function getConfig(productId: ProductIds) {
  return FilterPanelConfig[productId] ?? ({} as SearchRouteConfig)
}

export function getFilterItems(
  productId: ProductIds,
  route: 'search' | 'team',
  teamName?: string,
  subMenuID?: keyof typeof TeamViewSubMenuIds | 'default',
): SearchFilterFieldNames[] {
  const config = getConfig(productId)

  if (route === 'search') {
    if ('search' in config) {
      return config.search
    }
  } else if (route === 'team') {
    if ('team' in config) {
      let teamConfig
      if (teamName && teamName in config.team) {
        teamConfig = config.team?.[teamName]
      } else {
        teamConfig = config.team?.['default']
      }

      if (teamConfig) {
        if (subMenuID && subMenuID in teamConfig) {
          return teamConfig[subMenuID] ?? []
        }
        if ('default' in teamConfig) {
          return teamConfig['default'] ?? []
        }
      }
      if ('default' in config.team) {
        return config.team['default']['default']!
      }
    }
  }

  return []
}
