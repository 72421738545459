import { IClusterJobResultSummary } from '@copilot-dash/domain'
import { ColDef } from 'ag-grid-community'
import { ColumnClusterResultCount } from './ColumnClusterResultCount'
import { ColumnClusterResultIssue } from './ColumnClusterResultIssue'
import { ColumnClusterResultPercent } from './ColumnClusterResultPercent'
import { ColumnClusterResultPriority } from './ColumnClusterResultPriority'

export const AllClusterResultTableColumns = (): ColDef<IClusterJobResultSummary>[] => [
  ColumnClusterResultPriority,
  ColumnClusterResultIssue,
  ColumnClusterResultPercent,
  ColumnClusterResultCount,
]
