import { PromiseSnapshot } from '@copilot-dash/core'
import { IFilterTagData } from '@copilot-dash/domain'
import { convertTicketTagsFromApi } from './convertFilterTags'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getFilterTags(context: IDashStoreContext): PromiseSnapshot<IFilterTagData[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.basic.filterTags
    },
    set: (state, snapshot) => {
      state.basic.filterTags = snapshot
    },
    fetch: async () => {
      const response = await context.api.logCollector.getFilterTagList()
      return convertTicketTagsFromApi(response.filterTagList)
    },
  })
}
