import { PromiseSnapshot } from '@copilot-dash/core'
import { IUserp3sDebugData } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketEnvironment } from '../actions-ticket/getTicketEnvironment'
import { assertTicketApplicableForUserpSearch } from '../actions-ticket-assert/assertTicketApplicableForUserpSearch'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getTicketUserpDebugData(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IUserp3sDebugData> {
  return context.getOrFetch<IUserp3sDebugData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.userpSource
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.userpSource = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<IUserp3sDebugData> {
    await assertTicketHasUserConsent(context, ticketId)

    const environment = await getTicketEnvironment(context, ticketId).promise
    if (environment.ring?.toLowerCase() !== 'sdfv2') {
      throw TicketError.create('NoUserp3sDebugDataDueToNotSDFV2', { ticketId, messageId })
    }

    const source = await getTicketSource(context, ticketId).promise
    const sourceItem = source.turns[messageId]?.userpDebugResponse
    if (sourceItem?.url) {
      return {
        url: sourceItem.url,
        name: sourceItem.raw?.name,
        sourceItem,
      }
    }

    await assertTicketApplicableForUserpSearch(context, ticketId, 'source')
    throw TicketError.create('NoUserp3sDebugData', { ticketId, messageId, sourceItem })
  }
}
