import { Monaco } from '@monaco-editor/react'
import { AnyExplainers } from '../../any-explainer/AnyExplainers'

export function initMonacoTooltip(monaco: Monaco) {
  monaco.languages.registerHoverProvider('json', {
    provideHover: (model, position) => {
      const word = model.getWordAtPosition(position)
      if (!word) return

      const result = AnyExplainers.explain(word.word)
      if (!result?.markdown) return

      return {
        range: {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        },
        contents: [
          {
            value: result.markdown,
          },
        ],
      }
    },
  })
}
