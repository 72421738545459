import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const BATCH_UPDATE_TICKETS_PANEL_WIDTH = 386

export const useStyles = makeStyles({
  drawer: {
    width: `${BATCH_UPDATE_TICKETS_PANEL_WIDTH}px`,
    minHeight: '100%',
  },

  title: {
    alignItems: 'center',
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },

  form: {
    ...shorthands.padding('20px', '16px', '24px', '16px'),
    borderRadius: tokens.borderRadiusMedium,
    backgroundColor: tokens.colorNeutralBackground2,
  },

  addBtn: {
    ...shorthands.border('1px', 'solid', tokens.colorPaletteBlueBorderActive),
    color: tokens.colorCompoundBrandForeground1,

    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorCompoundBrandForeground1Hover),
      color: tokens.colorCompoundBrandForeground1Hover,
    },
  },

  suggestion: {
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.border('2px', 'dashed', tokens.colorPaletteBlueBorderActive),
    ...shorthands.padding('16px', '16px', '16px', '16px'),
  },

  suggestionActionArea: {
    marginTop: tokens.spacingVerticalL,
    marginBottom: tokens.spacingVerticalMNudge,
  },
  rootCauseMaxWidth: {
    maxWidth: '254px',
  },
  otherActions: {
    alignItems: 'flex-start',
  },
  otherActionsText: {
    color: tokens.colorNeutralForeground4,
  },
  querySet: {
    height: '38px',
    paddingLeft: 0,
  },
})
