import { useOverlayItem } from './OverlayItem'
import { useOverlayStore } from './store/OverlayStore'
import { closeTop } from './store-actions/closeTop'
import { open } from './store-actions/open'
import { openAsFullDialog } from './store-actions/openAsFullDialog'

export const Overlays = {
  use: useOverlayStore,
  useCurrentItem: useOverlayItem,
  open: open,
  openAsFullDialog: openAsFullDialog,
  closeTop: closeTop,
}
