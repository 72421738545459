import { Column, Row, Spacer } from '@copilot-dash/components'
import { Skeleton, SkeletonItem, makeStyles } from '@fluentui/react-components'

export function TurnViewSkeleton() {
  const styles = useStyles()

  return (
    <Skeleton aria-label="Loading Conversation">
      <Column className={styles.root} hAlign="stretch">
        {/* 1. Timestamp */}
        <Row className={styles.timestamp} hAlign="center">
          <SkeletonItem shape="rectangle" size={16} />
        </Row>

        {/* 2. Chat from user */}
        <Row className={styles.user}>
          <SkeletonItem className="avatar" shape="circle" size={24} />
          <Spacer size={12} />
          <SkeletonItem shape="rectangle" size={24} />
        </Row>

        {/* 3. Chat from copilot */}
        <Row className={styles.copilot}>
          <SkeletonItem className="avatar" shape="circle" size={24} />
          <Spacer size={12} />
          <SkeletonItem shape="rectangle" size={96} />
        </Row>
      </Column>
    </Skeleton>
  )
}

const useStyles = makeStyles({
  root: {
    '& .avatar': {
      flex: '0 0 auto',
    },
  },
  timestamp: {
    padding: '8px 16px',
    alignSelf: 'center',
    width: '128px',
  },
  user: {
    padding: '8px 16px',
    maxWidth: '80%',
  },
  copilot: {
    padding: '8px 16px',
  },
  suggestions: {
    padding: '8px 16px',
    paddingLeft: '48px',
    maxWidth: '50%',
    gap: '8px',
    '& > *': {
      flex: '0 0 auto',
    },
  },
})
