import { Column, Row, Spacer } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { FC } from 'react'
import { useStyles } from './StatusField.styles'
import { RenderDescription } from '../../../../utils/RenderDescription'
interface IProps {
  readonly activity?: IActivityHistory
}

export const RootCauseDescriptionRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()

  if (activity?.fieldDiffs?.Description?.oldValue === activity?.fieldDiffs?.Description?.newValue) return null

  return (
    <Column>
      <Row className={styles.columnContainer}>
        <Text className={styles.statueKey}>Description:</Text>
      </Row>
      {activity?.fieldDiffs?.Description?.newValue && (
        <Row className={styles.descriptionRow}>
          <RenderDescription rawDescription={activity?.fieldDiffs?.Description?.newValue} />
        </Row>
      )}
      <Spacer height="4px" />
      {activity?.fieldDiffs?.Description?.oldValue && (
        <Row className={styles.descriptionRow}>
          <RenderDescription rawDescription={activity?.fieldDiffs?.Description?.oldValue} className={styles.deleted} />
        </Row>
      )}
    </Column>
  )
}
