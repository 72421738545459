import { INewTicketData } from '@copilot-dash/domain'
import { useMemo } from 'react'

interface IResult {
  readonly utterance: string | undefined
  readonly utteranceTranslated: boolean
  readonly highlights: Array<string> | undefined
  readonly highlightsTranslated: boolean
}

export function useTranslatedTicketUtterance(ticket: INewTicketData): IResult {
  const translation = app.settings.translateContentToEnglish.use()

  return useMemo((): IResult => {
    const utteranceLanguage = ticket.utteranceLanguage

    const utterance = ticket.utterance
    const utteranceInEnglish = ticket.utteranceInEnglish
    const utteranceTranslated =
      translation &&
      !!utteranceInEnglish &&
      utteranceLanguage !== 'English' &&
      utterance?.trim() !== utteranceInEnglish?.trim()

    const highlight = ticket.utteranceWithHighlight
    const highlightInEnglish = ticket.utteranceInEnglishWithHighlight
    const highlightTranslated = translation && !!highlightInEnglish && utteranceLanguage !== 'English'

    return {
      utterance: utteranceTranslated ? utteranceInEnglish : utterance,
      utteranceTranslated: utteranceTranslated,
      highlights: highlightTranslated ? highlightInEnglish : highlight,
      highlightsTranslated: highlightTranslated,
    }
  }, [translation, ticket])
}
