import { Row, Spacer } from '@copilot-dash/components'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { ErrorViewPopoverHeaderLeading } from './ErrorViewPopoverHeaderLeading'
import { ErrorViewPopoverHeaderTrailing } from './ErrorViewPopoverHeaderTrailing'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
  readonly onClose?: () => void
}

export function ErrorViewPopoverHeader({ components, onClose }: IProps) {
  const styles = useStyles()

  return (
    <Row className={mergeClasses(styles.root)} vAlign="center">
      <ErrorViewPopoverHeaderLeading components={components} />
      <Spacer />
      <ErrorViewPopoverHeaderTrailing components={components} onClose={onClose} />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '4px 8px',
    minHeight: '32px',
  },
  icon: {
    color: tokens.colorStatusWarningForeground1,
    fontSize: '14px',
  },
  name: {
    color: tokens.colorStatusWarningForeground1,
  },
})
