import { ApiTicket, ApiTicketContext } from '@copilot-dash/api'
import { getProductIdByEndpoint, INewTicketData, TicketEmotionType } from '@copilot-dash/domain'
import { isAssignedTo3SDashTorus } from '../../../utils/is3SDashTorus'

export class NewTicketConverter {
  static readonly fromApi = (api: ApiTicket): INewTicketData => {
    return this.fromApiContext(api.context)
  }

  static readonly fromApiContext = (api: ApiTicketContext): INewTicketData => {
    const ticketId = api.externalTicketId
    return {
      ...api,
      status: this.getStatus(api),
      emotionType: api.thumbFeedback === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      productId: getProductIdByEndpoint(api.endpoint) ? getProductIdByEndpoint(api.endpoint) : undefined,
      ticketId,
      teamId: String(api.teamId),
      assignTo: isAssignedTo3SDashTorus(api.assignTo) ? undefined : api.assignTo, // `3SDashTorus`, it's a robot, should be ignored
    }
  }

  private static getStatus = (api: ApiTicketContext): INewTicketData['status'] => {
    switch (api.status) {
      case 'New':
      case 'Active':
      case 'Resolved':
      case 'Closed':
        return api.status
      default:
        return ''
    }
  }
}
