import { HomeRegular } from '@fluentui/react-icons'
import { OutlineItem } from './OutlineItem'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function OutlineItemForHome() {
  const actions = TicketDiagnosticStore.useActions()
  const active = TicketDiagnosticStore.use((state) => state.activeTab === TicketDiagnosticTab.Home)

  const handleOnActive = () => {
    actions.setActiveTab(TicketDiagnosticTab.Home)
  }

  return <OutlineItem icon={<HomeRegular />} title="Ticket" onActive={handleOnActive} active={active} />
}
