import { Row } from '@copilot-dash/components'
import { makeStyles, Text } from '@fluentui/react-components'

interface IProps {
  readonly name: string
}

export function OutlineGroupHeader({ name }: IProps) {
  const styles = useStyles()

  return (
    <Row vAlign="center">
      <Text weight="semibold" className={styles.root} block truncate wrap={false}>
        {name}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '4px 16px',
  },
})
