import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { FeedbackCopilotScreen } from '../../../screens/feedbackcopilot/FeedbackCopilotScreen'

export function FeedbackCopilotRoutePage() {
  return (
    <PageTitle title="FeedbackCopilot">
      <FeedbackCopilotScreen />
    </PageTitle>
  )
}
