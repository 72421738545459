import { Row } from '@copilot-dash/components'
import { Button, mergeClasses } from '@fluentui/react-components'
import { ChevronLeftRegular, ChevronRightRegular } from '@fluentui/react-icons'
import { throttle } from 'lodash'
import { Children, isValidElement, memo, useCallback, useEffect, useRef, useState } from 'react'
import { ResizeDimensions, useResizeObserver } from '../../hooks/useResizeObserver'

interface IProps {
  children: React.ReactNode
  className?: string
  containerClassName?: string
  onNavShow?: (showNav: boolean) => void
}

export const AdaptiveBar = memo(({ children, className, containerClassName, onNavShow }: IProps) => {
  const childrenCount = Children.count(children)
  const childrenRef = useRef<HTMLElement[]>([])
  const [showNav, setShowNav] = useState(false)
  const [pivot, setPivot] = useState(0)
  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr')
  const containerWidthRef = useRef(0)
  const [visibleChildrenIndexes, setVisibleChildrenIndexes] = useState(
    new Array(childrenCount).fill(true).map((_, i) => i),
  )
  let containerRef: React.RefObject<HTMLDivElement> = useRef(null)

  const onNavShowRef = useRef(onNavShow)
  useEffect(() => {
    onNavShowRef.current?.(showNav)
  }, [showNav])

  const recomputeVisibleChildrenIndexes = useCallback(() => {
    const width = containerWidthRef.current
    if (childrenCount === 1) {
      return
    }
    /**
     * Compute the width of each child using childrenRef.current
     */
    const childrenWidths = childrenRef.current.map((child) => child.getBoundingClientRect().width)
    const totalWidth = childrenWidths.reduce((acc, width) => acc + width, 0)

    const containerWidth = width

    const showNav = totalWidth > containerWidth
    setShowNav(showNav)
    if (!showNav) {
      setVisibleChildrenIndexes(new Array(childrenCount).fill(true).map((_, i) => i))
      return
    }

    // Set the min-width of the container to the width of the widest child
    const maxChildWidth = Math.max(...childrenWidths)
    if (containerRef.current) {
      containerRef.current.style.minWidth = `${maxChildWidth}px`
    }

    const newVisibleChildrenIndexes: number[] = []
    let currentWidth = 0

    function visitPivotRight() {
      for (let i = pivot; i < childrenCount; i++) {
        if (childrenWidths[i] === 0) {
          break
        }
        if (currentWidth + (childrenWidths[i] ?? Number.MAX_SAFE_INTEGER) > containerWidth) {
          break
        }
        currentWidth += childrenWidths[i]!
        newVisibleChildrenIndexes.push(i)
      }
    }
    function visitPivotLeft() {
      for (let i = pivot - 1; i > -1; i--) {
        if (childrenWidths[i] === 0) {
          break
        }
        if (currentWidth + (childrenWidths[i] ?? Number.MAX_SAFE_INTEGER) > containerWidth) {
          break
        }
        currentWidth += childrenWidths[i]!
        newVisibleChildrenIndexes.push(i)
      }
    }

    if (dir === 'ltr') {
      visitPivotRight()
      visitPivotLeft()
    } else if (dir === 'rtl') {
      visitPivotLeft()
      visitPivotRight()
    }
    setVisibleChildrenIndexes(newVisibleChildrenIndexes)
  }, [childrenCount, pivot, dir])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContainerResize = useCallback(
    throttle((width: number) => {
      containerWidthRef.current = width
      recomputeVisibleChildrenIndexes()
    }, 200),
    [recomputeVisibleChildrenIndexes],
  )

  containerRef = useResizeObserver(ResizeDimensions.Width, handleContainerResize)

  const cloneChild = useCallback(
    (node: React.ReactNode, index: number) => {
      return (
        <Row
          ref={(ref) => {
            if (ref) {
              childrenRef.current.push(ref)
            }
          }}
          style={{
            visibility: visibleChildrenIndexes.includes(index) ? 'visible' : 'hidden',
            position: Math.max(...visibleChildrenIndexes) < index ? 'absolute' : 'relative',
          }}
        >
          {node}
        </Row>
      )
    },
    [visibleChildrenIndexes],
  )

  const handleRightNav = useCallback(() => {
    const currentMaxIndex = Math.max(...visibleChildrenIndexes)
    if (currentMaxIndex >= childrenCount - 1) {
      return
    }
    setPivot(currentMaxIndex + 1)
    setDir('ltr')
  }, [childrenCount, visibleChildrenIndexes])

  const handleLeftNav = useCallback(() => {
    const currentMinIndex = Math.min(...visibleChildrenIndexes)
    if (currentMinIndex === 0) {
      return
    }
    setPivot(Math.max(currentMinIndex - 1, 0))
    setDir('rtl')
  }, [visibleChildrenIndexes])

  // Recompute visible children indexes on mount / children change / pivot change
  useEffect(() => {
    recomputeVisibleChildrenIndexes()
  }, [recomputeVisibleChildrenIndexes])

  childrenRef.current = []
  return (
    <Row className={mergeClasses(className)}>
      <Row
        ref={containerRef}
        className={containerClassName}
        style={{
          flexShrink: 1,
          flexGrow: 1,
          minWidth: 0,
          justifyContent: 'end',
        }}
      >
        {childrenCount === 1
          ? cloneChild(children, 0)
          : Children.map(children, (child, index) => {
              if (isValidElement(child)) {
                return cloneChild(child, index)
              }
              return child
            })}
      </Row>
      {showNav ? (
        <Row>
          <Button
            onClick={handleLeftNav}
            disabled={Math.min(...visibleChildrenIndexes) <= 0}
            size="small"
            appearance="subtle"
            icon={<ChevronLeftRegular />}
          />
          <Button
            onClick={handleRightNav}
            disabled={Math.max(...visibleChildrenIndexes) >= childrenCount - 1}
            size="small"
            appearance="subtle"
            icon={<ChevronRightRegular />}
          />
        </Row>
      ) : null}
    </Row>
  )
})

AdaptiveBar.displayName = 'AdaptiveBar'
