const COLUMN_SIZE_MIN = 60
const COLUMN_SIZE_MAX = 500
const COLUMN_PADDING = 16

const SAMPLE_CHAR_SIZE = 8

export function generateAnyTextLength(text: string): number {
  const length = 2 * COLUMN_PADDING + text.length * SAMPLE_CHAR_SIZE
  return Math.max(COLUMN_SIZE_MIN, Math.min(COLUMN_SIZE_MAX, length))
}
