import { Api3sOnlineDataGroup3 } from '@copilot-dash/api'
import { produce, PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { sortBy } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOnlineExists } from '../actions-ticket-assert/assertTicket3sOnlineExists'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRaw3sOnlineDataGroup3(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<Api3sOnlineDataGroup3> {
  return context.getOrFetch<Api3sOnlineDataGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.raw3sOnlineDataGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.raw3sOnlineDataGroup3 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.substrateSearchOnlineGroup3
      if (sourceItem?.url) {
        const promise = context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asSubstrateSearchOnlineGroup3()
        return sort(await promise)
      }

      await assertTicket3sOnlineExists(context, ticketId, messageId)
      throw TicketError.create('No3sOnline', { ticketId, messageId, sourceItem })
    },
  })

  function sort(data: Api3sOnlineDataGroup3): Api3sOnlineDataGroup3 {
    const map = getBestEntityOrderMap(data)

    return produce(data, (draft) => {
      draft.forEach((item) => {
        item.ReplayResponse.TrimmedResponse?.EntitySets?.forEach((entitySet) => {
          entitySet.ResultSets?.forEach((resultSet) => {
            if (resultSet.Results) {
              resultSet.Results = sortBy(resultSet.Results, (result) => {
                return map[result.Id ?? ''] ?? Number.MAX_SAFE_INTEGER
              })
            }
          })
        })
      })
    })
  }

  /**
   * The reference logic:
   *
   * https://msasg.visualstudio.com/WIT%20Engineering/_git/TuringAtWork?path=/services/TuringBot/src/Service/dev-ui/apps/client-app/src/features/telemetry-results-detail/3sexplain/explore/results-view/ResultsView.tsx&version=GBmaster&line=32&lineEnd=57&lineStartColumn=1&lineEndColumn=2&lineStyle=plain&_a=contents
   */
  function getBestEntityOrderMap(data: Api3sOnlineDataGroup3): Record<string, number> {
    const ids: string[] = []

    for (const item of data) {
      for (const ranking of item.ReplayResponse?.TrimmedResponse?.WholePageRankings ?? []) {
        if (ranking.CardPlacement !== 'MainLine') {
          break
        }

        for (const card of ranking.Cards ?? []) {
          for (const entityCard of card.EntityCards ?? []) {
            if (entityCard.EntityIds && entityCard.EntityResultType === 'Serp') {
              ids.push(...entityCard.EntityIds)
            }
          }
        }
      }
    }

    return Object.fromEntries(ids.map((id, index) => [id, index]))
  }
}
