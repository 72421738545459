import { ApiError } from '@copilot-dash/api'
import { Column, Spacer } from '@copilot-dash/components'
import { ApiRequestTable } from './request/ApiRequestTable'
import { ApiRequestTableTitle } from './request/ApiRequestTableTitle'
import { ApiResponseTable } from './response/ApiResponseTable'
import { ApiResponseTableTitle } from './response/ApiResponseTableTitle'

interface IProps {
  readonly error: ApiError
}

export function ApiErrorContextView({ error }: IProps) {
  return (
    <Column hAlign="start">
      <ApiRequestTableTitle />
      <ApiRequestTable error={error} />

      <Spacer size="8px" />

      <ApiResponseTableTitle />
      <ApiResponseTable error={error} />
    </Column>
  )
}
