import { Column, Right, Row, Spacer } from '@copilot-dash/components'
import { TimeRange, Times } from '@copilot-dash/core'
import { ColumnKey, INewTicketData, ProductIds, SearchTextPrefixType, columnKeySchema } from '@copilot-dash/domain'
import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { ColDef } from 'ag-grid-community'
import { compact, isNil, uniqBy } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { AGGridTable } from '../../../../../../components/AGGridTable/AGGridTable'
import { ColumnSettingButton } from '../../../../../../components/Button/ColumnSettingButton'
import { AllSearchTableColumns } from '../../../../../../components/TicketTableColumnConfigs'
import { SearchRoute } from '../../../../../../router'
import { useSearchScreenActions, useSearchScreenState } from '../../../../store'

import { LoaderComponent } from '../../../loader/LoaderComponent'

interface IProps {
  productId: ProductIds
}

export const VIPUserDSATs = memo(({ productId }: IProps) => {
  const style = styles()
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const [count, setCount] = useState<number | undefined>()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const isColumnSettingPanelOpen = useSearchScreenState((state) => state.isColumnSettingPanelOpen)

  const columns = app.settings.feedbackInsightsVipDSATsColumns.use()
  const setColumns = app.settings.feedbackInsightsVipDSATsColumns.set

  const handleColumnsChange = (columns: Array<string>) => {
    const movedColumns = columns
      .map((column) => columnKeySchema.safeParse(column))
      .filter((result) => result.success)
      .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
    setColumns(movedColumns)
  }

  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    const defaultColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      columns.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )
    return newSelectedColumns
  }, [columns])

  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const args = SearchRoute.navigator.useArgsOptional()
  const batchList = application.store.use((state) => (productId ? state.topIssue.batchList?.[productId] : null))

  useEffect(() => {
    actions.updateColumnSettingPanelVisibility(false)
  }, [actions])

  const range = useMemo((): TimeRange | undefined => {
    const batchInfo = batchList?.data?.find((item) => item.batchId === args?.issueBatchId)
    if (batchInfo) {
      const from = Times.startOfDay(batchInfo.from, 'UTC')
      const to = Times.endOfDay(batchInfo.to, 'UTC')
      return {
        type: 'absolute',
        from,
        to,
      }
    } else {
      return undefined
    }
  }, [args?.issueBatchId, batchList])

  const handleLoadMore = useCallback(
    (offset = 0) => {
      if (!range) {
        return
      }
      const query = SearchRoute.navigator.getArgsOptional()
      offset === 0 && setLoading(true)
      app.store.actions
        .searchTickets({
          product: query?.product || 'M365Chat',
          defaultRange: range,
          count: 20,
          offset: offset,
          isAIF: false,
          customerTypes: ['VIP Power User'],
          thumbs: ['Negative'],
          hasCopilotExtensionIds:
            productId === ProductIds.M365Agent ? ['HasCopilotExtensionIds'] : ['!HasCopilotExtensionIds'],
        })
        .then((resp) => {
          setCount(resp.ticketCount)
          setHasMore(resp.hasMore)
          setTableItems((prev) => uniqBy([...prev, ...(resp.tickets || [])], 'ticketId'))
        })
        .finally(() => {
          offset === 0 && setLoading(false)
        })
    },
    [productId, range],
  )

  useEffect(() => {
    if (!range) {
      return
    }
    handleLoadMore()
  }, [handleLoadMore, range])

  return (
    <Column className={style.container}>
      <Row space="between" className={style.title}>
        <Text weight="semibold" size={500}>
          VIP User DSATs {isNil(count) ? null : `(${count})`}
        </Text>
        <Right>
          <ColumnSettingButton
            placeholder="Columns"
            isColumnSettingPanelOpen={isColumnSettingPanelOpen}
            onClickButton={actions.handleColumnSettingButtonClick}
          />
        </Right>
      </Row>
      <Spacer height={22} />
      <Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
            <Spacer height={50} />
          </Column>
        ) : (
          <AGGridTable
            rowData={tableItems}
            getRowId={(data) => data.data.ticketId}
            columnDefs={colDefs}
            className={style.table}
            onRowClicked={actions.rowClick}
            focusedRowId={ticketId}
            domLayout="autoHeight"
            suppressRowVirtualisation={true}
            hasMore={hasMore}
            loadMore={handleLoadMore}
            handleColumnsChange={handleColumnsChange}
          />
        )}
      </Row>
    </Column>
  )
})
VIPUserDSATs.displayName = 'VIPUserDSATs'

const styles = makeStyles({
  container: {
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)`,
    borderRadius: '4px',
  },
  title: {
    width: '100%',
    padding: '20px',
    paddingBottom: 0,
  },
  table: {
    width: '100%',
  },
})
