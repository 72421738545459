import { getProductIdByName } from '@copilot-dash/domain'
import { memo, useCallback } from 'react'
import { BatchUpdateTicketsPanel } from '../../../../components/BatchTicketsOperationPanel/BatchUpdateTicketsPanel'
import { SearchRoute } from '../../../../router'
import { useSearchScreenActions, useSearchScreenState } from '../../store'

export const BatchOperationPanel = memo(() => {
  const actions = useSearchScreenActions()
  const open = useSearchScreenState((state) => state.isBatchOperationPanelOpen)
  const productName = SearchRoute.navigator.useArgsOptional()?.product
  const productId = getProductIdByName(productName)
  const tickets = useSearchScreenState((state) => state.selectedTickets)

  const onClose = useCallback(() => {
    actions.updateBatchOperationPanelVisibility(false)
  }, [actions])

  if (!productId) {
    return null
  }

  return <BatchUpdateTicketsPanel open={open} productId={productId} onClose={onClose} tickets={tickets} />
})

BatchOperationPanel.displayName = 'BatchOperationPanel'
