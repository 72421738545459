import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.padding('30px', '160px', '0px', '160px'),
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  content: {
    width: '100%',
    height: 'calc(100% - 100px)',
    display: 'flex',
    flexBasis: 'auto',
  },
  publishButton: {
    height: '40px',
    backgroundColor: tokens.colorCompoundBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    width: '85px',
    padding: '0 16px',
  },
  cancelButton: {
    height: '40px',
    width: '85px',
    padding: '0 16px',
  },
  cardsContainer: {
    display: 'flex',
    ...shorthands.borderRadius('8px'),
    height: '100%',
  },
  resultContainer: {
    ...shorthands.flex(1),
  },
  operationRow: {
    borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
    ...shorthands.padding('16px', '160px'),
    height: '90px',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalS,
    bottom: 0,
    flex: '0 0 auto',
  },
})
