import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketLastTurnMessageId(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch<string>({
    get: (state) => {
      return state.tickets[ticketId]?.lastTurnMessageId
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.lastTurnMessageId = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      return await doFetch()
    },
  })

  async function doFetch(): Promise<string> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const messageId = raw.diagnosticContext?.chat?.[0]?.messageId
    if (messageId) {
      return messageId
    }

    throw TicketError.create('NoConversations', { ticketId })
  }
}
