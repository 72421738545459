import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { LoginScreen } from '../../../screens/login/LoginScreen'
import { ClarityScenarioIds, useClaritySectionId } from '../../../utils/useClarityPageId'

export function LoginRoutePage() {
  useClaritySectionId(ClarityScenarioIds.login)
  return (
    <PageTitle title="Login">
      <LoginScreen />
    </PageTitle>
  )
}
