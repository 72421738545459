import { ApiClusterJobData } from '@copilot-dash/api'
import { ClusterJobResult } from '../ClusterJobResult'

export function convertClusterJobList(apiData: Array<ApiClusterJobData>): ClusterJobResult {
  const returnData: ClusterJobResult = {
    jobCount: apiData.length,
    jobs: apiData.map((item) => {
      return { ...item }
    }),
    hasMore: false,
  }

  return returnData
}
