import { AttachRegular } from '@fluentui/react-icons'
import { OutlineItem } from './OutlineItem'
import { TicketDiagnosticTab } from '../../store/IState'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

export function OutlineItemForTicketSourceTable() {
  const actions = TicketDiagnosticStore.useActions()
  const active = TicketDiagnosticStore.use((state) => state.activeTab === TicketDiagnosticTab.Table)

  const handleOnActive = () => {
    actions.setActiveTab(TicketDiagnosticTab.Table)
  }

  return (
    <OutlineItem icon={<AttachRegular />} title="Ticket Metadata Sources" onActive={handleOnActive} active={active} />
  )
}
