import { makeStyles } from '@fluentui/react-components'
import { TabStore } from '../../TabStore'

export function HeaderToolbar() {
  const styles = useStyles()

  const activeItem = TabStore.use((state) => {
    return state.items.find((item) => {
      return item.name === state.activeTab
    })
  })

  if (!activeItem?.toolbar) {
    return null
  }

  return <div className={styles.root}>{activeItem.toolbar}</div>
}

const useStyles = makeStyles({
  root: {
    flex: '0 0 auto',
  },
})
