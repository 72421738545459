import { Column } from '@copilot-dash/components'
import { ErrorView } from '../../../../../../../../components/Error'

interface IProps {
  readonly error: unknown
}

export function TreeItemErrorView({ error }: IProps) {
  return (
    <Column vAlign="center" hAlign="start" fill style={{ padding: '4px 0' }}>
      <ErrorView type="inline" error={error} />
    </Column>
  )
}
