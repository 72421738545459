import { z } from 'zod'

export enum SearchTextPrefixType {
  All = 'All',
  Utterance = 'Utterance',
  Response = 'Response',
  Verbatim = 'Verbatim',
  Query = 'Search Query',
}

export const SearchTextPrefixTypeSchema = z.enum([
  SearchTextPrefixType.All,
  SearchTextPrefixType.Utterance,
  SearchTextPrefixType.Response,
  SearchTextPrefixType.Verbatim,
  SearchTextPrefixType.Query,
])

export const DefaultOptionsArray: SearchTextPrefixType[] = [
  SearchTextPrefixType.All,
  SearchTextPrefixType.Utterance,
  SearchTextPrefixType.Response,
  SearchTextPrefixType.Verbatim,
]

export const UserpOptionsArray: SearchTextPrefixType[] = [
  SearchTextPrefixType.All,
  SearchTextPrefixType.Utterance,
  SearchTextPrefixType.Response,
  SearchTextPrefixType.Verbatim,
  SearchTextPrefixType.Query,
]
