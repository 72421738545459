import { z } from 'zod'
import { Email, RootCauseId, WorkItemId } from '../basic'
import { TeamId } from '../team/ITeamList'

export enum WorkItemStatus {
  Untriaged = 'Untriaged',
  TeamAssigned = 'TeamAssigned',
  RootCaused = 'RootCaused',
  ClosedFixed = 'ClosedFixed',
  ClosedByDesign = 'ClosedByDesign',
  ClosedNotActionable = 'ClosedNotActionable',
}

export interface IWorkItem {
  workItemId: WorkItemId
  adoWorkItemId?: string
  dsatStatus: WorkItemStatus
  priority: string
  teamId: TeamId
  assignTo?: Email
  adoWorkItemLink?: string
  closedComment?: string
  rootCauseIdList: RootCauseId[]
}

export const workItemStatusSchema = z.nativeEnum(WorkItemStatus)

export type IUpdateWorkItem = Partial<IWorkItem> & Pick<IWorkItem, 'workItemId'>

export const UN_ASSIGNED = 'Unassigned'
export const ANYONE = 'Anyone'
