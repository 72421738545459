import { ApiV2RootCauseRecommendation } from '@copilot-dash/api'
import { IRootCauseRecommendation, RootCauseActionTypeV2 } from '@copilot-dash/domain'

export function convertRCRFromAPItoDomain(apiResponse: ApiV2RootCauseRecommendation): IRootCauseRecommendation {
  return {
    ...apiResponse,
    teamId: apiResponse.teamId.toString(),
    userAction: apiResponse.userAction as RootCauseActionTypeV2,
  }
}
