import { z } from 'zod'

const apiUserpGroup4ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
  }),
)

const apiUserpGroup4ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiUserpGroup4ReplayResponseSchema.optional(),
  }),
)

export const apiUserpGroup4Schema = z.array(apiUserpGroup4ItemSchema)
