import { ApiV2BatchUpdateWorkItemCustomTagsRequest } from '@copilot-dash/api'
import { IUpdateWorkItemCustomTags } from '@copilot-dash/domain'

export function convertWorkItemCustomTagFromDomainToAPI(
  workItemCustomTags: IUpdateWorkItemCustomTags[],
): ApiV2BatchUpdateWorkItemCustomTagsRequest {
  return workItemCustomTags.map((item) => ({
    ticketId: item.ticketId,
    customTags: item.customTags,
    workItemEntityId: item.workItemEntityId,
  }))
}
