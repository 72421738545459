import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketUserConsent(context: IDashStoreContext, ticketId: string): PromiseSnapshot<boolean> {
  return context.getOrFetch<boolean>({
    get: (state) => {
      return state.tickets[ticketId]?.hasUserConsent
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.hasUserConsent = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise
      return raw.userFeedback.hasUserConsent
    },
  })
}
