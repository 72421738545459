import { IAssignedTeamMembers, ProductIds, TeamId } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'

export async function getAssignedTeamMembers(
  context: IDashStoreContext,
  productId: ProductIds,
  teamId: TeamId,
): Promise<IAssignedTeamMembers> {
  const response = await context.api.copilotDash.getPeopleAssignments(teamId)
  const convertedData: string[] = response.filter((item) => {
    return isAssignedTo3SDashTorus(item) ? '' : item
  })
  return { assignedTeamMembers: convertedData, errorMessage: '' }
}
