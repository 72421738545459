import Clarity from '@microsoft/clarity'

export function initClarity(): void {
  // Clarity
  // https://clarity.microsoft.com/projects/view/pt7ljy88tv/dashboard?date=Last%203%20days
  // Please concat Ethanfeng if you don't have permission to view the Clarity dashboard
  Clarity.init('pt7ljy88tv')
  const activeAccount = app.auth.activeAccount
  if (activeAccount?.localAccountId) {
    Clarity.identify(activeAccount.localAccountId)
  } else {
    Clarity.identify('Unauthenticated User')
  }
}
