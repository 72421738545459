import { ThemeModeProvider } from '@copilot-dash/components'
import { FluentProvider } from '@fluentui/react-components'
import { useMemo } from 'react'
import { getTheme } from './Themes'

interface IProps {
  readonly isDark?: boolean
  readonly className?: string
  readonly children: React.ReactNode
}

export function ThemeProvider(props: IProps) {
  const themeMode = app.settings.themeMode.use()
  const isDarkTheme = props.isDark ?? themeMode === 'dark'

  const theme = useMemo(() => {
    return getTheme(isDarkTheme)
  }, [isDarkTheme])

  return (
    <FluentProvider className={props.className} theme={theme}>
      <ThemeModeProvider theme={isDarkTheme ? 'dark' : 'light'}>{props.children}</ThemeModeProvider>
    </FluentProvider>
  )
}
