import { PromiseSnapshots } from '@copilot-dash/core'
import { IUpdateWorkItemCustomTags } from '@copilot-dash/domain'
import { convertWorkItemCustomTagFromDomainToAPI } from './converters/convertWorkItemCustomTagFromDomainToAPI'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function batchUpdateWorkItemCustomTags(
  context: IDashStoreContext,
  request: Array<IUpdateWorkItemCustomTags>,
  signal?: AbortSignal,
) {
  return context.requestAnd({
    request: async () => {
      return await context.api.copilotDash.patchWorkItemCustomTags(
        convertWorkItemCustomTagFromDomainToAPI(request),
        signal,
      )
    },
    onSuccess(state, data) {
      for (const successfulTicketIdId of data.updateSuccessTicketIdList) {
        const ticketInStore = state.tickets[successfulTicketIdId]
        if (ticketInStore) {
          const ticketToUpdate = request.find((item) => item.ticketId === successfulTicketIdId)
          if (!ticketToUpdate) continue
          ticketInStore.customTags = PromiseSnapshots.done(ticketToUpdate.customTags)
          if (ticketInStore.ticket?.data) {
            ticketInStore.ticket.data.customTags = ticketToUpdate.customTags
          }
        }
      }
    },
  })
}
