import { PromiseSnapshot } from '@copilot-dash/core'
import { DefaultProductEndpoints, ITicketEnvironment } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketEnvironment(
  context: IDashStoreContext,
  ticketId: string,
): PromiseSnapshot<ITicketEnvironment> {
  return context.getOrFetch<ITicketEnvironment>({
    get: (state) => {
      return state.tickets[ticketId]?.environment
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.environment = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise

      const endpoint = DefaultProductEndpoints.find((item) => {
        return item.name.toLocaleLowerCase() === raw.environment?.clientName.toLocaleLowerCase()
      })

      return {
        clientName: raw.environment?.clientName,
        scenarioName: raw.environment?.scenarioName,
        ring: raw.environment?.ring,
        productId: endpoint?.productId,
        endpoint,
      }
    },
  })
}
