import { Column, Row, Spacer } from '@copilot-dash/components'
import { IUpdateRootCause } from '@copilot-dash/domain'
import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Skeleton,
  SkeletonItem,
  Text,
  tokens,
} from '@fluentui/react-components'
import { Edit16Filled, MoreHorizontalRegular } from '@fluentui/react-icons'
import { FC, memo, useState } from 'react'
import { LexicalRichTextEditor } from '../../../../../components/Lexical/LexicalRichTextEditor'
import { useToast } from '../../../../../hooks/useToast'
import { useGlobalStore } from '../../../../../store'
import { RenderDescription } from '../../../../../utils/RenderDescription'
import { useTeamViewStore } from '../../../store'

export const RootCauseDescription: FC = memo(() => {
  const styles = useStyles()
  const [openEdit, setOpenEdit] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
  const productId = useTeamViewStore((state) => state.computed.productId)
  const teamId = useTeamViewStore((state) => state.teams.lastSelectedTeamId)
  const rootCauseInfoSnapshotV2 = application.store.use(
    (state) => state.team.v2RootCauseMap?.[`${selectedRootCauseId}`],
  )
  const toast = useToast()
  const onSaveDiscussion = async (value: string | undefined): Promise<void> => {
    const postData: IUpdateRootCause = {
      rootCauseTitle: rootCauseInfoSnapshotV2?.data?.rootCauseTitle ?? '',
      description: value ?? '',
      rootCausePriority: rootCauseInfoSnapshotV2?.data?.rootCausePriority ?? 3,
      teamId: rootCauseInfoSnapshotV2?.data?.teamId ?? '',
      owner: rootCauseInfoSnapshotV2?.data?.owner,
      rootCauseStatus: rootCauseInfoSnapshotV2?.data?.rootCauseStatus ?? '',
      issueId: rootCauseInfoSnapshotV2?.data?.issueId ?? '',
      eTA: rootCauseInfoSnapshotV2?.data?.eTA,
    }
    return useTeamViewStore
      .getState()
      .rootCauses.updateTeamRootCause(postData)
      .then((resp) => {
        if (resp.errorMessage === '') {
          setOpenEdit(false)
          useGlobalStore.getState().updateRootCause(postData)
          productId && application.store.actions.updateRootCauseByTeam(productId, teamId ?? '', postData)
          toast.showSuccess('Save successfully')
        } else {
          toast.showError('Failed to save', resp.errorMessage)
        }
      })
  }

  return (
    <Column>
      <Text size={300} className={styles.title} weight="semibold">
        Description
      </Text>
      <Spacer height={4} />
      {rootCauseInfoSnapshotV2?.status === 'waiting' ? (
        <Skeleton>
          <Column>
            <SkeletonItem size={16} />
            <Spacer height={4} />
            <SkeletonItem size={16} />
            <Spacer height={4} />
            <SkeletonItem size={16} />
          </Column>
        </Skeleton>
      ) : (
        <Column
          className={styles.descriptionRoot}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Column>
            {isHovered && (
              <Row className={styles.edit}>
                <Menu positioning={'before-bottom'}>
                  <MenuTrigger>
                    {!openEdit && rootCauseInfoSnapshotV2?.data?.description ? (
                      <Button icon={<MoreHorizontalRegular />} appearance="transparent" />
                    ) : null}
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        icon={<Edit16Filled />}
                        onClick={() => {
                          setOpenEdit(true)
                        }}
                      >
                        Edit
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </Row>
            )}
          </Column>
          {!openEdit && rootCauseInfoSnapshotV2?.data?.description ? (
            <RenderDescription
              rawDescription={rootCauseInfoSnapshotV2?.data?.description ?? ''}
              isHovered={isHovered}
            />
          ) : (
            <Row className={styles.editorRoot}>
              <LexicalRichTextEditor
                initialValue={rootCauseInfoSnapshotV2?.data?.description}
                mode="create"
                placeholder="Add description"
                role="description"
                onSubmit={onSaveDiscussion}
                onCancel={() => {
                  setOpenEdit(false), setIsHovered(false)
                }}
              />
            </Row>
          )}
        </Column>
      )}
    </Column>
  )
})

RootCauseDescription.displayName = 'RootCauseDescription'

const useStyles = makeStyles({
  descriptionRoot: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  editorRoot: {
    background: tokens.colorNeutralBackground3,
    position: 'relative',
    width: '100%',
    minHeight: '160px',
  },
  edit: { position: 'absolute', top: '-25px', right: '-4px' },
  title: { color: tokens.colorNeutralForeground4 },
})
