import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRaw3sOnlineDataGroup2 } from '../actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from '../actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup3'

export function get3sOnlineGeneralDiagnosticData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
  transactionId: string,
): PromiseSnapshot<unknown> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.sssOnlineGeneralDiagnosticData?.[transactionId]
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      const sssGeneralDiagnosticData = (turn.sssOnlineGeneralDiagnosticData ??= {})
      sssGeneralDiagnosticData[transactionId] = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<object> {
    const group2Promise = getRaw3sOnlineDataGroup2(context, ticketId, turnId).promise
    const group3Promise = getRaw3sOnlineDataGroup3(context, ticketId, turnId).promise

    const group2 = await group2Promise.catch(() => undefined)
    const group3 = await group3Promise.catch(() => undefined)
    if (!group2 && !group3) {
      await group2Promise
      await group3Promise
    }

    const group3Index = group3?.findIndex((item) => item.ReplayResponse.TraceId === transactionId) ?? -1
    const group3Item = group3?.[group3Index]

    let group2Item: unknown
    group2Item ??= group2?.find((item) => item.ReplayResponse?.TraceId === transactionId)
    group2Item ??= group2?.[group3Index]
    group2Item ??= group2

    return {
      lu: group2Item,
      search: group3Item,
    }
  }
}
