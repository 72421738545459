import { Column } from '@copilot-dash/components'
import { DrawerBody, makeStyles } from '@fluentui/react-components'
import { RootCauseDetailView } from './rootCause-detail/RootCauseDetailView'
import { ActivityHistoryPanel } from '../../../../components/ActivityHistory/ActivityHistoryPanel'
import { DiscussionPanel } from '../../../../components/DiscussionPanel/DiscussionPanel'
import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'
import { useTeamViewStore } from '../../store'
import { RootCauseScreenDrawerType } from '../../store/types'

interface IProps {
  readonly type: RootCauseScreenDrawerType
}

export function RootCauseScreenDrawerBody({ type }: IProps) {
  const styles = useStyles()
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)

  return (
    <DrawerBody className={styles.root} style={{ paddingBottom: 0 }}>
      <Column className={styles.container} vAlign="stretch" hAlign="stretch">
        <Scrollbar className={styles.scrollbar}>
          {(() => {
            switch (type) {
              case RootCauseScreenDrawerType.RootCauseDetail:
                return <RootCauseDetailView />
              case RootCauseScreenDrawerType.DiscussionPanel:
                return <DiscussionPanel discussionId={selectedRootCauseId!} />
              case RootCauseScreenDrawerType.ActivityHistory:
                return <ActivityHistoryPanel ticketId={selectedRootCauseId!} />
            }
          })()}
        </Scrollbar>
      </Column>
    </DrawerBody>
  )
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
  },
  scrollbar: {
    height: '100%',
  },
})
