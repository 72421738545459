import { IProductData, parseProductName } from '@copilot-dash/domain'
import { useCallback, useMemo } from 'react'
import { IClusterRouteArgs } from './types'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { ClusterScreen } from '../../../screens/cluster/ClusterScreen'
import { createNavigator } from '../../navigator/createNavigator'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'

interface IClusterRoutePageProps {
  ClusterNavigator: ReturnType<typeof createNavigator<IClusterRouteArgs>>
}

export function ClusterRoutePage(props: IClusterRoutePageProps) {
  const { ClusterNavigator } = props
  const args = ClusterNavigator.useArgs()
  const handleSelectProduct = useCallback(
    (product: IProductData) => {
      ClusterNavigator.navigate({
        product: product.name,
      })
    },
    [ClusterNavigator],
  )

  const isProductValid = useMemo(() => {
    return parseProductName(args.product) !== undefined
  }, [args.product])

  if (!isProductValid) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector value={args.product} onChange={handleSelectProduct}>
      <PageTitle title="Cluster">
        <ClusterScreen key={args.product} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
