import { ApiAISearchRootCauseItem, ApiAISearchRootcauseResponse } from '@copilot-dash/api'
import { IRootCauseData } from '@copilot-dash/domain'
import { isAssignedTo3SDashTorus } from '../../../utils/is3SDashTorus'
import { translateRootCausePriority } from '../../../utils/translateRootCausePriority'
import { IAISearchRootCausesResult } from '../AISearchRootCauses.types'

function convertRootCauseFromAISearchResponseToDomain(rc: ApiAISearchRootCauseItem): IRootCauseData {
  return {
    issueId: rc.id,
    createdTimeUtc: rc.createdTimeUtc,
    rootCauseTitle: rc.title,
    rootCausePriority: translateRootCausePriority(rc.priority),
    owner: isAssignedTo3SDashTorus(rc.assignTo) ? undefined : rc.assignTo,
    rootCauseStatus: rc.status,
    teamId: rc.teamId,
    rootCauseType: 'Issue',
    visible: true,
    enable: rc.isRcrEnabled ?? false,
    eTA: rc.eta,
    description: rc.description,
  }
}

export function convertRootCausesFromAISearchResponseToDomain(
  response: ApiAISearchRootcauseResponse,
): IAISearchRootCausesResult {
  const rootCauseList = response.searchItems.map((rc) => convertRootCauseFromAISearchResponseToDomain(rc))

  return {
    rootCauseList,
    hasMore: response.startIndex + response.stepNumber < response.count,
    totalCount: response.count,
    startIndex: response.startIndex,
    stepNumber: response.stepNumber,
    facetResults: response.facetResults,
  }
}
