import { Overlays } from '@copilot-dash/components'
import { Button, Tooltip } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'

export function TicketScreenCloseButton() {
  const overlay = Overlays.useCurrentItem()
  if (!overlay) {
    return
  }

  const handleClick = () => {
    overlay.close()
  }

  return (
    <Tooltip content="Close" relationship="label">
      <Button appearance="subtle" onClick={handleClick} icon={<DismissRegular fontSize={24} />} />
    </Tooltip>
  )
}
