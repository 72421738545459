import { DefaultProductEndpoints } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { useMemo } from 'react'
import { ValueViewForStringIndicatorEndpoint } from './ValueViewForStringIndicatorEndpoint'

interface IProps {
  readonly value: string
}

export function ValueViewForStringExplainer({ value }: IProps) {
  const styles = useStyles()

  const children = useMemo(() => {
    const endpoint = DefaultProductEndpoints.find((endpoint) => endpoint.name.toLowerCase() === value.toLowerCase())
    if (endpoint) {
      return <ValueViewForStringIndicatorEndpoint value={value} endpoint={endpoint} />
    }

    return null
  }, [value])

  if (!children) {
    return null
  }

  return <span className={styles.root}>{children}</span>
}

const useStyles = makeStyles({
  root: {
    opacity: 0.38,
    display: 'inline-block',
    paddingLeft: '16px',
  },
})
