import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const conversationIds = generateKustoStringArrayExp([params.conversationId])
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
macro-expand force_remote=true augloop_odx_global as X (
X.database('ODX AugLoop Service').OperationEventV2
| where operationName startswith 'SydneyAgent'
| where PreciseTimeStamp ${timeRange}
| extend json = parse_json(dataFields)
| extend conversationId = tostring(json.conversationId)
| where conversationId in (${conversationIds})
| project operationName, success, resultDescription, durationMs, conversationId, PreciseTimeStamp
);
`
}

export const AugLoop_WW: IKustoQueryDefinition = {
  database: 'ODX AugLoop Service',
  connection: 'https://odxaugloop.eastus.kusto.windows.net',
  query,
}
