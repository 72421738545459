import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSystemData } from '@copilot-dash/domain'
import { convertTicketTagData } from './converters/convertTicketTagData'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketSystemTags(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketSystemData> {
  return context.getOrFetch<ITicketSystemData>({
    get: (state) => {
      return state.tickets[ticketId]?.systemTags
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.systemTags = snapshot
    },
    fetch: async () => {
      const raw = await getRawV2Ticket(context, ticketId).promise
      return convertTicketTagData(raw.diagnosticContext?.tags ?? raw.tags ?? [])
    },
  })
}
