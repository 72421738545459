import { PromiseSnapshot } from '@copilot-dash/core'
import { IQuery, ProductIds, TeamId } from '@copilot-dash/domain'
import { QueryDataConverter } from './converters/QueryDataConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTeamQueries(
  context: IDashStoreContext,
  teamId: TeamId,
  productId: ProductIds,
): PromiseSnapshot<IQuery[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.query.teamQueriesMap[`${productId}-${teamId}`]
    },
    set: (state, snapshot) => {
      state.query.teamQueriesMap[`${productId}-${teamId}`] = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getTeamQueries(Number(teamId))
      return QueryDataConverter.fromGetQueriesApi(response).queries
    },
  })
}
