import { ApiUserpGroup1 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketApplicableForUserpSearch } from '../actions-ticket-assert/assertTicketApplicableForUserpSearch'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRawUserpGroup1(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ApiUserpGroup1> {
  return context.getOrFetch<ApiUserpGroup1>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.rawUserpGroup1
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.rawUserpGroup1 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.userpGroup1

      if (sourceItem?.url) {
        return await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asUserpGroup1()
      }

      await assertTicketApplicableForUserpSearch(context, ticketId, 'source')
      await assertTicketTurnExists(context, ticketId, messageId)
      throw TicketError.create('NoUserpSearch', { ticketId, messageId, sourceItem })
    },
  })
}
