import { IPublicClientApplication } from '@azure/msal-browser'
import { acquireTokenRoles } from './acquireTokenRoles'
import { IDashAuthPermissionData } from '../IDashAuth'

const SERVICE_SCOPE_AAD = ['d97af45c-a676-4654-a778-236750b20726/.default']
const SERVICE_SCOPE_TORUS = ['da637854-315f-44cf-aa43-9fea24d718d9/.default']
const SERVICE_SCOPE_AT = ['8313c666-ef01-4e96-a379-281719a10b63/.default']

const ROLE_ADMIN = 'feedback.admin'
const ROLE_BASIC = 'feedback.read.general'
const ROLE_ADVANCED = 'feedback.read.tier2'

export async function getPermission(
  client: IPublicClientApplication,
  isTorus: boolean,
): Promise<IDashAuthPermissionData | null> {
  const account = client.getActiveAccount()
  if (!account) {
    return null
  }

  let roles: Array<string>
  if (isTorus) {
    roles = await acquireTokenRoles(client, SERVICE_SCOPE_TORUS)
  } else {
    roles = [
      ...(await acquireTokenRoles(client, SERVICE_SCOPE_AAD)),
      ...(await acquireTokenRoles(client, SERVICE_SCOPE_AT)),
    ]
  }

  return {
    roles,
    admin: roles.includes(ROLE_ADMIN),
    general: roles.includes(ROLE_BASIC),
    advanced: roles.includes(ROLE_ADVANCED),
  }
}
