import { createStoreContext, useStore } from '@copilot-dash/core'
import { useEffect, useRef } from 'react'
import { TicketConversationLayout } from './layout/TicketConversationLayout'
import {
  createConversationHistoryActions,
  createConversationHistoryState,
  ITicketConversationHistoryActions,
  ITicketConversationHistoryState,
} from './store'
import { ErrorViewBoundary } from '../Error'

interface IProps {
  readonly ticketId: string
  readonly selectedMessageId?: string
  readonly onSelect?: (messageId: string) => void
}

export function TicketConversationHistory({ ticketId, selectedMessageId, onSelect }: IProps) {
  const onSelectRef = useRef(onSelect)
  onSelectRef.current = onSelect

  const store = useStore<ITicketConversationHistoryState, ITicketConversationHistoryActions>((set, get) => ({
    state: createConversationHistoryState(ticketId),
    actions: createConversationHistoryActions({
      set,
      get,
      onSelectRef,
    }),
  }))

  // Listen ticketId
  useEffect(() => {
    if (ticketId !== store.state.ticketId) {
      store.setState((state) => {
        state.ticketId = ticketId
      })
    }
  }, [store, ticketId])

  // Listen selectedIndex
  useEffect(() => {
    store.setState((state) => {
      state.selectedTurnId = selectedMessageId
    })
  }, [store, selectedMessageId])

  return (
    <StoreContext.Provider store={store}>
      <ErrorViewBoundary label="TicketConversationHistory">
        <TicketConversationLayout />
      </ErrorViewBoundary>
    </StoreContext.Provider>
  )
}

const StoreContext = createStoreContext<ITicketConversationHistoryState, ITicketConversationHistoryActions>()
TicketConversationHistory.use = StoreContext.use
TicketConversationHistory.useActions = StoreContext.useActions
