import { useAsyncLoader } from '@copilot-dash/core'
import { IFilterOption } from '@copilot-dash/domain'
import { debounce, find } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CopilotDashSearchableDropdown } from '../Filter/CopilotDashSearchableDropdown'

interface TenantFilterProps {
  placeholder?: string
  onChangeTenant: (selectedOptions: string[] | undefined) => void
  value: string[] | undefined
  className?: string
}

export const TenantFilter: React.FC<TenantFilterProps> = ({ ...props }) => {
  const [tenantKeyword, setTenantKeyword] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const handleTenantChange = (selectedOptions: string[] | undefined) => {
    props.onChangeTenant(selectedOptions)
    Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromTenant', value: selectedOptions })
  }

  const [submit, snapshot] = useAsyncLoader(
    useCallback(
      async (keyword: string): Promise<IFilterOption[]> => {
        const optionList: IFilterOption[] = []
        const tenants = await application.store.actions.searchM365Tenants(keyword, keyword ? undefined : props.value)
        tenants.forEach((tenant) => {
          optionList.push({
            key: tenant.tenantId,
            text: tenant.tenantName,
          })
        })
        if (keyword) {
          if (!find(optionList, { key: keyword })) {
            optionList.unshift({
              key: keyword,
              text: keyword,
              notFound: true,
            })
          }
        } else {
          props.value?.forEach((tenantId) => {
            if (!find(optionList, { key: tenantId })) {
              optionList.unshift({
                key: tenantId,
                text: tenantId,
                notFound: true,
              })
            }
          })
        }
        return optionList
      },
      [props.value],
    ),
  )

  const debouncedSubmit = useMemo(() => debounce(submit, 300), [submit])

  useEffect(() => {
    debouncedSubmit(tenantKeyword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantKeyword])

  useEffect(() => {
    if (!isPopoverOpen || props.value?.length === 0) {
      debouncedSubmit(tenantKeyword)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <CopilotDashSearchableDropdown
      onChangeFilter={handleTenantChange}
      defaultSelectedOption={props.value}
      setkeyword={setTenantKeyword}
      keyword={tenantKeyword}
      className={props.className}
      snapshot={snapshot}
      showInput={true}
      placeholder={props.placeholder ?? 'From Tenant'}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
    />
  )
}
