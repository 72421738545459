import { User } from '@copilot-dash/api'
import { Column } from '@copilot-dash/components'
import { AsyncSnapshot, useAsyncLoader } from '@copilot-dash/core'
import { Checkbox, Option, Persona, Spinner } from '@fluentui/react-components'
import { MailRegular } from '@fluentui/react-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useStyles } from './CopilotDashDropdown.styles'
import { CopilotDashPopover } from './CopilotDashPopover'
import { useDebounce } from '../../hooks/useDebounce'

interface IProps {
  readonly userId?: string[]
  readonly disabled?: boolean
  readonly onSelect?: (userId: string[]) => void
  readonly onDeselect?: () => void
  readonly className?: string
  readonly placeholder?: string
  showInput?: boolean
}

export function CopilotDashEmailDropdown({
  userId,
  disabled,
  onSelect,
  onDeselect: onDeSelect,
  placeholder = 'Select',
  showInput,
}: IProps) {
  const styles = useStyles()
  const [input, setInput] = useState<string>('')
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string[]>([])

  const debouncedInput = useDebounce(input.trim(), 300)

  const [submit, searchSnapshot] = useAsyncLoader(
    useCallback(
      async (input: string): Promise<User[]> => {
        if (input.trim().length === 0 && (!userId || userId?.length === 0)) {
          return []
        }

        if (input.trim().length === 0 && userId && userId.length > 0) {
          const results = await Promise.all(
            userId.map((item) => {
              return app.store.actions.getUserProfile(item)
            }),
          )
          return results
        }

        return application.api.microsoftGraph.searchUsers(input).then((res) => res.value)
      },
      [userId],
    ),
  )
  const [inputSubmit, inputSnapshot] = useAsyncLoader(
    useCallback((selectedUserId: string[]): Promise<string> => {
      if (selectedUserId.length === 0) {
        return Promise.resolve('')
      }
      const results = Promise.all(
        selectedUserId.map(async (item) => {
          const user = await app.store.actions.getUserProfile(item).catch(() => undefined)
          return user?.displayName ?? user?.userPrincipalName ?? item
        }),
      )
      return results.then((items) => {
        return items.join('; ')
      })
    }, []),
  )

  const submitRef = useRef(submit)
  submitRef.current = submit

  const inputSubmitRef = useRef(inputSubmit)
  inputSubmitRef.current = inputSubmit

  useEffect(() => {
    submitRef.current(debouncedInput)
  }, [debouncedInput])

  useEffect(() => {
    inputSubmitRef.current(selectedUserId)
  }, [selectedUserId])

  useEffect(() => {
    if (userId && userId.length > 0) {
      setSelectedUserId(userId)
    } else {
      setSelectedUserId([])
    }
  }, [userId])

  const handleCheckboxChange = (checked: string | boolean, userId: string | undefined) => {
    if (userId) {
      if (checked) {
        setSelectedUserId([...selectedUserId, userId])
        onSelect?.([...selectedUserId, userId])
      } else {
        setSelectedUserId(selectedUserId.filter((item) => item !== userId))
        onSelect?.(selectedUserId.filter((item) => item !== userId))
      }
    } else {
      onDeSelect?.()
    }
  }

  const handleSelectCleanClick = () => {
    onDeSelect?.()
  }

  const renderContent = (snapshot: AsyncSnapshot<User[]>) => {
    switch (snapshot.status) {
      case 'none':
        return (
          <Option key="freeform" disabled>
            None
          </Option>
        )
      case 'waiting':
        return <Spinner />
      case 'done':
        if (snapshot.data.length === 0) {
          return (
            <Option key="freeform" disabled>
              No results found
            </Option>
          )
        }

        return snapshot.data.map((user) => {
          if (!user.userPrincipalName || !user.id) {
            return null
          }

          return (
            <Column key={user.id} vAlign="center">
              <Checkbox
                checked={selectedUserId.includes(user.id)}
                label={
                  <Persona
                    avatar={{ color: 'colorful', 'aria-hidden': true }}
                    name={user.displayName ?? 'No display name'}
                    secondaryText={user.userPrincipalName}
                    size="small"
                  />
                }
                onChange={(_, data) => handleCheckboxChange(data.checked, user.id)}
                className={styles.checkBox}
              />
            </Column>
          )
        })
      case 'error':
        return (
          <Option key="freeform" disabled>
            {`${snapshot.error}`}
          </Option>
        )
    }
  }
  const targetFinalOptions = renderContent(searchSnapshot)
  return (
    <CopilotDashPopover
      optionContent={targetFinalOptions}
      value={inputSnapshot.data ?? ''}
      placeholder={placeholder}
      selectedOptions={userId ? userId : []}
      handleSelectCleanClick={handleSelectCleanClick}
      inputValue={input}
      setInputValue={setInput}
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      disabled={disabled}
      showInput={showInput}
      prefixIcon={<MailRegular fontSize={16} />}
    />
  )
}
