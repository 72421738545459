import { Body, Column, Row } from '@copilot-dash/components'
import { Button, Image, Text } from '@fluentui/react-components'
import { memo, useState } from 'react'

import { FeedbackCopilotHeader } from './FeedbackCopilotHeader'
import { useStyles } from './FeedbackCopilotStartup.styles'
import { QueryBox } from './querybox/QueryBox'
import FeedbackCopilotBrand from '../assets/FeedbackCopilotBrand.svg'

const zeroPrompts = [
  'Show me all DSATs about <feature>.',
  'Show me DSATs from <tenant>.',
  'What are the top issues on meeting recap?',
  'What are the top issues on <feature>?',
  'Summarize the issue for <feature>.',
  'How many DSATs have been received from <tenant>?',
]

export const FeedbackCopilotStartup = memo(function FeedbackCopilotStartup() {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState('')
  return (
    <Column className={styles.container}>
      <FeedbackCopilotHeader />
      <Body className={styles.startupbody}>
        <Column className={styles.greeting}>
          <Row style={{ marginTop: '200px' }}>
            <Image src={FeedbackCopilotBrand} alt={'Feedback Copilot'} />
          </Row>
          <Row style={{ marginTop: '32px', marginBottom: '60px' }}>
            <Text wrap={false} className={styles.greetingTitle}>
              Feedback Copilot
            </Text>
          </Row>
          <QueryBox diableInput={false} defaultInput={inputValue} />
          {zeroPrompts.map((prompt, index) => (
            <Row key={index} style={{ width: '100%', height: '40px' }}>
              <Button
                appearance="transparent"
                className={styles.propmtButton}
                onClick={() => {
                  setInputValue(prompt)
                }}
              >
                {prompt}
              </Button>
            </Row>
          ))}
        </Column>
      </Body>
    </Column>
  )
})
