import { PromiseSnapshot } from '@copilot-dash/core'
import { ITopIssueList, TeamId } from '@copilot-dash/domain'
import { TeamViewDataConverter } from './converters/TeamViewDataConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function getTeamIssueClusteringInfoList(
  context: IDashStoreContext,
  batchId: string,
  teamId: TeamId,
): Promise<ITopIssueList> {
  const response = await context.api.logCollector.getIssueClusteringInfoList({
    BatchId: batchId,
    TeamId: Number(teamId),
  })
  return TeamViewDataConverter.fromApiTopIssueClusteringInfoListResponse(response)
}

export function getOrFetchTeamIssueClusteringInfoList(
  context: IDashStoreContext,
  batchId: string,
  teamId: TeamId,
): PromiseSnapshot<ITopIssueList> {
  return context.getOrFetch({
    get: (state) => {
      return state.topIssue.topIssueListMap[`${batchId}-${teamId}`]
    },
    set: (state, snapshot) => {
      state.topIssue.topIssueListMap[`${batchId}-${teamId}`] = snapshot
    },
    fetch: () => {
      return getTeamIssueClusteringInfoList(context, batchId, teamId)
    },
  })
}
