import { LicenseType, ProductIds, ProductLicense } from '@copilot-dash/domain'
import { getFilterDisplayName, useProductIdEndpoints } from '@copilot-dash/store'
import { compact, includes, sortBy, uniq } from 'lodash'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  productId: ProductIds
  applications: string[]
  platforms: string[]
  selectedValues: string[] | undefined
  onChangeValue: (endpoints: string[]) => void
}

export const LicenseFilter: React.FC<IProps> = ({
  productId,
  applications,
  platforms,
  selectedValues,
  onChangeValue,
}) => {
  const allProductEndpoints = useProductIdEndpoints({ productId }).data
  const allLicenses = uniq(compact(allProductEndpoints?.map((endpoint) => endpoint.license)))

  let productEndpoints =
    applications.length === 0
      ? allProductEndpoints
      : allProductEndpoints?.filter((endpoint) => includes(applications, endpoint.application))

  productEndpoints =
    platforms.length === 0
      ? productEndpoints
      : productEndpoints?.filter((endpoint) => includes(platforms, endpoint.platform))

  const enabledItems = uniq(compact(productEndpoints?.map((endpoint) => endpoint.license)))

  const licenseList = sortBy(allLicenses, (item) => sortLicense(item)).map((license) => ({
    key: license,
    text: license,
    disabled: !enabledItems.includes(license),
  }))

  return (
    <>
      <CopilotDashMultiSelectDropdown
        comboId={'combo-licenseFilter'}
        filterType={getFilterDisplayName('licenses')}
        isGroup={true}
        optionsList={licenseList}
        defaultSelectedOption={selectedValues}
        onChangeFilter={onChangeValue}
      />
    </>
  )
}

function sortLicense(item: LicenseType): number {
  return ProductLicense.indexOf(item)
}
