import { Column } from '@copilot-dash/components'
import { useMemo } from 'react'
import { MetricCardJsonBody } from './MetricCardJsonBody'
import { MetricCardJsonHeader } from './MetricCardJsonHeader'

interface IProps {
  readonly title: string
  readonly data: unknown
}

export function MetricCardJson({ title, data }: IProps) {
  const content = useMemo(() => {
    if (data === undefined) {
      return ''
    }

    if (typeof data === 'string') {
      return data
    }

    try {
      return JSON.stringify(data, null, 2)
    } catch (e) {
      return String(e)
    }
  }, [data])

  return (
    <Column>
      <MetricCardJsonHeader title={title} content={content} />
      <MetricCardJsonBody content={content} />
    </Column>
  )
}
