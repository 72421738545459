import { ReactNode } from 'react'
import { TelemetryScopesContext } from './TelemetryScopesContext'
import { useTelemetryScopes } from './useTelemetryScope'
import { useTelemetryScopeViewTracker } from './useTelemetryScopeViewTracker'
import { ITelemetryScope } from '../telemetry/ITelemetryScope'

type IProps1<T extends keyof ITelemetryScope> = {
  readonly scope: T
  readonly properties: ITelemetryScope[T]
  readonly children?: ReactNode
}

type IProps2<T extends keyof ITelemetryScope> = {
  readonly scope: T
  readonly properties?: ITelemetryScope[T]
  readonly children?: ReactNode
}

type IProps<T extends keyof ITelemetryScope> = ITelemetryScope[T] extends void ? IProps2<T> : IProps1<T>

export function TelemetryScope<T extends keyof ITelemetryScope>(props: IProps<T>): ReactNode {
  useTelemetryScopeViewTracker(props.scope, (props.properties ?? {}) as ITelemetryScope[T])

  const scopes = useTelemetryScopes(props.scope)

  return <TelemetryScopesContext.Provider value={scopes}>{props.children}</TelemetryScopesContext.Provider>
}
