import { MonacoViewInline } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'

interface IProps {
  readonly data: unknown
}

export function TreeItemJsonView({ data }: IProps) {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <MonacoViewInline data={data} maxHeight={400} showLineNumber />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
