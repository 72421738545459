/* eslint-disable react/forbid-elements */
import { mergeClasses } from '@fluentui/react-components'
import { forwardRef } from 'react'
import { Flex } from './Flex'

interface IProps {
  /**
   * Defines if container should be inline element.
   */
  readonly inline?: boolean

  /**
   * Allows overflow items to wrap on the next container's line.
   */
  readonly wrap?: boolean

  /**
   * Controls items alignment in horizontal direction.
   */
  readonly hAlign?: 'start' | 'center' | 'end' | 'stretch'

  /**
   * Controls items alignment in vertical direction.
   */
  readonly vAlign?: 'start' | 'center' | 'end' | 'stretch'

  /**
   * Defines strategy for distributing remaining space between items.
   */
  readonly space?: 'around' | 'between' | 'evenly'

  /**
   * Defines gap between each two adjacent child items.
   */
  readonly gap?: 'gap.smaller' | 'gap.small' | 'gap.medium' | 'gap.large'

  /**
   * Defines container's padding.
   */
  readonly padding?: 'padding.medium'

  /**
   * Orders container to fill all parent's space available.
   */
  readonly fill?: boolean
}

export const Column = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement> & IProps>(function Column(
  { className, children, ...rest },
  ref,
) {
  const classNameInternal = mergeClasses('Column', className)
  return (
    <Flex className={classNameInternal} column ref={ref} {...rest}>
      {children}
    </Flex>
  )
})
