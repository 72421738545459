import { makeStyles, Spinner, tokens } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface ILoadingMaskProps {
  visible: boolean
  message?: string
}

export const LoadingMask: FC<ILoadingMaskProps> = memo(({ visible, message }) => {
  const styles = useStyles()
  if (!visible) return null

  return (
    <div className={styles.loadingMask}>
      <div className={styles.loadingMaskContent}>
        <Spinner size="large" />
        {message && <div className={styles.loadingMessage}>{message}</div>}
      </div>
    </div>
  )
})

LoadingMask.displayName = 'LoadingMask'

const useStyles = makeStyles({
  loadingMask: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingMaskContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingMessage: {
    marginTop: tokens.spacingVerticalS,
    fontSize: tokens.fontSizeBase300,
  },
})
