import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSource } from '@copilot-dash/domain'
import { convertToTicketSource } from './convertToTicketSource'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketSource(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketSource> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.source
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.source = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const raw = await getRawV2Ticket(context, ticketId).promise
      return convertToTicketSource(raw)
    },
  })
}
