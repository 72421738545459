import { ScenarioSlicers } from '@copilot-dash/domain'
import { getFilterDisplayName } from '@copilot-dash/store'
import { Control, Controller } from 'react-hook-form'

import { CopilotDashDependencyDropdown } from '../../../Filter/CopilotDashDependencyDropdown'
import { OptionSetUtteranceGroup } from '../../config/OptionSetUtteranceGroup'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export const UtteranceGroupAndScenarioSlicerFilter: React.FC<IProps> = ({ control }: IProps) => {
  function calcScenarioSlicersFromUtteranceGroups(selectedUtteranceGroups: string[]): string[] {
    const items = (
      selectedUtteranceGroups.length === 0
        ? ScenarioSlicers
        : ScenarioSlicers.filter((slicer) => selectedUtteranceGroups.some((group) => group === slicer.utteranceGroup))
    )
      .map((slicer) => slicer.scenarios)
      .flat()

    return items.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
  }
  const parentDisplayName = getFilterDisplayName('utteranceGroups')
  const childDisplayName = getFilterDisplayName('scenarioSlicers')

  return (
    <>
      <Controller
        name="utteranceGroups"
        control={control}
        render={({ field }) => (
          <Controller
            name="scenarioSlicers"
            control={control}
            render={({ field: scenarioField }) => (
              <CopilotDashDependencyDropdown
                parentFilter={field.name}
                parentDisplayName={parentDisplayName}
                parentOptions={OptionSetUtteranceGroup}
                selectedParents={field.value}
                onParentChanged={field.onChange}
                parentInfoLabel={
                  <>
                    Scenarios are <b>AI-generated</b>, computed offline with a potential delay. SLA: 33 hours.
                  </>
                }
                childFilter={scenarioField.name}
                childDisplayName={childDisplayName}
                selectedChlidren={scenarioField.value}
                onChildChanged={scenarioField.onChange}
                childInfoLabel={
                  <>
                    Please select a scenario group first.
                    <br /> Scenarios are <b>AI-generated</b>, computed offline with a potential delay. SLA: 33 hours.
                  </>
                }
                childCalcFunc={calcScenarioSlicersFromUtteranceGroups}
              />
            )}
          />
        )}
      />
    </>
  )
}
