import { Tab, TabItem, TabItemForAnyTable, TabItemForMonaco } from '@copilot-dash/components'
import { AsyncSnapshots } from '@copilot-dash/core'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'
import { TicketSourceKustoOpener } from '../../../../common/TicketSourceKustoOpener'

export function LlmLogView() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} messageId={selectedTurnId} />
}

function Content({ ticketId, messageId }: { readonly ticketId: string; readonly messageId: string }) {
  const snapshot = app.store.use.getLlmLogs(ticketId, messageId)
  const toolbar = <TicketSourceKustoOpener ticketId={ticketId} messageId={messageId} source="llmLog" />

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(items) => {
        return (
          <Tab>
            <TabItem name="Table">
              <TabItemForAnyTable data={items} toolbar={toolbar} />
            </TabItem>
            <TabItem name="JSON">
              <TabItemForMonaco data={items} toolbar={toolbar} />
            </TabItem>
          </Tab>
        )
      }}
    </TicketAsyncView>
  )
}
