import { IQuery, ITeamData, ProductIds } from '@copilot-dash/domain'
import { useFilterOptions } from '@copilot-dash/store'
import { memo, useEffect, useMemo, useState } from 'react'
import { SaveQueryDialog, saveQueryActionType } from '../../../SaveQueryDialog/SaveQueryDialog'
import { useTicketFilterStore } from '../../store/store'
import { ITicketFilterPanel } from '../../TicketFilterPanel.types'
import { getCleanedFilters, toODataQuery } from '../TicketFilterForm/TicketFilterForm.types'

interface ISaveQueryScreenProps<T> extends ITicketFilterPanel<T> {
  dialogOpen: boolean
  setDialogOpen: (open: boolean) => void
  currentQuery?: IQuery
  canUpdate: boolean
  type: saveQueryActionType
  disableTeamSharedQueries?: boolean
}

export const SaveQueryScreen = memo(function SaveQueryScreen<T>(props: ISaveQueryScreenProps<T>) {
  const productId = useTicketFilterStore((state) => state.selectedProductId)
  const copilotDashPath = useTicketFilterStore((state) => state.copilotDashPath)

  const timezone = app.settings.timezone.use()
  const filterOptionsSnapshot = useFilterOptions('')
  const [tenantName, setTenantName] = useState<string[] | undefined>()
  const [emailName, setEmailName] = useState<string[] | undefined>()

  const tagsMetaDataMappingList = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot])

  const filterPayload = useMemo(() => {
    const combinedFilters = {
      ...props.externalArgs,
      ...props.ticketFilterForm,
      range: props.ticketFilterForm.range ?? props.defaultRange,
    }

    const cleanedFilters = getCleanedFilters(combinedFilters)

    return JSON.stringify(cleanedFilters)
  }, [props.ticketFilterForm, props.defaultRange, props.externalArgs])

  useEffect(() => {
    ;(async () => {
      if (props.ticketFilterForm.tenantIds && props.ticketFilterForm.tenantIds.length > 0) {
        const tenantNames = await Promise.all(
          props.ticketFilterForm.tenantIds.map(async (tenantId) => {
            const item = await application.store.actions.searchM365Tenants(tenantId)
            if (item.length === 1 && item[0]?.tenantName && item[0]?.tenantId === tenantId) {
              return item[0]?.tenantName
            }
            return tenantId
          }),
        )

        setTenantName(tenantNames)
      }
    })()
  }, [props.ticketFilterForm.tenantIds])
  useEffect(() => {
    ;(async () => {
      if (props.ticketFilterForm.userId && props.ticketFilterForm.userId.length > 0) {
        const emailNames = await Promise.all(
          props.ticketFilterForm.userId.map(async (userId) => {
            const item = await app.store.actions.getUserProfile(userId)
            if (item?.userPrincipalName) {
              return item.userPrincipalName
            }
            return ''
          }),
        )

        setEmailName(emailNames)
      }
    })()
  }, [props.ticketFilterForm.userId])

  const queryDetail = useMemo(() => {
    return toODataQuery(
      {
        ...props.externalArgs,
        ...props.ticketFilterForm,
        range: props.ticketFilterForm.range ?? props.defaultRange,
      },
      timezone,
      tagsMetaDataMappingList,
      tenantName,
      emailName,
    )
  }, [
    props.ticketFilterForm,
    props.defaultRange,
    props.externalArgs,
    tagsMetaDataMappingList,
    timezone,
    tenantName,
    emailName,
  ])

  const teamsSnapshot = app.store.use.getTeams(productId ?? ProductIds.M365Chat)

  const teamsOptions: ITeamData[] = useMemo(() => {
    switch (teamsSnapshot.status) {
      case 'done':
        return teamsSnapshot.data
      default:
        return []
    }
  }, [teamsSnapshot.data, teamsSnapshot.status])

  return (
    <SaveQueryDialog
      productId={productId}
      open={props.dialogOpen}
      onClose={() => {
        props.setDialogOpen(false)
      }}
      onSaved={() => {
        useTicketFilterStore.setState({ queryChanged: false })
      }}
      okBtnText="Save"
      queryDetail={queryDetail}
      copilotDashPath={copilotDashPath}
      filterPayload={filterPayload}
      canUpdate={props.canUpdate}
      isTeamShared={props.currentQuery?.isTeamShared}
      teamId={props.currentQuery?.teamId}
      queryId={props.currentQuery?.queryId}
      title={props.currentQuery?.title ?? ''}
      teamsOptions={teamsOptions}
      type={props.type}
      disableTeamSharedQueries={props.disableTeamSharedQueries}
    />
  )
}) as <T>(props: ISaveQueryScreenProps<T>) => JSX.Element
