import { AnyExplainer, AnyExplainerResult } from './AnyExplainer'

export class AnyExplainers {
  private static readonly explainers: AnyExplainer[] = []
  private static useTimezoneHook?: () => string

  static register(explainer: AnyExplainer) {
    this.explainers.push(explainer)
  }

  static registerTimezoneHook(hook: () => string) {
    this.useTimezoneHook = hook
  }

  static explain(keyword: string): AnyExplainerResult | undefined {
    for (const explainer of this.explainers) {
      const result = explainer(keyword)
      if (result) {
        return result
      }
    }

    return undefined
  }

  static useTimezone(): string | undefined {
    if (this.useTimezoneHook) {
      return this.useTimezoneHook()
    }

    return undefined
  }
}
