import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { compact, uniq } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getRawTicketInteractions } from '../actions-raw-ticket-interactions/getRawTicketInteractions'
import { assertTicket3sTriggered } from '../actions-ticket-assert/assertTicket3sTriggered'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { get3sGwsLogs } from '../actions-ticket-kusto/get3sGwsLogs'

export function get3sTransactionIds(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<string[]> {
  return context.getOrFetch<string[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.sssTransactionIds
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.sssTransactionIds = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const transactionIds = await doFetch()
      if (transactionIds.length !== 0) {
        return transactionIds
      }

      // Check if 3s is triggered
      await assertTicket3sTriggered(context, ticketId, messageId)

      // Ops! No data found
      throw TicketError.create('No3S', { ticketId, messageId })
    },
  })

  async function doFetch(): Promise<string[]> {
    return uniq(
      compact([
        ...(await doFetchFromTicket()), //
        ...(await doFetchFromInteraction()),
        ...(await doFetchFromGwsLogs()),
      ]),
    )
  }

  async function doFetchFromTicket(): Promise<string[]> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    return (
      raw.diagnosticContext?.chat?.find((item) => item.messageId === messageId)?.substrateSearch?.transactionIds ?? []
    )
  }

  async function doFetchFromInteraction(): Promise<string[]> {
    const interactions = await getRawTicketInteractions(context, ticketId).promise
    const interaction = interactions.find((interaction) => interaction.MessageId === messageId)

    const onlineIds1 = interaction?.SubstrateSearchReplayInfoList?.map((info) => info.TransactionId) ?? []
    const onlineIds2 = interaction?.Diagnostic?.SubstrateSearch?.map((item) => item.TransactionId) ?? []
    const offlineIds = interaction?.ImpressionIds ?? []

    return compact([...onlineIds1, ...onlineIds2, ...offlineIds])
  }

  async function doFetchFromGwsLogs(): Promise<string[]> {
    try {
      const logs = await get3sGwsLogs(context, ticketId, messageId).promise
      return compact(logs.map((log) => log.transactionId))
    } catch (e) {
      return []
    }
  }
}
