import { RootCauseId } from '@copilot-dash/domain'
import { mergeClasses, Tag, Text, makeStyles, tokens } from '@fluentui/react-components'
import { ClipboardTextLtrFilled, Dismiss16Filled } from '@fluentui/react-icons'
import { memo } from 'react'
import { Path } from 'react-router-dom'
import { RouteLink } from '../../../../router'
import { useGlobalStore } from '../../../../store'

interface IRootCauseTagProps {
  readonly rootCauseId: RootCauseId
  readonly tabIndex: number
  readonly onRemoveRootCause: (e: React.MouseEvent<SVGElement, MouseEvent>, rootCauseId: RootCauseId) => void
  readonly generatePath: (rootCauseId: string) => '' | Readonly<Path>
  readonly rootCauseMaxWidth?: string
}

export const RootCauseTag = memo((props: IRootCauseTagProps) => {
  const { rootCauseId, tabIndex, onRemoveRootCause, generatePath, rootCauseMaxWidth } = props
  const styles = useStyles()
  const rootCauseSnapshot = app.store.use.getRootCauseInfoByIdV2(rootCauseId)
  const rootCauseTitle =
    rootCauseSnapshot?.data?.rootCauseTitle ??
    useGlobalStore
      .getState()
      .areaListAndRootCauseList.data?.map((team) => team.rootCauseList)
      .flat(1)
      .find((item) => item.issueId === rootCauseId)?.rootCauseTitle

  return (
    <Tag
      dismissible
      value={rootCauseId}
      key={rootCauseId}
      tabIndex={tabIndex}
      className={styles.tagStyle}
      dismissIcon={{
        as: 'span',
        children: (
          <Dismiss16Filled className={styles.dismissButton} onClick={(e) => onRemoveRootCause(e, rootCauseId)} />
        ),
      }}
    >
      <RouteLink path={generatePath(rootCauseId)} key={rootCauseId} openInNewTab>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <ClipboardTextLtrFilled
            style={{
              color: tokens.colorBrandForeground1,
              width: '20px',
              height: '20px',
              marginRight: '5px',
              flexShrink: 0,
            }}
          />
          <Text className={mergeClasses(styles.textStyle, rootCauseMaxWidth)}>{rootCauseTitle}</Text>
        </div>
      </RouteLink>
    </Tag>
  )
})

RootCauseTag.displayName = 'RootCauseTag'

const useStyles = makeStyles({
  tagStyle: {
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '3px 8px 3px 0',
    '& .fui-Tag__primaryText': {
      overflow: 'hidden',
    },
  },
  dismissButton: {
    color: tokens.colorNeutralForeground3,
    height: tokens.lineHeightBase100,
  },
  textStyle: {
    maxWidth: '438px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})
