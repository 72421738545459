import { ISearchTicketArgs } from '@copilot-dash/domain'

/**
 * FilterDisplayNameMapping contains the supported filter tags for the ticket filter panel.
 * Each item's key corresponds to a key in ISearchTicketArgs and SearchFilterFieldNames.
 */
export const FilterDisplayNameMapping: {
  key: keyof ISearchTicketArgs
  displayName: string
}[] = [
  {
    key: 'agentTypes',
    displayName: 'Agent types',
  },
  {
    key: 'appKinds',
    displayName: 'App kinds',
  },
  {
    key: 'applications',
    displayName: 'Applications',
  },
  {
    key: 'platforms',
    displayName: 'Platforms',
  },
  {
    key: 'licenses',
    displayName: 'Licenses',
  },
  {
    key: 'authTypes',
    displayName: 'Auth types',
  },
  {
    key: 'customerTypes',
    displayName: 'Customer type',
  },
  {
    key: 'ring',
    displayName: 'Ring',
  },
  {
    key: 'thumbs',
    displayName: 'Emotion type',
  },
  {
    key: 'hasVerbatim',
    displayName: 'Has user verbatim',
  },
  {
    key: 'hasUserConsent',
    displayName: 'Has user consent',
  },
  {
    key: 'priority',
    displayName: 'Priority',
  },
  {
    key: 'promptLanguages',
    displayName: 'Prompt language',
  },
  {
    key: 'groundedPrompts',
    displayName: 'Grounded prompts',
  },
  {
    key: 'isApology',
    displayName: 'Is apology',
  },
  {
    key: 'hasCitation',
    displayName: 'Has citations',
  },
  {
    key: 'hitAvalon',
    displayName: 'Hit avalon',
  },
  {
    key: 'triggeredSkill',
    displayName: 'Triggered skills',
  },
  {
    key: 'hasEntityCard',
    displayName: 'Has entity representations',
  },
  {
    key: 'invocationType',
    displayName: 'Invocation types',
  },
  {
    key: 'isSTCAChina',
    displayName: 'Is STCA China',
  },
  {
    key: 'isTopi18N',
    displayName: 'Is top i18N tenants',
  },
  {
    key: 'responseHeroType',
    displayName: 'Response hero types',
  },
  {
    key: 'responseLinkType',
    displayName: 'Response link types',
  },
  {
    key: 'semanticSearchType',
    displayName: 'Semantic search types',
  },
  {
    key: 'experienceType',
    displayName: 'Experience types',
  },
  {
    key: 'hasGPTExtension',
    displayName: 'Has GPT-Extension keywords',
  },
  {
    key: 'hasMessageExtension',
    displayName: 'Has MessageExtension keywords',
  },
  {
    key: 'hasConnector',
    displayName: 'Has Connector keywords',
  },
  {
    key: 'hasCopilotExtensionIds',
    displayName: 'Is agent interaction',
  },
  {
    key: 'optionsSets',
    displayName: 'Options sets',
  },
  {
    key: 'errorCode',
    displayName: 'Error code',
  },
  {
    key: 'channel',
    displayName: 'Feedback entry',
  },
  {
    key: 'isGCIntent',
    displayName: 'Is GC intent',
  },
  {
    key: 'hasConnectorResult',
    displayName: 'Has Connector result',
  },
  {
    key: 'dSATStatus',
    displayName: 'State',
  },
  {
    key: 'customTags',
    displayName: 'Tags',
  },
  {
    key: 'sliceIds',
    displayName: 'Slice Ids',
  },
  {
    key: 'copilotExtensionIds',
    displayName: 'CopilotExtensionIds',
  },
  {
    key: 'dSATAssignedTo',
    displayName: 'Assigned to',
  },
  {
    key: 'flights',
    displayName: 'Flights',
  },
  {
    key: 'hasErrorMessages',
    displayName: 'Has error messages',
  },
  {
    key: 'scenarios',
    displayName: 'Scenarios',
  },
  {
    key: 'hasScreenshot',
    displayName: 'Has screenshots',
  },
  {
    key: 'chatMode',
    displayName: 'Chat mode',
  },
  {
    key: 'utteranceGroups',
    displayName: 'Scenario group',
  },
  {
    key: 'scenarioSlicers',
    displayName: 'Scenario',
  },
  {
    key: 'feedbackTargets',
    displayName: 'Feedback target',
  },
  {
    key: 'builderNameSlicers',
    displayName: 'Builder name',
  },
  {
    key: 'capabilitiesSlicers',
    displayName: 'Capabilities',
  },
  {
    key: 'usageIntensityCohortSlicers',
    displayName: 'Usage intensity cohort',
  },
  {
    key: 'engagementCohortSlicers',
    displayName: 'Engagement cohort',
  },
  {
    key: 'languageTierSlicers',
    displayName: 'Setting language',
  },
  {
    key: 'localeSlicers',
    displayName: 'Locale',
  },
  {
    key: 'hasAssignee',
    displayName: 'Has assignee',
  },
  {
    key: 'teamIds',
    displayName: 'Team',
  },
  {
    key: 'clientFlights',
    displayName: 'Client flights',
  },
  {
    key: 'deployRing',
    displayName: 'Deploy ring',
  },
  {
    key: 'invocationSlicers',
    displayName: 'Invocation slicers',
  },
]
