import { Row } from '@copilot-dash/components'
import { IFCConversationMessageData } from '@copilot-dash/domain'
import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import { TimeView } from '../../../../components/Time/TimeView'

interface IProps {
  readonly data?: IFCConversationMessageData
  readonly isResponseTime: boolean
}

export function TurnViewHeader({ data, isResponseTime }: IProps) {
  const styles = useStyles()

  const [timeValue, setTimeValue] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (isResponseTime) {
      setTimeValue(data?.botResponseTimeUTC)
    } else {
      setTimeValue(data?.clientStartTimeUTC ? data?.clientStartTimeUTC : data?.startTimeUTC)
    }
  }, [data, isResponseTime])

  if (!data) return null

  if (isResponseTime && !data.botResponseTimeUTC) return null

  const formatChatTimestamp = (date: string | undefined) => {
    if (!date) return 'Unknown'
    const now = new Date()
    const messageDate = new Date(date)
    const isToday = now.toLocaleDateString() === messageDate.toLocaleDateString() // Check if it's today
    if (isToday) {
      return 'h:mm:ss A'
    } else {
      return 'MMMM D, YYYY h:mm:ss A'
    }
  }

  return (
    <Row className={styles.root} hAlign="center">
      <TimeView value={timeValue} format={formatChatTimestamp(timeValue)} defaultText="">
        {(text) => {
          return <Text className={styles.text}>{text}</Text>
        }}
      </TimeView>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
  },
  text: {
    color: tokens.colorNeutralForeground4,
  },
})
