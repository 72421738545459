import { ReactNode } from 'react'
import { open } from './open'
import { FullDialog } from '../children/FullDialog'

interface IParams {
  readonly title?: string
  readonly header?: boolean
  readonly transition?: boolean
  readonly content: ((open: boolean, onClose: () => void) => ReactNode) | ReactNode
}

export function openAsFullDialog({ title, header, transition, content }: IParams): void {
  open({
    content: (open, onClose) => {
      return (
        <FullDialog open={open} onClose={onClose} title={title} header={header} transition={transition}>
          {typeof content === 'function' ? content(open, onClose) : content}
        </FullDialog>
      )
    },
  })
}
