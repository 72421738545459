import { Row } from '@copilot-dash/components'
import { ColumnIdNameMapping, ColumnKey, INewTicketData } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { CopilotDashCustomHeaderProps, CustomHeader } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { TicketLinkCopyButton } from './TicketLinkCopyButton'
import { RouteLink, TicketRoute } from '../../router'

export const ColumnConfigTicketId: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.TicketId],
  field: String(ColumnKey.TicketId) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <ColumnConfigTicket {...props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.TicketId]}</TableCellLayoutWithStyles>
    ),
  },
  minWidth: 50,
  width: 90,
  sortable: false,
  resizable: true,
}

const ColumnConfigTicket = (item: INewTicketData) => {
  const styles = useStyles()
  const ticketId = item.ticketId
  if (ticketId)
    return (
      <TableCellLayoutWithStyles className={styles.layout}>
        <Row fill vAlign="center">
          <RouteLink
            className={styles.ticketId}
            path={TicketRoute.navigator.generatePath({ id: ticketId })}
            type="link"
            openInNewTab
          >
            <span
              onClickCapture={(e) => {
                e.stopPropagation()
              }}
            >
              {item.ticketId}
            </span>
          </RouteLink>
          <TicketLinkCopyButton ticketId={ticketId} />
        </Row>
      </TableCellLayoutWithStyles>
    )
  else return null
}

const useStyles = makeStyles({
  layout: {
    display: 'block',
  },
  ticketId: {
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    direction: 'rtl',
    display: 'block',
  },
})
