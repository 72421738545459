import { Column, Row, Spacer } from '@copilot-dash/components'
import { uuid } from '@copilot-dash/core'
import {
  FeedbackId,
  INewTicketData,
  IRootCauseRecommendation,
  ISevalQueryData,
  IUpdateRootCauseRecommendationItemV2,
  IUpdateWorkItem,
  ProductIds,
  RootCauseActionTypeV2,
  RootCauseId,
} from '@copilot-dash/domain'
import { translateRootCausePriority } from '@copilot-dash/store'
import { Button, Drawer, DrawerBody, DrawerHeader, Field, ProgressBar, Spinner, Text } from '@fluentui/react-components'
import {
  AddRegular,
  BookCoinsRegular,
  DismissRegular,
  LightbulbFilamentRegular,
  ThumbDislikeRegular,
} from '@fluentui/react-icons'
import { isNil, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { useStyles } from './BatchUpdateTicketsPanel.styles'
import { findCommonCustomTags, findCommonRootCauseIds } from './utils'
import { useToast } from '../../hooks/useToast'
import { sleep } from '../../utils/time'
import { GenerateQuerySetTemplate } from '../GenerateQuerySetTemplate/GenerateQuerySetTemplate'
import { FieldNames, ITicketActionFormRef, TicketActionForm } from '../TicketActionForm/TicketActionForm'
import {
  mapADOStateToCopilotDashState,
  mapCopilotDashStateToWorkItemState,
  mapWorkItemsStateToCopilotDashState,
} from '../TicketActionForm/utils'

const postDataValidation = z.array(
  z.object({
    feedbackId: z.string(),
    workItemId: z.string(),
    dsatStatus: z.enum([
      'Untriaged',
      'TeamAssigned',
      'RootCaused',
      'ClosedFixed',
      'ClosedByDesign',
      'ClosedNotActionable',
    ]),
    teamId: z.string(),
    priority: z.string(),
    assignTo: z.string().optional(),
    closedComment: z.string().optional(),
    rootCauseIdList: z.array(z.string()),
  }),
)
type IPostData = Array<IUpdateWorkItem & { feedbackId: FeedbackId } & { customTags: string[] }>

function reportAction(
  suggestion: IRootCauseRecommendation,
  userAction: RootCauseActionTypeV2,
  tickets: INewTicketData[],
) {
  const data: IUpdateRootCauseRecommendationItemV2[] = tickets.map((ticket) => ({
    ticketId: ticket.ticketId,
    userActionList: [{ workItemId: suggestion.workItemId, userActionType: userAction }],
  }))
  application.store.actions.updateRootCauseRecommendationActionV2(data)
}
interface BatchUpdateTicketsPanelProps {
  open: boolean
  productId: ProductIds
  onClose?: () => void
  tickets?: INewTicketData[]
  suggestion?: IRootCauseRecommendation
  refreshTickets?: () => void
  onRejectSuggestion?: (suggestion: IRootCauseRecommendation) => void
  onAcceptSuggestion?: (suggestion: IRootCauseRecommendation) => void
}
const INTERVAL_DELAY = 1000
const GROUP_SIZE = 20
export const BatchUpdateTicketsPanel: React.FC<BatchUpdateTicketsPanelProps> = ({
  open,
  onClose,
  tickets = [],
  suggestion,
  productId,
  refreshTickets,
  onRejectSuggestion,
  onAcceptSuggestion,
}) => {
  const styles = useStyles()
  const toast = useToast()

  const [selectedRootCause, setSelectedRootCause] = useState<RootCauseId[]>([])
  const [savedTicketsCount, setSavedTicketsCount] = useState(0)
  const [querySetDialogOpen, setQuerySetDialogOpen] = useState(false)
  const [querySetData, setQuerySetData] = useState<Partial<ISevalQueryData>>()
  const controller = useRef(new AbortController())
  const signal = controller.current.signal
  const isQuerySetVisible = app.features.v2QuerySet.use()

  const formRef = useRef<ITicketActionFormRef | null>(null)
  const [saving, setSaving] = useState(false)
  const [allowSave, setAllowSave] = useState(false)
  const [formModified, setFormModified] = useState(false)
  const modifiedFieldRef = useRef<{
    [FieldNames.state]: boolean
    [FieldNames.priority]: boolean
    [FieldNames.area]: boolean
    [FieldNames.assignTo]: boolean
    [FieldNames.customTags]: boolean
    [FieldNames.rootCauseIDs]: boolean
    [FieldNames.noActionableReason]: boolean
  }>({
    [FieldNames.state]: false,
    [FieldNames.priority]: false,
    [FieldNames.area]: false,
    [FieldNames.assignTo]: false,
    [FieldNames.customTags]: false,
    [FieldNames.rootCauseIDs]: false,
    [FieldNames.noActionableReason]: false,
  })
  const [hasSuggestionAdded, setHasSuggestionAdded] = useState(false)

  const commonRootCauseIds = findCommonRootCauseIds(tickets)
  const commonCustomTags = findCommonCustomTags(tickets)

  const stateMixed = new Set(tickets.map((t) => mapWorkItemsStateToCopilotDashState(t.workItemStatus))).size > 1
  const priorityMixed = new Set(tickets.map((t) => t.priority)).size > 1
  const areaMixed = new Set(tickets.map((t) => t.teamId)).size > 1
  const assignToMixed = new Set(tickets.map((t) => t.assignTo)).size > 1
  const rootCausesMixed =
    tickets.length > 1 && new Set(tickets.map((t) => t.rootCauseIds).flat()).size > commonRootCauseIds.length
  const customTagsMixed =
    tickets.length > 1 && new Set(tickets.map((t) => t.customTags).flat()).size > commonCustomTags.length

  const allStateSame = new Set(tickets.map((t) => mapWorkItemsStateToCopilotDashState(t.workItemStatus))).size === 1
  const allPrioritySame = new Set(tickets.map((t) => t.priority)).size === 1
  const allAreaSame = new Set(tickets.map((t) => t.teamId)).size === 1

  const allAssignToSame = new Set(tickets.map((t) => t.assignTo)).size === 1

  const disappearRCR = useMemo(() => {
    const rcrIssueId = suggestion?.workItemId
    return !!(rcrIssueId && selectedRootCause?.includes(rcrIssueId))
  }, [selectedRootCause, suggestion?.workItemId])

  const suggestionTeam = app.store.use((state) =>
    suggestion?.teamId ? state.team.teamsMap?.data?.[suggestion.teamId] : undefined,
  )

  const refreshPage = useCallback(
    (ticketsCount: number) => {
      // NOTE: @Ethan - As our service save is async, we need to wait for a while to refresh the page.
      sleep(Math.min(3000, 500 * ticketsCount)).then(() => {
        refreshTickets?.()
        onClose?.()
      })
    },
    [onClose, refreshTickets],
  )

  const validateSavable = useCallback(
    (formValues: ReturnType<ITicketActionFormRef['getValues']>) => {
      if (!stateMixed && isNil(formValues?.state)) return false
      if (!priorityMixed && isNil(formValues?.priority)) return false
      if (!areaMixed && isNil(formValues?.area)) return false
      return true
    },
    [stateMixed, priorityMixed, areaMixed],
  )

  const chunkArray = useCallback(<T,>(array: T[], size: number): Array<Array<T>> => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }, [])

  const groupAndFetch = useCallback(
    async (array: IPostData, size: number) => {
      const groups = chunkArray(array, size)
      let completedRequests = 0
      let progress = 0
      const id = setInterval(() => {
        if (savedTicketsCount > array.length) {
          clearInterval(id)
        } else {
          progress += 1
          if (progress < GROUP_SIZE) {
            setSavedTicketsCount(progress)
          } else {
            clearInterval(id)
          }
        }
      }, INTERVAL_DELAY)

      for (const group of groups) {
        try {
          // Update workitem
          await app.store.actions.batchUpdateWorkItems(group, signal)

          // Update CustomTag
          const customTagsRequest = group.map((ticket) => ({
            ticketId: ticket.feedbackId,
            customTags: ticket.customTags,
            workItemEntityId: ticket.workItemId,
          }))
          await app.store.actions.batchUpdateWorkItemCustomTags(customTagsRequest, signal)

          completedRequests += group.length
          app.store.actions.batchUpdateTicketsByWorkItems(group)
          setSavedTicketsCount(completedRequests)
        } catch (err) {
          toast.showError(`Failed to save. Cause:` + err)
          setSaving(false)
          throw new Error('Failed to save. Cause:' + err)
        }
      }
      toast.showSuccess(`Batch update ${completedRequests} tickets successfully!`)
      refreshPage(completedRequests)
      setSaving(false)
      setFormModified(false)
    },
    [chunkArray, refreshPage, savedTicketsCount, signal, toast],
  )

  const handleSave = useCallback(() => {
    if (!formRef.current) return
    if (!tickets || tickets.length === 0) return
    const values = formRef.current.getValues()
    if (!values) return

    const postDataDraft: IPostData = tickets.map((ticket) => {
      const uniqueCustomTags = (ticket.customTags ?? []).filter((tag) => !commonCustomTags.includes(tag))
      const mergedCustomTags = uniq([...uniqueCustomTags, ...(values['customTags'] ?? [])])

      const uniqueRootCauseIds = (ticket.rootCauseIds ?? []).filter(
        (rootCauseId) => !commonRootCauseIds.includes(rootCauseId),
      )
      const mergedRootCauseIds = uniq([...uniqueRootCauseIds, ...(values['rootCauseIDs'] ?? [])])

      return {
        feedbackId: ticket.ticketId,
        workItemId: ticket.workItemId!,
        priority: translateRootCausePriority(values.priority ?? ticket.priority!),
        dsatStatus: values.state
          ? mapCopilotDashStateToWorkItemState(
              values.state,
              !!values['area'],
              (values['rootCauseIDs']?.length ?? 0) > 0,
            )
          : ticket.workItemStatus!,
        teamId: values['area'] ?? ticket.teamId,
        rootCauseIdList: mergedRootCauseIds,
        assignTo: values.assignTo ?? ticket.assignTo,
        closedComment: values.noActionableReason ?? '',
        customTags: mergedCustomTags,
      }
    })

    const validation = postDataValidation.safeParse(postDataDraft)
    if (!validation.success) return
    setSavedTicketsCount(0)
    setSaving(true)

    groupAndFetch(postDataDraft, GROUP_SIZE)
  }, [tickets, groupAndFetch, commonRootCauseIds, commonCustomTags])

  const handleCancel = () => {
    controller.current.abort()
  }

  const handleGenerateQuerySet = useCallback(() => {
    if (tickets.length === 0) {
      toast.showError('No tickets found for this query')
      return
    }
    const templateId = uuid()
    const data: Partial<ISevalQueryData> = {
      templateId,
      templateName: `QuerySet-${new Date().toLocaleString()}`,
      querySeed: tickets.map((ticket) => ({
        ticketId: ticket.ticketId,
        messageId: ticket.messageId,
        utterance: ticket.utterance,
        datetimeInUtc: ticket.dateTimeUtc,
      })),
    }
    setQuerySetData(data)
    setQuerySetDialogOpen(true)
  }, [toast, tickets])

  const handleRejectSuggestion = useCallback(
    (suggestion: IRootCauseRecommendation) => {
      onRejectSuggestion?.(suggestion)
      reportAction(suggestion, RootCauseActionTypeV2.UserRejected, tickets)
    },
    [onRejectSuggestion, tickets],
  )
  const handleAcceptSuggestion = useCallback(
    (suggestion: IRootCauseRecommendation) => {
      onAcceptSuggestion?.(suggestion)
      const values = formRef.current?.getValues()
      values &&
        formRef.current?.setValues({
          ...values,
          rootCauseIDs: [...(values.rootCauseIDs ?? []), suggestion.workItemId].filter((v) => v),
        })
      reportAction(suggestion, RootCauseActionTypeV2.UserConfirmed, tickets)
      setHasSuggestionAdded(true)
    },
    [onAcceptSuggestion, tickets],
  )

  useEffect(() => {
    if (hasSuggestionAdded) {
      setTimeout(() => {
        handleSave()
        setHasSuggestionAdded(false)
      }, 100)
    }
  }, [hasSuggestionAdded, handleSave])

  const handleWatchForm = useCallback(
    (form: ITicketActionFormRef) => {
      formRef.current = form
      form?.watch(async (values, key) => {
        const pass = await formRef.current?.validate()
        if (!pass) {
          setAllowSave(false)
          return
        }
        setAllowSave(validateSavable(values))
        if (modifiedFieldRef.current) {
          modifiedFieldRef.current[key as FieldNames] = true
        }
      })
    },
    [validateSavable],
  )

  const theLonelyTicket = tickets.length === 1 ? tickets[0] : null

  return (
    <>
      <Drawer className={styles.drawer} open={open} type="inline" separator position="end">
        <DrawerHeader>
          <Row vAlign="center" fill>
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<DismissRegular />}
              onClick={() => {
                onClose?.()
              }}
            />
            <Text className={styles.title}>Batch Management</Text>
            <Spacer />
            <Button
              disabled={!formModified || !allowSave || saving}
              appearance="primary"
              onClick={handleSave}
              icon={saving ? <Spinner size="extra-tiny" /> : null}
            >
              Save
            </Button>
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Column>
            <Text>{tickets.length} tickets selected, you will manage these tickets.</Text>
            <Spacer height={20} />
            {tickets.length > GROUP_SIZE && saving && (
              <Row>
                <Field
                  validationMessage={`${savedTicketsCount} ticket updated......`}
                  validationState="none"
                  style={{ width: '100%' }}
                >
                  <ProgressBar max={tickets.length} value={savedTicketsCount} />
                </Field>
                <text
                  style={{ color: 'blue', cursor: 'pointer', position: 'absolute', left: '146px' }}
                  onClick={handleCancel}
                >
                  Cancel
                </text>
              </Row>
            )}
            <TicketActionForm
              key={tickets.map((t) => t.ticketId).join(',')}
              ref={handleWatchForm}
              className={styles.form}
              rootCauseMaxWidth={styles.rootCauseMaxWidth}
              gridLayout={{
                templateColumns: 'auto',
                templateRows: 'auto 0px auto 20px auto 20px auto 20px auto 20px auto 20px auto',
                templateAreas: [
                  ['state'],
                  ['.'],
                  ['noActionableReason'],
                  ['.'],
                  ['priority'],
                  ['.'],
                  ['area'],
                  ['.'],
                  ['assignTo'],
                  ['.'],
                  ['customTags'],
                  ['.'],
                  ['rootCauseIDs'],
                ],
              }}
              orientation="vertical"
              disabled={{
                state: false,
                priority: false,
                area: false,
                rootCauseIDs: false,
              }}
              defaultValues={{
                state: theLonelyTicket?.status
                  ? mapADOStateToCopilotDashState(theLonelyTicket.status, theLonelyTicket.reasoning)
                  : allStateSame
                    ? mapADOStateToCopilotDashState(tickets[0]!.status, tickets[0]!.reasoning)
                    : undefined,
                noActionableReason: theLonelyTicket?.closedComment || 'N/A',
                priority: theLonelyTicket?.priority ?? (allPrioritySame ? tickets[0]!.priority : undefined),
                area: areaMixed ? undefined : allAreaSame ? String(tickets[0]?.teamId) : undefined,
                assignTo: assignToMixed ? undefined : allAssignToSame ? tickets[0]?.assignTo : undefined,
                rootCauseIDs: commonRootCauseIds,
                customTags: commonCustomTags,
              }}
              defaultValueTexts={{
                state: stateMixed ? 'Mixed' : '',
                priority: priorityMixed ? 'Mixed' : '',
                area: areaMixed ? 'Mixed' : '',
                assignTo: assignToMixed ? 'Mixed' : '',
                rootCauseIDs: rootCausesMixed ? 'Mixed' : '',
                customTags: customTagsMixed ? 'Mixed' : '',
              }}
              fieldsProps={
                rootCausesMixed
                  ? {
                      rootCauses: {
                        validationState: 'none',
                        validationMessage:
                          'Some ticket(s) got more than 1 root causes, please check and manage the ticket(s) separately to avoid mis-operation',
                      },
                    }
                  : {}
              }
              enableRules={{
                state: stateMixed ? false : true,
                noActionableReason: true,
                priority: priorityMixed ? false : true,
                area: areaMixed ? false : true,
                rootCauseIDs: true,
              }}
              onModified={(modified, data) => {
                setFormModified(modified)
                if (data && data.rootCauseIDs) {
                  setSelectedRootCause?.(data.rootCauseIDs)
                }
              }}
              productId={productId}
            />
            {suggestion && !disappearRCR && (
              <>
                <Spacer height={32} />
                <Text size={400} weight="bold">
                  Root Cause Recommendation
                </Text>
                <Spacer height={10} />
                <Column className={styles.suggestion}>
                  <Text size={400} weight="bold">
                    {suggestion.title}
                  </Text>
                  <Text size={300}>Team: {suggestionTeam?.name}</Text>
                  <Row className={styles.suggestionActionArea}>
                    <Spacer />
                    <Button
                      onClick={() => handleRejectSuggestion(suggestion)}
                      icon={<ThumbDislikeRegular fontSize={14} />}
                    >
                      This is incorrect
                    </Button>
                    <Spacer width={8} />
                    <Button
                      onClick={() => handleAcceptSuggestion(suggestion)}
                      className={styles.addBtn}
                      icon={<AddRegular fontSize={14} />}
                    >
                      Add
                    </Button>
                  </Row>
                  <Row vAlign="center">
                    <Spacer />
                    <LightbulbFilamentRegular fontSize={'16px'} />
                    <Spacer width={2} />
                    <Text>Generated by AI. Check for accuracy.</Text>
                  </Row>
                </Column>
              </>
            )}
            {isQuerySetVisible && (
              <Column className={styles.otherActions}>
                <Spacer height={32} />
                <Text weight="semibold" size={200} className={styles.otherActionsText}>
                  Other Action(s)
                </Text>
                <Spacer height={8} />
                <Button
                  appearance="transparent"
                  icon={<BookCoinsRegular />}
                  className={styles.querySet}
                  onClick={handleGenerateQuerySet}
                >
                  Generate Query Set Template
                </Button>
              </Column>
            )}
          </Column>
        </DrawerBody>
      </Drawer>
      {querySetDialogOpen && querySetData && (
        <GenerateQuerySetTemplate
          defaultQuerySet={querySetData}
          open={querySetDialogOpen}
          onClose={() => setQuerySetDialogOpen(false)}
        />
      )}
    </>
  )
}

BatchUpdateTicketsPanel.displayName = 'BatchUpdateTicketsPanel'
