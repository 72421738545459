import { uuid } from '@copilot-dash/core'
import {
  BasicSearchTicketArgsNames,
  ISearchTicketOptions,
  SearchTextPrefixType,
  SearchTicketFacet,
  SearchTicketResult,
  TicketRingType,
  getProductEndpoints,
} from '@copilot-dash/domain'
import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { SearchTicketIdByAISearchAction } from './SearchTicketIdByAISearchAction'
import { IDashStoreContext } from '../../IDashStoreContext'

export class SearchTicketAction {
  private readonly SearchTicketIdByAISearchAction: SearchTicketIdByAISearchAction

  constructor(context: IDashStoreContext) {
    this.SearchTicketIdByAISearchAction = new SearchTicketIdByAISearchAction(context)
  }

  async search(options: ISearchTicketOptions, facets?: Array<SearchTicketFacet>): Promise<SearchTicketResult> {
    const searchId = uuid()
    const startTime = Date.now()
    const page = this.pageLocation(options)
    try {
      Logger.telemetry.trackEvent('Search', { filters: options, searchId })
      Logger.telemetry.trackEvent('SearchStart', { filters: options, searchId })
      return await this.doSearch(options, searchId, facets)
    } finally {
      const duration = Date.now() - startTime

      Logger.telemetry.trackEvent('SearchEnd', { filters: options, searchId })
      Logger.telemetry.trackMetric('SearchTime', {
        duration,
        filters: options,
        searchId,
        page,
      })
    }
  }

  @EnableExceptionTracking()
  private async doSearch(
    options: ISearchTicketOptions,
    searchId: string,
    facets?: Array<SearchTicketFacet>,
  ): Promise<SearchTicketResult> {
    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.scenarios,
    )
    if (clientNames.length === 0 && options[BasicSearchTicketArgsNames.id]?.length === 0) {
      return this.createEmptyResult(searchId)
    }

    const updatedOptions: ISearchTicketOptions = {
      ...options,
      client: clientNames,
      channel: options.channel,
      ring: options.ring || [
        TicketRingType.DONMT,
        TicketRingType.MSIT,
        TicketRingType.SDFV2,
        TicketRingType.SIP,
        TicketRingType.WW,
        TicketRingType.UNKNOWN,
      ],
      searchTextPrefix: options.searchTextPrefix || SearchTextPrefixType.All,
    }
    return await this.SearchTicketIdByAISearchAction.search(updatedOptions, facets)
  }

  private pageLocation = (options: ISearchTicketOptions): 'Overview' | 'Team View' => {
    if (options.teamId || options.teamName) {
      return 'Team View'
    }
    return 'Overview'
  }

  private createEmptyResult(searchId: string): SearchTicketResult {
    return {
      searchId: searchId,
      ticketCount: 0,
      tickets: [],
      hasMore: false,
      errorMessage: '',
      satCount: 0,
      dsatCount: 0,
    }
  }
}
