import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const messageId = generateKustoStringArrayExp([params.messageId])
  const botRequestIds = generateKustoStringArrayExp(params.botRequestIds)
  const correlationIds = generateKustoStringArrayExp(params.correlationIds)
  const timeRange = generateKustoTimeRangeExp(params.time, 360)

  return `
Slicer_Dataset
| where RequestTime ${timeRange}
| where RowType == 'Slicer'
| where RequestId in~ (${messageId}, ${botRequestIds}) or CorrelationId in~ (${correlationIds})
| order by IngestionDateTime
| take 1
`
}

export const Slicer_WW: IKustoQueryDefinition = {
  database: 'ScoreDataset',
  connection: 'https://msaidataexplorerweu.westeurope.kusto.windows.net',
  query,
}
