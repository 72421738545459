import { Row } from '@copilot-dash/components'
import { Option, Persona, Popover, PopoverSurface, Spinner, mergeClasses } from '@fluentui/react-components'
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { useEffect, useState } from 'react'
import { useStyles } from './MentionsMenu.styles'
import { MentionOptions } from './MentionsPlugin'

interface MentionsMenuProps {
  anchorElementRef: React.RefObject<HTMLElement>
  selectedIndex: number | null
  selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void
  setHighlightedIndex: (index: number) => void
  options: MentionOptions
}

type MentionUser = {
  displayName: string
  uid: string
  emailAddress: string | undefined
}

export class MentionTypeaheadOption extends MenuOption {
  mentionUser: MentionUser
  constructor(mentionUser: MentionUser) {
    super(mentionUser.displayName)
    this.mentionUser = mentionUser
  }
}

export const MentionsMenu: React.FC<MentionsMenuProps> = ({
  anchorElementRef,
  selectedIndex,
  selectOptionAndCleanUp,
  setHighlightedIndex,
  options,
}) => {
  const styles = useStyles()
  const [rect, setRect] = useState(anchorElementRef.current?.getBoundingClientRect())
  const newCurrentRect = anchorElementRef.current?.getBoundingClientRect()

  function MentionsTypeAheadMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option,
  }: {
    index: number
    isSelected: boolean
    onClick: () => void
    onMouseEnter: () => void
    option: MentionTypeaheadOption
  }) {
    if (option) {
      return (
        <Option
          key={option.key}
          tabIndex={-1}
          className={isSelected ? mergeClasses(styles.option, styles.optionSelected) : styles.option}
          ref={option.setRefElement}
          role="option"
          aria-selected={isSelected}
          id={'typeahead-item-' + index}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          text={option.mentionUser.displayName}
        >
          <Persona
            avatar={{ color: 'colorful', 'aria-hidden': true }}
            key={option.mentionUser.uid}
            name={option.mentionUser.displayName}
            secondaryText={option.mentionUser.emailAddress}
          />
        </Option>
      )
    }
    return null
  }

  useEffect(() => {
    if (!rect || !rect?.x || !rect.y) {
      if (newCurrentRect?.x && newCurrentRect?.y) {
        setRect(newCurrentRect)
      }
    }
  }, [newCurrentRect, rect])

  let target = undefined
  if (rect) {
    target = {
      getBoundingClientRect: () => {
        return rect
      },
      contextElement: anchorElementRef.current ?? undefined,
    }
  }

  return (
    <Popover
      positioning={{
        target: target,
        position: 'above',
        align: 'start',
      }}
      open={true}
      unstable_disableAutoFocus
    >
      <PopoverSurface style={{ padding: 0 }}>
        <div className={styles.mentionDropDown}>
          {options.status === 'waiting' ? (
            <Row hAlign="center" className={styles.status}>
              <Spinner label="Loading..." />
            </Row>
          ) : options.status === 'done' && options.data.length > 0 ? (
            options.data.map((option: MentionTypeaheadOption, i) => {
              return (
                <MentionsTypeAheadMenuItem
                  index={i}
                  isSelected={selectedIndex === i}
                  onClick={() => {
                    setHighlightedIndex(i)
                    selectOptionAndCleanUp(option)
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(i)
                  }}
                  key={option.key}
                  option={option}
                />
              )
            })
          ) : options.status === 'done' && options.data.length === 0 ? (
            <Row hAlign="center" className={styles.status}>
              No results found
            </Row>
          ) : null}
        </div>
      </PopoverSurface>
    </Popover>
  )
}
