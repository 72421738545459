import { Column, Row } from '@copilot-dash/components'

import { IFCConversationMessageData } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { UserMessageContext } from './UserMessageContext'

interface IProps {
  readonly data: IFCConversationMessageData
}

export function UserMessageTile({ data }: IProps) {
  const styles = useStyles()
  if (!data.query) {
    return null
  }

  return (
    <Row className={styles.root}>
      <Column className={styles.content} hAlign="end">
        <UserMessageContext text={data.query} />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    gap: '12px',
  },
  content: {
    flex: '1 1 0',
    gap: '8px',
    overflow: 'hidden',
  },
})
