import { TeamId, UN_TRIAGED_TEAM_IDS, IWorkItem, WorkItemStatus } from '@copilot-dash/domain'

export const EMPTY_ROOT_CAUSE = ''

export const TICKET_STEP_STAGES = ['Untriaged', 'Team Assigned', 'Root Caused', 'Closed'] as const

export function getTicketStepStageByFields(
  status: (typeof COPILOT_DASH_STATE_OPTIONS)[number] | undefined,
  rootCauseList: unknown[] | undefined,
  teamId: TeamId | undefined,
): (typeof TICKET_STEP_STAGES)[number] {
  if (status === 'Active') {
    if (!teamId || (teamId && UN_TRIAGED_TEAM_IDS.includes(Number(teamId)))) return 'Untriaged'
    if (rootCauseList?.length) {
      return 'Root Caused'
    } else {
      return 'Team Assigned'
    }
  } else {
    return 'Closed'
  }
}

export function mapWorkItemToTicketActionFormValue(workItemsInfo: IWorkItem, ticketCustomTags?: string[]) {
  return {
    state: mapWorkItemsStateToCopilotDashState(workItemsInfo.dsatStatus),
    noActionableReason: workItemsInfo.closedComment || 'N/A',
    priority: Number(workItemsInfo.priority.split('')[1]),
    area: String(workItemsInfo.teamId),
    rootCauseIDs: workItemsInfo.rootCauseIdList,
    assignTo: workItemsInfo.assignTo,
    customTags: ticketCustomTags,
  }
}

export type ElementType<T extends ReadonlyArray<unknown>> = T[number]

export const PRIORITY_OPTIONS = [0, 1, 2, 3, 4] as const
const ADO_STATE_OPTIONS = ['New', 'Active', 'Resolved', 'Closed'] as const
export const COPILOT_DASH_STATE_OPTIONS = [
  'Active',
  'Closed - Not Actionable',
  'Closed - Fixed',
  'Closed - By Design',
] as const

export function mapADOStateToCopilotDashState(
  adoState: ElementType<typeof ADO_STATE_OPTIONS> | '' | undefined,
  reason?: string,
): ElementType<typeof COPILOT_DASH_STATE_OPTIONS> {
  switch (adoState) {
    case undefined:
    case '':
    case ADO_STATE_OPTIONS[0]:
    case ADO_STATE_OPTIONS[1]:
      return COPILOT_DASH_STATE_OPTIONS[0]
    case ADO_STATE_OPTIONS[2]:
      return COPILOT_DASH_STATE_OPTIONS[2]
    case ADO_STATE_OPTIONS[3]: {
      if (reason === 'Fixed and verified') {
        return COPILOT_DASH_STATE_OPTIONS[2]
      } else if (reason === 'As Designed') {
        return COPILOT_DASH_STATE_OPTIONS[3]
      }
      return COPILOT_DASH_STATE_OPTIONS[1]
    }
  }
}

export function mapWorkItemsStateToCopilotDashState(
  workItemsState: WorkItemStatus | undefined,
): ElementType<typeof COPILOT_DASH_STATE_OPTIONS> {
  switch (workItemsState) {
    case WorkItemStatus.Untriaged:
    case WorkItemStatus.TeamAssigned:
    case WorkItemStatus.RootCaused:
      return COPILOT_DASH_STATE_OPTIONS[0]
    case WorkItemStatus.ClosedNotActionable:
      return COPILOT_DASH_STATE_OPTIONS[1]
    case WorkItemStatus.ClosedFixed:
      return COPILOT_DASH_STATE_OPTIONS[2]
    case WorkItemStatus.ClosedByDesign:
      return COPILOT_DASH_STATE_OPTIONS[3]
    default:
      return COPILOT_DASH_STATE_OPTIONS[0]
  }
}

export function mapCopilotDashStateToWorkItemState(
  copilotDashState: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>,
  teamAssigned: boolean,
  rootCaused: boolean,
): WorkItemStatus {
  switch (copilotDashState) {
    case COPILOT_DASH_STATE_OPTIONS[0]:
      return teamAssigned
        ? rootCaused
          ? WorkItemStatus.RootCaused
          : WorkItemStatus.TeamAssigned
        : WorkItemStatus.Untriaged
    case COPILOT_DASH_STATE_OPTIONS[1]:
      return WorkItemStatus.ClosedNotActionable
    case COPILOT_DASH_STATE_OPTIONS[2]:
      return WorkItemStatus.ClosedFixed
    case COPILOT_DASH_STATE_OPTIONS[3]:
      return WorkItemStatus.ClosedByDesign
    default:
      return WorkItemStatus.Untriaged
  }
}
