import { Row } from '@copilot-dash/components'
import { IActivityHistory } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ChevronDownFilled, ChevronUpFilled } from '@fluentui/react-icons'
import { FC } from 'react'
import { useStyles } from './ActivityTitle.styles'
import { ActivityTitleAvatarForSystem } from './ActivityTitleAvatarForSystem'
import { ActivityTitleAvatarForUser } from './ActivityTitleAvatarForUser'
import { TimeView } from '../../Time/TimeView'
import { useActivityHistoryPanelStore } from '../store/store'

interface IProps {
  readonly activity: IActivityHistory
}

export const ActivityTitle: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Row fill vAlign="center" className={styles.container}>
      <Row className={styles.leftContent}>
        {activity.actor === 'user' && activity.userId ? (
          <ActivityTitleAvatarForUser userId={activity.userId} />
        ) : (
          <ActivityTitleAvatarForSystem systemType={activity.system} />
        )}
      </Row>
      <Text className={styles.time}>
        <TimeView value={activity.timestamp} format="YYYY/MM/DD hh:mm A" />
        <Text
          className={
            activity?.fieldDiffs && Object.keys(activity.fieldDiffs).length > 0 ? styles.icon : styles.transparentIcon
          }
        >
          {selectedActivity?.activityId === activity.activityId ? <ChevronUpFilled /> : <ChevronDownFilled />}
        </Text>
      </Text>
    </Row>
  )
}
