import { Column } from '@copilot-dash/components'
import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'
import { Label } from '@fluentui/react-components'
import { memo, useMemo } from 'react'

import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (selectedOptions: string[]) => void
}
export const HasConnectorResultFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('hasConnectorResult')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('hasConnectorResult')

  return (
    <Column>
      <Label weight="semibold">{displayName}:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={selectedValues}
        onChange={onChangeValue}
        multiple={false}
      />
    </Column>
  )
})

HasConnectorResultFilter.displayName = 'HasConnectorResultFilter'
