import { Body, Column, Row } from '@copilot-dash/components'
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { memo, useEffect, useRef, useState } from 'react'

import { ConversationPanel } from './conversation/ConversationPanel'
import { FeedbackCopilotHeader } from './FeedbackCopilotHeader'
import { useStyles } from './FeedbackCopilotMain.styles'
import { QueryBox } from './querybox/QueryBox'

const allowInputStatus = ['QueryReady', 'QueryFailed', 'QueryCancelled']

export const FeedbackCopilotMain = memo(function FeedbackCopilotMain() {
  const styles = useStyles()
  const [disableInput, setDisableInput] = useState(false)
  const lastTurnStatus = app.store.use().feedbackCopilot.lastFCConversation
  const scrollbarRef = useRef<OverlayScrollbarsComponentRef>(null)

  useEffect(() => {
    if (lastTurnStatus) {
      setDisableInput(
        lastTurnStatus?.status !== 'done' ||
          (lastTurnStatus?.data.queryStatus && !allowInputStatus.includes(lastTurnStatus?.data.queryStatus)) ||
          false,
      )
    }
  }, [lastTurnStatus])

  return (
    <Column className={styles.container}>
      <FeedbackCopilotHeader />
      <Body className={styles.startupbody}>
        <Column hAlign="stretch" style={{ width: '100%' }}>
          <OverlayScrollbarsComponent
            ref={scrollbarRef}
            options={{ scrollbars: { autoHide: 'never' } }}
            style={{
              padding: '10px',
              overflow: 'hidden',
            }}
            events={{
              initialized: (instance) => {
                instance.elements().viewport.scrollTo(9999, 9999)
              },
              updated: (instance) => {
                instance.elements().viewport.scrollTo(9999, 9999)
              },
            }}
          >
            <Row className={styles.contentbody}>
              <ConversationPanel />
            </Row>
          </OverlayScrollbarsComponent>
          <Row className={styles.contentInput}>
            <Column hAlign="stretch" style={{ width: '100%' }}>
              <QueryBox diableInput={disableInput} />
            </Column>
          </Row>
        </Column>
      </Body>
    </Column>
  )
})
