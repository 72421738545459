import { get } from 'lodash'
import { generateAnyText } from './generateAnyText'
import { generateAnyTextLength } from './generateAnyTextLength'

const SAMPLE_COUNT = 5

export function generateAnyColumnWidth(key: string, data: unknown[]): number {
  let max = generateAnyTextLength(key)

  const len = Math.min(SAMPLE_COUNT, data.length)
  for (let i = 0; i < len; i++) {
    const value = get(data[i], key)
    const valueText = generateAnyText(value)
    const valueTextLength = generateAnyTextLength(valueText)

    max = Math.max(max, valueTextLength)
  }

  return max
}
