import { ApiTopIssueExtendInfo } from '@copilot-dash/api'
import { ITopIssueExtendInfo } from '@copilot-dash/domain'

export function convertTopIssueExtendInfoFromApi(data: ApiTopIssueExtendInfo): ITopIssueExtendInfo {
  return {
    issueId: data.issueId,
    issueTitle: data.issueTitle,
    priority: data.priority,
    coverage: data.coverage,
    trends: data.trends,
    isNew: data.isNew,
    ticketCount: data.ticketCount,
    impactedTenantsCount: data.impactedTenantsCount,
    ticketStatusCount: data.ticketStatusCount,
    VIPPowerUserIdList: data.vIPPowerUserIdList
      .map((id) => {
        if (id.includes(':')) {
          const [, userId] = id.split(':')
          return userId ?? ''
        }
        return id
      })
      .filter((id) => id),
    CAPTenantNameList: data.cAPTenantNameList,
    cAPTenantList: data.cAPTenantList,
  }
}
