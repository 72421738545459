import { TelemetryScope } from '@copilot-dash/logger'
import { useEffect } from 'react'
import { ActivityHistoryPanelLayout } from './ActivityHistoryPanelLayout'
import { useActivityHistoryPanelStore } from './store/store'
import { ErrorViewBoundary } from '../Error'

interface IProps {
  readonly ticketId: string
}

export function ActivityHistoryPanel({ ticketId }: IProps) {
  useEffect(() => {
    useActivityHistoryPanelStore.getState().setTicketId(ticketId)
    useActivityHistoryPanelStore.getState().getActivityHistory(ticketId)
  }, [ticketId])

  return (
    <ErrorViewBoundary label="ActivityHistoryPanel">
      <TelemetryScope scope="ActivityHistoryPanel" properties={{ ticketId: ticketId }} key={ticketId}>
        <ActivityHistoryPanelLayout />
      </TelemetryScope>
    </ErrorViewBoundary>
  )
}
