import { Row } from '@copilot-dash/components'
import { Text, tokens } from '@fluentui/react-components'
import { ChevronRightRegular } from '@fluentui/react-icons'
import { DSATs } from './DSATs'
import { Issue } from './Issue'
import { Priority } from './Priority'
import { State } from './State'
import { Tenants } from './Tenants'
import { IRowData } from './types'
import { VIPUserAndCAPTenants } from './VIPUserAndCAPTenants'

export const columnsConfig: Array<{
  id: string
  renderHeaderCell: () => JSX.Element
  renderCell: (data: IRowData, index?: number, context?: IRowData[]) => JSX.Element
  cellStyle?: React.CSSProperties
}> = [
  {
    id: 'priority',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}>
        Priority
      </Text>
    ),
    renderCell: (data) => <Priority data={data} />,
    cellStyle: {
      minWidth: 120,
      flex: '0 0 120px',
      minHeight: 32,
    },
  },
  {
    id: 'issueTitle',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}>
        Issue
      </Text>
    ),
    renderCell: (data) => <Issue data={data} />,
    cellStyle: {
      minWidth: 300,
      flex: '1 1 600px',
      minHeight: 32,
    },
  },
  {
    id: 'DSATs',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}>
        DSATs
      </Text>
    ),
    renderCell: (data) => <DSATs data={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      minHeight: 32,
      flex: '0 0 180px',
    },
  },
  {
    id: 'Tenants',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}>
        Tenants
      </Text>
    ),
    renderCell: (data) => <Tenants data={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      minHeight: 32,
      flex: '0 0 180px',
    },
  },
  {
    id: 'State',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}>
        State
      </Text>
    ),
    renderCell: (data, _, context) => {
      const tableTotalTicketStatusCount = context?.reduce((acc, rowData) => {
        if (rowData.extendInfo.status === 'done') {
          const { closed, rootCaused, teamAssigned } = rowData.extendInfo.data.ticketStatusCount
          const untriaged = rowData.extendInfo.data.ticketCount - closed - rootCaused - teamAssigned
          return Math.max(acc, closed + rootCaused + teamAssigned + untriaged)
        }
        return acc
      }, 0)
      return <State data={data} tableTotalTicketStatusCount={tableTotalTicketStatusCount} />
    },
    cellStyle: {
      minWidth: 350,
      minHeight: 32,
      flex: '0 0 350px',
    },
  },
  {
    id: 'VIPUserAndTenant',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}></Text>
    ),
    renderCell: (data) => (
      <Row style={{ justifyContent: 'flex-end', width: '100%' }}>
        <VIPUserAndCAPTenants data={data} />
      </Row>
    ),
    cellStyle: {
      minWidth: 180,
      maxWidth: 400,
      minHeight: 32,
      flex: '1 1 180px',
    },
  },
  {
    id: 'Arrow',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} style={{ color: tokens.colorNeutralForeground3 }}></Text>
    ),
    renderCell: () => <ChevronRightRegular />,
    cellStyle: {
      minWidth: 50,
      maxWidth: 50,
      minHeight: 32,
      flex: '0 0 50px',
    },
  },
]
