import { LinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import * as React from 'react'
import { useEffect } from 'react'

export const CustomTransformNodePlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const unregisterTransform = editor.registerNodeTransform(LinkNode, (node) => {
      // Transform LinkNode to ensure that its custom properties are correct
      if (node.getTarget() !== '_blank') {
        node.setTarget('_blank')
      }
      if (node.getRel() !== 'noreferrer noopener') {
        node.setRel('noreferrer noopener')
      }
    })

    return unregisterTransform
  }, [editor])

  return null
}
