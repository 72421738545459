import { Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { RouteLink } from '../../../../router'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicketADOLink() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const workItemSnapshot = app.store.use.getWorkItemByFeedbackId(ticketId)

  const vsoLinkObject = useMemo(() => {
    if (workItemSnapshot.status !== 'done') {
      return null
    }
    const adoLink = workItemSnapshot.data?.adoWorkItemLink
    const adoId = workItemSnapshot.data?.adoWorkItemId
    if (adoId && adoLink) {
      return { vsoTicketId: adoId, vsoLink: adoLink }
    } else {
      return null
    }
  }, [workItemSnapshot])

  if (!vsoLinkObject) {
    return null
  }

  return (
    <Text>
      <Text weight="semibold">ADO:&nbsp;</Text>
      <RouteLink path={vsoLinkObject.vsoLink} openInNewTab type="link">
        {vsoLinkObject.vsoTicketId}
      </RouteLink>
    </Text>
  )
}
