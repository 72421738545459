export type TriggeredSkillName = '3S' | 'MomentsPlugin' | 'WebSearch' | 'LLMOnly'

export interface ITriggeredSkill {
  readonly key: TriggeredSkillName
  readonly text: string
  readonly options: string[]
}

export const TicketTriggeredSkillList: ITriggeredSkill[] = [
  {
    key: '3S',
    text: '3S',
    options: ['3S:Triggered'],
  },
  {
    key: 'WebSearch',
    text: 'Web search',
    options: ['WebSearch:Triggered'],
  },

  {
    key: 'MomentsPlugin',
    text: 'Moments plugin',
    options: ['Moments:Triggered'],
  },
  {
    key: 'LLMOnly',
    text: 'LLM only',
    options: ['!3S:Triggered', '!WebSearch:Triggered', '!Moments:Triggered'],
  },
]
