import { Column, Row } from '@copilot-dash/components'
import { Button, mergeClasses, Spinner } from '@fluentui/react-components'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { COMMAND_PRIORITY_HIGH, KEY_DOWN_COMMAND } from 'lexical'
import { useEffect, useState } from 'react'
import { useStyles } from './KeyboardPlugin.styles'
import { LexicalRichTextEditorStore } from '../../LexicalRichTextEditorStore'
import { isEditorEmpty } from '../../utils/lexical'

export function DescriptionKeyboardPlugin() {
  const styles = useStyles()
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const actions = LexicalRichTextEditorStore.useActions()
  const isSubmitting = LexicalRichTextEditorStore.use((state) => state.isSubmitting)
  const isUploading = LexicalRichTextEditorStore.use((state) => state.isUploading)
  const initialValue = LexicalRichTextEditorStore.use((state) => state.initialValue)
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    actions.prepare(editor)

    const unregister = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'Enter') {
          actions.submit()
          return true
        }
        return false
      },
      COMMAND_PRIORITY_HIGH,
    )

    return () => {
      unregister()
    }
  }, [actions, editor])

  useEffect(() => {
    if (!initialValue) {
      setIsEmpty(true)
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          if (initialValue) {
            setIsEmpty(false)
          } else {
            setIsEmpty(isEditorEmpty(editorState))
          }
        })
      }),
    )
  }, [editor, initialValue])

  return (
    <Row>
      {!isSubmitting ? (
        <Column>
          <Button
            size="small"
            appearance="transparent"
            className={styles.cancelDescriptionBtn}
            onClick={actions.cancel}
            disabled={isSubmitting || isUploading}
          >
            Cancel
          </Button>
          <Button
            size="small"
            appearance="primary"
            className={styles.submitDescriptionBtn}
            onClick={actions.submit}
            disabled={isSubmitting || isUploading || isEmpty}
          >
            Save
          </Button>
        </Column>
      ) : (
        <Spinner size="tiny" className={mergeClasses(styles.baseLoadingStyle, styles.sendLoading)} />
      )}
    </Row>
  )
}
