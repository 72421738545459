import { z } from 'zod'

const apiUserpGroup2ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
  }),
)

const apiUserpGroup2ItemSchema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiUserpGroup2ReplayResponseSchema.optional(),
  }),
)

export const apiUserpGroup2Schema = z.array(apiUserpGroup2ItemSchema)
