import { IRootCauseCustomTag } from '@copilot-dash/domain'
import { updateAllRootCauseCustomTags } from './updateAllRootCauseCustomTags'
import { updateRootCauseCustomTag } from './updateRootCauseCustomTag'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function patchRootCauseCustomTags(
  context: IDashStoreContext,
  rootCauseGuid: string,
  customTags: Array<string>,
): Promise<IRootCauseCustomTag> {
  const response = await context.api.copilotDash.patchRootCauseCustomTags(rootCauseGuid, customTags)
  updateRootCauseCustomTag(context, rootCauseGuid, response.customTags)
  updateAllRootCauseCustomTags(context, response.customTags)
  return response
}
