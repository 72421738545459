import { DashErrorLevel, TicketError, TicketErrorSource } from '@copilot-dash/error'
import { CheckmarkCircleRegular, ErrorCircleRegular } from '@fluentui/react-icons'
import { TicketErrorContextView } from './components/TicketErrorContextView'
import { IErrorConfig } from './interfaces/IErrorConfig'

export class ErrorConfigForTicket implements IErrorConfig {
  private readonly error: TicketError

  constructor(error: TicketError) {
    this.error = error
  }

  buildLevel() {
    switch (this.error.data.level) {
      case DashErrorLevel.Fine:
        return 'OK'
      case DashErrorLevel.Warning:
        return 'WARNING'
      case DashErrorLevel.Error:
        switch (this.error.data.source) {
          case TicketErrorSource.FeedbackPlatform:
            // TODO: PMs require the same color for warnings/errors😓. But I think we need an approach to differentiate between error and warning.
            // return 'ERROR'
            return 'WARNING'
          case TicketErrorSource.User:
          case TicketErrorSource.CopilotClient:
          case TicketErrorSource.Sydney:
          case TicketErrorSource.SubstrateSearch:
          case TicketErrorSource.Kusto:
            return 'WARNING'
          case TicketErrorSource.ByDesign:
            return 'OK'
        }
    }
  }

  buildIcon() {
    switch (this.error.data.level) {
      case DashErrorLevel.Fine:
        return <CheckmarkCircleRegular />
      case DashErrorLevel.Warning:
      case DashErrorLevel.Error:
        return <ErrorCircleRegular />
    }
  }

  buildName() {
    return 'Ticket Error'
  }

  buildMessage() {
    return this.error.data.message
  }

  buildMessageDetails() {
    return this.error.data.description
  }

  buildContext() {
    return <TicketErrorContextView data={this.error.data} />
  }
}
