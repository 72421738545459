import { z } from 'zod'

export interface ApiQuery {
  readonly queryId: string
  readonly title: string
  readonly productId?: number
  readonly isTeamShared: boolean
  readonly teamId?: number
  readonly userId?: string
  readonly createdBy: string
  readonly createdTime: string
  readonly lastModifiedBy: string
  readonly lastModifiedTime: string
  readonly copilotDashQuery: string
  readonly copilotDashPath: string
  readonly filterPayload: string
  readonly oDataQuery?: string
}

export interface ApiGetQueryResponse {
  readonly queries: Array<ApiQuery>
}

export const apiQuerySchema = z.object({
  queryId: z.string(),
  title: z.string(),
  productId: z.number().optional(),
  isTeamShared: z.boolean(),
  teamId: z.number().optional(),
  userId: z.string().optional(),
  createdBy: z.string(),
  createdTime: z.string(),
  lastModifiedBy: z.string(),
  lastModifiedTime: z.string(),
  copilotDashQuery: z.string(),
  copilotDashPath: z.string(),
  filterPayload: z.string(),
  oDataQuery: z.string().optional(),
})

export const apiGetQueryResponseSchema = z.object({
  queries: z.array(apiQuerySchema),
})
