import { Row } from '@copilot-dash/components'
import { makeStyles, shorthands, Text } from '@fluentui/react-components'
import { InfoRegular } from '@fluentui/react-icons'
import { DatePanelProps } from 'react-multi-date-picker/plugins/date_panel'

export function TimeZoneInfo(props: Pick<DatePanelProps, 'position'> & { readonly timezone: string }) {
  const styles = useStyles()

  return (
    <Row className={styles.root} vAlign="center">
      <InfoRegular fontSize={14} className={styles.icon} />
      <Text className={styles.message} wrap>
        {`Display time follows ${props.timezone} time.`}
      </Text>
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    width: '270px',
    ...shorthands.border('0px'),
    ...shorthands.borderTop('1px', 'solid', 'rgba(0,0,0,0.15)'),
    textAlign: 'left',
    alignItems: 'center',
    ...shorthands.padding('0px', '0px', '0px', '4px'),
  },
  icon: {
    minWidth: '16px',
  },
  message: {
    ...shorthands.padding('4px'),
    alignItems: 'baseline',
  },
})
