import { ApiV2Ticket, ApiV2TicketSourceType } from '@copilot-dash/api'
import { getKustoQueryDefinition } from './getKustoQueryDefinition'
import { IKustoQueryParams } from './types/IKustoQueryDefinition'

interface IParams {
  readonly ticket: ApiV2Ticket
  readonly sourceType: ApiV2TicketSourceType
  readonly messageId: string
}

export function getKustoQueryUrl({ ticket, sourceType, messageId }: IParams): string | undefined {
  const isEU = ticket.user?.isEU
  const region = isEU ? 'EU' : 'WW'
  const definition = getKustoQueryDefinition(sourceType, region)
  if (!definition) {
    return
  }

  const time = ticket.userFeedback.createdDateTime
  const conversationId = ticket.diagnosticContext?.conversationId
  if (!conversationId) {
    return
  }

  const correlationId = ticket.diagnosticContext?.correlationId
  if (!correlationId) {
    return
  }

  const params: IKustoQueryParams = {
    messageId,
    time,
    conversationId,
    botRequestIds: ['BOT_REQUEST_ID_PLACEHOLDER'],
    correlationIds: [correlationId],
    transactionIds: ['TRANSACTION_ID_PLACEHOLDER'],
  }

  // Cluster
  const connection = new URL(definition.connection)
  const host = connection.host
  const cluster = host.replace('.kusto.windows.net', '')
  const clusterEncoded = encodeURIComponent(cluster)

  // Database
  const database = definition.database
  const databaseEncoded = encodeURIComponent(database)

  // Query
  const query = definition.query(params).trim()
  const queryEncoded = encodeURIComponent(query)

  return `https://dataexplorer.azure.com/clusters/${clusterEncoded}/databases/${databaseEncoded}/query?query=${queryEncoded}`
}
