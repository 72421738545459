import { ITicketDiagnosticSource } from '@copilot-dash/domain'
import { DocumentRegular } from '@fluentui/react-icons'
import { OutlineItem } from './OutlineItem'
import { TicketDiagnosticStore } from '../../TicketDiagnosticStore'

interface IProps {
  readonly data: ITicketDiagnosticSource
}

export function OutlineItemForTicketBlob({ data }: IProps) {
  const actions = TicketDiagnosticStore.useActions()
  const active = TicketDiagnosticStore.use((state) => state.activeSourceUrl === data.url)

  const handleOnActive = () => {
    if (data.url) {
      actions.setActiveSourceUrl(data.url)
    }
  }

  return (
    <OutlineItem
      icon={<DocumentRegular />}
      title={data.title}
      subtitle={data.subtitle}
      active={active}
      onActive={handleOnActive}
    />
  )
}
