import { TicketErrorCode } from './TicketErrorCode'
import { TicketErrorSource } from './TicketErrorSource'
import { DashErrorLevel } from '../core/DashErrorLevel'

/**
 * This file from server-side
 *
 * https://dev.azure.com/O365Exchange/O365%20Core/_git/TenantsFeedback?path=%2Fsources%2FTenantsFeedback%2FModels%2Fdev%2FBizchatReplayErrorType.cs
 */
export const TicketErrorCodes = [
  // Succeeded
  {
    code: [0],
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Succeeded.',
  },

  // Service internal error
  {
    code: [1],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No data found',
    description: 'Feedback service encountered an internal error.',
  },

  // Empty feedback id
  {
    code: [2],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Client failed to pass feedback ID to feedback service.',
  },

  // Received wrong conversation ids and sydney ids
  {
    code: [1000],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Client passed wrong trace ID to feedback service.',
  },
  {
    code: [1001],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No conversation found',
    description: 'Client passed wrong conversation ID to feedback service.',
  },
  {
    code: [1003],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No conversation found',
    description: 'Client passed wrong conversation ID to feedback service.',
  },
  {
    code: [1004],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Client passed wrong trace ID to feedback service.',
  },
  {
    code: [1005],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No conversation found',
    description: 'Client failed to request Sydney service.',
  },

  // Sydney http code
  {
    code: [2200, 2299],
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Succeeded.',
  },
  {
    code: [2400, 2599],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No data found',
    description: 'Feedback service encountered an error when calling Sydney API.',
  },

  // Errors while requesting sydney conversation history API
  {
    code: [2001],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No conversation found',
    description: 'Feedback service encountered an auth error when calling Sydney API.',
  },
  {
    code: [2002],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No conversation found',
    description: 'Feedback service encountered an exception when calling Sydney API.',
  },

  // 2XXX
  {
    code: [2000, 2999],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No data found',
    description: 'Feedback service encountered an error when calling Sydney API.',
  },
  // Conversation expired
  {
    code: [2003],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No data given',
    description:
      'Conversation telemetry data expired because the feedback was submitted more than 24 hours after the conversation.',
  },

  // Errors in  handling conversation history response
  {
    code: [3000],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No conversation found',
    description: 'Sydney returned empty conversation history.',
  },
  {
    code: [3001],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No conversation found',
    description: 'Sydney service returned conversation history in an incorrect format. ',
  },
  {
    code: [3002],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No conversation found',
    description: 'Sydney service returned conversation history in an incorrect format. ',
  },
  {
    code: [3003],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'No conversation found',
    description: `Sydney service didn't find conversation history.`,
  },
  {
    code: [3005],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Sydney service returned a wrong conversation status.',
  },
  {
    code: [3006],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Sydney service returned a wrong conversation status.',
  },
  {
    code: [3007],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No data found',
    description: 'Sydney service returned a wrong conversation status.',
  },
  {
    code: [4000],
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.Sydney,
    message: 'No need to call 3S',
    description: '',
  },
  {
    code: [4002],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.Sydney,
    message: 'Failed to call 3S',
    description: 'Sydney encountered an error when calling 3S API.',
  },

  // Session reply expired
  {
    code: [4003],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No search data found',
    description:
      'Conversation telemetry data expired because the feedback was submitted more than 24 hours after the conversation.',
  },
  {
    code: [5000],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.CopilotClient,
    message: 'No search data found',
    description: '3S cached data has expired.',
  },
  // Errors while requesting 3S
  {
    code: [6200, 6299],
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Succeeded.',
  },
  {
    code: [6400, 6599],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.SubstrateSearch,
    message: 'No search data found',
    description: 'Feedback service encountered an error when calling 3S API.',
  },

  // 6001
  {
    code: [6001],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.SubstrateSearch,
    message: 'No search data found',
    description: 'Feedback service encountered an auth error when calling 3S API.',
  },

  // 6002
  {
    code: [6002],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.SubstrateSearch,
    message: 'No search data found',
    description: 'Feedback service encountered an exception when calling 3S API.',
  },

  // 6003
  {
    code: [6003],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.SubstrateSearch,
    message: 'No search data found',
    description: 'Feedback service encountered an error when calling 3S API.',
  },

  {
    code: ['default'],
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'Failed to collect data',
    description: 'Failed to collect data due to unknown error.',
  },
] as const satisfies TicketErrorCode[]
