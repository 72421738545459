import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { createActions } from './store/createActions'
import { createState } from './store/createState'
import { IActions } from './store/IActions'
import { IState } from './store/IState'

interface IProps {
  readonly tab: string | undefined
  readonly onTabChange: ((tab: string) => void) | undefined
  readonly children: ReactNode
}

export function TabStore({ tab, onTabChange, children }: IProps): ReactNode {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({ activeTab: tab, onActiveTabChange: onTabChange }),
    actions: createActions({ set, get }),
  }))

  // Only update the active tab if the tab prop changes and not empty
  useEffect(() => {
    if (tab) {
      store.setState((state) => {
        state.activeTab = tab
      })
    }
  }, [store, tab])

  useEffect(() => {
    store.setState((state) => {
      state.onActiveTabChange = onTabChange
    })
  }, [store, onTabChange])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()

TabStore.use = Context.use
TabStore.useActions = Context.useActions
