import { PromiseSnapshots } from '@copilot-dash/core'
import { IAISearchRootCausesOptions, IAISearchRootCausesResult } from './AISearchRootCauses.types'
import { AiSearchRootCausesQueryBuilder } from './AISearchRootCausesQueryBuilder'
import { convertRootCausesFromAISearchResponseToDomain } from './converters/AISearchRootCausesConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function AISearchRootCauses(
  context: IDashStoreContext,
  options: IAISearchRootCausesOptions,
): Promise<IAISearchRootCausesResult> {
  const { searchQuery, filterQuery, searchAndHighlightField } = AiSearchRootCausesQueryBuilder.buildQuery(options)

  const response = await context.api.copilotDash.getAISearchRootCauses(
    { query: searchQuery },
    {
      filter: filterQuery,
      skip: options.offset,
      size: options.count,
      facets: options.facets,
      searchFields: searchAndHighlightField,
      highlightFields: searchAndHighlightField,
    },
  )
  const result = convertRootCausesFromAISearchResponseToDomain(response)

  context.use.setState((state) => {
    for (const rc of result.rootCauseList) {
      state.team.v2RootCauseMap ??= {}
      state.team.v2RootCauseMap[rc.issueId] = PromiseSnapshots.done(rc)
    }
  })

  return result
}
