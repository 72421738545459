import { Route } from 'react-router-dom'
import { FeedbackCopilotRoutePage } from './FeedbackCopilotRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { VoidMapper } from '../../utils/VoidMapper'

export function FeedbackCopilotRoute() {
  return <Route id={ID} path={PATH} element={<FeedbackCopilotRoutePage />} />
}

const ID = 'feedbackcopilot'
const PATH = '/feedbackcopilot'

FeedbackCopilotRoute.id = ID
FeedbackCopilotRoute.path = PATH
FeedbackCopilotRoute.navigator = createNavigator(ID, PATH, VoidMapper)
