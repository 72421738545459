import { Api3sOfflineDataGroup2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicket3sOfflineCooked } from '../actions-ticket-assert/assertTicket3sOfflineCooked'
import { assertTicket3sOfflineExists } from '../actions-ticket-assert/assertTicket3sOfflineExists'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getRaw3sOfflineDataGroup2(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<Api3sOfflineDataGroup2> {
  return context.getOrFetch<Api3sOfflineDataGroup2>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.raw3sOfflineDataGroup2
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.raw3sOfflineDataGroup2 = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.substrateSearchOfflineGroup2
      if (sourceItem?.url) {
        return await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asSubstrateSearchOfflineGroup2()
      }

      await assertTicket3sOfflineExists(context, ticketId, messageId)
      await assertTicket3sOfflineCooked(context, ticketId)
      throw TicketError.create('No3sOffline', { ticketId, messageId, sourceItem })
    },
  })
}
