import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoLLMLogItem, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'
import { assertTicketKustoNotExpired } from '../actions-ticket-assert/assertTicketKustoNotExpired'
import { assertTicketTurnExists } from '../actions-ticket-assert/assertTicketTurnExists'
import { getTicketSource } from '../actions-ticket-blob/getTicketSource'

export function getLlmLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoLLMLogItem[]> {
  return context.getOrFetch<IKustoLLMLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.llmLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.llmLogs = snapshot
    },
    fetch: async () => {
      await assertTicketHasUserConsent(context, ticketId)

      const source = await getTicketSource(context, ticketId).promise
      const sourceItem = source.turns[messageId]?.llmLog
      if (sourceItem?.url) {
        const results = await context.api.copilotDash.getTicketBlob(ticketId, sourceItem.url).asLlmLog()
        return results
      }

      await assertTicketTurnExists(context, ticketId, messageId)
      await assertTicketKustoNotExpired(context, ticketId, KustoLogTable.LLMLog)
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.LLMLog, sourceItem })
    },
  })
}
