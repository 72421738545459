import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRaw3sOfflineDataGroup3 } from '../actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup3'

export function get3sOfflineGeneralDiagnosticData(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
  transactionId: string,
): PromiseSnapshot<unknown> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.sssOfflineGeneralDiagnosticData?.[transactionId]
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      const sssGeneralDiagnosticData = (turn.sssOfflineGeneralDiagnosticData ??= {})
      sssGeneralDiagnosticData[transactionId] = snapshot
    },
    fetch: async () => {
      const raw = await getRaw3sOfflineDataGroup3(context, ticketId, messageId).promise
      const result = raw.EnterpriseSearchImpressions?.find((item) => item.ImpressionId === transactionId)
      if (result) {
        return result
      }

      throw TicketError.create('No3sOffline', { ticketId, messageId })
    },
  })
}
