import { AsyncSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { SearchTicketResult } from '@copilot-dash/domain'
import { SearchTicketIdsResult } from './types'

export function mapSearchTicketsSnapshotToSearchTicketIDsSnapshot(
  searchTicketsSnapshot: AsyncSnapshot<SearchTicketResult>,
): AsyncSnapshot<SearchTicketIdsResult> {
  if (searchTicketsSnapshot.status === 'done') {
    const tickets = searchTicketsSnapshot.data?.tickets ?? []
    return PromiseSnapshots.done({
      ticketIds: tickets.map((item) => item.ticketId).filter((item): item is string => !!item) ?? [],
      hasMore: searchTicketsSnapshot.data?.hasMore ?? false,
      ticketCount: searchTicketsSnapshot.data?.ticketCount ?? 0,
    })
  } else if (searchTicketsSnapshot.status === 'error') {
    return PromiseSnapshots.error(searchTicketsSnapshot.error)
  } else if (searchTicketsSnapshot.status === 'waiting') {
    return PromiseSnapshots.waiting(new Promise(() => {}))
  }
  return PromiseSnapshots.waiting(new Promise(() => {}))
}

export function batchUpdateAppStoreTickets(tickets: SearchTicketResult['tickets']): void {
  app.store.actions.batchUpdateTickets(tickets)
}
