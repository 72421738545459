import { getFilterDisplayName } from '@copilot-dash/store'
import { memo } from 'react'
import { CopilotDashSearchBox } from '../../../Filter/CopilotDashSearchBox'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const ClientFlightFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashSearchBox
      value={value}
      onChangeValue={onChangeValue}
      title={getFilterDisplayName('clientFlights') + ':'}
      placeholder="Search"
      info="Search ECS flights"
    />
  )
})

ClientFlightFilter.displayName = 'ClientFlightFilter'
