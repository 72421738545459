import { AsyncLoader, AsyncSnapshots, PromiseSnapshots } from '@copilot-dash/core'
import {
  AreaPath,
  AreaPathListWithRootCauseList,
  IRootCauseData,
  ITeamList,
  IUpdateRootCause,
  IUpdateTicketStatusInfoData,
  IUpdateTicketStatusResponse,
  TeamId,
} from '@copilot-dash/domain'
import { WritableDraft } from 'immer'
import { IGlobalStoreActions } from './IGlobalStoreActions'
import { IGlobalStoreState } from './IGlobalStoreState'

export function createGlobalStoreActions(
  set: (callback: (state: WritableDraft<IGlobalStoreState>) => void) => void,
  get: () => IGlobalStoreState,
): IGlobalStoreActions {
  const asyncLoader = new AsyncLoader<AreaPathListWithRootCauseList>()
  const fetchAreaListAndRootCauseList = async (teamList?: ITeamList): Promise<void> => {
    if (teamList) {
      // teamList is used for V2, if teamIds is Nil, it will use V1
      if (teamList.every((team) => app.store.state.team.v2RootCauseIdsByTeam[team.id]?.status === 'done')) {
        set((state) => {
          state.areaListAndRootCauseList = AsyncSnapshots.done(
            teamList.map((team) => {
              const rootCauseIdList = app.store.state.team.v2RootCauseIdsByTeam[team.id]?.data ?? []
              return {
                teamId: team.id,
                areaPath: team.name,
                teamDisplayName: team.name,
                rootCauseList: rootCauseIdList
                  .map((rootCauseId) => {
                    const rootCauseData = app.store.state.team.v2RootCauseMap[rootCauseId]?.data
                    return rootCauseData
                  })
                  .filter((rc): rc is IRootCauseData => rc !== undefined),
              }
            }),
          )
        })
        return
      }

      const promise: Promise<AreaPathListWithRootCauseList> = app.store.actions
        .aiSearchRootCauses({
          teamIds: teamList.map((team) => team.id),
          offset: 0,
          count: 10000,
        })
        .then((response) => {
          const data = response
          const teamIdList = Array.from(new Set(data.rootCauseList.map((rootCause) => rootCause.teamId)))
          const groupedRootCausesByTeamId = teamIdList.map((teamId) => {
            const teamInfo = teamList.find((team) => team.id === teamId)!
            return {
              teamId,
              areaPath: teamInfo.name,
              teamDisplayName: teamInfo?.name,
              rootCauseList: data.rootCauseList.filter((rootCause) => rootCause.teamId === teamId),
            }
          })

          app.store.use.setState((state) => {
            for (const teamId of teamIdList) {
              state.team.v2RootCauseIdsByTeam[teamId] = PromiseSnapshots.done(
                groupedRootCausesByTeamId
                  .find((team) => team.teamId === teamId)
                  ?.rootCauseList.map((rc) => rc.issueId) ?? [],
              )
            }
          })

          return groupedRootCausesByTeamId
        })
      asyncLoader.submit({
        promise,
        onChanged: (snapshot) => {
          set((state) => {
            state.areaListAndRootCauseList = snapshot
          })
        },
      })
    } else {
      const promise = application.store.actions.getTeamAreasAndRootCauses()
      asyncLoader.submit({
        promise,
        onChanged: (snapshot) => {
          set((state) => {
            state.areaListAndRootCauseList = snapshot
          })
        },
      })
    }
  }

  const getAreaPathList = (): AreaPath[] => {
    return (get().areaListAndRootCauseList.data ?? []).map((areaPathAndRootCause) => areaPathAndRootCause.areaPath)
  }

  const getRootCauseListByAreaPath = (areaPath: AreaPath) => {
    const areaPathAndRootCause = (get().areaListAndRootCauseList.data ?? []).find(
      (areaPathAndRootCause) => areaPathAndRootCause.areaPath === areaPath,
    )
    return areaPathAndRootCause?.rootCauseList || []
  }
  const getRootCauseListByTeamId = (teamId: TeamId) => {
    const areaPathAndRootCause = (get().areaListAndRootCauseList.data ?? []).find(
      (areaPathAndRootCause) => areaPathAndRootCause.teamId === teamId,
    )
    return areaPathAndRootCause?.rootCauseList || []
  }

  const postTicketDetails = async (
    ticketDetails: IUpdateTicketStatusInfoData,
  ): Promise<IUpdateTicketStatusResponse> => {
    return application.store.actions.updateTicketStatusInfo(ticketDetails)
  }

  const addRootCauseListByAreaPath = (areaPath: AreaPath, rootCause: IRootCauseData) => {
    const currentRootCauseList = getRootCauseListByAreaPath(areaPath)

    const existedRootCauseIndex = currentRootCauseList.findIndex((rc) => rc.issueId === rootCause.issueId)
    if (existedRootCauseIndex > -1) {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === areaPath) {
            areaPathAndRootCause.rootCauseList[existedRootCauseIndex] = rootCause
          }
        })
      })
    } else {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === areaPath) {
            areaPathAndRootCause.rootCauseList.push(rootCause)
          }
        })
      })
    }
  }

  const removeRootCauseListByAreaPath = (areaPath: AreaPath, rootCauseId: string) => {
    const currentRootCauseList = getRootCauseListByAreaPath(areaPath)
    const existedRootCauseIndex = currentRootCauseList.findIndex((rc) => rc.issueId === rootCauseId)

    if (existedRootCauseIndex > -1) {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.areaPath === areaPath) {
            areaPathAndRootCause.rootCauseList.splice(existedRootCauseIndex, 1)
          }
        })
      })
    }
  }

  const updateRootCause = (rootCause: IUpdateRootCause) => {
    const existedRootCauseIndex = getRootCauseListByTeamId(rootCause.teamId ?? '').findIndex(
      (rc) => rc.issueId === rootCause.issueId,
    )
    if (existedRootCauseIndex > -1) {
      set((state) => {
        state.areaListAndRootCauseList.data?.forEach((areaPathAndRootCause) => {
          if (areaPathAndRootCause.teamId === rootCause.teamId) {
            areaPathAndRootCause.rootCauseList.forEach((rc) => {
              if (rc.issueId === rootCause.issueId) {
                rc.rootCauseTitle = rootCause.rootCauseTitle
              }
            })
          }
        })
      })
    }
  }

  const clearAreaListAndRootCauseList = () => {
    set((state) => {
      state.areaListAndRootCauseList = { status: 'none' }
    })
  }

  return {
    fetchAreaListAndRootCauseList,
    getAreaPathList,
    getRootCauseListByAreaPath,
    postTicketDetails,
    addRootCauseListByAreaPath,
    removeRootCauseListByAreaPath,
    updateRootCause,
    clearAreaListAndRootCauseList,
  }
}
