import { ITicketTurnSource } from '@copilot-dash/domain'
import { ToolbarButton } from '@fluentui/react-components'
import { DatabaseLinkRegular } from '@fluentui/react-icons'
import { RouteLink } from '../../../../router'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
  readonly source: keyof ITicketTurnSource
}

export function TicketSourceKustoOpener({ ticketId, messageId, source }: IProps) {
  const snapshot = app.store.use.getTicketSource(ticketId)
  if (snapshot.status !== 'done') {
    return null
  }

  const item = snapshot.data.turns[messageId]?.[source]
  if (!item || !item.query) {
    return null
  }

  return (
    <RouteLink type="button" path={item.query.url} openInNewTab>
      <ToolbarButton title="Execute Query in Azure Data Explorer" icon={<DatabaseLinkRegular />} onClick={() => {}} />
    </RouteLink>
  )
}
