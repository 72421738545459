import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { AgGridReact } from 'ag-grid-react'
import { NameView } from './columns/name/NameView'
import { NameColumn } from './columns/NameColumn'
import { ValueView } from './columns/value/ValueView'
import { ValueColumn } from './columns/ValueColumn'
import { useThemeMode } from '../../theme/ThemeModeProvider'
import { AnyPropertyItem } from '../AnyPropertyType'

const THEME_CLASS_LIGHT = 'ag-theme-quartz'
const THEME_CLASS_DARK = 'ag-theme-quartz-dark'

const COMPONENTS: Record<string, unknown> = {
  nameRenderer: NameView,
  valueRenderer: ValueView,
}

interface IProps {
  readonly items: AnyPropertyItem[]
}

export function Root({ items }: IProps) {
  const styles = useStyles()
  const themeMode = useThemeMode()
  const themeClass = themeMode === 'dark' ? THEME_CLASS_DARK : THEME_CLASS_LIGHT

  return (
    <AgGridReact
      className={mergeClasses(themeClass, styles.root)}
      rowData={items}
      columnDefs={[NameColumn, ValueColumn]}
      components={COMPONENTS}
      rowSelection={{
        mode: 'multiRow',
        headerCheckbox: false,
        checkboxes: false,
        enableClickSelection: false,
      }}
      headerHeight={0}
      readOnlyEdit={false}
      cacheQuickFilter={true}
    />
  )
}

const useStyles = makeStyles({
  root: {
    '& .ag-root-wrapper': {
      borderRadius: '0',
      border: 'none',
    },

    '& .ag-row-hover': {
      backgroundColor: 'transparent',
    },
  },
})
