import { Column, Spacer } from '@copilot-dash/components'
import { Text } from '@fluentui/react-components'
import { memo } from 'react'
import { useStyles } from './Legend.style'

interface LegendItem {
  index: number
  datasetIndex: number
  strokeStyle: string
  hidden: boolean
  text: string
  fillStyle: string
}
interface LegendProps {
  items: LegendItem[]
  handleClickItem: (item: LegendItem) => void
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const Legend = memo((props: LegendProps) => {
  const items = props.items
  const styles = useStyles()
  return (
    <ul className={styles.ul}>
      {items.map((item: LegendItem) => (
        <li key={item.index} className={styles.li} onClick={() => props.handleClickItem(item)}>
          <Column
            style={{
              backgroundColor: item.fillStyle,
            }}
            className={styles.legendBox}
          ></Column>
          <Spacer width={4} />
          <Text
            style={{
              textDecoration: item.hidden ? 'line-through' : 'none',
            }}
            className={styles.legendLabel}
          >
            {item.text}
          </Text>
        </li>
      ))}
    </ul>
  )
})
Legend.displayName = 'Legend'
