import { makeStyles, tokens } from '@fluentui/react-components'
import { MonacoViewInline } from '@copilot-dash/components'

interface IProps {
  readonly value: Exclude<unknown, null>
}

export function ValueViewForUnknown({ value }: IProps) {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <MonacoViewInline className={styles.content} data={value} language="json" maxHeight={300} />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  content: {
    padding: '0px',
    backgroundColor: tokens.colorNeutralBackground1,
    border: `1px solid ${tokens.colorNeutralStroke1}`,
  },
})
