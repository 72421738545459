import { Row } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewCardErrorMessage({ components }: IProps) {
  const styles = useStyles()

  return <Row className={styles.message}>{components.message}</Row>
}

const useStyles = makeStyles({
  message: {
    color: tokens.colorNeutralForeground3,
    textAlign: 'center',
  },
})
