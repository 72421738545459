import { Column } from '@copilot-dash/components'
import { makeStyles, Popover, PopoverProps, PopoverSurface, PopoverTrigger, tokens } from '@fluentui/react-components'
import { ReactElement, useCallback, useRef, useState } from 'react'
import { ErrorViewPopoverBody } from './ErrorViewPopoverBody'
import { ErrorViewPopoverHeader } from './ErrorViewPopoverHeader'
import { InlineDivider } from '../../Divider/InlineDivider'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
  readonly trigger?: 'click' | 'hover'
  readonly children: ReactElement
}

export function ErrorViewPopover({ components, trigger = 'click', children }: IProps) {
  const styles = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const openTimerRef = useRef<number | null>(null)

  const handleClose = useCallback(() => {
    setOpen(false)
    openTimerRef.current && clearTimeout(openTimerRef.current)
    openTimerRef.current = null
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
    openTimerRef.current && clearTimeout(openTimerRef.current)
    openTimerRef.current = null
  }, [])

  const handleOpenWithDelay = useCallback(() => {
    openTimerRef.current = window.setTimeout(handleOpen, 500)
  }, [handleOpen])

  const handleOpenChange: PopoverProps['onOpenChange'] = (_, data) => {
    if (data.open) {
      if (trigger === 'click') {
        handleOpen()
      } else {
        handleOpenWithDelay()
      }
    } else {
      handleClose()
    }
  }

  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      withArrow
      openOnHover={trigger === 'hover'}
      unstable_disableAutoFocus={true}
    >
      <PopoverTrigger disableButtonEnhancement>{children}</PopoverTrigger>
      <PopoverSurface className={styles.surface} onClick={(e) => e.stopPropagation()}>
        <Column className={styles.content}>
          <ErrorViewPopoverHeader components={components} onClose={handleClose} />
          <InlineDivider space={1} />
          <ErrorViewPopoverBody components={components} onClose={handleClose} />
        </Column>
      </PopoverSurface>
    </Popover>
  )
}

const useStyles = makeStyles({
  surface: {
    padding: '0',
    margin: '0',
  },
  content: {
    minWidth: '300px',
    maxWidth: '480px',
    maxHeight: '480px',
    width: '400px',
    backgroundColor: tokens.colorNeutralBackground1,
  },
})
