import { Row } from '@copilot-dash/components'
import { Main } from './main/Main'
import { Outline } from './outline/Outline'
import { TicketAsyncView } from '../../ticket/children/common/TicketAsyncView'
import { TicketDiagnosticStore } from '../TicketDiagnosticStore'

export function Root() {
  const ticketId = TicketDiagnosticStore.use((state) => state.ticketId)
  const snapshot = app.store.use.getTicketDiagnosticData(ticketId)

  return (
    <TicketAsyncView snapshot={snapshot} loadingMessage="Loading ticket diagnostic data...">
      {(data) => {
        return (
          <Row vAlign="stretch" hAlign="stretch" style={{ flex: 1, height: '100%' }}>
            <Main />
            <Outline data={data} />
          </Row>
        )
      }}
    </TicketAsyncView>
  )
}
