import { Column, Row, Spacer } from '@copilot-dash/components'
import { ColumnKey, INewTicketData, SearchTextPrefixType, columnKeySchema } from '@copilot-dash/domain'
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Spinner,
  Text,
  mergeClasses,
} from '@fluentui/react-components'
import { LinkDismissRegular } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { compact, isNil } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { z } from 'zod'
import { CustomSearchTickets } from './CustomSearchTickets'
import { GroupedTicketsByState } from './GroupedTicketsByState'
import { GroupedTicketsByTeam } from './GroupedTicketsByTeam'
import { useStyles } from './IssueTickets.styles'
import { AGGridTable } from '../../../../../../../components/AGGridTable/AGGridTable'
import { ColumnSettingButton } from '../../../../../../../components/Button/ColumnSettingButton'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { SearchRoute } from '../../../../../../../router'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'

import { LoaderComponent } from '../../../../loader/LoaderComponent'

export interface IssueTicketsProps {
  onTicketsLoaded?: (tickets: INewTicketData[]) => void
  expectedTicketCount?: number
}

enum TableType {
  'All',
  'DSATsStatus',
  'AssignedTeam',
}

export const IssueTickets = memo(function IssueTickets({
  onTicketsLoaded,
  expectedTicketCount = 500,
}: IssueTicketsProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const isColumnSettingPanelOpen = useSearchScreenState((state) => state.isColumnSettingPanelOpen)
  const drillDownScenario = useSearchScreenState((state) => state.feedbackInsights.issueDetail.drillDownScenario)
  const drillDownQuery = useSearchScreenState((state) => state.feedbackInsights.issueDetail.drillDownParams)

  const [activeTable, setActiveTable] = useState<TableType>(TableType.All)
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState<number | undefined>()
  const [hasMore, setHasMore] = useState(true)
  const [onlyShowVIPDSATs, setOnlyShowVIPDSATs] = useState<CheckboxProps['checked']>(false)
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const args = SearchRoute.navigator.useArgsOptional()
  const issueId = args?.issueId
  const batchId = args?.issueBatchId

  const columns = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const setColumns = app.settings.feedbackInsightsTopIssueDetailsColumns.set

  const handleColumnsChange = useCallback(
    (columns: Array<string>) => {
      const movedColumns = columns
        .map((column) => columnKeySchema.safeParse(column))
        .filter((result) => result.success)
        .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
      setColumns(movedColumns)
    },
    [setColumns],
  )

  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    const defaultColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      columns.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )
    return newSelectedColumns
  }, [columns])

  useEffect(() => {
    actions.updateColumnSettingPanelVisibility(false)
  }, [actions])

  const loadTickets = useCallback(
    (offset = 0) => {
      if (issueId && batchId && expectedTicketCount > 0) {
        offset === 0 && setLoading(true)
        const query = SearchRoute.navigator.getArgsOptional()
        app.store.actions
          .searchTickets(
            Object.assign(
              {
                product: query?.product || 'M365Chat',
                defaultRange: undefined,
                clusteringIssueId: issueId,
                batchId: batchId,
                count: expectedTicketCount,
                offset: offset,
                isAIF: false,
                customerTypes: onlyShowVIPDSATs ? ['VIP Power User'] : undefined,
              },
              {},
            ),
          )
          .then((resp) => {
            setHasMore(resp.hasMore)
            setCount(resp.ticketCount)

            setTableItems((pre) => {
              const data = offset > 0 ? [...pre, ...resp.tickets] : resp.tickets
              if (!onlyShowVIPDSATs) {
                onTicketsLoaded?.(data)
              }
              return data
            })
          })
          .finally(() => {
            offset === 0 && setLoading(false)
          })
      }
    },
    [batchId, expectedTicketCount, issueId, onTicketsLoaded, onlyShowVIPDSATs],
  )

  useEffect(() => {
    if (issueId && batchId) {
      loadTickets()
    }
  }, [batchId, issueId, loadTickets, onlyShowVIPDSATs])

  const doubleConfirmRemoveTicketRef = useRef<INewTicketData | undefined>()
  const [removing, setRemoving] = useState(false)
  const handleRemoveTicket = useCallback(
    (feedbackId: string) => {
      if (batchId && issueId && feedbackId) {
        setRemoving(true)
        app.store.actions
          .removeTicketFromTopIssue(batchId, issueId, feedbackId)
          .then(() => {
            setTableItems((pre) => pre.filter((item) => item.ticketId !== feedbackId))
          })
          .finally(() => {
            setRemoving(false)
            setDialogOpen(false)
          })
      }
    },
    [batchId, issueId],
  )

  const table = useMemo(() => {
    if (tableItems.length === 0) {
      return (
        <Column fill hAlign="center">
          No tickets found
        </Column>
      )
    }
    const rowHoverStickySlot = (rowData: INewTicketData) => (
      <DialogTrigger disableButtonEnhancement>
        <Button
          onClick={() => {
            doubleConfirmRemoveTicketRef.current = rowData
          }}
          icon={<LinkDismissRegular />}
          style={{ width: 'max-content' }}
          appearance="transparent"
        >
          Remove from Issue
        </Button>
      </DialogTrigger>
    )
    switch (activeTable) {
      case TableType.All:
        return (
          <div style={{ width: '100%', minHeight: '400px' }}>
            <AGGridTable
              rowData={tableItems}
              getRowId={(data) => data.data.ticketId}
              columnDefs={colDefs}
              className={styles.agGridTable}
              onRowClicked={actions.rowClick}
              focusedRowId={ticketId}
              /**
               * NOTE: @Ethan temporarily disable the row hover sticky slot until the TopIssues API upgrade to V2
               */
              stickySuffixRenderer={undefined}
              suppressRowVirtualisation={tableItems.length < 50}
              handleColumnsChange={handleColumnsChange}
              rowSelectionMode="multiRow"
              onSelectionChange={(selectedItems: INewTicketData[]) => {
                actions.setSelectedTickets(selectedItems)
                actions.updateBatchOperationPanelVisibility(selectedItems.length > 0)
              }}
            />
          </div>
        )
      case TableType.DSATsStatus:
        return (
          <GroupedTicketsByState
            tickets={tableItems}
            hasMore={hasMore}
            loadMore={loadTickets}
            rowHoverStickySlot={rowHoverStickySlot}
          />
        )
      case TableType.AssignedTeam:
        return (
          <GroupedTicketsByTeam
            tickets={tableItems}
            hasMore={hasMore}
            loadMore={loadTickets}
            rowHoverStickySlot={rowHoverStickySlot}
          />
        )
    }
  }, [
    tableItems,
    activeTable,
    colDefs,
    styles.agGridTable,
    ticketId,
    handleColumnsChange,
    hasMore,
    loadTickets,
    actions,
  ])

  useEffect(() => {
    return () => {
      actions.updateBatchOperationPanelVisibility(false)
      actions.setSelectedTickets([])
    }
  }, [activeTable, onlyShowVIPDSATs, actions])

  if (drillDownScenario && drillDownQuery) {
    return (
      <CustomSearchTickets
        expectedTicketCount={expectedTicketCount}
        scenario={drillDownScenario}
        searchParams={drillDownQuery}
      />
    )
  }

  return (
    <Column style={{ flexGrow: 1 }}>
      <Row>
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.All ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.All)}
        >
          All DSATs {isNil(count) ? null : `(${count})`}
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.DSATsStatus ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.DSATsStatus)}
        >
          DSAT State
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.AssignedTeam ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.AssignedTeam)}
        >
          Assigned Team
        </Button>
        <Spacer />
        <Checkbox
          label="Show VIP DSATs Only"
          checked={onlyShowVIPDSATs}
          onChange={(_, data) => {
            setOnlyShowVIPDSATs(data.checked)
            setTableItems([])
          }}
        />
        <ColumnSettingButton
          placeholder="Columns"
          isColumnSettingPanelOpen={isColumnSettingPanelOpen}
          onClickButton={actions.handleColumnSettingButtonClick}
        />
      </Row>

      <Spacer height={24} />
      <Row style={{ flexGrow: 1 }}>
        <Dialog
          modalType="alert"
          open={dialogOpen}
          onOpenChange={(_, data) => {
            setDialogOpen(data.open)
            if (!data.open) {
              doubleConfirmRemoveTicketRef.current = undefined
            }
          }}
        >
          {loading ? (
            <Column vAlign="center" fill>
              <Spacer height={50} />
              <LoaderComponent />
            </Column>
          ) : (
            table
          )}
          <DialogSurface>
            <DialogBody>
              <DialogContent>
                <Text size={400} weight="semibold">
                  Are you sure to remove the DSAT from the current issue clustering?
                </Text>
              </DialogContent>
              <DialogActions>
                <Button
                  appearance="primary"
                  disabled={removing}
                  icon={removing ? <Spinner size="extra-tiny" /> : null}
                  onClick={() => {
                    doubleConfirmRemoveTicketRef.current &&
                      handleRemoveTicket(doubleConfirmRemoveTicketRef.current.ticketId)
                  }}
                >
                  Remove
                </Button>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Cancel</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </Row>
    </Column>
  )
})
