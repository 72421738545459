import { MonacoViewInline, Row } from '@copilot-dash/components'
import { ITicketTurnTransactionHealthData } from '@copilot-dash/domain'
import { TableCell, TableRow, Text } from '@fluentui/react-components'

interface IProps {
  readonly data: ITicketTurnTransactionHealthData
}

export function RowForRetrievalGwsLog({ data }: IProps) {
  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval</Text>
        <br />
        Source: GWS log
      </TableCell>
      <TableCell>
        {data.gwsLog && (
          <Row>
            <MonacoViewInline data={data.gwsLog} language="json" />
          </Row>
        )}
      </TableCell>
    </TableRow>
  )
}
