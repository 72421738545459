import { AsyncLoader, AsyncSnapshot } from '@copilot-dash/core'
import { ISearchTicketOptions, SearchTicketFacet, SearchTicketResult } from '@copilot-dash/domain'
import { stableStringify } from './const'

export function createTicketsSearcher() {
  const asyncLoader = new AsyncLoader<SearchTicketResult>()
  const fetchingPool = new Map<string, Promise<SearchTicketResult>>()
  const _lastFetchingKeyRef: { current: string } = { current: '' }
  const searchTicket = async (
    request: ISearchTicketOptions,
    onChange: (snapshot: AsyncSnapshot<SearchTicketResult>, request?: ISearchTicketOptions) => void,
    facets?: Array<SearchTicketFacet>,
  ) => {
    const key = stableStringify(request)
    _lastFetchingKeyRef.current = key
    const promise = fetchingPool.get(key) || app.store.actions.searchTickets(request, facets)
    fetchingPool.set(key, promise)

    asyncLoader.submit({
      promise,
      onChanged: (snapshot) => {
        if (key !== _lastFetchingKeyRef.current) {
          return
        }
        onChange(snapshot, request)
      },
      onFinished: () => {
        fetchingPool.delete(key)
      },
    })

    return promise
  }

  return searchTicket
}
