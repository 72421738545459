import { Column, Spacer } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewPopoverBodyMessage({ components }: IProps) {
  const styles = useStyles()

  return (
    <Column>
      {components.message}
      {components.messageDetails && <Spacer size={8} />}
      {components.messageDetails && <span className={styles.messageDetail}>{components.messageDetails}</span>}
    </Column>
  )
}

const useStyles = makeStyles({
  messageDetail: {
    color: tokens.colorNeutralForeground4,
    fontSize: '0.9em',
  },
})
