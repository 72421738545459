import { IRootCauseData } from '@copilot-dash/domain'
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Text,
  tokens,
} from '@fluentui/react-components'
import { memo, useCallback, useState } from 'react'
import { useToast } from '../../../../../hooks/useToast'
import { useGlobalStore } from '../../../../../store'
import { useTeamViewStore } from '../../../store'

export const Remove = memo((props: { rowData: IRootCauseData }) => {
  const toast = useToast()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { issueId } = props.rowData

  const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeamName)

  const handleRemove = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      try {
        const response = await application.store.actions.deleteRootCause(issueId)
        if (response) {
          setDialogOpen(false)
          toast.showSuccess('Removed successfully')

          teamName && useGlobalStore.getState().removeRootCauseListByAreaPath(teamName, issueId)
        } else {
          throw new Error('Server failed to remove the root cause')
        }
      } catch (error) {
        Logger.telemetry.trackEvent('RootCauses/DeleteRootCause/Fail', {
          rootCauseId: issueId,
          teamName,
        })
        toast.showError(
          'Failed to remove',
          typeof error === 'string' ? error : error instanceof Error ? error.message : '',
        )
      }
    },
    [issueId, teamName, toast],
  )

  return (
    <Dialog modalType="alert" open={dialogOpen}>
      <DialogTrigger disableButtonEnhancement>
        <Text
          style={{
            color: tokens.colorBrandForeground1,
            fontWeight: 600,
            width: '120px',
            height: '44px',
            lineHeight: '44px',
          }}
          onClick={(e) => {
            setDialogOpen(true), e.stopPropagation(), e.preventDefault()
          }}
        >
          Remove
        </Text>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Text size={400} weight="semibold">
              Are you sure to remove the root cause from the current team?
            </Text>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={(e) => handleRemove(e)}>
              Remove
            </Button>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={(e) => {
                  setDialogOpen(false), e.stopPropagation(), e.preventDefault()
                }}
              >
                Cancel
              </Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
})

Remove.displayName = 'Remove'
