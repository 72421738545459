import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const messageId = params.messageId
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
union immersive_bizchat, immersive_bizchat_web
| where TraceId in ('${messageId}')
| where EventInfo_Time ${timeRange}
| where Event in  ('CitationNotShownMissingReference','EntitySpecificErrors','QuerySubmit')
| extend metadata = parse_json(Metadata)
| extend ExperienceType = tostring(metadata['promptMetadata']['experienceType'])
| distinct TraceId,Event,ErrorMessage,ExperienceType
`
}

export const MetaOSClientEvent_WW: IKustoQueryDefinition = {
  database: 'BizChat Prod',
  connection: 'https://bizchat.eastus2.kusto.windows.net',
  query,
}
