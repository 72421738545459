import { AutomationSettingEndpoint } from '@copilot-dash/domain'
import { Select } from '@fluentui/react-components'
import { ComponentProps, useId } from 'react'
import { z } from 'zod'

export function ATEndpointTile() {
  const labelId = useId()
  const value = app.settings.atendpointUrl.use()
  const disabled = application.env.ring === 'msit'

  const onChange: ComponentProps<typeof Select>['onChange'] = (_, data) => {
    const endpoint = z.nativeEnum(AutomationSettingEndpoint).safeParse(data.value).data
    if (endpoint) {
      app.settings.atendpointUrl.set(endpoint)
    } else {
      app.settings.atendpointUrl.set(null)
    }
  }

  return (
    <>
      <br />
      <label htmlFor={labelId}>Automation Server endpoint</label>
      <Select id={labelId} value={value ?? 'default'} onChange={onChange} disabled={disabled}>
        <option value="default">Default</option>
        {Object.values(AutomationSettingEndpoint).map((endpoint) => (
          <option key={endpoint} value={endpoint}>
            {endpoint}
          </option>
        ))}
      </Select>
    </>
  )
}
