import { AnyExplainerResult } from '@copilot-dash/components'
import { DefaultProductChannels, DefaultProducts } from '@copilot-dash/domain'

export function explainTicketScenario(keyword: string): AnyExplainerResult | undefined {
  const channel = DefaultProductChannels.find((item) => item.name.toLowerCase() === keyword.toLowerCase())
  if (!channel) {
    return undefined
  }

  const product = DefaultProducts.find((product) => product.productId === channel.productId)
  if (!product) {
    return undefined
  }

  let content = ''
  content += `**${product.title}**\n`
  content += '```json\n' + JSON.stringify(channel, null, 2) + '\n```'

  return {
    markdown: content,
  }
}
