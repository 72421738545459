import { Overlays } from '@copilot-dash/components'
import { Button, Tooltip } from '@fluentui/react-components'
import { SettingsRegular } from '@fluentui/react-icons'
import { SettingsScreen } from '../../../settings/SettingsScreen'

export function HeaderSettingsButton() {
  const isEnabled = app.features.settingsEntry.use()
  if (!isEnabled) {
    return null
  }

  const handleClick = () => {
    Overlays.openAsFullDialog({
      header: false,
      transition: true,
      content: <SettingsScreen />,
    })
  }

  return (
    <Tooltip content="Settings" relationship="label">
      <Button appearance="subtle" icon={<SettingsRegular />} onClick={handleClick} />
    </Tooltip>
  )
}
