import { PromiseSnapshot } from '@copilot-dash/core'
import { IActivityHistory } from '@copilot-dash/domain'
import { isEmpty } from 'lodash'
import { ActivityHistoryV2Converter } from './ActivityHistoryV2Converter'
import { IDashStoreContext } from '../../IDashStoreContext'

export function getTicketActivityHistoryV2(
  context: IDashStoreContext,
  ticketId: string,
  forceRequest?: boolean,
): PromiseSnapshot<IActivityHistory[]> {
  return context.getOrFetch({
    get: (state) => {
      return forceRequest ? undefined : state.tickets[ticketId]?.activityHistory
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      ticket.activityHistory = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getActivityHistoryV2(ticketId)

      return ActivityHistoryV2Converter.getActivityHistoriesDiff(response.activityHistories)
        .filter(shouldIncludeActivity)
        .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
    },
  })
}

function shouldIncludeActivity(activity: IActivityHistory): boolean {
  if (
    activity.activity === 'FeedbackCreated' ||
    activity.activity === 'FeedbackCooked' ||
    activity.activity === 'RootCauseDeleted'
  )
    return true
  if (activity.activity === 'Unset') return false
  if (isEmpty(activity.fieldDiffs)) return false

  // Check if all fields in ActivityFieldDiff are undefined
  const allFieldsUndefined = Object.values(activity.fieldDiffs!).every(
    (field) => field?.newValue === undefined && field?.oldValue === undefined,
  )

  return !allFieldsUndefined
}
