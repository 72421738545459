import { ApiError } from '@copilot-dash/api'
import { PromiseSnapshots } from '@copilot-dash/core'
import { IFCConversationMessageData, IFCNewQuery } from '@copilot-dash/domain'

import { IDashStoreContext } from '../../IDashStoreContext'

export async function submitFCRagQuery(
  context: IDashStoreContext,
  newQuery: IFCNewQuery,
): Promise<IFCConversationMessageData> {
  return context.requestAnd({
    request: async () => {
      const response = await context.api.automation.submitRagQuery(newQuery)
      return { ...response, queryResultIsRead: response.queryResultIsRead ?? false }
    },
    onSuccess: (state, data) => {
      // update queriesMap
      const currentDataSnapshot = state.feedbackCopilot.allFCConversations

      // find the record in allFCConversations by messageid ,and update the data
      const newConv: IFCConversationMessageData = {
        ...data,
        clientStartTimeUTC: newQuery.clientStartTimeUTC,
        query: newQuery.userQuery,
        queryResultIsRead: false,
      }
      state.feedbackCopilot.lastFCConversation = PromiseSnapshots.done(newConv)
      if (currentDataSnapshot?.data) {
        //remove data with same messageid and push new data
        const existsData = currentDataSnapshot.data.find((item) => item.messageId === newConv.messageId)
        if (existsData) {
          existsData.queryResultIsRead = true
          existsData.queryResult = newConv.queryResult
          existsData.queryStatus = newConv.queryStatus
          existsData.botDefaultResponse = newConv.botDefaultResponse
          existsData.botResponseTimeUTC = newConv.botResponseTimeUTC
        }
      }
    },
    onError: (state, error) => {
      // update queriesMap
      const currentDataSnapshot = state.feedbackCopilot.allFCConversations
      let errorMsg = 'Something went wrong, please try again!'
      if (error instanceof ApiError) {
        errorMsg = error.message
      }
      const newConv: IFCConversationMessageData = {
        ...newQuery,
        botDefaultResponse: errorMsg,
        botResponseTimeUTC: new Date().toISOString(),
        queryStatus: 'QueryFailed',
      }
      state.feedbackCopilot.lastFCConversation = PromiseSnapshots.done(newConv)
      if (currentDataSnapshot?.data) {
        const existsData = currentDataSnapshot.data.find((item) => item.messageId === newConv.messageId)
        if (existsData) {
          existsData.queryResultIsRead = true
          existsData.queryResult = newConv.queryResult
          existsData.queryStatus = newConv.queryStatus
          existsData.botDefaultResponse = newConv.botDefaultResponse
          existsData.botResponseTimeUTC = newConv.botResponseTimeUTC
        }
      }
    },
  })
}
