import { Row } from '@copilot-dash/components'
import { Button } from '@fluentui/react-components'
import { ChatMultipleRegular, HistoryRegular, InfoRegular } from '@fluentui/react-icons'
import { useTeamViewStore } from '../../store'
import { RootCauseScreenDrawerType } from '../../store/types'

interface IProps {
  readonly type: RootCauseScreenDrawerType
}

export function RootCauseScreenBarDrawerTrigger({ type }: IProps) {
  const handleOnClick = () => {
    useTeamViewStore.getState().rootCauses.openDrawer(type)
  }

  switch (type) {
    case RootCauseScreenDrawerType.RootCauseDetail:
      return (
        <Button
          size="medium"
          title="RootCause Detail"
          appearance="transparent"
          icon={<InfoRegular />}
          onClick={handleOnClick}
        >
          Details
        </Button>
      )
    case RootCauseScreenDrawerType.DiscussionPanel:
      return (
        <DiscussionPanelCounter>
          <Button
            size="medium"
            title="Discussion"
            appearance="transparent"
            icon={<ChatMultipleRegular />}
            onClick={handleOnClick}
          >
            Discussion
          </Button>
        </DiscussionPanelCounter>
      )
    case RootCauseScreenDrawerType.ActivityHistory:
      return (
        <Button
          size="medium"
          title="History"
          appearance="transparent"
          icon={<HistoryRegular />}
          onClick={handleOnClick}
        >
          History
        </Button>
      )
  }
}

function DiscussionPanelCounter({ children }: { children: React.ReactNode }) {
  return (
    <Row style={{ position: 'relative' }}>
      {children}
      <DiscussionPanelCounterIndicator />
    </Row>
  )
}

function DiscussionPanelCounterIndicator() {
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
  const snapshot = app.store.use.getCommentCount(selectedRootCauseId!)

  switch (snapshot.status) {
    case 'waiting':
    case 'error':
      return null
    case 'done': {
      if (snapshot.data === 0) {
        return null
      }

      return (
        <span
          style={{
            position: 'absolute',
            top: '6px',
            right: '4px',
            width: '10px',
            height: '10px',
            backgroundColor: 'blue',
            borderRadius: '50%',
            border: '2px solid white',
          }}
        />
      )
    }
  }
}
