import { Column } from '@copilot-dash/components'
import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'
import { Label } from '@fluentui/react-components'
import { memo, useMemo } from 'react'

import { CopilotDashCheckBox } from '../../../Filter/CopilotDashCheckBox'

interface IProps {
  value: boolean | undefined | null
  onChangeValue: (value: boolean | undefined | null) => void
}

export const HasAssigneeFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('hasAssignee')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('hasAssignee')

  return (
    <Column>
      <Label weight="semibold">{displayName}:</Label>
      <CopilotDashCheckBox
        options={filterOptions}
        selectedOptions={typeof value === 'boolean' ? [value.toString()] : []}
        onChange={(selectedOptions) => {
          if (selectedOptions.length === 0) {
            onChangeValue(undefined)
          } else {
            onChangeValue(selectedOptions[0] === 'true')
          }
        }}
        multiple={false}
      />
    </Column>
  )
})
HasAssigneeFilter.displayName = 'HasAssigneeFilter'
