import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'
import { memo, useMemo } from 'react'

import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const SemanticSearchTypeFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('semanticSearchType')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('semanticSearchType')

  return (
    <CopilotDashMultiSelectDropdown
      comboId="SemanticSearchTypeFilter"
      filterType={displayName}
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
    />
  )
})

SemanticSearchTypeFilter.displayName = 'SemanticSearchTypeFilter'
