import * as React from 'react'
import { ReactNode } from 'react'
import { RootScreenLayout } from './layout/RootScreenLayout'
import { ErrorViewBoundary } from '../../components/Error'

interface IProps {
  readonly children?: React.ReactNode
}

export function RootShell(props: IProps): ReactNode {
  return (
    <ErrorViewBoundary label="RootScreen">
      <RootScreenLayout>{props.children}</RootScreenLayout>
    </ErrorViewBoundary>
  )
}
