import { useOverlayStore } from '../store/OverlayStore'

/**
 * Close the first open overlay
 *
 * @returns true if an overlay was closed, false otherwise
 */
export function closeTop(): boolean {
  const state = useOverlayStore.getState()
  const item = state.items.find((item) => item.open)
  if (item) {
    item.close()
    return true
  } else {
    return false
  }
}
