import { IClusterJobData, JobColumnIdNaColumnIdNameMapping, JobColumnKey } from '@copilot-dash/domain'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'
import { TimeView } from '../../../../../components/Time/TimeView'

export const ColumnStartTimeUTC: ColDef<IClusterJobData> = {
  headerName: JobColumnIdNaColumnIdNameMapping[JobColumnKey.StartTimeUTC],
  field: String(JobColumnKey.StartTimeUTC) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles>
        <TimeView value={props.data.startTimeUTC} format="YYYY/MM/DD" /> -
        <TimeView value={props.data.endTimeUTC} format="YYYY/MM/DD" />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>DataSet</TableCellLayoutWithStyles>,
  },
  minWidth: 300,
  width: 300,
  maxWidth: 300,
  sortable: true,
  resizable: true,
}
