import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IActions } from './IActions'
import { IState, TicketDiagnosticTab } from './IState'

export function createActions(set: IStoreSetter<IState>, get: IStoreGetter<IState>): IActions {
  return {
    setOpen(isOpen: boolean): void {
      set((state) => {
        state.isOpen = isOpen
      })
    },

    setActiveTab(tab: TicketDiagnosticTab): void {
      set((state) => {
        state.activeTab = tab
        state.activeSourceUrl = undefined
      })
    },

    setActiveSourceUrl(url: string): void {
      set((state) => {
        state.activeTab = TicketDiagnosticTab.Blobs
        state.activeSourceUrl = url
      })
    },
  }
}
