import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const correlationIds = generateKustoStringArrayExp(params.correlationIds)
  const conversationIds = generateKustoStringArrayExp([params.conversationId])
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
TuringBotTraceMDS | union TuringBotChatMessageEventMDS | union TuringBotEventMDS | union TuringBotMonitoredScope
| where TIMESTAMP between (${timeRange})
| where CorrelationId in~ (${correlationIds}) or ConversationId in~ (${conversationIds})
| where ScopeName has_any ('MetaOsGptProvider', 'GetGptList', 'EntityServeService', 'SKDS', 'SkillDiscover')
| project TIMESTAMP, ObjectId, ConversationId,CorrelationId, ScopeName, ScopeOutcome, Message, Exception
`
}

export const SydneyExtensibility_WW: IKustoQueryDefinition = {
  database: 'CompliantSydneyLogs',
  connection: 'https://compliantsydney.eastus.kusto.windows.net',
  query,
}
