import { Column, Row, Spacer } from '@copilot-dash/components'
import { makeStyles, mergeClasses } from '@fluentui/react-components'
import { getErrorTooltipVisible } from './utils/getErrorTooltipVisible'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { ErrorViewCardDetailButton } from '../components/ErrorViewCardDetailButton'
import { ErrorViewCardErrorIcon } from '../components/ErrorViewCardErrorIcon'
import { ErrorViewCardErrorMessage } from '../components/ErrorViewCardErrorMessage'

interface IProps {
  readonly components: IErrorComponents
  readonly className?: string
}

export function ErrorViewLayoutBlock({ components, className }: IProps) {
  const styles = useStyles()
  const scrollbar = useScrollbar()

  const tooltipVisible = getErrorTooltipVisible(components)

  return (
    <Row ref={scrollbar} className={mergeClasses(styles.outer, className)} hAlign="center" vAlign="center">
      <Column className={styles.inner} hAlign="center" vAlign="center">
        {/* 1. Error Icon */}
        <ErrorViewCardErrorIcon components={components} />

        {/* 2. Error Message */}
        <Spacer size="16px" />
        <ErrorViewCardErrorMessage components={components} />

        {/* 3. Error Details */}
        {tooltipVisible && <Spacer size="16px" />}
        {tooltipVisible && <ErrorViewCardDetailButton components={components} />}
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  outer: {
    padding: '48px',
  },
  inner: {
    width: 'auto',
    height: 'auto',
    minHeight: '100%',
  },
})
