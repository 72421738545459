import { Column, Row, Spacer } from '@copilot-dash/components'
import { ColumnKey, columnKeySchema, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { mergeClasses, Text } from '@fluentui/react-components'
import { ChevronRightFilled } from '@fluentui/react-icons'
import { ColDef } from 'ag-grid-community'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import { memo, useMemo, useState } from 'react'
import { z } from 'zod'
import { useStyles } from './CollapsibleTable.styles'
import { AGGridTable, IAGGridTableRef } from '../../../../../../../components/AGGridTable/AGGridTable'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'

interface CollapsibleTableProps {
  tickets: INewTicketData[]
  title: string
  defaultExpanded?: boolean
  onlyShowVIPDSATs?: boolean
  hasMore?: boolean
  loadMore?: (offset: number) => void
  rowHoverStickySlot?: (rowData: INewTicketData) => JSX.Element
  tableRef?: (ref: IAGGridTableRef | null) => void
  onSelectionChange?: (selectedItems: INewTicketData[]) => void
}

export const CollapsibleTable = memo(function CollapsibleTable({
  tickets,
  title,
  defaultExpanded = false,
  rowHoverStickySlot,
  tableRef,
  onSelectionChange,
}: CollapsibleTableProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)
  const [expanded, setExpanded] = useState(defaultExpanded)

  const columns = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const setColumns = app.settings.feedbackInsightsTopIssueDetailsColumns.set

  const handleColumnsChange = (columns: Array<string>) => {
    const movedColumns = columns
      .map((column) => columnKeySchema.safeParse(column))
      .filter((result) => result.success)
      .map((result) => (result as z.SafeParseSuccess<ColumnKey>).data)
    setColumns(movedColumns)
  }

  const colDefs: ColDef<INewTicketData>[] = useMemo(() => {
    const defaultColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    const newSelectedColumns: ColDef<INewTicketData>[] = compact(
      columns.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
    )
    return newSelectedColumns
  }, [columns])

  const count = tickets.length
  const countStr = count === 0 ? '' : ` (${count})`
  const autoHeight = tickets.length <= 15

  return (
    <Column fill className={styles.container}>
      <Row
        className={styles.header}
        onClick={() => {
          setExpanded((bool) => !bool)
        }}
      >
        <Text weight="semibold" size={400}>
          {title}
          {countStr}
        </Text>
        <Spacer />
        <motion.div className={styles.icon} animate={{ rotate: expanded ? 90 : 0 }}>
          <ChevronRightFilled fontSize={22} />
        </motion.div>
      </Row>
      <motion.div
        layout
        animate={expanded ? 'expanded' : 'collapsed'}
        className={styles.accordionContent}
        variants={{
          collapsed: { height: 0, paddingTop: 0 },
          expanded: { height: 'auto', paddingTop: 16 },
        }}
        initial={false}
      >
        <AGGridTable
          ref={(ref) => tableRef?.(ref)}
          rowData={tickets}
          getRowId={(data) => data.data.ticketId}
          columnDefs={colDefs}
          className={mergeClasses(styles.table, autoHeight ? undefined : styles.fixedHeight)}
          onRowClicked={actions.rowClick}
          focusedRowId={ticketId}
          stickySuffixRenderer={rowHoverStickySlot}
          suppressRowVirtualisation={tickets.length < 50}
          domLayout={autoHeight ? 'autoHeight' : 'normal'}
          handleColumnsChange={handleColumnsChange}
          rowSelectionMode="multiRow"
          onSelectionChange={onSelectionChange}
        />
      </motion.div>
    </Column>
  )
})
