import { ReactNode, useId, useLayoutEffect } from 'react'

import { TabItemBody } from './TabItemBody'
import { TabItemContext } from './TabItemContext'
import { TabStore } from '../tab/TabStore'

interface IProps {
  readonly name: string
  readonly title?: string
  readonly children?: ReactNode
}

export function TabItem({ name, title, children }: IProps): ReactNode {
  const id = useId()
  const actions = TabStore.useActions()

  // Note: `init` must be called in `useLayoutEffect` to ensure that the tab item is registered before the first render.
  useLayoutEffect(() => {
    return actions.init({
      id,
      name,
      title,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions])

  useLayoutEffect(() => {
    actions.update({
      id,
      name,
      title: title ?? name,
    })
  }, [actions, id, name, title])

  return (
    <TabItemBody id={id}>
      <TabItemContext.Provider value={id}>{children}</TabItemContext.Provider>
    </TabItemBody>
  )
}
