import { ISearchTicketArgs, SearchFilterFieldNames } from '@copilot-dash/domain'
import { create } from 'zustand'

interface TicketFilterState {
  ticketFilterForm: ISearchTicketArgs
  setTicketFilterForm: (form: ISearchTicketArgs) => void
  supportedTicketFilterItems: SearchFilterFieldNames[]
  setSupportedTicketFilterItems: (items: SearchFilterFieldNames[]) => void
}

export const useTicketFilterStore = create<TicketFilterState>((set) => ({
  ticketFilterForm: {},
  setTicketFilterForm: (form) => set({ ticketFilterForm: form }),
  supportedTicketFilterItems: [],
  setSupportedTicketFilterItems: (items) => set({ supportedTicketFilterItems: items }),
}))
