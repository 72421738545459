import { ApiUpdateRootCauseResponse } from '@copilot-dash/api'
import { IUpdateRootCause } from '@copilot-dash/domain'
import { RootCauseDataConverter } from './converters/RootCauseDataConverter'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function updateRootCause(
  context: IDashStoreContext,
  params: IUpdateRootCause,
): Promise<ApiUpdateRootCauseResponse> {
  const data = RootCauseDataConverter.updateDataToApiV2(params)
  const response = await context.api.copilotDash.updateRootCause(params.issueId, data)
  return { workItemId: response.id, errorMessage: '' }
}
