import { z } from 'zod'

interface Client {
  clientName: string
  displayClientName: string
  platform: string
  licenseType: string
  application: string
  productByClient: string
}

interface Scenario {
  scenarioName: string
  displayScenarioName: string
}

interface Product {
  productId: string
  productName: string
  clientList: Client[]
  scenarioList: Scenario[]
  teamIdList: number[]
}

export type ApiV2ProductListResponse = Product[]

export const apiV2ProductListResponseSchema = z.array(
  z.object({
    productId: z.string(),
    productName: z.string(),
    clientList: z.array(
      z.object({
        clientName: z.string(),
        displayClientName: z.string(),
        platform: z.string(),
        licenseType: z.string(),
        application: z.string(),
        productByClient: z.string(),
      }),
    ),
    scenarioList: z.array(
      z.object({
        scenarioName: z.string(),
        displayScenarioName: z.string(),
      }),
    ),
    teamIdList: z.array(z.number()),
  }),
)
