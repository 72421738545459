import { ApiCreateClusterRequest, ApiGetClusterRequestResult } from '@copilot-dash/api'
import { IClusterBaseResultData, IClusterJobData } from '@copilot-dash/domain'

import { IDashStoreContext } from '../../IDashStoreContext'

// export async function getdataset list by product
export async function createClusteringRequest(
  context: IDashStoreContext,
  data: ApiCreateClusterRequest,
): Promise<IClusterJobData> {
  const response = await context.api.automation.createClusteringRequest(data)
  return { ...response }
}

export async function cancelClusteringRequest(
  context: IDashStoreContext,
  data: ApiGetClusterRequestResult,
): Promise<IClusterBaseResultData> {
  const response = await context.api.automation.cancelClustering(data)
  return { ...response }
}
