import { Node } from '@xyflow/react'
import { layoutNodesHandles } from './layoutNodesHandles'
import { layoutNodesPositions } from './layoutNodesPositions'
import { CallFlowNode } from '../store/IState'

interface IParams {
  readonly nodes: Node<CallFlowNode>[]
  readonly nodeWidth?: number
  readonly nodeHeight?: number
  readonly canvasWidth: number
  readonly paddingLeft?: number
  readonly paddingTop?: number
  readonly paddingRight?: number
  readonly gapX?: number
  readonly gapY?: number
}

export function layoutNodes({
  nodes,
  nodeWidth = 128,
  nodeHeight = 48,
  canvasWidth,
  paddingLeft = 48,
  paddingRight = 48,
  paddingTop = 24,
  gapX = 16,
  gapY = 16,
}: IParams) {
  const maxLayoutColumnCount = Math.floor((canvasWidth - paddingLeft - paddingRight) / (nodeWidth + gapX))
  const maxActualColumnCount = new Set(nodes.map((node) => node.data.item.serviceId)).size
  const columnCount = Math.max(1, Math.min(maxLayoutColumnCount, maxActualColumnCount))

  const initialX = (canvasWidth - columnCount * nodeWidth - (columnCount - 1) * gapX) / 2
  const initialY = paddingTop

  layoutNodesPositions({
    nodes,
    nodeWidth,
    nodeHeight,
    gapX,
    gapY,
    initialX,
    initialY,
    columnCount: maxLayoutColumnCount,
  })

  // 2. Update directions
  layoutNodesHandles({ nodes })
}
