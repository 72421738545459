import { PromiseSnapshot, PromiseSnapshots } from '@copilot-dash/core'
import { IRootCauseData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'
import { translateRootCausePriority } from '../../utils/translateRootCausePriority'

export function getOrFetchRootCauseInfoByRootCauseIdV2(
  context: IDashStoreContext,
  rootCauseId: string,
): PromiseSnapshot<IRootCauseData> {
  return context.getOrFetch({
    get: (state) => {
      return state.team.v2RootCauseMap?.[`${rootCauseId}`]
    },
    set: (state, snapshot) => {
      if (!state.team.v2RootCauseMap) {
        state.team.v2RootCauseMap = {}
      }
      state.team.v2RootCauseMap[`${rootCauseId}`] = snapshot
    },
    fetch: async () => {
      const response = await context.api.copilotDash.getRootCauseById(rootCauseId)
      const { id, priority, isDeleted, isRcrEnabled, assignTo, eta, ...otherValues } = response
      const ret: IRootCauseData = {
        ...otherValues,
        owner: isAssignedTo3SDashTorus(assignTo) ? undefined : assignTo,
        eTA: eta,
        issueId: id,
        rootCausePriority: translateRootCausePriority(priority),
        visible: !isDeleted,
        enable: isRcrEnabled,
        teamId: response.teamId.toString(),
      }
      return ret
    },
  })
}

export function getRootCauseInfoByRootCauseIdV2(
  context: IDashStoreContext,
  rootCauseId: string,
): Promise<IRootCauseData> {
  return context.requestAnd({
    request: async () => {
      const response = await context.api.copilotDash.getRootCauseById(rootCauseId)
      const { id, priority, isDeleted, isRcrEnabled, assignTo, eta, ...otherValues } = response
      const ret: IRootCauseData = {
        ...otherValues,
        owner: isAssignedTo3SDashTorus(assignTo) ? undefined : assignTo,
        eTA: eta,
        issueId: id,
        rootCausePriority: translateRootCausePriority(priority),
        visible: !isDeleted,
        enable: isRcrEnabled,
        teamId: response.teamId.toString(),
      }
      return ret
    },
    onSuccess: (state, snapshot) => {
      state.team.v2RootCauseMap ??= {}
      state.team.v2RootCauseMap[rootCauseId] = PromiseSnapshots.done(snapshot)
    },
  })
}
