import { INewTicketData, WorkItemStatus } from '@copilot-dash/domain'
import { groupBy } from 'lodash'

export function groupTicketsByStateV2(tickets: INewTicketData[]) {
  const groupedTickets = groupBy(tickets, (ticket) => {
    switch (ticket.workItemStatus) {
      case WorkItemStatus.Untriaged:
        return 'Untriaged' as const
      case WorkItemStatus.TeamAssigned:
        return 'Team Assigned' as const
      case WorkItemStatus.RootCaused:
        return 'Root Caused' as const
      case WorkItemStatus.ClosedByDesign:
      case WorkItemStatus.ClosedFixed:
      case WorkItemStatus.ClosedNotActionable:
        return 'Closed' as const
      default:
        return 'Untriaged' as const
    }
  })
  return groupedTickets
}

export function groupTicketsByTeamV2(tickets: INewTicketData[]) {
  const groupedTickets = groupBy(tickets, 'teamId')
  return groupedTickets
}
