import { z } from 'zod'

export enum ClusterResultColumnKey {
  Issue = 'issue',
  Count = 'count',
  Percent = 'percent',
  Priority = 'priority',
}

export const clusterResultColumnKeySchema = z.nativeEnum(ClusterResultColumnKey)
