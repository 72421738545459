import { Row } from '@copilot-dash/components'
import { FC } from 'react'
import { CAPTenantTag } from './CAPTenantTag'
import { FoldedTags } from './FoldedTags'
import { useStyles } from './InsightsTag.styles'
import { VIPUserTag } from './VIPUserTag'
import { TagCounter } from '../../tags/TagCounter'

interface IProps {
  VIPUserIds: string[]
  CAPTenants: Array<{
    tenantId: string
    tenantName: string
  }>
}

const Table_COLUMN_MAX_SHOW_Tags = 1
export const FeedbackInsightsTag: FC<IProps> = ({ VIPUserIds, CAPTenants }) => {
  const styles = useStyles()
  const totalTagCount = VIPUserIds.length + CAPTenants.length
  CAPTenants.sort((a, b) => a.tenantName.localeCompare(b.tenantName))
  return (
    <Row className={styles.container}>
      {VIPUserIds.length > 0 && VIPUserIds[0] ? (
        <VIPUserTag enableTooltip={true} userId={VIPUserIds[0]} isShowViewDSATs={false} />
      ) : (
        CAPTenants[0] && (
          <CAPTenantTag
            enableTooltip={true}
            tenantName={CAPTenants[0].tenantName}
            tenantId={CAPTenants[0].tenantId}
            isShowViewDSATs={false}
          />
        )
      )}
      {totalTagCount > Table_COLUMN_MAX_SHOW_Tags && (
        <TagCounter
          tagCount={totalTagCount - Table_COLUMN_MAX_SHOW_Tags}
          foldedTags={
            <FoldedTags
              VIPUserIds={VIPUserIds.slice(Table_COLUMN_MAX_SHOW_Tags)}
              CAPTenants={
                VIPUserIds.length > 0 && VIPUserIds[0] ? CAPTenants : CAPTenants.slice(Table_COLUMN_MAX_SHOW_Tags)
              }
            />
          }
          className={styles.interactionTagPrimary}
          tagSize="small"
        />
      )}
    </Row>
  )
}
