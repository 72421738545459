import { Button } from '@fluentui/react-components'
import { ErrorViewPopover } from './ErrorViewPopover'
import { IErrorComponents } from '../../ErrorConfigs/interfaces/IErrorComponents'

interface IProps {
  readonly components: IErrorComponents
}

export function ErrorViewCardDetailButton({ components }: IProps) {
  return (
    <ErrorViewPopover components={components}>
      <Button shape="circular">View Details</Button>
    </ErrorViewPopover>
  )
}
