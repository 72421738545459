import { createContext, ReactNode, useContext } from 'react'

const ThemeModeContext = createContext<'light' | 'dark'>('light')

interface IProps {
  readonly theme: 'light' | 'dark'
  readonly children: ReactNode
}

export function ThemeModeProvider({ theme, children }: IProps): ReactNode {
  return <ThemeModeContext.Provider value={theme}>{children}</ThemeModeContext.Provider>
}

export function useThemeMode(): 'light' | 'dark' {
  return useContext(ThemeModeContext)
}
