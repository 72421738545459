import { IProductData } from '@copilot-dash/domain'
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { AnimatePresence, motion } from 'framer-motion'
import { last } from 'lodash'
import { TicketProductDropdown } from '../../../../components/TicketProductDropdown/TicketProductDropdown'
import { GlobalProductProvider } from '../../../../providers/product'
import { ThemeProvider } from '../../../../theme/ThemeProvider'
import { Themes } from '../../../../theme/Themes'

export function HeaderProductDropdown() {
  const styles = useStyles()
  const actions = GlobalProductProvider.useActions()
  const selector = GlobalProductProvider.use((state) => last(state.selectors))

  const handleChange = (value: IProductData) => {
    actions.selectProduct(value)
    application.settings.lastSelectedProduct.set({ productName: value.name, productId: value.productId })
  }

  return (
    <AnimatePresence>
      {selector && (
        <motion.div
          initial
          animate={{ opacity: selector.disabled ? 0.5 : 1.0, scale: 1.0, width: 220 }}
          exit={{ opacity: 0, scale: 0.8, width: 0 }}
        >
          <ThemeProvider
            className={mergeClasses(
              'HeaderProductSelector',
              styles.root,
              !selector.value && styles.rootWhenValueIsEmpty,
            )}
          >
            <TicketProductDropdown
              className={styles.dropdown}
              value={selector.value}
              onChange={handleChange}
              isItemDisabled={selector.isItemDisabled}
              disabled={selector.disabled}
            />
          </ThemeProvider>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const useStyles = makeStyles({
  root: {
    background: 'transparent',

    '& .fui-Dropdown': {
      background: Themes.dark.colorNeutralBackground6,
      ...shorthands.borderColor(Themes.dark.colorNeutralStroke2),

      '&:hover': {
        ...shorthands.borderColor(Themes.dark.colorNeutralStroke1Hover),
      },
    },

    '& .fui-Dropdown__button': {
      color: Themes.dark.colorNeutralForeground1,
    },

    '& .fui-Dropdown__expandIcon': {
      color: Themes.dark.colorNeutralForeground4,
    },
  },
  rootWhenValueIsEmpty: {
    '& .fui-Dropdown button': {
      color: tokens.colorNeutralForegroundInvertedDisabled,
    },
  },
  dropdown: {
    width: '100%',
  },
})
