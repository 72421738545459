import { Field, Input } from '@fluentui/react-components'
import { FC, memo } from 'react'

interface ITitleInputProps {
  value: string | undefined
  onChange: (value: string) => void
  disabled?: boolean
  placeholder?: string
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const TitleInput: FC<ITitleInputProps> = memo(({ value, onChange, disabled, placeholder, fieldProps = {} }) => {
  return (
    <Field label="Issue Title:" orientation="vertical" required {...fieldProps}>
      <Input
        value={value ?? ''}
        disabled={disabled}
        onChange={(_, data) => onChange(data.value)}
        placeholder={placeholder ?? 'Enter Issue title'}
      />
    </Field>
  )
})

TitleInput.displayName = 'TitleInput'
