import { PromiseSnapshot } from '@copilot-dash/core'
import { DefaultProductEndpoints, DefaultProducts, ITicketContextData } from '@copilot-dash/domain'
import { getTicketEmail } from './getTicketEmail'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketContext(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketContextData> {
  return context.getOrFetch<ITicketContextData>({
    get: (state) => {
      return state.tickets[ticketId]?.ticketContext
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.ticketContext = snapshot
    },
    fetch: async () => {
      return fetch()
    },
  })

  async function fetch(): Promise<ITicketContextData> {
    const ticket = await getRawV2Ticket(context, ticketId).promise
    const email = await getTicketEmail(context, ticketId).promise.catch((error) => String(error))

    const client = DefaultProductEndpoints.find(
      (p) => p.name.toLowerCase() === ticket.environment?.clientName?.toLowerCase(),
    )
    const product = DefaultProducts.find((p) => p.productId === client?.productId)
    const { sources, ...visibleTicketContext } = ticket
    void sources

    return {
      properties: {
        'Feedback ID': ticket.id,
        'Created At': ticket.userFeedback.createdDateTime,
        'User Email': email,
        'Auth Type': ticket.user?.authenticationType,
        'Tenant ID': ticket.tenant?.tenantId ?? ticket.user?.tenantId,
        Sentiment: ticket.userFeedback.emotionType ?? ticket.userFeedback.sentiment,
        'Consent Given': ticket.userFeedback.hasUserConsent ? 'Yes' : 'No',
        'Product Name': product?.name,
        'Product Title': product?.title,
        'Client Name': ticket.environment?.clientName,
        'Scenario Name': ticket.environment?.scenarioName,
        Ring: ticket.environment?.ring,
        'Client Version': ticket.environment?.clientVersion,
        'OCV Link': ticket.userFeedback.ocvLink,
        'System Tags': ticket.diagnosticContext?.tags ?? ticket.tags,
      },
      raw: visibleTicketContext,
    }
  }
}
