import { Row } from '@copilot-dash/components'
import { ITicketMessageForSuggestion } from '@copilot-dash/domain'
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'

interface IProps {
  readonly suggestions: ITicketMessageForSuggestion[]
}

export function CopilotMessageViewSuggestions({ suggestions }: IProps) {
  const styles = useStyles()
  if (suggestions.length === 0) {
    return null
  }

  return (
    <Row className={styles.root} hAlign="start" wrap>
      {suggestions.map((item, index) => (
        <Text key={index} className={styles.item}>
          {item.text}
        </Text>
      ))}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '8px 16px',
    marginLeft: '52px',
    gap: '4px',
  },
  item: {
    padding: '4px 8px',
    borderRadius: tokens.borderRadiusMedium,
    backgroundColor: tokens.colorNeutralBackground1,
    wordBreak: 'break-word',
    ...shorthands.border('1px', 'solid', tokens.colorBrandStroke2),
  },
})
