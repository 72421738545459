import { IFCRagResponseResultAnswerItemData, IFCRagResponseResultData } from '@copilot-dash/domain'
import { Button, ProgressBar, Text, mergeClasses } from '@fluentui/react-components'
import { List24Regular, SparkleFilled } from '@fluentui/react-icons'
import { useStyles } from './CopilotMessageContext.styles'
import { MarkdownView } from '../../../../components/Markdown/MarkdownView'
import { useFeedbackCopilotScreenActions, useFeedbackCopilotScreenState } from '../../store'

/**
 * We use an empty space to display when the message content is empty to prevent the message content from collapsing.
 */
const EMPTY_PLACEHOLDER = `&nbsp;`
const DISPLAY_ITEM_TITLE = false // for current reponse, don't need to display title

interface IProps {
  readonly defaultResponse?: string
  readonly queryStatus?: string
  readonly queryResult?: IFCRagResponseResultData
}

export function CopilotMessageContext({ defaultResponse, queryStatus, queryResult }: IProps) {
  const styles = useStyles()
  const actions = useFeedbackCopilotScreenActions()
  const ragAnswerId = useFeedbackCopilotScreenState((state) => state.clickedRagInfo?.answer_id)

  const onMessageClick = (ticket: IFCRagResponseResultAnswerItemData) => {
    actions.ragResultClick(ticket)
  }
  return (() => {
    // todo result list display
    if (queryStatus === 'QueryReady' && queryResult) {
      return (
        <div className={styles.ragResultPanel}>
          <MarkdownView className={styles.markdown}>{queryResult.response ?? ''}</MarkdownView>
          {queryResult.response_references?.map((ticket, index) => (
            <div key={index} className={styles.ragResultItem}>
              {DISPLAY_ITEM_TITLE && ticket.title && ticket.summary && (
                <div className={styles.ragResultItemTitle}>
                  <SparkleFilled style={{ marginRight: '8px' }} /> {/* Replace emoji with icon */}
                  <Text style={{ fontWeight: 600 }}>{ticket.title}</Text>
                </div>
              )}
              {DISPLAY_ITEM_TITLE && ticket.summary && (
                <Text className={styles.ragResultItemSummary}>{ticket.summary}</Text>
              )}
              {ticket.tickets && ticket.tickets.length > 0 && (
                <div className={DISPLAY_ITEM_TITLE ? styles.ragResultItemButton : styles.ragResultItemButtonNoMagin}>
                  <Button
                    appearance="outline"
                    icon={<List24Regular style={{ fontSize: '10px' }} />}
                    iconPosition="before"
                    className={mergeClasses(
                      styles.ragResultItemButtonLabel,
                      ragAnswerId === ticket.answer_id ? 'selected' : 'unselected',
                    )}
                    onClick={() => {
                      onMessageClick(ticket)
                    }}
                  >
                    {ticket.title ? ` Tickets of  ${ticket.title}` : 'Show Tickets'}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className={styles.text}>
          {defaultResponse || EMPTY_PLACEHOLDER}
          {queryStatus === 'NotStarted' && <ProgressBar thickness="large" />}
        </div>
      )
    }
  })()
}
