import { ApiTicketType, ApiTicketsRequest, ApiTicketsWithTicketListRequest } from '@copilot-dash/api'
import { Times } from '@copilot-dash/core'
import { ISearchTicketOptions, getProductEndpoints } from '@copilot-dash/domain'

import { SearchTicketIdByAISearchAction } from './SearchTicketIdByAISearchAction'
import { convertHasErrorMessages } from './utils/convertHasErrorMessages'
import { IDashStoreContext } from '../../IDashStoreContext'

interface IParams {
  readonly searchId: string
  readonly options: ISearchTicketOptions
}

export class BaseTicketSearchAction {
  protected readonly context: IDashStoreContext
  private readonly SearchTicketIdByAISearchAction: SearchTicketIdByAISearchAction

  constructor(context: IDashStoreContext) {
    this.context = context
    this.SearchTicketIdByAISearchAction = new SearchTicketIdByAISearchAction(context)
  }

  protected createRequest(params: IParams): ApiTicketsWithTicketListRequest {
    const { options } = params
    //TODO: Align new rule with backend to handle triggered skill
    const hasErrorMessagesConditions = convertHasErrorMessages(options.hasErrorMessages)
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.scenarios,
    )

    const requestBody: ApiTicketsRequest = {
      Count: options.count,
      Offset: options.offset,
      ClientNames: clientNames,
      ScenarioNames: options.channel,
      MetaData: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.hasUserConsent?.length ? [options.hasUserConsent.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appKinds?.length ? [options.appKinds.join('|')] : []),
      ],
      //ModelLanguages: options.modelLanguages,//Updated ModelLanguages to Utterance Language(from VSO Tag)
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      TenantIds: options.tenantIds,
      Thumbs: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      ExternalTicketId: options.ticketId,
      Priorities: options.priority?.map(Number) ?? [],
      DSATStatus: options.dSATStatus,
      DSATAssignedTo: options.dSATAssignedTo,
    }

    return {
      searchId: params.searchId,
      SearchTicketStatus: params.options.ticketStatus,
      TeamName: params.options.teamName,
      IssueId: params.options.issueId,
      RecommendedRootCauseId: params.options.recommendedRootCauseId,
      ClusteringIssueId: params.options.clusteringIssueId,
      ClusteringBatchId: params.options.batchId,
      TeamId: Number(params.options.teamId),
      request: requestBody,
    }
  }
}
