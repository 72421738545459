import { INewTicketData } from '@copilot-dash/domain'
import { useMemo } from 'react'

interface IResult {
  readonly text: string | undefined
  readonly textTranslated: boolean
  readonly highlights: Array<string> | undefined
  readonly highlightsTranslated: boolean
}

export function useTranslatedTicketResponse(ticket: INewTicketData): IResult {
  const translation = app.settings.translateContentToEnglish.use()

  return useMemo((): IResult => {
    const responseLanguage = ticket.responseLanguage

    const response = ticket.response
    const responseInEnglish = ticket.responseInEnglish
    const responseTranslated =
      translation &&
      !!responseInEnglish &&
      responseLanguage !== 'English' &&
      response?.trim() !== responseInEnglish?.trim()

    const highlight = ticket.responseWithHighlight
    const highlightInEnglish = ticket.responseInEnglishWithHighlight
    const highlightTranslated = translation && !!highlightInEnglish && responseLanguage !== 'English'

    return {
      text: responseTranslated ? responseInEnglish : response,
      textTranslated: responseTranslated,
      highlights: highlightTranslated ? highlightInEnglish : highlight,
      highlightsTranslated: highlightTranslated,
    }
  }, [translation, ticket])
}
