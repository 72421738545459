import { Body, Column, Row, Spacer } from '@copilot-dash/components'
import { IFilterOption, SearchFilterFieldNamesArraySchema, searchFilterFieldNamesSchema } from '@copilot-dash/domain'
import { getFilterDisplayName } from '@copilot-dash/store'
import { Button, DialogTrigger, Divider, Text, makeStyles, tokens } from '@fluentui/react-components'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useMemo, useState } from 'react'
import { useTicketFilterStore } from './store/store'
import { CohortFilters, CustomFilterCardFilters } from '../../components/TicketsFilterPanel/children/cards/config'
import { SearchRoute, TeamRoute } from '../../router'

import { CopilotDashCheckBox } from '../Filter/CopilotDashCheckBox'
import { useScrollbar } from '../Scrollbar/useScrollbar'
import { isValidFormValue } from '../TicketsFilterPanel/config/utils'

export function CustomizedFilterForm() {
  const styles = useStyles()
  const scrollbar = useScrollbar()
  const searchScreenFilterItems = app.settings.searchScreenCustomizedFilterItems.use()
  const teamViewFilterItems = app.settings.teamViewCustomizedFilterItems.use()
  const form = useTicketFilterStore((state) => state.ticketFilterForm)
  const supportedTicketFilterItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const enableCohortFilter = app.features.cohortFilter.use()

  const [localFilterPanelItems, setLocalFilterPanelItems] = useState(() => {
    if (TeamRoute.navigator.isCurrent()) {
      return teamViewFilterItems
    }

    if (SearchRoute.navigator.isCurrent()) {
      return searchScreenFilterItems
    }

    return searchScreenFilterItems
  })

  const onClickSave = () => {
    app.settings.searchScreenCustomizedFilterItems.set(localFilterPanelItems)
    app.settings.teamViewCustomizedFilterItems.set(localFilterPanelItems)
  }
  const newCustomFilterCardFilters = useMemo(() => {
    if (enableCohortFilter) {
      return CustomFilterCardFilters
    }
    return CustomFilterCardFilters.filter((item) => !CohortFilters.includes(item))
  }, [enableCohortFilter])

  const allCustomFilters: IFilterOption[] = useMemo(() => {
    return newCustomFilterCardFilters.map((filterKey) => ({
      key: filterKey,
      text: getFilterDisplayName(filterKey),
      disabled: isValidFormValue(form[filterKey]),
      hidden: !supportedTicketFilterItems.includes(filterKey),
    }))
  }, [form, newCustomFilterCardFilters, supportedTicketFilterItems])

  const selectedCustomFilters = useMemo(() => {
    return allCustomFilters
      .filter((item) => {
        const result = searchFilterFieldNamesSchema.safeParse(item.key)
        if (result.success) {
          return localFilterPanelItems.includes(result.data)
        }
        return false
      })
      .map((item) => item.key)
  }, [allCustomFilters, localFilterPanelItems])

  return (
    <Column className={styles.container}>
      <Column>
        <Row>
          <Text className={styles.title} size={500} weight="semibold">
            Add filters
          </Text>
          <DialogTrigger disableButtonEnhancement>
            <Button
              appearance="subtle"
              className={styles.closeButton}
              aria-label="Close Panel"
              icon={<Dismiss24Regular />}
            />
          </DialogTrigger>
        </Row>
      </Column>
      <Spacer size="16px" />

      <Row>
        <Text>
          Select filters below to show in the filters list. The filters will appear under Custom filters group.
        </Text>
      </Row>

      <Spacer size="16px" />
      <Text size={400} weight="semibold">
        Select filters
      </Text>
      <Spacer size="12px" />

      <Body className={styles.body} ref={scrollbar}>
        <CopilotDashCheckBox
          options={allCustomFilters}
          selectedOptions={selectedCustomFilters}
          onChange={(selectedOptions) => {
            const result = SearchFilterFieldNamesArraySchema.safeParse(selectedOptions)
            if (result.success) {
              setLocalFilterPanelItems(result.data)
            }
          }}
          multiple={true}
          isGrouped={false}
          className={styles.checkbox}
        />
      </Body>

      <Column vAlign="start">
        <Divider />
        <Spacer size="16px" />
        <Row>
          <Spacer />
          <DialogTrigger disableButtonEnhancement>
            <Button appearance="primary" className={styles.saveButton} onClick={onClickSave}>
              Update filters
            </Button>
          </DialogTrigger>
        </Row>
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  container: {
    padding: '16px',
  },

  header: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  body: {
    flex: '1 1 auto',
  },
  title: {
    flex: '1 1 auto',
  },
  closeButton: {
    alignItems: 'end',
    justifyContent: 'flex-end',
  },
  saveButton: {},
  checkbox: {
    fontSize: tokens.fontSizeHero800,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1,
  },
})
