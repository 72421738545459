import { z } from 'zod'

export interface ApiV2BatchUpdateWorkItemCustomTagsResponse {
  readonly updateSuccessTicketIdList: Array<string>
  readonly updateFailedTicketIdList: Array<string>
}

export const apiV2BatchUpdateWorkItemCustomTagsResponseSchema = z.object({
  updateSuccessTicketIdList: z.array(z.string()),
  updateFailedTicketIdList: z.array(z.string()),
})
