import { IClusterJobData, JobColumnKey, JobStatus } from '@copilot-dash/domain'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo, useCallback, useMemo } from 'react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'
import { ClusterRoute } from '../../../../../../router'
import { useClusterScreenActions } from '../../../../store'

export const Actions: ColDef<IClusterJobData> = {
  field: String(JobColumnKey.Id) as NestedFieldPaths<IClusterJobData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return <RenderActions item={props.data} />
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles></TableCellLayoutWithStyles>,
  },
  minWidth: 200,
  width: 200,
  maxWidth: 200,
  sortable: true,
  resizable: true,
}

const RenderActions = memo(({ item }: { item: IClusterJobData }) => {
  const styles = useStyles()
  const args = ClusterRoute.navigator.useArgsOptional()
  const actions = useClusterScreenActions()
  const onClusterRowClick = useCallback(
    (row: IClusterJobData) => {
      actions.clusterJobClick(row)
      args?.product && ClusterRoute.navigator.navigate({ ...args, clusteringRequestId: row.id })
    },
    [actions, args],
  )
  const content = useMemo(() => {
    if (item.clusteringRequestStatus === JobStatus.Published) {
      return (
        <Button className={styles.resultBtn} onClick={() => onClusterRowClick(item)}>
          View Results
        </Button>
      )
    }

    //will add more logic for next phase 2
    switch (item.clusteringRequestStatus) {
      // case JobStatus.Submitted:
      // case JobStatus.Clustering:
      // case JobStatus.DataGathering:
      // case JobStatus.DatasetReady:
      //   return <Button>Cancel</Button>
      case JobStatus.ClusteringCompleted:
        return (
          <Button className={styles.resultBtn} onClick={() => onClusterRowClick(item)}>
            View Results
          </Button>
        )
      default:
        return <></>
    }
  }, [item, onClusterRowClick, styles.resultBtn])

  return <TableCellLayoutWithStyles>{content}</TableCellLayoutWithStyles>
})
RenderActions.displayName = 'RenderActions'

const useStyles = makeStyles({
  resultBtn: {
    color: tokens.colorCompoundBrandBackground,
    backgroundColor: tokens.colorBrandBackground2,
    border: `1px solid ${tokens.colorBrandBackground2}`,
  },
})
