import { Row } from '@copilot-dash/components'
import { Text, makeStyles, tokens } from '@fluentui/react-components'

export function DiscussionPanelEmpty() {
  const styles = useStyles()

  return (
    <Row fill vAlign="center" hAlign="center">
      <Text className={styles.grayColor}>No comments found</Text>
    </Row>
  )
}

const useStyles = makeStyles({
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
})
