import { PromiseSnapshot, PromiseSnapshots, WritableDraft } from '@copilot-dash/core'
import { IDashStoreUse } from '../create/createDashStoreUse'
import { IDashStoreState } from '../state/IDashStoreState'

interface IParams<T> {
  readonly use: IDashStoreUse
  readonly get: (state: IDashStoreState) => PromiseSnapshot<T> | undefined
  readonly set: (state: WritableDraft<IDashStoreState>, snapshot: PromiseSnapshot<T>) => void
  readonly fetch: () => T | Promise<T>
  readonly shouldPromiseCompare?: boolean
}

export function getOrFetch<T>({ use, get, set, fetch, shouldPromiseCompare = true }: IParams<T>): PromiseSnapshot<T> {
  const existing = get(use.getState())
  if (existing) {
    return existing
  }

  const doFetch = async (): Promise<T> => {
    await Promise.resolve()

    const existing = get(use.getState())
    if (existing) {
      return existing.promise
    }

    return fetch()
  }

  const doUpdate = (snapshot: PromiseSnapshot<T>) => {
    const existing = get(use.getState())
    if (existing && existing.promise !== snapshot.promise && shouldPromiseCompare) {
      return
    }

    use.setState((state) => {
      set(state, snapshot)
    })
  }

  const doUpdateAsync = (snapshot: PromiseSnapshot<T>) => {
    Promise.resolve().then(() => {
      doUpdate(snapshot)
    })
  }

  const promise = doFetch()
  const promiseSnapshot = PromiseSnapshots.waiting(promise)
  doUpdateAsync(promiseSnapshot)

  promise
    .then((result) => {
      doUpdate(PromiseSnapshots.done(result, promise))
    })
    .catch((error) => {
      doUpdate(PromiseSnapshots.error(error, promise))
    })

  return promiseSnapshot
}
