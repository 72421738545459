import { Spacer } from '@copilot-dash/components'
import { DashRing } from '@copilot-dash/core'
import { Text } from '@fluentui/react-components'

const rings: {
  [key in DashRing]: string
} = {
  dev: 'Dev',
  test: 'Test',
  sdf: 'SDF',
  msit: '',
  unknown: 'Unknown',
}

export function HeaderRingText() {
  const ring = application.env.ring
  const ringText = rings[ring]

  if (!ringText) {
    return null
  }

  return (
    <>
      <Spacer width="12px" />
      <Text wrap={false} size={500} weight="semibold" truncate>
        {`(${ringText})`}
      </Text>
    </>
  )
}
