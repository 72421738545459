import { Column, Row, Spacer } from '@copilot-dash/components'
import { Button, Skeleton, SkeletonItem, Spinner, Tag, Text } from '@fluentui/react-components'
import { Dismiss16Filled } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useStyles } from '../../../../../../components/TicketActionForm/fields/CustomTags.styles'
import { CustomTagsPopover } from '../../../../../../components/TicketActionForm/fields/CustomTagsPopover'
import { useToast } from '../../../../../../hooks/useToast'
import { useTeamViewStore } from '../../../../store'

export const RootCauseCustomTags: FC = memo(() => {
  const styles = useStyles()
  const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
  const filterOptionsSnapshot = app.store.use.getAllCustomTags()
  const rootCauseCustomTagsSnapshot = app.store.use.getRootCauseCustomTags(selectedRootCauseId!)
  const [visibleTags, setVisibleTags] = useState<string[]>([])
  const [focusDisplayValue, setFocusDisplayValue] = useState('')
  const [openSelect, setOpenSelect] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pendingDeleteTag, setPendingDeleteTag] = useState('')
  const toast = useToast()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRemoveTag = useCallback(
    debounce((e: React.MouseEvent, value: string) => {
      e.stopPropagation()
      setPendingDeleteTag(value)
      const newValues = [...visibleTags].filter((tag) => tag !== value)
      app.store.actions
        .patchRootCauseCustomTags(selectedRootCauseId!, newValues)
        .then(() => {
          setPendingDeleteTag('')
        })
        .catch((err) => {
          setPendingDeleteTag('')
          toast.showError('Failed to delete. Cause:' + err)
        })
      Logger.telemetry.trackEvent('CustomTag/DeleteTag', {
        tags: newValues,
        deletedTag: value,
        workItemType: 'RootCause',
      })
    }, 300),
    [visibleTags, selectedRootCauseId],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddTag = useCallback(
    debounce((tag: string) => {
      setIsLoading(true)
      const newValues = [...visibleTags]
      newValues.push(tag)
      app.store.actions
        .patchRootCauseCustomTags(selectedRootCauseId!, newValues)
        .then(() => {
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
          toast.showError('Failed to save. Cause:' + err)
        })

      setFocusDisplayValue('')
      setIsPopoverOpen(false)
      Logger.telemetry.trackEvent('CustomTag/AddTag', { tags: newValues, addedTag: tag, workItemType: 'RootCause' })
    }, 300),
    [visibleTags, selectedRootCauseId],
  )

  useEffect(() => {
    if (rootCauseCustomTagsSnapshot.status === 'done') {
      setVisibleTags(
        rootCauseCustomTagsSnapshot.data && rootCauseCustomTagsSnapshot.data.length > 0
          ? rootCauseCustomTagsSnapshot.data
          : [],
      )
    }
  }, [rootCauseCustomTagsSnapshot.data, rootCauseCustomTagsSnapshot.status])

  //filter the options based on the input value
  function filterOptions(optionsList: string[]) {
    const updatedOptionsList = [...optionsList]
    // append the focusDisplayValue to the options list if it is not in the list
    if (
      focusDisplayValue &&
      !visibleTags.includes(focusDisplayValue) &&
      !updatedOptionsList.includes(focusDisplayValue)
    ) {
      updatedOptionsList.push(focusDisplayValue)
    }
    const filteredOptions = updatedOptionsList
      .filter((item) => {
        return (
          item?.toLocaleLowerCase().startsWith(focusDisplayValue.toLocaleLowerCase()) && !visibleTags.includes(item)
        )
      })
      .sort((a, b) => {
        const x1 = a!.toLocaleUpperCase()
        const x2 = b!.toLocaleUpperCase()
        return x1.localeCompare(x2)
      })

    if (visibleTags.includes(focusDisplayValue) && filteredOptions.length === 0) {
      return <Text>Tag already exists.</Text>
    }

    if (filteredOptions.length > 0) {
      return filteredOptions.map((item) => (
        <Column key={item}>
          <Button appearance="subtle" className={styles.selectButton} onClick={() => onAddTag(item)} title={item}>
            {item}
          </Button>
        </Column>
      ))
    }

    return null
  }

  const targetFinalOptions = filterOptions(filterOptionsSnapshot.data ?? [])

  //close the dropdown when the targetFinalOptions is null
  useEffect(() => {
    if (targetFinalOptions !== null) {
      setOpenSelect(true)
    } else {
      setOpenSelect(false)
    }
  }, [openSelect, targetFinalOptions])

  return (
    <Column>
      <Text size={300} className={styles.title} weight="semibold">
        Tags
      </Text>
      <Spacer height={10} />
      {rootCauseCustomTagsSnapshot.status === 'waiting' ? (
        <Skeleton>
          <Column>
            <SkeletonItem size={16} />
            <Spacer height={4} />
            <SkeletonItem size={16} />
          </Column>
        </Skeleton>
      ) : (
        <Row className={styles.tagsContainer}>
          {rootCauseCustomTagsSnapshot.data?.map((tag, index) => (
            <Tag
              dismissible
              value={tag}
              key={tag}
              tabIndex={index}
              disabled={pendingDeleteTag === tag}
              className={styles.tagStyle}
              dismissIcon={{
                as: 'span',
                children:
                  pendingDeleteTag === tag ? (
                    <Spinner style={{ opacity: 0.6 }} key={index} size="tiny" />
                  ) : (
                    <Dismiss16Filled className={styles.dismissButton} onClick={(e) => onRemoveTag(e, tag)} />
                  ),
              }}
            >
              <Text className={styles.textStyle} title={tag}>
                {tag}
              </Text>
            </Tag>
          ))}
          {isLoading ? (
            <Spinner size="extra-small" />
          ) : (
            <CustomTagsPopover
              optionContent={targetFinalOptions}
              inputValue={focusDisplayValue}
              setInputValue={setFocusDisplayValue}
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              showInput={true}
              onKeyDown={onAddTag}
            />
          )}
        </Row>
      )}
    </Column>
  )
})

RootCauseCustomTags.displayName = 'RootCauseCustomTags'
