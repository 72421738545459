import { Column, Row, Spacer } from '@copilot-dash/components'
import { Button, Text, tokens } from '@fluentui/react-components'
import { ListRegular, PenSparkleRegular } from '@fluentui/react-icons'

import { FC } from 'react'
import { useStyles } from './TopIssueTable.style'
import { Legend } from '../../../../../../components/RatioProgressBar/Legend'

interface IProps {
  handleClusterListClick?: () => void
}
export const TopIssueFineTuneBar: FC<IProps> = ({ handleClusterListClick }) => {
  const styles = useStyles()
  const isFineTuneEnabled = app.features.finetuneCluster.use()
  return (
    <>
      <Row className={styles.title}>
        <Column>
          <Row style={{ marginBottom: '4px' }}>
            <Text weight="semibold" size={500}>
              Top Issues
            </Text>
          </Row>
          <Row>
            <Text size={300} color={tokens.colorNeutralForeground1}>
              Not satisfied with the clustering result? Contact your admin to fine-tune it.
            </Text>
          </Row>
        </Column>
        <Spacer />
        {isFineTuneEnabled && (
          <>
            <Button icon={<PenSparkleRegular />} className={styles.finetueButton}>
              Fine-tune clusters
            </Button>
            <Spacer width={10} />
          </>
        )}
        <Button icon={<ListRegular />} className={styles.clusterButton} onClick={handleClusterListClick}>
          Clusterings
        </Button>
        <Spacer width={16} />
      </Row>
      <Row className={styles.title}>
        <Spacer />
        <Legend
          items={[
            {
              color: tokens.colorPaletteGreenBackground2,
              label: 'Closed',
            },
            {
              color: tokens.colorCompoundBrandBackground,
              label: 'Root Caused',
            },
            {
              color: tokens.colorPaletteMarigoldBackground3,
              label: 'Team Assigned',
            },
            {
              color: tokens.colorNeutralBackground6,
              label: 'Untriaged',
            },
          ]}
        />
        <Spacer width={16} />
      </Row>
    </>
  )
}
