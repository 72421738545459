import { FeedbackId, WorkItemId } from '../basic'
import { TeamId } from '../team'

export enum RootCauseActionType {
  UserNoAction = '-1',
  UserRejected = '0',
  UserConfirmed = '1',
  DirectLinked = '2',
  DirectLinkedReviewed = '3',
  DirectLinkedRemoved = '4',
}

export enum RootCauseActionTypeV2 {
  UserNoAction = 'UserNoAction',
  UserRejected = 'UserRejected',
  UserConfirmed = 'UserConfirmed',
}

export interface IRootCauseRecommendation {
  readonly workItemId: string
  readonly title: string
  readonly userAction: RootCauseActionType | RootCauseActionTypeV2
  readonly teamId: TeamId
  readonly rank: number
  readonly isRcrEnabled: boolean
}

export interface IUpdateRootCauseRecommendationItemV2 {
  readonly ticketId: FeedbackId
  readonly userActionList: Array<{
    readonly workItemId: WorkItemId
    readonly userActionType: RootCauseActionTypeV2
  }>
}
