import { Column, Row } from '@copilot-dash/components'
import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { Skeleton, SkeletonItem, Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { ErrorView } from '../Error'

export const ColumnConfigRootCause: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.RootCause],
  field: String(ColumnKey.RootCause) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderRootCauseListV2 item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles>{ColumnIdNameMapping[ColumnKey.RootCause]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 130,
  sortable: false,
  resizable: true,
}

const RenderRootCauseList = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (!item.rootCauseList || item.rootCauseList.length === 0) {
    return <Text className={styles.grayColor}>Need to add</Text>
  }
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Column className={styles.tooltipContent}>
          {item.rootCauseList.map((item) => (
            <Row key={item.issueId}>{item.rootCauseTitle}</Row>
          ))}
        </Column>
      }
      className={styles.tooltip}
      toolTipParentContent={
        <Text className={styles.text}>{item.rootCauseList.map((item) => item.rootCauseTitle).join(', ')}</Text>
      }
    />
  )
})

RenderRootCauseList.displayName = 'RenderRootCauseList'

const RenderRootCauseListV2 = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (!item.rootCauseIds || item.rootCauseIds.length === 0) {
    return <Text className={styles.grayColor}>Need to add</Text>
  }

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Column className={styles.tooltipContent}>
          {item.rootCauseIds.map((rootCauseId) => (
            <Row key={rootCauseId}>
              <RootCauseItem rootCauseId={rootCauseId} />
            </Row>
          ))}
        </Column>
      }
      className={styles.tooltip}
      toolTipParentContent={
        <Text className={styles.text}>
          {item.rootCauseIds.map((rootCauseId) => (
            <>
              <RootCauseItem rootCauseId={rootCauseId} />
              <span>; </span>
            </>
          ))}
        </Text>
      }
    />
  )
})
RenderRootCauseListV2.displayName = 'RenderRootCauseListV2'

const RootCauseItem = memo(({ rootCauseId }: { rootCauseId: string }) => {
  const rootCauseSnapshot = app.store.use.getRootCauseInfoByIdV2(rootCauseId)
  if (rootCauseSnapshot.status === 'waiting') {
    return (
      <Skeleton>
        <SkeletonItem />
      </Skeleton>
    )
  }
  if (rootCauseSnapshot.status === 'error') {
    return <ErrorView error={rootCauseSnapshot.error} />
  }
  return rootCauseSnapshot.data?.rootCauseTitle
})
RootCauseItem.displayName = 'rootCauseItem'
