import { useMemo } from 'react'
import { Route } from 'react-router-dom'
import { ClusterRouteMapper } from './ClusterRouteMapper'
import { ClusterRoutePage } from './ClusterRoutePage'
import { createNavigator } from '../../navigator/createNavigator'

export function ClusterRoute() {
  const clusterViewNavigator = useMemo(() => createNavigator(ID, PATH, ClusterRouteMapper), [])
  return <Route id={ID} path={PATH} element={<ClusterRoutePage ClusterNavigator={clusterViewNavigator} />} />
}

const ID = 'product.Cluster'
const PATH = '/:product/Cluster'

ClusterRoute.id = ID
ClusterRoute.path = PATH
ClusterRoute.navigator = createNavigator(ID, PATH, ClusterRouteMapper)
