import { useMemo } from 'react'
import { useMatches } from 'react-router-dom'
import { useCurrentRouteParams } from './utils/useCurrentRouteParams'
import { IRouteContext } from '../../types/IRouteContext'

export function useArgs<TArgs>(context: IRouteContext<TArgs>): TArgs {
  const matches = useMatches()

  // Case 1: No matches
  const last = matches[matches.length - 1]
  if (!last) {
    throw new Error('Unable to call "useRouteArgs()" outside of a router context.')
  }

  // Case 2: Route not found in matches
  const match = matches.find((match) => match.id === context.id)
  if (!match) {
    throw new Error(
      `Unable to call "useRouteArgs()" from a different route. [actual: ${last.id}, expected: ${context.id}]`,
    )
  }

  // Case 3: Route found but not the last one
  if (match !== last) {
    throw new Error(
      `Unable to call "useRouteArgs()" from a non-leaf route. [actual: ${last.id}, expected: ${context.id}]`,
    )
  }

  const currentRouteParams = useCurrentRouteParams()
  return useMemo(() => {
    return context.mapper.mapToArgs(currentRouteParams)
  }, [context.mapper, currentRouteParams])
}
