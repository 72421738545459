import { IFilterOption } from '@copilot-dash/domain'
import { getFilterDisplayName, useFilterOptions } from '@copilot-dash/store'

import { useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const CapabilitiesSlicerFilter: React.FC<IProps> = ({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useFilterOptions('capabilitiesSlicers')
  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (filterOptionsSnapshot.status) {
      case 'done':
        return filterOptionsSnapshot.data
      default:
        return []
    }
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status])
  const displayName = getFilterDisplayName('capabilitiesSlicers')

  return (
    <CopilotDashMultiSelectDropdown
      comboId={'combo-capabilitiesSlicersFilter'}
      filterType={displayName}
      isGroup={false}
      optionsList={filterOptions}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      infoLabelContent={<>Capabilities is computed offline with a potential delay. SLA: 33 hours.</>}
    />
  )
}
