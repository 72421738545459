import { makeStyles, Tab, tokens } from '@fluentui/react-components'
import { SettingsScreenOutlineConfigs } from './SettingsScreenOutlineConstant'
import { SettingsScreenTab, useSettingsScreenStore } from '../../store'

interface IProps {
  readonly tab: SettingsScreenTab
}

export function SettingsScreenOutlineTile({ tab }: IProps) {
  const styles = useStyles()
  const isSelected = useSettingsScreenStore((state) => state.selectedTab === tab)

  const config = SettingsScreenOutlineConfigs[tab]
  const title = config.title
  const icon = isSelected ? config.selectedIcon : config.icon

  return (
    <Tab className={styles.tab} icon={icon} value={tab} color="red">
      {title}
    </Tab>
  )
}

const useStyles = makeStyles({
  tab: {
    height: '40px',

    '&:hover': {
      fontWeight: 'bold',
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
})
