import { Tab, TabItem } from '@copilot-dash/components'
import { z } from 'zod'
import { MetricView } from './tabs/metrics/MetricView'
import { TicketScreenTelemetryItemForJson } from './TicketScreenTelemetryItemForJson'
import { TicketScreenTelemetryTab } from '../../../store/IState'
import { TicketScreenStore } from '../../../TicketScreenStore'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function TicketScreenTelemetry({ ticketId, messageId }: IProps) {
  const actions = TicketScreenStore.useActions()
  const selectedTab = TicketScreenStore.use((state) => state.selectedTelemetryTab)

  return (
    <Tab
      tab={selectedTab}
      onTabChange={(value) => {
        const tab = z.nativeEnum(TicketScreenTelemetryTab).safeParse(value).data
        if (tab) {
          actions.selectTelemetryTab(tab)
        }
      }}
    >
      <TabItem name={TicketScreenTelemetryTab.Metrics}>
        <MetricView ticketId={ticketId} messageId={messageId} />
      </TabItem>
      <TabItem name={TicketScreenTelemetryTab.JSON}>
        <TicketScreenTelemetryItemForJson ticketId={ticketId} messageId={messageId} />
      </TabItem>
    </Tab>
  )
}
