import { PromiseSnapshots } from '@copilot-dash/core'
import { IDashStoreContext } from '../../IDashStoreContext'

export async function updateRootCauseCustomTag(
  context: IDashStoreContext,
  rootCauseGuid: string,
  customTags: string[],
): Promise<void> {
  context.use.setState((state) => {
    if (!state.team.rootCauseCustomTags) {
      state.team.rootCauseCustomTags = {}
    }
    state.team.rootCauseCustomTags[rootCauseGuid] = PromiseSnapshots.done(customTags)
  })
}
