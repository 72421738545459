import { generateKustoStringArrayExp } from './generators/generateKustoStringArrayExp'
import { generateKustoTimeRangeExp } from './generators/generateKustoTimeRangeExp'
import { IKustoQueryBuilder, IKustoQueryDefinition } from '../types/IKustoQueryDefinition'

const query: IKustoQueryBuilder = (params) => {
  const botRequestIds = generateKustoStringArrayExp(params.botRequestIds)
  const correlationIds = generateKustoStringArrayExp(params.correlationIds)
  const transactionIds = generateKustoStringArrayExp(params.transactionIds)
  const timeRange = generateKustoTimeRangeExp(params.time, 60)

  return `
SubstrateSearchInfoEvent_Global_NoEUPI()
| where IsEcho == False
| where env_time ${timeRange}
| where AppScenario == 'sydney' and RouteAction in ('query', 'asyncquery', 'unfurl')
| extend ClientRequestIdGuid = split(ClientRequestId, ":")[0]
| where LogicalId in~ (${botRequestIds})
    or ClientRequestIdGuid in~ (${correlationIds})
    or TransactionId in~ (${transactionIds})
| extend DiagnosticData_Decode=gzip_decompress_from_base64_string(DiagnosticData)
| sort by env_time asc
`
}

export const SubstrateSearchInfo_WW: IKustoQueryDefinition = {
  database: 'SubstrateSearchExceptionEvent',
  connection: 'https://substratesearch.kusto.windows.net',
  query: query,
}
