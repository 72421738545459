import { MonacoViewInline, Row } from '@copilot-dash/components'
import { makeStyles, tokens } from '@fluentui/react-components'

interface IProps {
  readonly content: string
}

export function MetricCardJsonBody({ content }: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.root} hAlign="stretch">
      <div className={styles.background} />
      <MonacoViewInline className={styles.root} data={content} language="json" />
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '240px',
    width: '100%',
    border: `1px solid ${tokens.colorBrandStroke2}`,
    borderRadius: tokens.borderRadiusMedium,
    overflow: 'hidden',
    position: 'relative',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: tokens.colorBrandBackground2,
    opacity: 0.2,
  },
})
