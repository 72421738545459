import { ITicketMessageForInvocation } from '@copilot-dash/domain'
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { CircleFilled } from '@fluentui/react-icons'

interface IProps {
  readonly index: number
  readonly data: ITicketMessageForInvocation
  readonly expanded: boolean
}

export function InvocationMessageItemForData({ index, data, expanded }: IProps) {
  const styles = useStyles()

  return (
    <AccordionItem value={index} title={data.text}>
      <AccordionHeader
        className={mergeClasses(styles.header, expanded ? 'expanded' : 'collapsed')}
        size="small"
        expandIcon={<CircleFilled className={mergeClasses(styles.headerIcon, expanded ? 'expanded' : 'collapsed')} />}
      >
        {data.type ?? 'Unknown'}
      </AccordionHeader>
      <AccordionPanel className={mergeClasses(styles.panel, expanded ? 'expanded' : 'collapsed')}>
        <pre className={styles.text}>{data.text}</pre>
      </AccordionPanel>
    </AccordionItem>
  )
}

const useStyles = makeStyles({
  root: {},
  header: {
    '&.expanded': {
      color: tokens.colorNeutralForeground1,
      fontWeight: tokens.fontWeightSemibold,
    },

    '&.collapsed': {
      color: tokens.colorNeutralForeground4,
    },
  },
  headerIcon: {
    fontSize: '6px',
    marginLeft: '3px',
  },
  panel: {
    paddingTop: '0px',
    paddingBottom: '16px',
  },
  text: {
    margin: '0',
    padding: '0',
    paddingLeft: '12px',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: tokens.colorNeutralForeground4,
    fontSize: tokens.fontSizeBase200,
  },
})
