import { Column } from '@copilot-dash/components'
import { AsyncSnapshot } from '@copilot-dash/core'
import { IFilterOption } from '@copilot-dash/domain'
import { Checkbox, Option, Persona, Spinner, Text } from '@fluentui/react-components'
import { PeopleListRegular } from '@fluentui/react-icons'
import { uniqBy } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useStyles } from './CopilotDashDropdown.styles'
import { CopilotDashPopover } from './CopilotDashPopover'

interface IProps {
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[] | undefined) => void
  snapshot: AsyncSnapshot<IFilterOption[]>
  keyword: string | undefined
  setkeyword: (value: string) => void
  className?: string
  placeholder?: string
  showInput?: boolean
  isPopoverOpen: boolean
  setIsPopoverOpen: (value: boolean) => void
}

export const CopilotDashSearchableDropdown: FC<IProps> = ({
  defaultSelectedOption,
  onChangeFilter,
  snapshot,
  keyword,
  setkeyword,
  placeholder = 'Select',
  showInput,
  isPopoverOpen,
  setIsPopoverOpen,
}) => {
  const styles = useStyles()
  const [selectedOptionsKey, setSelectedOptionsKey] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<IFilterOption[]>([])
  const [value, setValue] = useState<string>('')

  const filterOptions: IFilterOption[] = useMemo(() => {
    switch (snapshot.status) {
      case 'done':
        return snapshot.data
      default:
        return []
    }
  }, [snapshot.data, snapshot.status])

  useEffect(() => {
    setSelectedOptionsKey(defaultSelectedOption ?? [])
  }, [defaultSelectedOption, filterOptions])

  const handleSelectCleanClick = () => {
    setSelectedOptionsKey([])
    setSelectedOptions([])
    onChangeFilter([])
  }
  const handleCheckboxChange = (checked: string | boolean, option: IFilterOption) => {
    if (checked) {
      setSelectedOptionsKey([...selectedOptionsKey, option.key])
      setSelectedOptions([...selectedOptions, option])
      onChangeFilter([...selectedOptionsKey, option.key])
    } else {
      setSelectedOptionsKey(selectedOptionsKey.filter((item) => item !== option.key))
      setSelectedOptions(selectedOptions.filter((item) => item.key !== option.key))
      onChangeFilter(selectedOptionsKey.filter((item) => item !== option.key))
    }
  }

  useEffect(() => {
    if (selectedOptionsKey.length > 0) {
      const filterOptionsMap = uniqBy([...filterOptions, ...selectedOptions], 'key').reduce(
        (acc: Record<string, string>, item) => {
          acc[item.key] = item.text
          return acc
        },
        {},
      )

      const displayValue = selectedOptionsKey.map((item) => filterOptionsMap[item] ?? item).join('; ')
      setValue(displayValue)
    } else {
      setValue('')
    }
  }, [selectedOptionsKey, filterOptions, selectedOptions])

  const renderContent = (snapshot: AsyncSnapshot<IFilterOption[]>) => {
    switch (snapshot.status) {
      case 'none':
        return null
      case 'waiting':
        return <Spinner />
      case 'done': {
        return (
          <Column>
            {snapshot.data.length > 0 &&
              snapshot.data.map((option) => {
                return (
                  <Column key={option.key} title={option.text} vAlign="center">
                    <label htmlFor={`tenantId-${option.key}`} className={styles.checkboxLabel}>
                      <Checkbox
                        checked={selectedOptionsKey.includes(option.key)}
                        label={
                          !option.notFound ? (
                            <Persona
                              avatar={{ color: 'colorful', 'aria-hidden': true }}
                              name={option.text}
                              secondaryText={option.key}
                              size="extra-small"
                            />
                          ) : (
                            <Text className={styles.text}>{`Search for tenant ID: "${option.key}"`}</Text>
                          )
                        }
                        id={`tenantId-${option.key}`}
                        onChange={(_, data) => handleCheckboxChange(data.checked, option)}
                        className={styles.checkBox}
                      />
                    </label>
                  </Column>
                )
              })}
          </Column>
        )
      }
      case 'error':
        return (
          <Option key="freeform" disabled>
            {`${snapshot.error}`}
          </Option>
        )
    }
  }

  const targetFinalOptions = renderContent(snapshot)
  return (
    <Column>
      <CopilotDashPopover
        optionContent={targetFinalOptions}
        value={value}
        placeholder={placeholder}
        selectedOptions={selectedOptionsKey}
        handleSelectCleanClick={handleSelectCleanClick}
        inputValue={keyword ?? ''}
        setInputValue={setkeyword}
        isPopoverOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        showInput={showInput}
        prefixIcon={<PeopleListRegular fontSize={16} />}
      />
    </Column>
  )
}
