import { PromiseSnapshot } from '@copilot-dash/core'
import { Skeleton, SkeletonItem, makeStyles } from '@fluentui/react-components'
import { ReactNode } from 'react'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'
import { ErrorView } from '../Error'

interface IProps<T> {
  readonly snapshot: PromiseSnapshot<T>
  readonly children?: ReactNode | ((data: T) => ReactNode)
}

export function TableCellForSnapshot<T>({ snapshot, children }: IProps<T>): ReactNode {
  const styles = useStyles()

  return (
    <TableCellLayoutWithStyles truncate>
      {(() => {
        switch (snapshot.status) {
          case 'waiting':
            return (
              <Skeleton appearance="translucent" className={styles.skeleton}>
                <SkeletonItem className={styles.skeletonItem1} />
                <SkeletonItem className={styles.skeletonItem2} />
              </Skeleton>
            )
          case 'done':
            return typeof children === 'function' ? children(snapshot.data) : children
          case 'error':
            return <ErrorView type="inline" error={snapshot.error} />
        }
      })()}
    </TableCellLayoutWithStyles>
  )
}

const useStyles = makeStyles({
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '250px',
    gap: '8px',
  },
  skeletonItem1: {
    width: '100%',
  },
  skeletonItem2: {
    width: '80%',
  },
})
