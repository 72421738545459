import { AsyncSnapshot, produce } from '@copilot-dash/core'

import { IClusterJobData } from '@copilot-dash/domain'
import { cloneDeep } from 'lodash'
import { defaultClusterArgs, defaultFilterForm } from './const'
import { createClusterSearcher } from './createClusterSearcher'
import { IClusterScreenStore } from './IClusterScreenStore'
import { IClusterScreenActions } from './IClusterScreenStoreActions'
import { ClusterJobResult, IClusterScreenState, ISearchClusterOptions } from './IClusterScreenStoreState'

export function createClusterScreenActions(store: IClusterScreenStore): IClusterScreenActions {
  return new ClusterScreenActions(store)
}

class ClusterScreenActions implements IClusterScreenActions {
  private readonly store: IClusterScreenStore

  private jobPreArgsForAll = defaultClusterArgs
  private readonly allClusterSearcher = createClusterSearcher()
  constructor(store: IClusterScreenStore) {
    this.store = store
  }

  onInit() {}
  onStateChanged(state: IClusterScreenState, old: IClusterScreenState): void {
    if (state.jobfilterForm !== old.jobfilterForm) {
      this.store.setState({
        jobListByAll: { status: 'none' },
      })
    }
  }

  clusterJobClick = (item: IClusterJobData): void => {
    if (!item) {
      this.store.setState({
        clickedClusterJobInfo: undefined,
      })
      return
    }
    if (item.id) {
      this.store.setState({
        clickedClusterJobInfo: item,
      })
      return
    }
  }

  handleJobsFromAll = (snapshot: AsyncSnapshot<ClusterJobResult>) => {
    this.store.setState((state) => {
      if (this.jobPreArgsForAll.offset === 0) {
        state.jobListByAll = cloneDeep(snapshot)
      } else {
        state.jobListByAll.error = snapshot.error
        state.jobListByAll.status = snapshot.status
        state.jobListByAll.data?.jobs.push(...(snapshot.data?.jobs ?? []))
      }
      if (snapshot.status === 'done') {
        state.hasMoreJobsForAll = snapshot.data?.hasMore ?? false
      }
    })
  }

  fetchClusterJobListForAll(filter?: Partial<ISearchClusterOptions>) {
    this.store.setState({
      jobListByAll: { status: 'none' },
    })
    this.jobPreArgsForAll = produce(defaultClusterArgs, (draft) => {
      draft = Object.assign(draft, filter)
    })
    return this.allClusterSearcher(this.jobPreArgsForAll, this.handleJobsFromAll)
  }

  loadMoreClusterListForAll(offset: number, count?: number) {
    this.jobPreArgsForAll = produce(this.jobPreArgsForAll, (draft) => {
      draft.offset = offset
      draft.count = count ?? draft.count
    })
    return this.allClusterSearcher(this.jobPreArgsForAll, this.handleJobsFromAll)
  }

  updateJobStatus(jobs: Array<IClusterJobData>) {
    this.store.setState((state) => {
      state.jobListByAll.data?.jobs.forEach((job) => {
        const newJob = jobs.find((newJob) => newJob.id === job.id)
        if (newJob) {
          Object.assign(job, newJob)
        }
      })
    })
  }

  resetFilterForm() {
    this.store.setState((state) => {
      state.jobfilterForm = defaultFilterForm
    })
  }

  setFilterForm(filter: Partial<ISearchClusterOptions>) {
    this.store.setState((state) => {
      state.jobfilterForm = { ...defaultFilterForm, ...filter }
    })
  }
}
