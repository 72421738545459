import { Route } from 'react-router-dom'
import { LogoutRoutePage } from './LogoutRoutePage'
import { createNavigator } from '../../navigator/createNavigator'
import { VoidMapper } from '../../utils/VoidMapper'

export function LogoutRoute() {
  return <Route id={ID} path={PATH} element={<LogoutRoutePage />} />
}

const ID = 'logout'
const PATH = '/logout'
LogoutRoute.navigator = createNavigator(ID, PATH, VoidMapper)
