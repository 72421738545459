import { makeStyles } from '@fluentui/react-components'
import { Editor } from '@monaco-editor/react'
import { initMonaco } from './instance/initMonaco'
import { MonacoLanguage } from './MonacoViewTypes'
import { useMonacoValue } from './utils/useMonacoValue'
import { useThemeMode } from '../theme/ThemeModeProvider'

interface IProps {
  readonly data: unknown
  readonly language?: MonacoLanguage
  readonly wrap?: boolean
  readonly minimap?: boolean
  readonly editable?: boolean
}

export function MonacoView({ data, language, wrap = false, minimap = false, editable = false }: IProps) {
  const styles = useStyles()
  const theme = useThemeMode()
  const result = useMonacoValue(data, language)

  return (
    <Editor
      className={styles.root}
      value={result.value}
      language={result.language}
      theme={theme === 'dark' ? 'vs-dark' : 'light'}
      options={{
        readOnly: !editable,
        minimap: { enabled: minimap },
        wordWrap: wrap ? 'on' : 'off',
        scrollbar: {
          alwaysConsumeMouseWheel: true,
          vertical: 'visible',
        },
      }}
      beforeMount={initMonaco}
    />
  )
}

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    width: '100%',
  },
})
