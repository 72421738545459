export async function initAppAuth(): Promise<void> {
  await application.auth.init()

  const count = application.auth.accounts.length
  if (count === 0) {
    Logger.trace.info('No accounts found')
  } else if (count === 1) {
    Logger.trace.info('Found 1 account')
  } else {
    Logger.trace.info(`Found ${count} accounts`)
  }
}
