import { Column, Row } from '@copilot-dash/components'
import { Spinner } from '@fluentui/react-components'
import { useEffect, useRef } from 'react'
import { DiscussionPanelBody } from './DiscussionPanelBody'
import { useStyles } from './DiscussionPanelDrawer.styles'
import { useToast } from '../../../hooks/useToast'
import { ErrorView } from '../../Error'
import { getErrorMessage } from '../../ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../../ErrorConfigs/utils/getErrorMessageDetails'
import { LexicalRichTextEditor } from '../../Lexical/LexicalRichTextEditor'
import { DiscussionPanel } from '../DiscussionPanel'

export function DiscussionPanelDrawer() {
  const styles = useStyles()
  const containerRef = useRef<HTMLDivElement>(null)
  const discussionId = DiscussionPanel.use((state) => state.discussionId)
  const actions = DiscussionPanel.useActions()
  const commentsSnapshot = DiscussionPanel.use((state) => state.commentsSnapshot)
  const postCommentSnapshot = DiscussionPanel.use((state) => state.postCommentSnapshot)
  const deleteCommentSnapshot = DiscussionPanel.use((state) => state.deleteCommentSnapshot)

  const toast = useToast()
  const onSubmitted = async (value: string | undefined) => {
    if (value?.trim() === '') {
      toast.showWarning('Please type a comment to continue!')
    } else {
      if (value) {
        actions.postComment(discussionId, value)
      }
    }
  }

  useEffect(() => {
    switch (postCommentSnapshot.status) {
      case 'none':
        break
      case 'waiting':
        break
      case 'done':
        Logger.telemetry.trackEvent('TicketDetail/CreateComment', {
          ticketId: discussionId,
          status: 'Succeed',
        })
        toast.showSuccess('Sent a comment successfully!')
        break
      case 'error':
        toast.showError(
          'Failed to send!',
          getErrorMessageDetails(postCommentSnapshot.error) ?? getErrorMessage(postCommentSnapshot.error),
        )
        Logger.telemetry.trackEvent('TicketDetail/CreateComment', {
          ticketId: discussionId,
          status: 'Failed',
          errorMessage: String(postCommentSnapshot.error),
        })
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCommentSnapshot.status])

  useEffect(() => {
    switch (deleteCommentSnapshot.status) {
      case 'none':
        break
      case 'waiting':
        break
      case 'done':
        toast.showSuccess('Delete a comment successfully!')
        break
      case 'error':
        toast.showError(
          'Failed to delete!',
          getErrorMessageDetails(deleteCommentSnapshot.error) ?? getErrorMessage(deleteCommentSnapshot.error),
        )
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommentSnapshot.status])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsSnapshot.data?.slice(-1)[0]?.id])

  return (
    <Column className={styles.card} vAlign="stretch" hAlign="stretch" fill>
      <Column ref={containerRef} className={styles.body}>
        {(() => {
          switch (commentsSnapshot.status) {
            case 'waiting':
              return (
                <Row className={styles.waiting} fill vAlign="center" hAlign="center">
                  <Spinner label="Loading..." />
                </Row>
              )
            case 'error':
              return <ErrorView error={commentsSnapshot.error} />
            case 'done':
              return <DiscussionPanelBody />
            default:
              return null
          }
        })()}
      </Column>
      <Column className={styles.footer}>
        <LexicalRichTextEditor placeholder="Add a comment" onSubmit={onSubmitted} />
      </Column>
    </Column>
  )
}
