import { Column } from '@copilot-dash/components'
import { INewTicketData } from '@copilot-dash/domain'
import { memo, useCallback, useRef } from 'react'
import { CollapsibleTable } from './CollapsibleTable'
import { groupTicketsByStateV2 } from './utils'
import { IAGGridTableRef } from '../../../../../../../components/AGGridTable/AGGridTable'
import { useSearchScreenActions } from '../../../../../store'

interface IGroupedTicketsByStateProps {
  readonly tickets: INewTicketData[]
  readonly hasMore?: boolean
  readonly loadMore?: (offset: number) => void
  readonly rowHoverStickySlot?: (rowData: INewTicketData) => JSX.Element
}

const stateOrder = ['Team Assigned', 'Root Caused', 'Closed', 'Untriaged']

export const GroupedTicketsByState = memo(function GroupedTicketsByState(props: IGroupedTicketsByStateProps) {
  const { tickets } = props
  const actions = useSearchScreenActions()
  const tablesRef = useRef<{
    [key: string]: IAGGridTableRef | null
  }>({})

  const nestArray = Object.entries(groupTicketsByStateV2(tickets)).sort((a, b) => {
    return stateOrder.indexOf(a[0]) - stateOrder.indexOf(b[0])
  })
  const currentSelectedGroupKeyRef = useRef('')
  const handleSelectionChange = useCallback(
    (selectedItems: INewTicketData[], groupKey: string) => {
      if (groupKey !== currentSelectedGroupKeyRef.current) {
        tablesRef.current[currentSelectedGroupKeyRef.current]?.clearSelection()
      }
      currentSelectedGroupKeyRef.current = groupKey

      actions.setSelectedTickets(selectedItems)
      actions.updateBatchOperationPanelVisibility(selectedItems.length > 0)
    },
    [actions],
  )

  return (
    <Column style={{ width: '100%' }}>
      {nestArray.map(([state, tickets], index) => (
        <CollapsibleTable
          key={state}
          tickets={tickets}
          title={state}
          defaultExpanded={index === 0}
          hasMore={props.hasMore}
          loadMore={props.loadMore}
          rowHoverStickySlot={props.rowHoverStickySlot}
          tableRef={(ref) => {
            tablesRef.current[state] = ref
          }}
          onSelectionChange={(tickets) => handleSelectionChange(tickets, state)}
        />
      ))}
    </Column>
  )
})
