import { MonacoDialog } from '@copilot-dash/components'
import { Button } from '@fluentui/react-components'
import { FullScreenMaximizeRegular } from '@fluentui/react-icons'

interface IProps {
  readonly data: unknown
}

export function TreeItemOpenButton({ data }: IProps) {
  if (!data) {
    return null
  }

  const handleClick = () => {
    MonacoDialog.open(data)
  }

  return <Button appearance="subtle" icon={<FullScreenMaximizeRegular />} title="Open" onClick={handleClick} />
}
