import { User } from '@copilot-dash/api'
import { Avatar, Button, Image, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components'
import * as React from 'react'
import { useStyles } from './HeaderAvatar.style'
import { HeaderAvatarPopover } from './HeaderAvatarPopover'
import { ThemeProvider } from '../../../../theme/ThemeProvider'

export function HeaderAvatar() {
  const activeAccount = app.auth.activeAccount
  const [avatarUrl, setAvatarUrl] = React.useState<string | null>(null)
  const [user, setUser] = React.useState<User | undefined>(undefined)
  const avatarRef = React.useRef(null)
  const styles = useStyles()

  React.useEffect(() => {
    application.api.microsoftGraph
      .getMyPhoto()
      .then((bitmap: Blob) => {
        setAvatarUrl(URL.createObjectURL(bitmap))
      })
      .catch((error: Blob) => {
        setAvatarUrl('')
        Logger.trace.error('Failed to get user photo:', error)
      })
    application.api.microsoftGraph
      .getMyProfile()
      .then(setUser)
      .catch((error) => {
        Logger.trace.error('Failed to get user profile:', error)
      })
  }, [])

  const logout = React.useCallback(() => {
    application.auth.logout()
  }, [])

  return (
    <Popover withArrow>
      <PopoverTrigger>
        {avatarUrl ? (
          <Button appearance="subtle" className={styles.triggerContainer}>
            <Image ref={avatarRef} src={avatarUrl} alt={activeAccount?.username} className={styles.avatarIcon} />
          </Button>
        ) : (
          <Avatar name={user?.displayName ?? undefined} className={styles.avatarIcon} />
        )}
      </PopoverTrigger>
      <ThemeProvider>
        <PopoverSurface className={styles.panelLayout}>
          {user && <HeaderAvatarPopover logout={logout} avatarUrl={avatarUrl} user={user} />}
        </PopoverSurface>
      </ThemeProvider>
    </Popover>
  )
}
