import { Row } from '@copilot-dash/components'
import { Text } from '@fluentui/react-components'
import { TimeView } from '../../../../components/Time/TimeView'

interface IProps {
  readonly datetime: string | undefined
}

export function TicketScreenBarTicketDate({ datetime }: IProps) {
  return (
    <Row gap="gap.smaller">
      <Text weight="semibold">Date: </Text>
      <TimeView value={datetime} format="MM/DD HH:mmA" />
    </Row>
  )
}
