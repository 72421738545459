import { TabItemForMonaco } from '@copilot-dash/components'
import { TicketAsyncView } from '../../common/TicketAsyncView'

interface IProps {
  readonly ticketId: string
  readonly messageId: string
}

export function TicketScreenTelemetryItemForJson({ ticketId, messageId }: IProps) {
  const snapshot = app.store.use.getRawConversationGroup3(ticketId, messageId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        return <TabItemForMonaco data={data} />
      }}
    </TicketAsyncView>
  )
}
