import { useLayoutEffect, useState } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ClusterScreenStoreContext } from './ClusterScreenStoreContext'
import { createClusterScreenActions } from './createClusterScreenActions'
import { createClusterScreenState } from './createClusterScreenState'
import { IClusterScreenState } from './IClusterScreenStoreState'

interface IProps {
  readonly children: React.ReactNode
}

export function ClusterScreenStoreProvider({ children }: IProps) {
  const [value] = useState(() => {
    const initialState = createClusterScreenState()
    const useBoundStore = create<IClusterScreenState>()(immer(() => initialState))
    const store = {
      get state() {
        return useBoundStore.getState()
      },
      use: useBoundStore,
      setState: useBoundStore.setState,
    }

    const actions = createClusterScreenActions(store)
    return { store, actions }
  })
  useLayoutEffect(() => {
    return value.store.use.subscribe((state, prevState) => {
      value.actions.onStateChanged(state, prevState)
    })
  }, [value])

  useLayoutEffect(() => {
    value.actions.onInit()
  }, [value])

  return <ClusterScreenStoreContext.Provider value={value}>{children}</ClusterScreenStoreContext.Provider>
}
