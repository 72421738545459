import { Column, Row } from '@copilot-dash/components'
import { makeStyles } from '@fluentui/react-components'
import { ActivityHistoryCard } from './ActivityHistoryCard'

export function ActivityHistoryBody() {
  const styles = useStyles()

  return (
    <Row fill>
      <Column className={styles.side}>
        <ActivityHistoryCard />
      </Column>
    </Row>
  )
}

const useStyles = makeStyles({
  side: {
    width: '100%',
    padding: '8px 16px 0px 16px',
  },
})
