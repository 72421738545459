import { z } from 'zod'

export interface ApiV2CreateRootCauseResponse {
  readonly id: string
  readonly teamId: number
  readonly rootCauseTitle: string
  readonly rootCauseStatus: string
  readonly rootCauseType: string
  readonly priority: string
  readonly eta?: string
  readonly assignTo?: string
  readonly description?: string
  readonly isDeleted: boolean
  readonly isRcrEnabled: boolean
  readonly createdTimeUtc: string
}

export const apiV2CreateRootCauseResponseSchema = z.object({
  id: z.string(),
  teamId: z.number(),
  rootCauseTitle: z.string(),
  rootCauseStatus: z.string(),
  rootCauseType: z.string(),
  priority: z.string(),
  eta: z.string().optional(),
  assignTo: z.string().optional(),
  description: z.string().optional(),
  isDeleted: z.boolean(),
  isRcrEnabled: z.boolean(),
  createdTimeUtc: z.string(),
})
