import { makeStyles, Tab } from '@fluentui/react-components'
import { forwardRef } from 'react'
import { ITabItem } from '../../store/IState'

interface IProps {
  readonly item: ITabItem
}

export const HeaderTabBarItem = forwardRef<HTMLButtonElement, IProps>(function HeaderTabBarItem({ item }, ref) {
  const styles = useStyles()

  return (
    <Tab ref={ref} className={styles.root} value={item.name} title={item.title || item.name}>
      {item.title || item.name}
    </Tab>
  )
})

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
})
