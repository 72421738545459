import { generatePath, Path } from 'react-router-dom'
import { generatePathQueryStringByQueryParams } from './generatePathQueryStringByQueryParams'
import { IRouteParams } from '../../../types/IRouteParams'

export function generatePathByPathPatternAndParams(pathPattern: string, params: IRouteParams): Readonly<Path> {
  try {
    const pathname = generatePath<string>(pathPattern, params.pathParams)
    const search = generatePathQueryStringByQueryParams(params.queryParams)
    let hash = params.hash ? params.hash : ''
    if (!hash.startsWith('#')) {
      hash = `#${hash}`
    }

    return {
      pathname,
      search,
      hash,
    }
  } catch (error) {
    throw new Error(
      `Failed to generate path by path pattern and params: ${error}. [pathPattern: ${pathPattern}, params: ${JSON.stringify(params)}]`,
    )
  }
}
