import { ProductName } from '@copilot-dash/domain'
import { IMcpExperimentFeedbackRouteArgs } from './types'
import { IRouteMapper } from '../../types/IRouteMapper'
import { IRouteParams } from '../../types/IRouteParams'
import { RouteDataReader } from '../../utils/RouteDataReader'

const PATH_PARAM_PRODUCT = 'product'

export const McpExperimentRouteMapper: IRouteMapper<IMcpExperimentFeedbackRouteArgs> = {
  mapToParams(args: IMcpExperimentFeedbackRouteArgs): IRouteParams {
    return {
      pathParams: {
        product: args.product,
      },
    }
  },

  mapToArgs(params: IRouteParams): IMcpExperimentFeedbackRouteArgs {
    const path = new RouteDataReader(params.pathParams)

    return {
      product: path.getString(PATH_PARAM_PRODUCT) as ProductName,
    }
  },
}
