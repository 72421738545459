import { MonacoViewInline } from '@copilot-dash/components'
import { SymptomReportItem } from '@copilot-dash/domain'
import { makeStyles, TableCell, tokens } from '@fluentui/react-components'
import { useMemo } from 'react'

interface IProps {
  readonly item: Extract<SymptomReportItem, { valueType: 'Json' }>
}

export function ItemCellValueForJson({ item }: IProps) {
  const styles = useStyles()

  const jsonString = useMemo(() => {
    return JSON.stringify(item.value, null, 2)
  }, [item])

  return (
    <TableCell className={styles.root} title={jsonString}>
      <div
        className={styles.scrollbar}
        onMouseEnter={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <MonacoViewInline data={item.value} maxHeight={300} />
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px',
  },
  scrollbar: {
    padding: '12px',
    backgroundColor: tokens.colorNeutralBackground2,
    border: `1px solid ${tokens.colorNeutralStroke1}`,
    borderRadius: '4px',
  },
})
