import { z } from 'zod'

export interface ApiTenantItem {
  readonly tenantId: string
  readonly tenantName: string
  readonly candidateCreatedDate?: string
  readonly enabledCreatedDate?: string
  readonly isPreviewCandidate: boolean
  readonly isPreviewEnabled: boolean
  readonly isS500: boolean
  readonly copilotEnabledUsers?: number
  readonly copilotAvailableUnits?: number
  readonly copilotSkuName?: string
  readonly createTime: string
  readonly modifyTime?: string
  readonly tenantType: Array<string>
}

export type ApiTenantListResponse = Array<ApiTenantItem>

const apiTenantItemSchema = z.object({
  tenantId: z.string(),
  tenantName: z.string(),
  candidateCreatedDate: z.string().optional(),
  enabledCreatedDate: z.string().optional(),
  isPreviewCandidate: z.boolean(),
  isPreviewEnabled: z.boolean(),
  isS500: z.boolean(),
  copilotEnabledUsers: z.number().optional(),
  copilotAvailableUnits: z.number().optional(),
  copilotSkuName: z.string().optional(),
  createTime: z.string(),
  tenantType: z.array(z.string()),
})

export const apiTenantListResponseSchema = z.array(apiTenantItemSchema)
