import { Row, Spacer } from '@copilot-dash/components'
import { ClusterResultColumnKey, IClusterJobResultSummary } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import {
  CopilotDashCustomHeaderProps,
  CustomHeader,
} from '../../../../../components/TicketTableColumnConfigs/CustomHeaderComponent'

import { TableCellLayoutWithStyles } from '../../../../../components/TicketTableColumnConfigs/TableCellLayoutWithStyles'

export const ColumnClusterResultPercent: ColDef<IClusterJobResultSummary> = {
  headerName: ClusterResultColumnKey.Percent,
  field: String(ClusterResultColumnKey.Percent) as NestedFieldPaths<IClusterJobResultSummary>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <Row>
          <Text weight={'semibold'}>{props.data.count}</Text>
          <Spacer width={8} />
          <Text>({(props.data.percent * 100).toFixed(2)}%)</Text>
        </Row>
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: <TableCellLayoutWithStyles>DSATs</TableCellLayoutWithStyles>,
  },
  minWidth: 200,
  width: 200,
  maxWidth: 200,
  sortable: true,
  resizable: true,
}
