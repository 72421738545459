import { z } from 'zod'

const apiDiagnosticConversationDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
  }),
)

const apiDiagnosticSubstrateSearchDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
    TransactionId: z.string().optional(),
  }),
)

const apiDiagnosticOfflineDataSchema = z.record(z.unknown()).and(
  z.object({
    StatusCode: z.number(),
    TransactionId: z.string().optional(),
  }),
)

const apiDiagnosticDataSchema = z.record(z.unknown()).and(
  z.object({
    Conversation: apiDiagnosticConversationDataSchema.optional(),
    SubstrateSearch: z.array(apiDiagnosticSubstrateSearchDataSchema).optional(),
    Offline: z.array(apiDiagnosticOfflineDataSchema).optional(),
  }),
)

export const apiInteractionInfosSchema = z.record(z.unknown()).and(
  z.object({
    InteractionTimeStr: z.string().optional(),
    MessageId: z.string().optional(),
    ClientName: z.string().optional(),
    Index: z.number(),
    ConversationId: z.string().optional(),
    SubstrateSearchReplayInfoList: z
      .array(
        z.record(z.unknown()).and(
          z.object({
            TransactionId: z.string().optional(),
            ReplayServiceCallTraceId: z.string().optional(),
          }),
        ),
      )
      .optional(),
    ImpressionIds: z.array(z.string()).optional(),
    Diagnostic: apiDiagnosticDataSchema.optional(),
  }),
)
