import {
  ApiConversationGroup1,
  ApiConversationGroup1AdaptiveCardBody,
  ApiConversationGroup1Message,
} from '@copilot-dash/api'
import { produce } from '@copilot-dash/core'
import {
  ITicketMessageForBot,
  ITicketMessageForInvocation,
  ITicketMessageForSuggestion,
  ITicketMessageForUser,
  ITicketTurnConversation,
} from '@copilot-dash/domain'
import { compact, isEmpty } from 'lodash'
import { z } from 'zod'

const AUTHOR_BOT = 'bot'
const AUTHOR_USER = 'user'
const MESSAGE_TYPE_CHAT = 'Chat'

export function convertTicketChatTurnFromConversationGroup1(
  messageId: string,
  api: ApiConversationGroup1,
): ITicketTurnConversation {
  return {
    id: messageId,
    timestamp: getTimeStamp(),
    utterance: getMessageForUser(),
    response: getBotMessage(),
    invocations: getInvocationMessages(),
    suggestions: getSuggestions(),
    source: 'conversation-group1',
    raw: api,
  }

  function getTimeStamp(): string | undefined {
    for (const message of api.conversation.messages) {
      if (message.createdAt) {
        return message.createdAt
      }
    }

    return undefined
  }

  function getMessageForUser(): ITicketMessageForUser | undefined {
    const message = api.conversation.messages.find((item) => {
      return item.author === AUTHOR_USER && item.messageType === MESSAGE_TYPE_CHAT
    })
    if (!message) {
      return undefined
    }

    return {
      content: {
        language: undefined,
        text: getMessageText(message.text, message.adaptiveCards?.[0]?.body?.[0]?.text) ?? '',
        textInEnglish: undefined,
        markdown: undefined,
        markdownInEnglish: undefined,
        adaptiveCard: getAdaptiveCard(message),
      },
      timestamp: message.createdAt,
    }
  }

  function getBotMessage(): ITicketMessageForBot | undefined {
    const possibleMessages = api.conversation.messages
      .filter((item) => {
        return item.author !== AUTHOR_USER && (!item.messageType || item.messageType === MESSAGE_TYPE_CHAT)
      })
      .reverse()

    let message: ApiConversationGroup1Message | undefined
    message ??= possibleMessages.find((item) => item.author === AUTHOR_BOT && item.messageType === MESSAGE_TYPE_CHAT)
    message ??= possibleMessages.find((item) => item.author === AUTHOR_BOT)
    message ??= possibleMessages.find((item) => !isEmpty(item.suggestedResponses) && !isEmpty(item.adaptiveCards))
    message ??= possibleMessages.find((item) => !isEmpty(item.suggestedResponses))
    message ??= possibleMessages.find((item) => !isEmpty(item.adaptiveCards))
    message ??= possibleMessages.find((item) => item.suggestedResponses && item.adaptiveCards)
    message ??= possibleMessages.find((item) => item.suggestedResponses)
    message ??= possibleMessages.find((item) => item.adaptiveCards)
    message ??= possibleMessages[0]

    if (!message) {
      return undefined
    }

    const text = getMessageText(message.text, message.adaptiveCards?.[0]?.body?.[0]?.text)
    return {
      content: {
        language: undefined,
        text: text || '',
        textInEnglish: undefined,
        markdown: text,
        markdownInEnglish: undefined,
        adaptiveCard: getAdaptiveCard(message),
      },
      timestamp: message.createdAt,
    }
  }

  function getInvocationMessages(): ITicketMessageForInvocation[] {
    const message = api.conversation.messages.filter((item) => item.invocation)

    return compact(
      message.map((item) => {
        if (!item.invocation) {
          return
        }

        let text = item.invocation
        try {
          text = JSON.stringify(JSON.parse(item.invocation), null, 2)
        } catch (error) {
          // ignore
        }

        return {
          type: item.messageType,
          text,
        }
      }),
    )
  }

  function getSuggestions(): ITicketMessageForSuggestion[] {
    for (const message of api.conversation.messages) {
      if (message.suggestedResponses && message.suggestedResponses.length > 0) {
        return message.suggestedResponses.map((item) => {
          return {
            text: item.text,
          }
        })
      }
    }

    return []
  }

  function getMessageText(...values: unknown[]): string | undefined {
    for (const value of values) {
      if (value && typeof value === 'string') {
        return tryParseAdaptiveCardMessage(value)
      }
    }

    return ''
  }

  function tryParseAdaptiveCardMessage(text: string): string {
    // TODO: This is a temporary solution to handle OPG bugs!
    // Needs to be removed once the OPG fixes the issue.
    try {
      const jsonObject = JSON.parse(text)
      const message: AdaptiveCardMessage = adaptiveCardMessageSchema.parse(jsonObject)
      return message.body.message || message.body.textContent || message.body.messageHeader?.text || text
    } catch (error) {
      return text
    }
  }
}

function getAdaptiveCard(message: ApiConversationGroup1Message): object | undefined {
  const instance = message.adaptiveCards?.[0]
  if (!instance) {
    return undefined
  }

  // Case1:
  // This is a temporary solution to handle OPG bugs!
  // Needs to be removed once the OPG fixes the issue.
  // If text field is a json string and can be parsed, then it is NOT a valid adaptive card.
  try {
    const text = instance.body?.[0]?.text
    if (text) {
      adaptiveCardMessageSchema.parse(JSON.parse(text))
      return undefined
    }
  } catch {
    // do nothing
  }

  // Case2:
  // If the adaptive card's id is "compliant-image-entity" and has only one body, then we should append the message text into the body at the first
  if (
    instance.id === 'compliant-image-entity' &&
    instance.body?.length === 1 &&
    !instance.body[0]?.text &&
    instance.body[0]?.url
  ) {
    const messageText = message.text
    if (messageText && typeof messageText === 'string') {
      return produce(instance, (draft) => {
        const bodyItem: ApiConversationGroup1AdaptiveCardBody = {
          type: 'TextBlock',
          text: messageText,
          wrap: true,
        }

        draft.body?.unshift(bodyItem)
      })
    }
  }

  return instance
}

interface AdaptiveCardMessage {
  readonly body: AdaptiveCardMessageBody
}

interface AdaptiveCardMessageBody {
  readonly message?: string
  readonly textContent?: string
  readonly messageHeader?: {
    readonly text?: string
  }
}

const adaptiveCardMessageBodySchema = z.object({
  message: z.string().optional(),
  textContent: z.string().optional(),
  messageHeader: z
    .object({
      text: z.string().optional(),
    })
    .optional(),
})

const adaptiveCardMessageSchema = z.object({
  body: adaptiveCardMessageBodySchema,
})
