import { INewTicketData, TicketEmotionType, ColumnKey, ColumnIdNameMapping } from '@copilot-dash/domain'
import { Text } from '@fluentui/react-components'
import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { CustomCellRendererProps } from 'ag-grid-react'
import { memo } from 'react'
import { useStyles } from './ColumnComponent.styles'
import { CustomHeader, CopilotDashCustomHeaderProps } from './CustomHeaderComponent'
import { TableCellLayoutWithStyles } from './TableCellLayoutWithStyles'

export const ColumnConfigPriority: ColDef<INewTicketData> = {
  headerName: ColumnIdNameMapping[ColumnKey.Priority],
  field: String(ColumnKey.Priority) as NestedFieldPaths<INewTicketData>,
  cellRenderer: (props: CustomCellRendererProps) => {
    return (
      <TableCellLayoutWithStyles truncate>
        <RenderPriority item={props.data} />
      </TableCellLayoutWithStyles>
    )
  },
  headerComponent: (props: CopilotDashCustomHeaderProps) => <CustomHeader {...props} />,
  headerComponentParams: {
    customHeaderContent: (
      <TableCellLayoutWithStyles truncate>{ColumnIdNameMapping[ColumnKey.Priority]}</TableCellLayoutWithStyles>
    ),
  },
  flex: 1,
  minWidth: 70,
  sortable: false,
  resizable: true,
}

const RenderPriority = memo(({ item }: { item: INewTicketData }) => {
  const styles = useStyles()
  if (item.thumbFeedback === TicketEmotionType.Positive) {
    return <Text className={styles.grayColor}>N/A</Text>
  } else if (item.priority === null || item.priority === undefined || item.priority === -1) return
  return <Text className={styles.text}>P{item.priority}</Text>
})

RenderPriority.displayName = 'RenderPriority'
